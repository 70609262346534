import { ComponentProps } from 'react';
import { RadioGroup } from './RadioGroup';

const radioOptions = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

type BooleanRadioGroupProps = Omit<
  ComponentProps<typeof RadioGroup>,
  'options'
>;

export function BooleanRadioGroup(props: BooleanRadioGroupProps) {
  return <RadioGroup {...props} options={radioOptions} />;
}
