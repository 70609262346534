import { Box, Menu } from '@mantine/core';
import { useContext, useEffect } from 'react';
import { ContextMenuContext } from './ContextMenuContext';

export function ContextMenu() {
  const { opened, setOpened, point, title, menuItems } =
    useContext(ContextMenuContext);

  // This is a hack to force the menu to re-render when the the point/menu items change.
  useEffect(() => {
    if (opened) {
      setOpened(false);
      setTimeout(() => {
        setOpened(true);
      }, 0);
    }
  }, [opened, point, menuItems, setOpened]);

  useEffect(() => {
    const handleClick = () => setOpened(false);
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [setOpened]);

  if (!opened) {
    return null;
  }

  return (
    <Menu opened zIndex={1000} withArrow shadow="md">
      <Menu.Target>
        <Box w={0} h={0} pos="absolute" left={point.x} top={point.y} />
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>{title}</Menu.Label>
        {menuItems.map((item, index) => {
          const { label, ...props } = item;
          const key = `${index}${label}`;
          return (
            <Menu.Item key={key} {...props}>
              {item.label}
            </Menu.Item>
          );
        })}
      </Menu.Dropdown>
    </Menu>
  );
}
