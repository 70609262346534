import { File } from '@agtuary/api/types/file';
import { Carousel, Embla, useAnimationOffsetEffect } from '@mantine/carousel';
import { ContextModalProps } from '@mantine/modals';
import { useState } from 'react';
import { ImageCarouselSlide } from './ImageCarouselSlide';

export type ImageCarouselModalInnerProps = {
  images: File[];
  initialSlide: number;
};

export function ImageCarouselModal({
  id,
  context,
  innerProps: { images, initialSlide },
}: ContextModalProps<ImageCarouselModalInnerProps>) {
  const [embla, setEmbla] = useState<Embla | null>(null);

  const transitionDuration =
    context.modals.find((modal) => modal.id === id)?.props?.transitionProps
      ?.duration || 200;

  useAnimationOffsetEffect(embla, transitionDuration);

  return (
    <Carousel loop getEmblaApi={setEmbla} initialSlide={initialSlide}>
      {images.map((image) => (
        <ImageCarouselSlide file={image} key={image.id} />
      ))}
    </Carousel>
  );
}
