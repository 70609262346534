export const typeFilterFactory = <T, K extends keyof T, V extends T[K]>(
  k: K,
  v: V,
): ((o: T) => o is Extract<T, Record<K, V>>) => {
  return (o: T): o is Extract<T, Record<K, V>> => {
    return o[k] === v;
  };
};

export const isDefined = <T>(o: T | undefined | null): o is T => {
  return o !== undefined && o !== null;
};

export const onlyUnique = <T>(value: T, index: number, array: Array<T>) => {
  return array.indexOf(value) === index;
};
