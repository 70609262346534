import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  from,
} from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import equal from 'deep-equal';
import merge from 'deepmerge';
import { useMemo } from 'react';
import fragmentMatcher from '../../__generated__/fragment-matcher.json';
import {
  APOLLO_CLIENT_NAME,
  APOLLO_CLIENT_VERSION,
  APOLLO_STATE_PROP_NAME,
} from '../constants';
import { batchHttpLink } from '../links/batchHttpLink';
import { gatewayTimeoutLink } from '../links/gatewayTimeoutLink';
import { logoutLink } from '../links/logoutLink';
import { removeTypenameLink } from '../links/removeTypename';
import { typePolicies } from '../type-policies';

// https://www.apollographql.com/docs/react/errors/
if (process.env.NODE_ENV === 'development') {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

/**
 * Build the Apollo Link.
 * @param logout - The logout function to call.
 * @returns An Apollo Link.
 */
const buildLink = (logout: () => void = () => undefined): ApolloLink => {
  return from([
    removeTypenameLink,
    gatewayTimeoutLink,
    logoutLink(logout),
    batchHttpLink,
  ]);
};

/**
 * The Client-Side Apollo Client.
 * On the client-side we always use the same client instance.
 */
const client = new ApolloClient({
  link: buildLink(),
  cache: new InMemoryCache({
    possibleTypes: fragmentMatcher.possibleTypes,
    typePolicies,
  }),
  name: APOLLO_CLIENT_NAME,
  version: APOLLO_CLIENT_VERSION,
});

/**
 * Initialize an Apollo Client on the client-side.
 * @param initialState The initial state.
 * @param logout The logout function.
 * @returns An Apollo Client.
 */
const initClient = (
  initialState: NormalizedCacheObject | undefined,
  logout: (() => void) | undefined,
) => {
  client.setLink(buildLink(logout));

  // If we have state initialized through SSR, restore it.
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = client.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !equal(d, s)),
        ),
      ],
    });

    // Restore the cache with the merged data
    client.cache.restore(data);
  }

  return client;
};

/**
 * Initialize an Apollo Client on the client-side.
 * @param pageProps The page props.
 * @param logout The logout function. (Must be stable through re-renders.)
 * @returns An Apollo Client.
 */
export function useApollo(
  pageProps: {
    [APOLLO_STATE_PROP_NAME]?: NormalizedCacheObject;
  },
  logout?: () => void,
) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];

  return useMemo(() => initClient(state, logout), [logout, state]);
}
