import { Title } from '@mantine/core';
import { openContextModal } from '@mantine/modals';
import { ConfirmDeleteModalInnerProps } from './ConfirmDeleteModal';

export const confirmDelete = 'confirmDelete';

export const useConfirmDeleteModal =
  () => (innerProps: ConfirmDeleteModalInnerProps) => {
    openContextModal({
      modal: confirmDelete,
      innerProps,
      title: <Title order={4}>Confirm delete</Title>,
    });
  };
