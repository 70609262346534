import { useEffect, useState } from 'react';

/**
 * This is a helper hook to use a persistent Zustand store with SSR.
 * Not required when not using persist middleware.
 */
export const useHydratedStore = <S, T extends keyof S>(
  store: (callback: (state: S) => S[T]) => S[T],
  initialStates: S,
  selector: (state: S) => S[T],
): S[T] => {
  const [state, setState] = useState(selector(initialStates));
  const zustandState = store(selector);

  useEffect(() => {
    setState(zustandState);
  }, [zustandState]);

  return state;
};
