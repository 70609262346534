import { Title } from '@mantine/core';
import { openContextModal } from '@mantine/modals';

const ctx = 'deleteProfile';

export const useDeleteProfileModal = () => {
  return {
    ctx,
    open: () => {
      openContextModal({
        modal: ctx,
        innerProps: {},
        title: <Title order={4}>Profile delete request</Title>,
      });
    },
  };
};
