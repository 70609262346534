import {
  MantineTheme,
  MantineThemeOverride,
  NavLinkProps,
} from '@mantine/core';

export const NavLink: MantineThemeOverride['components'] = {
  NavLink: {
    defaultProps: (): Partial<NavLinkProps> => ({
      color: 'gray',
      h: 40,
    }),
    styles: (theme: MantineTheme) => ({
      root: {
        borderRadius: theme.radius.sm,
        fontWeight: theme.other.fonts.weights.medium,
        // marginBottom: 2,

        '&:hover': {
          backgroundColor: theme.colors.white,
        },

        '&[data-active="true"]': {
          fontWeight: theme.other.fonts.weights.bold,
          backgroundColor: theme.colors.gray[2],
        },

        '&[data-active="true"]:hover': {
          backgroundColor: theme.colors.gray[2],
          // backgroundColor: theme.fn.lighten(theme.colors.gray[2], 0.4),
        },

        // '&:last-of-type': {
        //   marginBottom: 0,
        // },
      },

      label: {
        fontSize: theme.fontSizes.md,
      },
    }),
  },
};
