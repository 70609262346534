import { DatesProvider } from '@mantine/dates';
import dayjs from 'dayjs';
import 'dayjs/locale/en-au';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { PropsWithChildren } from 'react';

dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);

/**
 * Every dayjs locale we want to support must be imported one by one. This will need extension
 * in the future if we want to support more locales.
 */
const defaultLocale = 'en-au';
dayjs.locale(defaultLocale);

export function LocaleProvider({ children }: PropsWithChildren) {
  return (
    <DatesProvider settings={{ locale: defaultLocale }}>
      {children}
    </DatesProvider>
  );
}
