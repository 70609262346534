import { Invitation } from '@agtuary/api/types/invitation';
import { TeamIcon } from '@agtuary/ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  Modal,
  Space,
  Text,
  ThemeIcon,
  Title,
} from '@mantine/core';

type AcceptAdvisorInviteModalProps = {
  onClose: () => void;
  teamName: string | undefined;
  invitation: Invitation;
  acceptInvite: () => void;
  isLoading: boolean;
};

export function AcceptAdvisorInviteModal({
  onClose,
  teamName,
  invitation,
  acceptInvite,
  isLoading,
}: AcceptAdvisorInviteModalProps) {
  const { invitedBy } = invitation;

  // invitedBy can technically be null, but it in practice it should always be populated
  const advisorName = invitedBy
    ? `${invitedBy.firstName} ${invitedBy.lastName}`
    : 'An Advisor';

  const teamNameString = teamName ? `the team '${teamName}'` : 'a team';

  return (
    <Modal opened onClose={onClose} title="Claim your team">
      <Box p="xs">
        <Center>
          <Flex direction="column" align="center" gap="sm">
            <Space h="lg" />
            <ThemeIcon radius="xl" size={60}>
              {/* TODO: use own icon */}
              <TeamIcon width={40} height={40} />
            </ThemeIcon>
            <Title order={5}>{teamName}</Title>
            <Text size="sm">{`${advisorName} has created ${teamNameString} for you in the Agtuary platform.`}</Text>
            <Text size="sm">
              You will become the owner of this team, and they will will stay in
              your team with an &apos;Advisor&apos; role.
            </Text>

            <Space h="xl" />
          </Flex>
        </Center>
        <Flex justify="space-between">
          {/* REVISIT: Should we actually expire the invite if the user declines it? */}
          <Button variant="outline" color="gray" onClick={onClose}>
            Decline
          </Button>
          <Space w="md" />
          <Button color="brand" onClick={acceptInvite} loading={isLoading}>
            Accept
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
}
