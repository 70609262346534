/**
 * Type safe string to lowercase.
 * @param str The string to convert to lowercase.
 * @returns The lowercase string.
 */
export const lowercase = <T extends string>(str: T): Lowercase<T> =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  str.toLowerCase() as Lowercase<T>;

/**
 * Type safe string to uppercase.
 * @param str The string to convert to uppercase.
 * @returns The uppercase string.
 */
export const uppercase = <T extends string>(str: T): Uppercase<T> =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  str.toUpperCase() as Uppercase<T>;

/**
 * Type safe string capitalize.
 * @param str The string to capitalize.
 * @returns The capitalized string.
 */
export const capitalize = <T extends string>(str: T): Capitalize<T> =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  (str.charAt(0).toUpperCase() + str.slice(1)) as Capitalize<T>;

/**
 * Type safe string capitalize and lowercase.
 * @param str The string to capitalize and lowercase.
 * @returns The capitalized and lowercase string.
 */
export const capitalizeLowercase = <T extends string>(
  str: T,
): Capitalize<Lowercase<T>> => capitalize(lowercase(str));
