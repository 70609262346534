import { Anchor, Flex, Text, Title } from '@mantine/core';
import { useApplicationMode } from 'hooks/useApplicationMode';
import { ReactNode } from 'react';
import styles from './ErrorContent.module.scss';

type ErrorProps = { message?: ReactNode };

export function ErrorContent({ message }: ErrorProps) {
  const {
    options: { contactUrl },
  } = useApplicationMode();
  return (
    <Flex direction="column" justify="center" align="center" gap="lg">
      <Title className={styles.title} order={2}>
        Something went wrong!
      </Title>
      <Text ta="center">
        {message}
        <br />
        If this error continues to occur please{' '}
        <Anchor href={contactUrl}>Contact Us</Anchor>
      </Text>
    </Flex>
  );
}
