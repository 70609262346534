import { useTeamResendInvoice } from '@agtuary/api/hooks/teams/mutations/useTeamResendInvoice';
import { DangerIcon } from '@agtuary/ui/icons';
import {
  Button,
  Center,
  Flex,
  Space,
  Text,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { showError } from 'helpers/showError';
import { showSuccess } from 'helpers/showSuccess';
import { useCurrentTeamSubscription } from 'hooks/useCurrentTeamSubscription';
import { useCurrentUser } from 'hooks/useCurrentUser';

export function PaymentNudgeModal() {
  const {
    user: { data: user },
    currentTeam,
  } = useCurrentUser();

  const { isSubscribed: isCurrentTeamSubscribed } =
    useCurrentTeamSubscription();

  const { isLoading, mutateAsync: trigger } = useTeamResendInvoice(
    currentTeam?.id,
  );

  const resend = () => {
    const teamId = currentTeam?.id;
    const userId = user?.id;

    if (teamId && userId) {
      trigger({ user: userId })
        .then((data) => {
          if (data?.success) {
            showSuccess({
              title: 'Invoice Sent',
              message: 'An invoice has been sent to your email.',
            });
          }
        })
        .catch(() => {
          showError(
            'An error occurred while resending the invoice. Please try again later.',
          );
        });
    } else {
      showError('An error occurred. Please try again later.');
    }
  };

  return isCurrentTeamSubscribed ? (
    <Center>
      <Flex direction="column" align="center" gap="sm">
        <Space h="lg" />
        <ThemeIcon radius="xl" size={60}>
          {/* TODO: use own icon */}
          <IconCheck width={50} height={50} />
        </ThemeIcon>
        <Title order={5}>Payment successful!</Title>
        <Text size="sm">
          You can now access all the features of the Agtuary platform.
        </Text>
        <Space h="xl" />
      </Flex>
    </Center>
  ) : (
    <Center>
      <Flex direction="column" align="center" gap="sm" w="100%">
        <Space h="lg" />
        <ThemeIcon radius="xl" size={60} color="red">
          <DangerIcon width={38} height={38} />
        </ThemeIcon>
        <Title order={5}>Payment Required</Title>
        <Text size="sm" align="center">
          Your team has an unpaid invoice.
          <br />
          Please make this payment to access all the features of the Agtuary
          platform.
        </Text>
        <Space h="xl" />
        <Flex justify="space-between" w="100%">
          <Button loading={isLoading} variant="outline" onClick={resend}>
            Resend Invoice by Email
          </Button>
          <Button
            component="a"
            href={currentTeam?.paymentSubscriptions.at(0)?.checkoutUrl}
            target="_blank"
          >
            Pay Now
          </Button>
        </Flex>
      </Flex>
    </Center>
  );
}
