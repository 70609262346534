import { routeId } from '@agtuary/common/helpers/encode/base62';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useFlags } from 'hooks/useFlags';
import { ComponentProps, useMemo } from 'react';

import { entriesFromObject } from '@agtuary/common/helpers/typed/object';
import { HamburgerMenuIcon } from '@agtuary/ui/icons';
import { ActionIcon, Button, Group, Menu } from '@mantine/core';
import { isRouteActive } from 'helpers/routes/is-active';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { MainHeaderMenuItems } from '../MainHeaderMenuItems';
import styles from './MainHeaderMenu.module.scss';

const menuClassNames = {
  dropdown: styles.dropdown,
};

export function MainHeaderMenu() {
  const { currentTeam } = useCurrentUser();
  const { route: currentRoute } = useRouter();
  const {
    portfolios: portfoliosEnabled,
    'jobs-management': jobsEnabled,
    'advisor-dashboard': advisorDashboardEnabled,
  } = useFlags();

  const targets: ComponentProps<typeof MainHeaderMenuItems>['targets'] =
    useMemo(
      () => ({
        ...(advisorDashboardEnabled && {
          advisorDashboard: {
            label: 'Advisor Dashboard',
            route: {
              pathname: '/advisor-dashboard',
              query: {},
            },
          },
        }),
        properties: {
          label: 'Properties',
          route: {
            pathname: '/team/[teamId]/properties',
            query: {
              teamId: routeId(currentTeam?.id),
            },
          },
        },
        ...(jobsEnabled && {
          jobs: {
            label: 'Jobs',
            route: {
              pathname: '/team/[teamId]/jobs',
              query: {
                teamId: routeId(currentTeam?.id),
              },
            },
          },
        }),
        ...(portfoliosEnabled && {
          portfolios: {
            label: 'Portfolios',
            route: {
              pathname: '/team/[teamId]/portfolios',
              query: {
                teamId: routeId(currentTeam?.id),
              },
            },
          },
        }),
      }),
      [
        advisorDashboardEnabled,
        currentTeam?.id,
        jobsEnabled,
        portfoliosEnabled,
      ],
    );

  const possibleMatch = entriesFromObject(targets).find(([_k, target]) =>
    isRouteActive(currentRoute, target.route.pathname),
  );

  const titleTarget = possibleMatch ? possibleMatch[1] : targets.properties;

  const showMenu = Object.keys(targets).length > 1;
  const groupStyle = showMenu
    ? styles.CurrentSection
    : styles.CurrentSectionNoMenu;

  return (
    <Menu classNames={menuClassNames} position="bottom-start">
      <Group className={groupStyle}>
        {showMenu && (
          <Menu.Target>
            <ActionIcon className={styles.MenuToggleButton}>
              <HamburgerMenuIcon className={styles.icon} />
            </ActionIcon>
          </Menu.Target>
        )}
        <Button
          component={Link}
          href={titleTarget.route}
          className={styles.button}
        >
          {titleTarget.label}
        </Button>
      </Group>

      <Menu.Dropdown>
        <MainHeaderMenuItems targets={targets} />
      </Menu.Dropdown>
    </Menu>
  );
}
