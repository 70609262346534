import { Role } from '@agtuary/api/types/role';
import { Team } from '@agtuary/api/types/team';
import { User } from '@agtuary/api/types/user';
import { Title } from '@mantine/core';
import { openContextModal } from '@mantine/modals';

export const editTeamMember = 'editTeamMember';

export const useEditTeamMemberModal =
  () => (team: Team, user: User, role: Role, closeOnSuccess?: boolean) => {
    openContextModal({
      modal: editTeamMember,
      title: <Title order={4}>Edit Team Member</Title>,
      innerProps: {
        team,
        user,
        role,
        closeOnSuccess,
      },
    });
  };
