import { BinIcon } from '@agtuary/ui/icons';
import { Button, ButtonProps } from '@mantine/core';
import { useConfirmDeleteModal } from 'components/modals/ConfirmDeleteModal';
import { EventHandler, MouseEvent } from 'react';

type DeleteButtonProps = {
  onDelete: EventHandler<MouseEvent>;
  acceptClicks?: boolean;
  confirmDelete?: boolean;
  text?: string;
} & ButtonProps;

export function DeleteButton({
  onDelete,
  text,
  confirmDelete = true,
  acceptClicks = false,
  ...props
}: DeleteButtonProps) {
  const openConfirmDeleteModal = useConfirmDeleteModal();

  const onClick = confirmDelete
    ? () => openConfirmDeleteModal({ onDelete })
    : onDelete;

  return (
    <Button
      {...props}
      type="submit"
      color="red.7"
      leftIcon={<BinIcon width={10} height={10} />}
      onClick={acceptClicks ? onClick : undefined}
    >
      {text || 'Delete'}
    </Button>
  );
}
