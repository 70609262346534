import { gql } from '@agtuary/graphql';

export const GET_MAP_SHAPES = gql(/* GraphQL */ `
  query GetMapShapesForSelectMapShapeFromLibraryModal($propertyId: ID!) {
    mapShapes(propertyId: $propertyId) {
      id
      ...SelectMapShapeFromLibraryModal_MapShape
    }
  }
`);
