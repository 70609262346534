type Transformer = (value: number) => number;

export const transformUnits = (transformer: Transformer) => {
  function applyTransform<
    T extends number | null | undefined,
    R = T extends number ? number : T extends undefined ? undefined : null,
  >(value: T): R {
    if (value === null) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return null as R;
    }
    if (value === undefined) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return undefined as R;
    }
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return transformer(value) as R;
  }

  return applyTransform;
};
