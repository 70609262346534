import { Property } from '@agtuary/api/types/property';
import { Report } from '@agtuary/api/types/report';
import dayjs from 'dayjs';

export const downloadReportPdf = (
  pdfUrl: string,
  report: Report,
  property: Property,
) => {
  const reportCreatedAt = dayjs(report.createdAt).format('DD-MM-YYYY');
  return fetch(pdfUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      const addressSegment = property?.addressLine1
        ? ` - ${property.addressLine1}`
        : '';
      const stateSegment = property?.state ? `-${property.state}` : '';
      link.download = `${report.name}${addressSegment}${stateSegment} - Agtuary Property Report - ${reportCreatedAt}.pdf`;
      link.click();
    });
};
