export const formatArea = (area: number, options?: Intl.NumberFormatOptions) =>
  new Intl.NumberFormat('en-AU', {
    style: 'decimal',
    ...options,
  }).format(area);

type FormatOptions = {
  /** The unit of the input area. Default is 'hectare'. */
  inputUnit?: 'squareMeter' | 'hectare';
  /** The number of decimal places to round the result to. Default is 2. */
  decimalPlaces?: number;
};

export const formatHectares = (area: number, options?: FormatOptions) => {
  const { inputUnit = 'hectare', decimalPlaces = 2 } = options || {};
  const areaInHectares = inputUnit === 'hectare' ? area : area / 10000;
  return formatArea(areaInHectares, {
    unit: 'hectare',
    unitDisplay: 'short',
    style: 'unit',
    maximumFractionDigits: decimalPlaces,
  });
};
