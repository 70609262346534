import {
  colorCodes as agtuaryColorCodes,
  colors as agtuaryColors,
} from '@agtuary/ui/theme/colors';
import { MantineThemeOverride } from '@mantine/core';

const colorCodes: MantineThemeOverride['colors'] = {
  ...agtuaryColorCodes,
  brand: [
    // AIA Blue
    '#EDF4FF', // blue-50
    '#C7DDFF', // blue-100
    '#AFCEFD', // blue-200
    '#8FB5EE', // blue-300
    '#578EDF', // blue-400
    '#477DCE', // blue-500
    '#3A65A6', // blue-600
    '#074894', // blue-700
    '#043A79', // blue-800
    '#022F65', // blue-900
  ],
};

export const colors: MantineThemeOverride = {
  ...agtuaryColors,
  // Object of arrays with 10 colors
  colors: colorCodes,
};
