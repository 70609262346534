import { Box } from '@mantine/core';
import { memo } from 'react';
import styles from './AIAEAPLogo.module.scss';
import Icon from './aia-eap-logo.svg';

function AIAEAPLogoComponent() {
  return (
    <Box className={styles.container}>
      <Icon viewBox="0 0 190 38" />
    </Box>
  );
}

export const AIAEAPLogo = memo(AIAEAPLogoComponent);
