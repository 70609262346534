import { MantineThemeOverride, rem } from '@mantine/core';

export const fonts: MantineThemeOverride = {
  // font-family and line-height used in most components
  fontFamily: 'Plus Jakarta Sans',
  // lineHeight: string | number;

  // Sizes for corresponding properties
  fontSizes: {
    xs: rem(10),
    sm: rem(12),
    md: rem(14),
    lg: rem(16),
    xl: rem(20),
  },

  headings: {
    fontFamily: 'Plus Jakarta Sans',
    sizes: {
      h1: { fontSize: rem(30) },
      h2: { fontSize: rem(24) },
      h3: { fontSize: rem(20) },
      h4: { fontSize: rem(16), fontWeight: 700 },
      h5: { fontSize: rem(14), fontWeight: 700 },
      h6: { fontSize: rem(12) },
    },
  },
};

export const fontsOther: MantineThemeOverride['other'] = {
  fonts: {
    weights: {
      thin: 100,
      extraLight: 200,
      light: 300,
      normal: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
      extraBold: 800,
      heavy: 900,
    },
  },
};

export const fontCSSVariables = {
  '--mantine-font-weight-thin': 100,
  '--mantine-font-weight-extra-light': 200,
  '--mantine-font-weight-light': 300,
  '--mantine-font-weight-normal': 400,
  '--mantine-font-weight-medium': 500,
  '--mantine-font-weight-semi-bold': 600,
  '--mantine-font-weight-bold': 700,
  '--mantine-font-weight-extra-bold': 800,
  '--mantine-font-weight-heavy': 900,
};
