import { createContext, SetStateAction, Dispatch } from 'react';
import { ContextMenuItem, ScreenPoint } from './types';

export const defaultContextMenuContext: {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  point: ScreenPoint;
  setPoint: Dispatch<SetStateAction<ScreenPoint>>;
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
  menuItems: ContextMenuItem[];
  setMenuItems: Dispatch<SetStateAction<ContextMenuItem[]>>;
} = {
  opened: false,
  setOpened: () => undefined,
  point: { x: 0, y: 0 },
  setPoint: () => undefined,
  title: '',
  setTitle: () => undefined,
  menuItems: [],
  setMenuItems: () => undefined,
};

export const ContextMenuContext = createContext(defaultContextMenuContext);
