import {
  MORE_INFO_REQUIRED_ACTION_TYPE,
  needsMoreInfoMinimumRequiredFields,
} from './constants';
import { MoreInfoRequiredAction } from './types';

export const moreInfoRequiredReducer = (
  state: boolean | null,
  action: MoreInfoRequiredAction,
) => {
  switch (action.type) {
    case MORE_INFO_REQUIRED_ACTION_TYPE.Check:
      if (!action.payload.user || !action.payload.team) return null;
      return (
        needsMoreInfoMinimumRequiredFields.user.some(
          (field) => action.payload.user[field] === null,
        ) ||
        needsMoreInfoMinimumRequiredFields.team.some(
          (field) => action.payload.team[field] === null,
        ) ||
        needsMoreInfoMinimumRequiredFields['team.organisation'].some(
          (field) => action.payload.team.organisation?.[field] === null,
        )
      );
    default:
      return state;
  }
};
