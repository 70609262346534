import { UserContext } from 'context/userContext';
import { MORE_INFO_REQUIRED_ACTION_TYPE } from 'context/userContext/constants';
import { useContext, useEffect } from 'react';
import { useCurrentUser } from './useCurrentUser';

export const useCheckUser = () => {
  const {
    user: { data: user },
    currentTeam,
    // organisations: { data: organisations },
  } = useCurrentUser();
  const { moreInfoRequired, moreInfoRequiredDispatch } =
    useContext(UserContext);

  useEffect(() => {
    if (
      moreInfoRequired === null &&
      user?.id &&
      currentTeam?.id
      // organisations?.[0]?.id
    ) {
      moreInfoRequiredDispatch({
        type: MORE_INFO_REQUIRED_ACTION_TYPE.Check,
        payload: { user, team: currentTeam },
      });
    }
  }, [currentTeam, moreInfoRequired, moreInfoRequiredDispatch, user]);

  return { moreInfoRequired };
};
