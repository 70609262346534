import { InputBase, InputBaseProps } from '@mantine/core';
import PhoneInputBase from 'react-phone-number-input/input';

type PhoneInputProps = {
  onChange: (value: string) => void;
  placeholder?: string;
} & InputBaseProps;

export function PhoneInput({ onChange, ...props }: PhoneInputProps) {
  return (
    <InputBase
      component={PhoneInputBase}
      onChange={onChange}
      country="AU"
      format="national"
      {...props}
    />
  );
}
