import { openContextModal } from '@mantine/modals';
import { ImageCarouselModalInnerProps } from './ImageCarouselModal';

const ctx = 'imageCarousel';

export const useImageCarouselModal = () => ({
  ctx,
  open: (innerProps: ImageCarouselModalInnerProps, onClose?: () => void) => {
    openContextModal({
      modal: ctx,
      innerProps,
      withCloseButton: false,
      onClose,
    });
  },
});
