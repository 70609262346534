/* eslint-disable */
import type { Decimal } from 'decimal.js-light';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** BBox2D custom scalar type for a 2 dimensional GeoJSON BBox */
  BBox2D: {
    input: GeoJSON.BBox & [number, number, number, number];
    output: GeoJSON.BBox & [number, number, number, number];
  };
  BigInt: { input: bigint; output: bigint };
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: string; output: string };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: string; output: string };
  /** Decimal custom scalar type for numbers that want to include decimal places */
  Decimal: { input: Decimal; output: Decimal };
  /** A set of multiple geometries, possibly of various types. */
  GeoJSONGeometryCollection: {
    input: GeoJSON.GeometryCollection;
    output: GeoJSON.GeometryCollection;
  };
  GeoJSONGeometryInterface: {
    input: GeoJSON.Geometry;
    output: GeoJSON.Geometry;
  };
  /** Object describing a single connected sequence of geographical points. */
  GeoJSONLineString: { input: GeoJSON.LineString; output: GeoJSON.LineString };
  /** Object describing multiple connected sequences of geographical points. */
  GeoJSONMultiLineString: {
    input: GeoJSON.MultiLineString;
    output: GeoJSON.MultiLineString;
  };
  /** Object describing multiple geographical points. */
  GeoJSONMultiPoint: { input: GeoJSON.MultiPoint; output: GeoJSON.MultiPoint };
  /** Object describing multiple shapes formed by sets of geographical points. */
  GeoJSONMultiPolygon: {
    input: GeoJSON.MultiPolygon;
    output: GeoJSON.MultiPolygon;
  };
  /** Object describing a single geographical point. */
  GeoJSONPoint: { input: GeoJSON.Point; output: GeoJSON.Point };
  /** Object describing a single shape formed by a set of geographical points. */
  GeoJSONPolygon: { input: GeoJSON.Polygon; output: GeoJSON.Polygon };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: JSON.Value; output: JSON.Value };
  JSONObject: { input: JSON.Object; output: JSON.Object };
  /** A field whose value is a valid decimal degrees latitude number (53.471): https://en.wikipedia.org/wiki/Latitude */
  Latitude: { input: number; output: number };
  /** A field whose value is a valid decimal degrees longitude number (53.471): https://en.wikipedia.org/wiki/Longitude */
  Longitude: { input: number; output: number };
  /** Percent custom scalar type for numbers between 0 and 1 */
  Percent: { input: Decimal; output: Decimal };
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: { input: string; output: string };
};

export type Activity = {
  businessActivity: BusinessActivity;
  businessActivityId: Scalars['ID']['output'];
  end: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  locations?: Maybe<Array<Maybe<ActivityLocation>>>;
  name: Scalars['String']['output'];
  start: Scalars['Date']['output'];
  targetAllocations?: Maybe<Array<Maybe<ActivityAllocation>>>;
};

export type ActivityAllocation = {
  __typename: 'ActivityAllocation';
  percentage: Scalars['Int']['output'];
  sourceActivity: Activity;
  targetActivity: Activity;
};

export type ActivityAllocationInput = {
  percentage: Scalars['Int']['input'];
  targetActivityId: Scalars['ID']['input'];
};

export type ActivityLocation = {
  __typename: 'ActivityLocation';
  area?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type ActivityQueryParamsInput = {
  activityTypes?: InputMaybe<Array<ActivityType>>;
};

export enum ActivityType {
  BeefLivestockActivity = 'BEEF_LIVESTOCK_ACTIVITY',
  BuffaloLivestockActivity = 'BUFFALO_LIVESTOCK_ACTIVITY',
  BurningActivity = 'BURNING_ACTIVITY',
  CroppingActivity = 'CROPPING_ACTIVITY',
  DairyLivestockActivity = 'DAIRY_LIVESTOCK_ACTIVITY',
  DeerLivestockActivity = 'DEER_LIVESTOCK_ACTIVITY',
  FeedlotActivity = 'FEEDLOT_ACTIVITY',
  GoatLivestockActivity = 'GOAT_LIVESTOCK_ACTIVITY',
  PorkLivestockActivity = 'PORK_LIVESTOCK_ACTIVITY',
  PoultryBroilerActivity = 'POULTRY_BROILER_ACTIVITY',
  PoultryLayerActivity = 'POULTRY_LAYER_ACTIVITY',
  SheepLivestockActivity = 'SHEEP_LIVESTOCK_ACTIVITY',
  VegetationActivity = 'VEGETATION_ACTIVITY',
  WildSeaFisheryActivity = 'WILD_SEA_FISHERY_ACTIVITY',
}

/** Input for adding a parcel to a property */
export type AddParcelToPropertyInput = {
  /** The parcel geometry as a multi-polygon */
  geometry: Scalars['GeoJSONMultiPolygon']['input'];
  /** The name of the parcel within the property */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Unique ID for the parcel */
  parcelId: Scalars['String']['input'];
};

/** Input for adding a property to a job. */
export type AddPropertyToJobInput = {
  /** The job ID. */
  jobId: Scalars['ID']['input'];
  /** The name of the property inside the job. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The property ID. */
  propertyId: Scalars['ID']['input'];
};

/** Address for the property */
export type Address = {
  __typename: 'Address';
  /** Address for the property */
  address?: Maybe<Scalars['String']['output']>;
  /** Postcode for the property */
  postcode?: Maybe<Scalars['String']['output']>;
  /** Name of the property */
  propertyName?: Maybe<Scalars['String']['output']>;
  /** State for the property */
  state?: Maybe<Scalars['String']['output']>;
  /** Street name for the property */
  street?: Maybe<Scalars['String']['output']>;
  /** Street number for the property */
  streetNumber?: Maybe<Scalars['String']['output']>;
  /** Street number sort for the property */
  streetNumberSort?: Maybe<Scalars['Int']['output']>;
  /** Street type for the property */
  streetType?: Maybe<Scalars['String']['output']>;
  /** Suburb for the property */
  suburb?: Maybe<Scalars['String']['output']>;
  /** Unit number for the property */
  unit?: Maybe<Scalars['String']['output']>;
};

export type AllEmissionsCalculation = {
  __typename: 'AllEmissionsCalculation';
  inputs: EmissionsCalculationInputs;
  result: AllEmissionsCalculationResult;
};

export type AllEmissionsCalculationResult = {
  __typename: 'AllEmissionsCalculationResult';
  carbonSequestration: AllEmissionsCarbonSequestration;
  net: AllEmissionsNet;
  purchasedOffsets: AllEmissionsPurchasedOffsets;
  scope1: AllEmissionsScope1;
  scope2: AllEmissionsScope2;
  scope3: AllEmissionsScope3;
};

export type AllEmissionsCarbonSequestration = {
  __typename: 'AllEmissionsCarbonSequestration';
  total: Scalars['Float']['output'];
};

export type AllEmissionsNet = {
  __typename: 'AllEmissionsNet';
  total: Scalars['Float']['output'];
};

export type AllEmissionsPurchasedOffsets = {
  __typename: 'AllEmissionsPurchasedOffsets';
  total: Scalars['Float']['output'];
};

export type AllEmissionsScope1 = {
  __typename: 'AllEmissionsScope1';
  total: Scalars['Float']['output'];
  totalCH4: Scalars['Float']['output'];
  totalCO2: Scalars['Float']['output'];
  totalN2O: Scalars['Float']['output'];
};

export type AllEmissionsScope2 = {
  __typename: 'AllEmissionsScope2';
  total: Scalars['Float']['output'];
};

export type AllEmissionsScope3 = {
  __typename: 'AllEmissionsScope3';
  total: Scalars['Float']['output'];
};

export enum BeefClass {
  BullsGt_1 = 'BULLS_GT_1',
  CowsGt_2 = 'COWS_GT_2',
  Heifers_1To_2 = 'HEIFERS_1_TO_2',
  HeifersGt_2 = 'HEIFERS_GT_2',
  HeifersLt_1 = 'HEIFERS_LT_1',
  Steers_1To_2 = 'STEERS_1_TO_2',
  SteersGt_2 = 'STEERS_GT_2',
  SteersLt_1 = 'STEERS_LT_1',
}

export type BeefLivestockActivity = Activity &
  LivestockActivity & {
    __typename: 'BeefLivestockActivity';
    bullsGt1Breeding: Scalars['Boolean']['output'];
    bullsGt1Trading: Scalars['Boolean']['output'];
    businessActivity: BusinessActivity;
    businessActivityId: Scalars['ID']['output'];
    cowsGt2Breeding: Scalars['Boolean']['output'];
    cowsGt2Trading: Scalars['Boolean']['output'];
    end: Scalars['Date']['output'];
    feed?: Maybe<Feed>;
    feedId?: Maybe<Scalars['ID']['output']>;
    heifers1to2Breeding: Scalars['Boolean']['output'];
    heifers1to2Trading: Scalars['Boolean']['output'];
    heifersGt2Breeding: Scalars['Boolean']['output'];
    heifersGt2Trading: Scalars['Boolean']['output'];
    heifersLt1Breeding: Scalars['Boolean']['output'];
    heifersLt1Trading: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    locations?: Maybe<Array<Maybe<ActivityLocation>>>;
    name: Scalars['String']['output'];
    start: Scalars['Date']['output'];
    steers1to2Breeding: Scalars['Boolean']['output'];
    steers1to2Trading: Scalars['Boolean']['output'];
    steersGt2Breeding: Scalars['Boolean']['output'];
    steersGt2Trading: Scalars['Boolean']['output'];
    steersLt1Breeding: Scalars['Boolean']['output'];
    steersLt1Trading: Scalars['Boolean']['output'];
    targetAllocations?: Maybe<Array<Maybe<ActivityAllocation>>>;
  };

export type BeefMonthLivestockActivity = {
  __typename: 'BeefMonthLivestockActivity';
  averageLiveweightKg: Scalars['Int']['output'];
  beefClass: BeefClass;
  beefLivestockActivityId: Scalars['ID']['output'];
  crudeProteinPercentage: Scalars['Percent']['output'];
  dryMatterDigestibilityPercentage: Scalars['Percent']['output'];
  headAtStart: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  keyEvents?: Maybe<Scalars['String']['output']>;
  livestockCategory: LivestockCategory;
  livestockTransactionEvents: Array<Maybe<LivestockTransactionEvent>>;
  month: Month;
  nonSalePopulationChange: Scalars['Int']['output'];
  percentageCowsCalving: Scalars['Percent']['output'];
  previousMonthLiveweightKg?: Maybe<Scalars['Int']['output']>;
  year: Scalars['Int']['output'];
};

export enum BuffaloClass {
  Bulls = 'BULLS',
  Calfs = 'CALFS',
  Cows = 'COWS',
  Steers = 'STEERS',
}

export type BuffaloEmissionsCalculation = {
  __typename: 'BuffaloEmissionsCalculation';
  inputs: EmissionsCalculationInputs;
  result: BuffaloEmissionsCalculationResult;
};

export type BuffaloEmissionsCalculationResult = {
  __typename: 'BuffaloEmissionsCalculationResult';
  carbonSequestration?: Maybe<BuffaloEmissionsCarbonSequestration>;
  intensities?: Maybe<BuffaloEmissionsIntensities>;
  net?: Maybe<BuffaloEmissionsNet>;
  scope1?: Maybe<BuffaloEmissionsScope1>;
  scope2?: Maybe<BuffaloEmissionsScope2>;
  scope3?: Maybe<BuffaloEmissionsScope3>;
};

export type BuffaloEmissionsCarbonSequestration = {
  __typename: 'BuffaloEmissionsCarbonSequestration';
  intermediate?: Maybe<BuffaloEmissionsTotalAverage>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type BuffaloEmissionsIntensities = {
  __typename: 'BuffaloEmissionsIntensities';
  buffaloMeatExcludingSequestration?: Maybe<Scalars['Float']['output']>;
  buffaloMeatIncludingSequestration?: Maybe<Scalars['Float']['output']>;
};

export type BuffaloEmissionsNet = {
  __typename: 'BuffaloEmissionsNet';
  total?: Maybe<Scalars['Float']['output']>;
};

export type BuffaloEmissionsScope1 = {
  __typename: 'BuffaloEmissionsScope1';
  animalWasteN2O?: Maybe<Scalars['Float']['output']>;
  atmosphericDepositionN2O?: Maybe<Scalars['Float']['output']>;
  entericCH4?: Maybe<Scalars['Float']['output']>;
  fertiliserN2O?: Maybe<Scalars['Float']['output']>;
  fuelCH4?: Maybe<Scalars['Float']['output']>;
  fuelCO2?: Maybe<Scalars['Float']['output']>;
  fuelN2O?: Maybe<Scalars['Float']['output']>;
  leachingAndRunoffN2O?: Maybe<Scalars['Float']['output']>;
  limeCO2?: Maybe<Scalars['Float']['output']>;
  manureManagementCH4?: Maybe<Scalars['Float']['output']>;
  manureManagementN2O?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  totalCH4?: Maybe<Scalars['Float']['output']>;
  totalCO2?: Maybe<Scalars['Float']['output']>;
  totalN2O?: Maybe<Scalars['Float']['output']>;
  transportCH4?: Maybe<Scalars['Float']['output']>;
  transportCO2?: Maybe<Scalars['Float']['output']>;
  transportN2O?: Maybe<Scalars['Float']['output']>;
  ureaCO2?: Maybe<Scalars['Float']['output']>;
  urineAndDungN2O?: Maybe<Scalars['Float']['output']>;
};

export type BuffaloEmissionsScope2 = {
  __typename: 'BuffaloEmissionsScope2';
  electricity?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type BuffaloEmissionsScope3 = {
  __typename: 'BuffaloEmissionsScope3';
  electricity?: Maybe<Scalars['Float']['output']>;
  fertiliser?: Maybe<Scalars['Float']['output']>;
  fuel?: Maybe<Scalars['Float']['output']>;
  herbicide?: Maybe<Scalars['Float']['output']>;
  lime?: Maybe<Scalars['Float']['output']>;
  purchasedFeed?: Maybe<Scalars['Float']['output']>;
  purchasedLivestock?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type BuffaloEmissionsTotalAverage = {
  __typename: 'BuffaloEmissionsTotalAverage';
  average?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type BuffaloLivestockActivity = Activity &
  LivestockActivity & {
    __typename: 'BuffaloLivestockActivity';
    bullsBreeding: Scalars['Boolean']['output'];
    bullsTrading: Scalars['Boolean']['output'];
    businessActivity: BusinessActivity;
    businessActivityId: Scalars['ID']['output'];
    calfsBreeding: Scalars['Boolean']['output'];
    calfsTrading: Scalars['Boolean']['output'];
    cowsBreeding: Scalars['Boolean']['output'];
    cowsTrading: Scalars['Boolean']['output'];
    end: Scalars['Date']['output'];
    feed?: Maybe<Feed>;
    feedId?: Maybe<Scalars['ID']['output']>;
    id: Scalars['ID']['output'];
    locations?: Maybe<Array<Maybe<ActivityLocation>>>;
    name: Scalars['String']['output'];
    start: Scalars['Date']['output'];
    steersBreeding: Scalars['Boolean']['output'];
    steersTrading: Scalars['Boolean']['output'];
    targetAllocations?: Maybe<Array<Maybe<ActivityAllocation>>>;
  };

export type BuffaloMonthLivestockActivity = {
  __typename: 'BuffaloMonthLivestockActivity';
  buffaloClass: BuffaloClass;
  buffaloLivestockActivityId: Scalars['ID']['output'];
  headAtStart: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  keyEvents?: Maybe<Scalars['String']['output']>;
  livestockCategory: LivestockCategory;
  livestockTransactionEvents: Array<LivestockTransactionEvent>;
  month: Month;
  nonSalePopulationChange: Scalars['Int']['output'];
  percentageCowsCalving: Scalars['Percent']['output'];
  seasonalCalvingRate: Scalars['Percent']['output'];
  year: Scalars['Int']['output'];
};

export type BurningActivity = Activity & {
  __typename: 'BurningActivity';
  businessActivity: BusinessActivity;
  businessActivityId: Scalars['ID']['output'];
  end: Scalars['Date']['output'];
  fireScarAreaSquareMetres?: Maybe<Scalars['Int']['output']>;
  fuelClass?: Maybe<BurningActivityFuelClass>;
  id: Scalars['ID']['output'];
  locations?: Maybe<Array<Maybe<ActivityLocation>>>;
  name: Scalars['String']['output'];
  patchiness?: Maybe<BurningActivityPatchiness>;
  rainfallZone?: Maybe<BurningActivityRainfallZone>;
  season?: Maybe<BurningActivitySeason>;
  start: Scalars['Date']['output'];
  targetAllocations?: Maybe<Array<Maybe<ActivityAllocation>>>;
  vegetationClass?: Maybe<BurningActivityVegetationClass>;
  yearsSinceLastFire?: Maybe<Scalars['Decimal']['output']>;
};

export enum BurningActivityFuelClass {
  Coarse = 'COARSE',
  Fine = 'FINE',
}

export enum BurningActivityPatchiness {
  High = 'HIGH',
  Low = 'LOW',
}

export enum BurningActivityRainfallZone {
  High = 'HIGH',
  Low = 'LOW',
}

export enum BurningActivitySeason {
  Early = 'EARLY',
  Late = 'LATE',
}

export enum BurningActivityVegetationClass {
  MelaleucaWoodland = 'MELALEUCA_WOODLAND',
  OpenForestMixed = 'OPEN_FOREST_MIXED',
  OpenWoodlandWithMixedGrass = 'OPEN_WOODLAND_WITH_MIXED_GRASS',
  ShrublandHeathWithHummockGrass = 'SHRUBLAND_HEATH_WITH_HUMMOCK_GRASS',
  ShrublandHummock = 'SHRUBLAND_HUMMOCK',
  WoodlandHummock = 'WOODLAND_HUMMOCK',
  WoodlandMixed = 'WOODLAND_MIXED',
  WoodlandWithHummockGrass = 'WOODLAND_WITH_HUMMOCK_GRASS',
  WoodlandWithMixedGrass = 'WOODLAND_WITH_MIXED_GRASS',
  WoodlandWithTussockGrass = 'WOODLAND_WITH_TUSSOCK_GRASS',
}

export type BusinessActivity = {
  __typename: 'BusinessActivity';
  /** Exposes the list of activities associated with this business activity, with CroppingActivity attached where relevant */
  activities?: Maybe<Array<Maybe<Activity>>>;
  consumptionAllocations?: Maybe<Array<Maybe<ConsumptionAllocation>>>;
  consumptions?: Maybe<Array<Maybe<Consumption>>>;
  createdAt: Scalars['Date']['output'];
  createdByUserId: Scalars['ID']['output'];
  /** @deprecated Use enabledActivityTypes instead */
  enabledActivities: EnabledActivities;
  enabledActivityTypes?: Maybe<Array<EnabledActivityTypes>>;
  id: Scalars['ID']['output'];
  ownedByTeamId: Scalars['ID']['output'];
  propertyId: Scalars['ID']['output'];
  propertyNorthOfTropicOfCapricorn: Scalars['Boolean']['output'];
  rainfallAbove600: Scalars['Boolean']['output'];
  state: StateRegion;
};

export enum ClumLandUse {
  ChannelAqueduct = 'CHANNEL_AQUEDUCT',
  Cropping = 'CROPPING',
  EstuaryCoastalWaters = 'ESTUARY_COASTAL_WATERS',
  GrazingIrrigatedModifiedPastures = 'GRAZING_IRRIGATED_MODIFIED_PASTURES',
  GrazingModifiedPastures = 'GRAZING_MODIFIED_PASTURES',
  GrazingNativeVegetation = 'GRAZING_NATIVE_VEGETATION',
  IntensiveAnimalProduction = 'INTENSIVE_ANIMAL_PRODUCTION',
  IntensiveHorticulture = 'INTENSIVE_HORTICULTURE',
  IrrigatedCropping = 'IRRIGATED_CROPPING',
  IrrigatedLandInTransition = 'IRRIGATED_LAND_IN_TRANSITION',
  IrrigatedPerennialHorticulture = 'IRRIGATED_PERENNIAL_HORTICULTURE',
  IrrigatedPlantationForests = 'IRRIGATED_PLANTATION_FORESTS',
  IrrigatedSeasonalHorticulture = 'IRRIGATED_SEASONAL_HORTICULTURE',
  Lake = 'LAKE',
  LandInTransition = 'LAND_IN_TRANSITION',
  ManagedResourceProtection = 'MANAGED_RESOURCE_PROTECTION',
  ManufacturingAndIndustrial = 'MANUFACTURING_AND_INDUSTRIAL',
  MarshWetland = 'MARSH_WETLAND',
  Mining = 'MINING',
  NatureConservation = 'NATURE_CONSERVATION',
  OtherMinimalUse = 'OTHER_MINIMAL_USE',
  PerennialHorticulture = 'PERENNIAL_HORTICULTURE',
  PlantationForests = 'PLANTATION_FORESTS',
  ProductionNativeForests = 'PRODUCTION_NATIVE_FORESTS',
  ReservoirDam = 'RESERVOIR_DAM',
  ResidentialAndFarmInfrastructure = 'RESIDENTIAL_AND_FARM_INFRASTRUCTURE',
  River = 'RIVER',
  SeasonalHorticulture = 'SEASONAL_HORTICULTURE',
  Services = 'SERVICES',
  TransportAndCommunication = 'TRANSPORT_AND_COMMUNICATION',
  Utilities = 'UTILITIES',
  WasteTreatmentAndDisposal = 'WASTE_TREATMENT_AND_DISPOSAL',
}

export type CalculationRequest = {
  __typename: 'CalculationRequest';
  endpoint: Scalars['String']['output'];
  payload: Scalars['JSONObject']['output'];
};

export type Carbamate = {
  __typename: 'Carbamate';
  activeIngredientPercentage?: Maybe<Scalars['Percent']['output']>;
  amountPerHectareGrams: Scalars['Int']['output'];
  applicationAreaGeometry?: Maybe<Scalars['GeoJSONMultiPolygon']['output']>;
  applicationAreaSquareMetres?: Maybe<Scalars['Int']['output']>;
  applicationDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  product: CarbamateProduct;
  type: CarbamateType;
};

export enum CarbamateProduct {
  AlphaCyphemethrin = 'ALPHA_CYPHEMETHRIN',
  Atrazine = 'ATRAZINE',
  Azoxystrobin = 'AZOXYSTROBIN',
  Bifenthrin = 'BIFENTHRIN',
  Carbendazim = 'CARBENDAZIM',
  Chlorpyrifos = 'CHLORPYRIFOS',
  Clethodim = 'CLETHODIM',
  Dimetheoate = 'DIMETHEOATE',
  Diquat = 'DIQUAT',
  DualGold = 'DUAL_GOLD',
  DAmine = 'D_AMINE',
  DEster = 'D_ESTER',
  Epoxiconazole = 'EPOXICONAZOLE',
  Glyphosate = 'GLYPHOSATE',
  McpaAmine = 'MCPA_AMINE',
  McpaEster = 'MCPA_ESTER',
  Ometheoate = 'OMETHEOATE',
  Other = 'OTHER',
  Paraquat = 'PARAQUAT',
  ParaquatDiquat = 'PARAQUAT_DIQUAT',
  Prothioconazole = 'PROTHIOCONAZOLE',
  Pyroxasulfone = 'PYROXASULFONE',
  Simazine = 'SIMAZINE',
  Tebuconazole = 'TEBUCONAZOLE',
  Tigrex = 'TIGREX',
  Trifluralin = 'TRIFLURALIN',
}

export enum CarbamateType {
  Fungicide = 'FUNGICIDE',
  Herbicide = 'HERBICIDE',
  Pesticide = 'PESTICIDE',
}

export type ComparableSale = {
  __typename: 'ComparableSale';
  /** The user defined name for this comparable sale */
  name?: Maybe<Scalars['String']['output']>;
  /** The Property object that is comparable to this sale (not the one that was sold) */
  property: Property;
  /** The unique ID of the property */
  propertyId: Scalars['ID']['output'];
  /** The sale object */
  sale: Sale;
  /** The unique ID of the sale */
  saleId: Scalars['ID']['output'];
  /** Similarity score to the original property */
  similarity: Scalars['Percent']['output'];
};

export type ComparableSaleSearchOrderBy = {
  area?: InputMaybe<SortOrder>;
  /** Order by distance */
  distance?: InputMaybe<SortOrder>;
  /** Order by price */
  price?: InputMaybe<SortOrder>;
  /** Order by sale date */
  saleDate?: InputMaybe<SortOrder>;
  /** Order by similarity */
  similarity?: InputMaybe<SortOrder>;
};

/** Comparable sale object */
export type ComparableSaleSearchResult = {
  __typename: 'ComparableSaleSearchResult';
  /** The address of the property */
  address?: Maybe<Address>;
  /** Area of the property in square meters */
  area?: Maybe<Scalars['Float']['output']>;
  bbox?: Maybe<Scalars['BBox2D']['output']>;
  /** Bounding property outline GeoJson */
  boundingGeoJson?: Maybe<Scalars['JSONObject']['output']>;
  /** Distance in metres from the original property */
  distance: Scalars['Int']['output'];
  /** Center point latitude */
  latitude: Scalars['Latitude']['output'];
  /** Center point longitude */
  longitude: Scalars['Longitude']['output'];
  /** Parcel ids */
  parcelIds: Array<Scalars['String']['output']>;
  /** List of parcels for the property */
  parcelLotPlans?: Maybe<Array<Maybe<ParcelLotPlan>>>;
  /** Price of the sale in cents */
  price: Scalars['Int']['output'];
  /** Date of the sale */
  saleDate: Scalars['Date']['output'];
  /** Similarity score to the original property */
  similarity: Scalars['Percent']['output'];
  /** The source of the data (ie NPG/PropTrack/user-entered) */
  source: SalesDataSource;
  /** Meta data specific to the source - readonly */
  sourceRef: Scalars['String']['output'];
};

export enum ConsumableSubType {
  Ammonia = 'AMMONIA',
  BeddingHayStraw = 'BEDDING_HAY_STRAW',
  CottonSeed = 'COTTON_SEED',
  Diesel = 'DIESEL',
  Grain = 'GRAIN',
  Grid = 'GRID',
  Hay = 'HAY',
  Hfc_23 = 'HFC_23',
  Hfc_32 = 'HFC_32',
  Hfc_41 = 'HFC_41',
  Hfc_43_10Mee = 'HFC_43_10MEE',
  Hfc_125 = 'HFC_125',
  Hfc_134 = 'HFC_134',
  Hfc_134A = 'HFC_134A',
  Hfc_143 = 'HFC_143',
  Hfc_143A = 'HFC_143A',
  Hfc_152A = 'HFC_152A',
  Hfc_227Ea = 'HFC_227EA',
  Hfc_236Fa = 'HFC_236FA',
  Hfc_245Ca = 'HFC_245CA',
  Hfc_245Fa = 'HFC_245FA',
  Hfc_365Mfc = 'HFC_365MFC',
  Lpg = 'LPG',
  Petrol = 'PETROL',
  R438A = 'R438A',
  R448A = 'R448A',
  Renewable = 'RENEWABLE',
  R_22 = 'R_22',
}

export enum ConsumableType {
  Electricity = 'ELECTRICITY',
  Feed = 'FEED',
  Fuel = 'FUEL',
  Refrigerant = 'REFRIGERANT',
}

export type Consumption = {
  __typename: 'Consumption';
  /** electricity = Wh, feed = kg, fuel = ml, refrigerant = kg */
  amount: Scalars['Int']['output'];
  consumableSubType: ConsumableSubType;
  consumableType: ConsumableType;
  consumptionAllocations: Array<ConsumptionAllocation>;
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
};

export type ConsumptionAllocation = {
  __typename: 'ConsumptionAllocation';
  percentage: Scalars['Int']['output'];
  sourceConsumption: Consumption;
  targetActivity: Activity;
};

export type ConsumptionAllocationInput = {
  consumptionId?: InputMaybe<Scalars['ID']['input']>;
  percentage: Scalars['Int']['input'];
  targetActivityId: Scalars['ID']['input'];
};

export type CottonActivity = {
  __typename: 'CottonActivity';
  averageCottonYieldKgPerHa?: Maybe<Scalars['Int']['output']>;
  averageWeightPerBaleKg: Scalars['Decimal']['output'];
  balesPerHa?: Maybe<Scalars['Decimal']['output']>;
  cottonLintPerBaleKg: Scalars['Decimal']['output'];
  cottonSeedPerBaleKg: Scalars['Decimal']['output'];
  id: Scalars['ID']['output'];
  percentageBurned?: Maybe<Scalars['Int']['output']>;
  residueBurned: Scalars['Boolean']['output'];
  wastePerBaleKg: Scalars['Decimal']['output'];
};

export type CottonEmissionsCalculation = {
  __typename: 'CottonEmissionsCalculation';
  inputs: EmissionsCalculationInputs;
  result: CottonEmissionsCalculationResult;
};

export type CottonEmissionsCalculationResult = {
  __typename: 'CottonEmissionsCalculationResult';
  carbonSequestration?: Maybe<GrainsEmissionsCarbonSequestration>;
  intensities: Array<CottonEmissionsIntensity>;
  intermediate?: Maybe<Array<Maybe<GrainsEmissionsIntermediateScopes>>>;
  net?: Maybe<GrainsEmissionsNet>;
  scope1?: Maybe<GrainsEmissionsScope1>;
  scope2?: Maybe<GrainsEmissionsScope2>;
  scope3?: Maybe<GrainsEmissionsScope3>;
};

export type CottonEmissionsIntensity = {
  __typename: 'CottonEmissionsIntensity';
  balesExcludingSequestration?: Maybe<Scalars['Float']['output']>;
  balesIncludingSequestration?: Maybe<Scalars['Float']['output']>;
  lintEconomicAllocation?: Maybe<Scalars['Float']['output']>;
  lintExcludingSequestration?: Maybe<Scalars['Float']['output']>;
  lintIncludingSequestration?: Maybe<Scalars['Float']['output']>;
  seedEconomicAllocation?: Maybe<Scalars['Float']['output']>;
  seedExcludingSequestration?: Maybe<Scalars['Float']['output']>;
  seedIncludingSequestration?: Maybe<Scalars['Float']['output']>;
  tonnesCropExcludingSequestration?: Maybe<Scalars['Float']['output']>;
  tonnesCropIncludingSequestration?: Maybe<Scalars['Float']['output']>;
};

export type CreateActivityInput = {
  end: Scalars['Date']['input'];
  name: Scalars['String']['input'];
  start: Scalars['Date']['input'];
};

export type CreateAdvisorInvitationInput = {
  advisorUserId: Scalars['ID']['input'];
  clientEmail: Scalars['String']['input'];
  clientFirstName: Scalars['String']['input'];
  invitationUrl: Scalars['String']['input'];
  organisationName: Scalars['String']['input'];
  teamName?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBeefLivestockActivityInput = {
  createActivityInput: CreateActivityInput;
};

export type CreateBeefMonthLivestockActivityInput = {
  averageLiveweightKg: Scalars['Int']['input'];
  beefClass: BeefClass;
  beefLivestockActivityId: Scalars['ID']['input'];
  crudeProteinPercentage: Scalars['Percent']['input'];
  dryMatterDigestibilityPercentage: Scalars['Percent']['input'];
  headAtStart: Scalars['Int']['input'];
  keyEvents?: InputMaybe<Scalars['String']['input']>;
  livestockCategory: LivestockCategory;
  month: Month;
  nonSalePopulationChange: Scalars['Int']['input'];
  percentageCowsCalving: Scalars['Percent']['input'];
  previousMonthLiveweightKg?: InputMaybe<Scalars['Int']['input']>;
  year: Scalars['Int']['input'];
};

export type CreateBuffaloLivestockActivityInput = {
  createActivityInput: CreateActivityInput;
};

export type CreateBuffaloMonthLivestockActivityInput = {
  buffaloClass: BuffaloClass;
  buffaloLivestockActivityId: Scalars['ID']['input'];
  headAtStart: Scalars['Int']['input'];
  keyEvents?: InputMaybe<Scalars['String']['input']>;
  livestockCategory: LivestockCategory;
  month: Month;
  nonSalePopulationChange: Scalars['Int']['input'];
  percentageCowsCalving: Scalars['Percent']['input'];
  seasonalCalvingRate: Scalars['Percent']['input'];
  year: Scalars['Int']['input'];
};

export type CreateBurningActivityInput = {
  createActivityInput: CreateActivityInput;
  fireScarAreaSquareMetres?: InputMaybe<Scalars['Int']['input']>;
  fuelClass?: InputMaybe<BurningActivityFuelClass>;
  patchiness?: InputMaybe<BurningActivityPatchiness>;
  rainfallZone?: InputMaybe<BurningActivityRainfallZone>;
  season?: InputMaybe<BurningActivitySeason>;
  vegetationClass?: InputMaybe<BurningActivityVegetationClass>;
  yearsSinceLastFire?: InputMaybe<Scalars['Decimal']['input']>;
};

export type CreateBusinessActivityInput = {
  createdByUserId: Scalars['ID']['input'];
  enabledActivityTypes?: InputMaybe<Array<EnabledActivityTypes>>;
  ownedByTeamId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
  propertyNorthOfTropicOfCapricorn: Scalars['Boolean']['input'];
  rainfallAbove600: Scalars['Boolean']['input'];
  state: StateRegion;
};

export type CreateCarbamateInput = {
  activeIngredientPercentage?: InputMaybe<Scalars['Percent']['input']>;
  amountPerHectareGrams: Scalars['Int']['input'];
  applicationAreaGeometry?: InputMaybe<Scalars['GeoJSONMultiPolygon']['input']>;
  applicationAreaSquareMetres?: InputMaybe<Scalars['Int']['input']>;
  applicationDate?: InputMaybe<Scalars['Date']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  product: CarbamateProduct;
  type: CarbamateType;
};

export type CreateConsumptionInput = {
  /** electricity = Wh, feed = kg, fuel = ml, refrigerant = kg */
  amount: Scalars['Int']['input'];
  consumableSubType: ConsumableSubType;
  consumableType: ConsumableType;
  consumptionAllocations?: InputMaybe<Array<ConsumptionAllocationInput>>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type CreateCottonActivityInput = {
  createActivityInput: CreateActivityInput;
};

export type CreateCropVarietyInput = {
  species: CropSpecies;
  yieldInKg: Scalars['Int']['input'];
};

export type CreateCroppingActivityInput = {
  createActivityInput: CreateActivityInput;
  croppingType: CroppingType;
};

export type CreateDairyLivestockActivityInput = {
  createActivityInput: CreateActivityInput;
};

export type CreateDairyMonthLivestockActivityInput = {
  averageLiveweightKg: Scalars['Int']['input'];
  crudeProteinPercentage: Scalars['Percent']['input'];
  dairyClass: DairyClass;
  dairyLivestockActivityId: Scalars['ID']['input'];
  dryMatterDigestibilityPercentage: Scalars['Percent']['input'];
  headAtStart: Scalars['Int']['input'];
  keyEvents?: InputMaybe<Scalars['String']['input']>;
  litresPerHeadPerDay: Scalars['Decimal']['input'];
  month: Month;
  nonSalePopulationChange: Scalars['Int']['input'];
  previousMonthLiveweightKg?: InputMaybe<Scalars['Int']['input']>;
  year: Scalars['Int']['input'];
};

export type CreateDeerLivestockActivityInput = {
  createActivityInput: CreateActivityInput;
};

export type CreateDeerMonthLivestockActivityInput = {
  deerClass: DeerClass;
  deerLivestockActivityId: Scalars['ID']['input'];
  headAtStart: Scalars['Int']['input'];
  keyEvents?: InputMaybe<Scalars['String']['input']>;
  livestockCategory: LivestockCategory;
  month: Month;
  nonSalePopulationChange: Scalars['Int']['input'];
  percentageDoesFawning: Scalars['Percent']['input'];
  seasonalFawningRate: Scalars['Percent']['input'];
  year: Scalars['Int']['input'];
};

export type CreateFeedInput = {
  drySeasonMix: Scalars['Boolean']['input'];
  drySeasonMixKg: Scalars['Int']['input'];
  drySeasonMixUreaPercentage: Scalars['Percent']['input'];
  grazingCrops: Scalars['Boolean']['input'];
  mineralBlock: Scalars['Boolean']['input'];
  mineralBlockKg: Scalars['Int']['input'];
  mineralBlockUreaPercentage: Scalars['Percent']['input'];
  pasture: Scalars['Boolean']['input'];
  weanerBlock: Scalars['Boolean']['input'];
  weanerBlockKg: Scalars['Int']['input'];
  weanerBlockUreaPercentage: Scalars['Percent']['input'];
};

export type CreateFeedlotActivityInput = {
  createActivityInput: CreateActivityInput;
};

export type CreateFeedlotFeedBreakdownInput = {
  crudeProtein: Scalars['Percent']['input'];
  dryMatterDigestibility: Scalars['Percent']['input'];
  netEnergyConcentrationMjPerKg: Scalars['Decimal']['input'];
  nitrogenRetention: Scalars['Percent']['input'];
};

export type CreateFeedlotFeedCompositionInput = {
  grainsTotalCellulous: Scalars['Percent']['input'];
  grainsTotalHemicellulous: Scalars['Percent']['input'];
  grainsTotalNitrogen: Scalars['Percent']['input'];
  grainsTotalProportion: Scalars['Percent']['input'];
  grainsTotalSolubleResidue: Scalars['Percent']['input'];
  grassesCellulous: Scalars['Percent']['input'];
  grassesHemicellulous: Scalars['Percent']['input'];
  grassesNitrogen: Scalars['Percent']['input'];
  grassesProportion: Scalars['Percent']['input'];
  grassesSolubleResidue: Scalars['Percent']['input'];
  legumesCellulous: Scalars['Percent']['input'];
  legumesHemicellulous: Scalars['Percent']['input'];
  legumesNitrogen: Scalars['Percent']['input'];
  legumesProportion: Scalars['Percent']['input'];
  legumesSolubleResidue: Scalars['Percent']['input'];
  otherConcentratesCellulous: Scalars['Percent']['input'];
  otherConcentratesHemicellulous: Scalars['Percent']['input'];
  otherConcentratesNitrogen: Scalars['Percent']['input'];
  otherConcentratesProportion: Scalars['Percent']['input'];
  otherConcentratesSolubleResidue: Scalars['Percent']['input'];
};

export type CreateFeedlotGroupInput = {
  averageLiveweightKg: Scalars['Int']['input'];
  lengthOfStayDays: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  numberOfLivestock: Scalars['Int']['input'];
};

export type CreateFeedlotPurchaseInput = {
  beefClass: BeefClass;
  head: Scalars['Int']['input'];
  livestockCategory: LivestockCategory;
  month: Month;
  source: FeedlotPurchaseSource;
  weightPerHeadKg: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type CreateFeedlotSaleInput = {
  beefClass: BeefClass;
  head: Scalars['Int']['input'];
  livestockCategory: LivestockCategory;
  month: Month;
  weightPerHeadKg: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type CreateFertiliserInput = {
  amountPerHectareKg: Scalars['Decimal']['input'];
  applicationAreaGeometry?: InputMaybe<Scalars['GeoJSONMultiPolygon']['input']>;
  applicationAreaSquareMetres?: InputMaybe<Scalars['Int']['input']>;
  applicationDate?: InputMaybe<Scalars['Date']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ratioK: Scalars['Percent']['input'];
  ratioN: Scalars['Percent']['input'];
  ratioP: Scalars['Percent']['input'];
  ratioS: Scalars['Percent']['input'];
  type: FertiliserType;
};

export type CreateFileInput = {
  bytes: Scalars['Int']['input'];
  contentType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  propertyId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
};

export type CreateFisheryBaitPurchaseInput = {
  amountPurchasedTonnes: Scalars['Decimal']['input'];
  baitType: FisheryBaitType;
  emissionsIntensityBaitProduct?: InputMaybe<Scalars['Decimal']['input']>;
  percentageAdditionalIngredients?: InputMaybe<Scalars['Percent']['input']>;
};

export type CreateGoatLivestockActivityInput = {
  createActivityInput: CreateActivityInput;
};

export type CreateGoatMonthLivestockActivityInput = {
  crudeProteinPercentage: Scalars['Percent']['input'];
  dryMatterDigestibilityPercentage: Scalars['Percent']['input'];
  feedAvailabilityTonnesPerHectare: Scalars['Decimal']['input'];
  goatClass: GoatClass;
  goatLivestockActivityId: Scalars['ID']['input'];
  headAtStart: Scalars['Int']['input'];
  keyEvents?: InputMaybe<Scalars['String']['input']>;
  livestockCategory: LivestockCategory;
  month: Month;
  nonSalePopulationChange: Scalars['Int']['input'];
  percentageDoesKidding: Scalars['Percent']['input'];
  seasonalKiddingRate: Scalars['Percent']['input'];
  year: Scalars['Int']['input'];
};

export type CreateGoatShearingEventInput = {
  cleanWoolYieldKg: Scalars['Decimal']['input'];
  date?: InputMaybe<Scalars['Date']['input']>;
  goatClass: GoatClass;
  livestockCategory: LivestockCategory;
  numberGoatShorn: Scalars['Int']['input'];
  woolPerHeadKg: Scalars['Decimal']['input'];
};

/** Job creation input */
export type CreateJobInput = {
  /** The name of the job. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the team the job belongs to. */
  teamId: Scalars['ID']['input'];
};

export type CreateLimeInput = {
  amountPerHectareKg: Scalars['Int']['input'];
  applicationAreaGeometry?: InputMaybe<Scalars['GeoJSONMultiPolygon']['input']>;
  applicationAreaSquareMetres?: InputMaybe<Scalars['Int']['input']>;
  applicationDate?: InputMaybe<Scalars['Date']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type: LimeType;
};

export type CreateLivestockTransactionEventInput = {
  averageWeightKg?: InputMaybe<Scalars['Decimal']['input']>;
  headPurchasedMerino?: InputMaybe<Scalars['Int']['input']>;
  livestockCount: Scalars['Int']['input'];
  totalWeightKg: Scalars['Decimal']['input'];
  transactionRegion?: InputMaybe<TransactionRegion>;
  transactionType: TransactionType;
};

/** Create map shape input */
export type CreateMapShapeInput = {
  /** The shape geometry as a multi-polygon */
  geometry: Scalars['GeoJSONMultiPolygon']['input'];
  /** The name of the shape */
  name: Scalars['String']['input'];
  /** The property ID that the shape belongs to */
  propertyId: Scalars['ID']['input'];
  /** The type of the shape */
  type: MapShapeType;
};

export type CreateOrganisationInput = {
  name: Scalars['String']['input'];
};

/** Input for creating a parcel in a property */
export type CreateParcelInPropertyInput = {
  /** The parcel geometry as a multi-polygon */
  geometry: Scalars['GeoJSONMultiPolygon']['input'];
  /** The name of the parcel within the property */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Unique ID for the parcel */
  parcelId: Scalars['String']['input'];
  /** Unique ID for the property */
  propertyId: Scalars['String']['input'];
};

export type CreatePorkFeedBlendInput = {
  amountPurchasedTonnes: Scalars['Decimal']['input'];
  barley: Scalars['Percent']['input'];
  beetPulp: Scalars['Percent']['input'];
  blendType: PorkFeedBlendType;
  bloodMeal: Scalars['Percent']['input'];
  canolaMeal: Scalars['Percent']['input'];
  emissionsIntensityFeedProduction?: InputMaybe<Scalars['Decimal']['input']>;
  fishMeal: Scalars['Percent']['input'];
  meatMeal: Scalars['Percent']['input'];
  millMix: Scalars['Percent']['input'];
  percentageAdditionalIngredients?: InputMaybe<Scalars['Percent']['input']>;
  soybeanMeal: Scalars['Percent']['input'];
  tallow: Scalars['Percent']['input'];
  wheat: Scalars['Percent']['input'];
  wheatBran: Scalars['Percent']['input'];
  wheyPowder: Scalars['Percent']['input'];
};

export type CreatePorkLivestockActivityInput = {
  createActivityInput: CreateActivityInput;
};

export type CreatePorkMonthLivestockActivityInput = {
  headAtStart: Scalars['Int']['input'];
  keyEvents?: InputMaybe<Scalars['String']['input']>;
  month: Month;
  nonSalePopulationChange: Scalars['Int']['input'];
  porkClass: PorkClass;
  porkLivestockActivityId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
};

/** Create property portfolio input */
export type CreatePortfolioInput = {
  /** The name of the portfolio */
  name: Scalars['String']['input'];
  /** The id of the team the portfolio is associated with */
  teamId: Scalars['ID']['input'];
};

export type CreatePoultryBroilerActivityInput = {
  createActivityInput: CreateActivityInput;
};

export type CreatePoultryGrowerBatchFeedBlendInput = {
  amountPurchasedTonnes: Scalars['Decimal']['input'];
  barleyWhole?: InputMaybe<Scalars['Percent']['input']>;
  blendType: PoultryFeedBlendType;
  emissionsIntensityFeedProduction?: InputMaybe<Scalars['Decimal']['input']>;
  millRun?: InputMaybe<Scalars['Percent']['input']>;
  percentageAdditionalIngredients?: InputMaybe<Scalars['Percent']['input']>;
  sorghum?: InputMaybe<Scalars['Percent']['input']>;
  soybean?: InputMaybe<Scalars['Percent']['input']>;
  wheat?: InputMaybe<Scalars['Percent']['input']>;
};

export type CreatePoultryGrowerBatchInput = {
  endDate: Scalars['Date']['input'];
  name: Scalars['String']['input'];
  startDate: Scalars['Date']['input'];
};

export type CreatePoultryLayerActivityInput = {
  createActivityInput: CreateActivityInput;
};

export type CreatePoultryLayerFeedBlendInput = {
  amountPurchasedTonnes: Scalars['Decimal']['input'];
  barleyWhole?: InputMaybe<Scalars['Percent']['input']>;
  blendType: PoultryFeedBlendType;
  emissionsIntensityFeedProduction?: InputMaybe<Scalars['Percent']['input']>;
  millRun?: InputMaybe<Scalars['Percent']['input']>;
  percentageAdditionalIngredients?: InputMaybe<Scalars['Percent']['input']>;
  sorghum?: InputMaybe<Scalars['Percent']['input']>;
  soybean?: InputMaybe<Scalars['Percent']['input']>;
  wheat?: InputMaybe<Scalars['Percent']['input']>;
};

export type CreatePoultryLayerMonthActivityInput = {
  layerCount: Scalars['Int']['input'];
  meatChickenLayerCount: Scalars['Int']['input'];
  month: Month;
  year: Scalars['Int']['input'];
};

export type CreatePoultryTransactionEventInput = {
  averageWeightKg?: InputMaybe<Scalars['Decimal']['input']>;
  class: PoultryTransactionClass;
  date: Scalars['Date']['input'];
  livestockCount: Scalars['Int']['input'];
  percentPurchasedFreeRange?: InputMaybe<Scalars['Percent']['input']>;
  totalWeightKg: Scalars['Decimal']['input'];
  transactionRegion?: InputMaybe<TransactionRegion>;
  transactionType: TransactionType;
};

/** Input for adding a property to a job while creating the property. */
export type CreatePropertyAndAddToJobInput = {
  /** The job ID. */
  jobId: Scalars['ID']['input'];
  /** The name of the property inside the job. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Create property input. */
  property: CreatePropertyInput;
};

/** Input required to create a new property */
export type CreatePropertyInput = {
  /** The address line 1 of the property */
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  /** The address line 2 of the property */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** The city the property is in */
  city?: InputMaybe<Scalars['String']['input']>;
  /** The country the property is in */
  country?: InputMaybe<Scalars['String']['input']>;
  /** The country code of the property */
  countryCode?: InputMaybe<Scalars['String']['input']>;
  /** Optionally provide an ID for the property (UUIDV4) */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The name of the property */
  name: Scalars['String']['input'];
  /** The postcode of the property */
  postcode?: InputMaybe<Scalars['String']['input']>;
  /** The ParcelInProperty objects associated with the property */
  propertyParcels?: InputMaybe<Array<InputMaybe<AddParcelToPropertyInput>>>;
  /** The state of Australia the property is in */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The ID for the team the property belongs to. */
  teamId: Scalars['ID']['input'];
};

/** Create property note input */
export type CreatePropertyNoteInput = {
  /** The id of the property the note is associated with */
  propertyId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
  /** The text content of the note */
  text?: InputMaybe<Scalars['String']['input']>;
  /** The title of the note */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Input to create a new report */
export type CreateReportInput = {
  /** Report name */
  name: Scalars['String']['input'];
  /** The ID of the property the report is for. */
  propertyId: Scalars['ID']['input'];
  /** Enabled report sections */
  sections: Array<ReportSection>;
  /** The ID of the team the report belongs to. */
  teamId: Scalars['ID']['input'];
  /** Report type */
  type: ReportType;
};

export type CreateRouteInput = {
  distanceStartToEndKm?: InputMaybe<Scalars['Decimal']['input']>;
  endPoint: Scalars['String']['input'];
  fuelType?: InputMaybe<RouteFuelType>;
  numberOfTrips?: InputMaybe<Scalars['Int']['input']>;
  startPoint: Scalars['String']['input'];
  totalDistanceTravelledKm: Scalars['Decimal']['input'];
  vehicleType: RouteVehicleType;
};

export type CreateSheepLivestockActivityInput = {
  createActivityInput: CreateActivityInput;
};

export type CreateSheepMonthLivestockActivityInput = {
  averageLiveweightKg: Scalars['Int']['input'];
  crudeProteinPercentage: Scalars['Percent']['input'];
  dryMatterDigestibilityPercentage: Scalars['Percent']['input'];
  feedAvailabilityTonnesPerHectare: Scalars['Decimal']['input'];
  headAtStart: Scalars['Int']['input'];
  keyEvents?: InputMaybe<Scalars['String']['input']>;
  livestockCategory: LivestockCategory;
  month: Month;
  nonSalePopulationChange: Scalars['Int']['input'];
  percentageEwesLambing: Scalars['Percent']['input'];
  previousMonthLiveweightKg?: InputMaybe<Scalars['Int']['input']>;
  seasonalLambingRate: Scalars['Percent']['input'];
  sheepClass: SheepClass;
  sheepLivestockActivityId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
};

export type CreateSheepShearingEventInput = {
  cleanWoolYieldKg: Scalars['Decimal']['input'];
  date?: InputMaybe<Scalars['Date']['input']>;
  livestockCategory: LivestockCategory;
  numberSheepShorn: Scalars['Int']['input'];
  sheepClass: SheepClass;
  woolPerHeadKg: Scalars['Decimal']['input'];
};

export type CreateTeamInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  organisationId: Scalars['ID']['input'];
  status: Scalars['String']['input'];
};

export type CreateVegetationActivityInput = {
  createActivityInput: CreateActivityInput;
};

export type CreateWildSeaFisheryActivityInput = {
  createActivityInput: CreateActivityInput;
  offsetsPurchasedTonnes?: InputMaybe<Scalars['Decimal']['input']>;
  productionSystem?: InputMaybe<WildSeaFisheryProductionSystem>;
  totalWeightCaughtKg?: InputMaybe<Scalars['Decimal']['input']>;
};

export enum CropSpecies {
  AnnualGrass = 'ANNUAL_GRASS',
  AnnualHorticulture = 'ANNUAL_HORTICULTURE',
  Barley = 'BARLEY',
  ForageCrops = 'FORAGE_CROPS',
  GrassCloverMixture = 'GRASS_CLOVER_MIXTURE',
  Hops = 'HOPS',
  Lucerne = 'LUCERNE',
  Maize = 'MAIZE',
  Oats = 'OATS',
  Oilseeds = 'OILSEEDS',
  OtherCereals = 'OTHER_CEREALS',
  OtherLegume = 'OTHER_LEGUME',
  Peanuts = 'PEANUTS',
  PerennialHorticulture = 'PERENNIAL_HORTICULTURE',
  PerennialPasture = 'PERENNIAL_PASTURE',
  Pulses = 'PULSES',
  Rice = 'RICE',
  Sorghum = 'SORGHUM',
  SugarCane = 'SUGAR_CANE',
  Triticale = 'TRITICALE',
  TuberAndRoots = 'TUBER_AND_ROOTS',
  Wheat = 'WHEAT',
}

export type CropVariety = {
  __typename: 'CropVariety';
  id: Scalars['ID']['output'];
  species: CropSpecies;
  yieldInKg: Scalars['Int']['output'];
};

export type CroppingActivity = Activity & {
  __typename: 'CroppingActivity';
  businessActivity: BusinessActivity;
  businessActivityId: Scalars['ID']['output'];
  croppingType: CroppingType;
  end: Scalars['Date']['output'];
  fertiliser: Array<Fertiliser>;
  fungicide: Array<Carbamate>;
  herbicide: Array<Carbamate>;
  id: Scalars['ID']['output'];
  irrigated: Scalars['Boolean']['output'];
  lime: Array<Lime>;
  locations?: Maybe<Array<Maybe<ActivityLocation>>>;
  name: Scalars['String']['output'];
  nitrificationInhibitorUsed?: Maybe<Scalars['Boolean']['output']>;
  percentageBurned?: Maybe<Scalars['Int']['output']>;
  pesticide: Array<Carbamate>;
  plantingAreaGeometry?: Maybe<Scalars['GeoJSONMultiPolygon']['output']>;
  plantingAreaSquareMetres: Scalars['Int']['output'];
  residueBurned: Scalars['Boolean']['output'];
  start: Scalars['Date']['output'];
  targetAllocations?: Maybe<Array<Maybe<ActivityAllocation>>>;
  ureaseInhibitorUsed?: Maybe<Scalars['Boolean']['output']>;
  varieties: Array<CropVariety>;
};

export enum CroppingType {
  Cotton = 'COTTON',
  Cropping = 'CROPPING',
  Horticulture = 'HORTICULTURE',
  Pasture = 'PASTURE',
  Sugar = 'SUGAR',
}

export enum DairyClass {
  DairyBullsGt_1 = 'DAIRY_BULLS_GT_1',
  DairyBullsLt_1 = 'DAIRY_BULLS_LT_1',
  HeifersGt_1 = 'HEIFERS_GT_1',
  HeifersLt_1 = 'HEIFERS_LT_1',
  MilkingCows = 'MILKING_COWS',
}

export type DairyEmissionsCalculation = {
  __typename: 'DairyEmissionsCalculation';
  inputs: EmissionsCalculationInputs;
  result: DairyEmissionsCalculationResult;
};

export type DairyEmissionsCalculationResult = {
  __typename: 'DairyEmissionsCalculationResult';
  carbonSequestration?: Maybe<DairyEmissionsCarbonSequestration>;
  intensities?: Maybe<DairyEmissionsIntensities>;
  net?: Maybe<DairyEmissionsNet>;
  scope1?: Maybe<DairyEmissionsScope1>;
  scope2?: Maybe<DairyEmissionsScope2>;
  scope3?: Maybe<DairyEmissionsScope3>;
};

export type DairyEmissionsCarbonSequestration = {
  __typename: 'DairyEmissionsCarbonSequestration';
  intermediate?: Maybe<DairyEmissionsTotalAverage>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type DairyEmissionsIntensities = {
  __typename: 'DairyEmissionsIntensities';
  intensity?: Maybe<Scalars['Float']['output']>;
};

export type DairyEmissionsNet = {
  __typename: 'DairyEmissionsNet';
  total?: Maybe<Scalars['Float']['output']>;
};

export type DairyEmissionsScope1 = {
  __typename: 'DairyEmissionsScope1';
  animalWasteN2O?: Maybe<Scalars['Float']['output']>;
  atmosphericDepositionN2O?: Maybe<Scalars['Float']['output']>;
  entericCH4?: Maybe<Scalars['Float']['output']>;
  fertiliserN2O?: Maybe<Scalars['Float']['output']>;
  fuelCH4?: Maybe<Scalars['Float']['output']>;
  fuelCO2?: Maybe<Scalars['Float']['output']>;
  fuelN2O?: Maybe<Scalars['Float']['output']>;
  leachingAndRunoffN2O?: Maybe<Scalars['Float']['output']>;
  limeCO2?: Maybe<Scalars['Float']['output']>;
  manureManagementCH4?: Maybe<Scalars['Float']['output']>;
  manureManagementN2O?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  totalCH4?: Maybe<Scalars['Float']['output']>;
  totalCO2?: Maybe<Scalars['Float']['output']>;
  totalN2O?: Maybe<Scalars['Float']['output']>;
  transportCH4?: Maybe<Scalars['Float']['output']>;
  transportCO2?: Maybe<Scalars['Float']['output']>;
  transportN2O?: Maybe<Scalars['Float']['output']>;
  ureaCO2?: Maybe<Scalars['Float']['output']>;
  urineAndDungN2O?: Maybe<Scalars['Float']['output']>;
};

export type DairyEmissionsScope2 = {
  __typename: 'DairyEmissionsScope2';
  electricity?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type DairyEmissionsScope3 = {
  __typename: 'DairyEmissionsScope3';
  electricity?: Maybe<Scalars['Float']['output']>;
  fertiliser?: Maybe<Scalars['Float']['output']>;
  fuel?: Maybe<Scalars['Float']['output']>;
  herbicide?: Maybe<Scalars['Float']['output']>;
  lime?: Maybe<Scalars['Float']['output']>;
  purchasedFeed?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type DairyEmissionsTotalAverage = {
  __typename: 'DairyEmissionsTotalAverage';
  average?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type DairyLivestockActivity = Activity &
  LivestockActivity & {
    __typename: 'DairyLivestockActivity';
    businessActivity: BusinessActivity;
    businessActivityId: Scalars['ID']['output'];
    dairyBullsGt1?: Maybe<Scalars['Boolean']['output']>;
    dairyBullsLt1?: Maybe<Scalars['Boolean']['output']>;
    distanceTravelledBetweenFarmsKm?: Maybe<Scalars['Decimal']['output']>;
    emissionsRedMeatPercentage?: Maybe<Scalars['Percent']['output']>;
    end: Scalars['Date']['output'];
    feed?: Maybe<Feed>;
    feedId?: Maybe<Scalars['ID']['output']>;
    heifersGt1?: Maybe<Scalars['Boolean']['output']>;
    heifersLt1?: Maybe<Scalars['Boolean']['output']>;
    id: Scalars['ID']['output'];
    locations?: Maybe<Array<Maybe<ActivityLocation>>>;
    manureMilkingLagoonPercentage?: Maybe<Scalars['Percent']['output']>;
    manureMilkingPaddocksPercentage?: Maybe<Scalars['Percent']['output']>;
    manureMilkingPasturePercentage?: Maybe<Scalars['Percent']['output']>;
    manureMilkingSolidStoragePercentage?: Maybe<Scalars['Percent']['output']>;
    manureMilkingSumpDispersalPercentage?: Maybe<Scalars['Percent']['output']>;
    manureOtherLagoonPercentage?: Maybe<Scalars['Percent']['output']>;
    manureOtherPaddocksPercentage?: Maybe<Scalars['Percent']['output']>;
    manureOtherPasturePercentage?: Maybe<Scalars['Percent']['output']>;
    manureOtherSolidStoragePercentage?: Maybe<Scalars['Percent']['output']>;
    manureOtherSumpDispersalPercentage?: Maybe<Scalars['Percent']['output']>;
    milkingCows?: Maybe<Scalars['Boolean']['output']>;
    name: Scalars['String']['output'];
    productionSystem?: Maybe<DairyProductionSystem>;
    start: Scalars['Date']['output'];
    targetAllocations?: Maybe<Array<Maybe<ActivityAllocation>>>;
    truckType?: Maybe<TruckType>;
  };

export type DairyMonthLivestockActivity = {
  __typename: 'DairyMonthLivestockActivity';
  averageLiveweightKg: Scalars['Int']['output'];
  crudeProteinPercentage: Scalars['Percent']['output'];
  dairyClass: DairyClass;
  dairyLivestockActivityId: Scalars['ID']['output'];
  dryMatterDigestibilityPercentage: Scalars['Percent']['output'];
  headAtStart: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  keyEvents?: Maybe<Scalars['String']['output']>;
  litresPerHeadPerDay: Scalars['Decimal']['output'];
  livestockTransactionEvents: Array<LivestockTransactionEvent>;
  month: Month;
  nonSalePopulationChange: Scalars['Int']['output'];
  previousMonthLiveweightKg?: Maybe<Scalars['Int']['output']>;
  year: Scalars['Int']['output'];
};

export enum DairyProductionSystem {
  IrrigatedCrop = 'IRRIGATED_CROP',
  IrrigatedPasture = 'IRRIGATED_PASTURE',
  NonIrrigatedCrop = 'NON_IRRIGATED_CROP',
  NonIrrigatedPasture = 'NON_IRRIGATED_PASTURE',
}

export enum DeerClass {
  BreedingDoes = 'BREEDING_DOES',
  Bucks = 'BUCKS',
  Fawn = 'FAWN',
  OtherDoes = 'OTHER_DOES',
}

export type DeerEmissionsCalculation = {
  __typename: 'DeerEmissionsCalculation';
  inputs: EmissionsCalculationInputs;
  result: DeerEmissionsCalculationResult;
};

export type DeerEmissionsCalculationResult = {
  __typename: 'DeerEmissionsCalculationResult';
  carbonSequestration?: Maybe<DeerEmissionsCarbonSequestration>;
  intensities?: Maybe<DeerEmissionsIntensities>;
  net?: Maybe<DeerEmissionsNet>;
  scope1?: Maybe<DeerEmissionsScope1>;
  scope2?: Maybe<DeerEmissionsScope2>;
  scope3?: Maybe<DeerEmissionsScope3>;
};

export type DeerEmissionsCarbonSequestration = {
  __typename: 'DeerEmissionsCarbonSequestration';
  intermediate?: Maybe<DeerEmissionsTotalAverage>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type DeerEmissionsIntensities = {
  __typename: 'DeerEmissionsIntensities';
  deerMeatExcludingSequestration?: Maybe<Scalars['Float']['output']>;
  deerMeatIncludingSequestration?: Maybe<Scalars['Float']['output']>;
};

export type DeerEmissionsNet = {
  __typename: 'DeerEmissionsNet';
  total?: Maybe<Scalars['Float']['output']>;
};

export type DeerEmissionsScope1 = {
  __typename: 'DeerEmissionsScope1';
  animalWasteN2O?: Maybe<Scalars['Float']['output']>;
  atmosphericDepositionN2O?: Maybe<Scalars['Float']['output']>;
  entericCH4?: Maybe<Scalars['Float']['output']>;
  fertiliserN2O?: Maybe<Scalars['Float']['output']>;
  fuelCH4?: Maybe<Scalars['Float']['output']>;
  fuelCO2?: Maybe<Scalars['Float']['output']>;
  fuelN2O?: Maybe<Scalars['Float']['output']>;
  leachingAndRunoffN2O?: Maybe<Scalars['Float']['output']>;
  limeCO2?: Maybe<Scalars['Float']['output']>;
  manureManagementCH4?: Maybe<Scalars['Float']['output']>;
  manureManagementN2O?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  totalCH4?: Maybe<Scalars['Float']['output']>;
  totalCO2?: Maybe<Scalars['Float']['output']>;
  totalN2O?: Maybe<Scalars['Float']['output']>;
  transportCH4?: Maybe<Scalars['Float']['output']>;
  transportCO2?: Maybe<Scalars['Float']['output']>;
  transportN2O?: Maybe<Scalars['Float']['output']>;
  ureaCO2?: Maybe<Scalars['Float']['output']>;
  urineAndDungN2O?: Maybe<Scalars['Float']['output']>;
};

export type DeerEmissionsScope2 = {
  __typename: 'DeerEmissionsScope2';
  electricity?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type DeerEmissionsScope3 = {
  __typename: 'DeerEmissionsScope3';
  electricity?: Maybe<Scalars['Float']['output']>;
  fertiliser?: Maybe<Scalars['Float']['output']>;
  fuel?: Maybe<Scalars['Float']['output']>;
  herbicide?: Maybe<Scalars['Float']['output']>;
  lime?: Maybe<Scalars['Float']['output']>;
  purchasedFeed?: Maybe<Scalars['Float']['output']>;
  purchasedLivestock?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type DeerEmissionsTotalAverage = {
  __typename: 'DeerEmissionsTotalAverage';
  average?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type DeerLivestockActivity = Activity &
  LivestockActivity & {
    __typename: 'DeerLivestockActivity';
    breedingDoesBreeding: Scalars['Boolean']['output'];
    breedingDoesTrading: Scalars['Boolean']['output'];
    bucksBreeding: Scalars['Boolean']['output'];
    bucksTrading: Scalars['Boolean']['output'];
    businessActivity: BusinessActivity;
    businessActivityId: Scalars['ID']['output'];
    end: Scalars['Date']['output'];
    fawnBreeding: Scalars['Boolean']['output'];
    fawnTrading: Scalars['Boolean']['output'];
    feed?: Maybe<Feed>;
    feedId?: Maybe<Scalars['ID']['output']>;
    id: Scalars['ID']['output'];
    locations?: Maybe<Array<Maybe<ActivityLocation>>>;
    name: Scalars['String']['output'];
    otherDoesBreeding: Scalars['Boolean']['output'];
    otherDoesTrading: Scalars['Boolean']['output'];
    start: Scalars['Date']['output'];
    targetAllocations?: Maybe<Array<Maybe<ActivityAllocation>>>;
  };

export type DeerMonthLivestockActivity = {
  __typename: 'DeerMonthLivestockActivity';
  deerClass: DeerClass;
  deerLivestockActivityId: Scalars['ID']['output'];
  headAtStart: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  keyEvents?: Maybe<Scalars['String']['output']>;
  livestockCategory: LivestockCategory;
  livestockTransactionEvents: Array<LivestockTransactionEvent>;
  month: Month;
  nonSalePopulationChange: Scalars['Int']['output'];
  percentageDoesFawning: Scalars['Percent']['output'];
  seasonalFawningRate: Scalars['Percent']['output'];
  year: Scalars['Int']['output'];
};

export type EmissionsCalculationInputs = {
  __typename: 'EmissionsCalculationInputs';
  messages: Array<EmissionsCalculationInputsMessage>;
  primaryActivities: Array<InputActivityWithCropping>;
  requests: Array<CalculationRequest>;
};

export type EmissionsCalculationInputsMessage = {
  __typename: 'EmissionsCalculationInputsMessage';
  activityId?: Maybe<Scalars['ID']['output']>;
  level: EmissionsCalculationInputsMessageLevel;
  message: Scalars['String']['output'];
};

export enum EmissionsCalculationInputsMessageLevel {
  Error = 'ERROR',
  Info = 'INFO',
  Warning = 'WARNING',
}

/** Filters for emission calculations */
export type EmissionsFilterInput = {
  /** The date to include data to */
  endDate?: InputMaybe<Scalars['Date']['input']>;
  /** The date to include data from */
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type EnabledActivities = {
  __typename: 'EnabledActivities';
  beefPasture: Scalars['Boolean']['output'];
  buffalo: Scalars['Boolean']['output'];
  burning: Scalars['Boolean']['output'];
  croppingCotton: Scalars['Boolean']['output'];
  croppingGrains: Scalars['Boolean']['output'];
  croppingHorticulture: Scalars['Boolean']['output'];
  croppingPasture: Scalars['Boolean']['output'];
  croppingSugar: Scalars['Boolean']['output'];
  dairy: Scalars['Boolean']['output'];
  deer: Scalars['Boolean']['output'];
  feedlot: Scalars['Boolean']['output'];
  goat: Scalars['Boolean']['output'];
  pork: Scalars['Boolean']['output'];
  poultryBroiler: Scalars['Boolean']['output'];
  poultryLayer: Scalars['Boolean']['output'];
  sheep: Scalars['Boolean']['output'];
  vegetation: Scalars['Boolean']['output'];
  wildSeaFishery: Scalars['Boolean']['output'];
};

export enum EnabledActivityTypes {
  BeefPasture = 'beefPasture',
  Buffalo = 'buffalo',
  Burning = 'burning',
  CroppingCotton = 'croppingCotton',
  CroppingGrains = 'croppingGrains',
  CroppingHorticulture = 'croppingHorticulture',
  CroppingPasture = 'croppingPasture',
  CroppingSugar = 'croppingSugar',
  Dairy = 'dairy',
  Deer = 'deer',
  Feedlot = 'feedlot',
  Goat = 'goat',
  Pork = 'pork',
  PoultryBroiler = 'poultryBroiler',
  PoultryLayer = 'poultryLayer',
  Sheep = 'sheep',
  Vegetation = 'vegetation',
  WildSeaFishery = 'wildSeaFishery',
}

/** A GeoJSON Feature object */
export type Feature = {
  /** The geometry of the feature */
  geometry: Scalars['GeoJSONGeometryInterface']['output'];
  /** The unique identifier of the feature */
  id: Scalars['ID']['output'];
  /** The properties of the feature */
  properties?: Maybe<FeatureProperties>;
  /** Always 'Feature' */
  type: FeatureType;
};

/** GeoJSON FeatureCollection object */
export type FeatureCollection = {
  /** The features of the collection */
  features: Array<Feature>;
  /** Always 'FeatureCollection' */
  type: FeatureCollectionType;
};

export enum FeatureCollectionType {
  FeatureCollection = 'FeatureCollection',
}

/** GeoJSON Feature Properties object */
export type FeatureProperties = {
  id?: Maybe<Scalars['ID']['output']>;
};

export enum FeatureType {
  Feature = 'Feature',
}

export type Feed = {
  __typename: 'Feed';
  drySeasonMix: Scalars['Boolean']['output'];
  drySeasonMixKg: Scalars['Int']['output'];
  drySeasonMixUreaPercentage: Scalars['Percent']['output'];
  grazingCrops: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  mineralBlock: Scalars['Boolean']['output'];
  mineralBlockKg: Scalars['Int']['output'];
  mineralBlockUreaPercentage: Scalars['Percent']['output'];
  pasture: Scalars['Boolean']['output'];
  weanerBlock: Scalars['Boolean']['output'];
  weanerBlockKg: Scalars['Int']['output'];
  weanerBlockUreaPercentage: Scalars['Percent']['output'];
};

export type FeedlotActivity = Activity & {
  __typename: 'FeedlotActivity';
  businessActivity: BusinessActivity;
  businessActivityId: Scalars['ID']['output'];
  end: Scalars['Date']['output'];
  feedComposition?: Maybe<FeedlotFeedComposition>;
  grazingCrops?: Maybe<Scalars['Boolean']['output']>;
  groups?: Maybe<Array<FeedlotGroup>>;
  id: Scalars['ID']['output'];
  locations?: Maybe<Array<Maybe<ActivityLocation>>>;
  name: Scalars['String']['output'];
  pasture?: Maybe<Scalars['Boolean']['output']>;
  purchases?: Maybe<Array<FeedlotPurchase>>;
  sales?: Maybe<Array<FeedlotSale>>;
  start: Scalars['Date']['output'];
  system?: Maybe<FeedlotSystem>;
  targetAllocations?: Maybe<Array<Maybe<ActivityAllocation>>>;
  totalDistanceTravelledKm?: Maybe<Scalars['Int']['output']>;
  truckType?: Maybe<TruckType>;
};

export type FeedlotEmissionsCalculation = {
  __typename: 'FeedlotEmissionsCalculation';
  inputs: EmissionsCalculationInputs;
  result: FeedlotEmissionsCalculationResult;
};

export type FeedlotEmissionsCalculationResult = {
  __typename: 'FeedlotEmissionsCalculationResult';
  carbonSequestration: FeedlotEmissionsCarbonSequestration;
  intensities: FeedlotEmissionsIntensities;
  intermediate: Array<FeedlotEmissionsIntermediateScopes>;
  net: FeedlotEmissionsNet;
  scope1: FeedlotEmissionsScope1;
  scope2: FeedlotEmissionsScope2;
  scope3: FeedlotEmissionsScope3;
};

export type FeedlotEmissionsCarbonSequestration = {
  __typename: 'FeedlotEmissionsCarbonSequestration';
  intermediate: Array<Scalars['Float']['output']>;
  total: Scalars['Float']['output'];
};

export type FeedlotEmissionsIntensities = {
  __typename: 'FeedlotEmissionsIntensities';
  beefExcludingSequestration: Scalars['Float']['output'];
  beefIncludingSequestration: Scalars['Float']['output'];
};

export type FeedlotEmissionsIntermediateScopes = {
  __typename: 'FeedlotEmissionsIntermediateScopes';
  carbonSequestration: Scalars['Float']['output'];
  scope1: FeedlotEmissionsScope1Intermediate;
};

export type FeedlotEmissionsNet = {
  __typename: 'FeedlotEmissionsNet';
  total: Scalars['Float']['output'];
};

export type FeedlotEmissionsScope1 = {
  __typename: 'FeedlotEmissionsScope1';
  atmosphericDepositionN2O: Scalars['Float']['output'];
  entericCH4: Scalars['Float']['output'];
  fuelCH4: Scalars['Float']['output'];
  fuelCO2: Scalars['Float']['output'];
  fuelN2O: Scalars['Float']['output'];
  limeCO2: Scalars['Float']['output'];
  manureAppliedToSoilN2O: Scalars['Float']['output'];
  manureDirectN2O: Scalars['Float']['output'];
  manureIndirectN2O: Scalars['Float']['output'];
  manureManagementCH4: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
  totalCH4: Scalars['Float']['output'];
  totalCO2: Scalars['Float']['output'];
  totalN2O: Scalars['Float']['output'];
  transportCH4: Scalars['Float']['output'];
  transportCO2: Scalars['Float']['output'];
  transportN2O: Scalars['Float']['output'];
  ureaCO2: Scalars['Float']['output'];
};

export type FeedlotEmissionsScope1Intermediate = {
  __typename: 'FeedlotEmissionsScope1Intermediate';
  atmosphericDepositionN2O: Scalars['Float']['output'];
  entericCH4: Scalars['Float']['output'];
  manureAppliedToSoilN2O: Scalars['Float']['output'];
  manureDirectN2O: Scalars['Float']['output'];
  manureIndirectN2O: Scalars['Float']['output'];
  manureManagementCH4: Scalars['Float']['output'];
};

export type FeedlotEmissionsScope2 = {
  __typename: 'FeedlotEmissionsScope2';
  electricity: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type FeedlotEmissionsScope3 = {
  __typename: 'FeedlotEmissionsScope3';
  electricity: Scalars['Float']['output'];
  feed: Scalars['Float']['output'];
  fertiliser: Scalars['Float']['output'];
  fuel: Scalars['Float']['output'];
  herbicide: Scalars['Float']['output'];
  lime: Scalars['Float']['output'];
  purchaseLivestock: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type FeedlotFeedBreakdown = {
  __typename: 'FeedlotFeedBreakdown';
  crudeProtein: Scalars['Percent']['output'];
  dryMatterDigestibility: Scalars['Percent']['output'];
  id: Scalars['ID']['output'];
  netEnergyConcentrationMjPerKg: Scalars['Decimal']['output'];
  nitrogenRetention: Scalars['Percent']['output'];
};

export type FeedlotFeedComposition = {
  __typename: 'FeedlotFeedComposition';
  grainsTotalCellulous: Scalars['Percent']['output'];
  grainsTotalHemicellulous: Scalars['Percent']['output'];
  grainsTotalNitrogen: Scalars['Percent']['output'];
  grainsTotalProportion: Scalars['Percent']['output'];
  grainsTotalSolubleResidue: Scalars['Percent']['output'];
  grassesCellulous: Scalars['Percent']['output'];
  grassesHemicellulous: Scalars['Percent']['output'];
  grassesNitrogen: Scalars['Percent']['output'];
  grassesProportion: Scalars['Percent']['output'];
  grassesSolubleResidue: Scalars['Percent']['output'];
  id: Scalars['ID']['output'];
  legumesCellulous: Scalars['Percent']['output'];
  legumesHemicellulous: Scalars['Percent']['output'];
  legumesNitrogen: Scalars['Percent']['output'];
  legumesProportion: Scalars['Percent']['output'];
  legumesSolubleResidue: Scalars['Percent']['output'];
  otherConcentratesCellulous: Scalars['Percent']['output'];
  otherConcentratesHemicellulous: Scalars['Percent']['output'];
  otherConcentratesNitrogen: Scalars['Percent']['output'];
  otherConcentratesProportion: Scalars['Percent']['output'];
  otherConcentratesSolubleResidue: Scalars['Percent']['output'];
};

export type FeedlotGroup = {
  __typename: 'FeedlotGroup';
  averageLiveweightKg: Scalars['Int']['output'];
  feedBreakdown?: Maybe<FeedlotFeedBreakdown>;
  id: Scalars['ID']['output'];
  lengthOfStayDays: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  numberOfLivestock: Scalars['Int']['output'];
};

export type FeedlotPurchase = {
  __typename: 'FeedlotPurchase';
  beefClass: BeefClass;
  head: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  livestockCategory: LivestockCategory;
  month: Month;
  source: FeedlotPurchaseSource;
  weightPerHeadKg: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export enum FeedlotPurchaseSource {
  NswSaPastoralZone = 'NSW_SA_PASTORAL_ZONE',
  Nt = 'NT',
  NthNsw = 'NTH_NSW',
  NthQld = 'NTH_QLD',
  SthCentralQld = 'STH_CENTRAL_QLD',
  SthNswVicSthSa = 'STH_NSW_VIC_STH_SA',
  SwWa = 'SW_WA',
  Tas = 'TAS',
  WaPastoral = 'WA_PASTORAL',
}

export type FeedlotSale = {
  __typename: 'FeedlotSale';
  beefClass: BeefClass;
  head: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  livestockCategory: LivestockCategory;
  month: Month;
  weightPerHeadKg: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export enum FeedlotSystem {
  Composting = 'COMPOSTING',
  Drylot = 'DRYLOT',
  SolidStorage = 'SOLID_STORAGE',
  UncoveredAnaerobicLagoon = 'UNCOVERED_ANAEROBIC_LAGOON',
}

export type Fertiliser = {
  __typename: 'Fertiliser';
  amountPerHectareKg: Scalars['Decimal']['output'];
  applicationAreaGeometry?: Maybe<Scalars['GeoJSONMultiPolygon']['output']>;
  applicationAreaSquareMetres?: Maybe<Scalars['Int']['output']>;
  applicationDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  ratioK: Scalars['Percent']['output'];
  ratioN: Scalars['Percent']['output'];
  ratioP: Scalars['Percent']['output'];
  ratioS: Scalars['Percent']['output'];
  type: FertiliserType;
};

export enum FertiliserType {
  AmmoniumNitrate = 'AMMONIUM_NITRATE',
  CalciumAmmoniumNitrate = 'CALCIUM_AMMONIUM_NITRATE',
  Custom = 'CUSTOM',
  DiammoniumPhosphate = 'DIAMMONIUM_PHOSPHATE',
  MonoammoniumPhosphate = 'MONOAMMONIUM_PHOSPHATE',
  MuriateOfPotash = 'MURIATE_OF_POTASH',
  SingleSuperphosphate = 'SINGLE_SUPERPHOSPHATE',
  SulphateOfAmmonia = 'SULPHATE_OF_AMMONIA',
  SulphateOfPotash = 'SULPHATE_OF_POTASH',
  SuperPotash_1_1 = 'SUPER_POTASH_1_1',
  SuperPotash_2_1 = 'SUPER_POTASH_2_1',
  SuperPotash_3_1 = 'SUPER_POTASH_3_1',
  SuperPotash_4_1 = 'SUPER_POTASH_4_1',
  SuperPotash_5_1 = 'SUPER_POTASH_5_1',
  TripleSuperphosphate = 'TRIPLE_SUPERPHOSPHATE',
  Urea = 'UREA',
  UreaAmmoniumNitrate = 'UREA_AMMONIUM_NITRATE',
}

export type File = {
  __typename: 'File';
  /** The size of the file in bytes */
  bytes: Scalars['Int']['output'];
  /** The date the file was created */
  createdAt: Scalars['DateTime']['output'];
  /** The id of the user that created the file */
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  /** The user who originally uploaded the file */
  creator?: Maybe<User>;
  /** The description of the file */
  description?: Maybe<Scalars['String']['output']>;
  /** The extension of the file */
  extension: Scalars['String']['output'];
  /** The date the file was last modified */
  fileModifiedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The height of the file */
  height?: Maybe<Scalars['Int']['output']>;
  /** The id of the file */
  id: Scalars['ID']['output'];
  /** The id of the job that created the file */
  jobId?: Maybe<Scalars['ID']['output']>;
  /** The metadata of the file */
  metadata?: Maybe<Scalars['JSON']['output']>;
  /** The mime type of the file */
  mimeType: Scalars['String']['output'];
  /** The name of the file */
  name?: Maybe<Scalars['String']['output']>;
  /** The path of the file */
  path: Scalars['String']['output'];
  /** The id of the property that created the file */
  propertyId?: Maybe<Scalars['ID']['output']>;
  /** The publicly accessible url of the file */
  signedUrl: Scalars['String']['output'];
  /** The id of the team that created the file */
  teamId?: Maybe<Scalars['ID']['output']>;
  /** The type of the file */
  type: FileType;
  /** The date the file was last updated */
  updatedAt: Scalars['DateTime']['output'];
  /** The current known status of whether the file has actually been uploaded */
  uploadStatus: FileUploadStatus;
  /** The width of the file */
  width?: Maybe<Scalars['Int']['output']>;
};

export type FileForUpload = {
  __typename: 'FileForUpload';
  file: File;
  presignedPostFields: Array<KeyValuePair>;
  presignedPostUrl: Scalars['String']['output'];
};

export type FileOnInspection = {
  __typename: 'FileOnInspection';
  /** The file category */
  category: InspectionFileCategory;
  /** The file */
  file: File;
  /** The file id */
  fileId: Scalars['ID']['output'];
  /** The inspection */
  inspection: Inspection;
  /** The inspection id */
  inspectionId: Scalars['ID']['output'];
  /** The latitude of the file, usually location of photo */
  latitude?: Maybe<Scalars['Latitude']['output']>;
  /** The longitude of the file, usually location of photo */
  longitude?: Maybe<Scalars['Longitude']['output']>;
};

export type FileOnInspectionAsset = {
  __typename: 'FileOnInspectionAsset';
  /** The file */
  file: File;
  /** The file id */
  fileId: Scalars['ID']['output'];
  /** The inspection asset */
  inspectionAsset: InspectionAsset;
  /** The inspection asset id */
  inspectionAssetId: Scalars['ID']['output'];
  /** The latitude of the file, usually location of photo */
  latitude?: Maybe<Scalars['Latitude']['output']>;
  /** The longitude of the file, usually location of photo */
  longitude?: Maybe<Scalars['Longitude']['output']>;
};

export type FileOnInspectionLandUse = {
  __typename: 'FileOnInspectionLandUse';
  /** The file */
  file: File;
  /** The file id */
  fileId: Scalars['ID']['output'];
  /** The inspection land use id */
  inspectionLandUseId: Scalars['ID']['output'];
  /** The latitude of the file, usually location of photo */
  latitude?: Maybe<Scalars['Latitude']['output']>;
  /** The longitude of the file, usually location of photo */
  longitude?: Maybe<Scalars['Longitude']['output']>;
};

/** Represents the type of a file */
export enum FileType {
  /** The file is a photo */
  Photo = 'PHOTO',
  PropertyAttachment = 'PROPERTY_ATTACHMENT',
  /** The file is a drawing */
  Sketch = 'SKETCH',
  /** The file type is unknown */
  Unknown = 'UNKNOWN',
}

export enum FileUploadStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN',
  Uploaded = 'UPLOADED',
}

export type FisheryBaitPurchase = {
  __typename: 'FisheryBaitPurchase';
  amountPurchasedTonnes: Scalars['Decimal']['output'];
  baitType: FisheryBaitType;
  emissionsIntensityBaitProduct?: Maybe<Scalars['Decimal']['output']>;
  id: Scalars['String']['output'];
  percentageAdditionalIngredients?: Maybe<Scalars['Percent']['output']>;
};

export enum FisheryBaitType {
  Custom = 'CUSTOM',
  FishFrames = 'FISH_FRAMES',
  FishHeads = 'FISH_HEADS',
  Sardines = 'SARDINES',
  Squid = 'SQUID',
  WholeFish = 'WHOLE_FISH',
}

/** Types of geo features */
export enum GeoFeatureType {
  /** An application area */
  ApplicationArea = 'APPLICATION_AREA',
  /** A management area */
  ManagementArea = 'MANAGEMENT_AREA',
  /** A paddock */
  Paddock = 'PADDOCK',
  /** A parcel */
  Parcel = 'PARCEL',
  /** A planting area */
  PlantingArea = 'PLANTING_AREA',
}

export type GeographicInformation = {
  __typename: 'GeographicInformation';
  /** The state of Australia the point is in (or closest to) */
  state: State;
};

/** comparableSale query input */
export type GetComparableSaleInput = {
  /** Property ID of the original property */
  propertyId: Scalars['ID']['input'];
  /** Sale ID for the comparable sale */
  saleId: Scalars['ID']['input'];
};

export enum GoatClass {
  BreedingDoes = 'BREEDING_DOES',
  Bucks = 'BUCKS',
  Kids = 'KIDS',
  MaidenBreedingDoes = 'MAIDEN_BREEDING_DOES',
  OtherDoes = 'OTHER_DOES',
  Wethers = 'WETHERS',
}

export type GoatEmissionsCalculation = {
  __typename: 'GoatEmissionsCalculation';
  inputs: EmissionsCalculationInputs;
  result: GoatEmissionsCalculationResult;
};

export type GoatEmissionsCalculationResult = {
  __typename: 'GoatEmissionsCalculationResult';
  carbonSequestration?: Maybe<GoatEmissionsCarbonSequestration>;
  intensities?: Maybe<GoatEmissionsIntensities>;
  net?: Maybe<GoatEmissionsNet>;
  scope1?: Maybe<GoatEmissionsScope1>;
  scope2?: Maybe<GoatEmissionsScope2>;
  scope3?: Maybe<GoatEmissionsScope3>;
};

export type GoatEmissionsCarbonSequestration = {
  __typename: 'GoatEmissionsCarbonSequestration';
  intermediate?: Maybe<GoatEmissionsTotalAverage>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type GoatEmissionsIntensities = {
  __typename: 'GoatEmissionsIntensities';
  goatMeatBreedingExcludingSequestration?: Maybe<Scalars['Float']['output']>;
  goatMeatBreedingIncludingSequestration?: Maybe<Scalars['Float']['output']>;
  woolExcludingSequestration?: Maybe<Scalars['Float']['output']>;
  woolIncludingSequestration?: Maybe<Scalars['Float']['output']>;
};

export type GoatEmissionsNet = {
  __typename: 'GoatEmissionsNet';
  total?: Maybe<Scalars['Float']['output']>;
};

export type GoatEmissionsScope1 = {
  __typename: 'GoatEmissionsScope1';
  atmosphericDepositionN2O?: Maybe<Scalars['Float']['output']>;
  entericCH4?: Maybe<Scalars['Float']['output']>;
  fertiliserN2O?: Maybe<Scalars['Float']['output']>;
  fuelCH4?: Maybe<Scalars['Float']['output']>;
  fuelCO2?: Maybe<Scalars['Float']['output']>;
  fuelN2O?: Maybe<Scalars['Float']['output']>;
  leachingAndRunoffN2O?: Maybe<Scalars['Float']['output']>;
  limeCO2?: Maybe<Scalars['Float']['output']>;
  manureManagementCH4?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  totalCH4?: Maybe<Scalars['Float']['output']>;
  totalCO2?: Maybe<Scalars['Float']['output']>;
  totalN2O?: Maybe<Scalars['Float']['output']>;
  ureaCO2?: Maybe<Scalars['Float']['output']>;
  urineAndDungN2O?: Maybe<Scalars['Float']['output']>;
};

export type GoatEmissionsScope2 = {
  __typename: 'GoatEmissionsScope2';
  electricity?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type GoatEmissionsScope3 = {
  __typename: 'GoatEmissionsScope3';
  electricity?: Maybe<Scalars['Float']['output']>;
  fertiliser?: Maybe<Scalars['Float']['output']>;
  fuel?: Maybe<Scalars['Float']['output']>;
  herbicide?: Maybe<Scalars['Float']['output']>;
  lime?: Maybe<Scalars['Float']['output']>;
  purchasedFeed?: Maybe<Scalars['Float']['output']>;
  purchasedLivestock?: Maybe<Scalars['Float']['output']>;
  purchasedMineralSupplementation?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type GoatEmissionsTotalAverage = {
  __typename: 'GoatEmissionsTotalAverage';
  average?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type GoatLivestockActivity = Activity &
  LivestockActivity & {
    __typename: 'GoatLivestockActivity';
    breedingDoesBreeding: Scalars['Boolean']['output'];
    breedingDoesTrading: Scalars['Boolean']['output'];
    bucksBreeding: Scalars['Boolean']['output'];
    bucksTrading: Scalars['Boolean']['output'];
    businessActivity: BusinessActivity;
    businessActivityId: Scalars['ID']['output'];
    end: Scalars['Date']['output'];
    feed?: Maybe<Feed>;
    feedId?: Maybe<Scalars['ID']['output']>;
    id: Scalars['ID']['output'];
    kidsBreeding: Scalars['Boolean']['output'];
    kidsTrading: Scalars['Boolean']['output'];
    locations?: Maybe<Array<Maybe<ActivityLocation>>>;
    maidenBreedingDoesBreeding: Scalars['Boolean']['output'];
    maidenBreedingDoesTrading: Scalars['Boolean']['output'];
    name: Scalars['String']['output'];
    otherDoesBreeding: Scalars['Boolean']['output'];
    otherDoesTrading: Scalars['Boolean']['output'];
    start: Scalars['Date']['output'];
    targetAllocations?: Maybe<Array<Maybe<ActivityAllocation>>>;
    wethersBreeding: Scalars['Boolean']['output'];
    wethersTrading: Scalars['Boolean']['output'];
  };

export type GoatMonthLivestockActivity = {
  __typename: 'GoatMonthLivestockActivity';
  crudeProteinPercentage: Scalars['Percent']['output'];
  dryMatterDigestibilityPercentage: Scalars['Percent']['output'];
  feedAvailabilityTonnesPerHectare: Scalars['Decimal']['output'];
  goatClass: GoatClass;
  goatLivestockActivityId: Scalars['ID']['output'];
  headAtStart: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  keyEvents?: Maybe<Scalars['String']['output']>;
  livestockCategory: LivestockCategory;
  livestockTransactionEvents: Array<LivestockTransactionEvent>;
  month: Month;
  nonSalePopulationChange: Scalars['Int']['output'];
  percentageDoesKidding: Scalars['Percent']['output'];
  seasonalKiddingRate: Scalars['Percent']['output'];
  year: Scalars['Int']['output'];
};

export type GoatShearingEvent = {
  __typename: 'GoatShearingEvent';
  cleanWoolYieldKg: Scalars['Decimal']['output'];
  date: Scalars['Date']['output'];
  goatClass?: Maybe<GoatClass>;
  goatLivestockActivityId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  livestockCategory: LivestockCategory;
  /** @deprecated Use 'date' instead */
  month: Month;
  numberGoatShorn: Scalars['Int']['output'];
  woolPerHeadKg: Scalars['Decimal']['output'];
  /** @deprecated Use 'date' instead */
  year: Scalars['Int']['output'];
};

export type GrainsEmissionsCalculation = {
  __typename: 'GrainsEmissionsCalculation';
  inputs: EmissionsCalculationInputs;
  result: GrainsEmissionsCalculationResult;
};

export type GrainsEmissionsCalculationResult = {
  __typename: 'GrainsEmissionsCalculationResult';
  carbonSequestration?: Maybe<GrainsEmissionsCarbonSequestration>;
  intensities?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  intensitiesWithSequestration?: Maybe<
    Array<Maybe<GrainsEmissionsIntensities>>
  >;
  intermediate?: Maybe<Array<Maybe<GrainsEmissionsIntermediateScopes>>>;
  net?: Maybe<GrainsEmissionsNet>;
  scope1?: Maybe<GrainsEmissionsScope1>;
  scope2?: Maybe<GrainsEmissionsScope2>;
  scope3?: Maybe<GrainsEmissionsScope3>;
};

export type GrainsEmissionsCarbonSequestration = {
  __typename: 'GrainsEmissionsCarbonSequestration';
  intermediate?: Maybe<Array<Maybe<GrainsEmissionsTotalAverage>>>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type GrainsEmissionsIntensities = {
  __typename: 'GrainsEmissionsIntensities';
  grainsExcludingSequestration?: Maybe<Scalars['Float']['output']>;
  grainsIncludingSequestration?: Maybe<Scalars['Float']['output']>;
};

export type GrainsEmissionsIntermediateScopes = {
  __typename: 'GrainsEmissionsIntermediateScopes';
  scope1?: Maybe<GrainsEmissionsScope1>;
  scope2?: Maybe<GrainsEmissionsScope2>;
  scope3?: Maybe<GrainsEmissionsScope3>;
};

export type GrainsEmissionsNet = {
  __typename: 'GrainsEmissionsNet';
  crops?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type GrainsEmissionsScope1 = {
  __typename: 'GrainsEmissionsScope1';
  atmosphericDepositionN2O?: Maybe<Scalars['Float']['output']>;
  cropResidueN2O?: Maybe<Scalars['Float']['output']>;
  fertiliserN2O?: Maybe<Scalars['Float']['output']>;
  fieldBurningCH4?: Maybe<Scalars['Float']['output']>;
  fieldBurningN2O?: Maybe<Scalars['Float']['output']>;
  fuelCH4?: Maybe<Scalars['Float']['output']>;
  fuelCO2?: Maybe<Scalars['Float']['output']>;
  fuelN2O?: Maybe<Scalars['Float']['output']>;
  leachingAndRunoffN2O?: Maybe<Scalars['Float']['output']>;
  limeCO2?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  totalCH4?: Maybe<Scalars['Float']['output']>;
  totalCO2?: Maybe<Scalars['Float']['output']>;
  totalN2O?: Maybe<Scalars['Float']['output']>;
  ureaCO2?: Maybe<Scalars['Float']['output']>;
};

export type GrainsEmissionsScope2 = {
  __typename: 'GrainsEmissionsScope2';
  electricity?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type GrainsEmissionsScope3 = {
  __typename: 'GrainsEmissionsScope3';
  electricity?: Maybe<Scalars['Float']['output']>;
  fertiliser?: Maybe<Scalars['Float']['output']>;
  fuel?: Maybe<Scalars['Float']['output']>;
  herbicide?: Maybe<Scalars['Float']['output']>;
  lime?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type GrainsEmissionsTotalAverage = {
  __typename: 'GrainsEmissionsTotalAverage';
  average?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type HorticultureEmissionsCalculation = {
  __typename: 'HorticultureEmissionsCalculation';
  inputs: EmissionsCalculationInputs;
  result: HorticultureEmissionsCalculationResult;
};

export type HorticultureEmissionsCalculationResult = {
  __typename: 'HorticultureEmissionsCalculationResult';
  carbonSequestration?: Maybe<GrainsEmissionsCarbonSequestration>;
  intensities: Array<HorticultureEmissionsIntensity>;
  intermediate?: Maybe<Array<Maybe<GrainsEmissionsIntermediateScopes>>>;
  net?: Maybe<GrainsEmissionsNet>;
  scope1?: Maybe<HorticultureEmissionsScope1>;
  scope2?: Maybe<GrainsEmissionsScope2>;
  scope3?: Maybe<GrainsEmissionsScope3>;
};

export type HorticultureEmissionsIntensity = {
  __typename: 'HorticultureEmissionsIntensity';
  tonnesCropExcludingSequestration: Scalars['Float']['output'];
  tonnesCropIncludingSequestration: Scalars['Float']['output'];
};

export type HorticultureEmissionsScope1 = {
  __typename: 'HorticultureEmissionsScope1';
  atmosphericDepositionN2O?: Maybe<Scalars['Float']['output']>;
  cropResidueN2O?: Maybe<Scalars['Float']['output']>;
  fertiliserN2O?: Maybe<Scalars['Float']['output']>;
  fieldBurningCH4?: Maybe<Scalars['Float']['output']>;
  fieldBurningN2O?: Maybe<Scalars['Float']['output']>;
  fuelCH4?: Maybe<Scalars['Float']['output']>;
  fuelCO2?: Maybe<Scalars['Float']['output']>;
  fuelN2O?: Maybe<Scalars['Float']['output']>;
  hfcsRefrigerantLeakage?: Maybe<Scalars['Float']['output']>;
  leachingAndRunoffN2O?: Maybe<Scalars['Float']['output']>;
  limeCO2?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  totalCH4?: Maybe<Scalars['Float']['output']>;
  totalCO2?: Maybe<Scalars['Float']['output']>;
  totalN2O?: Maybe<Scalars['Float']['output']>;
  ureaCO2?: Maybe<Scalars['Float']['output']>;
};

export type InputActivityWithCropping = {
  __typename: 'InputActivityWithCropping';
  activityType: ActivityType;
  cropping?: Maybe<InputCropping>;
  end: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  start: Scalars['Date']['output'];
};

export type InputCropping = {
  __typename: 'InputCropping';
  croppingType: CroppingType;
};

export type Inspection = {
  __typename: 'Inspection';
  /** The additional notes of the inspection */
  additionalNotes?: Maybe<Scalars['String']['output']>;
  /** The assets of the inspection */
  assets?: Maybe<Array<Maybe<InspectionAsset>>>;
  /** The contamination notes of the inspection */
  contaminationNotes?: Maybe<Scalars['String']['output']>;
  /** The date the inspection was created */
  createdAt: Scalars['DateTime']['output'];
  /** The enterprise description of the inspection */
  enterpriseDescription?: Maybe<Scalars['String']['output']>;
  /** The environment notes of the inspection */
  environmentNotes?: Maybe<Scalars['String']['output']>;
  /** The files of the inspection */
  files?: Maybe<Array<Maybe<FileOnInspection>>>;
  /** The id of the inspection */
  id: Scalars['ID']['output'];
  /** The date of the inspection */
  inspectionDate?: Maybe<Scalars['DateTime']['output']>;
  /** The name of the instructing party */
  instructingPartyName?: Maybe<Scalars['String']['output']>;
  /** The land area of the inspection */
  landArea?: Maybe<Scalars['String']['output']>;
  /** The land description of the inspection */
  landDescription?: Maybe<Scalars['String']['output']>;
  /** The land uses of the inspection */
  landUses?: Maybe<Array<Maybe<InspectionLandUse>>>;
  /** The name of the inspection */
  name?: Maybe<Scalars['String']['output']>;
  /** The notes of the inspection */
  notes?: Maybe<Scalars['String']['output']>;
  /** The other licenses notes of the inspection */
  otherLicensesNotes?: Maybe<Scalars['String']['output']>;
  /** The property identified of the inspection */
  propertyIdentified?: Maybe<Scalars['String']['output']>;
  /** The property notes of the inspection */
  propertyNotes?: Maybe<Scalars['String']['output']>;
  /** The purpose of the inspection */
  purpose?: Maybe<Scalars['String']['output']>;
  /** The site notes of the inspection */
  siteNotes?: Maybe<Scalars['String']['output']>;
  /** The status of the inspection */
  status: InspectionStatus;
  /** The type of the inspection */
  type: InspectionType;
  /** The date the inspection was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** The User that created the inspection */
  user: User;
  /** The ID of the user that created the inspection */
  userId: Scalars['ID']['output'];
  /** The date of the valuation */
  valuationDate?: Maybe<Scalars['DateTime']['output']>;
  /** The water license notes of the inspection */
  waterLicenseNotes?: Maybe<Scalars['String']['output']>;
};

export type InspectionAsset = {
  __typename: 'InspectionAsset';
  /** The category of the asset */
  category: InspectionAssetCategory;
  /** The condition of the asset */
  condition: InspectionItemCondition;
  /** The date the asset was created */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** The details of the asset */
  details?: Maybe<Scalars['JSON']['output']>;
  /** The files on the asset */
  files?: Maybe<Array<Maybe<FileOnInspectionAsset>>>;
  /** The geoJson of the asset */
  geoJson?: Maybe<Scalars['JSON']['output']>;
  /** The id of the asset */
  id: Scalars['ID']['output'];
  /** The inspection the asset belongs to */
  inspection: Inspection;
  /** The id of the inspection the asset belongs to */
  inspectionId: Scalars['ID']['output'];
  /** The latitude of the asset */
  latitude?: Maybe<Scalars['Latitude']['output']>;
  /** The longitude of the asset */
  longitude?: Maybe<Scalars['Longitude']['output']>;
  /** The name of the asset */
  name?: Maybe<Scalars['String']['output']>;
  /** The notes of the asset */
  notes?: Maybe<Scalars['String']['output']>;
  /** The subtype of the asset */
  subtype?: Maybe<Scalars['String']['output']>;
  /** The type of the asset */
  type?: Maybe<Scalars['String']['output']>;
  /** The date the asset was last updated */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Describes the category of an inspection asset */
export enum InspectionAssetCategory {
  /** The asset is a fence */
  Fencing = 'FENCING',
  /** The asset is something else */
  Other = 'OTHER',
  /** The asset is a building */
  Structure = 'STRUCTURE',
  /** The category is unknown */
  Unknown = 'UNKNOWN',
  /** The asset is water */
  Water = 'WATER',
}

/** Represents the category of an inspections file */
export enum InspectionFileCategory {
  /** The file is a sketch/drawing */
  Drawing = 'DRAWING',
  /** The file is for the environment contamination (usually a photo) */
  EnvironmentContamination = 'ENVIRONMENT_CONTAMINATION',
  /** The file is a general file (usually a photo) */
  General = 'GENERAL',
  /** The file category is unknown */
  Unknown = 'UNKNOWN',
}

/** Describes the condition of an inspection item */
export enum InspectionItemCondition {
  /** The condition is fair */
  Fair = 'FAIR',
  /** The condition is good */
  Good = 'GOOD',
  /** The condition is poor */
  Poor = 'POOR',
  /** The condition is unknown */
  Unknown = 'UNKNOWN',
  /** The condition is very good */
  VeryGood = 'VERY_GOOD',
  /** The condition is very poor */
  VeryPoor = 'VERY_POOR',
}

export type InspectionLandUse = {
  __typename: 'InspectionLandUse';
  /** The condition */
  condition?: Maybe<InspectionItemCondition>;
  /** The date the inspection land use was created */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** The crop */
  crop?: Maybe<Scalars['String']['output']>;
  /** The crop health */
  cropHealth?: Maybe<Scalars['String']['output']>;
  /** The files for the inspection land use */
  files?: Maybe<Array<Maybe<FileOnInspectionLandUse>>>;
  /** The geojson */
  geojson?: Maybe<Scalars['JSON']['output']>;
  /** The growth stage */
  growthStage?: Maybe<Scalars['String']['output']>;
  /** The id of the inspection land use */
  id: Scalars['ID']['output'];
  /** The inspection the inspection land use belongs to */
  inspection?: Maybe<Inspection>;
  /** The inspection id */
  inspectionId?: Maybe<Scalars['ID']['output']>;
  /** The name of the land use */
  name?: Maybe<Scalars['String']['output']>;
  /** The notes */
  notes?: Maybe<Scalars['String']['output']>;
  /** The rating */
  rating?: Maybe<Rating>;
  /** The risk */
  risk?: Maybe<Scalars['String']['output']>;
  /** The seasons */
  seasons?: Maybe<Array<Maybe<Season>>>;
  /** The soil type */
  soilType?: Maybe<Scalars['String']['output']>;
  /** The type of land use */
  type?: Maybe<Scalars['String']['output']>;
  /** The date the inspection land use was last updated */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The year */
  year?: Maybe<Scalars['Int']['output']>;
  /** The yield */
  yield?: Maybe<Scalars['Float']['output']>;
};

/** Describes the status of an inspection */
export enum InspectionStatus {
  /** The inspection is complete */
  Complete = 'COMPLETE',
  /** The inspection is a draft */
  Draft = 'DRAFT',
  /** The inspection is in progress */
  InProgress = 'IN_PROGRESS',
}

/** Describes the type of an inspection */
export enum InspectionType {
  /** The inspection is a depreciation inspection */
  Depreciation = 'DEPRECIATION',
  /** The inspection is a valuation */
  Valuation = 'VALUATION',
}

export type Invitation = {
  __typename: 'Invitation';
  id: Scalars['ID']['output'];
};

/** Job object */
export type Job = {
  __typename: 'Job';
  /** The date the job was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The ID of the job. */
  id: Scalars['ID']['output'];
  /** The properties associated with the job. */
  jobProperties: Array<Maybe<PropertyInJob>>;
  /** The name of the job. */
  name?: Maybe<Scalars['String']['output']>;
  /** The ID of the team the job belongs to. */
  teamId: Scalars['ID']['output'];
  /** The date the job was last updated. */
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type KeyValuePair = {
  __typename: 'KeyValuePair';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Lime = {
  __typename: 'Lime';
  amountPerHectareKg: Scalars['Int']['output'];
  applicationAreaGeometry?: Maybe<Scalars['GeoJSONMultiPolygon']['output']>;
  applicationAreaSquareMetres?: Maybe<Scalars['Int']['output']>;
  applicationDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type: LimeType;
};

export enum LimeType {
  Dolomite = 'DOLOMITE',
  Limestone = 'LIMESTONE',
}

export type ListBeefMonthLivestockActivitiesInput = {
  beefClass?: InputMaybe<BeefClass>;
  beefLivestockActivityId: Scalars['ID']['input'];
  livestockCategory: LivestockCategory;
};

export type ListBuffaloMonthLivestockActivitiesInput = {
  buffaloClass?: InputMaybe<BuffaloClass>;
  buffaloLivestockActivityId: Scalars['ID']['input'];
  livestockCategory?: InputMaybe<LivestockCategory>;
};

export type ListDairyMonthLivestockActivitiesInput = {
  dairyClass?: InputMaybe<DairyClass>;
  dairyLivestockActivityId: Scalars['ID']['input'];
};

export type ListDeerMonthLivestockActivitiesInput = {
  deerClass?: InputMaybe<DeerClass>;
  deerLivestockActivityId: Scalars['ID']['input'];
  livestockCategory?: InputMaybe<LivestockCategory>;
};

export type ListGoatMonthLivestockActivitiesInput = {
  goatClass?: InputMaybe<GoatClass>;
  goatLivestockActivityId: Scalars['ID']['input'];
  livestockCategory?: InputMaybe<LivestockCategory>;
};

export type ListPorkMonthLivestockActivitiesInput = {
  porkClass?: InputMaybe<PorkClass>;
  porkLivestockActivityId: Scalars['ID']['input'];
};

export type ListSheepMonthLivestockActivitiesInput = {
  livestockCategory?: InputMaybe<LivestockCategory>;
  sheepClass?: InputMaybe<SheepClass>;
  sheepLivestockActivityId: Scalars['ID']['input'];
};

export type LivestockActivity = {
  feed?: Maybe<Feed>;
};

export enum LivestockCategory {
  Breeding = 'BREEDING',
  Trading = 'TRADING',
}

export type LivestockTransactionEvent = {
  __typename: 'LivestockTransactionEvent';
  averageWeightKg?: Maybe<Scalars['Decimal']['output']>;
  /** The number of purchased head that are merino - relevant only for sheep purchase transactions */
  headPurchasedMerino?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  livestockCount: Scalars['Int']['output'];
  totalWeightKg: Scalars['Decimal']['output'];
  /** The region the transaction took place in - required only for beef purchase transactions */
  transactionRegion?: Maybe<TransactionRegion>;
  transactionType: TransactionType;
};

export type MapShape = {
  __typename: 'MapShape';
  /** The date the shape was created */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** The shape geometry as a multi-polygon */
  geometry?: Maybe<Scalars['GeoJSONMultiPolygon']['output']>;
  /** The id of the shape */
  id: Scalars['ID']['output'];
  /** The name of the shape */
  name?: Maybe<Scalars['String']['output']>;
  /** The type of the shape */
  type: MapShapeType;
  /** The date the shape was last updated */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum MapShapeType {
  ApplicationArea = 'APPLICATION_AREA',
  ManagementArea = 'MANAGEMENT_AREA',
  Paddock = 'PADDOCK',
  PlantingArea = 'PLANTING_AREA',
}

/** Months of the year */
export enum Month {
  /** April */
  April = 'APRIL',
  /** August */
  August = 'AUGUST',
  /** December */
  December = 'DECEMBER',
  /** February */
  February = 'FEBRUARY',
  /** January */
  January = 'JANUARY',
  /** July */
  July = 'JULY',
  /** June */
  June = 'JUNE',
  /** March */
  March = 'MARCH',
  /** May */
  May = 'MAY',
  /** November */
  November = 'NOVEMBER',
  /** October */
  October = 'OCTOBER',
  /** September */
  September = 'SEPTEMBER',
}

export type Mutation = {
  __typename: 'Mutation';
  /** Add an existing property to a job. */
  addPropertyToJob: PropertyInJob;
  /** Create a new invitation from an advisor */
  createAdvisorInvitation: Invitation;
  /** Create a new beef livestock activity */
  createBeefLivestockActivity: BeefLivestockActivity;
  createBeefLivestockTransactionEvent: LivestockTransactionEvent;
  /** Create a new beef month livestock activity */
  createBeefMonthLivestockActivity: BeefMonthLivestockActivity;
  createBuffaloLivestockActivity: BuffaloLivestockActivity;
  createBuffaloLivestockTransactionEvent: LivestockTransactionEvent;
  createBuffaloMonthLivestockActivity: BuffaloMonthLivestockActivity;
  createBurningActivity: BurningActivity;
  createBusinessActivity: BusinessActivity;
  createCarbamate: Carbamate;
  createConsumption?: Maybe<Consumption>;
  createCottonActivity: CottonActivity;
  createCropVariety: CropVariety;
  createCroppingActivity: CroppingActivity;
  /** Create a new dairy activity */
  createDairyLivestockActivity: DairyLivestockActivity;
  createDairyLivestockTransactionEvent: LivestockTransactionEvent;
  /** Create a new dairy month activity */
  createDairyMonthLivestockActivity: DairyMonthLivestockActivity;
  createDeerLivestockActivity: DeerLivestockActivity;
  createDeerLivestockTransactionEvent: LivestockTransactionEvent;
  createDeerMonthLivestockActivity: DeerMonthLivestockActivity;
  /** Create a new feed */
  createFeed: Feed;
  createFeedlotActivity: FeedlotActivity;
  createFeedlotFeedBreakdown: FeedlotFeedBreakdown;
  createFeedlotFeedComposition: FeedlotFeedComposition;
  createFeedlotGroup: FeedlotGroup;
  createFeedlotPurchase: FeedlotPurchase;
  createFeedlotSale: FeedlotSale;
  createFertiliser: Fertiliser;
  createFisheryBaitPurchase: FisheryBaitPurchase;
  createGoatLivestockActivity: GoatLivestockActivity;
  createGoatLivestockTransactionEvent: LivestockTransactionEvent;
  createGoatMonthLivestockActivity: GoatMonthLivestockActivity;
  createGoatShearingEvent: GoatShearingEvent;
  /** Create a new job. */
  createJob: Job;
  createLime: Lime;
  /** Create a new map shape */
  createMapShape: MapShape;
  /** Add a parcel to a property. */
  createParcelInProperty: ParcelInProperty;
  createPorkFeedBlend: PorkFeedBlend;
  createPorkLivestockActivity: PorkLivestockActivity;
  createPorkLivestockTransactionEvent: LivestockTransactionEvent;
  createPorkMonthLivestockActivity: PorkMonthLivestockActivity;
  /** Create a new property portfolio */
  createPortfolio: Portfolio;
  createPoultryBroilerActivity: PoultryBroilerActivity;
  createPoultryBroilerTransactionEvent: PoultryTransactionEvent;
  createPoultryGrowerBatch: PoultryGrowerBatch;
  createPoultryGrowerBatchFeedBlend: PoultryFeedBlend;
  createPoultryLayerActivity: PoultryLayerActivity;
  createPoultryLayerFeedBlend: PoultryFeedBlend;
  createPoultryLayerMonthActivity: PoultryLayerMonthActivity;
  createPoultryLayerTransactionEvent: PoultryTransactionEvent;
  /** Create a new property */
  createProperty?: Maybe<Property>;
  /** Create a property and add it to a job. */
  createPropertyAndAddToJob: PropertyInJob;
  /** Create a new property file */
  createPropertyFile: FileForUpload;
  /** Create a new property note */
  createPropertyNote: PropertyNote;
  /** Create a new report. */
  createReport: Report;
  createRoute: Route;
  createSheepLivestockActivity: SheepLivestockActivity;
  createSheepLivestockTransactionEvent: LivestockTransactionEvent;
  createSheepMonthLivestockActivity: SheepMonthLivestockActivity;
  createSheepShearingEvent: SheepShearingEvent;
  createVegetationActivity: VegetationActivity;
  createWildSeaFisheryActivity: WildSeaFisheryActivity;
  deleteActivity?: Maybe<Activity>;
  deleteCarbamate: Carbamate;
  /** Delete an existing comparable sale. */
  deleteComparableSale?: Maybe<ComparableSale>;
  deleteConsumption?: Maybe<Consumption>;
  deleteFeedlotFeedBreakdown: FeedlotFeedBreakdown;
  deleteFeedlotFeedComposition: FeedlotFeedComposition;
  deleteFeedlotGroup: FeedlotGroup;
  deleteFeedlotPurchase: FeedlotPurchase;
  deleteFeedlotSale: FeedlotSale;
  deleteFertiliser: Fertiliser;
  /** Delete an existing property file */
  deleteFile: File;
  deleteFisheryBaitPurchase: FisheryBaitPurchase;
  deleteGoatShearingEvent: GoatShearingEvent;
  /** Delete a job. */
  deleteJob?: Maybe<Job>;
  deleteLime: Lime;
  deleteLivestockTransactionEvent?: Maybe<LivestockTransactionEvent>;
  /** Delete an existing map shape */
  deleteMapShape: MapShape;
  deletePorkFeedBlend?: Maybe<PorkFeedBlend>;
  /** Delete an existing property portfolio */
  deletePortfolio: Portfolio;
  deletePoultryFeedBlend: PoultryFeedBlend;
  deletePoultryGrowerBatch: PoultryGrowerBatch;
  deletePoultryLayerMonthActivity: PoultryLayerMonthActivity;
  deletePoultryTransactionEvent?: Maybe<PoultryTransactionEvent>;
  /** Delete a property */
  deleteProperty?: Maybe<Property>;
  /** Delete an existing property note */
  deletePropertyNote: PropertyNote;
  /** Delete an existing report. */
  deleteReport: Report;
  deleteRoute: Route;
  deleteSheepShearingEvent: SheepShearingEvent;
  /** Remove a parcel from a property. */
  removeParcelFromProperty: Scalars['Boolean']['output'];
  /** Delete a PropertyInJob. */
  removePropertyFromJob?: Maybe<PropertyInJob>;
  saveComparableSale: ComparableSale;
  saveComparableSales: Array<ComparableSale>;
  /** Set all the parcels for a property at once. */
  setParcelsForProperty: Array<ParcelInProperty>;
  updateActivity?: Maybe<Activity>;
  updateActivityAllocations: Array<Maybe<ActivityAllocation>>;
  /** Update an existing beef livestock activity */
  updateBeefLivestockActivity: BeefLivestockActivity;
  /** Update an existing beef month livestock activity */
  updateBeefMonthLivestockActivity: BeefMonthLivestockActivity;
  updateBuffaloLivestockActivity: BuffaloLivestockActivity;
  updateBuffaloMonthLivestockActivity: BuffaloMonthLivestockActivity;
  updateBurningActivity: BurningActivity;
  updateBusinessActivity: BusinessActivity;
  updateCarbamate: Carbamate;
  /** Update an existing comparable sale. */
  updateComparableSale: ComparableSale;
  updateConsumption?: Maybe<Consumption>;
  updateCottonActivity: CottonActivity;
  updateCropVariety: CropVariety;
  updateCroppingActivity: CroppingActivity;
  /** Update an existing dairy activity */
  updateDairyLivestockActivity: DairyLivestockActivity;
  /** Update an existing dairy month activity */
  updateDairyMonthLivestockActivity: DairyMonthLivestockActivity;
  updateDeerLivestockActivity: DeerLivestockActivity;
  updateDeerMonthLivestockActivity: DeerMonthLivestockActivity;
  /** Update an existing feed */
  updateFeed: Feed;
  updateFeedlotActivity: FeedlotActivity;
  updateFeedlotFeedBreakdown: FeedlotFeedBreakdown;
  updateFeedlotFeedComposition: FeedlotFeedComposition;
  updateFeedlotGroup: FeedlotGroup;
  updateFeedlotPurchase: FeedlotPurchase;
  updateFeedlotSale: FeedlotSale;
  updateFertiliser: Fertiliser;
  /** Update an existing file */
  updateFile: File;
  updateFisheryBaitPurchase: FisheryBaitPurchase;
  updateGoatLivestockActivity: GoatLivestockActivity;
  updateGoatMonthLivestockActivity: GoatMonthLivestockActivity;
  updateGoatShearingEvent: GoatShearingEvent;
  /** Update a job. */
  updateJob: Job;
  updateLime: Lime;
  updateLivestockTransactionEvent: LivestockTransactionEvent;
  /** Update an existing map shape */
  updateMapShape: MapShape;
  /** Update a parcel in a property. */
  updateParcelInProperty: ParcelInProperty;
  updatePorkFeedBlend?: Maybe<PorkFeedBlend>;
  updatePorkLivestockActivity: PorkLivestockActivity;
  updatePorkMonthLivestockActivity: PorkMonthLivestockActivity;
  /** Update an existing property portfolio */
  updatePortfolio: Portfolio;
  updatePoultryBroilerActivity: PoultryBroilerActivity;
  updatePoultryFeedBlend: PoultryFeedBlend;
  updatePoultryGrowerBatch: PoultryGrowerBatch;
  updatePoultryGrowerBatchDetails: PoultryGrowerBatchDetails;
  updatePoultryGrowerBatchFeedBreakdown: PoultryFeedBreakdown;
  updatePoultryLayerActivity: PoultryLayerActivity;
  updatePoultryLayerMonthActivity: PoultryLayerMonthActivity;
  updatePoultryTransactionEvent: PoultryTransactionEvent;
  /** Update an existing property */
  updateProperty?: Maybe<Property>;
  /** Update a PropertyInJob. */
  updatePropertyInJob: PropertyInJob;
  /** Update an existing property note */
  updatePropertyNote: PropertyNote;
  /** Update an existing report. */
  updateReport: Report;
  /** Update the status of a report. (Internal auth only) */
  updateReportStatusInternal: Report;
  updateRoute: Route;
  updateSheepLivestockActivity: SheepLivestockActivity;
  updateSheepMonthLivestockActivity: SheepMonthLivestockActivity;
  updateSheepShearingEvent: SheepShearingEvent;
  updateVegetationActivity: VegetationActivity;
  updateWildSeaFisheryActivity: WildSeaFisheryActivity;
};

export type MutationAddPropertyToJobArgs = {
  input: AddPropertyToJobInput;
};

export type MutationCreateAdvisorInvitationArgs = {
  input: CreateAdvisorInvitationInput;
};

export type MutationCreateBeefLivestockActivityArgs = {
  createBeefLivestockActivityInput: CreateBeefLivestockActivityInput;
  propertyId: Scalars['ID']['input'];
};

export type MutationCreateBeefLivestockTransactionEventArgs = {
  beefMonthLivestockActivityId: Scalars['ID']['input'];
  createLivestockTransactionEventInput: CreateLivestockTransactionEventInput;
};

export type MutationCreateBeefMonthLivestockActivityArgs = {
  createBeefMonthLivestockActivityInput: CreateBeefMonthLivestockActivityInput;
};

export type MutationCreateBuffaloLivestockActivityArgs = {
  createBuffaloLivestockActivityInput: CreateBuffaloLivestockActivityInput;
  propertyId: Scalars['ID']['input'];
};

export type MutationCreateBuffaloLivestockTransactionEventArgs = {
  buffaloMonthLivestockActivityId: Scalars['ID']['input'];
  createLivestockTransactionEventInput: CreateLivestockTransactionEventInput;
};

export type MutationCreateBuffaloMonthLivestockActivityArgs = {
  createBuffaloMonthLivestockActivityInput: CreateBuffaloMonthLivestockActivityInput;
};

export type MutationCreateBurningActivityArgs = {
  createBurningActivityInput: CreateBurningActivityInput;
  propertyId: Scalars['ID']['input'];
};

export type MutationCreateBusinessActivityArgs = {
  input: CreateBusinessActivityInput;
};

export type MutationCreateCarbamateArgs = {
  createCarbamateInput: CreateCarbamateInput;
  croppingActivityId: Scalars['ID']['input'];
};

export type MutationCreateConsumptionArgs = {
  createConsumptionInput: CreateConsumptionInput;
  propertyId: Scalars['ID']['input'];
};

export type MutationCreateCottonActivityArgs = {
  createCottonActivityInput: CreateCottonActivityInput;
  propertyId: Scalars['ID']['input'];
};

export type MutationCreateCropVarietyArgs = {
  createCropVarietyInput: CreateCropVarietyInput;
  croppingActivityId: Scalars['ID']['input'];
};

export type MutationCreateCroppingActivityArgs = {
  createCroppingActivityInput: CreateCroppingActivityInput;
  propertyId: Scalars['ID']['input'];
};

export type MutationCreateDairyLivestockActivityArgs = {
  createDairyLivestockActivityInput: CreateDairyLivestockActivityInput;
  propertyId: Scalars['ID']['input'];
};

export type MutationCreateDairyLivestockTransactionEventArgs = {
  createLivestockTransactionEventInput: CreateLivestockTransactionEventInput;
  dairyMonthLivestockActivityId: Scalars['ID']['input'];
};

export type MutationCreateDairyMonthLivestockActivityArgs = {
  createDairyMonthLivestockActivityInput: CreateDairyMonthLivestockActivityInput;
};

export type MutationCreateDeerLivestockActivityArgs = {
  createDeerLivestockActivityInput: CreateDeerLivestockActivityInput;
  propertyId: Scalars['ID']['input'];
};

export type MutationCreateDeerLivestockTransactionEventArgs = {
  createLivestockTransactionEventInput: CreateLivestockTransactionEventInput;
  deerMonthLivestockActivityId: Scalars['ID']['input'];
};

export type MutationCreateDeerMonthLivestockActivityArgs = {
  createDeerMonthLivestockActivityInput: CreateDeerMonthLivestockActivityInput;
};

export type MutationCreateFeedArgs = {
  activityId: Scalars['ID']['input'];
  createFeedInput: CreateFeedInput;
};

export type MutationCreateFeedlotActivityArgs = {
  createFeedlotActivityInput: CreateFeedlotActivityInput;
  propertyId: Scalars['ID']['input'];
};

export type MutationCreateFeedlotFeedBreakdownArgs = {
  createFeedlotFeedBreakdownInput: CreateFeedlotFeedBreakdownInput;
  feedlotGroupId: Scalars['ID']['input'];
};

export type MutationCreateFeedlotFeedCompositionArgs = {
  createFeedlotFeedCompositionInput: CreateFeedlotFeedCompositionInput;
  feedlotActivityId: Scalars['ID']['input'];
};

export type MutationCreateFeedlotGroupArgs = {
  createFeedlotGroupInput: CreateFeedlotGroupInput;
  feedlotActivityId: Scalars['ID']['input'];
};

export type MutationCreateFeedlotPurchaseArgs = {
  createFeedlotPurchaseInput: CreateFeedlotPurchaseInput;
  feedlotActivityId: Scalars['ID']['input'];
};

export type MutationCreateFeedlotSaleArgs = {
  createFeedlotSaleInput: CreateFeedlotSaleInput;
  feedlotActivityId: Scalars['ID']['input'];
};

export type MutationCreateFertiliserArgs = {
  createFertiliserInput: CreateFertiliserInput;
  croppingActivityId: Scalars['ID']['input'];
};

export type MutationCreateFisheryBaitPurchaseArgs = {
  input: CreateFisheryBaitPurchaseInput;
  wildSeaFisheryActivityId: Scalars['ID']['input'];
};

export type MutationCreateGoatLivestockActivityArgs = {
  createGoatLivestockActivityInput: CreateGoatLivestockActivityInput;
  propertyId: Scalars['ID']['input'];
};

export type MutationCreateGoatLivestockTransactionEventArgs = {
  createLivestockTransactionEventInput: CreateLivestockTransactionEventInput;
  goatMonthLivestockActivityId: Scalars['ID']['input'];
};

export type MutationCreateGoatMonthLivestockActivityArgs = {
  createGoatMonthLivestockActivityInput: CreateGoatMonthLivestockActivityInput;
};

export type MutationCreateGoatShearingEventArgs = {
  goatLivestockActivityId: Scalars['ID']['input'];
  input: CreateGoatShearingEventInput;
};

export type MutationCreateJobArgs = {
  input: CreateJobInput;
};

export type MutationCreateLimeArgs = {
  createLimeInput: CreateLimeInput;
  croppingActivityId: Scalars['ID']['input'];
};

export type MutationCreateMapShapeArgs = {
  input: CreateMapShapeInput;
};

export type MutationCreateParcelInPropertyArgs = {
  input: CreateParcelInPropertyInput;
};

export type MutationCreatePorkFeedBlendArgs = {
  createPorkFeedBlendInput: CreatePorkFeedBlendInput;
  porkLivestockActivityId: Scalars['ID']['input'];
};

export type MutationCreatePorkLivestockActivityArgs = {
  createPorkLivestockActivityInput: CreatePorkLivestockActivityInput;
  propertyId: Scalars['ID']['input'];
};

export type MutationCreatePorkLivestockTransactionEventArgs = {
  createLivestockTransactionEventInput: CreateLivestockTransactionEventInput;
  porkMonthLivestockActivityId: Scalars['ID']['input'];
};

export type MutationCreatePorkMonthLivestockActivityArgs = {
  createPorkMonthLivestockActivityInput: CreatePorkMonthLivestockActivityInput;
};

export type MutationCreatePortfolioArgs = {
  input: CreatePortfolioInput;
};

export type MutationCreatePoultryBroilerActivityArgs = {
  createPoultryBroilerActivityInput: CreatePoultryBroilerActivityInput;
  propertyId: Scalars['ID']['input'];
};

export type MutationCreatePoultryBroilerTransactionEventArgs = {
  createPoultryTransactionEventInput: CreatePoultryTransactionEventInput;
  poultryBroilerActivityId: Scalars['ID']['input'];
};

export type MutationCreatePoultryGrowerBatchArgs = {
  createPoultryGrowerBatchInput: CreatePoultryGrowerBatchInput;
  poultryActivityId: Scalars['ID']['input'];
};

export type MutationCreatePoultryGrowerBatchFeedBlendArgs = {
  batchId: Scalars['ID']['input'];
  createPoultryGrowerBatchFeedBlendInput: CreatePoultryGrowerBatchFeedBlendInput;
};

export type MutationCreatePoultryLayerActivityArgs = {
  createPoultryLayerActivityInput: CreatePoultryLayerActivityInput;
  propertyId: Scalars['ID']['input'];
};

export type MutationCreatePoultryLayerFeedBlendArgs = {
  createPoultryLayerFeedBlendInput: CreatePoultryLayerFeedBlendInput;
  poultryLayerActivityId: Scalars['ID']['input'];
};

export type MutationCreatePoultryLayerMonthActivityArgs = {
  createPoultryLayerMonthActivityInput: CreatePoultryLayerMonthActivityInput;
  poultryActivityId: Scalars['ID']['input'];
};

export type MutationCreatePoultryLayerTransactionEventArgs = {
  createPoultryTransactionEventInput: CreatePoultryTransactionEventInput;
  poultryLayerActivityId: Scalars['ID']['input'];
};

export type MutationCreatePropertyArgs = {
  input: CreatePropertyInput;
};

export type MutationCreatePropertyAndAddToJobArgs = {
  input: CreatePropertyAndAddToJobInput;
};

export type MutationCreatePropertyFileArgs = {
  input: CreateFileInput;
};

export type MutationCreatePropertyNoteArgs = {
  input: CreatePropertyNoteInput;
};

export type MutationCreateReportArgs = {
  input: CreateReportInput;
};

export type MutationCreateRouteArgs = {
  activityId: Scalars['ID']['input'];
  input: CreateRouteInput;
};

export type MutationCreateSheepLivestockActivityArgs = {
  createSheepLivestockActivityInput: CreateSheepLivestockActivityInput;
  propertyId: Scalars['ID']['input'];
};

export type MutationCreateSheepLivestockTransactionEventArgs = {
  createLivestockTransactionEventInput: CreateLivestockTransactionEventInput;
  sheepMonthLivestockActivityId: Scalars['ID']['input'];
};

export type MutationCreateSheepMonthLivestockActivityArgs = {
  createSheepMonthLivestockActivityInput: CreateSheepMonthLivestockActivityInput;
};

export type MutationCreateSheepShearingEventArgs = {
  input: CreateSheepShearingEventInput;
  sheepLivestockActivityId: Scalars['ID']['input'];
};

export type MutationCreateVegetationActivityArgs = {
  createVegetationActivityInput: CreateVegetationActivityInput;
  propertyId: Scalars['ID']['input'];
};

export type MutationCreateWildSeaFisheryActivityArgs = {
  createWildSeaFisheryActivityInput: CreateWildSeaFisheryActivityInput;
  propertyId: Scalars['ID']['input'];
};

export type MutationDeleteActivityArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteCarbamateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteComparableSaleArgs = {
  propertyId: Scalars['ID']['input'];
  saleId: Scalars['ID']['input'];
};

export type MutationDeleteConsumptionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteFeedlotFeedBreakdownArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteFeedlotFeedCompositionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteFeedlotGroupArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteFeedlotPurchaseArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteFeedlotSaleArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteFertiliserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteFileArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteFisheryBaitPurchaseArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteGoatShearingEventArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteJobArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteLimeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteLivestockTransactionEventArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteMapShapeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeletePorkFeedBlendArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeletePortfolioArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeletePoultryFeedBlendArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeletePoultryGrowerBatchArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeletePoultryLayerMonthActivityArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeletePoultryTransactionEventArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeletePropertyArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeletePropertyNoteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteReportArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteRouteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteSheepShearingEventArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveParcelFromPropertyArgs = {
  parcelId: Scalars['String']['input'];
  propertyId: Scalars['String']['input'];
};

export type MutationRemovePropertyFromJobArgs = {
  jobId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
};

export type MutationSaveComparableSaleArgs = {
  input: SaveComparableSaleInput;
  propertyId: Scalars['ID']['input'];
};

export type MutationSaveComparableSalesArgs = {
  input: Array<SaveComparableSaleInput>;
  propertyId: Scalars['ID']['input'];
};

export type MutationSetParcelsForPropertyArgs = {
  parcels: Array<InputMaybe<AddParcelToPropertyInput>>;
  propertyId: Scalars['String']['input'];
};

export type MutationUpdateActivityArgs = {
  id: Scalars['ID']['input'];
  updateActivityInput: UpdateActivityInput;
};

export type MutationUpdateActivityAllocationsArgs = {
  sourceActivityId: Scalars['ID']['input'];
  updateActivityAllocationsInput: UpdateActivityAllocationsInput;
};

export type MutationUpdateBeefLivestockActivityArgs = {
  id: Scalars['ID']['input'];
  updateBeefLivestockActivityInput: UpdateBeefLivestockActivityInput;
};

export type MutationUpdateBeefMonthLivestockActivityArgs = {
  id: Scalars['ID']['input'];
  updateBeefMonthLivestockActivityInput: UpdateBeefMonthLivestockActivityInput;
};

export type MutationUpdateBuffaloLivestockActivityArgs = {
  id: Scalars['ID']['input'];
  updateBuffaloLivestockActivityInput: UpdateBuffaloLivestockActivityInput;
};

export type MutationUpdateBuffaloMonthLivestockActivityArgs = {
  id: Scalars['ID']['input'];
  updateBuffaloMonthLivestockActivityInput: UpdateBuffaloMonthLivestockActivityInput;
};

export type MutationUpdateBurningActivityArgs = {
  id: Scalars['ID']['input'];
  updateBurningActivityInput: UpdateBurningActivityInput;
};

export type MutationUpdateBusinessActivityArgs = {
  input: UpdateBusinessActivityInput;
};

export type MutationUpdateCarbamateArgs = {
  id: Scalars['ID']['input'];
  updateCarbamateInput: UpdateCarbamateInput;
};

export type MutationUpdateComparableSaleArgs = {
  input: UpdateComparableSaleInput;
  propertyId: Scalars['ID']['input'];
  saleId: Scalars['ID']['input'];
};

export type MutationUpdateConsumptionArgs = {
  id: Scalars['ID']['input'];
  updateConsumptionInput: UpdateConsumptionInput;
};

export type MutationUpdateCottonActivityArgs = {
  id: Scalars['ID']['input'];
  updateCottonActivityInput: UpdateCottonActivityInput;
};

export type MutationUpdateCropVarietyArgs = {
  id: Scalars['ID']['input'];
  updateCropVarietyInput: UpdateCropVarietyInput;
};

export type MutationUpdateCroppingActivityArgs = {
  id: Scalars['ID']['input'];
  updateCroppingActivityInput: UpdateCroppingActivityInput;
};

export type MutationUpdateDairyLivestockActivityArgs = {
  id: Scalars['ID']['input'];
  updateDairyLivestockActivityInput: UpdateDairyLivestockActivityInput;
};

export type MutationUpdateDairyMonthLivestockActivityArgs = {
  id: Scalars['ID']['input'];
  updateDairyMonthLivestockActivityInput: UpdateDairyMonthLivestockActivityInput;
};

export type MutationUpdateDeerLivestockActivityArgs = {
  id: Scalars['ID']['input'];
  updateDeerLivestockActivityInput: UpdateDeerLivestockActivityInput;
};

export type MutationUpdateDeerMonthLivestockActivityArgs = {
  id: Scalars['ID']['input'];
  updateDeerMonthLivestockActivityInput: UpdateDeerMonthLivestockActivityInput;
};

export type MutationUpdateFeedArgs = {
  id: Scalars['ID']['input'];
  updateFeedInput: UpdateFeedInput;
};

export type MutationUpdateFeedlotActivityArgs = {
  id: Scalars['ID']['input'];
  updateFeedlotActivityInput: UpdateFeedlotActivityInput;
};

export type MutationUpdateFeedlotFeedBreakdownArgs = {
  id: Scalars['ID']['input'];
  updateFeedlotFeedBreakdownInput: UpdateFeedlotFeedBreakdownInput;
};

export type MutationUpdateFeedlotFeedCompositionArgs = {
  id: Scalars['ID']['input'];
  updateFeedlotFeedCompositionInput: UpdateFeedlotFeedCompositionInput;
};

export type MutationUpdateFeedlotGroupArgs = {
  id: Scalars['ID']['input'];
  updateFeedlotGroupInput: UpdateFeedlotGroupInput;
};

export type MutationUpdateFeedlotPurchaseArgs = {
  id: Scalars['ID']['input'];
  updateFeedlotPurchaseInput: UpdateFeedlotPurchaseInput;
};

export type MutationUpdateFeedlotSaleArgs = {
  id: Scalars['ID']['input'];
  updateFeedlotSaleInput: UpdateFeedlotSaleInput;
};

export type MutationUpdateFertiliserArgs = {
  id: Scalars['ID']['input'];
  updateFertiliserInput: UpdateFertiliserInput;
};

export type MutationUpdateFileArgs = {
  id: Scalars['ID']['input'];
  input: UpdateFileInput;
};

export type MutationUpdateFisheryBaitPurchaseArgs = {
  id: Scalars['ID']['input'];
  input: UpdateFisheryBaitPurchaseInput;
};

export type MutationUpdateGoatLivestockActivityArgs = {
  id: Scalars['ID']['input'];
  updateGoatLivestockActivityInput: UpdateGoatLivestockActivityInput;
};

export type MutationUpdateGoatMonthLivestockActivityArgs = {
  id: Scalars['ID']['input'];
  updateGoatMonthLivestockActivityInput: UpdateGoatMonthLivestockActivityInput;
};

export type MutationUpdateGoatShearingEventArgs = {
  id: Scalars['ID']['input'];
  input: UpdateGoatShearingEventInput;
};

export type MutationUpdateJobArgs = {
  id: Scalars['ID']['input'];
  input: UpdateJobInput;
};

export type MutationUpdateLimeArgs = {
  id: Scalars['ID']['input'];
  updateLimeInput: UpdateLimeInput;
};

export type MutationUpdateLivestockTransactionEventArgs = {
  updateLivestockTransactionEventInput: UpdateLivestockTransactionEventInput;
};

export type MutationUpdateMapShapeArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMapShapeInput;
};

export type MutationUpdateParcelInPropertyArgs = {
  input: UpdateParcelInPropertyInput;
  parcelId: Scalars['String']['input'];
  propertyId: Scalars['String']['input'];
};

export type MutationUpdatePorkFeedBlendArgs = {
  id: Scalars['ID']['input'];
  updatePorkFeedBlendInput: UpdatePorkFeedBlendInput;
};

export type MutationUpdatePorkLivestockActivityArgs = {
  id: Scalars['ID']['input'];
  updatePorkLivestockActivityInput: UpdatePorkLivestockActivityInput;
};

export type MutationUpdatePorkMonthLivestockActivityArgs = {
  id: Scalars['ID']['input'];
  updatePorkMonthLivestockActivityInput: UpdatePorkMonthLivestockActivityInput;
};

export type MutationUpdatePortfolioArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePortfolioInput;
};

export type MutationUpdatePoultryBroilerActivityArgs = {
  id: Scalars['ID']['input'];
  updatePoultryBroilerActivityInput: UpdatePoultryBroilerActivityInput;
};

export type MutationUpdatePoultryFeedBlendArgs = {
  id: Scalars['ID']['input'];
  updatePoultryFeedBlendInput: UpdatePoultryFeedBlendInput;
};

export type MutationUpdatePoultryGrowerBatchArgs = {
  id: Scalars['ID']['input'];
  updatePoultryGrowerBatchInput: UpdatePoultryGrowerBatchInput;
};

export type MutationUpdatePoultryGrowerBatchDetailsArgs = {
  batchId: Scalars['ID']['input'];
  detailsType: PoultryGrowerBatchDetailsType;
  updatePoultryGrowerBatchDetailsInput: UpdatePoultryGrowerBatchDetailsInput;
};

export type MutationUpdatePoultryGrowerBatchFeedBreakdownArgs = {
  batchId: Scalars['ID']['input'];
  feedType: PoultryGrowerBatchFeedBreakdownType;
  updatePoultryGrowerBatchFeedBreakdownInput: UpdatePoultryGrowerBatchFeedBreakdownInput;
};

export type MutationUpdatePoultryLayerActivityArgs = {
  id: Scalars['ID']['input'];
  updatePoultryLayerActivityInput: UpdatePoultryLayerActivityInput;
};

export type MutationUpdatePoultryLayerMonthActivityArgs = {
  id: Scalars['ID']['input'];
  updatePoultryLayerMonthActivityInput: UpdatePoultryLayerMonthActivityInput;
};

export type MutationUpdatePoultryTransactionEventArgs = {
  id: Scalars['ID']['input'];
  updatePoultryTransactionEventInput: UpdatePoultryTransactionEventInput;
};

export type MutationUpdatePropertyArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePropertyInput;
};

export type MutationUpdatePropertyInJobArgs = {
  input: UpdatePropertyInJobInput;
  jobId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
};

export type MutationUpdatePropertyNoteArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePropertyNoteInput;
};

export type MutationUpdateReportArgs = {
  id: Scalars['ID']['input'];
  input: UpdateReportInput;
};

export type MutationUpdateReportStatusInternalArgs = {
  id: Scalars['ID']['input'];
  status: ReportStatus;
};

export type MutationUpdateRouteArgs = {
  id: Scalars['ID']['input'];
  input: UpdateRouteInput;
};

export type MutationUpdateSheepLivestockActivityArgs = {
  id: Scalars['ID']['input'];
  updateSheepLivestockActivityInput: UpdateSheepLivestockActivityInput;
};

export type MutationUpdateSheepMonthLivestockActivityArgs = {
  id: Scalars['ID']['input'];
  updateSheepMonthLivestockActivityInput: UpdateSheepMonthLivestockActivityInput;
};

export type MutationUpdateSheepShearingEventArgs = {
  id: Scalars['ID']['input'];
  input: UpdateSheepShearingEventInput;
};

export type MutationUpdateVegetationActivityArgs = {
  id: Scalars['ID']['input'];
  updateVegetationActivityInput: UpdateVegetationActivityInput;
};

export type MutationUpdateWildSeaFisheryActivityArgs = {
  id: Scalars['ID']['input'];
  updateWildSeaFisheryActivityInput: UpdateWildSeaFisheryActivityInput;
};

/** Property object returned from NPG */
export type NpgProperty = {
  __typename: 'NPGProperty';
  /** Number of frontages */
  frontageCount?: Maybe<Scalars['Int']['output']>;
  /** Length of the frontage */
  frontageLength?: Maybe<Scalars['Float']['output']>;
  /** Unique NPG property ID */
  id: Scalars['ID']['output'];
  /** Number of lots for the property */
  lotCount?: Maybe<Scalars['Int']['output']>;
  /** Unique NPG ID for the property */
  nationalPropertyId: Scalars['ID']['output'];
  /** Nature of the property */
  nature?: Maybe<Scalars['String']['output']>;
};

export type Organisation = {
  __typename: 'Organisation';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  ownerId: Scalars['ID']['output'];
};

export type Parcel = {
  __typename: 'Parcel';
  /** The geoJSON feature for this parcel */
  geoFeature?: Maybe<ParcelGeoFeature>;
  /** The area of the parcel in hectares */
  hectares?: Maybe<Scalars['Float']['output']>;
  /** Unique identifier of the parcel */
  id: Scalars['ID']['output'];
  /** The center latitude of the parcel */
  latitude?: Maybe<Scalars['Latitude']['output']>;
  /** The legal number (usually lot/plan) of the parcel */
  legalNumber?: Maybe<Scalars['String']['output']>;
  /** The center longitude of the parcel */
  longitude?: Maybe<Scalars['Longitude']['output']>;
  /** The area of the parcel in square metres */
  metresSquared?: Maybe<Scalars['Int']['output']>;
  /** The unique identifier of the state (of Australia) the parcel is in */
  stateId?: Maybe<Scalars['Int']['output']>;
};

export type ParcelElastic = Feature & {
  __typename: 'ParcelElastic';
  /** The geometry of the feature */
  geometry: Scalars['GeoJSONGeometryInterface']['output'];
  /** The parcel ID */
  id: Scalars['ID']['output'];
  /** The GeoJSON properties */
  properties: ParcelElasticProperties;
  /** The GeoJSON type (always Feature) */
  type: FeatureType;
};

export type ParcelElasticProperties = FeatureProperties & {
  __typename: 'ParcelElasticProperties';
  /** The parcel ID */
  id: Scalars['ID']['output'];
  /** The name of the feature */
  name?: Maybe<Scalars['String']['output']>;
  /** The state it is in */
  state?: Maybe<Scalars['String']['output']>;
};

export type ParcelGeoFeature = Feature & {
  __typename: 'ParcelGeoFeature';
  /** The geometry of the feature */
  geometry: Scalars['GeoJSONGeometryInterface']['output'];
  /** The parcel ID */
  id: Scalars['ID']['output'];
  /** The GeoJSON properties */
  properties: ParcelGeoFeatureProperties;
  /** The GeoJSON type (always Feature) */
  type: FeatureType;
};

export type ParcelGeoFeatureProperties = FeatureProperties & {
  __typename: 'ParcelGeoFeatureProperties';
  /** The parcel ID */
  id: Scalars['ID']['output'];
  /** The name of the feature */
  name?: Maybe<Scalars['String']['output']>;
};

export type ParcelInProperty = {
  __typename: 'ParcelInProperty';
  /** The date the parcel was added to the property */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** The parcel geometry as a multi-polygon */
  geometry?: Maybe<Scalars['GeoJSONMultiPolygon']['output']>;
  /** The name of the parcel within the property */
  name?: Maybe<Scalars['String']['output']>;
  /** The parcel object */
  parcel?: Maybe<Parcel>;
  /** Unique ID for the parcel */
  parcelId?: Maybe<Scalars['String']['output']>;
  /** The property object */
  property?: Maybe<Property>;
  /** Unique ID for the property */
  propertyId?: Maybe<Scalars['String']['output']>;
  /** The date the parcelInProperty was updated */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ParcelLotPlan = {
  __typename: 'ParcelLotPlan';
  /** Are of this parcel in square meters */
  area?: Maybe<Scalars['Float']['output']>;
  /** The lot of this parcel */
  lot?: Maybe<Scalars['String']['output']>;
  /** The national parcel ID */
  nationalParcelId?: Maybe<Scalars['String']['output']>;
  /** The type of parcel */
  parcelType?: Maybe<Scalars['String']['output']>;
  /** The plan of this parcel */
  plan?: Maybe<Scalars['String']['output']>;
};

export type ParcelSearchInput = {
  bbox?: InputMaybe<Scalars['BBox2D']['input']>;
};

export enum PorkClass {
  Boars = 'BOARS',
  Gilts = 'GILTS',
  Growers = 'GROWERS',
  SlaughterPigs = 'SLAUGHTER_PIGS',
  Sows = 'SOWS',
  Suckers = 'SUCKERS',
  Weaners = 'WEANERS',
}

export type PorkEmissionsCalculation = {
  __typename: 'PorkEmissionsCalculation';
  inputs: EmissionsCalculationInputs;
  result: PorkEmissionsCalculationResult;
};

export type PorkEmissionsCalculationResult = {
  __typename: 'PorkEmissionsCalculationResult';
  carbonSequestration?: Maybe<PorkEmissionsCarbonSequestration>;
  intensities?: Maybe<PorkEmissionsIntensities>;
  net?: Maybe<PorkEmissionsNet>;
  scope1?: Maybe<PorkEmissionsScope1>;
  scope2?: Maybe<PorkEmissionsScope2>;
  scope3?: Maybe<PorkEmissionsScope3>;
};

export type PorkEmissionsCarbonSequestration = {
  __typename: 'PorkEmissionsCarbonSequestration';
  intermediate?: Maybe<PorkEmissionsTotalAverage>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type PorkEmissionsIntensities = {
  __typename: 'PorkEmissionsIntensities';
  porkMeatExcludingSequestration?: Maybe<Scalars['Float']['output']>;
  porkMeatIncludingSequestration?: Maybe<Scalars['Float']['output']>;
};

export type PorkEmissionsNet = {
  __typename: 'PorkEmissionsNet';
  total?: Maybe<Scalars['Float']['output']>;
};

export type PorkEmissionsScope1 = {
  __typename: 'PorkEmissionsScope1';
  atmosphericDepositionIndirectN2O?: Maybe<Scalars['Float']['output']>;
  atmosphericDepositionN2O?: Maybe<Scalars['Float']['output']>;
  entericCH4?: Maybe<Scalars['Float']['output']>;
  fertiliserN2O?: Maybe<Scalars['Float']['output']>;
  fuelCH4?: Maybe<Scalars['Float']['output']>;
  fuelCO2?: Maybe<Scalars['Float']['output']>;
  fuelN2O?: Maybe<Scalars['Float']['output']>;
  leachingAndRunoffMMSN2O?: Maybe<Scalars['Float']['output']>;
  leachingAndRunoffSoilN2O?: Maybe<Scalars['Float']['output']>;
  limeCO2?: Maybe<Scalars['Float']['output']>;
  manureManagementCH4?: Maybe<Scalars['Float']['output']>;
  manureManagementDirectN2O?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  totalCH4?: Maybe<Scalars['Float']['output']>;
  totalCO2?: Maybe<Scalars['Float']['output']>;
  totalN2O?: Maybe<Scalars['Float']['output']>;
  ureaCO2?: Maybe<Scalars['Float']['output']>;
};

export type PorkEmissionsScope2 = {
  __typename: 'PorkEmissionsScope2';
  electricity?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type PorkEmissionsScope3 = {
  __typename: 'PorkEmissionsScope3';
  bedding?: Maybe<Scalars['Float']['output']>;
  electricity?: Maybe<Scalars['Float']['output']>;
  fertiliser?: Maybe<Scalars['Float']['output']>;
  fuel?: Maybe<Scalars['Float']['output']>;
  herbicide?: Maybe<Scalars['Float']['output']>;
  lime?: Maybe<Scalars['Float']['output']>;
  purchasedFeed?: Maybe<Scalars['Float']['output']>;
  purchasedLivestock?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type PorkEmissionsTotalAverage = {
  __typename: 'PorkEmissionsTotalAverage';
  average?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type PorkFeedBlend = {
  __typename: 'PorkFeedBlend';
  amountPurchasedTonnes: Scalars['Decimal']['output'];
  barley: Scalars['Percent']['output'];
  beetPulp: Scalars['Percent']['output'];
  blendType: PorkFeedBlendType;
  bloodMeal: Scalars['Percent']['output'];
  canolaMeal: Scalars['Percent']['output'];
  emissionsIntensityFeedProduction?: Maybe<Scalars['Decimal']['output']>;
  fishMeal: Scalars['Percent']['output'];
  id: Scalars['ID']['output'];
  meatMeal: Scalars['Percent']['output'];
  millMix: Scalars['Percent']['output'];
  percentageAdditionalIngredients?: Maybe<Scalars['Percent']['output']>;
  porkLivestockActivityId: Scalars['ID']['output'];
  soybeanMeal: Scalars['Percent']['output'];
  tallow: Scalars['Percent']['output'];
  wheat: Scalars['Percent']['output'];
  wheatBran: Scalars['Percent']['output'];
  wheyPowder: Scalars['Percent']['output'];
};

export enum PorkFeedBlendType {
  Breeder = 'BREEDER',
  Custom = 'CUSTOM',
  GestatingSows = 'GESTATING_SOWS',
  GrowerFinisher = 'GROWER_FINISHER',
  LactatingSows = 'LACTATING_SOWS',
  Weaner = 'WEANER',
}

export type PorkLivestockActivity = Activity &
  LivestockActivity & {
    __typename: 'PorkLivestockActivity';
    boars: Scalars['Boolean']['output'];
    businessActivity: BusinessActivity;
    businessActivityId: Scalars['ID']['output'];
    end: Scalars['Date']['output'];
    feed?: Maybe<Feed>;
    feedBlends?: Maybe<Array<PorkFeedBlend>>;
    feedId?: Maybe<Scalars['ID']['output']>;
    gilts: Scalars['Boolean']['output'];
    growers: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    locations?: Maybe<Array<Maybe<ActivityLocation>>>;
    name: Scalars['String']['output'];
    slaughterPigs: Scalars['Boolean']['output'];
    sows: Scalars['Boolean']['output'];
    start: Scalars['Date']['output'];
    suckers: Scalars['Boolean']['output'];
    targetAllocations?: Maybe<Array<Maybe<ActivityAllocation>>>;
    weaners: Scalars['Boolean']['output'];
  };

export type PorkMonthLivestockActivity = {
  __typename: 'PorkMonthLivestockActivity';
  headAtStart: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  keyEvents?: Maybe<Scalars['String']['output']>;
  livestockTransactionEvents: Array<Maybe<LivestockTransactionEvent>>;
  month: Month;
  nonSalePopulationChange: Scalars['Int']['output'];
  porkClass: PorkClass;
  porkLivestockActivityId: Scalars['ID']['output'];
  year: Scalars['Int']['output'];
};

export type Portfolio = {
  __typename: 'Portfolio';
  /** The date the portfolio was created */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** The id of the user who created the portfolio */
  creatorId: Scalars['ID']['output'];
  /** The id of the portfolio */
  id: Scalars['ID']['output'];
  /** The name of the portfolio */
  name: Scalars['String']['output'];
  /** The properties in the portfolio */
  portfolioProperties: Array<PropertyInPortfolio>;
  /** The id of the team the portfolio is associated with */
  teamId: Scalars['ID']['output'];
  /** The date the portfolio was last updated */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PoultryBroilerActivity = Activity & {
  __typename: 'PoultryBroilerActivity';
  businessActivity: BusinessActivity;
  businessActivityId: Scalars['ID']['output'];
  cyclesPerYear?: Maybe<Scalars['Int']['output']>;
  end: Scalars['Date']['output'];
  growerBatches: Array<PoultryGrowerBatch>;
  id: Scalars['ID']['output'];
  locations: Array<Maybe<ActivityLocation>>;
  name: Scalars['String']['output'];
  percentManureAddedToPasture?: Maybe<Scalars['Percent']['output']>;
  percentSolidManure?: Maybe<Scalars['Percent']['output']>;
  percentageLitterRecycled?: Maybe<Scalars['Percent']['output']>;
  start: Scalars['Date']['output'];
  targetAllocations: Array<Maybe<ActivityAllocation>>;
  transactionEvents: Array<PoultryTransactionEvent>;
};

export type PoultryEmissionsCalculation = {
  __typename: 'PoultryEmissionsCalculation';
  inputs: EmissionsCalculationInputs;
  result: PoultryEmissionsCalculationResult;
};

export type PoultryEmissionsCalculationResult = {
  __typename: 'PoultryEmissionsCalculationResult';
  carbonSequestration?: Maybe<PoultryEmissionsCarbonSequestration>;
  intensities?: Maybe<PoultryEmissionsIntensities>;
  net?: Maybe<PoultryEmissionsNet>;
  scope1?: Maybe<PoultryEmissionsScope1>;
  scope2?: Maybe<PoultryEmissionsScope2>;
  scope3?: Maybe<PoultryEmissionsScope3>;
};

export type PoultryEmissionsCarbonSequestration = {
  __typename: 'PoultryEmissionsCarbonSequestration';
  intermediate?: Maybe<PoultryEmissionsTotalAverage>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type PoultryEmissionsIntensities = {
  __typename: 'PoultryEmissionsIntensities';
  poultryEggsExcludingSequestration?: Maybe<Scalars['Float']['output']>;
  poultryEggsIncludingSequestration?: Maybe<Scalars['Float']['output']>;
  poultryMeatExcludingSequestration?: Maybe<Scalars['Float']['output']>;
  poultryMeatIncludingSequestration?: Maybe<Scalars['Float']['output']>;
};

export type PoultryEmissionsNet = {
  __typename: 'PoultryEmissionsNet';
  total?: Maybe<Scalars['Float']['output']>;
};

export type PoultryEmissionsScope1 = {
  __typename: 'PoultryEmissionsScope1';
  atmosphericDepositionN2O?: Maybe<Scalars['Float']['output']>;
  fuelCH4?: Maybe<Scalars['Float']['output']>;
  fuelCO2?: Maybe<Scalars['Float']['output']>;
  fuelN2O?: Maybe<Scalars['Float']['output']>;
  leachingAndRunoffN2O?: Maybe<Scalars['Float']['output']>;
  manureManagementCH4?: Maybe<Scalars['Float']['output']>;
  manureManagementN2O?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  totalCH4?: Maybe<Scalars['Float']['output']>;
  totalCO2?: Maybe<Scalars['Float']['output']>;
  totalN2O?: Maybe<Scalars['Float']['output']>;
};

export type PoultryEmissionsScope2 = {
  __typename: 'PoultryEmissionsScope2';
  electricity?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type PoultryEmissionsScope3 = {
  __typename: 'PoultryEmissionsScope3';
  electricity?: Maybe<Scalars['Float']['output']>;
  fuel?: Maybe<Scalars['Float']['output']>;
  herbicide?: Maybe<Scalars['Float']['output']>;
  purchasedFeed?: Maybe<Scalars['Float']['output']>;
  purchasedHay?: Maybe<Scalars['Float']['output']>;
  purchasedLivestock?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type PoultryEmissionsTotalAverage = {
  __typename: 'PoultryEmissionsTotalAverage';
  average?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type PoultryFeedBlend = {
  __typename: 'PoultryFeedBlend';
  amountPurchasedTonnes: Scalars['Decimal']['output'];
  barleyWhole?: Maybe<Scalars['Percent']['output']>;
  blendType: PoultryFeedBlendType;
  emissionsIntensityFeedProduction?: Maybe<Scalars['Decimal']['output']>;
  id: Scalars['ID']['output'];
  millRun?: Maybe<Scalars['Percent']['output']>;
  percentageAdditionalIngredients?: Maybe<Scalars['Decimal']['output']>;
  sorghum?: Maybe<Scalars['Percent']['output']>;
  soybean?: Maybe<Scalars['Percent']['output']>;
  wheat?: Maybe<Scalars['Percent']['output']>;
};

export enum PoultryFeedBlendType {
  BreederGrower = 'BREEDER_GROWER',
  BreederProduction = 'BREEDER_PRODUCTION',
  BreederStarter = 'BREEDER_STARTER',
  BroilerFinish = 'BROILER_FINISH',
  BroilerGrower = 'BROILER_GROWER',
  BroilerStarter = 'BROILER_STARTER',
  BroilerWithdrawal = 'BROILER_WITHDRAWAL',
  Custom = 'CUSTOM',
  FrFinish = 'FR_FINISH',
  FrGrower = 'FR_GROWER',
  FrStarter = 'FR_STARTER',
  FrWithdrawal = 'FR_WITHDRAWAL',
  PreBreederProduction = 'PRE_BREEDER_PRODUCTION',
  PreBreederRearer = 'PRE_BREEDER_REARER',
}

export type PoultryFeedBreakdown = {
  __typename: 'PoultryFeedBreakdown';
  crudeProteinPercent: Scalars['Percent']['output'];
  dryMatterDigestibilityPercent: Scalars['Percent']['output'];
  dryMatterIntakeKgPerHeadPerDay: Scalars['Decimal']['output'];
  manureAshPercent: Scalars['Percent']['output'];
  nitrogenRetentionPercent: Scalars['Percent']['output'];
};

export type PoultryGrowerBatch = {
  __typename: 'PoultryGrowerBatch';
  endDate: Scalars['Date']['output'];
  feedBlends: Array<PoultryFeedBlend>;
  grazingCrops: Scalars['Boolean']['output'];
  growerDetails?: Maybe<PoultryGrowerBatchDetails>;
  growers: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  layerDetails?: Maybe<PoultryGrowerBatchDetails>;
  layers: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  other: Scalars['Boolean']['output'];
  otherDetails?: Maybe<PoultryGrowerBatchDetails>;
  pasture: Scalars['Boolean']['output'];
  startDate: Scalars['Date']['output'];
};

export type PoultryGrowerBatchDetails = {
  __typename: 'PoultryGrowerBatchDetails';
  averageLengthOfStayDaysL1: Scalars['Int']['output'];
  averageLengthOfStayDaysL2: Scalars['Int']['output'];
  averageLiveweightPerHeadKgW1: Scalars['Decimal']['output'];
  averageLiveweightPerHeadKgW2: Scalars['Decimal']['output'];
  totalNumberOfBirds: Scalars['Int']['output'];
};

export enum PoultryGrowerBatchDetailsType {
  Growers = 'GROWERS',
  Layers = 'LAYERS',
  Other = 'OTHER',
}

export enum PoultryGrowerBatchFeedBreakdownType {
  Growers = 'GROWERS',
  Layers = 'LAYERS',
  Other = 'OTHER',
}

export type PoultryLayerActivity = Activity & {
  __typename: 'PoultryLayerActivity';
  averageLayerEggWeightGrams?: Maybe<Scalars['Decimal']['output']>;
  averageMeatChickenLayerEggWeightGrams?: Maybe<Scalars['Decimal']['output']>;
  businessActivity: BusinessActivity;
  businessActivityId: Scalars['ID']['output'];
  cyclesPerYear?: Maybe<Scalars['Int']['output']>;
  end: Scalars['Date']['output'];
  feedBlends: Array<PoultryFeedBlend>;
  grazingCrops: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  locations: Array<Maybe<ActivityLocation>>;
  name: Scalars['String']['output'];
  pasture: Scalars['Boolean']['output'];
  percentManureAddedToPasture?: Maybe<Scalars['Percent']['output']>;
  percentSolidManure?: Maybe<Scalars['Percent']['output']>;
  percentageLitterRecycled?: Maybe<Scalars['Percent']['output']>;
  poultryLayerMonthActivities: Array<PoultryLayerMonthActivity>;
  start: Scalars['Date']['output'];
  targetAllocations: Array<Maybe<ActivityAllocation>>;
  totalLayerEggsProduced?: Maybe<Scalars['Int']['output']>;
  totalMeatChickenLayerEggsProduced?: Maybe<Scalars['Int']['output']>;
  transactionEvents: Array<PoultryTransactionEvent>;
};

export type PoultryLayerMonthActivity = {
  __typename: 'PoultryLayerMonthActivity';
  id: Scalars['ID']['output'];
  layerCount: Scalars['Int']['output'];
  meatChickenLayerCount: Scalars['Int']['output'];
  month: Month;
  year: Scalars['Int']['output'];
};

export enum PoultryTransactionClass {
  Layers = 'LAYERS',
  MeatGrowers = 'MEAT_GROWERS',
  MeatLayers = 'MEAT_LAYERS',
  MeatOther = 'MEAT_OTHER',
}

export type PoultryTransactionEvent = {
  __typename: 'PoultryTransactionEvent';
  averageWeightKg?: Maybe<Scalars['Decimal']['output']>;
  class: PoultryTransactionClass;
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  livestockCount: Scalars['Int']['output'];
  percentPurchasedFreeRange?: Maybe<Scalars['Percent']['output']>;
  totalWeightKg: Scalars['Decimal']['output'];
  transactionRegion?: Maybe<TransactionRegion>;
  transactionType: TransactionType;
};

export type Property = {
  __typename: 'Property';
  /** The address line 1 of the property */
  addressLine1?: Maybe<Scalars['String']['output']>;
  /** The address line 2 of the property */
  addressLine2?: Maybe<Scalars['String']['output']>;
  /** The property's bounding box */
  bbox?: Maybe<Scalars['BBox2D']['output']>;
  /** The property's center point */
  centroid?: Maybe<Scalars['GeoJSONPoint']['output']>;
  /** The city the property is in */
  city?: Maybe<Scalars['String']['output']>;
  /** The properties' vector for CMA */
  cmaVector?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  /** Saved comparable sales for this property */
  comparableSales: Array<ComparableSale>;
  /** The country the property is in */
  country?: Maybe<Scalars['String']['output']>;
  /** The country code of the property */
  countryCode?: Maybe<Scalars['String']['output']>;
  /** The date the property was created */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  files: Array<File>;
  /** Unique identifier for the property */
  id: Scalars['ID']['output'];
  /** The inspections for this property */
  inspections?: Maybe<Array<Maybe<Inspection>>>;
  /** The name of the property */
  name?: Maybe<Scalars['String']['output']>;
  /** The postcode of the property */
  postcode?: Maybe<Scalars['String']['output']>;
  /** The ID for the predecessor property */
  predecessorId?: Maybe<Scalars['ID']['output']>;
  /** Jobs this Property is associated with */
  propertyJobs?: Maybe<Array<Maybe<PropertyInJob>>>;
  /** The property notes attached to this property */
  propertyNotes?: Maybe<Array<Maybe<PropertyNote>>>;
  /** The ParcelInProperty objects associated with the property */
  propertyParcels?: Maybe<Array<Maybe<ParcelInProperty>>>;
  /** The portfolios this property is associated with */
  propertyPortfolios: Array<PropertyInPortfolio>;
  /** The reports for this property */
  reports?: Maybe<Array<Maybe<Report>>>;
  /** The properties sales history and summary data */
  salesSummary?: Maybe<PropertySalesData>;
  /** The state of Australia the property is in */
  state?: Maybe<Scalars['String']['output']>;
  /** The ID for the team the property belongs to. */
  teamId: Scalars['ID']['output'];
  /** The date the property was last updated */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PropertyGeoFeature = Feature & {
  __typename: 'PropertyGeoFeature';
  /** The geometry of the feature */
  geometry: Scalars['GeoJSONGeometryInterface']['output'];
  /** The id built from the composite primary key for this feature (In the format: <propertyId>-<featureType>-<featureId>) */
  id: Scalars['ID']['output'];
  /** The properties attached to the feature */
  properties: PropertyGeoFeatureProperties;
  /** The GeoJSON type (always Feature) */
  type: FeatureType;
};

export type PropertyGeoFeatureCollection = FeatureCollection & {
  __typename: 'PropertyGeoFeatureCollection';
  /** The features */
  features: Array<PropertyGeoFeature>;
  /** The GeoJSON type (always FeatureCollection) */
  type: FeatureCollectionType;
};

export type PropertyGeoFeatureProperties = FeatureProperties & {
  __typename: 'PropertyGeoFeatureProperties';
  /** The id of the feature (e.g. the parcel id) */
  id: Scalars['ID']['output'];
  /** The name of the feature */
  name?: Maybe<Scalars['String']['output']>;
  /** The type of the feature */
  type: GeoFeatureType;
};

export type PropertyInJob = {
  __typename: 'PropertyInJob';
  /** The date the property was added to the job. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The job object. */
  job: Job;
  /** The job ID. */
  jobId: Scalars['ID']['output'];
  /** The name of the property inside the job. */
  name?: Maybe<Scalars['String']['output']>;
  /** The property object. */
  property: Property;
  /** The property ID. */
  propertyId: Scalars['ID']['output'];
  /** The date the property to job relationship was last updated. */
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type PropertyInPortfolio = {
  __typename: 'PropertyInPortfolio';
  /** The date the property was added to the portfolio */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** The portfolio object */
  portfolio: Portfolio;
  /** The portfolio's ID */
  portfolioId: Scalars['ID']['output'];
  /** The property object */
  property: Property;
  /** The property's ID */
  propertyId: Scalars['ID']['output'];
  /** The date the property in the portfolio was last updated */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PropertyInSale = {
  __typename: 'PropertyInSale';
  /** The date the property was added to the sale */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** The property object */
  property: Property;
  /** The property's ID */
  propertyId: Scalars['ID']['output'];
  /** The sale object */
  sale: Sale;
  /** The sale's ID */
  saleId: Scalars['ID']['output'];
  /** The date the property in the sale was last updated */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PropertyNote = {
  __typename: 'PropertyNote';
  /** The date the note was created */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** The user who created the note */
  creator?: Maybe<User>;
  /** The id of the user who created the note */
  creatorId: Scalars['ID']['output'];
  /** The id of the note */
  id: Scalars['ID']['output'];
  /** The property the note is associated with */
  property?: Maybe<Property>;
  /** The id of the property the note is associated with */
  propertyId: Scalars['ID']['output'];
  /** The id of the team the note is associated with */
  teamId: Scalars['ID']['output'];
  /** The text content of the note */
  text: Scalars['String']['output'];
  /** The title of the note */
  title: Scalars['String']['output'];
  /** The date the note was last updated */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The user who last updated the note */
  updater?: Maybe<User>;
  /** The id of the user who last updated the note */
  updaterId?: Maybe<Scalars['ID']['output']>;
};

export type PropertyOrderByInput = {
  name?: InputMaybe<SortOrder>;
};

export type PropertySalesData = {
  __typename: 'PropertySalesData';
  propertySalesHistory?: Maybe<SalesDataItem>;
  summaryAveragePriceHistory?: Maybe<SalesDataItem>;
  summaryPriceDistribution?: Maybe<SalesDataItem>;
  summaryPricePerAreaDistribution?: Maybe<SalesDataItem>;
  summarySaleCountHistory?: Maybe<SalesDataItem>;
};

export type Query = {
  __typename: 'Query';
  /** Get all activities for a property */
  activities: Array<Activity>;
  activity?: Maybe<Activity>;
  advisorTeams: Array<Team>;
  allEmissions?: Maybe<AllEmissionsCalculation>;
  baitPurchasesByWildSeaFisheryActivityId: Array<FisheryBaitPurchase>;
  beefEmissions: SheepBeefEmissionsCalculation;
  /** Get a beef livestock activity by id */
  beefLivestockActivity?: Maybe<BeefLivestockActivity>;
  /** Get all beef month livestock activities for a beef livestock activity */
  beefMonthLivestockActivities: Array<Maybe<BeefMonthLivestockActivity>>;
  /** Get a beef month livestock activity by id */
  beefMonthLivestockActivity: BeefMonthLivestockActivity;
  buffaloEmissions?: Maybe<BuffaloEmissionsCalculation>;
  buffaloLivestockActivity?: Maybe<BuffaloLivestockActivity>;
  buffaloMonthLivestockActivities: Array<Maybe<BuffaloMonthLivestockActivity>>;
  buffaloMonthLivestockActivity: BuffaloMonthLivestockActivity;
  burningActivity?: Maybe<BurningActivity>;
  businessActivity?: Maybe<BusinessActivity>;
  businessActivityForProperty?: Maybe<BusinessActivity>;
  carbamate?: Maybe<Carbamate>;
  /** Get all CMA reports for a property. */
  cmaReports: Array<Report>;
  /** Get a single comparable sale */
  comparableSale?: Maybe<ComparableSale>;
  comparableSalesForProperty: Array<ComparableSale>;
  /** Get all comparable sales for a given property ID */
  comparableSalesSearch: Array<ComparableSaleSearchResult>;
  consumption?: Maybe<Consumption>;
  consumptions?: Maybe<Array<Consumption>>;
  cottonActivity?: Maybe<CottonActivity>;
  cottonEmissions?: Maybe<CottonEmissionsCalculation>;
  cropVariety?: Maybe<CropVariety>;
  croppingActivity?: Maybe<CroppingActivity>;
  dairyEmissions?: Maybe<DairyEmissionsCalculation>;
  /** Get a dairy activity by id */
  dairyLivestockActivity?: Maybe<DairyLivestockActivity>;
  /** Get all dairy month activities for a dairy activity */
  dairyMonthLivestockActivities: Array<DairyMonthLivestockActivity>;
  /** Get a dairy month activity by id */
  dairyMonthLivestockActivity: DairyMonthLivestockActivity;
  deerEmissions?: Maybe<DeerEmissionsCalculation>;
  deerLivestockActivity?: Maybe<DeerLivestockActivity>;
  deerMonthLivestockActivities: Array<Maybe<DeerMonthLivestockActivity>>;
  deerMonthLivestockActivity: DeerMonthLivestockActivity;
  /** Get all DEFORESTATION reports for a property. */
  deforestationReports: Array<Report>;
  /** Get a feed by ID */
  feed: Feed;
  feedlotActivity?: Maybe<FeedlotActivity>;
  feedlotEmissions?: Maybe<FeedlotEmissionsCalculation>;
  feedlotFeedBreakdown?: Maybe<FeedlotFeedBreakdown>;
  feedlotFeedComposition?: Maybe<FeedlotFeedComposition>;
  feedlotGroup?: Maybe<FeedlotGroup>;
  feedlotPurchase?: Maybe<FeedlotPurchase>;
  feedlotSale?: Maybe<FeedlotSale>;
  fertiliser?: Maybe<Fertiliser>;
  file?: Maybe<File>;
  fisheryBaitPurchase: FisheryBaitPurchase;
  /** Get geographic information for a given geoJSON point */
  geographicInformationForPoint?: Maybe<GeographicInformation>;
  goatEmissions?: Maybe<GoatEmissionsCalculation>;
  goatLivestockActivity?: Maybe<GoatLivestockActivity>;
  goatMonthLivestockActivities: Array<Maybe<GoatMonthLivestockActivity>>;
  goatMonthLivestockActivity: GoatMonthLivestockActivity;
  goatShearingEvent: GoatShearingEvent;
  grainsEmissions?: Maybe<GrainsEmissionsCalculation>;
  horticultureEmissions?: Maybe<HorticultureEmissionsCalculation>;
  /** Get a job by ID. */
  job?: Maybe<Job>;
  /** Get all KYE reports for a property. */
  kyeReports: Array<Report>;
  lime?: Maybe<Lime>;
  livestockTransactionEvent?: Maybe<LivestockTransactionEvent>;
  /** Get a map shape by id */
  mapShape: MapShape;
  /** Get all map shapes for a property */
  mapShapes: Array<MapShape>;
  /** Get a parcel by ID */
  parcel?: Maybe<Parcel>;
  porkEmissions?: Maybe<PorkEmissionsCalculation>;
  porkFeedBlend?: Maybe<PorkFeedBlend>;
  porkLivestockActivity?: Maybe<PorkLivestockActivity>;
  porkMonthLivestockActivities: Array<Maybe<PorkMonthLivestockActivity>>;
  porkMonthLivestockActivity: PorkMonthLivestockActivity;
  portfolio: Portfolio;
  portfoliosForTeam: Array<Portfolio>;
  poultryBroilerActivity?: Maybe<PoultryBroilerActivity>;
  poultryBroilerEmissions?: Maybe<PoultryEmissionsCalculation>;
  poultryFeedBlend?: Maybe<PoultryFeedBlend>;
  poultryGrowerBatch?: Maybe<PoultryGrowerBatch>;
  poultryGrowerBatchDetails?: Maybe<PoultryGrowerBatchDetails>;
  poultryGrowerBatchFeedBreakdown?: Maybe<PoultryFeedBreakdown>;
  poultryLayerActivity?: Maybe<PoultryLayerActivity>;
  poultryLayerEmissions?: Maybe<PoultryEmissionsCalculation>;
  poultryLayerMonthActivity?: Maybe<PoultryLayerMonthActivity>;
  poultryTransactionEvent?: Maybe<PoultryTransactionEvent>;
  /** Get all properties for a team */
  properties: Array<Property>;
  /** Get all properties for a team which are not associated with any jobs */
  propertiesWithoutJobs: Array<Property>;
  /** Get a property by ID */
  property?: Maybe<Property>;
  propertyNote?: Maybe<PropertyNote>;
  /** Get a report by ID. */
  report?: Maybe<Report>;
  route: Route;
  routesByActivityId: Array<Route>;
  searchParcels?: Maybe<Array<Maybe<ParcelElastic>>>;
  shearingEventsByGoatLivestockActivityId: Array<Maybe<GoatShearingEvent>>;
  shearingEventsBySheepLivestockActivityId: Array<Maybe<SheepShearingEvent>>;
  /** @deprecated Use sheepEmissions or beefEmissions instead */
  sheepBeefEmissions?: Maybe<SheepBeefEmissionsCalculation>;
  sheepEmissions: SheepBeefEmissionsCalculation;
  sheepLivestockActivity?: Maybe<SheepLivestockActivity>;
  sheepMonthLivestockActivities: Array<Maybe<SheepMonthLivestockActivity>>;
  sheepMonthLivestockActivity: SheepMonthLivestockActivity;
  sheepShearingEvent: SheepShearingEvent;
  sugarEmissions?: Maybe<SugarEmissionsCalculation>;
  vegetationActivity?: Maybe<VegetationActivity>;
  wildSeaFisheryActivity?: Maybe<WildSeaFisheryActivity>;
  wildSeaFisheryEmissions?: Maybe<WildSeaFisheryEmissionsCalculation>;
};

export type QueryActivitiesArgs = {
  params?: InputMaybe<ActivityQueryParamsInput>;
  propertyId: Scalars['ID']['input'];
};

export type QueryActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAdvisorTeamsArgs = {
  userId: Scalars['ID']['input'];
};

export type QueryAllEmissionsArgs = {
  filter?: InputMaybe<EmissionsFilterInput>;
  propertyId: Scalars['ID']['input'];
};

export type QueryBaitPurchasesByWildSeaFisheryActivityIdArgs = {
  wildSeaFisheryActivityId: Scalars['ID']['input'];
};

export type QueryBeefEmissionsArgs = {
  filter?: InputMaybe<EmissionsFilterInput>;
  propertyId: Scalars['ID']['input'];
};

export type QueryBeefLivestockActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryBeefMonthLivestockActivitiesArgs = {
  listBeefMonthLivestockActivitiesInput: ListBeefMonthLivestockActivitiesInput;
};

export type QueryBeefMonthLivestockActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryBuffaloEmissionsArgs = {
  filter?: InputMaybe<EmissionsFilterInput>;
  propertyId: Scalars['ID']['input'];
};

export type QueryBuffaloLivestockActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryBuffaloMonthLivestockActivitiesArgs = {
  listBuffaloMonthLivestockActivitiesInput: ListBuffaloMonthLivestockActivitiesInput;
};

export type QueryBuffaloMonthLivestockActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryBurningActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryBusinessActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryBusinessActivityForPropertyArgs = {
  propertyId: Scalars['ID']['input'];
};

export type QueryCarbamateArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCmaReportsArgs = {
  propertyId: Scalars['ID']['input'];
};

export type QueryComparableSaleArgs = {
  input: GetComparableSaleInput;
};

export type QueryComparableSalesForPropertyArgs = {
  propertyId: Scalars['ID']['input'];
};

export type QueryComparableSalesSearchArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  maxArea?: InputMaybe<Scalars['Int']['input']>;
  maxPrice?: InputMaybe<Scalars['Int']['input']>;
  minArea?: InputMaybe<Scalars['Int']['input']>;
  minPrice?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ComparableSaleSearchOrderBy>;
  propertyId: Scalars['String']['input'];
  radius: Scalars['Int']['input'];
  similarity: Scalars['Percent']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryConsumptionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryConsumptionsArgs = {
  propertyId: Scalars['ID']['input'];
};

export type QueryCottonActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCottonEmissionsArgs = {
  filter?: InputMaybe<EmissionsFilterInput>;
  propertyId: Scalars['ID']['input'];
};

export type QueryCropVarietyArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCroppingActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDairyEmissionsArgs = {
  filter?: InputMaybe<EmissionsFilterInput>;
  propertyId: Scalars['ID']['input'];
};

export type QueryDairyLivestockActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDairyMonthLivestockActivitiesArgs = {
  listDairyMonthLivestockActivitiesInput: ListDairyMonthLivestockActivitiesInput;
};

export type QueryDairyMonthLivestockActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDeerEmissionsArgs = {
  filter?: InputMaybe<EmissionsFilterInput>;
  propertyId: Scalars['ID']['input'];
};

export type QueryDeerLivestockActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDeerMonthLivestockActivitiesArgs = {
  listDeerMonthLivestockActivitiesInput: ListDeerMonthLivestockActivitiesInput;
};

export type QueryDeerMonthLivestockActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDeforestationReportsArgs = {
  propertyId: Scalars['ID']['input'];
};

export type QueryFeedArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFeedlotActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFeedlotEmissionsArgs = {
  filter?: InputMaybe<EmissionsFilterInput>;
  propertyId: Scalars['ID']['input'];
};

export type QueryFeedlotFeedBreakdownArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFeedlotFeedCompositionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFeedlotGroupArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFeedlotPurchaseArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFeedlotSaleArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFertiliserArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFileArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFisheryBaitPurchaseArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGeographicInformationForPointArgs = {
  point?: InputMaybe<Scalars['GeoJSONPoint']['input']>;
};

export type QueryGoatEmissionsArgs = {
  filter?: InputMaybe<EmissionsFilterInput>;
  propertyId: Scalars['ID']['input'];
};

export type QueryGoatLivestockActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGoatMonthLivestockActivitiesArgs = {
  listGoatMonthLivestockActivitiesInput: ListGoatMonthLivestockActivitiesInput;
};

export type QueryGoatMonthLivestockActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGoatShearingEventArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGrainsEmissionsArgs = {
  filter?: InputMaybe<EmissionsFilterInput>;
  propertyId: Scalars['ID']['input'];
};

export type QueryHorticultureEmissionsArgs = {
  filter?: InputMaybe<EmissionsFilterInput>;
  propertyId: Scalars['ID']['input'];
};

export type QueryJobArgs = {
  id: Scalars['ID']['input'];
};

export type QueryKyeReportsArgs = {
  propertyId: Scalars['ID']['input'];
};

export type QueryLimeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryLivestockTransactionEventArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMapShapeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMapShapesArgs = {
  propertyId: Scalars['ID']['input'];
};

export type QueryParcelArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPorkEmissionsArgs = {
  filter?: InputMaybe<EmissionsFilterInput>;
  propertyId: Scalars['ID']['input'];
};

export type QueryPorkFeedBlendArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPorkLivestockActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPorkMonthLivestockActivitiesArgs = {
  listPorkMonthLivestockActivitiesInput: ListPorkMonthLivestockActivitiesInput;
};

export type QueryPorkMonthLivestockActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPortfolioArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPortfoliosForTeamArgs = {
  teamId: Scalars['ID']['input'];
};

export type QueryPoultryBroilerActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPoultryBroilerEmissionsArgs = {
  filter?: InputMaybe<EmissionsFilterInput>;
  propertyId: Scalars['ID']['input'];
};

export type QueryPoultryFeedBlendArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPoultryGrowerBatchArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPoultryGrowerBatchDetailsArgs = {
  batchId: Scalars['ID']['input'];
  detailsType?: InputMaybe<PoultryGrowerBatchDetailsType>;
};

export type QueryPoultryGrowerBatchFeedBreakdownArgs = {
  batchId: Scalars['ID']['input'];
  feedType?: InputMaybe<PoultryGrowerBatchFeedBreakdownType>;
};

export type QueryPoultryLayerActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPoultryLayerEmissionsArgs = {
  filter?: InputMaybe<EmissionsFilterInput>;
  propertyId: Scalars['ID']['input'];
};

export type QueryPoultryLayerMonthActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPoultryTransactionEventArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPropertiesArgs = {
  orderBy?: InputMaybe<PropertyOrderByInput>;
  teamId: Scalars['ID']['input'];
};

export type QueryPropertiesWithoutJobsArgs = {
  teamId: Scalars['ID']['input'];
};

export type QueryPropertyArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPropertyNoteArgs = {
  id: Scalars['ID']['input'];
};

export type QueryReportArgs = {
  id: Scalars['ID']['input'];
};

export type QueryRouteArgs = {
  id: Scalars['ID']['input'];
};

export type QueryRoutesByActivityIdArgs = {
  activityId: Scalars['ID']['input'];
};

export type QuerySearchParcelsArgs = {
  input: ParcelSearchInput;
};

export type QueryShearingEventsByGoatLivestockActivityIdArgs = {
  goatLivestockActivityId: Scalars['ID']['input'];
};

export type QueryShearingEventsBySheepLivestockActivityIdArgs = {
  sheepLivestockActivityId: Scalars['ID']['input'];
};

export type QuerySheepBeefEmissionsArgs = {
  filter?: InputMaybe<EmissionsFilterInput>;
  propertyId: Scalars['ID']['input'];
};

export type QuerySheepEmissionsArgs = {
  filter?: InputMaybe<EmissionsFilterInput>;
  propertyId: Scalars['ID']['input'];
};

export type QuerySheepLivestockActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySheepMonthLivestockActivitiesArgs = {
  listSheepMonthLivestockActivitiesInput: ListSheepMonthLivestockActivitiesInput;
};

export type QuerySheepMonthLivestockActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySheepShearingEventArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySugarEmissionsArgs = {
  filter?: InputMaybe<EmissionsFilterInput>;
  propertyId: Scalars['ID']['input'];
};

export type QueryVegetationActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryWildSeaFisheryActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryWildSeaFisheryEmissionsArgs = {
  filter?: InputMaybe<EmissionsFilterInput>;
  propertyId: Scalars['ID']['input'];
};

/** Values used to rate something */
export enum Rating {
  /** High (4/5) */
  High = 'HIGH',
  /** Low (2/5) */
  Low = 'LOW',
  /** Moderate (3/5) */
  Moderate = 'MODERATE',
  /** The rating is unknown */
  Unknown = 'UNKNOWN',
  /** Very high (5/5) */
  VeryHigh = 'VERY_HIGH',
  /** Very low (1/5) */
  VeryLow = 'VERY_LOW',
}

/** Report object */
export type Report = {
  __typename: 'Report';
  /** Comparable property sales */
  comparableSales: Array<ComparableSale>;
  /** The date the report was created. */
  createdAt: Scalars['DateTime']['output'];
  /** Report data */
  data?: Maybe<ReportData>;
  /** Report boundary as a GeoJSON MultiPolygon */
  geometry?: Maybe<Scalars['GeoJSONMultiPolygon']['output']>;
  /** Report id */
  id: Scalars['ID']['output'];
  /** Report name */
  name: Scalars['String']['output'];
  /** Report products */
  products: Array<ReportProduct>;
  /** The property the report is for. */
  property: Property;
  /** The ID of the property the report is for. */
  propertyId: Scalars['ID']['output'];
  /** Enabled report sections */
  sections: Array<ReportSection>;
  /** A short term signed URL to download the PDF report. */
  signedPdfUrl: Scalars['String']['output'];
  /** Report status */
  status: ReportStatus;
  /** The ID of the team the report belongs to. */
  teamId: Scalars['ID']['output'];
  /** Report type */
  type: ReportType;
  /** The date the report was last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** The user ID who created the report. */
  userId: Scalars['ID']['output'];
};

export type ReportData = {
  __typename: 'ReportData';
  buildingsGeometries?: Maybe<ReportDataItem>;
  buildingsList?: Maybe<ReportDataItem>;
  burnedAreaEventImages?: Maybe<ReportDataItem>;
  burnedAreaEvents?: Maybe<ReportDataItem>;
  climateRiskStatistics?: Maybe<ReportDataItem>;
  climateRiskYears?: Maybe<ReportDataItem>;
  climateSSP126RainfallYearly?: Maybe<ReportDataItem>;
  climateSSP126RainfallYearlyStdDev?: Maybe<ReportDataItem>;
  climateSSP126TempMaxYearly?: Maybe<ReportDataItem>;
  climateSSP126TempMaxYearlyStdDev?: Maybe<ReportDataItem>;
  climateSSP126TempMinYearly?: Maybe<ReportDataItem>;
  climateSSP126TempMinYearlyStdDev?: Maybe<ReportDataItem>;
  clumClassesLevel1?: Maybe<ReportDataItem>;
  clumClassesLevel1Image?: Maybe<ReportDataItem>;
  clumClassesLevel2?: Maybe<ReportDataItem>;
  clumClassesLevel2Image?: Maybe<ReportDataItem>;
  deforestationColorMap?: Maybe<ReportDataItem>;
  deforestationDataQuality?: Maybe<ReportDataItem>;
  deforestationNdviYearlyImages?: Maybe<ReportDataItem>;
  deforestationRgbYearlyImages?: Maybe<ReportDataItem>;
  deforestationYearly?: Maybe<ReportDataItem>;
  deforestationYearlyImages?: Maybe<ReportDataItem>;
  elevationArable?: Maybe<ReportDataItem>;
  elevationArableImage?: Maybe<ReportDataItem>;
  elevationElevationImage?: Maybe<ReportDataItem>;
  elevationSlope?: Maybe<ReportDataItem>;
  elevationSlopeImage?: Maybe<ReportDataItem>;
  elevationStatistics?: Maybe<ReportDataItem>;
  eviAreaProductivity?: Maybe<ReportDataItem>;
  eviAreaYearlyImages?: Maybe<ReportDataItem>;
  forestClasses?: Maybe<ReportDataItem>;
  forestClassesImage?: Maybe<ReportDataItem>;
  frostDurationsHistory?: Maybe<ReportDataItem>;
  frostDurationsVolume?: Maybe<ReportDataItem>;
  frostDurationsYtd?: Maybe<ReportDataItem>;
  frostDurationsYtdMinus1?: Maybe<ReportDataItem>;
  frostDurationsYtdMinus2?: Maybe<ReportDataItem>;
  frostEventsHistory?: Maybe<ReportDataItem>;
  frostEventsVolume?: Maybe<ReportDataItem>;
  frostEventsYtd?: Maybe<ReportDataItem>;
  frostEventsYtdMinus1?: Maybe<ReportDataItem>;
  frostEventsYtdMinus2?: Maybe<ReportDataItem>;
  grasslandFireMaximum?: Maybe<ReportDataItem>;
  grasslandFireMonthly?: Maybe<ReportDataItem>;
  heatDurationsHistory?: Maybe<ReportDataItem>;
  heatDurationsVolume?: Maybe<ReportDataItem>;
  heatDurationsYtd?: Maybe<ReportDataItem>;
  heatDurationsYtdMinus1?: Maybe<ReportDataItem>;
  heatDurationsYtdMinus2?: Maybe<ReportDataItem>;
  heatEventsHistory?: Maybe<ReportDataItem>;
  heatEventsVolume?: Maybe<ReportDataItem>;
  heatEventsYtd?: Maybe<ReportDataItem>;
  heatEventsYtdMinus1?: Maybe<ReportDataItem>;
  heatEventsYtdMinus2?: Maybe<ReportDataItem>;
  landCoverClasses?: Maybe<ReportDataItem>;
  landCoverClassesImage?: Maybe<ReportDataItem>;
  landUseClasses?: Maybe<ReportDataItem>;
  landUseClassesImage?: Maybe<ReportDataItem>;
  landmarksCities?: Maybe<ReportDataItem>;
  landmarksLocation?: Maybe<ReportDataItem>;
  landmarksSchools?: Maybe<ReportDataItem>;
  landmarksSilos?: Maybe<ReportDataItem>;
  landmarksTowns?: Maybe<ReportDataItem>;
  propertyParcelsGeoJson?: Maybe<ReportDataItem>;
  propertyParcelsList?: Maybe<ReportDataItem>;
  propertySite?: Maybe<ReportDataItem>;
  qldPmavClasses?: Maybe<ReportDataItem>;
  qldPmavClassesImage?: Maybe<ReportDataItem>;
  rainfallDuration?: Maybe<ReportDataItem>;
  rainfallHistory?: Maybe<ReportDataItem>;
  rainfallStatistics?: Maybe<ReportDataItem>;
  rainfallVolume?: Maybe<ReportDataItem>;
  rainfallYtd?: Maybe<ReportDataItem>;
  rainfallYtdMinus1?: Maybe<ReportDataItem>;
  rainfallYtdMinus2?: Maybe<ReportDataItem>;
  reportMetadata?: Maybe<ReportDataItem>;
  salesAveragePriceHistory?: Maybe<ReportDataItem>;
  salesPriceDistribution?: Maybe<ReportDataItem>;
  salesPricePerAreaDistribution?: Maybe<ReportDataItem>;
  salesPropertyHistory?: Maybe<ReportDataItem>;
  salesSaleCountHistory?: Maybe<ReportDataItem>;
  satelliteNdmiMonthlyImages?: Maybe<ReportDataItem>;
  satelliteNdviMonthlyImages?: Maybe<ReportDataItem>;
  satelliteNdviYearlyImages?: Maybe<ReportDataItem>;
  satelliteRgbMonthlyImages?: Maybe<ReportDataItem>;
  satelliteRgbYearlyImages?: Maybe<ReportDataItem>;
  soilAusClasses?: Maybe<ReportDataItem>;
  soilAusClassesImage?: Maybe<ReportDataItem>;
  soilAwcImage?: Maybe<ReportDataItem>;
  soilAwcProfile?: Maybe<ReportDataItem>;
  soilClayImage?: Maybe<ReportDataItem>;
  soilClayProfile?: Maybe<ReportDataItem>;
  soilOcImage?: Maybe<ReportDataItem>;
  soilOcProfile?: Maybe<ReportDataItem>;
  soilPhImage?: Maybe<ReportDataItem>;
  soilPhProfile?: Maybe<ReportDataItem>;
  soilSandImage?: Maybe<ReportDataItem>;
  soilSandProfile?: Maybe<ReportDataItem>;
  soilSiltImage?: Maybe<ReportDataItem>;
  soilSiltProfile?: Maybe<ReportDataItem>;
  temperatureMaxHistory?: Maybe<ReportDataItem>;
  temperatureMaxVolume?: Maybe<ReportDataItem>;
  temperatureMaxYtd?: Maybe<ReportDataItem>;
  temperatureMaxYtdMinus1?: Maybe<ReportDataItem>;
  temperatureMaxYtdMinus2?: Maybe<ReportDataItem>;
  temperatureMeanHistory?: Maybe<ReportDataItem>;
  temperatureMeanVolume?: Maybe<ReportDataItem>;
  temperatureMeanYtd?: Maybe<ReportDataItem>;
  temperatureMeanYtdMinus1?: Maybe<ReportDataItem>;
  temperatureMeanYtdMinus2?: Maybe<ReportDataItem>;
  temperatureMinHistory?: Maybe<ReportDataItem>;
  temperatureMinVolume?: Maybe<ReportDataItem>;
  temperatureMinYtd?: Maybe<ReportDataItem>;
  temperatureMinYtdMinus1?: Maybe<ReportDataItem>;
  temperatureMinYtdMinus2?: Maybe<ReportDataItem>;
  worldCoverClasses?: Maybe<ReportDataItem>;
  worldCoverClassesImages?: Maybe<ReportDataItem>;
  yieldModelDistribution?: Maybe<ReportDataItem>;
  yieldModelYearly?: Maybe<ReportDataItem>;
};

export type ReportDataItem = {
  __typename: 'ReportDataItem';
  definitionName: Scalars['String']['output'];
  funcName: Scalars['String']['output'];
  result?: Maybe<ReportDataItemResult>;
  type: Scalars['String']['output'];
};

export type ReportDataItemResult = {
  __typename: 'ReportDataItemResult';
  files?: Maybe<Array<Maybe<ReportDataItemResultFile>>>;
  id: Scalars['ID']['output'];
  product?: Maybe<Scalars['String']['output']>;
  reportId: Scalars['ID']['output'];
  subtype?: Maybe<Scalars['String']['output']>;
  values?: Maybe<Scalars['JSON']['output']>;
};

export type ReportDataItemResultFile = {
  __typename: 'ReportDataItemResultFile';
  location: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum ReportProduct {
  Buildings = 'BUILDINGS',
  BurnedArea = 'BURNED_AREA',
  ClimateRisk = 'CLIMATE_RISK',
  ClimateSsp126Rainfall = 'CLIMATE_SSP126_RAINFALL',
  ClimateSsp126Temperaturemax = 'CLIMATE_SSP126_TEMPERATUREMAX',
  ClimateSsp126Temperaturemin = 'CLIMATE_SSP126_TEMPERATUREMIN',
  Clum = 'CLUM',
  Deforestation = 'DEFORESTATION',
  Elevation = 'ELEVATION',
  EviArea = 'EVI_AREA',
  Forests = 'FORESTS',
  GrasslandFire = 'GRASSLAND_FIRE',
  Landmarks = 'LANDMARKS',
  LandCover = 'LAND_COVER',
  LandUse = 'LAND_USE',
  QldPmav = 'QLD_PMAV',
  Rainfall = 'RAINFALL',
  Sales = 'SALES',
  Satellite = 'SATELLITE',
  SoilAus = 'SOIL_AUS',
  SoilAwc = 'SOIL_AWC',
  SoilClay = 'SOIL_CLAY',
  SoilOc = 'SOIL_OC',
  SoilPh = 'SOIL_PH',
  SoilSand = 'SOIL_SAND',
  SoilSilt = 'SOIL_SILT',
  SoilTexture = 'SOIL_TEXTURE',
  TemperatureFrostCount = 'TEMPERATURE_FROST_COUNT',
  TemperatureFrostMaxrun = 'TEMPERATURE_FROST_MAXRUN',
  TemperatureHeatCount = 'TEMPERATURE_HEAT_COUNT',
  TemperatureHeatMaxrun = 'TEMPERATURE_HEAT_MAXRUN',
  TemperatureMean = 'TEMPERATURE_MEAN',
  WorldCover = 'WORLD_COVER',
  YieldModel = 'YIELD_MODEL',
}

export enum ReportSection {
  /** Climate Change */
  ClimateChange = 'CLIMATE_CHANGE',
  /** Climate Risks Summary */
  ClimateRisksSummary = 'CLIMATE_RISKS_SUMMARY',
  /** Deforestation Over Time */
  DeforestationOverTime = 'DEFORESTATION_OVER_TIME',
  /** Forests & Fires */
  ForestsFires = 'FORESTS_FIRES',
  /** Land Cover */
  LandCover = 'LAND_COVER',
  /** Land & Nature */
  LandNature = 'LAND_NATURE',
  /** Land Use */
  LandUse = 'LAND_USE',
  /** Overview */
  Overview = 'OVERVIEW',
  /** Productivity */
  Productivity = 'PRODUCTIVITY',
  /** Property & Climate Summary */
  PropertyClimateSummary = 'PROPERTY_CLIMATE_SUMMARY',
  /** Rainfall */
  Rainfall = 'RAINFALL',
  /** Sales */
  Sales = 'SALES',
  /** Soil & Terrain */
  SoilTerrain = 'SOIL_TERRAIN',
  /** Temperature Events */
  TemperatureEvents = 'TEMPERATURE_EVENTS',
  /** Titles & Buildings */
  TitlesBuildings = 'TITLES_BUILDINGS',
}

export enum ReportStatus {
  /** Report is complete */
  Complete = 'COMPLETE',
  /** Report has failed */
  Failed = 'FAILED',
  /** Report is in progress */
  InProgress = 'IN_PROGRESS',
  /** Report record has been created but has not begun processing */
  NotStarted = 'NOT_STARTED',
}

export enum ReportType {
  /** Comparative market analysis report */
  Cma = 'CMA',
  /** Deforestation report */
  Deforestation = 'DEFORESTATION',
  /** Know your environment report (AKA property report) */
  Kye = 'KYE',
}

export type Route = {
  __typename: 'Route';
  distanceStartToEndKm?: Maybe<Scalars['Decimal']['output']>;
  endPoint: Scalars['String']['output'];
  fuelType?: Maybe<RouteFuelType>;
  id: Scalars['ID']['output'];
  numberOfTrips?: Maybe<Scalars['Int']['output']>;
  startPoint: Scalars['String']['output'];
  totalDistanceTravelledKm: Scalars['Decimal']['output'];
  vehicleType: RouteVehicleType;
};

export enum RouteFuelType {
  Biodiesel = 'BIODIESEL',
  DieselOil = 'DIESEL_OIL',
  Ethanol = 'ETHANOL',
  FuelOil = 'FUEL_OIL',
  Gasoline = 'GASOLINE',
  LiquefiedPetroleumGas = 'LIQUEFIED_PETROLEUM_GAS',
  LiquifiedNaturalGas = 'LIQUIFIED_NATURAL_GAS',
  OtherBiofuels = 'OTHER_BIOFUELS',
  RenewableDiesel = 'RENEWABLE_DIESEL',
}

export enum RouteVehicleType {
  CommercialAirline = 'COMMERCIAL_AIRLINE',
  CourierVanUtility = 'COURIER_VAN_UTILITY',
  HeavyBus = 'HEAVY_BUS',
  HeavyRigid = 'HEAVY_RIGID',
  LargeCar = 'LARGE_CAR',
  LightRigid = 'LIGHT_RIGID',
  MediumCar = 'MEDIUM_CAR',
  MediumRigid = 'MEDIUM_RIGID',
  MidSize_4Wd = 'MID_SIZE_4WD',
  SmallCar = 'SMALL_CAR',
}

export type Sale = {
  __typename: 'Sale';
  address?: Maybe<Scalars['String']['output']>;
  area?: Maybe<Scalars['Decimal']['output']>;
  bbox?: Maybe<Scalars['BBox2D']['output']>;
  centroid?: Maybe<Scalars['GeoJSONPoint']['output']>;
  /** Sale contract date */
  contractDate?: Maybe<Scalars['Date']['output']>;
  externalId?: Maybe<Scalars['ID']['output']>;
  geog?: Maybe<Scalars['GeoJSONMultiPolygon']['output']>;
  /** Id of the sale */
  id: Scalars['ID']['output'];
  landUses: Array<SaleLandUse>;
  notes?: Maybe<Scalars['String']['output']>;
  /** Sale price in cents */
  price?: Maybe<Scalars['Int']['output']>;
  /** The properties sold */
  propertiesInSale: Array<Maybe<PropertyInSale>>;
};

export type SaleLandUse = {
  __typename: 'SaleLandUse';
  /** The area of the land use */
  area: Scalars['Decimal']['output'];
  id: Scalars['ID']['output'];
  /** The land use */
  landUse: ClumLandUse;
  pricePerHectare: Scalars['Decimal']['output'];
};

export type SaleLandUseInput = {
  /** The area of the land use */
  area: Scalars['Decimal']['input'];
  id: Scalars['ID']['input'];
  /** The land use */
  landUse: ClumLandUse;
  pricePerHectare: Scalars['Decimal']['input'];
};

export type SalesDataItem = {
  __typename: 'SalesDataItem';
  definitionName: Scalars['String']['output'];
  funcName: Scalars['String']['output'];
  result?: Maybe<Scalars['JSON']['output']>;
  type: Scalars['String']['output'];
};

export enum SalesDataSource {
  /** Data from NPG */
  NationalPropertyGroup = 'NATIONAL_PROPERTY_GROUP',
  PropTrack = 'PROP_TRACK',
  /** Data entered by the user */
  UserEntered = 'USER_ENTERED',
}

/** Input to save a comparable sale */
export type SaveComparableSaleInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  /** The amount the property sold for in cents */
  amount?: InputMaybe<Scalars['Int']['input']>;
  area?: InputMaybe<Scalars['Float']['input']>;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  geog?: InputMaybe<Scalars['GeoJSONMultiPolygon']['input']>;
  latitude?: InputMaybe<Scalars['Decimal']['input']>;
  longitude?: InputMaybe<Scalars['Decimal']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** The date of the sale */
  saleDate?: InputMaybe<Scalars['Date']['input']>;
  similarity: Scalars['Percent']['input'];
  /** The source of the data (ie NPG, user-entered) */
  source: SalesDataSource;
};

/** Seasons of the year */
export enum Season {
  /** Autumn */
  Autumn = 'AUTUMN',
  /** Spring */
  Spring = 'SPRING',
  /** Summer */
  Summer = 'SUMMER',
  /** The season is unknown */
  Unknown = 'UNKNOWN',
  /** Winter */
  Winter = 'WINTER',
}

export type SheepBeefEmissionsCalculation = {
  __typename: 'SheepBeefEmissionsCalculation';
  inputs: EmissionsCalculationInputs;
  result: SheepBeefEmissionsCalculationResult;
};

export type SheepBeefEmissionsCalculationResult = {
  __typename: 'SheepBeefEmissionsCalculationResult';
  carbonSequestration?: Maybe<SheepBeefEmissionsCarbonSequestration>;
  intensities?: Maybe<SheepBeefEmissionsIntensities>;
  intermediate?: Maybe<SheepBeefEmissionsIntermediate>;
  net?: Maybe<SheepBeefEmissionsNet>;
  scope1?: Maybe<SheepBeefEmissionsScope1>;
  scope2?: Maybe<SheepBeefEmissionsScope2>;
  scope3?: Maybe<SheepBeefEmissionsScope3>;
};

export type SheepBeefEmissionsCarbonSequestration = {
  __typename: 'SheepBeefEmissionsCarbonSequestration';
  intermediate?: Maybe<SheepBeefEmissionsTotalAverage>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type SheepBeefEmissionsIntensities = {
  __typename: 'SheepBeefEmissionsIntensities';
  beefExcludingSequestration?: Maybe<Scalars['Float']['output']>;
  beefIncludingSequestration?: Maybe<Scalars['Float']['output']>;
  sheepMeatBreedingExcludingSequestration?: Maybe<Scalars['Float']['output']>;
  sheepMeatBreedingIncludingSequestration?: Maybe<Scalars['Float']['output']>;
  woolExcludingSequestration?: Maybe<Scalars['Float']['output']>;
  woolIncludingSequestration?: Maybe<Scalars['Float']['output']>;
};

export type SheepBeefEmissionsIntermediate = {
  __typename: 'SheepBeefEmissionsIntermediate';
  beef?: Maybe<SheepBeefEmissionsIntermediateScopes>;
  sheep?: Maybe<SheepBeefEmissionsIntermediateScopes>;
};

export type SheepBeefEmissionsIntermediateScopes = {
  __typename: 'SheepBeefEmissionsIntermediateScopes';
  carbonSequestration?: Maybe<Scalars['Float']['output']>;
  scope1?: Maybe<SheepBeefEmissionsScope1>;
  scope2?: Maybe<SheepBeefEmissionsScope2>;
  scope3?: Maybe<SheepBeefEmissionsScope3>;
};

export type SheepBeefEmissionsNet = {
  __typename: 'SheepBeefEmissionsNet';
  beef?: Maybe<Scalars['Float']['output']>;
  sheep?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type SheepBeefEmissionsScope1 = {
  __typename: 'SheepBeefEmissionsScope1';
  atmosphericDepositionN2O?: Maybe<Scalars['Float']['output']>;
  entericCH4?: Maybe<Scalars['Float']['output']>;
  fertiliserN2O?: Maybe<Scalars['Float']['output']>;
  fuelCH4?: Maybe<Scalars['Float']['output']>;
  fuelCO2?: Maybe<Scalars['Float']['output']>;
  fuelN2O?: Maybe<Scalars['Float']['output']>;
  leachingAndRunoffN2O?: Maybe<Scalars['Float']['output']>;
  limeCO2?: Maybe<Scalars['Float']['output']>;
  manureManagementCH4?: Maybe<Scalars['Float']['output']>;
  savannahBurningCH4?: Maybe<Scalars['Float']['output']>;
  savannahBurningN2O?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  totalCH4?: Maybe<Scalars['Float']['output']>;
  totalCO2?: Maybe<Scalars['Float']['output']>;
  totalN2O?: Maybe<Scalars['Float']['output']>;
  ureaCO2?: Maybe<Scalars['Float']['output']>;
  urineAndDungN2O?: Maybe<Scalars['Float']['output']>;
};

export type SheepBeefEmissionsScope2 = {
  __typename: 'SheepBeefEmissionsScope2';
  electricity?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type SheepBeefEmissionsScope3 = {
  __typename: 'SheepBeefEmissionsScope3';
  electricity?: Maybe<Scalars['Float']['output']>;
  fertiliser?: Maybe<Scalars['Float']['output']>;
  fuel?: Maybe<Scalars['Float']['output']>;
  herbicide?: Maybe<Scalars['Float']['output']>;
  lime?: Maybe<Scalars['Float']['output']>;
  purchasedFeed?: Maybe<Scalars['Float']['output']>;
  purchasedLivestock?: Maybe<Scalars['Float']['output']>;
  purchasedMineralSupplementation?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type SheepBeefEmissionsTotalAverage = {
  __typename: 'SheepBeefEmissionsTotalAverage';
  average?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export enum SheepClass {
  EwesGt_2 = 'EWES_GT_2',
  EweLambsLt_1 = 'EWE_LAMBS_LT_1',
  MaidenBreedingEwes_1To_2 = 'MAIDEN_BREEDING_EWES_1_TO_2',
  OtherEwesGt_2 = 'OTHER_EWES_GT_2',
  Rams = 'RAMS',
  WethersGt_1 = 'WETHERS_GT_1',
  WetherLambsLt_1 = 'WETHER_LAMBS_LT_1',
}

export type SheepLivestockActivity = Activity &
  LivestockActivity & {
    __typename: 'SheepLivestockActivity';
    businessActivity: BusinessActivity;
    businessActivityId: Scalars['ID']['output'];
    end: Scalars['Date']['output'];
    eweLambsLt1Breeding: Scalars['Boolean']['output'];
    eweLambsLt1Trading: Scalars['Boolean']['output'];
    ewesGt2Breeding: Scalars['Boolean']['output'];
    ewesGt2Trading: Scalars['Boolean']['output'];
    feed?: Maybe<Feed>;
    feedId?: Maybe<Scalars['ID']['output']>;
    id: Scalars['ID']['output'];
    locations?: Maybe<Array<Maybe<ActivityLocation>>>;
    maidenBreedingEwes1To2Breeding: Scalars['Boolean']['output'];
    maidenBreedingEwes1To2Trading: Scalars['Boolean']['output'];
    name: Scalars['String']['output'];
    otherEwesGt2Breeding: Scalars['Boolean']['output'];
    otherEwesGt2Trading: Scalars['Boolean']['output'];
    ramsBreeding: Scalars['Boolean']['output'];
    ramsTrading: Scalars['Boolean']['output'];
    shearingEvents?: Maybe<Array<Maybe<SheepShearingEvent>>>;
    start: Scalars['Date']['output'];
    targetAllocations?: Maybe<Array<Maybe<ActivityAllocation>>>;
    wetherLambsLt1Breeding: Scalars['Boolean']['output'];
    wetherLambsLt1Trading: Scalars['Boolean']['output'];
    wethersGt1Breeding: Scalars['Boolean']['output'];
    wethersGt1Trading: Scalars['Boolean']['output'];
  };

export type SheepMonthLivestockActivity = {
  __typename: 'SheepMonthLivestockActivity';
  averageLiveweightKg: Scalars['Int']['output'];
  crudeProteinPercentage: Scalars['Percent']['output'];
  dryMatterDigestibilityPercentage: Scalars['Percent']['output'];
  feedAvailabilityTonnesPerHectare: Scalars['Decimal']['output'];
  headAtStart: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  keyEvents?: Maybe<Scalars['String']['output']>;
  livestockCategory: LivestockCategory;
  livestockTransactionEvents: Array<Maybe<LivestockTransactionEvent>>;
  month: Month;
  nonSalePopulationChange: Scalars['Int']['output'];
  percentageEwesLambing: Scalars['Percent']['output'];
  previousMonthLiveweightKg?: Maybe<Scalars['Int']['output']>;
  seasonalLambingRate: Scalars['Percent']['output'];
  sheepClass: SheepClass;
  sheepLivestockActivityId: Scalars['ID']['output'];
  year: Scalars['Int']['output'];
};

export type SheepShearingEvent = {
  __typename: 'SheepShearingEvent';
  cleanWoolYieldKg: Scalars['Decimal']['output'];
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  livestockCategory: LivestockCategory;
  /** @deprecated Use 'date' instead */
  month: Month;
  numberSheepShorn: Scalars['Int']['output'];
  sheepClass?: Maybe<SheepClass>;
  sheepLivestockActivityId?: Maybe<Scalars['ID']['output']>;
  woolPerHeadKg: Scalars['Decimal']['output'];
  /** @deprecated Use 'date' instead */
  year: Scalars['Int']['output'];
};

export enum SoilType {
  Calcarosols = 'CALCAROSOLS',
  Clay = 'CLAY',
  Clays = 'CLAYS',
  ClayAndRedLoam = 'CLAY_AND_RED_LOAM',
  ClayBrigaloAndBelah = 'CLAY_BRIGALO_AND_BELAH',
  ClayGidgee = 'CLAY_GIDGEE',
  ColouredSands = 'COLOURED_SANDS',
  CrackingClays = 'CRACKING_CLAYS',
  Duplex = 'DUPLEX',
  DuplexSoils = 'DUPLEX_SOILS',
  DuplexWoodland = 'DUPLEX_WOODLAND',
  Earths = 'EARTHS',
  GradationalSoils = 'GRADATIONAL_SOILS',
  GreyCrackingClays = 'GREY_CRACKING_CLAYS',
  Kandosols = 'KANDOSOLS',
  Loam = 'LOAM',
  LoamsAndClays = 'LOAMS_AND_CLAYS',
  LoamySoils = 'LOAMY_SOILS',
  NonCrackingClays = 'NON_CRACKING_CLAYS',
  NoSoilTreeDataAvailable = 'NO_SOIL_TREE_DATA_AVAILABLE',
  OpenDowns = 'OPEN_DOWNS',
  OtherSoils = 'OTHER_SOILS',
  RedDuplex = 'RED_DUPLEX',
  RedEarths = 'RED_EARTHS',
  SandyDuplexes = 'SANDY_DUPLEXES',
  SandySoils = 'SANDY_SOILS',
  StructuredEarths = 'STRUCTURED_EARTHS',
  Tenosols = 'TENOSOLS',
  YellowDuplex = 'YELLOW_DUPLEX',
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export type SpeciesInstance = {
  __typename: 'SpeciesInstance';
  age: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  species?: Maybe<VegetationSpecies>;
  treesPerHectare?: Maybe<Scalars['Int']['output']>;
};

/** States of Australia */
export enum State {
  /** Australian Capital Territory */
  Act = 'ACT',
  /** New South Wales */
  Nsw = 'NSW',
  /** Northern Territory */
  Nt = 'NT',
  /** Queensland */
  Qld = 'QLD',
  /** South Australia */
  Sa = 'SA',
  /** Tasmania */
  Tas = 'TAS',
  /** Victoria */
  Vic = 'VIC',
  /** Western Australia */
  Wa = 'WA',
}

export enum StateRegion {
  Act = 'ACT',
  Nsw = 'NSW',
  Nt = 'NT',
  Qld = 'QLD',
  Sa = 'SA',
  Tas = 'TAS',
  Vic = 'VIC',
  WaNw = 'WA_NW',
  WaSw = 'WA_SW',
}

export type SugarEmissionsCalculation = {
  __typename: 'SugarEmissionsCalculation';
  inputs: EmissionsCalculationInputs;
  result: SugarEmissionsCalculationResult;
};

export type SugarEmissionsCalculationResult = {
  __typename: 'SugarEmissionsCalculationResult';
  carbonSequestration?: Maybe<SugarEmissionsCarbonSequestration>;
  intensities?: Maybe<Array<Maybe<SugarEmissionsIntensities>>>;
  intermediate?: Maybe<Array<Maybe<SugarEmissionsIntermediateScopes>>>;
  net?: Maybe<SugarEmissionsNet>;
  scope1?: Maybe<SugarEmissionsScope1>;
  scope2?: Maybe<SugarEmissionsScope2>;
  scope3?: Maybe<SugarEmissionsScope3>;
};

export type SugarEmissionsCarbonSequestration = {
  __typename: 'SugarEmissionsCarbonSequestration';
  intermediate?: Maybe<Array<Maybe<SugarEmissionsTotalAverage>>>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type SugarEmissionsIntensities = {
  __typename: 'SugarEmissionsIntensities';
  sugarExcludingSequestration?: Maybe<Scalars['Float']['output']>;
  sugarIncludingSequestration?: Maybe<Scalars['Float']['output']>;
};

export type SugarEmissionsIntermediateScopes = {
  __typename: 'SugarEmissionsIntermediateScopes';
  scope1?: Maybe<SugarEmissionsScope1>;
  scope2?: Maybe<SugarEmissionsScope2>;
  scope3?: Maybe<SugarEmissionsScope3>;
};

export type SugarEmissionsNet = {
  __typename: 'SugarEmissionsNet';
  crops?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type SugarEmissionsScope1 = {
  __typename: 'SugarEmissionsScope1';
  atmosphericDepositionN2O?: Maybe<Scalars['Float']['output']>;
  cropResidueN2O?: Maybe<Scalars['Float']['output']>;
  fertiliserN2O?: Maybe<Scalars['Float']['output']>;
  fieldBurningCH4?: Maybe<Scalars['Float']['output']>;
  fieldBurningN2O?: Maybe<Scalars['Float']['output']>;
  fuelCH4?: Maybe<Scalars['Float']['output']>;
  fuelCO2?: Maybe<Scalars['Float']['output']>;
  fuelN2O?: Maybe<Scalars['Float']['output']>;
  leachingAndRunoffN2O?: Maybe<Scalars['Float']['output']>;
  limeCO2?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  totalCH4?: Maybe<Scalars['Float']['output']>;
  totalCO2?: Maybe<Scalars['Float']['output']>;
  totalN2O?: Maybe<Scalars['Float']['output']>;
  ureaCO2?: Maybe<Scalars['Float']['output']>;
};

export type SugarEmissionsScope2 = {
  __typename: 'SugarEmissionsScope2';
  electricity?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type SugarEmissionsScope3 = {
  __typename: 'SugarEmissionsScope3';
  electricity?: Maybe<Scalars['Float']['output']>;
  fertiliser?: Maybe<Scalars['Float']['output']>;
  fuel?: Maybe<Scalars['Float']['output']>;
  herbicide?: Maybe<Scalars['Float']['output']>;
  lime?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type SugarEmissionsTotalAverage = {
  __typename: 'SugarEmissionsTotalAverage';
  average?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type Team = {
  __typename: 'Team';
  /** The team's unique ID. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organisation: Organisation;
  properties: Array<Property>;
};

export enum TransactionRegion {
  Dairy = 'DAIRY',
  Nt = 'NT',
  Pastoral = 'PASTORAL',
  Qld = 'QLD',
  SthNsw = 'STH_NSW',
  SwWa = 'SW_WA',
  Tas = 'TAS',
  WaPastoral = 'WA_PASTORAL',
}

export enum TransactionType {
  Purchase = 'PURCHASE',
  Sale = 'SALE',
}

export enum TruckType {
  BDouble = 'B_DOUBLE',
  FourDeckTrailer = 'FOUR_DECK_TRAILER',
  SixDeckTrailer = 'SIX_DECK_TRAILER',
}

export type UpdateActivityAllocationsInput = {
  targetAllocations: Array<ActivityAllocationInput>;
};

export type UpdateActivityInput = {
  end?: InputMaybe<Scalars['Date']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['Date']['input']>;
};

export type UpdateBeefLivestockActivityInput = {
  bullsGt1Breeding?: InputMaybe<Scalars['Boolean']['input']>;
  bullsGt1Trading?: InputMaybe<Scalars['Boolean']['input']>;
  cowsGt2Breeding?: InputMaybe<Scalars['Boolean']['input']>;
  cowsGt2Trading?: InputMaybe<Scalars['Boolean']['input']>;
  heifers1to2Breeding?: InputMaybe<Scalars['Boolean']['input']>;
  heifers1to2Trading?: InputMaybe<Scalars['Boolean']['input']>;
  heifersGt2Breeding?: InputMaybe<Scalars['Boolean']['input']>;
  heifersGt2Trading?: InputMaybe<Scalars['Boolean']['input']>;
  heifersLt1Breeding?: InputMaybe<Scalars['Boolean']['input']>;
  heifersLt1Trading?: InputMaybe<Scalars['Boolean']['input']>;
  steers1to2Breeding?: InputMaybe<Scalars['Boolean']['input']>;
  steers1to2Trading?: InputMaybe<Scalars['Boolean']['input']>;
  steersGt2Breeding?: InputMaybe<Scalars['Boolean']['input']>;
  steersGt2Trading?: InputMaybe<Scalars['Boolean']['input']>;
  steersLt1Breeding?: InputMaybe<Scalars['Boolean']['input']>;
  steersLt1Trading?: InputMaybe<Scalars['Boolean']['input']>;
  updateActivityInput?: InputMaybe<UpdateActivityInput>;
};

export type UpdateBeefMonthLivestockActivityInput = {
  averageLiveweightKg?: InputMaybe<Scalars['Int']['input']>;
  crudeProteinPercentage?: InputMaybe<Scalars['Percent']['input']>;
  dryMatterDigestibilityPercentage?: InputMaybe<Scalars['Percent']['input']>;
  headAtStart?: InputMaybe<Scalars['Int']['input']>;
  keyEvents?: InputMaybe<Scalars['String']['input']>;
  nonSalePopulationChange?: InputMaybe<Scalars['Int']['input']>;
  percentageCowsCalving?: InputMaybe<Scalars['Percent']['input']>;
  previousMonthLiveweightKg?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateBuffaloLivestockActivityInput = {
  bullsBreeding?: InputMaybe<Scalars['Boolean']['input']>;
  bullsTrading?: InputMaybe<Scalars['Boolean']['input']>;
  calfsBreeding?: InputMaybe<Scalars['Boolean']['input']>;
  calfsTrading?: InputMaybe<Scalars['Boolean']['input']>;
  cowsBreeding?: InputMaybe<Scalars['Boolean']['input']>;
  cowsTrading?: InputMaybe<Scalars['Boolean']['input']>;
  steersBreeding?: InputMaybe<Scalars['Boolean']['input']>;
  steersTrading?: InputMaybe<Scalars['Boolean']['input']>;
  updateActivityInput?: InputMaybe<UpdateActivityInput>;
};

export type UpdateBuffaloMonthLivestockActivityInput = {
  headAtStart?: InputMaybe<Scalars['Int']['input']>;
  keyEvents?: InputMaybe<Scalars['String']['input']>;
  nonSalePopulationChange?: InputMaybe<Scalars['Int']['input']>;
  percentageCowsCalving?: InputMaybe<Scalars['Percent']['input']>;
  seasonalCalvingRate?: InputMaybe<Scalars['Percent']['input']>;
};

export type UpdateBurningActivityInput = {
  fireScarAreaSquareMetres?: InputMaybe<Scalars['Int']['input']>;
  fuelClass?: InputMaybe<BurningActivityFuelClass>;
  patchiness?: InputMaybe<BurningActivityPatchiness>;
  rainfallZone?: InputMaybe<BurningActivityRainfallZone>;
  season?: InputMaybe<BurningActivitySeason>;
  vegetationClass?: InputMaybe<BurningActivityVegetationClass>;
  yearsSinceLastFire?: InputMaybe<Scalars['Decimal']['input']>;
};

export type UpdateBusinessActivityInput = {
  createdByUserId?: InputMaybe<Scalars['ID']['input']>;
  enabledActivityTypes?: InputMaybe<Array<EnabledActivityTypes>>;
  id: Scalars['ID']['input'];
  ownedByTeamId?: InputMaybe<Scalars['ID']['input']>;
  propertyNorthOfTropicOfCapricorn?: InputMaybe<Scalars['Boolean']['input']>;
  rainfallAbove600?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<StateRegion>;
};

export type UpdateCarbamateInput = {
  activeIngredientPercentage?: InputMaybe<Scalars['Percent']['input']>;
  amountPerHectareGrams: Scalars['Int']['input'];
  applicationAreaGeometry?: InputMaybe<Scalars['GeoJSONMultiPolygon']['input']>;
  applicationAreaSquareMetres?: InputMaybe<Scalars['Int']['input']>;
  applicationDate?: InputMaybe<Scalars['Date']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  product: CarbamateProduct;
  type: CarbamateType;
};

/** Input to update a comparable sale */
export type UpdateComparableSaleInput = {
  landUses?: InputMaybe<Array<SaleLandUseInput>>;
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateConsumptionInput = {
  /** electricity = Wh, feed = kg, fuel = ml, refrigerant = kg */
  amount?: InputMaybe<Scalars['Int']['input']>;
  consumableSubType?: InputMaybe<ConsumableSubType>;
  consumableType?: InputMaybe<ConsumableType>;
  consumptionAllocations?: InputMaybe<Array<ConsumptionAllocationInput>>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type UpdateCottonActivityInput = {
  averageCottonYieldKgPerHa?: InputMaybe<Scalars['Int']['input']>;
  averageWeightPerBaleKg?: InputMaybe<Scalars['Decimal']['input']>;
  balesPerHa?: InputMaybe<Scalars['Decimal']['input']>;
  cottonLintPerBaleKg?: InputMaybe<Scalars['Decimal']['input']>;
  cottonSeedPerBaleKg?: InputMaybe<Scalars['Decimal']['input']>;
  percentageBurned?: InputMaybe<Scalars['Int']['input']>;
  residueBurned?: InputMaybe<Scalars['Boolean']['input']>;
  updateActivityInput?: InputMaybe<UpdateActivityInput>;
  wastePerBaleKg?: InputMaybe<Scalars['Decimal']['input']>;
};

export type UpdateCropVarietyInput = {
  species: CropSpecies;
  yieldInKg: Scalars['Int']['input'];
};

export type UpdateCroppingActivityInput = {
  irrigated?: InputMaybe<Scalars['Boolean']['input']>;
  nitrificationInhibitorUsed?: InputMaybe<Scalars['Boolean']['input']>;
  percentageBurned?: InputMaybe<Scalars['Int']['input']>;
  plantingAreaGeometry?: InputMaybe<Scalars['GeoJSONMultiPolygon']['input']>;
  plantingAreaSquareMetres?: InputMaybe<Scalars['Int']['input']>;
  residueBurned?: InputMaybe<Scalars['Boolean']['input']>;
  ureaseInhibitorUsed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateDairyLivestockActivityInput = {
  dairyBullsGt1?: InputMaybe<Scalars['Boolean']['input']>;
  dairyBullsLt1?: InputMaybe<Scalars['Boolean']['input']>;
  distanceTravelledBetweenFarmsKm?: InputMaybe<Scalars['Decimal']['input']>;
  emissionsRedMeatPercentage?: InputMaybe<Scalars['Percent']['input']>;
  heifersGt1?: InputMaybe<Scalars['Boolean']['input']>;
  heifersLt1?: InputMaybe<Scalars['Boolean']['input']>;
  manureMilkingLagoonPercentage?: InputMaybe<Scalars['Percent']['input']>;
  manureMilkingPaddocksPercentage?: InputMaybe<Scalars['Percent']['input']>;
  manureMilkingPasturePercentage?: InputMaybe<Scalars['Percent']['input']>;
  manureMilkingSolidStoragePercentage?: InputMaybe<Scalars['Percent']['input']>;
  manureMilkingSumpDispersalPercentage?: InputMaybe<
    Scalars['Percent']['input']
  >;
  manureOtherLagoonPercentage?: InputMaybe<Scalars['Percent']['input']>;
  manureOtherPaddocksPercentage?: InputMaybe<Scalars['Percent']['input']>;
  manureOtherPasturePercentage?: InputMaybe<Scalars['Percent']['input']>;
  manureOtherSolidStoragePercentage?: InputMaybe<Scalars['Percent']['input']>;
  manureOtherSumpDispersalPercentage?: InputMaybe<Scalars['Percent']['input']>;
  milkingCows?: InputMaybe<Scalars['Boolean']['input']>;
  productionSystem?: InputMaybe<DairyProductionSystem>;
  truckType?: InputMaybe<TruckType>;
  updateActivityInput?: InputMaybe<UpdateActivityInput>;
};

export type UpdateDairyMonthLivestockActivityInput = {
  averageLiveweightKg?: InputMaybe<Scalars['Int']['input']>;
  crudeProteinPercentage?: InputMaybe<Scalars['Percent']['input']>;
  dryMatterDigestibilityPercentage?: InputMaybe<Scalars['Percent']['input']>;
  headAtStart?: InputMaybe<Scalars['Int']['input']>;
  keyEvents?: InputMaybe<Scalars['String']['input']>;
  litresPerHeadPerDay?: InputMaybe<Scalars['Decimal']['input']>;
  nonSalePopulationChange?: InputMaybe<Scalars['Int']['input']>;
  previousMonthLiveweightKg?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateDeerLivestockActivityInput = {
  breedingDoesBreeding?: InputMaybe<Scalars['Boolean']['input']>;
  breedingDoesTrading?: InputMaybe<Scalars['Boolean']['input']>;
  bucksBreeding?: InputMaybe<Scalars['Boolean']['input']>;
  bucksTrading?: InputMaybe<Scalars['Boolean']['input']>;
  fawnBreeding?: InputMaybe<Scalars['Boolean']['input']>;
  fawnTrading?: InputMaybe<Scalars['Boolean']['input']>;
  otherDoesBreeding?: InputMaybe<Scalars['Boolean']['input']>;
  otherDoesTrading?: InputMaybe<Scalars['Boolean']['input']>;
  updateActivityInput?: InputMaybe<UpdateActivityInput>;
};

export type UpdateDeerMonthLivestockActivityInput = {
  headAtStart?: InputMaybe<Scalars['Int']['input']>;
  keyEvents?: InputMaybe<Scalars['String']['input']>;
  nonSalePopulationChange?: InputMaybe<Scalars['Int']['input']>;
  percentageDoesFawning?: InputMaybe<Scalars['Percent']['input']>;
  seasonalFawningRate?: InputMaybe<Scalars['Percent']['input']>;
};

export type UpdateFeedInput = {
  drySeasonMix?: InputMaybe<Scalars['Boolean']['input']>;
  drySeasonMixKg?: InputMaybe<Scalars['Int']['input']>;
  drySeasonMixUreaPercentage?: InputMaybe<Scalars['Percent']['input']>;
  grazingCrops?: InputMaybe<Scalars['Boolean']['input']>;
  mineralBlock?: InputMaybe<Scalars['Boolean']['input']>;
  mineralBlockKg?: InputMaybe<Scalars['Int']['input']>;
  mineralBlockUreaPercentage?: InputMaybe<Scalars['Percent']['input']>;
  pasture?: InputMaybe<Scalars['Boolean']['input']>;
  weanerBlock?: InputMaybe<Scalars['Boolean']['input']>;
  weanerBlockKg?: InputMaybe<Scalars['Int']['input']>;
  weanerBlockUreaPercentage?: InputMaybe<Scalars['Percent']['input']>;
};

export type UpdateFeedlotActivityInput = {
  grazingCrops?: InputMaybe<Scalars['Boolean']['input']>;
  pasture?: InputMaybe<Scalars['Boolean']['input']>;
  system?: InputMaybe<FeedlotSystem>;
  totalDistanceTravelledKm?: InputMaybe<Scalars['Int']['input']>;
  truckType?: InputMaybe<TruckType>;
};

export type UpdateFeedlotFeedBreakdownInput = {
  crudeProtein?: InputMaybe<Scalars['Percent']['input']>;
  dryMatterDigestibility?: InputMaybe<Scalars['Percent']['input']>;
  netEnergyConcentrationMjPerKg?: InputMaybe<Scalars['Decimal']['input']>;
  nitrogenRetention?: InputMaybe<Scalars['Percent']['input']>;
};

export type UpdateFeedlotFeedCompositionInput = {
  grainsTotalCellulous?: InputMaybe<Scalars['Percent']['input']>;
  grainsTotalHemicellulous?: InputMaybe<Scalars['Percent']['input']>;
  grainsTotalNitrogen?: InputMaybe<Scalars['Percent']['input']>;
  grainsTotalProportion?: InputMaybe<Scalars['Percent']['input']>;
  grainsTotalSolubleResidue?: InputMaybe<Scalars['Percent']['input']>;
  grassesCellulous?: InputMaybe<Scalars['Percent']['input']>;
  grassesHemicellulous?: InputMaybe<Scalars['Percent']['input']>;
  grassesNitrogen?: InputMaybe<Scalars['Percent']['input']>;
  grassesProportion?: InputMaybe<Scalars['Percent']['input']>;
  grassesSolubleResidue?: InputMaybe<Scalars['Percent']['input']>;
  legumesCellulous?: InputMaybe<Scalars['Percent']['input']>;
  legumesHemicellulous?: InputMaybe<Scalars['Percent']['input']>;
  legumesNitrogen?: InputMaybe<Scalars['Percent']['input']>;
  legumesProportion?: InputMaybe<Scalars['Percent']['input']>;
  legumesSolubleResidue?: InputMaybe<Scalars['Percent']['input']>;
  otherConcentratesCellulous?: InputMaybe<Scalars['Percent']['input']>;
  otherConcentratesHemicellulous?: InputMaybe<Scalars['Percent']['input']>;
  otherConcentratesNitrogen?: InputMaybe<Scalars['Percent']['input']>;
  otherConcentratesProportion?: InputMaybe<Scalars['Percent']['input']>;
  otherConcentratesSolubleResidue?: InputMaybe<Scalars['Percent']['input']>;
};

export type UpdateFeedlotGroupInput = {
  averageLiveweightKg?: InputMaybe<Scalars['Int']['input']>;
  lengthOfStayDays?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  numberOfLivestock?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateFeedlotPurchaseInput = {
  beefClass?: InputMaybe<BeefClass>;
  head?: InputMaybe<Scalars['Int']['input']>;
  livestockCategory?: InputMaybe<LivestockCategory>;
  month?: InputMaybe<Month>;
  source?: InputMaybe<FeedlotPurchaseSource>;
  weightPerHeadKg?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateFeedlotSaleInput = {
  beefClass?: InputMaybe<BeefClass>;
  head?: InputMaybe<Scalars['Int']['input']>;
  livestockCategory?: InputMaybe<LivestockCategory>;
  month?: InputMaybe<Month>;
  weightPerHeadKg?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateFertiliserInput = {
  amountPerHectareKg: Scalars['Decimal']['input'];
  applicationAreaGeometry?: InputMaybe<Scalars['GeoJSONMultiPolygon']['input']>;
  applicationAreaSquareMetres?: InputMaybe<Scalars['Int']['input']>;
  applicationDate?: InputMaybe<Scalars['Date']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ratioK?: InputMaybe<Scalars['Percent']['input']>;
  ratioN?: InputMaybe<Scalars['Percent']['input']>;
  ratioP?: InputMaybe<Scalars['Percent']['input']>;
  ratioS?: InputMaybe<Scalars['Percent']['input']>;
  type: FertiliserType;
};

export type UpdateFileInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  uploadStatus?: InputMaybe<FileUploadStatus>;
};

export type UpdateFisheryBaitPurchaseInput = {
  amountPurchasedTonnes?: InputMaybe<Scalars['Decimal']['input']>;
  baitType?: InputMaybe<FisheryBaitType>;
  emissionsIntensityBaitProduct?: InputMaybe<Scalars['Decimal']['input']>;
  percentageAdditionalIngredients?: InputMaybe<Scalars['Percent']['input']>;
};

export type UpdateGoatLivestockActivityInput = {
  breedingDoesBreeding?: InputMaybe<Scalars['Boolean']['input']>;
  breedingDoesTrading?: InputMaybe<Scalars['Boolean']['input']>;
  bucksBreeding?: InputMaybe<Scalars['Boolean']['input']>;
  bucksTrading?: InputMaybe<Scalars['Boolean']['input']>;
  kidsBreeding?: InputMaybe<Scalars['Boolean']['input']>;
  kidsTrading?: InputMaybe<Scalars['Boolean']['input']>;
  maidenBreedingDoesBreeding?: InputMaybe<Scalars['Boolean']['input']>;
  maidenBreedingDoesTrading?: InputMaybe<Scalars['Boolean']['input']>;
  otherDoesBreeding?: InputMaybe<Scalars['Boolean']['input']>;
  otherDoesTrading?: InputMaybe<Scalars['Boolean']['input']>;
  updateActivityInput?: InputMaybe<UpdateActivityInput>;
  wethersBreeding?: InputMaybe<Scalars['Boolean']['input']>;
  wethersTrading?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateGoatMonthLivestockActivityInput = {
  crudeProteinPercentage?: InputMaybe<Scalars['Percent']['input']>;
  dryMatterDigestibilityPercentage?: InputMaybe<Scalars['Percent']['input']>;
  feedAvailabilityTonnesPerHectare?: InputMaybe<Scalars['Decimal']['input']>;
  headAtStart?: InputMaybe<Scalars['Int']['input']>;
  keyEvents?: InputMaybe<Scalars['String']['input']>;
  nonSalePopulationChange?: InputMaybe<Scalars['Int']['input']>;
  percentageDoesKidding?: InputMaybe<Scalars['Percent']['input']>;
  seasonalKiddingRate?: InputMaybe<Scalars['Percent']['input']>;
};

export type UpdateGoatShearingEventInput = {
  cleanWoolYieldKg?: InputMaybe<Scalars['Decimal']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  goatClass?: InputMaybe<GoatClass>;
  livestockCategory?: InputMaybe<LivestockCategory>;
  numberGoatShorn?: InputMaybe<Scalars['Int']['input']>;
  woolPerHeadKg?: InputMaybe<Scalars['Decimal']['input']>;
};

/** Job update input */
export type UpdateJobInput = {
  /** The name of the job. */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLimeInput = {
  amountPerHectareKg?: InputMaybe<Scalars['Int']['input']>;
  applicationAreaGeometry?: InputMaybe<Scalars['GeoJSONMultiPolygon']['input']>;
  applicationAreaSquareMetres?: InputMaybe<Scalars['Int']['input']>;
  applicationDate?: InputMaybe<Scalars['Date']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<LimeType>;
};

export type UpdateLivestockTransactionEventInput = {
  averageWeightKg?: InputMaybe<Scalars['Decimal']['input']>;
  headPurchasedMerino?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  livestockCount?: InputMaybe<Scalars['Int']['input']>;
  totalWeightKg?: InputMaybe<Scalars['Decimal']['input']>;
  transactionRegion?: InputMaybe<TransactionRegion>;
  transactionType?: InputMaybe<TransactionType>;
};

/** Update map shape input */
export type UpdateMapShapeInput = {
  /** The shape geometry as a multi-polygon */
  geometry?: InputMaybe<Scalars['GeoJSONMultiPolygon']['input']>;
  /** The name of the shape */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The type of the shape */
  type?: InputMaybe<MapShapeType>;
};

/** Input for updating a parcel in a property */
export type UpdateParcelInPropertyInput = {
  /** The parcel geometry as a multi-polygon */
  geometry?: InputMaybe<Scalars['GeoJSONMultiPolygon']['input']>;
  /** The name of the parcel within the property */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePorkFeedBlendInput = {
  amountPurchasedTonnes?: InputMaybe<Scalars['Decimal']['input']>;
  barley?: InputMaybe<Scalars['Percent']['input']>;
  beetPulp?: InputMaybe<Scalars['Percent']['input']>;
  blendType?: InputMaybe<PorkFeedBlendType>;
  bloodMeal?: InputMaybe<Scalars['Percent']['input']>;
  canolaMeal?: InputMaybe<Scalars['Percent']['input']>;
  emissionsIntensityFeedProduction?: InputMaybe<Scalars['Decimal']['input']>;
  fishMeal?: InputMaybe<Scalars['Percent']['input']>;
  meatMeal?: InputMaybe<Scalars['Percent']['input']>;
  millMix?: InputMaybe<Scalars['Percent']['input']>;
  percentageAdditionalIngredients?: InputMaybe<Scalars['Percent']['input']>;
  soybeanMeal?: InputMaybe<Scalars['Percent']['input']>;
  tallow?: InputMaybe<Scalars['Percent']['input']>;
  wheat?: InputMaybe<Scalars['Percent']['input']>;
  wheatBran?: InputMaybe<Scalars['Percent']['input']>;
  wheyPowder?: InputMaybe<Scalars['Percent']['input']>;
};

export type UpdatePorkLivestockActivityInput = {
  boars?: InputMaybe<Scalars['Boolean']['input']>;
  gilts?: InputMaybe<Scalars['Boolean']['input']>;
  growers?: InputMaybe<Scalars['Boolean']['input']>;
  slaughterPigs?: InputMaybe<Scalars['Boolean']['input']>;
  sows?: InputMaybe<Scalars['Boolean']['input']>;
  suckers?: InputMaybe<Scalars['Boolean']['input']>;
  updateActivityInput?: InputMaybe<UpdateActivityInput>;
  weaners?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdatePorkMonthLivestockActivityInput = {
  headAtStart?: InputMaybe<Scalars['Int']['input']>;
  keyEvents?: InputMaybe<Scalars['String']['input']>;
  nonSalePopulationChange?: InputMaybe<Scalars['Int']['input']>;
};

/** Update property portfolio input */
export type UpdatePortfolioInput = {
  /** The name of the portfolio */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The ids of the properties that belong to the portfolio */
  propertyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UpdatePoultryBroilerActivityInput = {
  cyclesPerYear?: InputMaybe<Scalars['Int']['input']>;
  percentManureAddedToPasture?: InputMaybe<Scalars['Percent']['input']>;
  percentSolidManure?: InputMaybe<Scalars['Percent']['input']>;
  percentageLitterRecycled?: InputMaybe<Scalars['Percent']['input']>;
  updateActivityInput?: InputMaybe<UpdateActivityInput>;
};

export type UpdatePoultryFeedBlendInput = {
  amountPurchasedTonnes?: InputMaybe<Scalars['Decimal']['input']>;
  barleyWhole?: InputMaybe<Scalars['Percent']['input']>;
  blendType?: InputMaybe<PoultryFeedBlendType>;
  emissionsIntensityFeedProduction?: InputMaybe<Scalars['Decimal']['input']>;
  millRun?: InputMaybe<Scalars['Percent']['input']>;
  percentageAdditionalIngredients?: InputMaybe<Scalars['Percent']['input']>;
  sorghum?: InputMaybe<Scalars['Percent']['input']>;
  soybean?: InputMaybe<Scalars['Percent']['input']>;
  wheat?: InputMaybe<Scalars['Percent']['input']>;
};

export type UpdatePoultryGrowerBatchDetailsInput = {
  averageLengthOfStayDaysL1: Scalars['Int']['input'];
  averageLengthOfStayDaysL2: Scalars['Int']['input'];
  averageLiveweightPerHeadKgW1: Scalars['Decimal']['input'];
  averageLiveweightPerHeadKgW2: Scalars['Decimal']['input'];
  totalNumberOfBirds: Scalars['Int']['input'];
};

export type UpdatePoultryGrowerBatchFeedBreakdownInput = {
  crudeProteinPercent: Scalars['Percent']['input'];
  dryMatterDigestibilityPercent: Scalars['Percent']['input'];
  dryMatterIntakeKgPerHeadPerDay: Scalars['Decimal']['input'];
  manureAshPercent: Scalars['Percent']['input'];
  nitrogenRetentionPercent: Scalars['Percent']['input'];
};

export type UpdatePoultryGrowerBatchInput = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  grazingCrops?: InputMaybe<Scalars['Boolean']['input']>;
  growers?: InputMaybe<Scalars['Boolean']['input']>;
  layers?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  other?: InputMaybe<Scalars['Boolean']['input']>;
  pasture?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type UpdatePoultryLayerActivityInput = {
  averageLayerEggWeightGrams?: InputMaybe<Scalars['Decimal']['input']>;
  averageMeatChickenLayerEggWeightGrams?: InputMaybe<
    Scalars['Decimal']['input']
  >;
  cyclesPerYear?: InputMaybe<Scalars['Int']['input']>;
  grazingCrops?: InputMaybe<Scalars['Boolean']['input']>;
  pasture?: InputMaybe<Scalars['Boolean']['input']>;
  percentManureAddedToPasture?: InputMaybe<Scalars['Percent']['input']>;
  percentSolidManure?: InputMaybe<Scalars['Percent']['input']>;
  percentageLitterRecycled?: InputMaybe<Scalars['Percent']['input']>;
  totalLayerEggsProduced?: InputMaybe<Scalars['Int']['input']>;
  totalMeatChickenLayerEggsProduced?: InputMaybe<Scalars['Int']['input']>;
  updateActivityInput?: InputMaybe<UpdateActivityInput>;
};

export type UpdatePoultryLayerMonthActivityInput = {
  layerCount?: InputMaybe<Scalars['Int']['input']>;
  meatChickenLayerCount?: InputMaybe<Scalars['Int']['input']>;
  month?: InputMaybe<Month>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdatePoultryTransactionEventInput = {
  averageWeightKg?: InputMaybe<Scalars['Decimal']['input']>;
  class?: InputMaybe<PoultryTransactionClass>;
  date?: InputMaybe<Scalars['Date']['input']>;
  livestockCount?: InputMaybe<Scalars['Int']['input']>;
  percentPurchasedFreeRange?: InputMaybe<Scalars['Percent']['input']>;
  totalWeightKg?: InputMaybe<Scalars['Decimal']['input']>;
  transactionRegion?: InputMaybe<TransactionRegion>;
  transactionType?: InputMaybe<TransactionType>;
};

/** Input for updating a property in job. */
export type UpdatePropertyInJobInput = {
  /** The name of the property inside the job. */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Input required to update an existing property */
export type UpdatePropertyInput = {
  /** The address line 1 of the property */
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  /** The address line 2 of the property */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** The city the property is in */
  city?: InputMaybe<Scalars['String']['input']>;
  /** The country the property is in */
  country?: InputMaybe<Scalars['String']['input']>;
  /** The country code of the property */
  countryCode?: InputMaybe<Scalars['String']['input']>;
  /** The name of the property */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The postcode of the property */
  postcode?: InputMaybe<Scalars['String']['input']>;
  /** The ParcelInProperty objects associated with the property */
  propertyParcels?: InputMaybe<Array<InputMaybe<AddParcelToPropertyInput>>>;
  /** The state of Australia the property is in */
  state?: InputMaybe<Scalars['String']['input']>;
};

/** Update property note input */
export type UpdatePropertyNoteInput = {
  /** The text content of the note */
  text?: InputMaybe<Scalars['String']['input']>;
  /** The title of the note */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Input to update a report */
export type UpdateReportInput = {
  /** Report name */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRouteInput = {
  distanceStartToEndKm?: InputMaybe<Scalars['Decimal']['input']>;
  endPoint?: InputMaybe<Scalars['String']['input']>;
  fuelType?: InputMaybe<RouteFuelType>;
  numberOfTrips?: InputMaybe<Scalars['Int']['input']>;
  startPoint?: InputMaybe<Scalars['String']['input']>;
  totalDistanceTravelledKm?: InputMaybe<Scalars['Decimal']['input']>;
  vehicleType?: InputMaybe<RouteVehicleType>;
};

export type UpdateSheepLivestockActivityInput = {
  eweLambsLt1Breeding?: InputMaybe<Scalars['Boolean']['input']>;
  eweLambsLt1Trading?: InputMaybe<Scalars['Boolean']['input']>;
  ewesGt2Breeding?: InputMaybe<Scalars['Boolean']['input']>;
  ewesGt2Trading?: InputMaybe<Scalars['Boolean']['input']>;
  maidenBreedingEwes1To2Breeding?: InputMaybe<Scalars['Boolean']['input']>;
  maidenBreedingEwes1To2Trading?: InputMaybe<Scalars['Boolean']['input']>;
  otherEwesGt2Breeding?: InputMaybe<Scalars['Boolean']['input']>;
  otherEwesGt2Trading?: InputMaybe<Scalars['Boolean']['input']>;
  ramsBreeding?: InputMaybe<Scalars['Boolean']['input']>;
  ramsTrading?: InputMaybe<Scalars['Boolean']['input']>;
  updateActivityInput?: InputMaybe<UpdateActivityInput>;
  wetherLambsLt1Breeding?: InputMaybe<Scalars['Boolean']['input']>;
  wetherLambsLt1Trading?: InputMaybe<Scalars['Boolean']['input']>;
  wethersGt1Breeding?: InputMaybe<Scalars['Boolean']['input']>;
  wethersGt1Trading?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateSheepMonthLivestockActivityInput = {
  averageLiveweightKg?: InputMaybe<Scalars['Int']['input']>;
  crudeProteinPercentage?: InputMaybe<Scalars['Percent']['input']>;
  dryMatterDigestibilityPercentage?: InputMaybe<Scalars['Percent']['input']>;
  feedAvailabilityTonnesPerHectare?: InputMaybe<Scalars['Decimal']['input']>;
  headAtStart?: InputMaybe<Scalars['Int']['input']>;
  keyEvents?: InputMaybe<Scalars['String']['input']>;
  nonSalePopulationChange?: InputMaybe<Scalars['Int']['input']>;
  percentageEwesLambing?: InputMaybe<Scalars['Percent']['input']>;
  previousMonthLiveweightKg?: InputMaybe<Scalars['Int']['input']>;
  seasonalLambingRate?: InputMaybe<Scalars['Percent']['input']>;
};

export type UpdateSheepShearingEventInput = {
  cleanWoolYieldKg?: InputMaybe<Scalars['Decimal']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  livestockCategory?: InputMaybe<LivestockCategory>;
  numberSheepShorn?: InputMaybe<Scalars['Int']['input']>;
  sheepClass?: InputMaybe<SheepClass>;
  woolPerHeadKg?: InputMaybe<Scalars['Decimal']['input']>;
};

export type UpdateVegetationActivityInput = {
  averageAge?: InputMaybe<Scalars['Int']['input']>;
  plantingAreaGeometry?: InputMaybe<Scalars['GeoJSONMultiPolygon']['input']>;
  region?: InputMaybe<VegetationRegion>;
  soilType?: InputMaybe<SoilType>;
  species?: InputMaybe<VegetationSpecies>;
  totalPlantingAreaSquareMetres?: InputMaybe<Scalars['Int']['input']>;
  treesPerHectare?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateWildSeaFisheryActivityInput = {
  offsetsPurchasedTonnes?: InputMaybe<Scalars['Decimal']['input']>;
  productionSystem?: InputMaybe<WildSeaFisheryProductionSystem>;
  totalWeightCaughtKg?: InputMaybe<Scalars['Decimal']['input']>;
};

export type User = {
  __typename: 'User';
  /** The date and time the user was created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** The user's email address. */
  email: Scalars['String']['output'];
  /** The user's first name. */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Does the user have access to the comparable sales tool? */
  hasComparableSalesAccess?: Maybe<Scalars['Boolean']['output']>;
  /** The user's unique ID. */
  id: Scalars['ID']['output'];
  /** The user's last name. */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The user's mobile number. */
  mobile?: Maybe<Scalars['String']['output']>;
  /** The date and time the user accepted the terms and conditions. */
  termsAcceptedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The date and time the user was last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type VegetationActivity = Activity & {
  __typename: 'VegetationActivity';
  averageAge?: Maybe<Scalars['Int']['output']>;
  businessActivity: BusinessActivity;
  businessActivityId: Scalars['ID']['output'];
  end: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  locations?: Maybe<Array<Maybe<ActivityLocation>>>;
  name: Scalars['String']['output'];
  plantingAreaGeometry?: Maybe<Scalars['GeoJSONMultiPolygon']['output']>;
  region?: Maybe<VegetationRegion>;
  soilType?: Maybe<SoilType>;
  species?: Maybe<VegetationSpecies>;
  start: Scalars['Date']['output'];
  targetAllocations?: Maybe<Array<Maybe<ActivityAllocation>>>;
  totalPlantingAreaSquareMetres?: Maybe<Scalars['Int']['output']>;
  treesPerHectare?: Maybe<Scalars['Int']['output']>;
};

export enum VegetationRegion {
  NswActNorthernTablelands = 'NSW_ACT_NORTHERN_TABLELANDS',
  NswActNorthernWheatSheep = 'NSW_ACT_NORTHERN_WHEAT_SHEEP',
  NswActNorthCoast = 'NSW_ACT_NORTH_COAST',
  NswActSouthernTablelands = 'NSW_ACT_SOUTHERN_TABLELANDS',
  NswActSouthernWheatSheep = 'NSW_ACT_SOUTHERN_WHEAT_SHEEP',
  NswActSouthCoast = 'NSW_ACT_SOUTH_COAST',
  NswActWestern = 'NSW_ACT_WESTERN',
  NtAliceSprings = 'NT_ALICE_SPRINGS',
  NtArnhemRoper = 'NT_ARNHEM_ROPER',
  NtDarwinDaly = 'NT_DARWIN_DALY',
  NtVictoriaRiverTennantcreek = 'NT_VICTORIA_RIVER_TENNANTCREEK',
  QldCentralHighlandsNorthern = 'QLD_CENTRAL_HIGHLANDS_NORTHERN',
  QldCentralWestFlinders = 'QLD_CENTRAL_WEST_FLINDERS',
  QldChannelCountry = 'QLD_CHANNEL_COUNTRY',
  QldDarlingDownsBurnett = 'QLD_DARLING_DOWNS_BURNETT',
  QldMaranoaWarrego = 'QLD_MARANOA_WARREGO',
  QldNorthWestGulf = 'QLD_NORTH_WEST_GULF',
  SaMidNorthFlinders = 'SA_MID_NORTH_FLINDERS',
  SaMurray = 'SA_MURRAY',
  SaPastoral = 'SA_PASTORAL',
  SaSouthEast = 'SA_SOUTH_EAST',
  SaWestCoastEyre = 'SA_WEST_COAST_EYRE',
  TasCentralNorthMidlandsSouthEast = 'TAS_CENTRAL_NORTH_MIDLANDS_SOUTH_EAST',
  TasCentralPlateauDerwentValley = 'TAS_CENTRAL_PLATEAU_DERWENT_VALLEY',
  TasEastCoast = 'TAS_EAST_COAST',
  TasNorthEast = 'TAS_NORTH_EAST',
  TasNorthWest = 'TAS_NORTH_WEST',
  TasWestSouthCoast = 'TAS_WEST_SOUTH_COAST',
  VicCentral = 'VIC_CENTRAL',
  VicEastGippsland = 'VIC_EAST_GIPPSLAND',
  VicMallee = 'VIC_MALLEE',
  VicNorthernCountry = 'VIC_NORTHERN_COUNTRY',
  VicNorthEastVic = 'VIC_NORTH_EAST_VIC',
  VicSouthWestVic = 'VIC_SOUTH_WEST_VIC',
  VicWestSouthGippsland = 'VIC_WEST_SOUTH_GIPPSLAND',
  VicWimmera = 'VIC_WIMMERA',
  WaCentralWest = 'WA_CENTRAL_WEST',
  WaCentralWheatBelt = 'WA_CENTRAL_WHEAT_BELT',
  WaGascoyne = 'WA_GASCOYNE',
  WaGoldfieldsEucla = 'WA_GOLDFIELDS_EUCLA',
  WaInterior = 'WA_INTERIOR',
  WaKimberley = 'WA_KIMBERLEY',
  WaPilbara = 'WA_PILBARA',
  WaSouthCoastal = 'WA_SOUTH_COASTAL',
  WaSouthWest = 'WA_SOUTH_WEST',
}

export enum VegetationSpecies {
  Blackbutt = 'BLACKBUTT',
  DunnsWhiteGum = 'DUNNS_WHITE_GUM',
  FloodedGum = 'FLOODED_GUM',
  HoopPine = 'HOOP_PINE',
  LemonScentedGum = 'LEMON_SCENTED_GUM',
  LoblollyPine = 'LOBLOLLY_PINE',
  MaritimePine = 'MARITIME_PINE',
  MixedSpeciesEnvironmentalPlantings = 'MIXED_SPECIES_ENVIRONMENTAL_PLANTINGS',
  MountainAsh = 'MOUNTAIN_ASH',
  NoTreeDataAvailable = 'NO_TREE_DATA_AVAILABLE',
  PinusHybrids = 'PINUS_HYBRIDS',
  PinusRadiata = 'PINUS_RADIATA',
  PinusRadiataHighInput = 'PINUS_RADIATA_HIGH_INPUT',
  PinusRadiataLowInput = 'PINUS_RADIATA_LOW_INPUT',
  RadiataPineHighInput = 'RADIATA_PINE_HIGH_INPUT',
  RadiataPineLowInput = 'RADIATA_PINE_LOW_INPUT',
  RedIronbark = 'RED_IRONBARK',
  ShiningGum = 'SHINING_GUM',
  SlashPine = 'SLASH_PINE',
  SpottedGum = 'SPOTTED_GUM',
  SugarGum = 'SUGAR_GUM',
  SydneyBlueGum = 'SYDNEY_BLUE_GUM',
  TasmanianBlueGum = 'TASMANIAN_BLUE_GUM',
  WesternWhiteGum = 'WESTERN_WHITE_GUM',
}

export type WildSeaFisheryActivity = Activity & {
  __typename: 'WildSeaFisheryActivity';
  businessActivity: BusinessActivity;
  businessActivityId: Scalars['ID']['output'];
  end: Scalars['Date']['output'];
  fisheryBaitPurchases: Array<FisheryBaitPurchase>;
  id: Scalars['ID']['output'];
  locations?: Maybe<Array<Maybe<ActivityLocation>>>;
  name: Scalars['String']['output'];
  offsetsPurchasedTonnes?: Maybe<Scalars['Decimal']['output']>;
  productionSystem?: Maybe<WildSeaFisheryProductionSystem>;
  start: Scalars['Date']['output'];
  targetAllocations?: Maybe<Array<Maybe<ActivityAllocation>>>;
  totalWeightCaughtKg?: Maybe<Scalars['Decimal']['output']>;
};

export type WildSeaFisheryEmissionsCalculation = {
  __typename: 'WildSeaFisheryEmissionsCalculation';
  inputs: EmissionsCalculationInputs;
  result: WildSeaFisheryEmissionsCalculationResult;
};

export type WildSeaFisheryEmissionsCalculationResult = {
  __typename: 'WildSeaFisheryEmissionsCalculationResult';
  intensities?: Maybe<Array<Maybe<WildSeaFisheryEmissionsIntensities>>>;
  net?: Maybe<WildSeaFisheryEmissionsNet>;
  purchasedOffsets?: Maybe<WildSeaFisheryPurchasedOffsets>;
  scope1?: Maybe<WildSeaFisheryEmissionsScope1>;
  scope2?: Maybe<WildSeaFisheryEmissionsScope2>;
  scope3?: Maybe<WildSeaFisheryEmissionsScope3>;
};

export type WildSeaFisheryEmissionsIntensities = {
  __typename: 'WildSeaFisheryEmissionsIntensities';
  intensityExcludingCarbonOffset?: Maybe<Scalars['Float']['output']>;
  intensityIncludingCarbonOffset?: Maybe<Scalars['Float']['output']>;
};

export type WildSeaFisheryEmissionsNet = {
  __typename: 'WildSeaFisheryEmissionsNet';
  total?: Maybe<Scalars['Float']['output']>;
};

export type WildSeaFisheryEmissionsScope1 = {
  __typename: 'WildSeaFisheryEmissionsScope1';
  fuelCH4?: Maybe<Scalars['Float']['output']>;
  fuelCO2?: Maybe<Scalars['Float']['output']>;
  fuelN2O?: Maybe<Scalars['Float']['output']>;
  hfcsRefrigerantLeakage?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  totalCH4?: Maybe<Scalars['Float']['output']>;
  totalCO2?: Maybe<Scalars['Float']['output']>;
  totalHFCs?: Maybe<Scalars['Float']['output']>;
  totalN2O?: Maybe<Scalars['Float']['output']>;
};

export type WildSeaFisheryEmissionsScope2 = {
  __typename: 'WildSeaFisheryEmissionsScope2';
  electricity?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type WildSeaFisheryEmissionsScope3 = {
  __typename: 'WildSeaFisheryEmissionsScope3';
  bait?: Maybe<Scalars['Float']['output']>;
  electricity?: Maybe<Scalars['Float']['output']>;
  fuel?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export enum WildSeaFisheryProductionSystem {
  Abalone = 'ABALONE',
  DemersalTrawl = 'DEMERSAL_TRAWL',
  Gillnet = 'GILLNET',
  Handline = 'HANDLINE',
  LobsterPot = 'LOBSTER_POT',
  Longline = 'LONGLINE',
  NorthernFishTrap = 'NORTHERN_FISH_TRAP',
  NorthernPrawnTrawl = 'NORTHERN_PRAWN_TRAWL',
  OtterBoardTrawl = 'OTTER_BOARD_TRAWL',
  PrawnTrawlAustralianAverage = 'PRAWN_TRAWL_AUSTRALIAN_AVERAGE',
  PurseSeine = 'PURSE_SEINE',
  SouthernOceanLongline = 'SOUTHERN_OCEAN_LONGLINE',
}

export type WildSeaFisheryPurchasedOffsets = {
  __typename: 'WildSeaFisheryPurchasedOffsets';
  total?: Maybe<Scalars['Float']['output']>;
};

export type FiresTable_ReportDataFragment = {
  __typename: 'ReportData';
  burnedAreaEvents?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'FiresTable_ReportDataFragment' };

export type Report_ReportFragment = {
  __typename: 'Report';
  id: string;
  type: ReportType;
  createdAt: string;
  sections: Array<ReportSection>;
  status: ReportStatus;
  data?: {
    __typename: 'ReportData';
    reportMetadata?: {
      __typename: 'ReportDataItem';
      result?: {
        __typename: 'ReportDataItemResult';
        id: string;
        values?: JSON.Value | null;
      } | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'Report_ReportFragment' };

export type ClimateChangePage_ReportFragment = {
  __typename: 'Report';
  data?:
    | ({ __typename: 'ReportData' } & {
        ' $fragmentRefs'?: {
          ClimateChangeRainfall_ReportDataFragment: ClimateChangeRainfall_ReportDataFragment;
          ClimateChangeTempMin_ReportDataFragment: ClimateChangeTempMin_ReportDataFragment;
          ClimateChangeTempMax_ReportDataFragment: ClimateChangeTempMax_ReportDataFragment;
        };
      })
    | null;
} & { ' $fragmentName'?: 'ClimateChangePage_ReportFragment' };

export type ClimateChangeRainfall_ReportDataFragment = {
  __typename: 'ReportData';
  climateSSP126RainfallYearly?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  climateSSP126RainfallYearlyStdDev?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'ClimateChangeRainfall_ReportDataFragment' };

export type ClimateChangeTempMax_ReportDataFragment = {
  __typename: 'ReportData';
  climateSSP126TempMaxYearly?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  climateSSP126TempMaxYearlyStdDev?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'ClimateChangeTempMax_ReportDataFragment' };

export type ClimateChangeTempMin_ReportDataFragment = {
  __typename: 'ReportData';
  climateSSP126TempMinYearly?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  climateSSP126TempMinYearlyStdDev?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'ClimateChangeTempMin_ReportDataFragment' };

export type DeforestationOverTimePage_ReportFragment = {
  __typename: 'Report';
  data?:
    | ({ __typename: 'ReportData' } & {
        ' $fragmentRefs'?: {
          DeforestationOverTimeChart_ReportDataFragment: DeforestationOverTimeChart_ReportDataFragment;
          UseDeforestationImages_ReportDataFragment: UseDeforestationImages_ReportDataFragment;
        };
      })
    | null;
} & { ' $fragmentName'?: 'DeforestationOverTimePage_ReportFragment' };

export type DeforestationOverTimeChart_ReportDataFragment = {
  __typename: 'ReportData';
  deforestationYearly?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  deforestationColorMap?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  deforestationDataQuality?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'DeforestationOverTimeChart_ReportDataFragment' };

export type UseDeforestationImages_ReportDataFragment = {
  __typename: 'ReportData';
  deforestationYearlyImages?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      files?: Array<{
        __typename: 'ReportDataItemResultFile';
        name: string;
        location: string;
      } | null> | null;
    } | null;
  } | null;
  deforestationRgbYearlyImages?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      files?: Array<{
        __typename: 'ReportDataItemResultFile';
        name: string;
        location: string;
      } | null> | null;
    } | null;
  } | null;
  deforestationNdviYearlyImages?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      files?: Array<{
        __typename: 'ReportDataItemResultFile';
        name: string;
        location: string;
      } | null> | null;
    } | null;
  } | null;
  deforestationDataQuality?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'UseDeforestationImages_ReportDataFragment' };

export type EnvironmentRiskPage_ReportFragment = {
  __typename: 'Report';
  data?:
    | ({ __typename: 'ReportData' } & {
        ' $fragmentRefs'?: {
          FiresTable_ReportDataFragment: FiresTable_ReportDataFragment;
          TemperatureMeanHistory_ReportDataFragment: TemperatureMeanHistory_ReportDataFragment;
        };
      })
    | null;
} & { ' $fragmentName'?: 'EnvironmentRiskPage_ReportFragment' };

export type TemperatureMeanHistory_ReportDataFragment = {
  __typename: 'ReportData';
  temperatureMeanHistory?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  temperatureMeanYtd?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  temperatureMeanYtdMinus1?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'TemperatureMeanHistory_ReportDataFragment' };

export type ForestsAndFiresPage_ReportFragment = {
  __typename: 'Report';
  data?:
    | ({
        __typename: 'ReportData';
        propertyParcelsGeoJson?: {
          __typename: 'ReportDataItem';
          result?: {
            __typename: 'ReportDataItemResult';
            id: string;
            values?: JSON.Value | null;
          } | null;
        } | null;
        forestClasses?: {
          __typename: 'ReportDataItem';
          result?: {
            __typename: 'ReportDataItemResult';
            id: string;
            values?: JSON.Value | null;
          } | null;
        } | null;
        forestClassesImage?: {
          __typename: 'ReportDataItem';
          result?: {
            __typename: 'ReportDataItemResult';
            id: string;
            files?: Array<{
              __typename: 'ReportDataItemResultFile';
              location: string;
            } | null> | null;
          } | null;
        } | null;
      } & {
        ' $fragmentRefs'?: {
          FiresTable_ReportDataFragment: FiresTable_ReportDataFragment;
        };
      })
    | null;
} & { ' $fragmentName'?: 'ForestsAndFiresPage_ReportFragment' };

export type GrassAndForestFireRiskPage_ReportFragment = {
  __typename: 'Report';
  data?:
    | ({
        __typename: 'ReportData';
        grasslandFireMaximum?: {
          __typename: 'ReportDataItem';
          result?: {
            __typename: 'ReportDataItemResult';
            files?: Array<{
              __typename: 'ReportDataItemResultFile';
              location: string;
            } | null> | null;
          } | null;
        } | null;
      } & {
        ' $fragmentRefs'?: {
          FireRiskChart_ReportDataFragment: FireRiskChart_ReportDataFragment;
          TemperatureMeanHistory_ReportDataFragment: TemperatureMeanHistory_ReportDataFragment;
        };
      })
    | null;
} & { ' $fragmentName'?: 'GrassAndForestFireRiskPage_ReportFragment' };

export type FireRiskChart_ReportDataFragment = {
  __typename: 'ReportData';
  grasslandFireMonthly?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'FireRiskChart_ReportDataFragment' };

export type LandAndNaturePage_ReportFragment = {
  __typename: 'Report';
  data?: {
    __typename: 'ReportData';
    propertyParcelsGeoJson?: {
      __typename: 'ReportDataItem';
      result?: {
        __typename: 'ReportDataItemResult';
        id: string;
        values?: JSON.Value | null;
      } | null;
    } | null;
    forestClasses?: {
      __typename: 'ReportDataItem';
      result?: {
        __typename: 'ReportDataItemResult';
        id: string;
        values?: JSON.Value | null;
      } | null;
    } | null;
    forestClassesImage?: {
      __typename: 'ReportDataItem';
      result?: {
        __typename: 'ReportDataItemResult';
        id: string;
        files?: Array<{
          __typename: 'ReportDataItemResultFile';
          location: string;
        } | null> | null;
      } | null;
    } | null;
    landCoverClasses?: {
      __typename: 'ReportDataItem';
      result?: {
        __typename: 'ReportDataItemResult';
        id: string;
        values?: JSON.Value | null;
      } | null;
    } | null;
    landCoverClassesImage?: {
      __typename: 'ReportDataItem';
      result?: {
        __typename: 'ReportDataItemResult';
        id: string;
        files?: Array<{
          __typename: 'ReportDataItemResultFile';
          location: string;
        } | null> | null;
      } | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'LandAndNaturePage_ReportFragment' };

export type LandUsePage_ReportFragment = {
  __typename: 'Report';
  data?:
    | ({ __typename: 'ReportData' } & {
        ' $fragmentRefs'?: {
          LandUse_ReportDataFragment: LandUse_ReportDataFragment;
          PropertyDetailsLandUseTable_ReportDataFragment: PropertyDetailsLandUseTable_ReportDataFragment;
          UseTerrainSummary_ReportDataFragment: UseTerrainSummary_ReportDataFragment;
        };
      })
    | null;
} & { ' $fragmentName'?: 'LandUsePage_ReportFragment' };

export type PropertyDetailsLandUseTable_ReportDataFragment = {
  __typename: 'ReportData';
  propertySite?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'PropertyDetailsLandUseTable_ReportDataFragment' };

export type LandUse_ReportDataFragment = {
  __typename: 'ReportData';
  landUseClasses?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'LandUse_ReportDataFragment' };

export type UseTerrainSummary_ReportDataFragment = {
  __typename: 'ReportData';
  satelliteRgbYearlyImages?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      files?: Array<{
        __typename: 'ReportDataItemResultFile';
        name: string;
        location: string;
      } | null> | null;
    } | null;
  } | null;
  landUseClassesImage?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      files?: Array<{
        __typename: 'ReportDataItemResultFile';
        name: string;
        location: string;
      } | null> | null;
    } | null;
  } | null;
  satelliteNdviYearlyImages?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      files?: Array<{
        __typename: 'ReportDataItemResultFile';
        name: string;
        location: string;
      } | null> | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'UseTerrainSummary_ReportDataFragment' };

export type OverviewPage_ReportFragment = {
  __typename: 'Report';
  data?:
    | ({ __typename: 'ReportData' } & {
        ' $fragmentRefs'?: {
          OverviewMap_ReportDataFragment: OverviewMap_ReportDataFragment;
          PropertyDetailsTable_ReportDataFragment: PropertyDetailsTable_ReportDataFragment;
          LandmarksTable_ReportDataFragment: LandmarksTable_ReportDataFragment;
        };
      })
    | null;
} & { ' $fragmentName'?: 'OverviewPage_ReportFragment' };

export type LandmarksTable_ReportDataFragment = {
  __typename: 'ReportData';
  landmarksTowns?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  landmarksCities?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  landmarksSchools?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  landmarksSilos?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'LandmarksTable_ReportDataFragment' };

export type OverviewMap_ReportDataFragment = {
  __typename: 'ReportData';
  propertyParcelsGeoJson?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  buildingsGeometries?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'OverviewMap_ReportDataFragment' };

export type PropertyDetailsTable_ReportDataFragment = {
  __typename: 'ReportData';
  propertySite?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  landmarksLocation?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'PropertyDetailsTable_ReportDataFragment' };

export type ParcelsAndBuildingsPage_ReportFragment = {
  __typename: 'Report';
  data?: {
    __typename: 'ReportData';
    propertyParcelsList?: {
      __typename: 'ReportDataItem';
      result?: {
        __typename: 'ReportDataItemResult';
        id: string;
        values?: JSON.Value | null;
      } | null;
    } | null;
    buildingsList?: {
      __typename: 'ReportDataItem';
      result?: {
        __typename: 'ReportDataItemResult';
        id: string;
        values?: JSON.Value | null;
      } | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'ParcelsAndBuildingsPage_ReportFragment' };

export type ProductivityPage_ReportFragment = {
  __typename: 'Report';
  data?:
    | ({ __typename: 'ReportData' } & {
        ' $fragmentRefs'?: {
          EviAreaProductivity_ReportDataFragment: EviAreaProductivity_ReportDataFragment;
          EviAreaAndRainfall_ReportDataFragment: EviAreaAndRainfall_ReportDataFragment;
          YieldCropTypes_ReportDataFragment: YieldCropTypes_ReportDataFragment;
        };
      })
    | null;
} & { ' $fragmentName'?: 'ProductivityPage_ReportFragment' };

export type EviAreaAndRainfall_ReportDataFragment = {
  __typename: 'ReportData';
  eviAreaProductivity?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  rainfallVolume?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'EviAreaAndRainfall_ReportDataFragment' };

export type EviAreaProductivity_ReportDataFragment = {
  __typename: 'ReportData';
  eviAreaProductivity?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'EviAreaProductivity_ReportDataFragment' };

export type YieldCropTypes_ReportDataFragment = {
  __typename: 'ReportData';
  yieldModelYearly?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'YieldCropTypes_ReportDataFragment' };

export type PropertyAndClimateSummaryPage_ReportFragment = {
  __typename: 'Report';
  data?:
    | ({
        __typename: 'ReportData';
        elevationArableImage?: {
          __typename: 'ReportDataItem';
          result?: {
            __typename: 'ReportDataItemResult';
            files?: Array<{
              __typename: 'ReportDataItemResultFile';
              location: string;
            } | null> | null;
          } | null;
        } | null;
        landUseClassesImage?: {
          __typename: 'ReportDataItem';
          result?: {
            __typename: 'ReportDataItemResult';
            files?: Array<{
              __typename: 'ReportDataItemResultFile';
              name: string;
              location: string;
            } | null> | null;
          } | null;
        } | null;
        landUseClasses?: {
          __typename: 'ReportDataItem';
          result?: {
            __typename: 'ReportDataItemResult';
            id: string;
            values?: JSON.Value | null;
          } | null;
        } | null;
      } & {
        ' $fragmentRefs'?: {
          SummaryTable_ReportDataFragment: SummaryTable_ReportDataFragment;
          ProductivityCard_ReportDataFragment: ProductivityCard_ReportDataFragment;
          AnnualRainfallCard_ReportDataFragment: AnnualRainfallCard_ReportDataFragment;
          ClimateRiskCard_ReportDataFragment: ClimateRiskCard_ReportDataFragment;
        };
      })
    | null;
} & { ' $fragmentName'?: 'PropertyAndClimateSummaryPage_ReportFragment' };

export type AnnualRainfallCard_ReportDataFragment = {
  __typename: 'ReportData';
  rainfallStatistics?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'AnnualRainfallCard_ReportDataFragment' };

export type ClimateRiskCard_ReportDataFragment = {
  __typename: 'ReportData';
  climateRiskStatistics?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'ClimateRiskCard_ReportDataFragment' };

export type ProductivityCard_ReportDataFragment = {
  __typename: 'ReportData';
  yieldModelDistribution?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'ProductivityCard_ReportDataFragment' };

export type SummaryTable_ReportDataFragment = {
  __typename: 'ReportData';
  elevationArable?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  landUseClasses?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  soilAusClasses?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  elevationStatistics?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  elevationSlope?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'SummaryTable_ReportDataFragment' };

export type RainfallPage_ReportFragment = {
  __typename: 'Report';
  data?:
    | ({ __typename: 'ReportData' } & {
        ' $fragmentRefs'?: {
          RainfallDuration_ReportDataFragment: RainfallDuration_ReportDataFragment;
          RainfallVolume_ReportDataFragment: RainfallVolume_ReportDataFragment;
          SeasonalRainfallHistory_ReportDataFragment: SeasonalRainfallHistory_ReportDataFragment;
          RainfallHistory_ReportDataFragment: RainfallHistory_ReportDataFragment;
        };
      })
    | null;
} & { ' $fragmentName'?: 'RainfallPage_ReportFragment' };

export type RainfallDuration_ReportDataFragment = {
  __typename: 'ReportData';
  rainfallDuration?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'RainfallDuration_ReportDataFragment' };

export type RainfallHistory_ReportDataFragment = {
  __typename: 'ReportData';
  rainfallHistory?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  rainfallYtd?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  rainfallYtdMinus1?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'RainfallHistory_ReportDataFragment' };

export type RainfallVolume_ReportDataFragment = {
  __typename: 'ReportData';
  rainfallVolume?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'RainfallVolume_ReportDataFragment' };

export type SeasonalRainfallHistory_ReportDataFragment = {
  __typename: 'ReportData';
  rainfallVolume?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'SeasonalRainfallHistory_ReportDataFragment' };

export type SalesPage_ReportFragment = ({
  __typename: 'Report';
  data?:
    | ({
        __typename: 'ReportData';
        salesPropertyHistory?: {
          __typename: 'ReportDataItem';
          result?: {
            __typename: 'ReportDataItemResult';
            id: string;
            values?: JSON.Value | null;
          } | null;
        } | null;
      } & {
        ' $fragmentRefs'?: {
          RecentSalesPriceChart_ReportDataFragment: RecentSalesPriceChart_ReportDataFragment;
          RecentSalesPricePerAreaChart_ReportDataFragment: RecentSalesPricePerAreaChart_ReportDataFragment;
          PricePerHectareOverTimeChart_ReportDataFragment: PricePerHectareOverTimeChart_ReportDataFragment;
          NumberOfSalesOverTimeChart_ReportDataFragment: NumberOfSalesOverTimeChart_ReportDataFragment;
          PropertyPastSalesTable_ReportDataFragment: PropertyPastSalesTable_ReportDataFragment;
        };
      })
    | null;
} & {
  ' $fragmentRefs'?: {
    RecentSalesPriceChart_ReportFragment: RecentSalesPriceChart_ReportFragment;
  };
}) & { ' $fragmentName'?: 'SalesPage_ReportFragment' };

export type NumberOfSalesOverTimeChart_ReportDataFragment = {
  __typename: 'ReportData';
  salesSaleCountHistory?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'NumberOfSalesOverTimeChart_ReportDataFragment' };

export type PricePerHectareOverTimeChart_ReportDataFragment = {
  __typename: 'ReportData';
  salesAveragePriceHistory?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'PricePerHectareOverTimeChart_ReportDataFragment' };

export type PropertyPastSalesTable_ReportDataFragment = {
  __typename: 'ReportData';
  salesPropertyHistory?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'PropertyPastSalesTable_ReportDataFragment' };

export type RecentSalesPriceChart_ReportDataFragment = {
  __typename: 'ReportData';
  salesPriceDistribution?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  salesPropertyHistory?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'RecentSalesPriceChart_ReportDataFragment' };

export type RecentSalesPriceChart_ReportFragment = {
  __typename: 'Report';
  id: string;
  comparableSales: Array<{
    __typename: 'ComparableSale';
    propertyId: string;
    saleId: string;
    name?: string | null;
    similarity: Decimal;
    sale: {
      __typename: 'Sale';
      id: string;
      price?: number | null;
      contractDate?: string | null;
    };
  }>;
} & { ' $fragmentName'?: 'RecentSalesPriceChart_ReportFragment' };

export type RecentSalesPricePerAreaChart_ReportDataFragment = {
  __typename: 'ReportData';
  salesPricePerAreaDistribution?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  salesPropertyHistory?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'RecentSalesPricePerAreaChart_ReportDataFragment' };

export type SoilProfilePage_ReportFragment = {
  __typename: 'Report';
  data?:
    | ({ __typename: 'ReportData' } & {
        ' $fragmentRefs'?: {
          SoilTexture_ReportDataFragment: SoilTexture_ReportDataFragment;
          SoilOrganicCarbon_ReportDataFragment: SoilOrganicCarbon_ReportDataFragment;
          SoilPh_ReportDataFragment: SoilPh_ReportDataFragment;
          SoilAwc_ReportDataFragment: SoilAwc_ReportDataFragment;
        };
      })
    | null;
} & { ' $fragmentName'?: 'SoilProfilePage_ReportFragment' };

export type SoilAwc_ReportDataFragment = {
  __typename: 'ReportData';
  soilAwcProfile?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'SoilAwc_ReportDataFragment' };

export type SoilOrganicCarbon_ReportDataFragment = {
  __typename: 'ReportData';
  soilOcProfile?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'SoilOrganicCarbon_ReportDataFragment' };

export type SoilPh_ReportDataFragment = {
  __typename: 'ReportData';
  soilPhProfile?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'SoilPh_ReportDataFragment' };

export type SoilTexture_ReportDataFragment = {
  __typename: 'ReportData';
  soilSandProfile?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  soilSiltProfile?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  soilClayProfile?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'SoilTexture_ReportDataFragment' };

export type SoilTaxonomyPage_ReportFragment = {
  __typename: 'Report';
  data?: {
    __typename: 'ReportData';
    propertyParcelsGeoJson?: {
      __typename: 'ReportDataItem';
      result?: {
        __typename: 'ReportDataItemResult';
        id: string;
        values?: JSON.Value | null;
      } | null;
    } | null;
    soilAusClasses?: {
      __typename: 'ReportDataItem';
      result?: {
        __typename: 'ReportDataItemResult';
        id: string;
        values?: JSON.Value | null;
      } | null;
    } | null;
    soilAusClassesImage?: {
      __typename: 'ReportDataItem';
      result?: {
        __typename: 'ReportDataItemResult';
        id: string;
        files?: Array<{
          __typename: 'ReportDataItemResultFile';
          location: string;
        } | null> | null;
      } | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'SoilTaxonomyPage_ReportFragment' };

export type SoilTexturePage_ReportFragment = {
  __typename: 'Report';
  data?: {
    __typename: 'ReportData';
    soilOcImage?: {
      __typename: 'ReportDataItem';
      result?: {
        __typename: 'ReportDataItemResult';
        files?: Array<{
          __typename: 'ReportDataItemResultFile';
          location: string;
        } | null> | null;
      } | null;
    } | null;
    soilPhImage?: {
      __typename: 'ReportDataItem';
      result?: {
        __typename: 'ReportDataItemResult';
        files?: Array<{
          __typename: 'ReportDataItemResultFile';
          location: string;
        } | null> | null;
      } | null;
    } | null;
    soilAwcImage?: {
      __typename: 'ReportDataItem';
      result?: {
        __typename: 'ReportDataItemResult';
        files?: Array<{
          __typename: 'ReportDataItemResultFile';
          location: string;
        } | null> | null;
      } | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'SoilTexturePage_ReportFragment' };

export type TableOfContentsPage_ReportFragment = {
  __typename: 'Report';
  id: string;
  sections: Array<ReportSection>;
} & { ' $fragmentName'?: 'TableOfContentsPage_ReportFragment' };

export type TemperatureEventsPage_ReportFragment = {
  __typename: 'Report';
  data?:
    | ({ __typename: 'ReportData' } & {
        ' $fragmentRefs'?: {
          HeatEventsVolume_ReportDataFragment: HeatEventsVolume_ReportDataFragment;
          FrostEventsVolume_ReportDataFragment: FrostEventsVolume_ReportDataFragment;
          HeatEventsHistory_ReportDataFragment: HeatEventsHistory_ReportDataFragment;
          FrostEventsHistory_ReportDataFragment: FrostEventsHistory_ReportDataFragment;
        };
      })
    | null;
} & { ' $fragmentName'?: 'TemperatureEventsPage_ReportFragment' };

export type FrostEventsHistory_ReportDataFragment = {
  __typename: 'ReportData';
  frostEventsHistory?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  frostEventsYtd?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  frostEventsYtdMinus1?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'FrostEventsHistory_ReportDataFragment' };

export type FrostEventsVolume_ReportDataFragment = {
  __typename: 'ReportData';
  frostEventsVolume?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'FrostEventsVolume_ReportDataFragment' };

export type HeatEventsHistory_ReportDataFragment = {
  __typename: 'ReportData';
  heatEventsHistory?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  heatEventsYtd?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
  heatEventsYtdMinus1?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'HeatEventsHistory_ReportDataFragment' };

export type HeatEventsVolume_ReportDataFragment = {
  __typename: 'ReportData';
  heatEventsVolume?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'HeatEventsVolume_ReportDataFragment' };

export type TerrainPage_ReportFragment = {
  __typename: 'Report';
  data?:
    | ({
        __typename: 'ReportData';
        elevationElevationImage?: {
          __typename: 'ReportDataItem';
          result?: {
            __typename: 'ReportDataItemResult';
            files?: Array<{
              __typename: 'ReportDataItemResultFile';
              location: string;
            } | null> | null;
          } | null;
        } | null;
        elevationSlopeImage?: {
          __typename: 'ReportDataItem';
          result?: {
            __typename: 'ReportDataItemResult';
            files?: Array<{
              __typename: 'ReportDataItemResultFile';
              location: string;
            } | null> | null;
          } | null;
        } | null;
        elevationStatistics?: {
          __typename: 'ReportDataItem';
          result?: {
            __typename: 'ReportDataItemResult';
            id: string;
            values?: JSON.Value | null;
          } | null;
        } | null;
        elevationSlope?: {
          __typename: 'ReportDataItem';
          result?: {
            __typename: 'ReportDataItemResult';
            id: string;
            values?: JSON.Value | null;
          } | null;
        } | null;
      } & {
        ' $fragmentRefs'?: {
          Elevation_ReportDataFragment: Elevation_ReportDataFragment;
          Slope_ReportDataFragment: Slope_ReportDataFragment;
        };
      })
    | null;
} & { ' $fragmentName'?: 'TerrainPage_ReportFragment' };

export type Elevation_ReportDataFragment = {
  __typename: 'ReportData';
  elevationStatistics?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'Elevation_ReportDataFragment' };

export type Slope_ReportDataFragment = {
  __typename: 'ReportData';
  elevationSlope?: {
    __typename: 'ReportDataItem';
    result?: {
      __typename: 'ReportDataItemResult';
      id: string;
      values?: JSON.Value | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'Slope_ReportDataFragment' };

export type ViewReport_ReportFragment = ({
  __typename: 'Report';
  id: string;
  teamId: string;
  status: ReportStatus;
  type: ReportType;
} & {
  ' $fragmentRefs'?: {
    Report_ReportFragment: Report_ReportFragment;
    ParcelsAndBuildingsPage_ReportFragment: ParcelsAndBuildingsPage_ReportFragment;
    TableOfContentsPage_ReportFragment: TableOfContentsPage_ReportFragment;
    OverviewPage_ReportFragment: OverviewPage_ReportFragment;
    PropertyAndClimateSummaryPage_ReportFragment: PropertyAndClimateSummaryPage_ReportFragment;
    LandUsePage_ReportFragment: LandUsePage_ReportFragment;
    ProductivityPage_ReportFragment: ProductivityPage_ReportFragment;
    RainfallPage_ReportFragment: RainfallPage_ReportFragment;
    TerrainPage_ReportFragment: TerrainPage_ReportFragment;
    SoilTaxonomyPage_ReportFragment: SoilTaxonomyPage_ReportFragment;
    SoilTexturePage_ReportFragment: SoilTexturePage_ReportFragment;
    SoilProfilePage_ReportFragment: SoilProfilePage_ReportFragment;
    LandAndNaturePage_ReportFragment: LandAndNaturePage_ReportFragment;
    GrassAndForestFireRiskPage_ReportFragment: GrassAndForestFireRiskPage_ReportFragment;
    EnvironmentRiskPage_ReportFragment: EnvironmentRiskPage_ReportFragment;
    ClimateChangePage_ReportFragment: ClimateChangePage_ReportFragment;
    TemperatureEventsPage_ReportFragment: TemperatureEventsPage_ReportFragment;
    DeforestationOverTimePage_ReportFragment: DeforestationOverTimePage_ReportFragment;
    ForestsAndFiresPage_ReportFragment: ForestsAndFiresPage_ReportFragment;
    SalesPage_ReportFragment: SalesPage_ReportFragment;
  };
}) & { ' $fragmentName'?: 'ViewReport_ReportFragment' };

export type GetReportQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetReportQuery = {
  __typename: 'Query';
  report?:
    | ({ __typename: 'Report'; id: string } & {
        ' $fragmentRefs'?: {
          ViewReport_ReportFragment: ViewReport_ReportFragment;
        };
      })
    | null;
};

export type CreateMapShapeFromManageMapAreaButtonMutationVariables = Exact<{
  input: CreateMapShapeInput;
}>;

export type CreateMapShapeFromManageMapAreaButtonMutation = {
  __typename: 'Mutation';
  createMapShape: { __typename: 'MapShape'; id: string };
};

export type ComparableSaleAsideLayoutComparableSaleFragment = {
  __typename: 'ComparableSale';
  name?: string | null;
  sale: {
    __typename: 'Sale';
    id: string;
    price?: number | null;
    contractDate?: string | null;
    propertiesInSale: Array<{
      __typename: 'PropertyInSale';
      property: {
        __typename: 'Property';
        id: string;
        addressLine1?: string | null;
        propertyParcels?: Array<{
          __typename: 'ParcelInProperty';
          parcel?: {
            __typename: 'Parcel';
            id: string;
            hectares?: number | null;
            latitude?: number | null;
            longitude?: number | null;
            legalNumber?: string | null;
          } | null;
        } | null> | null;
      };
    } | null>;
  };
} & { ' $fragmentName'?: 'ComparableSaleAsideLayoutComparableSaleFragment' };

export type GetComparableSaleQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  saleId: Scalars['ID']['input'];
}>;

export type GetComparableSaleQuery = {
  __typename: 'Query';
  comparableSale?:
    | ({ __typename: 'ComparableSale' } & {
        ' $fragmentRefs'?: {
          ComparableSaleAsideLayoutComparableSaleFragment: ComparableSaleAsideLayoutComparableSaleFragment;
        };
      })
    | null;
};

export type PortfolioLayoutPropertyCoordinates_PortfolioFragment = {
  __typename: 'Portfolio';
  portfolioProperties: Array<{
    __typename: 'PropertyInPortfolio';
    propertyId: string;
    portfolioId: string;
    property: {
      __typename: 'Property';
      id: string;
      centroid?: GeoJSON.Point | null;
      name?: string | null;
      bbox?: (GeoJSON.BBox & [number, number, number, number]) | null;
    };
  }>;
} & {
  ' $fragmentName'?: 'PortfolioLayoutPropertyCoordinates_PortfolioFragment';
};

export type PortfolioLayoutPropertyList_PortfolioFragment = {
  __typename: 'Portfolio';
  portfolioProperties: Array<{
    __typename: 'PropertyInPortfolio';
    propertyId: string;
    portfolioId: string;
    property: { __typename: 'Property'; id: string };
  }>;
} & { ' $fragmentName'?: 'PortfolioLayoutPropertyList_PortfolioFragment' };

export type PortfolioLayoutPortals_PortfolioFragment = {
  __typename: 'Portfolio';
  id: string;
  name: string;
  portfolioProperties: Array<{
    __typename: 'PropertyInPortfolio';
    propertyId: string;
    portfolioId: string;
    property: {
      __typename: 'Property';
      id: string;
      centroid?: GeoJSON.Point | null;
      name?: string | null;
      bbox?: (GeoJSON.BBox & [number, number, number, number]) | null;
    };
  }>;
} & { ' $fragmentName'?: 'PortfolioLayoutPortals_PortfolioFragment' };

export type GetPortfolioForLayoutQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPortfolioForLayoutQuery = {
  __typename: 'Query';
  portfolio: { __typename: 'Portfolio'; id: string } & {
    ' $fragmentRefs'?: {
      PortfolioLayoutPortals_PortfolioFragment: PortfolioLayoutPortals_PortfolioFragment;
    };
  };
};

export type PropertyLayoutPortals_PropertyFragmentFragment = ({
  __typename: 'Property';
  id: string;
  name?: string | null;
} & {
  ' $fragmentRefs'?: {
    PropertyLayoutParcelGeometries_PropertyFragmentFragment: PropertyLayoutParcelGeometries_PropertyFragmentFragment;
  };
}) & { ' $fragmentName'?: 'PropertyLayoutPortals_PropertyFragmentFragment' };

export type GetPropertyForLayoutQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPropertyForLayoutQuery = {
  __typename: 'Query';
  property?:
    | ({ __typename: 'Property'; id: string } & {
        ' $fragmentRefs'?: {
          PropertyLayoutPortals_PropertyFragmentFragment: PropertyLayoutPortals_PropertyFragmentFragment;
        };
      })
    | null;
};

export type PropertyLayoutParcelGeometries_PropertyFragmentFragment = {
  __typename: 'Property';
  propertyParcels?: Array<{
    __typename: 'ParcelInProperty';
    propertyId?: string | null;
    parcelId?: string | null;
    name?: string | null;
    geometry?: GeoJSON.MultiPolygon | null;
  } | null> | null;
} & {
  ' $fragmentName'?: 'PropertyLayoutParcelGeometries_PropertyFragmentFragment';
};

export type FeedDrySeasonMix_FeedFragmentFragment = {
  __typename: 'Feed';
  id: string;
  drySeasonMixKg: number;
  drySeasonMixUreaPercentage: Decimal;
} & { ' $fragmentName'?: 'FeedDrySeasonMix_FeedFragmentFragment' };

export type FeedMineralBlock_FeedFragmentFragment = {
  __typename: 'Feed';
  id: string;
  mineralBlockKg: number;
  mineralBlockUreaPercentage: Decimal;
} & { ' $fragmentName'?: 'FeedMineralBlock_FeedFragmentFragment' };

export type FeedWeanerBlock_FeedFragmentFragment = {
  __typename: 'Feed';
  id: string;
  weanerBlockKg: number;
  weanerBlockUreaPercentage: Decimal;
} & { ' $fragmentName'?: 'FeedWeanerBlock_FeedFragmentFragment' };

export type UpdateFeedWeanerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateFeedInput: UpdateFeedInput;
}>;

export type UpdateFeedWeanerMutation = {
  __typename: 'Mutation';
  updateFeed: { __typename: 'Feed'; id: string } & {
    ' $fragmentRefs'?: {
      FeedWeanerBlock_FeedFragmentFragment: FeedWeanerBlock_FeedFragmentFragment;
    };
  };
};

export type UpdateFeedDrySeasonMixMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateFeedInput: UpdateFeedInput;
}>;

export type UpdateFeedDrySeasonMixMutation = {
  __typename: 'Mutation';
  updateFeed: { __typename: 'Feed'; id: string } & {
    ' $fragmentRefs'?: {
      FeedDrySeasonMix_FeedFragmentFragment: FeedDrySeasonMix_FeedFragmentFragment;
    };
  };
};

export type UpdateFeedMineralBlockMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateFeedInput: UpdateFeedInput;
}>;

export type UpdateFeedMineralBlockMutation = {
  __typename: 'Mutation';
  updateFeed: { __typename: 'Feed'; id: string } & {
    ' $fragmentRefs'?: {
      FeedMineralBlock_FeedFragmentFragment: FeedMineralBlock_FeedFragmentFragment;
    };
  };
};

export type MapShapesList_MapShapeFragment = ({
  __typename: 'MapShape';
  id: string;
} & {
  ' $fragmentRefs'?: {
    MapShapesListItem_MapShapeFragment: MapShapesListItem_MapShapeFragment;
  };
}) & { ' $fragmentName'?: 'MapShapesList_MapShapeFragment' };

export type MapShapesListItem_MapShapeFragment = {
  __typename: 'MapShape';
  id: string;
  type: MapShapeType;
  name?: string | null;
  geometry?: GeoJSON.MultiPolygon | null;
} & { ' $fragmentName'?: 'MapShapesListItem_MapShapeFragment' };

export type SelectMapShapeFromLibraryModal_MapShapeFragment = ({
  __typename: 'MapShape';
  id: string;
  geometry?: GeoJSON.MultiPolygon | null;
} & {
  ' $fragmentRefs'?: {
    MapShapesList_MapShapeFragment: MapShapesList_MapShapeFragment;
  };
}) & { ' $fragmentName'?: 'SelectMapShapeFromLibraryModal_MapShapeFragment' };

export type GetMapShapesForSelectMapShapeFromLibraryModalQueryVariables =
  Exact<{
    propertyId: Scalars['ID']['input'];
  }>;

export type GetMapShapesForSelectMapShapeFromLibraryModalQuery = {
  __typename: 'Query';
  mapShapes: Array<
    { __typename: 'MapShape'; id: string } & {
      ' $fragmentRefs'?: {
        SelectMapShapeFromLibraryModal_MapShapeFragment: SelectMapShapeFromLibraryModal_MapShapeFragment;
      };
    }
  >;
};

export type AdvisorDashboardTeamsFragmentFragment = {
  __typename: 'Team';
  id: string;
  name: string;
  organisation: {
    __typename: 'Organisation';
    id: string;
    name: string;
    ownerId: string;
  };
  properties: Array<{
    __typename: 'Property';
    id: string;
    name?: string | null;
  }>;
} & { ' $fragmentName'?: 'AdvisorDashboardTeamsFragmentFragment' };

export type GetTeamsForAdvisorDashboardQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type GetTeamsForAdvisorDashboardQuery = {
  __typename: 'Query';
  advisorTeams: Array<
    { __typename: 'Team' } & {
      ' $fragmentRefs'?: {
        AdvisorDashboardTeamsFragmentFragment: AdvisorDashboardTeamsFragmentFragment;
      };
    }
  >;
};

export type CreateAdvisorInviteForUserMutationVariables = Exact<{
  createAdvisorInvitationInput: CreateAdvisorInvitationInput;
}>;

export type CreateAdvisorInviteForUserMutation = {
  __typename: 'Mutation';
  createAdvisorInvitation: { __typename: 'Invitation'; id: string };
};

export type AllPropertiesForEditPortfolioFormFragmentFragment = {
  __typename: 'Property';
  id: string;
  name?: string | null;
  centroid?: GeoJSON.Point | null;
  bbox?: (GeoJSON.BBox & [number, number, number, number]) | null;
} & { ' $fragmentName'?: 'AllPropertiesForEditPortfolioFormFragmentFragment' };

export type PortfolioEditPage_PortfolioFragmentFragment = {
  __typename: 'Portfolio';
  name: string;
  portfolioProperties: Array<{
    __typename: 'PropertyInPortfolio';
    property: {
      __typename: 'Property';
      id: string;
      name?: string | null;
      centroid?: GeoJSON.Point | null;
      bbox?: (GeoJSON.BBox & [number, number, number, number]) | null;
    };
  }>;
} & { ' $fragmentName'?: 'PortfolioEditPage_PortfolioFragmentFragment' };

export type GetPortfolioForEditQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPortfolioForEditQuery = {
  __typename: 'Query';
  portfolio: { __typename: 'Portfolio'; id: string } & {
    ' $fragmentRefs'?: {
      PortfolioEditPage_PortfolioFragmentFragment: PortfolioEditPage_PortfolioFragmentFragment;
    };
  };
};

export type GetPropertiesForAddToPortfolioQueryVariables = Exact<{
  teamId: Scalars['ID']['input'];
}>;

export type GetPropertiesForAddToPortfolioQuery = {
  __typename: 'Query';
  properties: Array<
    { __typename: 'Property'; id: string } & {
      ' $fragmentRefs'?: {
        AllPropertiesForEditPortfolioFormFragmentFragment: AllPropertiesForEditPortfolioFormFragmentFragment;
      };
    }
  >;
};

export type UpdatePortfolioMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updatePortfolioInput: UpdatePortfolioInput;
}>;

export type UpdatePortfolioMutation = {
  __typename: 'Mutation';
  updatePortfolio: { __typename: 'Portfolio'; id: string } & {
    ' $fragmentRefs'?: {
      PortfolioEditPage_PortfolioFragmentFragment: PortfolioEditPage_PortfolioFragmentFragment;
    };
  };
};

export type DeletePortfolioMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeletePortfolioMutation = {
  __typename: 'Mutation';
  deletePortfolio: { __typename: 'Portfolio'; id: string };
};

export type PortfolioPage_PortfolioFragmentFragment = {
  __typename: 'Portfolio';
  name: string;
} & { ' $fragmentName'?: 'PortfolioPage_PortfolioFragmentFragment' };

export type GetPortfolioQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPortfolioQuery = {
  __typename: 'Query';
  portfolio: { __typename: 'Portfolio'; id: string } & {
    ' $fragmentRefs'?: {
      PortfolioPage_PortfolioFragmentFragment: PortfolioPage_PortfolioFragmentFragment;
    };
  };
};

export type TeamPortfoliosFragmentFragment = {
  __typename: 'Portfolio';
  id: string;
  name: string;
  portfolioProperties: Array<{
    __typename: 'PropertyInPortfolio';
    propertyId: string;
  }>;
} & { ' $fragmentName'?: 'TeamPortfoliosFragmentFragment' };

export type CreatePortfolioMutationVariables = Exact<{
  input: CreatePortfolioInput;
}>;

export type CreatePortfolioMutation = {
  __typename: 'Mutation';
  createPortfolio: { __typename: 'Portfolio'; id: string };
};

export type GetPortfoliosForTeamQueryVariables = Exact<{
  teamId: Scalars['ID']['input'];
}>;

export type GetPortfoliosForTeamQuery = {
  __typename: 'Query';
  portfoliosForTeam: Array<
    { __typename: 'Portfolio' } & {
      ' $fragmentRefs'?: {
        TeamPortfoliosFragmentFragment: TeamPortfoliosFragmentFragment;
      };
    }
  >;
};

export type CmaAssessmentAnalysisPage_PropertyFragment = ({
  __typename: 'Property';
  salesSummary?:
    | ({ __typename: 'PropertySalesData' } & {
        ' $fragmentRefs'?: {
          PropertyPastSalesTable_PropertySalesDataFragment: PropertyPastSalesTable_PropertySalesDataFragment;
          NumberOfSalesOverTimeChart_PropertySalesDataFragment: NumberOfSalesOverTimeChart_PropertySalesDataFragment;
          PricePerHectareOverTimeChart_PropertySalesDataFragment: PricePerHectareOverTimeChart_PropertySalesDataFragment;
          RecentSalesPriceChart_PropertySalesDataFragment: RecentSalesPriceChart_PropertySalesDataFragment;
          RecentSalesPricePerAreaChart_PropertySalesDataFragment: RecentSalesPricePerAreaChart_PropertySalesDataFragment;
        };
      })
    | null;
} & {
  ' $fragmentRefs'?: {
    RecentSalesPriceChart_PropertyFragment: RecentSalesPriceChart_PropertyFragment;
    RecentSalesPricePerAreaChart_PropertyFragment: RecentSalesPricePerAreaChart_PropertyFragment;
  };
}) & { ' $fragmentName'?: 'CmaAssessmentAnalysisPage_PropertyFragment' };

export type NumberOfSalesOverTimeChart_PropertySalesDataFragment = {
  __typename: 'PropertySalesData';
  summarySaleCountHistory?: {
    __typename: 'SalesDataItem';
    result?: JSON.Value | null;
  } | null;
} & {
  ' $fragmentName'?: 'NumberOfSalesOverTimeChart_PropertySalesDataFragment';
};

export type PricePerHectareOverTimeChart_PropertySalesDataFragment = {
  __typename: 'PropertySalesData';
  summaryAveragePriceHistory?: {
    __typename: 'SalesDataItem';
    result?: JSON.Value | null;
  } | null;
} & {
  ' $fragmentName'?: 'PricePerHectareOverTimeChart_PropertySalesDataFragment';
};

export type PropertyPastSalesTable_PropertySalesDataFragment = {
  __typename: 'PropertySalesData';
  propertySalesHistory?: {
    __typename: 'SalesDataItem';
    result?: JSON.Value | null;
  } | null;
} & { ' $fragmentName'?: 'PropertyPastSalesTable_PropertySalesDataFragment' };

export type RecentSalesPriceChart_PropertySalesDataFragment = {
  __typename: 'PropertySalesData';
  summaryPriceDistribution?: {
    __typename: 'SalesDataItem';
    result?: JSON.Value | null;
  } | null;
  propertySalesHistory?: {
    __typename: 'SalesDataItem';
    result?: JSON.Value | null;
  } | null;
} & { ' $fragmentName'?: 'RecentSalesPriceChart_PropertySalesDataFragment' };

export type RecentSalesPriceChart_PropertyFragment = {
  __typename: 'Property';
  id: string;
  comparableSales: Array<{
    __typename: 'ComparableSale';
    propertyId: string;
    saleId: string;
    name?: string | null;
    similarity: Decimal;
    sale: {
      __typename: 'Sale';
      id: string;
      price?: number | null;
      contractDate?: string | null;
    };
  }>;
} & { ' $fragmentName'?: 'RecentSalesPriceChart_PropertyFragment' };

export type RecentSalesPricePerAreaChart_PropertySalesDataFragment = {
  __typename: 'PropertySalesData';
  summaryPricePerAreaDistribution?: {
    __typename: 'SalesDataItem';
    result?: JSON.Value | null;
  } | null;
  propertySalesHistory?: {
    __typename: 'SalesDataItem';
    result?: JSON.Value | null;
  } | null;
} & {
  ' $fragmentName'?: 'RecentSalesPricePerAreaChart_PropertySalesDataFragment';
};

export type RecentSalesPricePerAreaChart_PropertyFragment = {
  __typename: 'Property';
  id: string;
  comparableSales: Array<{
    __typename: 'ComparableSale';
    propertyId: string;
    saleId: string;
    name?: string | null;
    similarity: Decimal;
    sale: {
      __typename: 'Sale';
      id: string;
      price?: number | null;
      contractDate?: string | null;
    };
  }>;
} & { ' $fragmentName'?: 'RecentSalesPricePerAreaChart_PropertyFragment' };

export type GetPropertyForCmaChartsQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
}>;

export type GetPropertyForCmaChartsQuery = {
  __typename: 'Query';
  property?:
    | ({ __typename: 'Property'; id: string } & {
        ' $fragmentRefs'?: {
          CmaAssessmentAnalysisPage_PropertyFragment: CmaAssessmentAnalysisPage_PropertyFragment;
        };
      })
    | null;
};

export type PropertyComparableSaleFragmentFragment = {
  __typename: 'ComparableSale';
  propertyId: string;
  saleId: string;
  sale: {
    __typename: 'Sale';
    id: string;
    price?: number | null;
    contractDate?: string | null;
    address?: string | null;
    area?: Decimal | null;
    notes?: string | null;
    landUses: Array<{
      __typename: 'SaleLandUse';
      id: string;
      landUse: ClumLandUse;
      area: Decimal;
      pricePerHectare: Decimal;
    }>;
  };
  property: { __typename: 'Property'; id: string };
} & { ' $fragmentName'?: 'PropertyComparableSaleFragmentFragment' };

export type GetComparableSaleOnPropertyQueryVariables = Exact<{
  input: GetComparableSaleInput;
}>;

export type GetComparableSaleOnPropertyQuery = {
  __typename: 'Query';
  comparableSale?:
    | ({ __typename: 'ComparableSale' } & {
        ' $fragmentRefs'?: {
          PropertyComparableSaleFragmentFragment: PropertyComparableSaleFragmentFragment;
        };
      })
    | null;
};

export type UpdateComparableSaleMutationVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  saleId: Scalars['ID']['input'];
  input: UpdateComparableSaleInput;
}>;

export type UpdateComparableSaleMutation = {
  __typename: 'Mutation';
  updateComparableSale: { __typename: 'ComparableSale' } & {
    ' $fragmentRefs'?: {
      PropertyComparableSaleFragmentFragment: PropertyComparableSaleFragmentFragment;
    };
  };
};

export type CmaAssessmentSalesSearchResultsFragment = {
  __typename: 'ComparableSaleSearchResult';
  latitude: number;
  longitude: number;
  price: number;
  saleDate: string;
  boundingGeoJson?: JSON.Object | null;
  bbox?: (GeoJSON.BBox & [number, number, number, number]) | null;
  source: SalesDataSource;
  sourceRef: string;
  similarity: Decimal;
  area?: number | null;
  address?: { __typename: 'Address'; address?: string | null } | null;
} & { ' $fragmentName'?: 'CmaAssessmentSalesSearchResultsFragment' };

export type CmaAssessmentPropertyComparableSalesFragmentFragment = {
  __typename: 'Property';
  comparableSales: Array<{
    __typename: 'ComparableSale';
    name?: string | null;
    similarity: Decimal;
    propertyId: string;
    saleId: string;
    sale: {
      __typename: 'Sale';
      id: string;
      externalId?: string | null;
      price?: number | null;
      contractDate?: string | null;
      centroid?: GeoJSON.Point | null;
      address?: string | null;
      bbox?: (GeoJSON.BBox & [number, number, number, number]) | null;
      geog?: GeoJSON.MultiPolygon | null;
    };
    property: { __typename: 'Property'; id: string };
  }>;
} & {
  ' $fragmentName'?: 'CmaAssessmentPropertyComparableSalesFragmentFragment';
};

export type CmaAssessmentPropertyCmaVectorFragmentFragment = {
  __typename: 'Property';
  cmaVector?: Array<number | null> | null;
} & { ' $fragmentName'?: 'CmaAssessmentPropertyCmaVectorFragmentFragment' };

export type GetPropertyComparableSalesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPropertyComparableSalesQuery = {
  __typename: 'Query';
  property?:
    | ({ __typename: 'Property'; id: string } & {
        ' $fragmentRefs'?: {
          CmaAssessmentPropertyComparableSalesFragmentFragment: CmaAssessmentPropertyComparableSalesFragmentFragment;
          CmaAssessmentPropertyCmaVectorFragmentFragment: CmaAssessmentPropertyCmaVectorFragmentFragment;
        };
      })
    | null;
};

export type GetPropertyComparableSaleSearchResultsQueryVariables = Exact<{
  propertyId: Scalars['String']['input'];
  similarity: Scalars['Percent']['input'];
  radius: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  minPrice?: InputMaybe<Scalars['Int']['input']>;
  maxPrice?: InputMaybe<Scalars['Int']['input']>;
  minArea?: InputMaybe<Scalars['Int']['input']>;
  maxArea?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ComparableSaleSearchOrderBy>;
}>;

export type GetPropertyComparableSaleSearchResultsQuery = {
  __typename: 'Query';
  comparableSalesSearch: Array<
    { __typename: 'ComparableSaleSearchResult' } & {
      ' $fragmentRefs'?: {
        CmaAssessmentSalesSearchResultsFragment: CmaAssessmentSalesSearchResultsFragment;
      };
    }
  >;
};

export type SaveComparableSalesForPropertyMutationVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  input: Array<SaveComparableSaleInput> | SaveComparableSaleInput;
}>;

export type SaveComparableSalesForPropertyMutation = {
  __typename: 'Mutation';
  saveComparableSales: Array<{
    __typename: 'ComparableSale';
    propertyId: string;
    saleId: string;
    property: { __typename: 'Property'; id: string };
    sale: { __typename: 'Sale'; id: string };
  }>;
};

export type DeleteComparableSaleForPropertyMutationVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  saleId: Scalars['ID']['input'];
}>;

export type DeleteComparableSaleForPropertyMutation = {
  __typename: 'Mutation';
  deleteComparableSale?: {
    __typename: 'ComparableSale';
    propertyId: string;
    saleId: string;
    property: { __typename: 'Property'; id: string };
    sale: { __typename: 'Sale'; id: string };
  } | null;
};

export type CmaAssessmentReportsEditPage_ReportFragment = {
  __typename: 'Report';
  id: string;
  name: string;
  sections: Array<ReportSection>;
  propertyId: string;
  teamId: string;
  type: ReportType;
} & { ' $fragmentName'?: 'CmaAssessmentReportsEditPage_ReportFragment' };

export type UpdateCmaReportMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateReportInput;
}>;

export type UpdateCmaReportMutation = {
  __typename: 'Mutation';
  updateReport: { __typename: 'Report'; id: string } & {
    ' $fragmentRefs'?: {
      CmaAssessmentReportsPage_ReportFragment: CmaAssessmentReportsPage_ReportFragment;
      CmaAssessmentReportsEditPage_ReportFragment: CmaAssessmentReportsEditPage_ReportFragment;
    };
  };
};

export type DeleteCmaReportMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteCmaReportMutation = {
  __typename: 'Mutation';
  deleteReport: { __typename: 'Report'; id: string };
};

export type GetCmaReportQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetCmaReportQuery = {
  __typename: 'Query';
  report?:
    | ({ __typename: 'Report'; id: string } & {
        ' $fragmentRefs'?: {
          CmaAssessmentReportsEditPage_ReportFragment: CmaAssessmentReportsEditPage_ReportFragment;
        };
      })
    | null;
};

export type CmaAssessmentReportsPage_ReportFragment = ({
  __typename: 'Report';
  id: string;
} & {
  ' $fragmentRefs'?: {
    CmaReportListItem_ReportFragment: CmaReportListItem_ReportFragment;
  };
}) & { ' $fragmentName'?: 'CmaAssessmentReportsPage_ReportFragment' };

export type CmaReportListItem_ReportFragment = {
  __typename: 'Report';
  id: string;
  teamId: string;
  propertyId: string;
  name: string;
  createdAt: string;
  status: ReportStatus;
} & { ' $fragmentName'?: 'CmaReportListItem_ReportFragment' };

export type CmaReportListItem_ReportPdfLinkFragment = {
  __typename: 'Report';
  id: string;
  signedPdfUrl: string;
} & { ' $fragmentName'?: 'CmaReportListItem_ReportPdfLinkFragment' };

export type GetCmaReportsQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
}>;

export type GetCmaReportsQuery = {
  __typename: 'Query';
  cmaReports: Array<
    { __typename: 'Report'; id: string } & {
      ' $fragmentRefs'?: {
        CmaAssessmentReportsPage_ReportFragment: CmaAssessmentReportsPage_ReportFragment;
      };
    }
  >;
};

export type GetCmaReportSignedUrlQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetCmaReportSignedUrlQuery = {
  __typename: 'Query';
  report?:
    | ({ __typename: 'Report'; id: string } & {
        ' $fragmentRefs'?: {
          CmaReportListItem_ReportPdfLinkFragment: CmaReportListItem_ReportPdfLinkFragment;
        };
      })
    | null;
};

export type CreateCmaReportMutationVariables = Exact<{
  input: CreateReportInput;
}>;

export type CreateCmaReportMutation = {
  __typename: 'Mutation';
  createReport: { __typename: 'Report'; id: string };
};

export type DeforestationAssessmentReportsEditPage_ReportFragment = {
  __typename: 'Report';
  id: string;
  name: string;
  sections: Array<ReportSection>;
  propertyId: string;
  teamId: string;
  type: ReportType;
} & {
  ' $fragmentName'?: 'DeforestationAssessmentReportsEditPage_ReportFragment';
};

export type UpdateDeforestationReportMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateReportInput;
}>;

export type UpdateDeforestationReportMutation = {
  __typename: 'Mutation';
  updateReport: { __typename: 'Report'; id: string } & {
    ' $fragmentRefs'?: {
      DeforestationAssessmentReportsPage_ReportFragment: DeforestationAssessmentReportsPage_ReportFragment;
      DeforestationAssessmentReportsEditPage_ReportFragment: DeforestationAssessmentReportsEditPage_ReportFragment;
    };
  };
};

export type DeleteDeforestationReportMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteDeforestationReportMutation = {
  __typename: 'Mutation';
  deleteReport: { __typename: 'Report'; id: string };
};

export type GetDeforestationReportQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetDeforestationReportQuery = {
  __typename: 'Query';
  report?:
    | ({ __typename: 'Report'; id: string } & {
        ' $fragmentRefs'?: {
          DeforestationAssessmentReportsEditPage_ReportFragment: DeforestationAssessmentReportsEditPage_ReportFragment;
        };
      })
    | null;
};

export type DeforestationAssessmentReportsPage_ReportFragment = ({
  __typename: 'Report';
  id: string;
} & {
  ' $fragmentRefs'?: {
    DeforestationReportListItem_ReportFragment: DeforestationReportListItem_ReportFragment;
  };
}) & { ' $fragmentName'?: 'DeforestationAssessmentReportsPage_ReportFragment' };

export type DeforestationReportListItem_ReportFragment = {
  __typename: 'Report';
  id: string;
  teamId: string;
  propertyId: string;
  name: string;
  createdAt: string;
  status: ReportStatus;
} & { ' $fragmentName'?: 'DeforestationReportListItem_ReportFragment' };

export type DeforestationReportListItem_ReportPdfLinkFragment = {
  __typename: 'Report';
  id: string;
  signedPdfUrl: string;
} & { ' $fragmentName'?: 'DeforestationReportListItem_ReportPdfLinkFragment' };

export type GetDeforestationReportsQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
}>;

export type GetDeforestationReportsQuery = {
  __typename: 'Query';
  deforestationReports: Array<
    { __typename: 'Report'; id: string } & {
      ' $fragmentRefs'?: {
        DeforestationAssessmentReportsPage_ReportFragment: DeforestationAssessmentReportsPage_ReportFragment;
      };
    }
  >;
};

export type GetDeforestationReportSignedUrlQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetDeforestationReportSignedUrlQuery = {
  __typename: 'Query';
  report?:
    | ({ __typename: 'Report'; id: string } & {
        ' $fragmentRefs'?: {
          DeforestationReportListItem_ReportPdfLinkFragment: DeforestationReportListItem_ReportPdfLinkFragment;
        };
      })
    | null;
};

export type CreateDeforestationReportMutationVariables = Exact<{
  input: CreateReportInput;
}>;

export type CreateDeforestationReportMutation = {
  __typename: 'Mutation';
  createReport: { __typename: 'Report'; id: string };
};

export type AssessmentGhgEmissionsAllPage_AllEmissionsCalculationResultFragmentFragment =
  ({ __typename: 'AllEmissionsCalculationResult' } & {
    ' $fragmentRefs'?: {
      AllTotalGhgEmissions_AllEmissionsCalculationResultFragmentFragment: AllTotalGhgEmissions_AllEmissionsCalculationResultFragmentFragment;
      AllBreakdownGhg_AllEmissionsCalculationResultFragmentFragment: AllBreakdownGhg_AllEmissionsCalculationResultFragmentFragment;
    };
  }) & {
    ' $fragmentName'?: 'AssessmentGhgEmissionsAllPage_AllEmissionsCalculationResultFragmentFragment';
  };

export type AllBreakdownGhg_AllEmissionsCalculationResultFragmentFragment = {
  __typename: 'AllEmissionsCalculationResult';
  scope1: {
    __typename: 'AllEmissionsScope1';
    totalCO2: number;
    totalCH4: number;
    totalN2O: number;
  };
} & {
  ' $fragmentName'?: 'AllBreakdownGhg_AllEmissionsCalculationResultFragmentFragment';
};

export type AllTotalGhgEmissions_AllEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'AllEmissionsCalculationResult';
    scope1: { __typename: 'AllEmissionsScope1'; total: number };
    scope2: { __typename: 'AllEmissionsScope2'; total: number };
    scope3: { __typename: 'AllEmissionsScope3'; total: number };
    carbonSequestration: {
      __typename: 'AllEmissionsCarbonSequestration';
      total: number;
    };
    purchasedOffsets: {
      __typename: 'AllEmissionsPurchasedOffsets';
      total: number;
    };
    net: { __typename: 'AllEmissionsNet'; total: number };
  } & {
    ' $fragmentName'?: 'AllTotalGhgEmissions_AllEmissionsCalculationResultFragmentFragment';
  };

export type GetAllEmissionsQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  filter?: InputMaybe<EmissionsFilterInput>;
}>;

export type GetAllEmissionsQuery = {
  __typename: 'Query';
  allEmissions?: {
    __typename: 'AllEmissionsCalculation';
    result: { __typename: 'AllEmissionsCalculationResult' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissionsAllPage_AllEmissionsCalculationResultFragmentFragment: AssessmentGhgEmissionsAllPage_AllEmissionsCalculationResultFragmentFragment;
      };
    };
    inputs: { __typename: 'EmissionsCalculationInputs' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment: AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment;
      };
    };
  } | null;
};

export type AssessmentGhgEmissionsBeefPage_SheepBeefEmissionsCalculationResultFragmentFragment =
  ({ __typename: 'SheepBeefEmissionsCalculationResult' } & {
    ' $fragmentRefs'?: {
      BeefTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragmentFragment: BeefTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragmentFragment;
      BeefEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragmentFragment: BeefEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragmentFragment;
      BeefBreakdownGhg_SheepBeefEmissionsCalculationResultFragmentFragment: BeefBreakdownGhg_SheepBeefEmissionsCalculationResultFragmentFragment;
      BeefIntensity_SheepBeefEmissionsCalculationResultFragmentFragment: BeefIntensity_SheepBeefEmissionsCalculationResultFragmentFragment;
    };
  }) & {
    ' $fragmentName'?: 'AssessmentGhgEmissionsBeefPage_SheepBeefEmissionsCalculationResultFragmentFragment';
  };

export type BeefBreakdownGhg_SheepBeefEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'SheepBeefEmissionsCalculationResult';
    scope1?: {
      __typename: 'SheepBeefEmissionsScope1';
      totalCO2?: number | null;
      totalCH4?: number | null;
      totalN2O?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'BeefBreakdownGhg_SheepBeefEmissionsCalculationResultFragmentFragment';
  };

export type BeefEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'SheepBeefEmissionsCalculationResult';
    scope1?: {
      __typename: 'SheepBeefEmissionsScope1';
      leachingAndRunoffN2O?: number | null;
      atmosphericDepositionN2O?: number | null;
      urineAndDungN2O?: number | null;
      manureManagementCH4?: number | null;
      entericCH4?: number | null;
      fertiliserN2O?: number | null;
      limeCO2?: number | null;
      fuelCO2?: number | null;
      fuelCH4?: number | null;
      fuelN2O?: number | null;
      ureaCO2?: number | null;
      savannahBurningN2O?: number | null;
      savannahBurningCH4?: number | null;
    } | null;
    scope2?: {
      __typename: 'SheepBeefEmissionsScope2';
      electricity?: number | null;
    } | null;
    scope3?: {
      __typename: 'SheepBeefEmissionsScope3';
      fertiliser?: number | null;
      purchasedMineralSupplementation?: number | null;
      purchasedFeed?: number | null;
      herbicide?: number | null;
      electricity?: number | null;
      fuel?: number | null;
      lime?: number | null;
      purchasedLivestock?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'BeefEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragmentFragment';
  };

export type BeefIntensity_SheepBeefEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'SheepBeefEmissionsCalculationResult';
    intensities?: {
      __typename: 'SheepBeefEmissionsIntensities';
      beefIncludingSequestration?: number | null;
      beefExcludingSequestration?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'BeefIntensity_SheepBeefEmissionsCalculationResultFragmentFragment';
  };

export type BeefTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'SheepBeefEmissionsCalculationResult';
    scope1?: {
      __typename: 'SheepBeefEmissionsScope1';
      total?: number | null;
    } | null;
    scope2?: {
      __typename: 'SheepBeefEmissionsScope2';
      total?: number | null;
    } | null;
    scope3?: {
      __typename: 'SheepBeefEmissionsScope3';
      total?: number | null;
    } | null;
    carbonSequestration?: {
      __typename: 'SheepBeefEmissionsCarbonSequestration';
      total?: number | null;
      intermediate?: {
        __typename: 'SheepBeefEmissionsTotalAverage';
        total?: number | null;
        average?: number | null;
      } | null;
    } | null;
    intermediate?: {
      __typename: 'SheepBeefEmissionsIntermediate';
      beef?: {
        __typename: 'SheepBeefEmissionsIntermediateScopes';
        carbonSequestration?: number | null;
      } | null;
    } | null;
    net?: { __typename: 'SheepBeefEmissionsNet'; total?: number | null } | null;
  } & {
    ' $fragmentName'?: 'BeefTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragmentFragment';
  };

export type GetBeefEmissionsQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  filter?: InputMaybe<EmissionsFilterInput>;
}>;

export type GetBeefEmissionsQuery = {
  __typename: 'Query';
  beefEmissions: {
    __typename: 'SheepBeefEmissionsCalculation';
    result: { __typename: 'SheepBeefEmissionsCalculationResult' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissionsBeefPage_SheepBeefEmissionsCalculationResultFragmentFragment: AssessmentGhgEmissionsBeefPage_SheepBeefEmissionsCalculationResultFragmentFragment;
      };
    };
    inputs: { __typename: 'EmissionsCalculationInputs' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment: AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment;
      };
    };
  };
};

export type AssessmentGhgEmissionsBuffaloPage_BuffaloEmissionsCalculationResultFragmentFragment =
  ({ __typename: 'BuffaloEmissionsCalculationResult' } & {
    ' $fragmentRefs'?: {
      BuffaloTotalGhgEmissions_BuffaloEmissionsCalculationResultFragmentFragment: BuffaloTotalGhgEmissions_BuffaloEmissionsCalculationResultFragmentFragment;
      BuffaloEmissionsBreakdown_BuffaloEmissionsCalculationResultFragmentFragment: BuffaloEmissionsBreakdown_BuffaloEmissionsCalculationResultFragmentFragment;
      BuffaloBreakdownGhg_BuffaloEmissionsCalculationResultFragmentFragment: BuffaloBreakdownGhg_BuffaloEmissionsCalculationResultFragmentFragment;
      BuffaloIntensity_BuffaloEmissionsCalculationResultFragmentFragment: BuffaloIntensity_BuffaloEmissionsCalculationResultFragmentFragment;
    };
  }) & {
    ' $fragmentName'?: 'AssessmentGhgEmissionsBuffaloPage_BuffaloEmissionsCalculationResultFragmentFragment';
  };

export type BuffaloBreakdownGhg_BuffaloEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'BuffaloEmissionsCalculationResult';
    scope1?: {
      __typename: 'BuffaloEmissionsScope1';
      totalCO2?: number | null;
      totalCH4?: number | null;
      totalN2O?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'BuffaloBreakdownGhg_BuffaloEmissionsCalculationResultFragmentFragment';
  };

export type BuffaloEmissionsBreakdown_BuffaloEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'BuffaloEmissionsCalculationResult';
    scope1?: {
      __typename: 'BuffaloEmissionsScope1';
      fuelCO2?: number | null;
      limeCO2?: number | null;
      ureaCO2?: number | null;
      transportCO2?: number | null;
      fuelCH4?: number | null;
      transportCH4?: number | null;
      entericCH4?: number | null;
      manureManagementCH4?: number | null;
      atmosphericDepositionN2O?: number | null;
      manureManagementN2O?: number | null;
      animalWasteN2O?: number | null;
      fertiliserN2O?: number | null;
      urineAndDungN2O?: number | null;
      leachingAndRunoffN2O?: number | null;
      fuelN2O?: number | null;
      transportN2O?: number | null;
    } | null;
    scope2?: {
      __typename: 'BuffaloEmissionsScope2';
      electricity?: number | null;
    } | null;
    scope3?: {
      __typename: 'BuffaloEmissionsScope3';
      fertiliser?: number | null;
      purchasedFeed?: number | null;
      herbicide?: number | null;
      electricity?: number | null;
      fuel?: number | null;
      lime?: number | null;
      purchasedLivestock?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'BuffaloEmissionsBreakdown_BuffaloEmissionsCalculationResultFragmentFragment';
  };

export type BuffaloIntensity_BuffaloEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'BuffaloEmissionsCalculationResult';
    intensities?: {
      __typename: 'BuffaloEmissionsIntensities';
      buffaloMeatIncludingSequestration?: number | null;
      buffaloMeatExcludingSequestration?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'BuffaloIntensity_BuffaloEmissionsCalculationResultFragmentFragment';
  };

export type BuffaloTotalGhgEmissions_BuffaloEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'BuffaloEmissionsCalculationResult';
    scope1?: {
      __typename: 'BuffaloEmissionsScope1';
      total?: number | null;
    } | null;
    scope2?: {
      __typename: 'BuffaloEmissionsScope2';
      total?: number | null;
    } | null;
    scope3?: {
      __typename: 'BuffaloEmissionsScope3';
      total?: number | null;
    } | null;
    carbonSequestration?: {
      __typename: 'BuffaloEmissionsCarbonSequestration';
      total?: number | null;
      intermediate?: {
        __typename: 'BuffaloEmissionsTotalAverage';
        total?: number | null;
        average?: number | null;
      } | null;
    } | null;
    net?: { __typename: 'BuffaloEmissionsNet'; total?: number | null } | null;
  } & {
    ' $fragmentName'?: 'BuffaloTotalGhgEmissions_BuffaloEmissionsCalculationResultFragmentFragment';
  };

export type GetBuffaloEmissionsQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  filter?: InputMaybe<EmissionsFilterInput>;
}>;

export type GetBuffaloEmissionsQuery = {
  __typename: 'Query';
  buffaloEmissions?: {
    __typename: 'BuffaloEmissionsCalculation';
    result: { __typename: 'BuffaloEmissionsCalculationResult' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissionsBuffaloPage_BuffaloEmissionsCalculationResultFragmentFragment: AssessmentGhgEmissionsBuffaloPage_BuffaloEmissionsCalculationResultFragmentFragment;
      };
    };
    inputs: { __typename: 'EmissionsCalculationInputs' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment: AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment;
      };
    };
  } | null;
};

export type AssessmentGhgEmissionsCottonPage_CottonEmissionsCalculationResultFragmentFragment =
  ({ __typename: 'CottonEmissionsCalculationResult' } & {
    ' $fragmentRefs'?: {
      CottonTotalGhgEmissions_CottonEmissionsCalculationResultFragmentFragment: CottonTotalGhgEmissions_CottonEmissionsCalculationResultFragmentFragment;
      CottonEmissionsBreakdown_CottonEmissionsCalculationResultFragmentFragment: CottonEmissionsBreakdown_CottonEmissionsCalculationResultFragmentFragment;
      CottonBreakdownGhg_CottonEmissionsCalculationResultFragmentFragment: CottonBreakdownGhg_CottonEmissionsCalculationResultFragmentFragment;
      CottonIntensity_CottonEmissionsCalculationResultFragmentFragment: CottonIntensity_CottonEmissionsCalculationResultFragmentFragment;
    };
  }) & {
    ' $fragmentName'?: 'AssessmentGhgEmissionsCottonPage_CottonEmissionsCalculationResultFragmentFragment';
  };

export type CottonBreakdownGhg_CottonEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'CottonEmissionsCalculationResult';
    scope1?: {
      __typename: 'GrainsEmissionsScope1';
      totalCO2?: number | null;
      totalCH4?: number | null;
      totalN2O?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'CottonBreakdownGhg_CottonEmissionsCalculationResultFragmentFragment';
  };

export type CottonEmissionsBreakdown_CottonEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'CottonEmissionsCalculationResult';
    scope1?: {
      __typename: 'GrainsEmissionsScope1';
      atmosphericDepositionN2O?: number | null;
      cropResidueN2O?: number | null;
      fertiliserN2O?: number | null;
      fieldBurningCH4?: number | null;
      fieldBurningN2O?: number | null;
      fuelCH4?: number | null;
      fuelCO2?: number | null;
      fuelN2O?: number | null;
      leachingAndRunoffN2O?: number | null;
      limeCO2?: number | null;
      ureaCO2?: number | null;
    } | null;
    scope2?: {
      __typename: 'GrainsEmissionsScope2';
      electricity?: number | null;
    } | null;
    scope3?: {
      __typename: 'GrainsEmissionsScope3';
      fertiliser?: number | null;
      herbicide?: number | null;
      electricity?: number | null;
      fuel?: number | null;
      lime?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'CottonEmissionsBreakdown_CottonEmissionsCalculationResultFragmentFragment';
  };

export type CottonIntensity_CottonEmissionsCalculationResultFragmentFragment = {
  __typename: 'CottonEmissionsCalculationResult';
  intensities: Array<{
    __typename: 'CottonEmissionsIntensity';
    balesExcludingSequestration?: number | null;
    balesIncludingSequestration?: number | null;
    tonnesCropExcludingSequestration?: number | null;
    tonnesCropIncludingSequestration?: number | null;
    lintIncludingSequestration?: number | null;
    lintExcludingSequestration?: number | null;
    seedIncludingSequestration?: number | null;
    seedExcludingSequestration?: number | null;
    lintEconomicAllocation?: number | null;
    seedEconomicAllocation?: number | null;
  }>;
} & {
  ' $fragmentName'?: 'CottonIntensity_CottonEmissionsCalculationResultFragmentFragment';
};

export type CottonTotalGhgEmissions_CottonEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'CottonEmissionsCalculationResult';
    scope1?: {
      __typename: 'GrainsEmissionsScope1';
      total?: number | null;
    } | null;
    scope2?: {
      __typename: 'GrainsEmissionsScope2';
      total?: number | null;
    } | null;
    scope3?: {
      __typename: 'GrainsEmissionsScope3';
      total?: number | null;
    } | null;
    carbonSequestration?: {
      __typename: 'GrainsEmissionsCarbonSequestration';
      total?: number | null;
    } | null;
    net?: { __typename: 'GrainsEmissionsNet'; total?: number | null } | null;
  } & {
    ' $fragmentName'?: 'CottonTotalGhgEmissions_CottonEmissionsCalculationResultFragmentFragment';
  };

export type GetCottonEmissionsQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  filter?: InputMaybe<EmissionsFilterInput>;
}>;

export type GetCottonEmissionsQuery = {
  __typename: 'Query';
  cottonEmissions?: {
    __typename: 'CottonEmissionsCalculation';
    result: { __typename: 'CottonEmissionsCalculationResult' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissionsCottonPage_CottonEmissionsCalculationResultFragmentFragment: AssessmentGhgEmissionsCottonPage_CottonEmissionsCalculationResultFragmentFragment;
      };
    };
    inputs: { __typename: 'EmissionsCalculationInputs' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment: AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment;
      };
    };
  } | null;
};

export type AssessmentGhgEmissionsDairyPage_DairyEmissionsCalculationResultFragmentFragment =
  ({ __typename: 'DairyEmissionsCalculationResult' } & {
    ' $fragmentRefs'?: {
      DairyTotalGhgEmissions_DairyEmissionsCalculationResultFragmentFragment: DairyTotalGhgEmissions_DairyEmissionsCalculationResultFragmentFragment;
      DairyEmissionsBreakdown_DairyEmissionsCalculationResultFragmentFragment: DairyEmissionsBreakdown_DairyEmissionsCalculationResultFragmentFragment;
      DairyBreakdownGhg_DairyEmissionsCalculationResultFragmentFragment: DairyBreakdownGhg_DairyEmissionsCalculationResultFragmentFragment;
      DairyIntensity_DairyEmissionsCalculationResultFragmentFragment: DairyIntensity_DairyEmissionsCalculationResultFragmentFragment;
    };
  }) & {
    ' $fragmentName'?: 'AssessmentGhgEmissionsDairyPage_DairyEmissionsCalculationResultFragmentFragment';
  };

export type DairyBreakdownGhg_DairyEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'DairyEmissionsCalculationResult';
    scope1?: {
      __typename: 'DairyEmissionsScope1';
      totalCO2?: number | null;
      totalCH4?: number | null;
      totalN2O?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'DairyBreakdownGhg_DairyEmissionsCalculationResultFragmentFragment';
  };

export type DairyEmissionsBreakdown_DairyEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'DairyEmissionsCalculationResult';
    scope1?: {
      __typename: 'DairyEmissionsScope1';
      fuelCO2?: number | null;
      limeCO2?: number | null;
      ureaCO2?: number | null;
      transportCO2?: number | null;
      fuelCH4?: number | null;
      transportCH4?: number | null;
      entericCH4?: number | null;
      manureManagementCH4?: number | null;
      atmosphericDepositionN2O?: number | null;
      manureManagementN2O?: number | null;
      animalWasteN2O?: number | null;
      fertiliserN2O?: number | null;
      urineAndDungN2O?: number | null;
      leachingAndRunoffN2O?: number | null;
      fuelN2O?: number | null;
      transportN2O?: number | null;
    } | null;
    scope2?: {
      __typename: 'DairyEmissionsScope2';
      electricity?: number | null;
    } | null;
    scope3?: {
      __typename: 'DairyEmissionsScope3';
      fertiliser?: number | null;
      purchasedFeed?: number | null;
      herbicide?: number | null;
      electricity?: number | null;
      fuel?: number | null;
      lime?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'DairyEmissionsBreakdown_DairyEmissionsCalculationResultFragmentFragment';
  };

export type DairyIntensity_DairyEmissionsCalculationResultFragmentFragment = {
  __typename: 'DairyEmissionsCalculationResult';
  intensities?: {
    __typename: 'DairyEmissionsIntensities';
    intensity?: number | null;
  } | null;
} & {
  ' $fragmentName'?: 'DairyIntensity_DairyEmissionsCalculationResultFragmentFragment';
};

export type DairyTotalGhgEmissions_DairyEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'DairyEmissionsCalculationResult';
    scope1?: {
      __typename: 'DairyEmissionsScope1';
      total?: number | null;
    } | null;
    scope2?: {
      __typename: 'DairyEmissionsScope2';
      total?: number | null;
    } | null;
    scope3?: {
      __typename: 'DairyEmissionsScope3';
      total?: number | null;
    } | null;
    carbonSequestration?: {
      __typename: 'DairyEmissionsCarbonSequestration';
      total?: number | null;
      intermediate?: {
        __typename: 'DairyEmissionsTotalAverage';
        total?: number | null;
        average?: number | null;
      } | null;
    } | null;
    net?: { __typename: 'DairyEmissionsNet'; total?: number | null } | null;
  } & {
    ' $fragmentName'?: 'DairyTotalGhgEmissions_DairyEmissionsCalculationResultFragmentFragment';
  };

export type GetDairyEmissionsQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  filter?: InputMaybe<EmissionsFilterInput>;
}>;

export type GetDairyEmissionsQuery = {
  __typename: 'Query';
  dairyEmissions?: {
    __typename: 'DairyEmissionsCalculation';
    result: { __typename: 'DairyEmissionsCalculationResult' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissionsDairyPage_DairyEmissionsCalculationResultFragmentFragment: AssessmentGhgEmissionsDairyPage_DairyEmissionsCalculationResultFragmentFragment;
      };
    };
    inputs: { __typename: 'EmissionsCalculationInputs' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment: AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment;
      };
    };
  } | null;
};

export type AssessmentGhgEmissionsDeerPage_DeerEmissionsCalculationResultFragmentFragment =
  ({ __typename: 'DeerEmissionsCalculationResult' } & {
    ' $fragmentRefs'?: {
      DeerTotalGhgEmissions_DeerEmissionsCalculationResultFragmentFragment: DeerTotalGhgEmissions_DeerEmissionsCalculationResultFragmentFragment;
      DeerEmissionsBreakdown_DeerEmissionsCalculationResultFragmentFragment: DeerEmissionsBreakdown_DeerEmissionsCalculationResultFragmentFragment;
      DeerBreakdownGhg_DeerEmissionsCalculationResultFragmentFragment: DeerBreakdownGhg_DeerEmissionsCalculationResultFragmentFragment;
      DeerIntensity_DeerEmissionsCalculationResultFragmentFragment: DeerIntensity_DeerEmissionsCalculationResultFragmentFragment;
    };
  }) & {
    ' $fragmentName'?: 'AssessmentGhgEmissionsDeerPage_DeerEmissionsCalculationResultFragmentFragment';
  };

export type DeerBreakdownGhg_DeerEmissionsCalculationResultFragmentFragment = {
  __typename: 'DeerEmissionsCalculationResult';
  scope1?: {
    __typename: 'DeerEmissionsScope1';
    totalCO2?: number | null;
    totalCH4?: number | null;
    totalN2O?: number | null;
  } | null;
} & {
  ' $fragmentName'?: 'DeerBreakdownGhg_DeerEmissionsCalculationResultFragmentFragment';
};

export type DeerEmissionsBreakdown_DeerEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'DeerEmissionsCalculationResult';
    scope1?: {
      __typename: 'DeerEmissionsScope1';
      fuelCO2?: number | null;
      limeCO2?: number | null;
      ureaCO2?: number | null;
      transportCO2?: number | null;
      fuelCH4?: number | null;
      transportCH4?: number | null;
      entericCH4?: number | null;
      manureManagementCH4?: number | null;
      atmosphericDepositionN2O?: number | null;
      manureManagementN2O?: number | null;
      animalWasteN2O?: number | null;
      fertiliserN2O?: number | null;
      urineAndDungN2O?: number | null;
      leachingAndRunoffN2O?: number | null;
      fuelN2O?: number | null;
      transportN2O?: number | null;
    } | null;
    scope2?: {
      __typename: 'DeerEmissionsScope2';
      electricity?: number | null;
    } | null;
    scope3?: {
      __typename: 'DeerEmissionsScope3';
      fertiliser?: number | null;
      purchasedFeed?: number | null;
      herbicide?: number | null;
      electricity?: number | null;
      fuel?: number | null;
      lime?: number | null;
      purchasedLivestock?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'DeerEmissionsBreakdown_DeerEmissionsCalculationResultFragmentFragment';
  };

export type DeerIntensity_DeerEmissionsCalculationResultFragmentFragment = {
  __typename: 'DeerEmissionsCalculationResult';
  intensities?: {
    __typename: 'DeerEmissionsIntensities';
    deerMeatIncludingSequestration?: number | null;
    deerMeatExcludingSequestration?: number | null;
  } | null;
} & {
  ' $fragmentName'?: 'DeerIntensity_DeerEmissionsCalculationResultFragmentFragment';
};

export type DeerTotalGhgEmissions_DeerEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'DeerEmissionsCalculationResult';
    scope1?: {
      __typename: 'DeerEmissionsScope1';
      total?: number | null;
    } | null;
    scope2?: {
      __typename: 'DeerEmissionsScope2';
      total?: number | null;
    } | null;
    scope3?: {
      __typename: 'DeerEmissionsScope3';
      total?: number | null;
    } | null;
    carbonSequestration?: {
      __typename: 'DeerEmissionsCarbonSequestration';
      total?: number | null;
      intermediate?: {
        __typename: 'DeerEmissionsTotalAverage';
        total?: number | null;
        average?: number | null;
      } | null;
    } | null;
    net?: { __typename: 'DeerEmissionsNet'; total?: number | null } | null;
  } & {
    ' $fragmentName'?: 'DeerTotalGhgEmissions_DeerEmissionsCalculationResultFragmentFragment';
  };

export type GetDeerEmissionsQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  filter?: InputMaybe<EmissionsFilterInput>;
}>;

export type GetDeerEmissionsQuery = {
  __typename: 'Query';
  deerEmissions?: {
    __typename: 'DeerEmissionsCalculation';
    result: { __typename: 'DeerEmissionsCalculationResult' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissionsDeerPage_DeerEmissionsCalculationResultFragmentFragment: AssessmentGhgEmissionsDeerPage_DeerEmissionsCalculationResultFragmentFragment;
      };
    };
    inputs: { __typename: 'EmissionsCalculationInputs' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment: AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment;
      };
    };
  } | null;
};

export type AssessmentGhgEmissionsFeedlotPage_FeedlotEmissionsCalculationResultFragmentFragment =
  ({ __typename: 'FeedlotEmissionsCalculationResult' } & {
    ' $fragmentRefs'?: {
      FeedlotTotalGhgEmissions_FeedlotEmissionsCalculationResultFragmentFragment: FeedlotTotalGhgEmissions_FeedlotEmissionsCalculationResultFragmentFragment;
      FeedlotEmissionsBreakdown_FeedlotEmissionsCalculationResultFragmentFragment: FeedlotEmissionsBreakdown_FeedlotEmissionsCalculationResultFragmentFragment;
      FeedlotBreakdownGhg_FeedlotEmissionsCalculationResultFragmentFragment: FeedlotBreakdownGhg_FeedlotEmissionsCalculationResultFragmentFragment;
      FeedlotIntensity_FeedlotEmissionsCalculationResultFragmentFragment: FeedlotIntensity_FeedlotEmissionsCalculationResultFragmentFragment;
    };
  }) & {
    ' $fragmentName'?: 'AssessmentGhgEmissionsFeedlotPage_FeedlotEmissionsCalculationResultFragmentFragment';
  };

export type FeedlotBreakdownGhg_FeedlotEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'FeedlotEmissionsCalculationResult';
    scope1: {
      __typename: 'FeedlotEmissionsScope1';
      totalCO2: number;
      totalCH4: number;
      totalN2O: number;
    };
  } & {
    ' $fragmentName'?: 'FeedlotBreakdownGhg_FeedlotEmissionsCalculationResultFragmentFragment';
  };

export type FeedlotEmissionsBreakdown_FeedlotEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'FeedlotEmissionsCalculationResult';
    scope1: {
      __typename: 'FeedlotEmissionsScope1';
      atmosphericDepositionN2O: number;
      entericCH4: number;
      fuelCH4: number;
      fuelCO2: number;
      fuelN2O: number;
      limeCO2: number;
      manureAppliedToSoilN2O: number;
      manureDirectN2O: number;
      manureIndirectN2O: number;
      manureManagementCH4: number;
      transportCH4: number;
      transportCO2: number;
      transportN2O: number;
      ureaCO2: number;
    };
    scope2: { __typename: 'FeedlotEmissionsScope2'; electricity: number };
    scope3: {
      __typename: 'FeedlotEmissionsScope3';
      fertiliser: number;
      herbicide: number;
      electricity: number;
      fuel: number;
      lime: number;
      feed: number;
      purchaseLivestock: number;
    };
  } & {
    ' $fragmentName'?: 'FeedlotEmissionsBreakdown_FeedlotEmissionsCalculationResultFragmentFragment';
  };

export type FeedlotIntensity_FeedlotEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'FeedlotEmissionsCalculationResult';
    intensities: {
      __typename: 'FeedlotEmissionsIntensities';
      beefIncludingSequestration: number;
      beefExcludingSequestration: number;
    };
  } & {
    ' $fragmentName'?: 'FeedlotIntensity_FeedlotEmissionsCalculationResultFragmentFragment';
  };

export type FeedlotTotalGhgEmissions_FeedlotEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'FeedlotEmissionsCalculationResult';
    scope1: { __typename: 'FeedlotEmissionsScope1'; total: number };
    scope2: { __typename: 'FeedlotEmissionsScope2'; total: number };
    scope3: { __typename: 'FeedlotEmissionsScope3'; total: number };
    intermediate: Array<{
      __typename: 'FeedlotEmissionsIntermediateScopes';
      carbonSequestration: number;
    }>;
    carbonSequestration: {
      __typename: 'FeedlotEmissionsCarbonSequestration';
      total: number;
    };
    net: { __typename: 'FeedlotEmissionsNet'; total: number };
  } & {
    ' $fragmentName'?: 'FeedlotTotalGhgEmissions_FeedlotEmissionsCalculationResultFragmentFragment';
  };

export type GetFeedlotEmissionsQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  filter?: InputMaybe<EmissionsFilterInput>;
}>;

export type GetFeedlotEmissionsQuery = {
  __typename: 'Query';
  feedlotEmissions?: {
    __typename: 'FeedlotEmissionsCalculation';
    result: { __typename: 'FeedlotEmissionsCalculationResult' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissionsFeedlotPage_FeedlotEmissionsCalculationResultFragmentFragment: AssessmentGhgEmissionsFeedlotPage_FeedlotEmissionsCalculationResultFragmentFragment;
      };
    };
    inputs: { __typename: 'EmissionsCalculationInputs' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment: AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment;
      };
    };
  } | null;
};

export type AssessmentGhgEmissionsGoatPage_GoatEmissionsCalculationResultFragmentFragment =
  ({ __typename: 'GoatEmissionsCalculationResult' } & {
    ' $fragmentRefs'?: {
      GoatTotalGhgEmissions_GoatEmissionsCalculationResultFragmentFragment: GoatTotalGhgEmissions_GoatEmissionsCalculationResultFragmentFragment;
      GoatEmissionsBreakdown_GoatEmissionsCalculationResultFragmentFragment: GoatEmissionsBreakdown_GoatEmissionsCalculationResultFragmentFragment;
      GoatBreakdownGhg_GoatEmissionsCalculationResultFragmentFragment: GoatBreakdownGhg_GoatEmissionsCalculationResultFragmentFragment;
      GoatIntensity_GoatEmissionsCalculationResultFragmentFragment: GoatIntensity_GoatEmissionsCalculationResultFragmentFragment;
    };
  }) & {
    ' $fragmentName'?: 'AssessmentGhgEmissionsGoatPage_GoatEmissionsCalculationResultFragmentFragment';
  };

export type GoatBreakdownGhg_GoatEmissionsCalculationResultFragmentFragment = {
  __typename: 'GoatEmissionsCalculationResult';
  scope1?: {
    __typename: 'GoatEmissionsScope1';
    totalCO2?: number | null;
    totalCH4?: number | null;
    totalN2O?: number | null;
  } | null;
} & {
  ' $fragmentName'?: 'GoatBreakdownGhg_GoatEmissionsCalculationResultFragmentFragment';
};

export type GoatEmissionsBreakdown_GoatEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'GoatEmissionsCalculationResult';
    scope1?: {
      __typename: 'GoatEmissionsScope1';
      fuelCO2?: number | null;
      fuelCH4?: number | null;
      fuelN2O?: number | null;
      ureaCO2?: number | null;
      limeCO2?: number | null;
      fertiliserN2O?: number | null;
      entericCH4?: number | null;
      manureManagementCH4?: number | null;
      urineAndDungN2O?: number | null;
      atmosphericDepositionN2O?: number | null;
      leachingAndRunoffN2O?: number | null;
    } | null;
    scope2?: {
      __typename: 'GoatEmissionsScope2';
      electricity?: number | null;
    } | null;
    scope3?: {
      __typename: 'GoatEmissionsScope3';
      fertiliser?: number | null;
      purchasedMineralSupplementation?: number | null;
      purchasedFeed?: number | null;
      herbicide?: number | null;
      electricity?: number | null;
      fuel?: number | null;
      lime?: number | null;
      purchasedLivestock?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'GoatEmissionsBreakdown_GoatEmissionsCalculationResultFragmentFragment';
  };

export type GoatIntensity_GoatEmissionsCalculationResultFragmentFragment = {
  __typename: 'GoatEmissionsCalculationResult';
  intensities?: {
    __typename: 'GoatEmissionsIntensities';
    goatMeatBreedingIncludingSequestration?: number | null;
    goatMeatBreedingExcludingSequestration?: number | null;
    woolIncludingSequestration?: number | null;
    woolExcludingSequestration?: number | null;
  } | null;
} & {
  ' $fragmentName'?: 'GoatIntensity_GoatEmissionsCalculationResultFragmentFragment';
};

export type GoatTotalGhgEmissions_GoatEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'GoatEmissionsCalculationResult';
    scope1?: {
      __typename: 'GoatEmissionsScope1';
      total?: number | null;
    } | null;
    scope2?: {
      __typename: 'GoatEmissionsScope2';
      total?: number | null;
    } | null;
    scope3?: {
      __typename: 'GoatEmissionsScope3';
      total?: number | null;
    } | null;
    carbonSequestration?: {
      __typename: 'GoatEmissionsCarbonSequestration';
      total?: number | null;
      intermediate?: {
        __typename: 'GoatEmissionsTotalAverage';
        total?: number | null;
        average?: number | null;
      } | null;
    } | null;
    net?: { __typename: 'GoatEmissionsNet'; total?: number | null } | null;
  } & {
    ' $fragmentName'?: 'GoatTotalGhgEmissions_GoatEmissionsCalculationResultFragmentFragment';
  };

export type GetGoatEmissionsQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  filter?: InputMaybe<EmissionsFilterInput>;
}>;

export type GetGoatEmissionsQuery = {
  __typename: 'Query';
  goatEmissions?: {
    __typename: 'GoatEmissionsCalculation';
    result: { __typename: 'GoatEmissionsCalculationResult' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissionsGoatPage_GoatEmissionsCalculationResultFragmentFragment: AssessmentGhgEmissionsGoatPage_GoatEmissionsCalculationResultFragmentFragment;
      };
    };
    inputs: { __typename: 'EmissionsCalculationInputs' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment: AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment;
      };
    };
  } | null;
};

export type AssessmentGhgEmissionsGrainsPage_GrainsEmissionsCalculationResultFragmentFragment =
  ({ __typename: 'GrainsEmissionsCalculationResult' } & {
    ' $fragmentRefs'?: {
      GrainsTotalGhgEmissions_GrainsEmissionsCalculationResultFragmentFragment: GrainsTotalGhgEmissions_GrainsEmissionsCalculationResultFragmentFragment;
      GrainsEmissionsBreakdown_GrainsEmissionsCalculationResultFragmentFragment: GrainsEmissionsBreakdown_GrainsEmissionsCalculationResultFragmentFragment;
      GrainsBreakdownGhg_GrainsEmissionsCalculationResultFragmentFragment: GrainsBreakdownGhg_GrainsEmissionsCalculationResultFragmentFragment;
      GrainsIntensity_GrainsEmissionsCalculationResultFragmentFragment: GrainsIntensity_GrainsEmissionsCalculationResultFragmentFragment;
    };
  }) & {
    ' $fragmentName'?: 'AssessmentGhgEmissionsGrainsPage_GrainsEmissionsCalculationResultFragmentFragment';
  };

export type GrainsBreakdownGhg_GrainsEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'GrainsEmissionsCalculationResult';
    scope1?: {
      __typename: 'GrainsEmissionsScope1';
      totalCO2?: number | null;
      totalCH4?: number | null;
      totalN2O?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'GrainsBreakdownGhg_GrainsEmissionsCalculationResultFragmentFragment';
  };

export type GrainsEmissionsBreakdown_GrainsEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'GrainsEmissionsCalculationResult';
    scope1?: {
      __typename: 'GrainsEmissionsScope1';
      atmosphericDepositionN2O?: number | null;
      cropResidueN2O?: number | null;
      fertiliserN2O?: number | null;
      fieldBurningCH4?: number | null;
      fieldBurningN2O?: number | null;
      fuelCH4?: number | null;
      fuelCO2?: number | null;
      fuelN2O?: number | null;
      leachingAndRunoffN2O?: number | null;
      limeCO2?: number | null;
      ureaCO2?: number | null;
    } | null;
    scope2?: {
      __typename: 'GrainsEmissionsScope2';
      electricity?: number | null;
    } | null;
    scope3?: {
      __typename: 'GrainsEmissionsScope3';
      fertiliser?: number | null;
      herbicide?: number | null;
      electricity?: number | null;
      fuel?: number | null;
      lime?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'GrainsEmissionsBreakdown_GrainsEmissionsCalculationResultFragmentFragment';
  };

export type GrainsIntensity_GrainsEmissionsCalculationResultFragmentFragment = {
  __typename: 'GrainsEmissionsCalculationResult';
  intensitiesWithSequestration?: Array<{
    __typename: 'GrainsEmissionsIntensities';
    grainsIncludingSequestration?: number | null;
    grainsExcludingSequestration?: number | null;
  } | null> | null;
} & {
  ' $fragmentName'?: 'GrainsIntensity_GrainsEmissionsCalculationResultFragmentFragment';
};

export type GrainsTotalGhgEmissions_GrainsEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'GrainsEmissionsCalculationResult';
    scope1?: {
      __typename: 'GrainsEmissionsScope1';
      total?: number | null;
    } | null;
    scope2?: {
      __typename: 'GrainsEmissionsScope2';
      total?: number | null;
    } | null;
    scope3?: {
      __typename: 'GrainsEmissionsScope3';
      total?: number | null;
    } | null;
    carbonSequestration?: {
      __typename: 'GrainsEmissionsCarbonSequestration';
      total?: number | null;
    } | null;
    net?: { __typename: 'GrainsEmissionsNet'; total?: number | null } | null;
  } & {
    ' $fragmentName'?: 'GrainsTotalGhgEmissions_GrainsEmissionsCalculationResultFragmentFragment';
  };

export type GetGrainsEmissionsQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  filter?: InputMaybe<EmissionsFilterInput>;
}>;

export type GetGrainsEmissionsQuery = {
  __typename: 'Query';
  grainsEmissions?: {
    __typename: 'GrainsEmissionsCalculation';
    result: { __typename: 'GrainsEmissionsCalculationResult' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissionsGrainsPage_GrainsEmissionsCalculationResultFragmentFragment: AssessmentGhgEmissionsGrainsPage_GrainsEmissionsCalculationResultFragmentFragment;
      };
    };
    inputs: { __typename: 'EmissionsCalculationInputs' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment: AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment;
      };
    };
  } | null;
};

export type AssessmentGhgEmissionsHorticulturePage_HorticultureEmissionsCalculationResultFragmentFragment =
  ({ __typename: 'HorticultureEmissionsCalculationResult' } & {
    ' $fragmentRefs'?: {
      HorticultureTotalGhgEmissions_HorticultureEmissionsCalculationResultFragmentFragment: HorticultureTotalGhgEmissions_HorticultureEmissionsCalculationResultFragmentFragment;
      HorticultureEmissionsBreakdown_HorticultureEmissionsCalculationResultFragmentFragment: HorticultureEmissionsBreakdown_HorticultureEmissionsCalculationResultFragmentFragment;
      HorticultureBreakdownGhg_HorticultureEmissionsCalculationResultFragmentFragment: HorticultureBreakdownGhg_HorticultureEmissionsCalculationResultFragmentFragment;
      HorticultureIntensity_HorticultureEmissionsCalculationResultFragmentFragment: HorticultureIntensity_HorticultureEmissionsCalculationResultFragmentFragment;
    };
  }) & {
    ' $fragmentName'?: 'AssessmentGhgEmissionsHorticulturePage_HorticultureEmissionsCalculationResultFragmentFragment';
  };

export type HorticultureBreakdownGhg_HorticultureEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'HorticultureEmissionsCalculationResult';
    scope1?: {
      __typename: 'HorticultureEmissionsScope1';
      totalCO2?: number | null;
      totalCH4?: number | null;
      totalN2O?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'HorticultureBreakdownGhg_HorticultureEmissionsCalculationResultFragmentFragment';
  };

export type HorticultureEmissionsBreakdown_HorticultureEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'HorticultureEmissionsCalculationResult';
    scope1?: {
      __typename: 'HorticultureEmissionsScope1';
      atmosphericDepositionN2O?: number | null;
      cropResidueN2O?: number | null;
      fertiliserN2O?: number | null;
      fieldBurningCH4?: number | null;
      fieldBurningN2O?: number | null;
      fuelCH4?: number | null;
      fuelCO2?: number | null;
      fuelN2O?: number | null;
      leachingAndRunoffN2O?: number | null;
      limeCO2?: number | null;
    } | null;
    scope2?: {
      __typename: 'GrainsEmissionsScope2';
      electricity?: number | null;
    } | null;
    scope3?: {
      __typename: 'GrainsEmissionsScope3';
      fertiliser?: number | null;
      herbicide?: number | null;
      electricity?: number | null;
      fuel?: number | null;
      lime?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'HorticultureEmissionsBreakdown_HorticultureEmissionsCalculationResultFragmentFragment';
  };

export type HorticultureIntensity_HorticultureEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'HorticultureEmissionsCalculationResult';
    intensities: Array<{
      __typename: 'HorticultureEmissionsIntensity';
      tonnesCropIncludingSequestration: number;
      tonnesCropExcludingSequestration: number;
    }>;
  } & {
    ' $fragmentName'?: 'HorticultureIntensity_HorticultureEmissionsCalculationResultFragmentFragment';
  };

export type HorticultureTotalGhgEmissions_HorticultureEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'HorticultureEmissionsCalculationResult';
    scope1?: {
      __typename: 'HorticultureEmissionsScope1';
      total?: number | null;
    } | null;
    scope2?: {
      __typename: 'GrainsEmissionsScope2';
      total?: number | null;
    } | null;
    scope3?: {
      __typename: 'GrainsEmissionsScope3';
      total?: number | null;
    } | null;
    carbonSequestration?: {
      __typename: 'GrainsEmissionsCarbonSequestration';
      total?: number | null;
      intermediate?: Array<{
        __typename: 'GrainsEmissionsTotalAverage';
        total?: number | null;
        average?: number | null;
      } | null> | null;
    } | null;
    net?: { __typename: 'GrainsEmissionsNet'; total?: number | null } | null;
  } & {
    ' $fragmentName'?: 'HorticultureTotalGhgEmissions_HorticultureEmissionsCalculationResultFragmentFragment';
  };

export type GetHorticultureEmissionsQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  filter?: InputMaybe<EmissionsFilterInput>;
}>;

export type GetHorticultureEmissionsQuery = {
  __typename: 'Query';
  horticultureEmissions?: {
    __typename: 'HorticultureEmissionsCalculation';
    result: { __typename: 'HorticultureEmissionsCalculationResult' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissionsHorticulturePage_HorticultureEmissionsCalculationResultFragmentFragment: AssessmentGhgEmissionsHorticulturePage_HorticultureEmissionsCalculationResultFragmentFragment;
      };
    };
    inputs: { __typename: 'EmissionsCalculationInputs' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment: AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment;
      };
    };
  } | null;
};

export type AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment =
  {
    __typename: 'EmissionsCalculationInputs';
    requests: Array<{
      __typename: 'CalculationRequest';
      endpoint: string;
      payload: JSON.Object;
    }>;
    primaryActivities: Array<{
      __typename: 'InputActivityWithCropping';
      id: string;
      name: string;
      start: string;
      end: string;
      activityType: ActivityType;
      cropping?: {
        __typename: 'InputCropping';
        croppingType: CroppingType;
      } | null;
    }>;
  } & {
    ' $fragmentName'?: 'AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment';
  };

export type EmissionsAssessmentLayout_PropertyFragmentFragment = {
  __typename: 'Property';
  name?: string | null;
} & { ' $fragmentName'?: 'EmissionsAssessmentLayout_PropertyFragmentFragment' };

export type GetPropertyForEmissionsAssessmentLayoutQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
}>;

export type GetPropertyForEmissionsAssessmentLayoutQuery = {
  __typename: 'Query';
  property?:
    | ({ __typename: 'Property'; id: string } & {
        ' $fragmentRefs'?: {
          EmissionsAssessmentLayout_PropertyFragmentFragment: EmissionsAssessmentLayout_PropertyFragmentFragment;
        };
      })
    | null;
};

export type AssessmentGhgEmissionsPorkPage_PorkEmissionsCalculationResultFragmentFragment =
  ({ __typename: 'PorkEmissionsCalculationResult' } & {
    ' $fragmentRefs'?: {
      PorkTotalGhgEmissions_PorkEmissionsCalculationResultFragmentFragment: PorkTotalGhgEmissions_PorkEmissionsCalculationResultFragmentFragment;
      PorkEmissionsBreakdown_PorkEmissionsCalculationResultFragmentFragment: PorkEmissionsBreakdown_PorkEmissionsCalculationResultFragmentFragment;
      PorkBreakdownGhg_PorkEmissionsCalculationResultFragmentFragment: PorkBreakdownGhg_PorkEmissionsCalculationResultFragmentFragment;
      PorkIntensity_PorkEmissionsCalculationResultFragmentFragment: PorkIntensity_PorkEmissionsCalculationResultFragmentFragment;
    };
  }) & {
    ' $fragmentName'?: 'AssessmentGhgEmissionsPorkPage_PorkEmissionsCalculationResultFragmentFragment';
  };

export type PorkBreakdownGhg_PorkEmissionsCalculationResultFragmentFragment = {
  __typename: 'PorkEmissionsCalculationResult';
  scope1?: {
    __typename: 'PorkEmissionsScope1';
    totalCO2?: number | null;
    totalCH4?: number | null;
    totalN2O?: number | null;
  } | null;
} & {
  ' $fragmentName'?: 'PorkBreakdownGhg_PorkEmissionsCalculationResultFragmentFragment';
};

export type PorkEmissionsBreakdown_PorkEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'PorkEmissionsCalculationResult';
    scope1?: {
      __typename: 'PorkEmissionsScope1';
      fuelCO2?: number | null;
      fuelCH4?: number | null;
      fuelN2O?: number | null;
      ureaCO2?: number | null;
      limeCO2?: number | null;
      fertiliserN2O?: number | null;
      entericCH4?: number | null;
      manureManagementCH4?: number | null;
      manureManagementDirectN2O?: number | null;
      atmosphericDepositionN2O?: number | null;
      atmosphericDepositionIndirectN2O?: number | null;
      leachingAndRunoffSoilN2O?: number | null;
      leachingAndRunoffMMSN2O?: number | null;
    } | null;
    scope2?: {
      __typename: 'PorkEmissionsScope2';
      electricity?: number | null;
    } | null;
    scope3?: {
      __typename: 'PorkEmissionsScope3';
      fertiliser?: number | null;
      purchasedFeed?: number | null;
      herbicide?: number | null;
      electricity?: number | null;
      fuel?: number | null;
      lime?: number | null;
      bedding?: number | null;
      purchasedLivestock?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'PorkEmissionsBreakdown_PorkEmissionsCalculationResultFragmentFragment';
  };

export type PorkIntensity_PorkEmissionsCalculationResultFragmentFragment = {
  __typename: 'PorkEmissionsCalculationResult';
  intensities?: {
    __typename: 'PorkEmissionsIntensities';
    porkMeatIncludingSequestration?: number | null;
    porkMeatExcludingSequestration?: number | null;
  } | null;
} & {
  ' $fragmentName'?: 'PorkIntensity_PorkEmissionsCalculationResultFragmentFragment';
};

export type PorkTotalGhgEmissions_PorkEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'PorkEmissionsCalculationResult';
    scope1?: {
      __typename: 'PorkEmissionsScope1';
      total?: number | null;
    } | null;
    scope2?: {
      __typename: 'PorkEmissionsScope2';
      total?: number | null;
    } | null;
    scope3?: {
      __typename: 'PorkEmissionsScope3';
      total?: number | null;
    } | null;
    carbonSequestration?: {
      __typename: 'PorkEmissionsCarbonSequestration';
      total?: number | null;
      intermediate?: {
        __typename: 'PorkEmissionsTotalAverage';
        total?: number | null;
        average?: number | null;
      } | null;
    } | null;
    net?: { __typename: 'PorkEmissionsNet'; total?: number | null } | null;
  } & {
    ' $fragmentName'?: 'PorkTotalGhgEmissions_PorkEmissionsCalculationResultFragmentFragment';
  };

export type GetPorkEmissionsQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  filter?: InputMaybe<EmissionsFilterInput>;
}>;

export type GetPorkEmissionsQuery = {
  __typename: 'Query';
  porkEmissions?: {
    __typename: 'PorkEmissionsCalculation';
    result: { __typename: 'PorkEmissionsCalculationResult' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissionsPorkPage_PorkEmissionsCalculationResultFragmentFragment: AssessmentGhgEmissionsPorkPage_PorkEmissionsCalculationResultFragmentFragment;
      };
    };
    inputs: { __typename: 'EmissionsCalculationInputs' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment: AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment;
      };
    };
  } | null;
};

export type AssessmentGhgEmissionsPoultryBroilerPage_PoultryBroilerEmissionsCalculationResultFragmentFragment =
  ({ __typename: 'PoultryEmissionsCalculationResult' } & {
    ' $fragmentRefs'?: {
      PoultryBroilerTotalGhgEmissions_PoultryBroilerEmissionsCalculationResultFragmentFragment: PoultryBroilerTotalGhgEmissions_PoultryBroilerEmissionsCalculationResultFragmentFragment;
      PoultryBroilerEmissionsBreakdown_PoultryBroilerEmissionsCalculationResultFragmentFragment: PoultryBroilerEmissionsBreakdown_PoultryBroilerEmissionsCalculationResultFragmentFragment;
      PoultryBroilerBreakdownGhg_PoultryBroilerEmissionsCalculationResultFragmentFragment: PoultryBroilerBreakdownGhg_PoultryBroilerEmissionsCalculationResultFragmentFragment;
      PoultryBroilerIntensity_PoultryBroilerEmissionsCalculationResultFragmentFragment: PoultryBroilerIntensity_PoultryBroilerEmissionsCalculationResultFragmentFragment;
    };
  }) & {
    ' $fragmentName'?: 'AssessmentGhgEmissionsPoultryBroilerPage_PoultryBroilerEmissionsCalculationResultFragmentFragment';
  };

export type PoultryBroilerBreakdownGhg_PoultryBroilerEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'PoultryEmissionsCalculationResult';
    scope1?: {
      __typename: 'PoultryEmissionsScope1';
      totalCO2?: number | null;
      totalCH4?: number | null;
      totalN2O?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'PoultryBroilerBreakdownGhg_PoultryBroilerEmissionsCalculationResultFragmentFragment';
  };

export type PoultryBroilerEmissionsBreakdown_PoultryBroilerEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'PoultryEmissionsCalculationResult';
    scope1?: {
      __typename: 'PoultryEmissionsScope1';
      atmosphericDepositionN2O?: number | null;
      fuelCH4?: number | null;
      fuelCO2?: number | null;
      fuelN2O?: number | null;
      leachingAndRunoffN2O?: number | null;
      manureManagementCH4?: number | null;
      manureManagementN2O?: number | null;
    } | null;
    scope2?: {
      __typename: 'PoultryEmissionsScope2';
      electricity?: number | null;
    } | null;
    scope3?: {
      __typename: 'PoultryEmissionsScope3';
      electricity?: number | null;
      fuel?: number | null;
      herbicide?: number | null;
      purchasedFeed?: number | null;
      purchasedHay?: number | null;
      purchasedLivestock?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'PoultryBroilerEmissionsBreakdown_PoultryBroilerEmissionsCalculationResultFragmentFragment';
  };

export type PoultryBroilerIntensity_PoultryBroilerEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'PoultryEmissionsCalculationResult';
    intensities?: {
      __typename: 'PoultryEmissionsIntensities';
      poultryMeatIncludingSequestration?: number | null;
      poultryMeatExcludingSequestration?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'PoultryBroilerIntensity_PoultryBroilerEmissionsCalculationResultFragmentFragment';
  };

export type PoultryBroilerTotalGhgEmissions_PoultryBroilerEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'PoultryEmissionsCalculationResult';
    scope1?: {
      __typename: 'PoultryEmissionsScope1';
      total?: number | null;
    } | null;
    scope2?: {
      __typename: 'PoultryEmissionsScope2';
      total?: number | null;
    } | null;
    scope3?: {
      __typename: 'PoultryEmissionsScope3';
      total?: number | null;
    } | null;
    carbonSequestration?: {
      __typename: 'PoultryEmissionsCarbonSequestration';
      total?: number | null;
      intermediate?: {
        __typename: 'PoultryEmissionsTotalAverage';
        total?: number | null;
        average?: number | null;
      } | null;
    } | null;
    net?: { __typename: 'PoultryEmissionsNet'; total?: number | null } | null;
  } & {
    ' $fragmentName'?: 'PoultryBroilerTotalGhgEmissions_PoultryBroilerEmissionsCalculationResultFragmentFragment';
  };

export type GetPoultryBroilerEmissionsQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  filter?: InputMaybe<EmissionsFilterInput>;
}>;

export type GetPoultryBroilerEmissionsQuery = {
  __typename: 'Query';
  poultryBroilerEmissions?: {
    __typename: 'PoultryEmissionsCalculation';
    result: { __typename: 'PoultryEmissionsCalculationResult' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissionsPoultryBroilerPage_PoultryBroilerEmissionsCalculationResultFragmentFragment: AssessmentGhgEmissionsPoultryBroilerPage_PoultryBroilerEmissionsCalculationResultFragmentFragment;
      };
    };
    inputs: { __typename: 'EmissionsCalculationInputs' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment: AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment;
      };
    };
  } | null;
};

export type AssessmentGhgEmissionsPoultryLayerPage_PoultryLayerEmissionsCalculationResultFragmentFragment =
  ({ __typename: 'PoultryEmissionsCalculationResult' } & {
    ' $fragmentRefs'?: {
      PoultryLayerTotalGhgEmissions_PoultryLayerEmissionsCalculationResultFragmentFragment: PoultryLayerTotalGhgEmissions_PoultryLayerEmissionsCalculationResultFragmentFragment;
      PoultryLayerEmissionsBreakdown_PoultryLayerEmissionsCalculationResultFragmentFragment: PoultryLayerEmissionsBreakdown_PoultryLayerEmissionsCalculationResultFragmentFragment;
      PoultryLayerBreakdownGhg_PoultryLayerEmissionsCalculationResultFragmentFragment: PoultryLayerBreakdownGhg_PoultryLayerEmissionsCalculationResultFragmentFragment;
      PoultryLayerIntensity_PoultryLayerEmissionsCalculationResultFragmentFragment: PoultryLayerIntensity_PoultryLayerEmissionsCalculationResultFragmentFragment;
    };
  }) & {
    ' $fragmentName'?: 'AssessmentGhgEmissionsPoultryLayerPage_PoultryLayerEmissionsCalculationResultFragmentFragment';
  };

export type PoultryLayerBreakdownGhg_PoultryLayerEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'PoultryEmissionsCalculationResult';
    scope1?: {
      __typename: 'PoultryEmissionsScope1';
      totalCO2?: number | null;
      totalCH4?: number | null;
      totalN2O?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'PoultryLayerBreakdownGhg_PoultryLayerEmissionsCalculationResultFragmentFragment';
  };

export type PoultryLayerEmissionsBreakdown_PoultryLayerEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'PoultryEmissionsCalculationResult';
    scope1?: {
      __typename: 'PoultryEmissionsScope1';
      atmosphericDepositionN2O?: number | null;
      fuelCH4?: number | null;
      fuelCO2?: number | null;
      fuelN2O?: number | null;
      leachingAndRunoffN2O?: number | null;
      manureManagementCH4?: number | null;
      manureManagementN2O?: number | null;
    } | null;
    scope2?: {
      __typename: 'PoultryEmissionsScope2';
      electricity?: number | null;
    } | null;
    scope3?: {
      __typename: 'PoultryEmissionsScope3';
      electricity?: number | null;
      fuel?: number | null;
      herbicide?: number | null;
      purchasedFeed?: number | null;
      purchasedHay?: number | null;
      purchasedLivestock?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'PoultryLayerEmissionsBreakdown_PoultryLayerEmissionsCalculationResultFragmentFragment';
  };

export type PoultryLayerIntensity_PoultryLayerEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'PoultryEmissionsCalculationResult';
    intensities?: {
      __typename: 'PoultryEmissionsIntensities';
      poultryEggsIncludingSequestration?: number | null;
      poultryEggsExcludingSequestration?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'PoultryLayerIntensity_PoultryLayerEmissionsCalculationResultFragmentFragment';
  };

export type PoultryLayerTotalGhgEmissions_PoultryLayerEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'PoultryEmissionsCalculationResult';
    scope1?: {
      __typename: 'PoultryEmissionsScope1';
      total?: number | null;
    } | null;
    scope2?: {
      __typename: 'PoultryEmissionsScope2';
      total?: number | null;
    } | null;
    scope3?: {
      __typename: 'PoultryEmissionsScope3';
      total?: number | null;
    } | null;
    carbonSequestration?: {
      __typename: 'PoultryEmissionsCarbonSequestration';
      total?: number | null;
      intermediate?: {
        __typename: 'PoultryEmissionsTotalAverage';
        total?: number | null;
        average?: number | null;
      } | null;
    } | null;
    net?: { __typename: 'PoultryEmissionsNet'; total?: number | null } | null;
  } & {
    ' $fragmentName'?: 'PoultryLayerTotalGhgEmissions_PoultryLayerEmissionsCalculationResultFragmentFragment';
  };

export type GetPoultryLayerEmissionsQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  filter?: InputMaybe<EmissionsFilterInput>;
}>;

export type GetPoultryLayerEmissionsQuery = {
  __typename: 'Query';
  poultryLayerEmissions?: {
    __typename: 'PoultryEmissionsCalculation';
    result: { __typename: 'PoultryEmissionsCalculationResult' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissionsPoultryLayerPage_PoultryLayerEmissionsCalculationResultFragmentFragment: AssessmentGhgEmissionsPoultryLayerPage_PoultryLayerEmissionsCalculationResultFragmentFragment;
      };
    };
    inputs: { __typename: 'EmissionsCalculationInputs' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment: AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment;
      };
    };
  } | null;
};

export type AssessmentGhgEmissionsSheepPage_SheepBeefEmissionsCalculationResultFragmentFragment =
  ({ __typename: 'SheepBeefEmissionsCalculationResult' } & {
    ' $fragmentRefs'?: {
      SheepTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragmentFragment: SheepTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragmentFragment;
      SheepEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragmentFragment: SheepEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragmentFragment;
      SheepBreakdownGhg_SheepBeefEmissionsCalculationResultFragmentFragment: SheepBreakdownGhg_SheepBeefEmissionsCalculationResultFragmentFragment;
      SheepIntensity_SheepBeefEmissionsCalculationResultFragmentFragment: SheepIntensity_SheepBeefEmissionsCalculationResultFragmentFragment;
    };
  }) & {
    ' $fragmentName'?: 'AssessmentGhgEmissionsSheepPage_SheepBeefEmissionsCalculationResultFragmentFragment';
  };

export type SheepBreakdownGhg_SheepBeefEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'SheepBeefEmissionsCalculationResult';
    scope1?: {
      __typename: 'SheepBeefEmissionsScope1';
      totalCO2?: number | null;
      totalCH4?: number | null;
      totalN2O?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'SheepBreakdownGhg_SheepBeefEmissionsCalculationResultFragmentFragment';
  };

export type SheepEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'SheepBeefEmissionsCalculationResult';
    scope1?: {
      __typename: 'SheepBeefEmissionsScope1';
      leachingAndRunoffN2O?: number | null;
      atmosphericDepositionN2O?: number | null;
      urineAndDungN2O?: number | null;
      manureManagementCH4?: number | null;
      entericCH4?: number | null;
      fertiliserN2O?: number | null;
      limeCO2?: number | null;
      fuelCO2?: number | null;
      fuelCH4?: number | null;
      fuelN2O?: number | null;
      ureaCO2?: number | null;
      savannahBurningN2O?: number | null;
      savannahBurningCH4?: number | null;
    } | null;
    scope2?: {
      __typename: 'SheepBeefEmissionsScope2';
      electricity?: number | null;
    } | null;
    scope3?: {
      __typename: 'SheepBeefEmissionsScope3';
      fertiliser?: number | null;
      purchasedMineralSupplementation?: number | null;
      purchasedFeed?: number | null;
      herbicide?: number | null;
      electricity?: number | null;
      fuel?: number | null;
      lime?: number | null;
      purchasedLivestock?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'SheepEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragmentFragment';
  };

export type SheepIntensity_SheepBeefEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'SheepBeefEmissionsCalculationResult';
    intensities?: {
      __typename: 'SheepBeefEmissionsIntensities';
      sheepMeatBreedingIncludingSequestration?: number | null;
      sheepMeatBreedingExcludingSequestration?: number | null;
      woolIncludingSequestration?: number | null;
      woolExcludingSequestration?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'SheepIntensity_SheepBeefEmissionsCalculationResultFragmentFragment';
  };

export type SheepTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'SheepBeefEmissionsCalculationResult';
    scope1?: {
      __typename: 'SheepBeefEmissionsScope1';
      total?: number | null;
    } | null;
    scope2?: {
      __typename: 'SheepBeefEmissionsScope2';
      total?: number | null;
    } | null;
    scope3?: {
      __typename: 'SheepBeefEmissionsScope3';
      total?: number | null;
    } | null;
    carbonSequestration?: {
      __typename: 'SheepBeefEmissionsCarbonSequestration';
      total?: number | null;
      intermediate?: {
        __typename: 'SheepBeefEmissionsTotalAverage';
        total?: number | null;
        average?: number | null;
      } | null;
    } | null;
    intermediate?: {
      __typename: 'SheepBeefEmissionsIntermediate';
      sheep?: {
        __typename: 'SheepBeefEmissionsIntermediateScopes';
        carbonSequestration?: number | null;
      } | null;
    } | null;
    net?: { __typename: 'SheepBeefEmissionsNet'; total?: number | null } | null;
  } & {
    ' $fragmentName'?: 'SheepTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragmentFragment';
  };

export type GetSheepEmissionsQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  filter?: InputMaybe<EmissionsFilterInput>;
}>;

export type GetSheepEmissionsQuery = {
  __typename: 'Query';
  sheepEmissions: {
    __typename: 'SheepBeefEmissionsCalculation';
    result: { __typename: 'SheepBeefEmissionsCalculationResult' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissionsSheepPage_SheepBeefEmissionsCalculationResultFragmentFragment: AssessmentGhgEmissionsSheepPage_SheepBeefEmissionsCalculationResultFragmentFragment;
      };
    };
    inputs: { __typename: 'EmissionsCalculationInputs' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment: AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment;
      };
    };
  };
};

export type AssessmentGhgEmissionsSugarPage_SugarEmissionsCalculationResultFragmentFragment =
  ({ __typename: 'SugarEmissionsCalculationResult' } & {
    ' $fragmentRefs'?: {
      SugarTotalGhgEmissions_SugarEmissionsCalculationResultFragmentFragment: SugarTotalGhgEmissions_SugarEmissionsCalculationResultFragmentFragment;
      SugarEmissionsBreakdown_SugarEmissionsCalculationResultFragmentFragment: SugarEmissionsBreakdown_SugarEmissionsCalculationResultFragmentFragment;
      SugarBreakdownGhg_SugarEmissionsCalculationResultFragmentFragment: SugarBreakdownGhg_SugarEmissionsCalculationResultFragmentFragment;
      SugarIntensity_SugarEmissionsCalculationResultFragmentFragment: SugarIntensity_SugarEmissionsCalculationResultFragmentFragment;
    };
  }) & {
    ' $fragmentName'?: 'AssessmentGhgEmissionsSugarPage_SugarEmissionsCalculationResultFragmentFragment';
  };

export type SugarBreakdownGhg_SugarEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'SugarEmissionsCalculationResult';
    scope1?: {
      __typename: 'SugarEmissionsScope1';
      totalCO2?: number | null;
      totalCH4?: number | null;
      totalN2O?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'SugarBreakdownGhg_SugarEmissionsCalculationResultFragmentFragment';
  };

export type SugarEmissionsBreakdown_SugarEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'SugarEmissionsCalculationResult';
    scope1?: {
      __typename: 'SugarEmissionsScope1';
      fuelCO2?: number | null;
      fuelCH4?: number | null;
      fuelN2O?: number | null;
      ureaCO2?: number | null;
      limeCO2?: number | null;
      fertiliserN2O?: number | null;
      atmosphericDepositionN2O?: number | null;
      leachingAndRunoffN2O?: number | null;
      cropResidueN2O?: number | null;
      fieldBurningN2O?: number | null;
      fieldBurningCH4?: number | null;
    } | null;
    scope2?: {
      __typename: 'SugarEmissionsScope2';
      electricity?: number | null;
    } | null;
    scope3?: {
      __typename: 'SugarEmissionsScope3';
      fertiliser?: number | null;
      herbicide?: number | null;
      electricity?: number | null;
      fuel?: number | null;
      lime?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'SugarEmissionsBreakdown_SugarEmissionsCalculationResultFragmentFragment';
  };

export type SugarIntensity_SugarEmissionsCalculationResultFragmentFragment = {
  __typename: 'SugarEmissionsCalculationResult';
  intensities?: Array<{
    __typename: 'SugarEmissionsIntensities';
    sugarIncludingSequestration?: number | null;
    sugarExcludingSequestration?: number | null;
  } | null> | null;
} & {
  ' $fragmentName'?: 'SugarIntensity_SugarEmissionsCalculationResultFragmentFragment';
};

export type SugarTotalGhgEmissions_SugarEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'SugarEmissionsCalculationResult';
    scope1?: {
      __typename: 'SugarEmissionsScope1';
      total?: number | null;
    } | null;
    scope2?: {
      __typename: 'SugarEmissionsScope2';
      total?: number | null;
    } | null;
    scope3?: {
      __typename: 'SugarEmissionsScope3';
      total?: number | null;
    } | null;
    carbonSequestration?: {
      __typename: 'SugarEmissionsCarbonSequestration';
      total?: number | null;
    } | null;
    net?: { __typename: 'SugarEmissionsNet'; total?: number | null } | null;
  } & {
    ' $fragmentName'?: 'SugarTotalGhgEmissions_SugarEmissionsCalculationResultFragmentFragment';
  };

export type GetSugarEmissionsQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  filter?: InputMaybe<EmissionsFilterInput>;
}>;

export type GetSugarEmissionsQuery = {
  __typename: 'Query';
  sugarEmissions?: {
    __typename: 'SugarEmissionsCalculation';
    result: { __typename: 'SugarEmissionsCalculationResult' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissionsSugarPage_SugarEmissionsCalculationResultFragmentFragment: AssessmentGhgEmissionsSugarPage_SugarEmissionsCalculationResultFragmentFragment;
      };
    };
    inputs: { __typename: 'EmissionsCalculationInputs' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment: AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment;
      };
    };
  } | null;
};

export type AssessmentGhgEmissionsWildSeaFisheryPage_WildSeaFisheryEmissionsCalculationResultFragmentFragment =
  ({ __typename: 'WildSeaFisheryEmissionsCalculationResult' } & {
    ' $fragmentRefs'?: {
      WildSeaFisheryTotalGhgEmissions_WildSeaFisheryEmissionsCalculationResultFragmentFragment: WildSeaFisheryTotalGhgEmissions_WildSeaFisheryEmissionsCalculationResultFragmentFragment;
      WildSeaFisheryEmissionsBreakdown_WildSeaFisheryEmissionsCalculationResultFragmentFragment: WildSeaFisheryEmissionsBreakdown_WildSeaFisheryEmissionsCalculationResultFragmentFragment;
      WildSeaFisheryBreakdownGhg_WildSeaFisheryEmissionsCalculationResultFragmentFragment: WildSeaFisheryBreakdownGhg_WildSeaFisheryEmissionsCalculationResultFragmentFragment;
      WildSeaFisheryIntensity_WildSeaFisheryEmissionsCalculationResultFragmentFragment: WildSeaFisheryIntensity_WildSeaFisheryEmissionsCalculationResultFragmentFragment;
    };
  }) & {
    ' $fragmentName'?: 'AssessmentGhgEmissionsWildSeaFisheryPage_WildSeaFisheryEmissionsCalculationResultFragmentFragment';
  };

export type WildSeaFisheryBreakdownGhg_WildSeaFisheryEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'WildSeaFisheryEmissionsCalculationResult';
    scope1?: {
      __typename: 'WildSeaFisheryEmissionsScope1';
      totalCO2?: number | null;
      totalCH4?: number | null;
      totalN2O?: number | null;
      totalHFCs?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'WildSeaFisheryBreakdownGhg_WildSeaFisheryEmissionsCalculationResultFragmentFragment';
  };

export type WildSeaFisheryEmissionsBreakdown_WildSeaFisheryEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'WildSeaFisheryEmissionsCalculationResult';
    scope1?: {
      __typename: 'WildSeaFisheryEmissionsScope1';
      fuelCO2?: number | null;
      fuelCH4?: number | null;
      fuelN2O?: number | null;
      hfcsRefrigerantLeakage?: number | null;
    } | null;
    scope2?: {
      __typename: 'WildSeaFisheryEmissionsScope2';
      electricity?: number | null;
    } | null;
    scope3?: {
      __typename: 'WildSeaFisheryEmissionsScope3';
      electricity?: number | null;
      fuel?: number | null;
      bait?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'WildSeaFisheryEmissionsBreakdown_WildSeaFisheryEmissionsCalculationResultFragmentFragment';
  };

export type WildSeaFisheryIntensity_WildSeaFisheryEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'WildSeaFisheryEmissionsCalculationResult';
    intensities?: Array<{
      __typename: 'WildSeaFisheryEmissionsIntensities';
      intensityIncludingCarbonOffset?: number | null;
      intensityExcludingCarbonOffset?: number | null;
    } | null> | null;
  } & {
    ' $fragmentName'?: 'WildSeaFisheryIntensity_WildSeaFisheryEmissionsCalculationResultFragmentFragment';
  };

export type WildSeaFisheryTotalGhgEmissions_WildSeaFisheryEmissionsCalculationResultFragmentFragment =
  {
    __typename: 'WildSeaFisheryEmissionsCalculationResult';
    scope1?: {
      __typename: 'WildSeaFisheryEmissionsScope1';
      total?: number | null;
    } | null;
    scope2?: {
      __typename: 'WildSeaFisheryEmissionsScope2';
      total?: number | null;
    } | null;
    scope3?: {
      __typename: 'WildSeaFisheryEmissionsScope3';
      total?: number | null;
    } | null;
    net?: {
      __typename: 'WildSeaFisheryEmissionsNet';
      total?: number | null;
    } | null;
    purchasedOffsets?: {
      __typename: 'WildSeaFisheryPurchasedOffsets';
      total?: number | null;
    } | null;
  } & {
    ' $fragmentName'?: 'WildSeaFisheryTotalGhgEmissions_WildSeaFisheryEmissionsCalculationResultFragmentFragment';
  };

export type GetWildSeaFisheryEmissionsQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  filter?: InputMaybe<EmissionsFilterInput>;
}>;

export type GetWildSeaFisheryEmissionsQuery = {
  __typename: 'Query';
  wildSeaFisheryEmissions?: {
    __typename: 'WildSeaFisheryEmissionsCalculation';
    result: { __typename: 'WildSeaFisheryEmissionsCalculationResult' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissionsWildSeaFisheryPage_WildSeaFisheryEmissionsCalculationResultFragmentFragment: AssessmentGhgEmissionsWildSeaFisheryPage_WildSeaFisheryEmissionsCalculationResultFragmentFragment;
      };
    };
    inputs: { __typename: 'EmissionsCalculationInputs' } & {
      ' $fragmentRefs'?: {
        AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment: AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment;
      };
    };
  } | null;
};

export type KyeAssessmentReportsEditPage_ReportFragment = {
  __typename: 'Report';
  id: string;
  name: string;
  sections: Array<ReportSection>;
  propertyId: string;
  teamId: string;
  type: ReportType;
} & { ' $fragmentName'?: 'KyeAssessmentReportsEditPage_ReportFragment' };

export type UpdateKyeReportMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateReportInput;
}>;

export type UpdateKyeReportMutation = {
  __typename: 'Mutation';
  updateReport: { __typename: 'Report'; id: string } & {
    ' $fragmentRefs'?: {
      KyeAssessmentReportsPage_ReportFragment: KyeAssessmentReportsPage_ReportFragment;
      KyeAssessmentReportsEditPage_ReportFragment: KyeAssessmentReportsEditPage_ReportFragment;
    };
  };
};

export type DeleteKyeReportMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteKyeReportMutation = {
  __typename: 'Mutation';
  deleteReport: { __typename: 'Report'; id: string };
};

export type GetKyeReportQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetKyeReportQuery = {
  __typename: 'Query';
  report?:
    | ({ __typename: 'Report'; id: string } & {
        ' $fragmentRefs'?: {
          KyeAssessmentReportsEditPage_ReportFragment: KyeAssessmentReportsEditPage_ReportFragment;
        };
      })
    | null;
};

export type KyeAssessmentReportsPage_ReportFragment = ({
  __typename: 'Report';
  id: string;
} & {
  ' $fragmentRefs'?: {
    KyeReportListItem_ReportFragment: KyeReportListItem_ReportFragment;
  };
}) & { ' $fragmentName'?: 'KyeAssessmentReportsPage_ReportFragment' };

export type KyeReportListItem_ReportFragment = {
  __typename: 'Report';
  id: string;
  teamId: string;
  propertyId: string;
  name: string;
  createdAt: string;
  status: ReportStatus;
} & { ' $fragmentName'?: 'KyeReportListItem_ReportFragment' };

export type KyeReportListItem_ReportPdfLinkFragment = {
  __typename: 'Report';
  id: string;
  signedPdfUrl: string;
} & { ' $fragmentName'?: 'KyeReportListItem_ReportPdfLinkFragment' };

export type GetKyeReportsQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
}>;

export type GetKyeReportsQuery = {
  __typename: 'Query';
  kyeReports: Array<
    { __typename: 'Report'; id: string } & {
      ' $fragmentRefs'?: {
        KyeAssessmentReportsPage_ReportFragment: KyeAssessmentReportsPage_ReportFragment;
      };
    }
  >;
};

export type GetKyeReportSignedUrlQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetKyeReportSignedUrlQuery = {
  __typename: 'Query';
  report?:
    | ({ __typename: 'Report'; id: string } & {
        ' $fragmentRefs'?: {
          KyeReportListItem_ReportPdfLinkFragment: KyeReportListItem_ReportPdfLinkFragment;
        };
      })
    | null;
};

export type CreateKyeReportMutationVariables = Exact<{
  input: CreateReportInput;
}>;

export type CreateKyeReportMutation = {
  __typename: 'Mutation';
  createReport: { __typename: 'Report'; id: string };
};

type PropertiesBusinessActivitiesAllocationIndexFragment_BeefLivestockActivity_Fragment =
  {
    __typename: 'BeefLivestockActivity';
    id: string;
    start: string;
    end: string;
    name: string;
    targetAllocations?: Array<{
      __typename: 'ActivityAllocation';
      percentage: number;
      sourceActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
      targetActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
    } | null> | null;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesAllocationIndexFragment_BeefLivestockActivity_Fragment';
  };

type PropertiesBusinessActivitiesAllocationIndexFragment_BuffaloLivestockActivity_Fragment =
  {
    __typename: 'BuffaloLivestockActivity';
    id: string;
    start: string;
    end: string;
    name: string;
    targetAllocations?: Array<{
      __typename: 'ActivityAllocation';
      percentage: number;
      sourceActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
      targetActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
    } | null> | null;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesAllocationIndexFragment_BuffaloLivestockActivity_Fragment';
  };

type PropertiesBusinessActivitiesAllocationIndexFragment_BurningActivity_Fragment =
  {
    __typename: 'BurningActivity';
    id: string;
    start: string;
    end: string;
    name: string;
    targetAllocations?: Array<{
      __typename: 'ActivityAllocation';
      percentage: number;
      sourceActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
      targetActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
    } | null> | null;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesAllocationIndexFragment_BurningActivity_Fragment';
  };

type PropertiesBusinessActivitiesAllocationIndexFragment_CroppingActivity_Fragment =
  {
    __typename: 'CroppingActivity';
    croppingType: CroppingType;
    id: string;
    start: string;
    end: string;
    name: string;
    targetAllocations?: Array<{
      __typename: 'ActivityAllocation';
      percentage: number;
      sourceActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
      targetActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
    } | null> | null;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesAllocationIndexFragment_CroppingActivity_Fragment';
  };

type PropertiesBusinessActivitiesAllocationIndexFragment_DairyLivestockActivity_Fragment =
  {
    __typename: 'DairyLivestockActivity';
    id: string;
    start: string;
    end: string;
    name: string;
    targetAllocations?: Array<{
      __typename: 'ActivityAllocation';
      percentage: number;
      sourceActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
      targetActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
    } | null> | null;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesAllocationIndexFragment_DairyLivestockActivity_Fragment';
  };

type PropertiesBusinessActivitiesAllocationIndexFragment_DeerLivestockActivity_Fragment =
  {
    __typename: 'DeerLivestockActivity';
    id: string;
    start: string;
    end: string;
    name: string;
    targetAllocations?: Array<{
      __typename: 'ActivityAllocation';
      percentage: number;
      sourceActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
      targetActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
    } | null> | null;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesAllocationIndexFragment_DeerLivestockActivity_Fragment';
  };

type PropertiesBusinessActivitiesAllocationIndexFragment_FeedlotActivity_Fragment =
  {
    __typename: 'FeedlotActivity';
    id: string;
    start: string;
    end: string;
    name: string;
    targetAllocations?: Array<{
      __typename: 'ActivityAllocation';
      percentage: number;
      sourceActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
      targetActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
    } | null> | null;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesAllocationIndexFragment_FeedlotActivity_Fragment';
  };

type PropertiesBusinessActivitiesAllocationIndexFragment_GoatLivestockActivity_Fragment =
  {
    __typename: 'GoatLivestockActivity';
    id: string;
    start: string;
    end: string;
    name: string;
    targetAllocations?: Array<{
      __typename: 'ActivityAllocation';
      percentage: number;
      sourceActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
      targetActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
    } | null> | null;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesAllocationIndexFragment_GoatLivestockActivity_Fragment';
  };

type PropertiesBusinessActivitiesAllocationIndexFragment_PorkLivestockActivity_Fragment =
  {
    __typename: 'PorkLivestockActivity';
    id: string;
    start: string;
    end: string;
    name: string;
    targetAllocations?: Array<{
      __typename: 'ActivityAllocation';
      percentage: number;
      sourceActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
      targetActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
    } | null> | null;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesAllocationIndexFragment_PorkLivestockActivity_Fragment';
  };

type PropertiesBusinessActivitiesAllocationIndexFragment_PoultryBroilerActivity_Fragment =
  {
    __typename: 'PoultryBroilerActivity';
    id: string;
    start: string;
    end: string;
    name: string;
    targetAllocations: Array<{
      __typename: 'ActivityAllocation';
      percentage: number;
      sourceActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
      targetActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
    } | null>;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesAllocationIndexFragment_PoultryBroilerActivity_Fragment';
  };

type PropertiesBusinessActivitiesAllocationIndexFragment_PoultryLayerActivity_Fragment =
  {
    __typename: 'PoultryLayerActivity';
    id: string;
    start: string;
    end: string;
    name: string;
    targetAllocations: Array<{
      __typename: 'ActivityAllocation';
      percentage: number;
      sourceActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
      targetActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
    } | null>;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesAllocationIndexFragment_PoultryLayerActivity_Fragment';
  };

type PropertiesBusinessActivitiesAllocationIndexFragment_SheepLivestockActivity_Fragment =
  {
    __typename: 'SheepLivestockActivity';
    id: string;
    start: string;
    end: string;
    name: string;
    targetAllocations?: Array<{
      __typename: 'ActivityAllocation';
      percentage: number;
      sourceActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
      targetActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
    } | null> | null;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesAllocationIndexFragment_SheepLivestockActivity_Fragment';
  };

type PropertiesBusinessActivitiesAllocationIndexFragment_VegetationActivity_Fragment =
  {
    __typename: 'VegetationActivity';
    id: string;
    start: string;
    end: string;
    name: string;
    targetAllocations?: Array<{
      __typename: 'ActivityAllocation';
      percentage: number;
      sourceActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
      targetActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
    } | null> | null;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesAllocationIndexFragment_VegetationActivity_Fragment';
  };

type PropertiesBusinessActivitiesAllocationIndexFragment_WildSeaFisheryActivity_Fragment =
  {
    __typename: 'WildSeaFisheryActivity';
    id: string;
    start: string;
    end: string;
    name: string;
    targetAllocations?: Array<{
      __typename: 'ActivityAllocation';
      percentage: number;
      sourceActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
      targetActivity:
        | { __typename: 'BeefLivestockActivity'; id: string }
        | { __typename: 'BuffaloLivestockActivity'; id: string }
        | { __typename: 'BurningActivity'; id: string }
        | { __typename: 'CroppingActivity'; id: string }
        | { __typename: 'DairyLivestockActivity'; id: string }
        | { __typename: 'DeerLivestockActivity'; id: string }
        | { __typename: 'FeedlotActivity'; id: string }
        | { __typename: 'GoatLivestockActivity'; id: string }
        | { __typename: 'PorkLivestockActivity'; id: string }
        | { __typename: 'PoultryBroilerActivity'; id: string }
        | { __typename: 'PoultryLayerActivity'; id: string }
        | { __typename: 'SheepLivestockActivity'; id: string }
        | { __typename: 'VegetationActivity'; id: string }
        | { __typename: 'WildSeaFisheryActivity'; id: string };
    } | null> | null;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesAllocationIndexFragment_WildSeaFisheryActivity_Fragment';
  };

export type PropertiesBusinessActivitiesAllocationIndexFragmentFragment =
  | PropertiesBusinessActivitiesAllocationIndexFragment_BeefLivestockActivity_Fragment
  | PropertiesBusinessActivitiesAllocationIndexFragment_BuffaloLivestockActivity_Fragment
  | PropertiesBusinessActivitiesAllocationIndexFragment_BurningActivity_Fragment
  | PropertiesBusinessActivitiesAllocationIndexFragment_CroppingActivity_Fragment
  | PropertiesBusinessActivitiesAllocationIndexFragment_DairyLivestockActivity_Fragment
  | PropertiesBusinessActivitiesAllocationIndexFragment_DeerLivestockActivity_Fragment
  | PropertiesBusinessActivitiesAllocationIndexFragment_FeedlotActivity_Fragment
  | PropertiesBusinessActivitiesAllocationIndexFragment_GoatLivestockActivity_Fragment
  | PropertiesBusinessActivitiesAllocationIndexFragment_PorkLivestockActivity_Fragment
  | PropertiesBusinessActivitiesAllocationIndexFragment_PoultryBroilerActivity_Fragment
  | PropertiesBusinessActivitiesAllocationIndexFragment_PoultryLayerActivity_Fragment
  | PropertiesBusinessActivitiesAllocationIndexFragment_SheepLivestockActivity_Fragment
  | PropertiesBusinessActivitiesAllocationIndexFragment_VegetationActivity_Fragment
  | PropertiesBusinessActivitiesAllocationIndexFragment_WildSeaFisheryActivity_Fragment;

export type BusinessActivitiesAllocationConsumptionEditFragmentFragment = {
  __typename: 'Consumption';
  id: string;
  startDate?: string | null;
  endDate?: string | null;
  consumableType: ConsumableType;
  consumableSubType: ConsumableSubType;
  amount: number;
  consumptionAllocations: Array<{
    __typename: 'ConsumptionAllocation';
    percentage: number;
    sourceConsumption: { __typename: 'Consumption'; id: string };
    targetActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
  }>;
} & {
  ' $fragmentName'?: 'BusinessActivitiesAllocationConsumptionEditFragmentFragment';
};

type AllocationIndexActivitiesFragment_BeefLivestockActivity_Fragment = {
  __typename: 'BeefLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
  targetAllocations?: Array<{
    __typename: 'ActivityAllocation';
    percentage: number;
    sourceActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
    targetActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
  } | null> | null;
} & {
  ' $fragmentName'?: 'AllocationIndexActivitiesFragment_BeefLivestockActivity_Fragment';
};

type AllocationIndexActivitiesFragment_BuffaloLivestockActivity_Fragment = {
  __typename: 'BuffaloLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
  targetAllocations?: Array<{
    __typename: 'ActivityAllocation';
    percentage: number;
    sourceActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
    targetActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
  } | null> | null;
} & {
  ' $fragmentName'?: 'AllocationIndexActivitiesFragment_BuffaloLivestockActivity_Fragment';
};

type AllocationIndexActivitiesFragment_BurningActivity_Fragment = {
  __typename: 'BurningActivity';
  id: string;
  name: string;
  start: string;
  end: string;
  targetAllocations?: Array<{
    __typename: 'ActivityAllocation';
    percentage: number;
    sourceActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
    targetActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
  } | null> | null;
} & {
  ' $fragmentName'?: 'AllocationIndexActivitiesFragment_BurningActivity_Fragment';
};

type AllocationIndexActivitiesFragment_CroppingActivity_Fragment = {
  __typename: 'CroppingActivity';
  croppingType: CroppingType;
  id: string;
  name: string;
  start: string;
  end: string;
  varieties: Array<{ __typename: 'CropVariety'; yieldInKg: number }>;
  targetAllocations?: Array<{
    __typename: 'ActivityAllocation';
    percentage: number;
    sourceActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
    targetActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
  } | null> | null;
} & {
  ' $fragmentName'?: 'AllocationIndexActivitiesFragment_CroppingActivity_Fragment';
};

type AllocationIndexActivitiesFragment_DairyLivestockActivity_Fragment = {
  __typename: 'DairyLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
  targetAllocations?: Array<{
    __typename: 'ActivityAllocation';
    percentage: number;
    sourceActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
    targetActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
  } | null> | null;
} & {
  ' $fragmentName'?: 'AllocationIndexActivitiesFragment_DairyLivestockActivity_Fragment';
};

type AllocationIndexActivitiesFragment_DeerLivestockActivity_Fragment = {
  __typename: 'DeerLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
  targetAllocations?: Array<{
    __typename: 'ActivityAllocation';
    percentage: number;
    sourceActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
    targetActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
  } | null> | null;
} & {
  ' $fragmentName'?: 'AllocationIndexActivitiesFragment_DeerLivestockActivity_Fragment';
};

type AllocationIndexActivitiesFragment_FeedlotActivity_Fragment = {
  __typename: 'FeedlotActivity';
  id: string;
  name: string;
  start: string;
  end: string;
  targetAllocations?: Array<{
    __typename: 'ActivityAllocation';
    percentage: number;
    sourceActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
    targetActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
  } | null> | null;
} & {
  ' $fragmentName'?: 'AllocationIndexActivitiesFragment_FeedlotActivity_Fragment';
};

type AllocationIndexActivitiesFragment_GoatLivestockActivity_Fragment = {
  __typename: 'GoatLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
  targetAllocations?: Array<{
    __typename: 'ActivityAllocation';
    percentage: number;
    sourceActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
    targetActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
  } | null> | null;
} & {
  ' $fragmentName'?: 'AllocationIndexActivitiesFragment_GoatLivestockActivity_Fragment';
};

type AllocationIndexActivitiesFragment_PorkLivestockActivity_Fragment = {
  __typename: 'PorkLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
  targetAllocations?: Array<{
    __typename: 'ActivityAllocation';
    percentage: number;
    sourceActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
    targetActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
  } | null> | null;
} & {
  ' $fragmentName'?: 'AllocationIndexActivitiesFragment_PorkLivestockActivity_Fragment';
};

type AllocationIndexActivitiesFragment_PoultryBroilerActivity_Fragment = {
  __typename: 'PoultryBroilerActivity';
  id: string;
  name: string;
  start: string;
  end: string;
  targetAllocations: Array<{
    __typename: 'ActivityAllocation';
    percentage: number;
    sourceActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
    targetActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
  } | null>;
} & {
  ' $fragmentName'?: 'AllocationIndexActivitiesFragment_PoultryBroilerActivity_Fragment';
};

type AllocationIndexActivitiesFragment_PoultryLayerActivity_Fragment = {
  __typename: 'PoultryLayerActivity';
  id: string;
  name: string;
  start: string;
  end: string;
  targetAllocations: Array<{
    __typename: 'ActivityAllocation';
    percentage: number;
    sourceActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
    targetActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
  } | null>;
} & {
  ' $fragmentName'?: 'AllocationIndexActivitiesFragment_PoultryLayerActivity_Fragment';
};

type AllocationIndexActivitiesFragment_SheepLivestockActivity_Fragment = {
  __typename: 'SheepLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
  targetAllocations?: Array<{
    __typename: 'ActivityAllocation';
    percentage: number;
    sourceActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
    targetActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
  } | null> | null;
} & {
  ' $fragmentName'?: 'AllocationIndexActivitiesFragment_SheepLivestockActivity_Fragment';
};

type AllocationIndexActivitiesFragment_VegetationActivity_Fragment = {
  __typename: 'VegetationActivity';
  id: string;
  name: string;
  start: string;
  end: string;
  targetAllocations?: Array<{
    __typename: 'ActivityAllocation';
    percentage: number;
    sourceActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
    targetActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
  } | null> | null;
} & {
  ' $fragmentName'?: 'AllocationIndexActivitiesFragment_VegetationActivity_Fragment';
};

type AllocationIndexActivitiesFragment_WildSeaFisheryActivity_Fragment = {
  __typename: 'WildSeaFisheryActivity';
  id: string;
  name: string;
  start: string;
  end: string;
  targetAllocations?: Array<{
    __typename: 'ActivityAllocation';
    percentage: number;
    sourceActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
    targetActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
  } | null> | null;
} & {
  ' $fragmentName'?: 'AllocationIndexActivitiesFragment_WildSeaFisheryActivity_Fragment';
};

export type AllocationIndexActivitiesFragmentFragment =
  | AllocationIndexActivitiesFragment_BeefLivestockActivity_Fragment
  | AllocationIndexActivitiesFragment_BuffaloLivestockActivity_Fragment
  | AllocationIndexActivitiesFragment_BurningActivity_Fragment
  | AllocationIndexActivitiesFragment_CroppingActivity_Fragment
  | AllocationIndexActivitiesFragment_DairyLivestockActivity_Fragment
  | AllocationIndexActivitiesFragment_DeerLivestockActivity_Fragment
  | AllocationIndexActivitiesFragment_FeedlotActivity_Fragment
  | AllocationIndexActivitiesFragment_GoatLivestockActivity_Fragment
  | AllocationIndexActivitiesFragment_PorkLivestockActivity_Fragment
  | AllocationIndexActivitiesFragment_PoultryBroilerActivity_Fragment
  | AllocationIndexActivitiesFragment_PoultryLayerActivity_Fragment
  | AllocationIndexActivitiesFragment_SheepLivestockActivity_Fragment
  | AllocationIndexActivitiesFragment_VegetationActivity_Fragment
  | AllocationIndexActivitiesFragment_WildSeaFisheryActivity_Fragment;

export type BusinessActivitiesAllocationIndexConsumptionsFragmentFragment = {
  __typename: 'Consumption';
  id: string;
  startDate?: string | null;
  endDate?: string | null;
  name?: string | null;
  consumableType: ConsumableType;
  consumableSubType: ConsumableSubType;
  amount: number;
  consumptionAllocations: Array<{
    __typename: 'ConsumptionAllocation';
    percentage: number;
    sourceConsumption: { __typename: 'Consumption'; id: string };
    targetActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
  }>;
} & {
  ' $fragmentName'?: 'BusinessActivitiesAllocationIndexConsumptionsFragmentFragment';
};

type AllocateToActivitiesComponentActivityFragment_BeefLivestockActivity_Fragment =
  {
    __typename: 'BeefLivestockActivity';
    id: string;
    name: string;
    start: string;
    end: string;
  } & {
    ' $fragmentName'?: 'AllocateToActivitiesComponentActivityFragment_BeefLivestockActivity_Fragment';
  };

type AllocateToActivitiesComponentActivityFragment_BuffaloLivestockActivity_Fragment =
  {
    __typename: 'BuffaloLivestockActivity';
    id: string;
    name: string;
    start: string;
    end: string;
  } & {
    ' $fragmentName'?: 'AllocateToActivitiesComponentActivityFragment_BuffaloLivestockActivity_Fragment';
  };

type AllocateToActivitiesComponentActivityFragment_BurningActivity_Fragment = {
  __typename: 'BurningActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'AllocateToActivitiesComponentActivityFragment_BurningActivity_Fragment';
};

type AllocateToActivitiesComponentActivityFragment_CroppingActivity_Fragment = {
  __typename: 'CroppingActivity';
  croppingType: CroppingType;
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'AllocateToActivitiesComponentActivityFragment_CroppingActivity_Fragment';
};

type AllocateToActivitiesComponentActivityFragment_DairyLivestockActivity_Fragment =
  {
    __typename: 'DairyLivestockActivity';
    id: string;
    name: string;
    start: string;
    end: string;
  } & {
    ' $fragmentName'?: 'AllocateToActivitiesComponentActivityFragment_DairyLivestockActivity_Fragment';
  };

type AllocateToActivitiesComponentActivityFragment_DeerLivestockActivity_Fragment =
  {
    __typename: 'DeerLivestockActivity';
    id: string;
    name: string;
    start: string;
    end: string;
  } & {
    ' $fragmentName'?: 'AllocateToActivitiesComponentActivityFragment_DeerLivestockActivity_Fragment';
  };

type AllocateToActivitiesComponentActivityFragment_FeedlotActivity_Fragment = {
  __typename: 'FeedlotActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'AllocateToActivitiesComponentActivityFragment_FeedlotActivity_Fragment';
};

type AllocateToActivitiesComponentActivityFragment_GoatLivestockActivity_Fragment =
  {
    __typename: 'GoatLivestockActivity';
    id: string;
    name: string;
    start: string;
    end: string;
  } & {
    ' $fragmentName'?: 'AllocateToActivitiesComponentActivityFragment_GoatLivestockActivity_Fragment';
  };

type AllocateToActivitiesComponentActivityFragment_PorkLivestockActivity_Fragment =
  {
    __typename: 'PorkLivestockActivity';
    id: string;
    name: string;
    start: string;
    end: string;
  } & {
    ' $fragmentName'?: 'AllocateToActivitiesComponentActivityFragment_PorkLivestockActivity_Fragment';
  };

type AllocateToActivitiesComponentActivityFragment_PoultryBroilerActivity_Fragment =
  {
    __typename: 'PoultryBroilerActivity';
    id: string;
    name: string;
    start: string;
    end: string;
  } & {
    ' $fragmentName'?: 'AllocateToActivitiesComponentActivityFragment_PoultryBroilerActivity_Fragment';
  };

type AllocateToActivitiesComponentActivityFragment_PoultryLayerActivity_Fragment =
  {
    __typename: 'PoultryLayerActivity';
    id: string;
    name: string;
    start: string;
    end: string;
  } & {
    ' $fragmentName'?: 'AllocateToActivitiesComponentActivityFragment_PoultryLayerActivity_Fragment';
  };

type AllocateToActivitiesComponentActivityFragment_SheepLivestockActivity_Fragment =
  {
    __typename: 'SheepLivestockActivity';
    id: string;
    name: string;
    start: string;
    end: string;
  } & {
    ' $fragmentName'?: 'AllocateToActivitiesComponentActivityFragment_SheepLivestockActivity_Fragment';
  };

type AllocateToActivitiesComponentActivityFragment_VegetationActivity_Fragment =
  {
    __typename: 'VegetationActivity';
    id: string;
    name: string;
    start: string;
    end: string;
  } & {
    ' $fragmentName'?: 'AllocateToActivitiesComponentActivityFragment_VegetationActivity_Fragment';
  };

type AllocateToActivitiesComponentActivityFragment_WildSeaFisheryActivity_Fragment =
  {
    __typename: 'WildSeaFisheryActivity';
    id: string;
    name: string;
    start: string;
    end: string;
  } & {
    ' $fragmentName'?: 'AllocateToActivitiesComponentActivityFragment_WildSeaFisheryActivity_Fragment';
  };

export type AllocateToActivitiesComponentActivityFragmentFragment =
  | AllocateToActivitiesComponentActivityFragment_BeefLivestockActivity_Fragment
  | AllocateToActivitiesComponentActivityFragment_BuffaloLivestockActivity_Fragment
  | AllocateToActivitiesComponentActivityFragment_BurningActivity_Fragment
  | AllocateToActivitiesComponentActivityFragment_CroppingActivity_Fragment
  | AllocateToActivitiesComponentActivityFragment_DairyLivestockActivity_Fragment
  | AllocateToActivitiesComponentActivityFragment_DeerLivestockActivity_Fragment
  | AllocateToActivitiesComponentActivityFragment_FeedlotActivity_Fragment
  | AllocateToActivitiesComponentActivityFragment_GoatLivestockActivity_Fragment
  | AllocateToActivitiesComponentActivityFragment_PorkLivestockActivity_Fragment
  | AllocateToActivitiesComponentActivityFragment_PoultryBroilerActivity_Fragment
  | AllocateToActivitiesComponentActivityFragment_PoultryLayerActivity_Fragment
  | AllocateToActivitiesComponentActivityFragment_SheepLivestockActivity_Fragment
  | AllocateToActivitiesComponentActivityFragment_VegetationActivity_Fragment
  | AllocateToActivitiesComponentActivityFragment_WildSeaFisheryActivity_Fragment;

export type GetSourceActivityAllocationsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetSourceActivityAllocationsQuery = {
  __typename: 'Query';
  activity?:
    | ({ __typename: 'BeefLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesAllocationIndexFragment_BeefLivestockActivity_Fragment: PropertiesBusinessActivitiesAllocationIndexFragment_BeefLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'BuffaloLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesAllocationIndexFragment_BuffaloLivestockActivity_Fragment: PropertiesBusinessActivitiesAllocationIndexFragment_BuffaloLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'BurningActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesAllocationIndexFragment_BurningActivity_Fragment: PropertiesBusinessActivitiesAllocationIndexFragment_BurningActivity_Fragment;
        };
      })
    | ({ __typename: 'CroppingActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesAllocationIndexFragment_CroppingActivity_Fragment: PropertiesBusinessActivitiesAllocationIndexFragment_CroppingActivity_Fragment;
        };
      })
    | ({ __typename: 'DairyLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesAllocationIndexFragment_DairyLivestockActivity_Fragment: PropertiesBusinessActivitiesAllocationIndexFragment_DairyLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'DeerLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesAllocationIndexFragment_DeerLivestockActivity_Fragment: PropertiesBusinessActivitiesAllocationIndexFragment_DeerLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'FeedlotActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesAllocationIndexFragment_FeedlotActivity_Fragment: PropertiesBusinessActivitiesAllocationIndexFragment_FeedlotActivity_Fragment;
        };
      })
    | ({ __typename: 'GoatLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesAllocationIndexFragment_GoatLivestockActivity_Fragment: PropertiesBusinessActivitiesAllocationIndexFragment_GoatLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'PorkLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesAllocationIndexFragment_PorkLivestockActivity_Fragment: PropertiesBusinessActivitiesAllocationIndexFragment_PorkLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'PoultryBroilerActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesAllocationIndexFragment_PoultryBroilerActivity_Fragment: PropertiesBusinessActivitiesAllocationIndexFragment_PoultryBroilerActivity_Fragment;
        };
      })
    | ({ __typename: 'PoultryLayerActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesAllocationIndexFragment_PoultryLayerActivity_Fragment: PropertiesBusinessActivitiesAllocationIndexFragment_PoultryLayerActivity_Fragment;
        };
      })
    | ({ __typename: 'SheepLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesAllocationIndexFragment_SheepLivestockActivity_Fragment: PropertiesBusinessActivitiesAllocationIndexFragment_SheepLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'VegetationActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesAllocationIndexFragment_VegetationActivity_Fragment: PropertiesBusinessActivitiesAllocationIndexFragment_VegetationActivity_Fragment;
        };
      })
    | ({ __typename: 'WildSeaFisheryActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesAllocationIndexFragment_WildSeaFisheryActivity_Fragment: PropertiesBusinessActivitiesAllocationIndexFragment_WildSeaFisheryActivity_Fragment;
        };
      })
    | null;
};

export type GetActivitiesToAllocateQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  searchParams?: InputMaybe<ActivityQueryParamsInput>;
}>;

export type GetActivitiesToAllocateQuery = {
  __typename: 'Query';
  activities: Array<
    | ({ __typename: 'BeefLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocationIndexActivitiesFragment_BeefLivestockActivity_Fragment: AllocationIndexActivitiesFragment_BeefLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'BuffaloLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocationIndexActivitiesFragment_BuffaloLivestockActivity_Fragment: AllocationIndexActivitiesFragment_BuffaloLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'BurningActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocationIndexActivitiesFragment_BurningActivity_Fragment: AllocationIndexActivitiesFragment_BurningActivity_Fragment;
        };
      })
    | ({ __typename: 'CroppingActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocationIndexActivitiesFragment_CroppingActivity_Fragment: AllocationIndexActivitiesFragment_CroppingActivity_Fragment;
        };
      })
    | ({ __typename: 'DairyLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocationIndexActivitiesFragment_DairyLivestockActivity_Fragment: AllocationIndexActivitiesFragment_DairyLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'DeerLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocationIndexActivitiesFragment_DeerLivestockActivity_Fragment: AllocationIndexActivitiesFragment_DeerLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'FeedlotActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocationIndexActivitiesFragment_FeedlotActivity_Fragment: AllocationIndexActivitiesFragment_FeedlotActivity_Fragment;
        };
      })
    | ({ __typename: 'GoatLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocationIndexActivitiesFragment_GoatLivestockActivity_Fragment: AllocationIndexActivitiesFragment_GoatLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'PorkLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocationIndexActivitiesFragment_PorkLivestockActivity_Fragment: AllocationIndexActivitiesFragment_PorkLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'PoultryBroilerActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocationIndexActivitiesFragment_PoultryBroilerActivity_Fragment: AllocationIndexActivitiesFragment_PoultryBroilerActivity_Fragment;
        };
      })
    | ({ __typename: 'PoultryLayerActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocationIndexActivitiesFragment_PoultryLayerActivity_Fragment: AllocationIndexActivitiesFragment_PoultryLayerActivity_Fragment;
        };
      })
    | ({ __typename: 'SheepLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocationIndexActivitiesFragment_SheepLivestockActivity_Fragment: AllocationIndexActivitiesFragment_SheepLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'VegetationActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocationIndexActivitiesFragment_VegetationActivity_Fragment: AllocationIndexActivitiesFragment_VegetationActivity_Fragment;
        };
      })
    | ({ __typename: 'WildSeaFisheryActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocationIndexActivitiesFragment_WildSeaFisheryActivity_Fragment: AllocationIndexActivitiesFragment_WildSeaFisheryActivity_Fragment;
        };
      })
  >;
};

export type GetAllocationConsumptionsQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
}>;

export type GetAllocationConsumptionsQuery = {
  __typename: 'Query';
  consumptions?: Array<
    { __typename: 'Consumption' } & {
      ' $fragmentRefs'?: {
        BusinessActivitiesAllocationIndexConsumptionsFragmentFragment: BusinessActivitiesAllocationIndexConsumptionsFragmentFragment;
      };
    }
  > | null;
};

export type GetConsumptionForAllocationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetConsumptionForAllocationQuery = {
  __typename: 'Query';
  consumption?:
    | ({ __typename: 'Consumption' } & {
        ' $fragmentRefs'?: {
          BusinessActivitiesAllocationConsumptionEditFragmentFragment: BusinessActivitiesAllocationConsumptionEditFragmentFragment;
        };
      })
    | null;
};

export type ActivityTargetActivitiesQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  searchParams?: InputMaybe<ActivityQueryParamsInput>;
}>;

export type ActivityTargetActivitiesQuery = {
  __typename: 'Query';
  activities: Array<
    | ({ __typename: 'BeefLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_BeefLivestockActivity_Fragment: AllocateToActivitiesComponentActivityFragment_BeefLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'BuffaloLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_BuffaloLivestockActivity_Fragment: AllocateToActivitiesComponentActivityFragment_BuffaloLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'BurningActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_BurningActivity_Fragment: AllocateToActivitiesComponentActivityFragment_BurningActivity_Fragment;
        };
      })
    | ({ __typename: 'CroppingActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_CroppingActivity_Fragment: AllocateToActivitiesComponentActivityFragment_CroppingActivity_Fragment;
        };
      })
    | ({ __typename: 'DairyLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_DairyLivestockActivity_Fragment: AllocateToActivitiesComponentActivityFragment_DairyLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'DeerLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_DeerLivestockActivity_Fragment: AllocateToActivitiesComponentActivityFragment_DeerLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'FeedlotActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_FeedlotActivity_Fragment: AllocateToActivitiesComponentActivityFragment_FeedlotActivity_Fragment;
        };
      })
    | ({ __typename: 'GoatLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_GoatLivestockActivity_Fragment: AllocateToActivitiesComponentActivityFragment_GoatLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'PorkLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_PorkLivestockActivity_Fragment: AllocateToActivitiesComponentActivityFragment_PorkLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'PoultryBroilerActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_PoultryBroilerActivity_Fragment: AllocateToActivitiesComponentActivityFragment_PoultryBroilerActivity_Fragment;
        };
      })
    | ({ __typename: 'PoultryLayerActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_PoultryLayerActivity_Fragment: AllocateToActivitiesComponentActivityFragment_PoultryLayerActivity_Fragment;
        };
      })
    | ({ __typename: 'SheepLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_SheepLivestockActivity_Fragment: AllocateToActivitiesComponentActivityFragment_SheepLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'VegetationActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_VegetationActivity_Fragment: AllocateToActivitiesComponentActivityFragment_VegetationActivity_Fragment;
        };
      })
    | ({ __typename: 'WildSeaFisheryActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_WildSeaFisheryActivity_Fragment: AllocateToActivitiesComponentActivityFragment_WildSeaFisheryActivity_Fragment;
        };
      })
  >;
};

export type GetConsumptionTargetActivitiesQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  searchParams?: InputMaybe<ActivityQueryParamsInput>;
}>;

export type GetConsumptionTargetActivitiesQuery = {
  __typename: 'Query';
  activities: Array<
    | ({ __typename: 'BeefLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_BeefLivestockActivity_Fragment: AllocateToActivitiesComponentActivityFragment_BeefLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'BuffaloLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_BuffaloLivestockActivity_Fragment: AllocateToActivitiesComponentActivityFragment_BuffaloLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'BurningActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_BurningActivity_Fragment: AllocateToActivitiesComponentActivityFragment_BurningActivity_Fragment;
        };
      })
    | ({ __typename: 'CroppingActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_CroppingActivity_Fragment: AllocateToActivitiesComponentActivityFragment_CroppingActivity_Fragment;
        };
      })
    | ({ __typename: 'DairyLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_DairyLivestockActivity_Fragment: AllocateToActivitiesComponentActivityFragment_DairyLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'DeerLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_DeerLivestockActivity_Fragment: AllocateToActivitiesComponentActivityFragment_DeerLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'FeedlotActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_FeedlotActivity_Fragment: AllocateToActivitiesComponentActivityFragment_FeedlotActivity_Fragment;
        };
      })
    | ({ __typename: 'GoatLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_GoatLivestockActivity_Fragment: AllocateToActivitiesComponentActivityFragment_GoatLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'PorkLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_PorkLivestockActivity_Fragment: AllocateToActivitiesComponentActivityFragment_PorkLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'PoultryBroilerActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_PoultryBroilerActivity_Fragment: AllocateToActivitiesComponentActivityFragment_PoultryBroilerActivity_Fragment;
        };
      })
    | ({ __typename: 'PoultryLayerActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_PoultryLayerActivity_Fragment: AllocateToActivitiesComponentActivityFragment_PoultryLayerActivity_Fragment;
        };
      })
    | ({ __typename: 'SheepLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_SheepLivestockActivity_Fragment: AllocateToActivitiesComponentActivityFragment_SheepLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'VegetationActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_VegetationActivity_Fragment: AllocateToActivitiesComponentActivityFragment_VegetationActivity_Fragment;
        };
      })
    | ({ __typename: 'WildSeaFisheryActivity'; id: string } & {
        ' $fragmentRefs'?: {
          AllocateToActivitiesComponentActivityFragment_WildSeaFisheryActivity_Fragment: AllocateToActivitiesComponentActivityFragment_WildSeaFisheryActivity_Fragment;
        };
      })
  >;
};

export type UpdateConsumptionAllocationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateConsumptionInput: UpdateConsumptionInput;
}>;

export type UpdateConsumptionAllocationMutation = {
  __typename: 'Mutation';
  updateConsumption?:
    | ({ __typename: 'Consumption' } & {
        ' $fragmentRefs'?: {
          BusinessActivitiesConsumptionIndexFragmentFragment: BusinessActivitiesConsumptionIndexFragmentFragment;
        };
      })
    | null;
};

export type UpdateCropAllocationsMutationVariables = Exact<{
  sourceActivityId: Scalars['ID']['input'];
  updateActivityAllocationsInput: UpdateActivityAllocationsInput;
}>;

export type UpdateCropAllocationsMutation = {
  __typename: 'Mutation';
  updateActivityAllocations: Array<{
    __typename: 'ActivityAllocation';
    percentage: number;
    sourceActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
    targetActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
  } | null>;
};

export type BusinessActivitiesConsumptionIndexFragmentFragment = {
  __typename: 'Consumption';
  id: string;
  startDate?: string | null;
  endDate?: string | null;
  consumableType: ConsumableType;
  consumableSubType: ConsumableSubType;
  amount: number;
  name?: string | null;
  notes?: string | null;
  consumptionAllocations: Array<{
    __typename: 'ConsumptionAllocation';
    percentage: number;
    targetActivity:
      | { __typename: 'BeefLivestockActivity'; id: string }
      | { __typename: 'BuffaloLivestockActivity'; id: string }
      | { __typename: 'BurningActivity'; id: string }
      | { __typename: 'CroppingActivity'; id: string }
      | { __typename: 'DairyLivestockActivity'; id: string }
      | { __typename: 'DeerLivestockActivity'; id: string }
      | { __typename: 'FeedlotActivity'; id: string }
      | { __typename: 'GoatLivestockActivity'; id: string }
      | { __typename: 'PorkLivestockActivity'; id: string }
      | { __typename: 'PoultryBroilerActivity'; id: string }
      | { __typename: 'PoultryLayerActivity'; id: string }
      | { __typename: 'SheepLivestockActivity'; id: string }
      | { __typename: 'VegetationActivity'; id: string }
      | { __typename: 'WildSeaFisheryActivity'; id: string };
    sourceConsumption: { __typename: 'Consumption'; id: string };
  }>;
} & { ' $fragmentName'?: 'BusinessActivitiesConsumptionIndexFragmentFragment' };

export type UpdateConsumptionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateConsumptionInput: UpdateConsumptionInput;
}>;

export type UpdateConsumptionMutation = {
  __typename: 'Mutation';
  updateConsumption?:
    | ({ __typename: 'Consumption'; id: string } & {
        ' $fragmentRefs'?: {
          BusinessActivitiesConsumptionIndexFragmentFragment: BusinessActivitiesConsumptionIndexFragmentFragment;
        };
      })
    | null;
};

export type CreateConsumptionMutationVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  createConsumptionInput: CreateConsumptionInput;
}>;

export type CreateConsumptionMutation = {
  __typename: 'Mutation';
  createConsumption?:
    | ({ __typename: 'Consumption'; id: string } & {
        ' $fragmentRefs'?: {
          BusinessActivitiesConsumptionIndexFragmentFragment: BusinessActivitiesConsumptionIndexFragmentFragment;
        };
      })
    | null;
};

export type DeleteConsumptionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteConsumptionMutation = {
  __typename: 'Mutation';
  deleteConsumption?: { __typename: 'Consumption'; id: string } | null;
};

export type GetConsumptionsQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
}>;

export type GetConsumptionsQuery = {
  __typename: 'Query';
  consumptions?: Array<
    { __typename: 'Consumption'; id: string } & {
      ' $fragmentRefs'?: {
        BusinessActivitiesConsumptionIndexConsumptionsFragmentFragment: BusinessActivitiesConsumptionIndexConsumptionsFragmentFragment;
      };
    }
  > | null;
};

export type GetConsumptionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetConsumptionQuery = {
  __typename: 'Query';
  consumption?:
    | ({ __typename: 'Consumption'; id: string } & {
        ' $fragmentRefs'?: {
          BusinessActivitiesConsumptionIndexFragmentFragment: BusinessActivitiesConsumptionIndexFragmentFragment;
        };
      })
    | null;
};

export type BusinessActivitiesConsumptionIndexConsumptionsFragmentFragment = {
  __typename: 'Consumption';
  id: string;
  startDate?: string | null;
  endDate?: string | null;
  consumableType: ConsumableType;
  consumableSubType: ConsumableSubType;
  amount: number;
  name?: string | null;
} & {
  ' $fragmentName'?: 'BusinessActivitiesConsumptionIndexConsumptionsFragmentFragment';
};

type ActivitiesActivityEditFragment_BeefLivestockActivity_Fragment = {
  __typename: 'BeefLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'ActivitiesActivityEditFragment_BeefLivestockActivity_Fragment';
};

type ActivitiesActivityEditFragment_BuffaloLivestockActivity_Fragment = {
  __typename: 'BuffaloLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'ActivitiesActivityEditFragment_BuffaloLivestockActivity_Fragment';
};

type ActivitiesActivityEditFragment_BurningActivity_Fragment = {
  __typename: 'BurningActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'ActivitiesActivityEditFragment_BurningActivity_Fragment';
};

type ActivitiesActivityEditFragment_CroppingActivity_Fragment = {
  __typename: 'CroppingActivity';
  croppingType: CroppingType;
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'ActivitiesActivityEditFragment_CroppingActivity_Fragment';
};

type ActivitiesActivityEditFragment_DairyLivestockActivity_Fragment = {
  __typename: 'DairyLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'ActivitiesActivityEditFragment_DairyLivestockActivity_Fragment';
};

type ActivitiesActivityEditFragment_DeerLivestockActivity_Fragment = {
  __typename: 'DeerLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'ActivitiesActivityEditFragment_DeerLivestockActivity_Fragment';
};

type ActivitiesActivityEditFragment_FeedlotActivity_Fragment = {
  __typename: 'FeedlotActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'ActivitiesActivityEditFragment_FeedlotActivity_Fragment';
};

type ActivitiesActivityEditFragment_GoatLivestockActivity_Fragment = {
  __typename: 'GoatLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'ActivitiesActivityEditFragment_GoatLivestockActivity_Fragment';
};

type ActivitiesActivityEditFragment_PorkLivestockActivity_Fragment = {
  __typename: 'PorkLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'ActivitiesActivityEditFragment_PorkLivestockActivity_Fragment';
};

type ActivitiesActivityEditFragment_PoultryBroilerActivity_Fragment = {
  __typename: 'PoultryBroilerActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'ActivitiesActivityEditFragment_PoultryBroilerActivity_Fragment';
};

type ActivitiesActivityEditFragment_PoultryLayerActivity_Fragment = {
  __typename: 'PoultryLayerActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'ActivitiesActivityEditFragment_PoultryLayerActivity_Fragment';
};

type ActivitiesActivityEditFragment_SheepLivestockActivity_Fragment = {
  __typename: 'SheepLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'ActivitiesActivityEditFragment_SheepLivestockActivity_Fragment';
};

type ActivitiesActivityEditFragment_VegetationActivity_Fragment = {
  __typename: 'VegetationActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'ActivitiesActivityEditFragment_VegetationActivity_Fragment';
};

type ActivitiesActivityEditFragment_WildSeaFisheryActivity_Fragment = {
  __typename: 'WildSeaFisheryActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'ActivitiesActivityEditFragment_WildSeaFisheryActivity_Fragment';
};

export type ActivitiesActivityEditFragmentFragment =
  | ActivitiesActivityEditFragment_BeefLivestockActivity_Fragment
  | ActivitiesActivityEditFragment_BuffaloLivestockActivity_Fragment
  | ActivitiesActivityEditFragment_BurningActivity_Fragment
  | ActivitiesActivityEditFragment_CroppingActivity_Fragment
  | ActivitiesActivityEditFragment_DairyLivestockActivity_Fragment
  | ActivitiesActivityEditFragment_DeerLivestockActivity_Fragment
  | ActivitiesActivityEditFragment_FeedlotActivity_Fragment
  | ActivitiesActivityEditFragment_GoatLivestockActivity_Fragment
  | ActivitiesActivityEditFragment_PorkLivestockActivity_Fragment
  | ActivitiesActivityEditFragment_PoultryBroilerActivity_Fragment
  | ActivitiesActivityEditFragment_PoultryLayerActivity_Fragment
  | ActivitiesActivityEditFragment_SheepLivestockActivity_Fragment
  | ActivitiesActivityEditFragment_VegetationActivity_Fragment
  | ActivitiesActivityEditFragment_WildSeaFisheryActivity_Fragment;

export type GetActivityQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetActivityQuery = {
  __typename: 'Query';
  activity?:
    | ({ __typename: 'BeefLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesActivityEditFragment_BeefLivestockActivity_Fragment: ActivitiesActivityEditFragment_BeefLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'BuffaloLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesActivityEditFragment_BuffaloLivestockActivity_Fragment: ActivitiesActivityEditFragment_BuffaloLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'BurningActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesActivityEditFragment_BurningActivity_Fragment: ActivitiesActivityEditFragment_BurningActivity_Fragment;
        };
      })
    | ({ __typename: 'CroppingActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesActivityEditFragment_CroppingActivity_Fragment: ActivitiesActivityEditFragment_CroppingActivity_Fragment;
        };
      })
    | ({ __typename: 'DairyLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesActivityEditFragment_DairyLivestockActivity_Fragment: ActivitiesActivityEditFragment_DairyLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'DeerLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesActivityEditFragment_DeerLivestockActivity_Fragment: ActivitiesActivityEditFragment_DeerLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'FeedlotActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesActivityEditFragment_FeedlotActivity_Fragment: ActivitiesActivityEditFragment_FeedlotActivity_Fragment;
        };
      })
    | ({ __typename: 'GoatLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesActivityEditFragment_GoatLivestockActivity_Fragment: ActivitiesActivityEditFragment_GoatLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'PorkLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesActivityEditFragment_PorkLivestockActivity_Fragment: ActivitiesActivityEditFragment_PorkLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'PoultryBroilerActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesActivityEditFragment_PoultryBroilerActivity_Fragment: ActivitiesActivityEditFragment_PoultryBroilerActivity_Fragment;
        };
      })
    | ({ __typename: 'PoultryLayerActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesActivityEditFragment_PoultryLayerActivity_Fragment: ActivitiesActivityEditFragment_PoultryLayerActivity_Fragment;
        };
      })
    | ({ __typename: 'SheepLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesActivityEditFragment_SheepLivestockActivity_Fragment: ActivitiesActivityEditFragment_SheepLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'VegetationActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesActivityEditFragment_VegetationActivity_Fragment: ActivitiesActivityEditFragment_VegetationActivity_Fragment;
        };
      })
    | ({ __typename: 'WildSeaFisheryActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesActivityEditFragment_WildSeaFisheryActivity_Fragment: ActivitiesActivityEditFragment_WildSeaFisheryActivity_Fragment;
        };
      })
    | null;
};

export type UpdateActivityMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateActivityInput: UpdateActivityInput;
}>;

export type UpdateActivityMutation = {
  __typename: 'Mutation';
  updateActivity?:
    | { __typename: 'BeefLivestockActivity'; id: string }
    | { __typename: 'BuffaloLivestockActivity'; id: string }
    | { __typename: 'BurningActivity'; id: string }
    | { __typename: 'CroppingActivity'; id: string }
    | { __typename: 'DairyLivestockActivity'; id: string }
    | { __typename: 'DeerLivestockActivity'; id: string }
    | { __typename: 'FeedlotActivity'; id: string }
    | { __typename: 'GoatLivestockActivity'; id: string }
    | { __typename: 'PorkLivestockActivity'; id: string }
    | { __typename: 'PoultryBroilerActivity'; id: string }
    | { __typename: 'PoultryLayerActivity'; id: string }
    | { __typename: 'SheepLivestockActivity'; id: string }
    | { __typename: 'VegetationActivity'; id: string }
    | { __typename: 'WildSeaFisheryActivity'; id: string }
    | null;
};

export type DeleteActivityMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteActivityMutation = {
  __typename: 'Mutation';
  deleteActivity?:
    | { __typename: 'BeefLivestockActivity'; id: string }
    | { __typename: 'BuffaloLivestockActivity'; id: string }
    | { __typename: 'BurningActivity'; id: string }
    | { __typename: 'CroppingActivity'; id: string }
    | { __typename: 'DairyLivestockActivity'; id: string }
    | { __typename: 'DeerLivestockActivity'; id: string }
    | { __typename: 'FeedlotActivity'; id: string }
    | { __typename: 'GoatLivestockActivity'; id: string }
    | { __typename: 'PorkLivestockActivity'; id: string }
    | { __typename: 'PoultryBroilerActivity'; id: string }
    | { __typename: 'PoultryLayerActivity'; id: string }
    | { __typename: 'SheepLivestockActivity'; id: string }
    | { __typename: 'VegetationActivity'; id: string }
    | { __typename: 'WildSeaFisheryActivity'; id: string }
    | null;
};

export type BeefMonthlyForm_BeefMonthLivestockActivityFragment = {
  __typename: 'BeefMonthLivestockActivity';
  id: string;
  month: Month;
  year: number;
  averageLiveweightKg: number;
  previousMonthLiveweightKg?: number | null;
  nonSalePopulationChange: number;
  percentageCowsCalving: Decimal;
  keyEvents?: string | null;
  headAtStart: number;
  livestockTransactionEvents: Array<{
    __typename: 'LivestockTransactionEvent';
    id: string;
    transactionType: TransactionType;
    livestockCount: number;
  } | null>;
} & { ' $fragmentName'?: 'BeefMonthlyForm_BeefMonthLivestockActivityFragment' };

export type UpdateTransaction_BeefMonthLivestockActivityFragmentFragment = ({
  __typename: 'BeefMonthLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    TransactionForm_BeefMonthLivestockActivityFragmentFragment: TransactionForm_BeefMonthLivestockActivityFragmentFragment;
  };
}) & {
  ' $fragmentName'?: 'UpdateTransaction_BeefMonthLivestockActivityFragmentFragment';
};

export type UpdateTransaction_LivestockTransactionEventFragmentFragment = ({
  __typename: 'LivestockTransactionEvent';
} & {
  ' $fragmentRefs'?: {
    TransactionForm_LivestockTransactionEventFragmentFragment: TransactionForm_LivestockTransactionEventFragmentFragment;
  };
}) & {
  ' $fragmentName'?: 'UpdateTransaction_LivestockTransactionEventFragmentFragment';
};

export type CreateTransaction_BeefMonthLivestockActivityFragmentFragment = ({
  __typename: 'BeefMonthLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    TransactionForm_BeefMonthLivestockActivityFragmentFragment: TransactionForm_BeefMonthLivestockActivityFragmentFragment;
  };
}) & {
  ' $fragmentName'?: 'CreateTransaction_BeefMonthLivestockActivityFragmentFragment';
};

export type TransactionForm_BeefMonthLivestockActivityFragmentFragment = {
  __typename: 'BeefMonthLivestockActivity';
  year: number;
  month: Month;
  beefClass: BeefClass;
} & {
  ' $fragmentName'?: 'TransactionForm_BeefMonthLivestockActivityFragmentFragment';
};

export type UpdateBeefMonthLivestockActivityForMonthlyBreakdownMutationVariables =
  Exact<{
    id: Scalars['ID']['input'];
    updateBeefMonthLivestockActivityInput: UpdateBeefMonthLivestockActivityInput;
  }>;

export type UpdateBeefMonthLivestockActivityForMonthlyBreakdownMutation = {
  __typename: 'Mutation';
  updateBeefMonthLivestockActivity: {
    __typename: 'BeefMonthLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      BeefMonthlyBreakdown_BeefMonthLivestockActivityFragment: BeefMonthlyBreakdown_BeefMonthLivestockActivityFragment;
    };
  };
};

export type CreateBeefLivestockTransactionEventForUpdateTransactionMutationVariables =
  Exact<{
    beefMonthLivestockActivityId: Scalars['ID']['input'];
    createLivestockTransactionEventInput: CreateLivestockTransactionEventInput;
  }>;

export type CreateBeefLivestockTransactionEventForUpdateTransactionMutation = {
  __typename: 'Mutation';
  createBeefLivestockTransactionEvent: {
    __typename: 'LivestockTransactionEvent';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      UpdateTransaction_LivestockTransactionEventFragmentFragment: UpdateTransaction_LivestockTransactionEventFragmentFragment;
    };
  };
};

export type GetBeefActivityForMonthlyBreakdownQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetBeefActivityForMonthlyBreakdownQuery = {
  __typename: 'Query';
  beefLivestockActivity?:
    | ({ __typename: 'BeefLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          BeefMonthlyBreakdown_BeefLivestockActivityFragmentFragment: BeefMonthlyBreakdown_BeefLivestockActivityFragmentFragment;
        };
      })
    | null;
};

export type GetBeefMonthLivestockActivitiesForMonthlyBreakdownQueryVariables =
  Exact<{
    listBeefMonthLivestockActivitiesInput: ListBeefMonthLivestockActivitiesInput;
  }>;

export type GetBeefMonthLivestockActivitiesForMonthlyBreakdownQuery = {
  __typename: 'Query';
  beefMonthLivestockActivities: Array<
    | ({ __typename: 'BeefMonthLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          BeefMonthlyBreakdown_BeefMonthLivestockActivityFragment: BeefMonthlyBreakdown_BeefMonthLivestockActivityFragment;
        };
      })
    | null
  >;
};

export type GetBeefMonthLivestockActivityForCreateUpdateTransactionQueryVariables =
  Exact<{
    id: Scalars['ID']['input'];
  }>;

export type GetBeefMonthLivestockActivityForCreateUpdateTransactionQuery = {
  __typename: 'Query';
  beefMonthLivestockActivity: {
    __typename: 'BeefMonthLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      CreateTransaction_BeefMonthLivestockActivityFragmentFragment: CreateTransaction_BeefMonthLivestockActivityFragmentFragment;
      UpdateTransaction_BeefMonthLivestockActivityFragmentFragment: UpdateTransaction_BeefMonthLivestockActivityFragmentFragment;
    };
  };
};

export type BeefMonthlyBreakdown_BeefLivestockActivityFragmentFragment = {
  __typename: 'BeefLivestockActivity';
  bullsGt1Breeding: boolean;
  cowsGt2Breeding: boolean;
  heifersLt1Breeding: boolean;
  heifers1to2Breeding: boolean;
  heifersGt2Breeding: boolean;
  steersLt1Breeding: boolean;
  steers1to2Breeding: boolean;
  steersGt2Breeding: boolean;
  bullsGt1Trading: boolean;
  cowsGt2Trading: boolean;
  heifersLt1Trading: boolean;
  heifers1to2Trading: boolean;
  heifersGt2Trading: boolean;
  steersLt1Trading: boolean;
  steers1to2Trading: boolean;
  steersGt2Trading: boolean;
} & {
  ' $fragmentName'?: 'BeefMonthlyBreakdown_BeefLivestockActivityFragmentFragment';
};

export type BeefMonthlyBreakdown_BeefMonthLivestockActivityFragment = ({
  __typename: 'BeefMonthLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    BeefMonthlyForm_BeefMonthLivestockActivityFragment: BeefMonthlyForm_BeefMonthLivestockActivityFragment;
  };
}) & {
  ' $fragmentName'?: 'BeefMonthlyBreakdown_BeefMonthLivestockActivityFragment';
};

export type BeefActivityLivestockBreedingBeefLivestockActivityFragment = {
  __typename: 'BeefLivestockActivity';
  bullsGt1Breeding: boolean;
  cowsGt2Breeding: boolean;
  heifersLt1Breeding: boolean;
  heifers1to2Breeding: boolean;
  heifersGt2Breeding: boolean;
  steersLt1Breeding: boolean;
  steers1to2Breeding: boolean;
  steersGt2Breeding: boolean;
} & {
  ' $fragmentName'?: 'BeefActivityLivestockBreedingBeefLivestockActivityFragment';
};

export type BeefActivityLivestockTradingBeefLivestockActivityFragment = {
  __typename: 'BeefLivestockActivity';
  bullsGt1Trading: boolean;
  cowsGt2Trading: boolean;
  heifersLt1Trading: boolean;
  heifers1to2Trading: boolean;
  heifersGt2Trading: boolean;
  steersLt1Trading: boolean;
  steers1to2Trading: boolean;
  steersGt2Trading: boolean;
} & {
  ' $fragmentName'?: 'BeefActivityLivestockTradingBeefLivestockActivityFragment';
};

export type BeefActivityLivestockBeefLivestockActivityFragment = ({
  __typename: 'BeefLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    BeefActivityLivestockBreedingBeefLivestockActivityFragment: BeefActivityLivestockBreedingBeefLivestockActivityFragment;
    BeefActivityLivestockTradingBeefLivestockActivityFragment: BeefActivityLivestockTradingBeefLivestockActivityFragment;
  };
}) & {
  ' $fragmentName'?: 'BeefActivityLivestockBeefLivestockActivityFragment';
};

export type UseBeefMonthlyFeedBreakdown_ActivityFragmentFragment = {
  __typename: 'BeefLivestockActivity';
  bullsGt1Breeding: boolean;
  cowsGt2Breeding: boolean;
  heifersLt1Breeding: boolean;
  heifers1to2Breeding: boolean;
  heifersGt2Breeding: boolean;
  steersLt1Breeding: boolean;
  steers1to2Breeding: boolean;
  steersGt2Breeding: boolean;
  bullsGt1Trading: boolean;
  cowsGt2Trading: boolean;
  heifersLt1Trading: boolean;
  heifers1to2Trading: boolean;
  heifersGt2Trading: boolean;
  steersLt1Trading: boolean;
  steers1to2Trading: boolean;
  steersGt2Trading: boolean;
  businessActivity: {
    __typename: 'BusinessActivity';
    id: string;
    state: StateRegion;
  };
} & {
  ' $fragmentName'?: 'UseBeefMonthlyFeedBreakdown_ActivityFragmentFragment';
};

export type UseBeefMonthlyFeedBreakdown_BeefMonthLivestockActivityFragment = {
  __typename: 'BeefMonthLivestockActivity';
  id: string;
  month: Month;
  year: number;
  crudeProteinPercentage: Decimal;
  dryMatterDigestibilityPercentage: Decimal;
} & {
  ' $fragmentName'?: 'UseBeefMonthlyFeedBreakdown_BeefMonthLivestockActivityFragment';
};

export type UpdateBeefMonthLivestockActivityForMonthlyFeedBreakdownMutationVariables =
  Exact<{
    id: Scalars['ID']['input'];
    updateBeefMonthLivestockActivityInput: UpdateBeefMonthLivestockActivityInput;
  }>;

export type UpdateBeefMonthLivestockActivityForMonthlyFeedBreakdownMutation = {
  __typename: 'Mutation';
  updateBeefMonthLivestockActivity: {
    __typename: 'BeefMonthLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      UseBeefMonthlyFeedBreakdown_BeefMonthLivestockActivityFragment: UseBeefMonthlyFeedBreakdown_BeefMonthLivestockActivityFragment;
    };
  };
};

export type GetBeefActivityForMonthlyFeedBreakdownQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetBeefActivityForMonthlyFeedBreakdownQuery = {
  __typename: 'Query';
  beefLivestockActivity?:
    | ({ __typename: 'BeefLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          UseBeefMonthlyFeedBreakdown_ActivityFragmentFragment: UseBeefMonthlyFeedBreakdown_ActivityFragmentFragment;
        };
      })
    | null;
};

export type GetBeefMonthLivestockActivitiesForMonthlyFeedBreakdownQueryVariables =
  Exact<{
    listBeefMonthLivestockActivitiesInput: ListBeefMonthLivestockActivitiesInput;
  }>;

export type GetBeefMonthLivestockActivitiesForMonthlyFeedBreakdownQuery = {
  __typename: 'Query';
  beefMonthLivestockActivities: Array<
    | ({ __typename: 'BeefMonthLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          UseBeefMonthlyFeedBreakdown_BeefMonthLivestockActivityFragment: UseBeefMonthlyFeedBreakdown_BeefMonthLivestockActivityFragment;
        };
      })
    | null
  >;
};

export type GetBeefLivestockActivityFeedDrySeasonMixQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetBeefLivestockActivityFeedDrySeasonMixQuery = {
  __typename: 'Query';
  beefLivestockActivity?:
    | ({ __typename: 'BeefLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          BeefFeedDrySeasonMix_BeefLivestockActivityFragmentFragment: BeefFeedDrySeasonMix_BeefLivestockActivityFragmentFragment;
        };
      })
    | null;
};

export type BeefFeedDrySeasonMix_BeefLivestockActivityFragmentFragment = {
  __typename: 'BeefLivestockActivity';
  feed?:
    | ({ __typename: 'Feed' } & {
        ' $fragmentRefs'?: {
          FeedDrySeasonMix_FeedFragmentFragment: FeedDrySeasonMix_FeedFragmentFragment;
        };
      })
    | null;
} & {
  ' $fragmentName'?: 'BeefFeedDrySeasonMix_BeefLivestockActivityFragmentFragment';
};

export type GetBeefActivityFeedQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetBeefActivityFeedQuery = {
  __typename: 'Query';
  beefLivestockActivity?:
    | ({ __typename: 'BeefLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          BeefActivityLivestockFeed_BeefLivestockActivityFragmentFragment: BeefActivityLivestockFeed_BeefLivestockActivityFragmentFragment;
        };
      })
    | null;
};

export type BeefActivityLivestockFeed_BeefLivestockActivityFragmentFragment = {
  __typename: 'BeefLivestockActivity';
  id: string;
  bullsGt1Breeding: boolean;
  cowsGt2Breeding: boolean;
  heifersLt1Breeding: boolean;
  heifers1to2Breeding: boolean;
  heifersGt2Breeding: boolean;
  steersLt1Breeding: boolean;
  steers1to2Breeding: boolean;
  steersGt2Breeding: boolean;
  bullsGt1Trading: boolean;
  cowsGt2Trading: boolean;
  heifersLt1Trading: boolean;
  heifers1to2Trading: boolean;
  heifersGt2Trading: boolean;
  steersLt1Trading: boolean;
  steers1to2Trading: boolean;
  steersGt2Trading: boolean;
  feed?: {
    __typename: 'Feed';
    id: string;
    pasture: boolean;
    grazingCrops: boolean;
    mineralBlock: boolean;
    weanerBlock: boolean;
    drySeasonMix: boolean;
  } | null;
} & {
  ' $fragmentName'?: 'BeefActivityLivestockFeed_BeefLivestockActivityFragmentFragment';
};

export type GetBeefLivestockActivityFeedMineralBlockQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetBeefLivestockActivityFeedMineralBlockQuery = {
  __typename: 'Query';
  beefLivestockActivity?:
    | ({ __typename: 'BeefLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          BeefFeedMineralBlock_BeefLivestockActivityFragmentFragment: BeefFeedMineralBlock_BeefLivestockActivityFragmentFragment;
        };
      })
    | null;
};

export type BeefFeedMineralBlock_BeefLivestockActivityFragmentFragment = {
  __typename: 'BeefLivestockActivity';
  feed?:
    | ({ __typename: 'Feed' } & {
        ' $fragmentRefs'?: {
          FeedMineralBlock_FeedFragmentFragment: FeedMineralBlock_FeedFragmentFragment;
        };
      })
    | null;
} & {
  ' $fragmentName'?: 'BeefFeedMineralBlock_BeefLivestockActivityFragmentFragment';
};

export type GetBeefLivestockActivityFeedWeanerQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetBeefLivestockActivityFeedWeanerQuery = {
  __typename: 'Query';
  beefLivestockActivity?:
    | ({ __typename: 'BeefLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          BeefFeedWeanerBlock_BeefLivestockActivityFragmentFragment: BeefFeedWeanerBlock_BeefLivestockActivityFragmentFragment;
        };
      })
    | null;
};

export type BeefFeedWeanerBlock_BeefLivestockActivityFragmentFragment = {
  __typename: 'BeefLivestockActivity';
  feed?:
    | ({ __typename: 'Feed' } & {
        ' $fragmentRefs'?: {
          FeedWeanerBlock_FeedFragmentFragment: FeedWeanerBlock_FeedFragmentFragment;
        };
      })
    | null;
} & {
  ' $fragmentName'?: 'BeefFeedWeanerBlock_BeefLivestockActivityFragmentFragment';
};

export type UpdateBeefLivestockActivityMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateBeefLivestockActivityInput: UpdateBeefLivestockActivityInput;
}>;

export type UpdateBeefLivestockActivityMutation = {
  __typename: 'Mutation';
  updateBeefLivestockActivity: {
    __typename: 'BeefLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      BeefActivityLivestockBreedingBeefLivestockActivityFragment: BeefActivityLivestockBreedingBeefLivestockActivityFragment;
      BeefActivityLivestockTradingBeefLivestockActivityFragment: BeefActivityLivestockTradingBeefLivestockActivityFragment;
    };
  };
};

export type GetBeefActivityQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetBeefActivityQuery = {
  __typename: 'Query';
  beefLivestockActivity?:
    | ({ __typename: 'BeefLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          BeefActivityLivestockBeefLivestockActivityFragment: BeefActivityLivestockBeefLivestockActivityFragment;
        };
      })
    | null;
};

export type GetBeefActivityInHeaderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetBeefActivityInHeaderQuery = {
  __typename: 'Query';
  beefLivestockActivity?:
    | ({ __typename: 'BeefLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesActivitiesHeaderBeefActivityFragment: PropertiesBusinessActivitiesActivitiesHeaderBeefActivityFragment;
        };
      })
    | null;
};

export type GetBeefMonthLivestockActivitiesForActivityQueryVariables = Exact<{
  listBeefMonthLivestockActivitiesInput: ListBeefMonthLivestockActivitiesInput;
}>;

export type GetBeefMonthLivestockActivitiesForActivityQuery = {
  __typename: 'Query';
  beefMonthLivestockActivities: Array<
    | ({ __typename: 'BeefMonthLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          BeefLivestockActivity_MonthActivitiesFragment: BeefLivestockActivity_MonthActivitiesFragment;
        };
      })
    | null
  >;
};

export type PropertiesBusinessActivitiesActivitiesHeaderBeefActivityFragment = {
  __typename: 'BeefLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'PropertiesBusinessActivitiesActivitiesHeaderBeefActivityFragment';
};

export type BeefLivestockActivity_MonthActivitiesFragment = {
  __typename: 'BeefMonthLivestockActivity';
  id: string;
  month: Month;
  year: number;
  averageLiveweightKg: number;
  headAtStart: number;
  beefClass: BeefClass;
} & { ' $fragmentName'?: 'BeefLivestockActivity_MonthActivitiesFragment' };

export type CreateBeefLivestockActivityMutationVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  createBeefLivestockActivityInput: CreateBeefLivestockActivityInput;
}>;

export type CreateBeefLivestockActivityMutation = {
  __typename: 'Mutation';
  createBeefLivestockActivity: {
    __typename: 'BeefLivestockActivity';
    id: string;
  };
};

export type BuffaloMonthlyForm_BuffaloMonthLivestockActivityFragment = {
  __typename: 'BuffaloMonthLivestockActivity';
  id: string;
  month: Month;
  year: number;
  nonSalePopulationChange: number;
  percentageCowsCalving: Decimal;
  seasonalCalvingRate: Decimal;
  keyEvents?: string | null;
  headAtStart: number;
  livestockTransactionEvents: Array<{
    __typename: 'LivestockTransactionEvent';
    id: string;
    transactionType: TransactionType;
    livestockCount: number;
  }>;
} & {
  ' $fragmentName'?: 'BuffaloMonthlyForm_BuffaloMonthLivestockActivityFragment';
};

export type UpdateTransaction_BuffaloMonthLivestockActivityFragmentFragment = ({
  __typename: 'BuffaloMonthLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    TransactionForm_BuffaloMonthLivestockActivityFragmentFragment: TransactionForm_BuffaloMonthLivestockActivityFragmentFragment;
  };
}) & {
  ' $fragmentName'?: 'UpdateTransaction_BuffaloMonthLivestockActivityFragmentFragment';
};

export type CreateTransaction_BuffaloMonthLivestockActivityFragmentFragment = ({
  __typename: 'BuffaloMonthLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    TransactionForm_BuffaloMonthLivestockActivityFragmentFragment: TransactionForm_BuffaloMonthLivestockActivityFragmentFragment;
  };
}) & {
  ' $fragmentName'?: 'CreateTransaction_BuffaloMonthLivestockActivityFragmentFragment';
};

export type TransactionForm_BuffaloMonthLivestockActivityFragmentFragment = {
  __typename: 'BuffaloMonthLivestockActivity';
  year: number;
  month: Month;
  buffaloClass: BuffaloClass;
} & {
  ' $fragmentName'?: 'TransactionForm_BuffaloMonthLivestockActivityFragmentFragment';
};

export type UpdateBuffaloMonthLivestockActivityForMonthlyBreakdownMutationVariables =
  Exact<{
    id: Scalars['ID']['input'];
    updateBuffaloMonthLivestockActivityInput: UpdateBuffaloMonthLivestockActivityInput;
  }>;

export type UpdateBuffaloMonthLivestockActivityForMonthlyBreakdownMutation = {
  __typename: 'Mutation';
  updateBuffaloMonthLivestockActivity: {
    __typename: 'BuffaloMonthLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      BuffaloMonthlyBreakdown_BuffaloMonthLivestockActivityFragment: BuffaloMonthlyBreakdown_BuffaloMonthLivestockActivityFragment;
    };
  };
};

export type CreateBuffaloLivestockTransactionEventForUpdateTransactionMutationVariables =
  Exact<{
    buffaloMonthLivestockActivityId: Scalars['ID']['input'];
    createLivestockTransactionEventInput: CreateLivestockTransactionEventInput;
  }>;

export type CreateBuffaloLivestockTransactionEventForUpdateTransactionMutation =
  {
    __typename: 'Mutation';
    createBuffaloLivestockTransactionEvent: {
      __typename: 'LivestockTransactionEvent';
      id: string;
    } & {
      ' $fragmentRefs'?: {
        UpdateTransaction_LivestockTransactionEventFragmentFragment: UpdateTransaction_LivestockTransactionEventFragmentFragment;
      };
    };
  };

export type UpdateLivestockTransactionEventForUpdateTransactionMutationVariables =
  Exact<{
    updateLivestockTransactionEventInput: UpdateLivestockTransactionEventInput;
  }>;

export type UpdateLivestockTransactionEventForUpdateTransactionMutation = {
  __typename: 'Mutation';
  updateLivestockTransactionEvent: {
    __typename: 'LivestockTransactionEvent';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      UpdateTransaction_LivestockTransactionEventFragmentFragment: UpdateTransaction_LivestockTransactionEventFragmentFragment;
    };
  };
};

export type GetBuffaloActivityForMonthlyBreakdownQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetBuffaloActivityForMonthlyBreakdownQuery = {
  __typename: 'Query';
  buffaloLivestockActivity?:
    | ({ __typename: 'BuffaloLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          BuffaloMonthlyBreakdown_BuffaloLivestockActivityFragmentFragment: BuffaloMonthlyBreakdown_BuffaloLivestockActivityFragmentFragment;
        };
      })
    | null;
};

export type GetBuffaloMonthLivestockActivitiesForMonthlyBreakdownQueryVariables =
  Exact<{
    listBuffaloMonthLivestockActivitiesInput: ListBuffaloMonthLivestockActivitiesInput;
  }>;

export type GetBuffaloMonthLivestockActivitiesForMonthlyBreakdownQuery = {
  __typename: 'Query';
  buffaloMonthLivestockActivities: Array<
    | ({ __typename: 'BuffaloMonthLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          BuffaloMonthlyBreakdown_BuffaloMonthLivestockActivityFragment: BuffaloMonthlyBreakdown_BuffaloMonthLivestockActivityFragment;
        };
      })
    | null
  >;
};

export type GetBuffaloMonthLivestockActivityForCreateUpdateTransactionQueryVariables =
  Exact<{
    id: Scalars['ID']['input'];
  }>;

export type GetBuffaloMonthLivestockActivityForCreateUpdateTransactionQuery = {
  __typename: 'Query';
  buffaloMonthLivestockActivity: {
    __typename: 'BuffaloMonthLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      CreateTransaction_BuffaloMonthLivestockActivityFragmentFragment: CreateTransaction_BuffaloMonthLivestockActivityFragmentFragment;
      UpdateTransaction_BuffaloMonthLivestockActivityFragmentFragment: UpdateTransaction_BuffaloMonthLivestockActivityFragmentFragment;
    };
  };
};

export type BuffaloMonthlyBreakdown_BuffaloLivestockActivityFragmentFragment = {
  __typename: 'BuffaloLivestockActivity';
  bullsBreeding: boolean;
  cowsBreeding: boolean;
  steersBreeding: boolean;
  calfsBreeding: boolean;
  bullsTrading: boolean;
  cowsTrading: boolean;
  steersTrading: boolean;
  calfsTrading: boolean;
} & {
  ' $fragmentName'?: 'BuffaloMonthlyBreakdown_BuffaloLivestockActivityFragmentFragment';
};

export type BuffaloMonthlyBreakdown_BuffaloMonthLivestockActivityFragment = ({
  __typename: 'BuffaloMonthLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    BuffaloMonthlyForm_BuffaloMonthLivestockActivityFragment: BuffaloMonthlyForm_BuffaloMonthLivestockActivityFragment;
  };
}) & {
  ' $fragmentName'?: 'BuffaloMonthlyBreakdown_BuffaloMonthLivestockActivityFragment';
};

export type BuffaloActivityLivestockBreedingBuffaloLivestockActivityFragment = {
  __typename: 'BuffaloLivestockActivity';
  bullsBreeding: boolean;
  cowsBreeding: boolean;
  steersBreeding: boolean;
  calfsBreeding: boolean;
} & {
  ' $fragmentName'?: 'BuffaloActivityLivestockBreedingBuffaloLivestockActivityFragment';
};

export type BuffaloActivityLivestockTradingBuffaloLivestockActivityFragment = {
  __typename: 'BuffaloLivestockActivity';
  bullsTrading: boolean;
  cowsTrading: boolean;
  steersTrading: boolean;
  calfsTrading: boolean;
} & {
  ' $fragmentName'?: 'BuffaloActivityLivestockTradingBuffaloLivestockActivityFragment';
};

export type BuffaloActivityLivestockBuffaloLivestockActivityFragment = ({
  __typename: 'BuffaloLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    BuffaloActivityLivestockBreedingBuffaloLivestockActivityFragment: BuffaloActivityLivestockBreedingBuffaloLivestockActivityFragment;
    BuffaloActivityLivestockTradingBuffaloLivestockActivityFragment: BuffaloActivityLivestockTradingBuffaloLivestockActivityFragment;
  };
}) & {
  ' $fragmentName'?: 'BuffaloActivityLivestockBuffaloLivestockActivityFragment';
};

export type GetBuffaloActivityFeedQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetBuffaloActivityFeedQuery = {
  __typename: 'Query';
  buffaloLivestockActivity?:
    | ({ __typename: 'BuffaloLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          BuffaloActivityLivestockFeed_BuffaloLivestockActivityFragmentFragment: BuffaloActivityLivestockFeed_BuffaloLivestockActivityFragmentFragment;
        };
      })
    | null;
};

export type BuffaloActivityLivestockFeed_BuffaloLivestockActivityFragmentFragment =
  {
    __typename: 'BuffaloLivestockActivity';
    id: string;
    feed?: {
      __typename: 'Feed';
      id: string;
      pasture: boolean;
      grazingCrops: boolean;
    } | null;
  } & {
    ' $fragmentName'?: 'BuffaloActivityLivestockFeed_BuffaloLivestockActivityFragmentFragment';
  };

export type PropertiesBusinessActivitiesActivitiesHeaderBuffaloActivityFragment =
  {
    __typename: 'BuffaloLivestockActivity';
    id: string;
    name: string;
    start: string;
    end: string;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesActivitiesHeaderBuffaloActivityFragment';
  };

export type GetBuffaloActivityQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetBuffaloActivityQuery = {
  __typename: 'Query';
  buffaloLivestockActivity?:
    | ({ __typename: 'BuffaloLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          BuffaloActivityLivestockBuffaloLivestockActivityFragment: BuffaloActivityLivestockBuffaloLivestockActivityFragment;
        };
      })
    | null;
};

export type GetBuffaloMonthLivestockActivitiesForActivityQueryVariables =
  Exact<{
    listBuffaloMonthLivestockActivitiesInput: ListBuffaloMonthLivestockActivitiesInput;
  }>;

export type GetBuffaloMonthLivestockActivitiesForActivityQuery = {
  __typename: 'Query';
  buffaloMonthLivestockActivities: Array<
    | ({ __typename: 'BuffaloMonthLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          BuffaloLivestockActivity_MonthActivitiesFragment: BuffaloLivestockActivity_MonthActivitiesFragment;
        };
      })
    | null
  >;
};

export type GetBuffaloActivityInHeaderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetBuffaloActivityInHeaderQuery = {
  __typename: 'Query';
  buffaloLivestockActivity?:
    | ({ __typename: 'BuffaloLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesActivitiesHeaderBuffaloActivityFragment: PropertiesBusinessActivitiesActivitiesHeaderBuffaloActivityFragment;
        };
      })
    | null;
};

export type UpdateBuffaloLivestockActivityMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateBuffaloLivestockActivityInput: UpdateBuffaloLivestockActivityInput;
}>;

export type UpdateBuffaloLivestockActivityMutation = {
  __typename: 'Mutation';
  updateBuffaloLivestockActivity: {
    __typename: 'BuffaloLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      BuffaloActivityLivestockBreedingBuffaloLivestockActivityFragment: BuffaloActivityLivestockBreedingBuffaloLivestockActivityFragment;
      BuffaloActivityLivestockTradingBuffaloLivestockActivityFragment: BuffaloActivityLivestockTradingBuffaloLivestockActivityFragment;
    };
  };
};

export type BuffaloLivestockActivity_MonthActivitiesFragment = {
  __typename: 'BuffaloMonthLivestockActivity';
  id: string;
  month: Month;
  year: number;
  headAtStart: number;
  buffaloClass: BuffaloClass;
} & { ' $fragmentName'?: 'BuffaloLivestockActivity_MonthActivitiesFragment' };

export type CreateBuffaloLivestockActivityMutationVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  createBuffaloLivestockActivityInput: CreateBuffaloLivestockActivityInput;
}>;

export type CreateBuffaloLivestockActivityMutation = {
  __typename: 'Mutation';
  createBuffaloLivestockActivity: {
    __typename: 'BuffaloLivestockActivity';
    id: string;
  };
};

export type BusinessActivitiesBurningActivityInfoFragmentFragment = {
  __typename: 'BurningActivity';
  fireScarAreaSquareMetres?: number | null;
  fuelClass?: BurningActivityFuelClass | null;
  patchiness?: BurningActivityPatchiness | null;
  season?: BurningActivitySeason | null;
  vegetationClass?: BurningActivityVegetationClass | null;
  yearsSinceLastFire?: Decimal | null;
  rainfallZone?: BurningActivityRainfallZone | null;
} & {
  ' $fragmentName'?: 'BusinessActivitiesBurningActivityInfoFragmentFragment';
};

export type PropertiesBusinessActivitiesActivitiesHeaderBurningActivityFragment =
  {
    __typename: 'BurningActivity';
    id: string;
    name: string;
    start: string;
    end: string;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesActivitiesHeaderBurningActivityFragment';
  };

export type GetBurningActivityInfoQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetBurningActivityInfoQuery = {
  __typename: 'Query';
  burningActivity?:
    | ({ __typename: 'BurningActivity'; id: string } & {
        ' $fragmentRefs'?: {
          BusinessActivitiesBurningActivityInfoFragmentFragment: BusinessActivitiesBurningActivityInfoFragmentFragment;
        };
      })
    | null;
};

export type UpdateBurningMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateBurningActivityInput: UpdateBurningActivityInput;
}>;

export type UpdateBurningMutation = {
  __typename: 'Mutation';
  updateBurningActivity: { __typename: 'BurningActivity'; id: string } & {
    ' $fragmentRefs'?: {
      BusinessActivitiesBurningActivityInfoFragmentFragment: BusinessActivitiesBurningActivityInfoFragmentFragment;
    };
  };
};

export type CreateBurningActivityMutationVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  createBurningActivityInput: CreateBurningActivityInput;
}>;

export type CreateBurningActivityMutation = {
  __typename: 'Mutation';
  createBurningActivity: { __typename: 'BurningActivity'; id: string };
};

export type GetBurningActivityInHeaderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetBurningActivityInHeaderQuery = {
  __typename: 'Query';
  burningActivity?:
    | ({ __typename: 'BurningActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesActivitiesHeaderBurningActivityFragment: PropertiesBusinessActivitiesActivitiesHeaderBurningActivityFragment;
        };
      })
    | null;
};

export type CottonCropInfoPageCottonFieldsFragment = {
  __typename: 'CottonActivity';
  id: string;
  residueBurned: boolean;
  percentageBurned?: number | null;
  averageCottonYieldKgPerHa?: number | null;
  balesPerHa?: Decimal | null;
  averageWeightPerBaleKg: Decimal;
  cottonLintPerBaleKg: Decimal;
  cottonSeedPerBaleKg: Decimal;
  wastePerBaleKg: Decimal;
} & { ' $fragmentName'?: 'CottonCropInfoPageCottonFieldsFragment' };

export type CottonCropInfoPageCroppingActivityFragmentFragment = {
  __typename: 'CroppingActivity';
  name: string;
  plantingAreaSquareMetres: number;
  plantingAreaGeometry?: GeoJSON.MultiPolygon | null;
} & { ' $fragmentName'?: 'CottonCropInfoPageCroppingActivityFragmentFragment' };

export type GetCottonCroppingActivityQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetCottonCroppingActivityQuery = {
  __typename: 'Query';
  cottonActivity?:
    | ({ __typename: 'CottonActivity'; id: string } & {
        ' $fragmentRefs'?: {
          CottonCropInfoPageCottonFieldsFragment: CottonCropInfoPageCottonFieldsFragment;
        };
      })
    | null;
};

export type UpdateCottonCroppingForInfoPageMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateCottonActivityInput: UpdateCottonActivityInput;
}>;

export type UpdateCottonCroppingForInfoPageMutation = {
  __typename: 'Mutation';
  updateCottonActivity: { __typename: 'CottonActivity'; id: string } & {
    ' $fragmentRefs'?: {
      CottonCropInfoPageCottonFieldsFragment: CottonCropInfoPageCottonFieldsFragment;
    };
  };
};

export type CropInfoPageCropVarietyFragmentFragment = {
  __typename: 'CropVariety';
  id: string;
  species: CropSpecies;
  yieldInKg: number;
} & { ' $fragmentName'?: 'CropInfoPageCropVarietyFragmentFragment' };

export type CropInfoPageCroppingActivityFragmentFragment = {
  __typename: 'CroppingActivity';
  irrigated: boolean;
  residueBurned: boolean;
  percentageBurned?: number | null;
  plantingAreaSquareMetres: number;
  plantingAreaGeometry?: GeoJSON.MultiPolygon | null;
  name: string;
  varieties: Array<
    { __typename: 'CropVariety' } & {
      ' $fragmentRefs'?: {
        CropInfoPageCropVarietyFragmentFragment: CropInfoPageCropVarietyFragmentFragment;
      };
    }
  >;
} & { ' $fragmentName'?: 'CropInfoPageCroppingActivityFragmentFragment' };

export type UpdateCroppingForInfoPageMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateCroppingActivityInput: UpdateCroppingActivityInput;
}>;

export type UpdateCroppingForInfoPageMutation = {
  __typename: 'Mutation';
  updateCroppingActivity: { __typename: 'CroppingActivity'; id: string } & {
    ' $fragmentRefs'?: {
      CropInfoPageCroppingActivityFragmentFragment: CropInfoPageCroppingActivityFragmentFragment;
    };
  };
};

export type CreateCropVarietyMutationVariables = Exact<{
  croppingActivityId: Scalars['ID']['input'];
  createCropVarietyInput: CreateCropVarietyInput;
}>;

export type CreateCropVarietyMutation = {
  __typename: 'Mutation';
  createCropVariety: { __typename: 'CropVariety'; id: string } & {
    ' $fragmentRefs'?: {
      CropInfoPageCropVarietyFragmentFragment: CropInfoPageCropVarietyFragmentFragment;
    };
  };
};

export type UpdateCropVarietyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateCropVarietyInput: UpdateCropVarietyInput;
}>;

export type UpdateCropVarietyMutation = {
  __typename: 'Mutation';
  updateCropVariety: { __typename: 'CropVariety'; id: string } & {
    ' $fragmentRefs'?: {
      CropInfoPageCropVarietyFragmentFragment: CropInfoPageCropVarietyFragmentFragment;
    };
  };
};

export type GetCroppingActivityInfoQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetCroppingActivityInfoQuery = {
  __typename: 'Query';
  croppingActivity?:
    | ({ __typename: 'CroppingActivity'; id: string } & {
        ' $fragmentRefs'?: {
          CropInfoPageCroppingActivityFragmentFragment: CropInfoPageCroppingActivityFragmentFragment;
          CottonCropInfoPageCroppingActivityFragmentFragment: CottonCropInfoPageCroppingActivityFragmentFragment;
        };
      })
    | null;
};

export type PropertiesBusinessActivitiesActivitiesHeaderCroppingActivityFragment =
  {
    __typename: 'CroppingActivity';
    id: string;
    name: string;
    start: string;
    end: string;
    croppingType: CroppingType;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesActivitiesHeaderCroppingActivityFragment';
  };

export type GetCropActivityInHeaderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetCropActivityInHeaderQuery = {
  __typename: 'Query';
  croppingActivity?:
    | ({ __typename: 'CroppingActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesActivitiesHeaderCroppingActivityFragment: PropertiesBusinessActivitiesActivitiesHeaderCroppingActivityFragment;
        };
      })
    | null;
};

export type PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragmentFragment =
  {
    __typename: 'Fertiliser';
    type: FertiliserType;
    amountPerHectareKg: Decimal;
    applicationAreaSquareMetres?: number | null;
    name?: string | null;
    applicationDate?: string | null;
    applicationAreaGeometry?: GeoJSON.MultiPolygon | null;
    ratioN: Decimal;
    ratioP: Decimal;
    ratioK: Decimal;
    ratioS: Decimal;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragmentFragment';
  };

export type CreateFertiliserMutationVariables = Exact<{
  croppingActivityId: Scalars['ID']['input'];
  createFertiliserInput: CreateFertiliserInput;
}>;

export type CreateFertiliserMutation = {
  __typename: 'Mutation';
  createFertiliser: { __typename: 'Fertiliser'; id: string } & {
    ' $fragmentRefs'?: {
      PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragmentFragment: PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragmentFragment;
    };
  };
};

export type UpdateFertiliserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateFertiliserInput: UpdateFertiliserInput;
}>;

export type UpdateFertiliserMutation = {
  __typename: 'Mutation';
  updateFertiliser: { __typename: 'Fertiliser'; id: string } & {
    ' $fragmentRefs'?: {
      PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragmentFragment: PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragmentFragment;
    };
  };
};

export type DeleteFertiliserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteFertiliserMutation = {
  __typename: 'Mutation';
  deleteFertiliser: { __typename: 'Fertiliser'; id: string };
};

export type GetFertiliserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetFertiliserQuery = {
  __typename: 'Query';
  fertiliser?:
    | ({ __typename: 'Fertiliser'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragmentFragment: PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragmentFragment;
        };
      })
    | null;
};

export type UpdateCroppingForLimeFertiliserPageMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateCroppingActivityInput: UpdateCroppingActivityInput;
}>;

export type UpdateCroppingForLimeFertiliserPageMutation = {
  __typename: 'Mutation';
  updateCroppingActivity: { __typename: 'CroppingActivity'; id: string } & {
    ' $fragmentRefs'?: {
      PropertiesBusinessActivitiesCropLimeFertiliserFragmentFragment: PropertiesBusinessActivitiesCropLimeFertiliserFragmentFragment;
    };
  };
};

export type GetCroppingLimeFertiliserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetCroppingLimeFertiliserQuery = {
  __typename: 'Query';
  croppingActivity?:
    | ({ __typename: 'CroppingActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesCropLimeFertiliserFragmentFragment: PropertiesBusinessActivitiesCropLimeFertiliserFragmentFragment;
        };
      })
    | null;
};

export type PropertiesBusinessActivitiesCropLimeFertiliserFragmentFragment = {
  __typename: 'CroppingActivity';
  id: string;
  plantingAreaSquareMetres: number;
  ureaseInhibitorUsed?: boolean | null;
  nitrificationInhibitorUsed?: boolean | null;
  lime: Array<{
    __typename: 'Lime';
    id: string;
    type: LimeType;
    amountPerHectareKg: number;
    applicationAreaSquareMetres?: number | null;
  }>;
  fertiliser: Array<{
    __typename: 'Fertiliser';
    id: string;
    type: FertiliserType;
    amountPerHectareKg: Decimal;
    applicationAreaSquareMetres?: number | null;
  }>;
} & {
  ' $fragmentName'?: 'PropertiesBusinessActivitiesCropLimeFertiliserFragmentFragment';
};

export type PropertiesBusinessActivitiesCropLimeFertiliserLimeFragmentFragment =
  {
    __typename: 'Lime';
    type: LimeType;
    amountPerHectareKg: number;
    applicationAreaSquareMetres?: number | null;
    name?: string | null;
    applicationDate?: string | null;
    applicationAreaGeometry?: GeoJSON.MultiPolygon | null;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesCropLimeFertiliserLimeFragmentFragment';
  };

export type CreateLimeMutationVariables = Exact<{
  croppingActivityId: Scalars['ID']['input'];
  createLimeInput: CreateLimeInput;
}>;

export type CreateLimeMutation = {
  __typename: 'Mutation';
  createLime: { __typename: 'Lime'; id: string } & {
    ' $fragmentRefs'?: {
      PropertiesBusinessActivitiesCropLimeFertiliserLimeFragmentFragment: PropertiesBusinessActivitiesCropLimeFertiliserLimeFragmentFragment;
    };
  };
};

export type UpdateLimeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateLimeInput: UpdateLimeInput;
}>;

export type UpdateLimeMutation = {
  __typename: 'Mutation';
  updateLime: { __typename: 'Lime'; id: string } & {
    ' $fragmentRefs'?: {
      PropertiesBusinessActivitiesCropLimeFertiliserLimeFragmentFragment: PropertiesBusinessActivitiesCropLimeFertiliserLimeFragmentFragment;
    };
  };
};

export type DeleteLimeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteLimeMutation = {
  __typename: 'Mutation';
  deleteLime: { __typename: 'Lime'; id: string };
};

export type GetLimeQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetLimeQuery = {
  __typename: 'Query';
  lime?:
    | ({ __typename: 'Lime'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesCropLimeFertiliserLimeFragmentFragment: PropertiesBusinessActivitiesCropLimeFertiliserLimeFragmentFragment;
        };
      })
    | null;
};

export type CreateCarbamateMutationVariables = Exact<{
  croppingActivityId: Scalars['ID']['input'];
  createCarbamateInput: CreateCarbamateInput;
}>;

export type CreateCarbamateMutation = {
  __typename: 'Mutation';
  createCarbamate: { __typename: 'Carbamate'; id: string } & {
    ' $fragmentRefs'?: {
      PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragmentFragment: PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragmentFragment;
    };
  };
};

export type UpdateCarbamateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateCarbamateInput: UpdateCarbamateInput;
}>;

export type UpdateCarbamateMutation = {
  __typename: 'Mutation';
  updateCarbamate: { __typename: 'Carbamate'; id: string } & {
    ' $fragmentRefs'?: {
      PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragmentFragment: PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragmentFragment;
    };
  };
};

export type DeleteCarbamateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteCarbamateMutation = {
  __typename: 'Mutation';
  deleteCarbamate: { __typename: 'Carbamate'; id: string };
};

export type GetCroppingWeedPestDiseaseQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetCroppingWeedPestDiseaseQuery = {
  __typename: 'Query';
  croppingActivity?:
    | ({ __typename: 'CroppingActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesCropWeedPestDiseaseListsFragmentFragment: PropertiesBusinessActivitiesCropWeedPestDiseaseListsFragmentFragment;
        };
      })
    | null;
};

export type GetCarbamateQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetCarbamateQuery = {
  __typename: 'Query';
  carbamate?:
    | ({ __typename: 'Carbamate'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragmentFragment: PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragmentFragment;
        };
      })
    | null;
};

export type PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragmentFragment =
  {
    __typename: 'Carbamate';
    type: CarbamateType;
    name?: string | null;
    amountPerHectareGrams: number;
    product: CarbamateProduct;
    applicationAreaSquareMetres?: number | null;
    applicationAreaGeometry?: GeoJSON.MultiPolygon | null;
    activeIngredientPercentage?: Decimal | null;
    applicationDate?: string | null;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragmentFragment';
  };

export type PropertiesBusinessActivitiesCropWeedPestDiseaseListsFragmentFragment =
  {
    __typename: 'CroppingActivity';
    plantingAreaSquareMetres: number;
    pesticide: Array<{
      __typename: 'Carbamate';
      id: string;
      type: CarbamateType;
      product: CarbamateProduct;
      amountPerHectareGrams: number;
      applicationAreaSquareMetres?: number | null;
    }>;
    herbicide: Array<{
      __typename: 'Carbamate';
      id: string;
      type: CarbamateType;
      product: CarbamateProduct;
      amountPerHectareGrams: number;
      applicationAreaSquareMetres?: number | null;
    }>;
    fungicide: Array<{
      __typename: 'Carbamate';
      id: string;
      type: CarbamateType;
      product: CarbamateProduct;
      amountPerHectareGrams: number;
      applicationAreaSquareMetres?: number | null;
    }>;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesCropWeedPestDiseaseListsFragmentFragment';
  };

export type CreateCroppingActivityMutationVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  createCroppingActivityInput: CreateCroppingActivityInput;
}>;

export type CreateCroppingActivityMutation = {
  __typename: 'Mutation';
  createCroppingActivity: { __typename: 'CroppingActivity'; id: string };
};

export type UseDairyMonthlyFeedBreakdown_ActivityFragmentFragment = {
  __typename: 'DairyLivestockActivity';
  id: string;
  dairyBullsGt1?: boolean | null;
  dairyBullsLt1?: boolean | null;
  heifersGt1?: boolean | null;
  heifersLt1?: boolean | null;
  milkingCows?: boolean | null;
  feed?: { __typename: 'Feed'; pasture: boolean; grazingCrops: boolean } | null;
  businessActivity: {
    __typename: 'BusinessActivity';
    id: string;
    state: StateRegion;
  };
} & {
  ' $fragmentName'?: 'UseDairyMonthlyFeedBreakdown_ActivityFragmentFragment';
};

export type UseDairyMonthlyFeedBreakdown_DairyMonthLivestockActivityFragment = {
  __typename: 'DairyMonthLivestockActivity';
  id: string;
  month: Month;
  year: number;
  crudeProteinPercentage: Decimal;
  dryMatterDigestibilityPercentage: Decimal;
} & {
  ' $fragmentName'?: 'UseDairyMonthlyFeedBreakdown_DairyMonthLivestockActivityFragment';
};

export type UpdateDairyMonthLivestockActivityForMonthlyFeedBreakdownMutationVariables =
  Exact<{
    id: Scalars['ID']['input'];
    updateDairyMonthLivestockActivityInput: UpdateDairyMonthLivestockActivityInput;
  }>;

export type UpdateDairyMonthLivestockActivityForMonthlyFeedBreakdownMutation = {
  __typename: 'Mutation';
  updateDairyMonthLivestockActivity: {
    __typename: 'DairyMonthLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      UseDairyMonthlyFeedBreakdown_DairyMonthLivestockActivityFragment: UseDairyMonthlyFeedBreakdown_DairyMonthLivestockActivityFragment;
    };
  };
};

export type GetDairyActivityForMonthlyFeedBreakdownQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetDairyActivityForMonthlyFeedBreakdownQuery = {
  __typename: 'Query';
  dairyLivestockActivity?:
    | ({ __typename: 'DairyLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          UseDairyMonthlyFeedBreakdown_ActivityFragmentFragment: UseDairyMonthlyFeedBreakdown_ActivityFragmentFragment;
        };
      })
    | null;
};

export type GetDairyMonthLivestockActivitiesForMonthlyFeedBreakdownQueryVariables =
  Exact<{
    listDairyMonthLivestockActivitiesInput: ListDairyMonthLivestockActivitiesInput;
  }>;

export type GetDairyMonthLivestockActivitiesForMonthlyFeedBreakdownQuery = {
  __typename: 'Query';
  dairyMonthLivestockActivities: Array<
    { __typename: 'DairyMonthLivestockActivity'; id: string } & {
      ' $fragmentRefs'?: {
        UseDairyMonthlyFeedBreakdown_DairyMonthLivestockActivityFragment: UseDairyMonthlyFeedBreakdown_DairyMonthLivestockActivityFragment;
      };
    }
  >;
};

export type DairyFeedFragmentFragment = ({
  __typename: 'DairyLivestockActivity';
  id: string;
  dairyBullsGt1?: boolean | null;
  dairyBullsLt1?: boolean | null;
  heifersGt1?: boolean | null;
  heifersLt1?: boolean | null;
  milkingCows?: boolean | null;
  feed?: {
    __typename: 'Feed';
    id: string;
    pasture: boolean;
    grazingCrops: boolean;
  } | null;
} & {
  ' $fragmentRefs'?: {
    DairyFeedBreakdownSummaryFragmentFragment: DairyFeedBreakdownSummaryFragmentFragment;
  };
}) & { ' $fragmentName'?: 'DairyFeedFragmentFragment' };

export type GetDairyLivestockActivityFeedQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetDairyLivestockActivityFeedQuery = {
  __typename: 'Query';
  dairyLivestockActivity?:
    | ({ __typename: 'DairyLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          DairyFeedFragmentFragment: DairyFeedFragmentFragment;
        };
      })
    | null;
};

export type GetDairyMonthLivestockActivitiesForFeedBreakdownQueryVariables =
  Exact<{
    listDairyMonthLivestockActivitiesInput: ListDairyMonthLivestockActivitiesInput;
  }>;

export type GetDairyMonthLivestockActivitiesForFeedBreakdownQuery = {
  __typename: 'Query';
  dairyMonthLivestockActivities: Array<
    { __typename: 'DairyMonthLivestockActivity'; id: string } & {
      ' $fragmentRefs'?: {
        DairyFeedBreakdownSummaryMonthsFragmentFragment: DairyFeedBreakdownSummaryMonthsFragmentFragment;
      };
    }
  >;
};

export type DairyFeedBreakdownSummaryFragmentFragment = {
  __typename: 'DairyLivestockActivity';
  dairyBullsGt1?: boolean | null;
  dairyBullsLt1?: boolean | null;
  heifersGt1?: boolean | null;
  heifersLt1?: boolean | null;
  milkingCows?: boolean | null;
} & { ' $fragmentName'?: 'DairyFeedBreakdownSummaryFragmentFragment' };

export type DairyFeedBreakdownSummaryMonthsFragmentFragment = {
  __typename: 'DairyMonthLivestockActivity';
  id: string;
  month: Month;
  year: number;
  crudeProteinPercentage: Decimal;
  dryMatterDigestibilityPercentage: Decimal;
  dairyClass: DairyClass;
} & { ' $fragmentName'?: 'DairyFeedBreakdownSummaryMonthsFragmentFragment' };

export type DairyInfoFragmentFragment = {
  __typename: 'DairyLivestockActivity';
  productionSystem?: DairyProductionSystem | null;
  distanceTravelledBetweenFarmsKm?: Decimal | null;
  truckType?: TruckType | null;
  emissionsRedMeatPercentage?: Decimal | null;
} & { ' $fragmentName'?: 'DairyInfoFragmentFragment' };

export type GetDairyLivestockActivityInfoQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetDairyLivestockActivityInfoQuery = {
  __typename: 'Query';
  dairyLivestockActivity?:
    | ({ __typename: 'DairyLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          DairyInfoFragmentFragment: DairyInfoFragmentFragment;
        };
      })
    | null;
};

export type UpdateDairyLivestockActivityInfoMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateDairyLivestockActivityInput: UpdateDairyLivestockActivityInput;
}>;

export type UpdateDairyLivestockActivityInfoMutation = {
  __typename: 'Mutation';
  updateDairyLivestockActivity: {
    __typename: 'DairyLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: { DairyInfoFragmentFragment: DairyInfoFragmentFragment };
  };
};

export type DairyLivestockActivityHeaderFragmentFragment = {
  __typename: 'DairyLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & { ' $fragmentName'?: 'DairyLivestockActivityHeaderFragmentFragment' };

export type GetDairyLivestockActivityInHeaderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetDairyLivestockActivityInHeaderQuery = {
  __typename: 'Query';
  dairyLivestockActivity?:
    | ({ __typename: 'DairyLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          DairyLivestockActivityHeaderFragmentFragment: DairyLivestockActivityHeaderFragmentFragment;
        };
      })
    | null;
};

export type DairyMonthlyForm_DairyMonthLivestockActivityFragment = {
  __typename: 'DairyMonthLivestockActivity';
  id: string;
  month: Month;
  year: number;
  previousMonthLiveweightKg?: number | null;
  averageLiveweightKg: number;
  nonSalePopulationChange: number;
  keyEvents?: string | null;
  headAtStart: number;
  livestockTransactionEvents: Array<{
    __typename: 'LivestockTransactionEvent';
    id: string;
    transactionType: TransactionType;
    livestockCount: number;
  }>;
} & {
  ' $fragmentName'?: 'DairyMonthlyForm_DairyMonthLivestockActivityFragment';
};

export type UpdateTransaction_DairyMonthLivestockActivityFragmentFragment = ({
  __typename: 'DairyMonthLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    TransactionForm_DairyMonthLivestockActivityFragmentFragment: TransactionForm_DairyMonthLivestockActivityFragmentFragment;
  };
}) & {
  ' $fragmentName'?: 'UpdateTransaction_DairyMonthLivestockActivityFragmentFragment';
};

export type CreateTransaction_DairyMonthLivestockActivityFragmentFragment = ({
  __typename: 'DairyMonthLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    TransactionForm_DairyMonthLivestockActivityFragmentFragment: TransactionForm_DairyMonthLivestockActivityFragmentFragment;
  };
}) & {
  ' $fragmentName'?: 'CreateTransaction_DairyMonthLivestockActivityFragmentFragment';
};

export type TransactionForm_DairyMonthLivestockActivityFragmentFragment = {
  __typename: 'DairyMonthLivestockActivity';
  year: number;
  month: Month;
  dairyClass: DairyClass;
} & {
  ' $fragmentName'?: 'TransactionForm_DairyMonthLivestockActivityFragmentFragment';
};

export type UpdateDairyMonthLivestockActivityForMonthlyBreakdownMutationVariables =
  Exact<{
    id: Scalars['ID']['input'];
    updateDairyMonthLivestockActivityInput: UpdateDairyMonthLivestockActivityInput;
  }>;

export type UpdateDairyMonthLivestockActivityForMonthlyBreakdownMutation = {
  __typename: 'Mutation';
  updateDairyMonthLivestockActivity: {
    __typename: 'DairyMonthLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      DairyMonthlyBreakdown_DairyMonthLivestockActivityFragment: DairyMonthlyBreakdown_DairyMonthLivestockActivityFragment;
    };
  };
};

export type CreateDairyLivestockTransactionEventForUpdateTransactionMutationVariables =
  Exact<{
    dairyMonthLivestockActivityId: Scalars['ID']['input'];
    createLivestockTransactionEventInput: CreateLivestockTransactionEventInput;
  }>;

export type CreateDairyLivestockTransactionEventForUpdateTransactionMutation = {
  __typename: 'Mutation';
  createDairyLivestockTransactionEvent: {
    __typename: 'LivestockTransactionEvent';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      UpdateTransaction_LivestockTransactionEventFragmentFragment: UpdateTransaction_LivestockTransactionEventFragmentFragment;
    };
  };
};

export type GetDairyLivestockActivityForMonthlyBreakdownQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetDairyLivestockActivityForMonthlyBreakdownQuery = {
  __typename: 'Query';
  dairyLivestockActivity?:
    | ({ __typename: 'DairyLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          DairyMonthlyBreakdown_DairyLivestockActivityFragmentFragment: DairyMonthlyBreakdown_DairyLivestockActivityFragmentFragment;
        };
      })
    | null;
};

export type GetDairyMonthLivestockActivitiesForMonthlyBreakdownQueryVariables =
  Exact<{
    listDairyMonthLivestockActivitiesInput: ListDairyMonthLivestockActivitiesInput;
  }>;

export type GetDairyMonthLivestockActivitiesForMonthlyBreakdownQuery = {
  __typename: 'Query';
  dairyMonthLivestockActivities: Array<
    { __typename: 'DairyMonthLivestockActivity'; id: string } & {
      ' $fragmentRefs'?: {
        DairyMonthlyBreakdown_DairyMonthLivestockActivityFragment: DairyMonthlyBreakdown_DairyMonthLivestockActivityFragment;
      };
    }
  >;
};

export type GetdairyMonthLivestockActivityForCreateUpdateTransactionQueryVariables =
  Exact<{
    id: Scalars['ID']['input'];
  }>;

export type GetdairyMonthLivestockActivityForCreateUpdateTransactionQuery = {
  __typename: 'Query';
  dairyMonthLivestockActivity: {
    __typename: 'DairyMonthLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      CreateTransaction_DairyMonthLivestockActivityFragmentFragment: CreateTransaction_DairyMonthLivestockActivityFragmentFragment;
      UpdateTransaction_DairyMonthLivestockActivityFragmentFragment: UpdateTransaction_DairyMonthLivestockActivityFragmentFragment;
    };
  };
};

export type DairyMonthlyBreakdown_DairyLivestockActivityFragmentFragment = {
  __typename: 'DairyLivestockActivity';
  dairyBullsGt1?: boolean | null;
  dairyBullsLt1?: boolean | null;
  heifersGt1?: boolean | null;
  heifersLt1?: boolean | null;
  milkingCows?: boolean | null;
} & {
  ' $fragmentName'?: 'DairyMonthlyBreakdown_DairyLivestockActivityFragmentFragment';
};

export type DairyMonthlyBreakdown_DairyMonthLivestockActivityFragment = ({
  __typename: 'DairyMonthLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    DairyMonthlyForm_DairyMonthLivestockActivityFragment: DairyMonthlyForm_DairyMonthLivestockActivityFragment;
  };
}) & {
  ' $fragmentName'?: 'DairyMonthlyBreakdown_DairyMonthLivestockActivityFragment';
};

export type DairyActivityLivestockFragmentFragment = ({
  __typename: 'DairyLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    DairyLivestockFragmentFragment: DairyLivestockFragmentFragment;
  };
}) & { ' $fragmentName'?: 'DairyActivityLivestockFragmentFragment' };

export type GetDairyLivestockActivityLivestockQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetDairyLivestockActivityLivestockQuery = {
  __typename: 'Query';
  dairyLivestockActivity?:
    | ({ __typename: 'DairyLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          DairyActivityLivestockFragmentFragment: DairyActivityLivestockFragmentFragment;
        };
      })
    | null;
};

export type UpdateDairyLivestockActivityLivestockMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateDairyLivestockActivityInput: UpdateDairyLivestockActivityInput;
}>;

export type UpdateDairyLivestockActivityLivestockMutation = {
  __typename: 'Mutation';
  updateDairyLivestockActivity: {
    __typename: 'DairyLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      DairyActivityLivestockFragmentFragment: DairyActivityLivestockFragmentFragment;
    };
  };
};

export type GetDairyMonthLivestockActivitiesForActivityQueryVariables = Exact<{
  listDairyMonthLivestockActivitiesInput: ListDairyMonthLivestockActivitiesInput;
}>;

export type GetDairyMonthLivestockActivitiesForActivityQuery = {
  __typename: 'Query';
  dairyMonthLivestockActivities: Array<
    { __typename: 'DairyMonthLivestockActivity'; id: string } & {
      ' $fragmentRefs'?: {
        DairyLivestockMonthsFragmentFragment: DairyLivestockMonthsFragmentFragment;
      };
    }
  >;
};

export type DairyLivestockFragmentFragment = {
  __typename: 'DairyLivestockActivity';
  dairyBullsGt1?: boolean | null;
  dairyBullsLt1?: boolean | null;
  heifersGt1?: boolean | null;
  heifersLt1?: boolean | null;
  milkingCows?: boolean | null;
} & { ' $fragmentName'?: 'DairyLivestockFragmentFragment' };

export type DairyLivestockMonthsFragmentFragment = {
  __typename: 'DairyMonthLivestockActivity';
  id: string;
  month: Month;
  year: number;
  headAtStart: number;
  dairyClass: DairyClass;
} & { ' $fragmentName'?: 'DairyLivestockMonthsFragmentFragment' };

export type DairyManureFragmentFragment = {
  __typename: 'DairyLivestockActivity';
  manureMilkingPasturePercentage?: Decimal | null;
  manureMilkingLagoonPercentage?: Decimal | null;
  manureMilkingSumpDispersalPercentage?: Decimal | null;
  manureMilkingPaddocksPercentage?: Decimal | null;
  manureMilkingSolidStoragePercentage?: Decimal | null;
  manureOtherPasturePercentage?: Decimal | null;
  manureOtherLagoonPercentage?: Decimal | null;
  manureOtherSumpDispersalPercentage?: Decimal | null;
  manureOtherPaddocksPercentage?: Decimal | null;
  manureOtherSolidStoragePercentage?: Decimal | null;
} & { ' $fragmentName'?: 'DairyManureFragmentFragment' };

export type GetDairyLivestockActivityManureQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetDairyLivestockActivityManureQuery = {
  __typename: 'Query';
  dairyLivestockActivity?:
    | ({ __typename: 'DairyLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          DairyManureFragmentFragment: DairyManureFragmentFragment;
        };
      })
    | null;
};

export type UpdateDairyLivestockActivityManureMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateDairyLivestockActivityInput: UpdateDairyLivestockActivityInput;
}>;

export type UpdateDairyLivestockActivityManureMutation = {
  __typename: 'Mutation';
  updateDairyLivestockActivity: {
    __typename: 'DairyLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      DairyManureFragmentFragment: DairyManureFragmentFragment;
    };
  };
};

export type UseDairyMonthlyMilkBreakdown_DairyMonthLivestockActivityFragment = {
  __typename: 'DairyMonthLivestockActivity';
  id: string;
  month: Month;
  year: number;
  litresPerHeadPerDay: Decimal;
} & {
  ' $fragmentName'?: 'UseDairyMonthlyMilkBreakdown_DairyMonthLivestockActivityFragment';
};

export type GetDairyMonthLivestockActivitiesForMonthlyMilkBreakdownQueryVariables =
  Exact<{
    listDairyMonthLivestockActivitiesInput: ListDairyMonthLivestockActivitiesInput;
  }>;

export type GetDairyMonthLivestockActivitiesForMonthlyMilkBreakdownQuery = {
  __typename: 'Query';
  dairyMonthLivestockActivities: Array<
    { __typename: 'DairyMonthLivestockActivity'; id: string } & {
      ' $fragmentRefs'?: {
        UseDairyMonthlyMilkBreakdown_DairyMonthLivestockActivityFragment: UseDairyMonthlyMilkBreakdown_DairyMonthLivestockActivityFragment;
      };
    }
  >;
};

export type UpdateDairyMonthLivestockActivityForMonthlyMilkBreakdownMutationVariables =
  Exact<{
    id: Scalars['ID']['input'];
    updateDairyMonthLivestockActivityInput: UpdateDairyMonthLivestockActivityInput;
  }>;

export type UpdateDairyMonthLivestockActivityForMonthlyMilkBreakdownMutation = {
  __typename: 'Mutation';
  updateDairyMonthLivestockActivity: {
    __typename: 'DairyMonthLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      UseDairyMonthlyMilkBreakdown_DairyMonthLivestockActivityFragment: UseDairyMonthlyMilkBreakdown_DairyMonthLivestockActivityFragment;
    };
  };
};

export type DairyMilkProductionListFragmentFragment = ({
  __typename: 'DairyLivestockActivity';
  dairyBullsGt1?: boolean | null;
  dairyBullsLt1?: boolean | null;
  heifersGt1?: boolean | null;
  heifersLt1?: boolean | null;
  milkingCows?: boolean | null;
} & {
  ' $fragmentRefs'?: {
    DairyMilkSummaryFragmentFragment: DairyMilkSummaryFragmentFragment;
  };
}) & { ' $fragmentName'?: 'DairyMilkProductionListFragmentFragment' };

export type GetDairyLivestockActivityMilkProductionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetDairyLivestockActivityMilkProductionQuery = {
  __typename: 'Query';
  dairyLivestockActivity?:
    | ({ __typename: 'DairyLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          DairyMilkProductionListFragmentFragment: DairyMilkProductionListFragmentFragment;
        };
      })
    | null;
};

export type GetDairyMonthLivestockActivitiesForMilkProductionQueryVariables =
  Exact<{
    listDairyMonthLivestockActivitiesInput: ListDairyMonthLivestockActivitiesInput;
  }>;

export type GetDairyMonthLivestockActivitiesForMilkProductionQuery = {
  __typename: 'Query';
  dairyMonthLivestockActivities: Array<
    { __typename: 'DairyMonthLivestockActivity'; id: string } & {
      ' $fragmentRefs'?: {
        DairyMilkSummaryMonthsFragmentFragment: DairyMilkSummaryMonthsFragmentFragment;
      };
    }
  >;
};

export type DairyMilkSummaryFragmentFragment = {
  __typename: 'DairyLivestockActivity';
  dairyBullsGt1?: boolean | null;
  dairyBullsLt1?: boolean | null;
  heifersGt1?: boolean | null;
  heifersLt1?: boolean | null;
  milkingCows?: boolean | null;
} & { ' $fragmentName'?: 'DairyMilkSummaryFragmentFragment' };

export type DairyMilkSummaryMonthsFragmentFragment = {
  __typename: 'DairyMonthLivestockActivity';
  id: string;
  month: Month;
  year: number;
  litresPerHeadPerDay: Decimal;
  dairyClass: DairyClass;
} & { ' $fragmentName'?: 'DairyMilkSummaryMonthsFragmentFragment' };

export type CreateDairyLivestockActivityMutationVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  createDairyLivestockActivityInput: CreateDairyLivestockActivityInput;
}>;

export type CreateDairyLivestockActivityMutation = {
  __typename: 'Mutation';
  createDairyLivestockActivity: {
    __typename: 'DairyLivestockActivity';
    id: string;
  };
};

export type DeerMonthlyForm_DeerMonthLivestockActivityFragment = {
  __typename: 'DeerMonthLivestockActivity';
  id: string;
  month: Month;
  year: number;
  nonSalePopulationChange: number;
  percentageDoesFawning: Decimal;
  seasonalFawningRate: Decimal;
  keyEvents?: string | null;
  headAtStart: number;
  livestockTransactionEvents: Array<{
    __typename: 'LivestockTransactionEvent';
    id: string;
    transactionType: TransactionType;
    livestockCount: number;
  }>;
} & { ' $fragmentName'?: 'DeerMonthlyForm_DeerMonthLivestockActivityFragment' };

export type UpdateTransaction_DeerMonthLivestockActivityFragmentFragment = ({
  __typename: 'DeerMonthLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    TransactionForm_DeerMonthLivestockActivityFragmentFragment: TransactionForm_DeerMonthLivestockActivityFragmentFragment;
  };
}) & {
  ' $fragmentName'?: 'UpdateTransaction_DeerMonthLivestockActivityFragmentFragment';
};

export type CreateTransaction_DeerMonthLivestockActivityFragmentFragment = ({
  __typename: 'DeerMonthLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    TransactionForm_DeerMonthLivestockActivityFragmentFragment: TransactionForm_DeerMonthLivestockActivityFragmentFragment;
  };
}) & {
  ' $fragmentName'?: 'CreateTransaction_DeerMonthLivestockActivityFragmentFragment';
};

export type TransactionForm_DeerMonthLivestockActivityFragmentFragment = {
  __typename: 'DeerMonthLivestockActivity';
  year: number;
  month: Month;
  deerClass: DeerClass;
} & {
  ' $fragmentName'?: 'TransactionForm_DeerMonthLivestockActivityFragmentFragment';
};

export type UpdateDeerMonthLivestockActivityForMonthlyBreakdownMutationVariables =
  Exact<{
    id: Scalars['ID']['input'];
    updateDeerMonthLivestockActivityInput: UpdateDeerMonthLivestockActivityInput;
  }>;

export type UpdateDeerMonthLivestockActivityForMonthlyBreakdownMutation = {
  __typename: 'Mutation';
  updateDeerMonthLivestockActivity: {
    __typename: 'DeerMonthLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      DeerMonthlyBreakdown_DeerMonthLivestockActivityFragment: DeerMonthlyBreakdown_DeerMonthLivestockActivityFragment;
    };
  };
};

export type CreateDeerLivestockTransactionEventForUpdateTransactionMutationVariables =
  Exact<{
    deerMonthLivestockActivityId: Scalars['ID']['input'];
    createLivestockTransactionEventInput: CreateLivestockTransactionEventInput;
  }>;

export type CreateDeerLivestockTransactionEventForUpdateTransactionMutation = {
  __typename: 'Mutation';
  createDeerLivestockTransactionEvent: {
    __typename: 'LivestockTransactionEvent';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      UpdateTransaction_LivestockTransactionEventFragmentFragment: UpdateTransaction_LivestockTransactionEventFragmentFragment;
    };
  };
};

export type GetDeerActivityForMonthlyBreakdownQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetDeerActivityForMonthlyBreakdownQuery = {
  __typename: 'Query';
  deerLivestockActivity?:
    | ({ __typename: 'DeerLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          DeerMonthlyBreakdown_DeerLivestockActivityFragmentFragment: DeerMonthlyBreakdown_DeerLivestockActivityFragmentFragment;
        };
      })
    | null;
};

export type GetDeerMonthLivestockActivitiesForMonthlyBreakdownQueryVariables =
  Exact<{
    listDeerMonthLivestockActivitiesInput: ListDeerMonthLivestockActivitiesInput;
  }>;

export type GetDeerMonthLivestockActivitiesForMonthlyBreakdownQuery = {
  __typename: 'Query';
  deerMonthLivestockActivities: Array<
    | ({ __typename: 'DeerMonthLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          DeerMonthlyBreakdown_DeerMonthLivestockActivityFragment: DeerMonthlyBreakdown_DeerMonthLivestockActivityFragment;
        };
      })
    | null
  >;
};

export type GetDeerMonthLivestockActivityForCreateUpdateTransactionQueryVariables =
  Exact<{
    id: Scalars['ID']['input'];
  }>;

export type GetDeerMonthLivestockActivityForCreateUpdateTransactionQuery = {
  __typename: 'Query';
  deerMonthLivestockActivity: {
    __typename: 'DeerMonthLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      CreateTransaction_DeerMonthLivestockActivityFragmentFragment: CreateTransaction_DeerMonthLivestockActivityFragmentFragment;
      UpdateTransaction_DeerMonthLivestockActivityFragmentFragment: UpdateTransaction_DeerMonthLivestockActivityFragmentFragment;
    };
  };
};

export type DeerMonthlyBreakdown_DeerLivestockActivityFragmentFragment = {
  __typename: 'DeerLivestockActivity';
  bucksBreeding: boolean;
  breedingDoesBreeding: boolean;
  otherDoesBreeding: boolean;
  fawnBreeding: boolean;
  bucksTrading: boolean;
  breedingDoesTrading: boolean;
  otherDoesTrading: boolean;
  fawnTrading: boolean;
} & {
  ' $fragmentName'?: 'DeerMonthlyBreakdown_DeerLivestockActivityFragmentFragment';
};

export type DeerMonthlyBreakdown_DeerMonthLivestockActivityFragment = ({
  __typename: 'DeerMonthLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    DeerMonthlyForm_DeerMonthLivestockActivityFragment: DeerMonthlyForm_DeerMonthLivestockActivityFragment;
  };
}) & {
  ' $fragmentName'?: 'DeerMonthlyBreakdown_DeerMonthLivestockActivityFragment';
};

export type DeerActivityLivestockBreedingDeerLivestockActivityFragment = {
  __typename: 'DeerLivestockActivity';
  bucksBreeding: boolean;
  breedingDoesBreeding: boolean;
  otherDoesBreeding: boolean;
  fawnBreeding: boolean;
} & {
  ' $fragmentName'?: 'DeerActivityLivestockBreedingDeerLivestockActivityFragment';
};

export type DeerActivityLivestockTradingDeerLivestockActivityFragment = {
  __typename: 'DeerLivestockActivity';
  bucksTrading: boolean;
  breedingDoesTrading: boolean;
  otherDoesTrading: boolean;
  fawnTrading: boolean;
} & {
  ' $fragmentName'?: 'DeerActivityLivestockTradingDeerLivestockActivityFragment';
};

export type DeerActivityLivestockDeerLivestockActivityFragment = ({
  __typename: 'DeerLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    DeerActivityLivestockBreedingDeerLivestockActivityFragment: DeerActivityLivestockBreedingDeerLivestockActivityFragment;
    DeerActivityLivestockTradingDeerLivestockActivityFragment: DeerActivityLivestockTradingDeerLivestockActivityFragment;
  };
}) & {
  ' $fragmentName'?: 'DeerActivityLivestockDeerLivestockActivityFragment';
};

export type GetDeerActivityFeedQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetDeerActivityFeedQuery = {
  __typename: 'Query';
  deerLivestockActivity?:
    | ({ __typename: 'DeerLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          DeerActivityLivestockFeed_DeerLivestockActivityFragmentFragment: DeerActivityLivestockFeed_DeerLivestockActivityFragmentFragment;
        };
      })
    | null;
};

export type DeerActivityLivestockFeed_DeerLivestockActivityFragmentFragment = {
  __typename: 'DeerLivestockActivity';
  id: string;
  feed?: {
    __typename: 'Feed';
    id: string;
    pasture: boolean;
    grazingCrops: boolean;
  } | null;
} & {
  ' $fragmentName'?: 'DeerActivityLivestockFeed_DeerLivestockActivityFragmentFragment';
};

export type PropertiesBusinessActivitiesActivitiesHeaderDeerActivityFragment = {
  __typename: 'DeerLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'PropertiesBusinessActivitiesActivitiesHeaderDeerActivityFragment';
};

export type GetDeerActivityQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetDeerActivityQuery = {
  __typename: 'Query';
  deerLivestockActivity?:
    | ({ __typename: 'DeerLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          DeerActivityLivestockDeerLivestockActivityFragment: DeerActivityLivestockDeerLivestockActivityFragment;
        };
      })
    | null;
};

export type GetDeerMonthLivestockActivitiesForActivityQueryVariables = Exact<{
  listDeerMonthLivestockActivitiesInput: ListDeerMonthLivestockActivitiesInput;
}>;

export type GetDeerMonthLivestockActivitiesForActivityQuery = {
  __typename: 'Query';
  deerMonthLivestockActivities: Array<
    | ({ __typename: 'DeerMonthLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          DeerLivestockActivity_MonthActivitiesFragment: DeerLivestockActivity_MonthActivitiesFragment;
        };
      })
    | null
  >;
};

export type GetDeerActivityInHeaderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetDeerActivityInHeaderQuery = {
  __typename: 'Query';
  deerLivestockActivity?:
    | ({ __typename: 'DeerLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesActivitiesHeaderDeerActivityFragment: PropertiesBusinessActivitiesActivitiesHeaderDeerActivityFragment;
        };
      })
    | null;
};

export type UpdateDeerLivestockActivityMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateDeerLivestockActivityInput: UpdateDeerLivestockActivityInput;
}>;

export type UpdateDeerLivestockActivityMutation = {
  __typename: 'Mutation';
  updateDeerLivestockActivity: {
    __typename: 'DeerLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      DeerActivityLivestockBreedingDeerLivestockActivityFragment: DeerActivityLivestockBreedingDeerLivestockActivityFragment;
      DeerActivityLivestockTradingDeerLivestockActivityFragment: DeerActivityLivestockTradingDeerLivestockActivityFragment;
    };
  };
};

export type DeerLivestockActivity_MonthActivitiesFragment = {
  __typename: 'DeerMonthLivestockActivity';
  id: string;
  month: Month;
  year: number;
  headAtStart: number;
  deerClass: DeerClass;
} & { ' $fragmentName'?: 'DeerLivestockActivity_MonthActivitiesFragment' };

export type CreateDeerLivestockActivityMutationVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  createDeerLivestockActivityInput: CreateDeerLivestockActivityInput;
}>;

export type CreateDeerLivestockActivityMutation = {
  __typename: 'Mutation';
  createDeerLivestockActivity: {
    __typename: 'DeerLivestockActivity';
    id: string;
  };
};

export type FeedlotGroupFeedBreakdownFragmentFragment = {
  __typename: 'FeedlotFeedBreakdown';
  id: string;
  dryMatterDigestibility: Decimal;
  crudeProtein: Decimal;
  netEnergyConcentrationMjPerKg: Decimal;
  nitrogenRetention: Decimal;
} & { ' $fragmentName'?: 'FeedlotGroupFeedBreakdownFragmentFragment' };

export type FeedlotGroupFeedFragmentFragment = {
  __typename: 'FeedlotGroup';
  id: string;
  feedBreakdown?:
    | ({ __typename: 'FeedlotFeedBreakdown' } & {
        ' $fragmentRefs'?: {
          FeedlotGroupFeedBreakdownFragmentFragment: FeedlotGroupFeedBreakdownFragmentFragment;
        };
      })
    | null;
} & { ' $fragmentName'?: 'FeedlotGroupFeedFragmentFragment' };

export type GetFeedlotGroupFeedBreakdownQueryVariables = Exact<{
  groupId: Scalars['ID']['input'];
}>;

export type GetFeedlotGroupFeedBreakdownQuery = {
  __typename: 'Query';
  feedlotGroup?:
    | ({ __typename: 'FeedlotGroup'; id: string } & {
        ' $fragmentRefs'?: {
          FeedlotGroupFeedFragmentFragment: FeedlotGroupFeedFragmentFragment;
        };
      })
    | null;
};

export type CreateFeedBreakdownMutationVariables = Exact<{
  feedlotGroupId: Scalars['ID']['input'];
  createFeedlotFeedBreakdownInput: CreateFeedlotFeedBreakdownInput;
}>;

export type CreateFeedBreakdownMutation = {
  __typename: 'Mutation';
  createFeedlotFeedBreakdown: {
    __typename: 'FeedlotFeedBreakdown';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      FeedlotGroupFeedBreakdownFragmentFragment: FeedlotGroupFeedBreakdownFragmentFragment;
    };
  };
};

export type UpdateFeedBreakdownMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateFeedlotFeedBreakdownInput: UpdateFeedlotFeedBreakdownInput;
}>;

export type UpdateFeedBreakdownMutation = {
  __typename: 'Mutation';
  updateFeedlotFeedBreakdown: {
    __typename: 'FeedlotFeedBreakdown';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      FeedlotGroupFeedBreakdownFragmentFragment: FeedlotGroupFeedBreakdownFragmentFragment;
    };
  };
};

export type CreateFeedCompositionMutationVariables = Exact<{
  feedlotActivityId: Scalars['ID']['input'];
  createFeedlotFeedCompositionInput: CreateFeedlotFeedCompositionInput;
}>;

export type CreateFeedCompositionMutation = {
  __typename: 'Mutation';
  createFeedlotFeedComposition: {
    __typename: 'FeedlotFeedComposition';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      FeedlotFeedCompositionFragmentFragment: FeedlotFeedCompositionFragmentFragment;
    };
  };
};

export type UpdateFeedCompositionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateFeedlotFeedCompositionInput: UpdateFeedlotFeedCompositionInput;
}>;

export type UpdateFeedCompositionMutation = {
  __typename: 'Mutation';
  updateFeedlotFeedComposition: {
    __typename: 'FeedlotFeedComposition';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      FeedlotFeedCompositionFragmentFragment: FeedlotFeedCompositionFragmentFragment;
    };
  };
};

export type FeedlotFeedCompositionFragmentFragment = {
  __typename: 'FeedlotFeedComposition';
  id: string;
  grainsTotalProportion: Decimal;
  grainsTotalCellulous: Decimal;
  grainsTotalHemicellulous: Decimal;
  grainsTotalSolubleResidue: Decimal;
  grainsTotalNitrogen: Decimal;
  otherConcentratesProportion: Decimal;
  otherConcentratesCellulous: Decimal;
  otherConcentratesHemicellulous: Decimal;
  otherConcentratesSolubleResidue: Decimal;
  otherConcentratesNitrogen: Decimal;
  grassesProportion: Decimal;
  grassesCellulous: Decimal;
  grassesHemicellulous: Decimal;
  grassesSolubleResidue: Decimal;
  grassesNitrogen: Decimal;
  legumesProportion: Decimal;
  legumesCellulous: Decimal;
  legumesHemicellulous: Decimal;
  legumesSolubleResidue: Decimal;
  legumesNitrogen: Decimal;
} & { ' $fragmentName'?: 'FeedlotFeedCompositionFragmentFragment' };

export type FeedlotProductionFeedFragmentFragment = {
  __typename: 'FeedlotActivity';
  id: string;
  pasture?: boolean | null;
  grazingCrops?: boolean | null;
  feedComposition?:
    | ({ __typename: 'FeedlotFeedComposition' } & {
        ' $fragmentRefs'?: {
          FeedlotFeedCompositionFragmentFragment: FeedlotFeedCompositionFragmentFragment;
        };
      })
    | null;
  groups?: Array<{
    __typename: 'FeedlotGroup';
    id: string;
    name: string;
    feedBreakdown?: { __typename: 'FeedlotFeedBreakdown'; id: string } | null;
  }> | null;
} & { ' $fragmentName'?: 'FeedlotProductionFeedFragmentFragment' };

export type PropertiesBusinessActivitiesActivitiesHeaderFeedlotActivityFragment =
  {
    __typename: 'FeedlotActivity';
    id: string;
    name: string;
    start: string;
    end: string;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesActivitiesHeaderFeedlotActivityFragment';
  };

export type GetFeedlotActivitySystemQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetFeedlotActivitySystemQuery = {
  __typename: 'Query';
  feedlotActivity?:
    | ({ __typename: 'FeedlotActivity'; id: string } & {
        ' $fragmentRefs'?: {
          FeedlotProductionSystemFragmentFragment: FeedlotProductionSystemFragmentFragment;
        };
      })
    | null;
};

export type GetFeedlotActivityTransportQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetFeedlotActivityTransportQuery = {
  __typename: 'Query';
  feedlotActivity?:
    | ({ __typename: 'FeedlotActivity'; id: string } & {
        ' $fragmentRefs'?: {
          FeedlotProductionTransportFragmentFragment: FeedlotProductionTransportFragmentFragment;
        };
      })
    | null;
};

export type GetFeedlotActivityLivestockQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetFeedlotActivityLivestockQuery = {
  __typename: 'Query';
  feedlotActivity?:
    | ({ __typename: 'FeedlotActivity'; id: string } & {
        ' $fragmentRefs'?: {
          FeedlotProductionLivestockFragmentFragment: FeedlotProductionLivestockFragmentFragment;
        };
      })
    | null;
};

export type UpdateFeedlotMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateFeedlotActivityInput: UpdateFeedlotActivityInput;
}>;

export type UpdateFeedlotMutation = {
  __typename: 'Mutation';
  updateFeedlotActivity: { __typename: 'FeedlotActivity'; id: string } & {
    ' $fragmentRefs'?: {
      FeedlotProductionSystemFragmentFragment: FeedlotProductionSystemFragmentFragment;
      FeedlotProductionFeedFragmentFragment: FeedlotProductionFeedFragmentFragment;
    };
  };
};

export type GetFeedlotActivityFeedQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetFeedlotActivityFeedQuery = {
  __typename: 'Query';
  feedlotActivity?:
    | ({ __typename: 'FeedlotActivity'; id: string } & {
        ' $fragmentRefs'?: {
          FeedlotProductionFeedFragmentFragment: FeedlotProductionFeedFragmentFragment;
        };
      })
    | null;
};

export type FeedlotLivestockGroupFragmentFragment = {
  __typename: 'FeedlotGroup';
  id: string;
  name: string;
  numberOfLivestock: number;
  lengthOfStayDays: number;
  averageLiveweightKg: number;
} & { ' $fragmentName'?: 'FeedlotLivestockGroupFragmentFragment' };

export type FeedlotProductionLivestockFragmentFragment = {
  __typename: 'FeedlotActivity';
  id: string;
  groups?: Array<{
    __typename: 'FeedlotGroup';
    id: string;
    name: string;
  }> | null;
  purchases?: Array<{
    __typename: 'FeedlotPurchase';
    id: string;
    livestockCategory: LivestockCategory;
    head: number;
    beefClass: BeefClass;
  }> | null;
  sales?: Array<{
    __typename: 'FeedlotSale';
    id: string;
    livestockCategory: LivestockCategory;
    head: number;
    beefClass: BeefClass;
  }> | null;
} & { ' $fragmentName'?: 'FeedlotProductionLivestockFragmentFragment' };

export type FeedlotGroupQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type FeedlotGroupQuery = {
  __typename: 'Query';
  feedlotGroup?:
    | ({ __typename: 'FeedlotGroup'; id: string } & {
        ' $fragmentRefs'?: {
          FeedlotLivestockGroupFragmentFragment: FeedlotLivestockGroupFragmentFragment;
        };
      })
    | null;
};

export type CreateLivestockGroupMutationVariables = Exact<{
  feedlotActivityId: Scalars['ID']['input'];
  createFeedlotGroupInput: CreateFeedlotGroupInput;
}>;

export type CreateLivestockGroupMutation = {
  __typename: 'Mutation';
  createFeedlotGroup: { __typename: 'FeedlotGroup'; id: string } & {
    ' $fragmentRefs'?: {
      FeedlotLivestockGroupFragmentFragment: FeedlotLivestockGroupFragmentFragment;
    };
  };
};

export type UpdateLivestockGroupMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateFeedlotGroupInput: UpdateFeedlotGroupInput;
}>;

export type UpdateLivestockGroupMutation = {
  __typename: 'Mutation';
  updateFeedlotGroup: { __typename: 'FeedlotGroup'; id: string } & {
    ' $fragmentRefs'?: {
      FeedlotLivestockGroupFragmentFragment: FeedlotLivestockGroupFragmentFragment;
    };
  };
};

export type DeleteLivestockGroupMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteLivestockGroupMutation = {
  __typename: 'Mutation';
  deleteFeedlotGroup: { __typename: 'FeedlotGroup'; id: string };
};

export type FeedlotLivestockPurchaseFragmentFragment = {
  __typename: 'FeedlotPurchase';
  id: string;
  head: number;
  month: Month;
  year: number;
  weightPerHeadKg: number;
  beefClass: BeefClass;
  livestockCategory: LivestockCategory;
  source: FeedlotPurchaseSource;
} & { ' $fragmentName'?: 'FeedlotLivestockPurchaseFragmentFragment' };

export type FeedlotPurchaseQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type FeedlotPurchaseQuery = {
  __typename: 'Query';
  feedlotPurchase?:
    | ({ __typename: 'FeedlotPurchase'; id: string } & {
        ' $fragmentRefs'?: {
          FeedlotLivestockPurchaseFragmentFragment: FeedlotLivestockPurchaseFragmentFragment;
        };
      })
    | null;
};

export type CreateLivestockPurchaseMutationVariables = Exact<{
  feedlotActivityId: Scalars['ID']['input'];
  createFeedlotPurchaseInput: CreateFeedlotPurchaseInput;
}>;

export type CreateLivestockPurchaseMutation = {
  __typename: 'Mutation';
  createFeedlotPurchase: { __typename: 'FeedlotPurchase'; id: string } & {
    ' $fragmentRefs'?: {
      FeedlotLivestockPurchaseFragmentFragment: FeedlotLivestockPurchaseFragmentFragment;
    };
  };
};

export type UpdateLivestockPurchaseMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateFeedlotPurchaseInput: UpdateFeedlotPurchaseInput;
}>;

export type UpdateLivestockPurchaseMutation = {
  __typename: 'Mutation';
  updateFeedlotPurchase: { __typename: 'FeedlotPurchase'; id: string } & {
    ' $fragmentRefs'?: {
      FeedlotLivestockPurchaseFragmentFragment: FeedlotLivestockPurchaseFragmentFragment;
    };
  };
};

export type DeleteLivestockPurchaseMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteLivestockPurchaseMutation = {
  __typename: 'Mutation';
  deleteFeedlotPurchase: { __typename: 'FeedlotPurchase'; id: string } & {
    ' $fragmentRefs'?: {
      FeedlotLivestockPurchaseFragmentFragment: FeedlotLivestockPurchaseFragmentFragment;
    };
  };
};

export type FeedlotLivestockSaleFragmentFragment = {
  __typename: 'FeedlotSale';
  id: string;
  head: number;
  month: Month;
  year: number;
  weightPerHeadKg: number;
  beefClass: BeefClass;
  livestockCategory: LivestockCategory;
} & { ' $fragmentName'?: 'FeedlotLivestockSaleFragmentFragment' };

export type FeedlotSaleQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type FeedlotSaleQuery = {
  __typename: 'Query';
  feedlotSale?:
    | ({ __typename: 'FeedlotSale'; id: string } & {
        ' $fragmentRefs'?: {
          FeedlotLivestockSaleFragmentFragment: FeedlotLivestockSaleFragmentFragment;
        };
      })
    | null;
};

export type CreateLivestockSaleMutationVariables = Exact<{
  feedlotActivityId: Scalars['ID']['input'];
  createFeedlotSaleInput: CreateFeedlotSaleInput;
}>;

export type CreateLivestockSaleMutation = {
  __typename: 'Mutation';
  createFeedlotSale: { __typename: 'FeedlotSale'; id: string } & {
    ' $fragmentRefs'?: {
      FeedlotLivestockSaleFragmentFragment: FeedlotLivestockSaleFragmentFragment;
    };
  };
};

export type UpdateLivestockSaleMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateFeedlotSaleInput: UpdateFeedlotSaleInput;
}>;

export type UpdateLivestockSaleMutation = {
  __typename: 'Mutation';
  updateFeedlotSale: { __typename: 'FeedlotSale'; id: string } & {
    ' $fragmentRefs'?: {
      FeedlotLivestockSaleFragmentFragment: FeedlotLivestockSaleFragmentFragment;
    };
  };
};

export type DeleteLivestockSaleMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteLivestockSaleMutation = {
  __typename: 'Mutation';
  deleteFeedlotSale: { __typename: 'FeedlotSale'; id: string } & {
    ' $fragmentRefs'?: {
      FeedlotLivestockSaleFragmentFragment: FeedlotLivestockSaleFragmentFragment;
    };
  };
};

export type FeedlotProductionSystemFragmentFragment = {
  __typename: 'FeedlotActivity';
  id: string;
  system?: FeedlotSystem | null;
} & { ' $fragmentName'?: 'FeedlotProductionSystemFragmentFragment' };

export type FeedlotProductionTransportFragmentFragment = {
  __typename: 'FeedlotActivity';
  id: string;
  totalDistanceTravelledKm?: number | null;
  truckType?: TruckType | null;
} & { ' $fragmentName'?: 'FeedlotProductionTransportFragmentFragment' };

export type CreateFeedlotActivityMutationVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  createFeedlotActivityInput: CreateFeedlotActivityInput;
}>;

export type CreateFeedlotActivityMutation = {
  __typename: 'Mutation';
  createFeedlotActivity: { __typename: 'FeedlotActivity'; id: string };
};

export type GetFeedlotActivityInHeaderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetFeedlotActivityInHeaderQuery = {
  __typename: 'Query';
  feedlotActivity?:
    | ({ __typename: 'FeedlotActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesActivitiesHeaderFeedlotActivityFragment: PropertiesBusinessActivitiesActivitiesHeaderFeedlotActivityFragment;
        };
      })
    | null;
};

export type UpdateTransaction_GoatMonthLivestockActivityFragmentFragment = ({
  __typename: 'GoatMonthLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    TransactionForm_GoatMonthLivestockActivityFragmentFragment: TransactionForm_GoatMonthLivestockActivityFragmentFragment;
  };
}) & {
  ' $fragmentName'?: 'UpdateTransaction_GoatMonthLivestockActivityFragmentFragment';
};

export type GoatMonthlyForm_GoatMonthLivestockActivityFragment = {
  __typename: 'GoatMonthLivestockActivity';
  id: string;
  month: Month;
  year: number;
  nonSalePopulationChange: number;
  percentageDoesKidding: Decimal;
  seasonalKiddingRate: Decimal;
  keyEvents?: string | null;
  headAtStart: number;
  livestockTransactionEvents: Array<{
    __typename: 'LivestockTransactionEvent';
    id: string;
    transactionType: TransactionType;
    livestockCount: number;
  }>;
} & { ' $fragmentName'?: 'GoatMonthlyForm_GoatMonthLivestockActivityFragment' };

export type CreateTransaction_GoatMonthLivestockActivityFragmentFragment = ({
  __typename: 'GoatMonthLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    TransactionForm_GoatMonthLivestockActivityFragmentFragment: TransactionForm_GoatMonthLivestockActivityFragmentFragment;
  };
}) & {
  ' $fragmentName'?: 'CreateTransaction_GoatMonthLivestockActivityFragmentFragment';
};

export type TransactionForm_GoatMonthLivestockActivityFragmentFragment = {
  __typename: 'GoatMonthLivestockActivity';
  year: number;
  month: Month;
  goatClass: GoatClass;
} & {
  ' $fragmentName'?: 'TransactionForm_GoatMonthLivestockActivityFragmentFragment';
};

export type UpdateGoatMonthLivestockActivityForMonthlyBreakdownMutationVariables =
  Exact<{
    id: Scalars['ID']['input'];
    updateGoatMonthLivestockActivityInput: UpdateGoatMonthLivestockActivityInput;
  }>;

export type UpdateGoatMonthLivestockActivityForMonthlyBreakdownMutation = {
  __typename: 'Mutation';
  updateGoatMonthLivestockActivity: {
    __typename: 'GoatMonthLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      GoatMonthlyBreakdown_GoatMonthLivestockActivityFragment: GoatMonthlyBreakdown_GoatMonthLivestockActivityFragment;
    };
  };
};

export type CreateGoatLivestockTransactionEventForUpdateTransactionMutationVariables =
  Exact<{
    goatMonthLivestockActivityId: Scalars['ID']['input'];
    createLivestockTransactionEventInput: CreateLivestockTransactionEventInput;
  }>;

export type CreateGoatLivestockTransactionEventForUpdateTransactionMutation = {
  __typename: 'Mutation';
  createGoatLivestockTransactionEvent: {
    __typename: 'LivestockTransactionEvent';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      UpdateTransaction_LivestockTransactionEventFragmentFragment: UpdateTransaction_LivestockTransactionEventFragmentFragment;
    };
  };
};

export type GetGoatActivityForMonthlyBreakdownQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetGoatActivityForMonthlyBreakdownQuery = {
  __typename: 'Query';
  goatLivestockActivity?:
    | ({ __typename: 'GoatLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          GoatMonthlyBreakdown_GoatLivestockActivityFragmentFragment: GoatMonthlyBreakdown_GoatLivestockActivityFragmentFragment;
        };
      })
    | null;
};

export type GetGoatMonthLivestockActivitiesForMonthlyBreakdownQueryVariables =
  Exact<{
    listGoatMonthLivestockActivitiesInput: ListGoatMonthLivestockActivitiesInput;
  }>;

export type GetGoatMonthLivestockActivitiesForMonthlyBreakdownQuery = {
  __typename: 'Query';
  goatMonthLivestockActivities: Array<
    | ({ __typename: 'GoatMonthLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          GoatMonthlyBreakdown_GoatMonthLivestockActivityFragment: GoatMonthlyBreakdown_GoatMonthLivestockActivityFragment;
        };
      })
    | null
  >;
};

export type GetGoatMonthLivestockActivityForCreateUpdateTransactionQueryVariables =
  Exact<{
    id: Scalars['ID']['input'];
  }>;

export type GetGoatMonthLivestockActivityForCreateUpdateTransactionQuery = {
  __typename: 'Query';
  goatMonthLivestockActivity: {
    __typename: 'GoatMonthLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      CreateTransaction_GoatMonthLivestockActivityFragmentFragment: CreateTransaction_GoatMonthLivestockActivityFragmentFragment;
      UpdateTransaction_GoatMonthLivestockActivityFragmentFragment: UpdateTransaction_GoatMonthLivestockActivityFragmentFragment;
    };
  };
};

export type GoatMonthlyBreakdown_GoatLivestockActivityFragmentFragment = {
  __typename: 'GoatLivestockActivity';
  bucksBreeding: boolean;
  wethersBreeding: boolean;
  breedingDoesBreeding: boolean;
  maidenBreedingDoesBreeding: boolean;
  otherDoesBreeding: boolean;
  kidsBreeding: boolean;
  bucksTrading: boolean;
  wethersTrading: boolean;
  breedingDoesTrading: boolean;
  maidenBreedingDoesTrading: boolean;
  otherDoesTrading: boolean;
  kidsTrading: boolean;
} & {
  ' $fragmentName'?: 'GoatMonthlyBreakdown_GoatLivestockActivityFragmentFragment';
};

export type GoatMonthlyBreakdown_GoatMonthLivestockActivityFragment = ({
  __typename: 'GoatMonthLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    GoatMonthlyForm_GoatMonthLivestockActivityFragment: GoatMonthlyForm_GoatMonthLivestockActivityFragment;
  };
}) & {
  ' $fragmentName'?: 'GoatMonthlyBreakdown_GoatMonthLivestockActivityFragment';
};

export type GoatActivityLivestockBreedingGoatLivestockActivityFragment = {
  __typename: 'GoatLivestockActivity';
  bucksBreeding: boolean;
  wethersBreeding: boolean;
  breedingDoesBreeding: boolean;
  maidenBreedingDoesBreeding: boolean;
  otherDoesBreeding: boolean;
  kidsBreeding: boolean;
} & {
  ' $fragmentName'?: 'GoatActivityLivestockBreedingGoatLivestockActivityFragment';
};

export type GoatActivityLivestockTradingGoatLivestockActivityFragment = {
  __typename: 'GoatLivestockActivity';
  bucksTrading: boolean;
  wethersTrading: boolean;
  breedingDoesTrading: boolean;
  maidenBreedingDoesTrading: boolean;
  otherDoesTrading: boolean;
  kidsTrading: boolean;
} & {
  ' $fragmentName'?: 'GoatActivityLivestockTradingGoatLivestockActivityFragment';
};

export type GoatActivityLivestockGoatLivestockActivityFragment = ({
  __typename: 'GoatLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    GoatActivityLivestockBreedingGoatLivestockActivityFragment: GoatActivityLivestockBreedingGoatLivestockActivityFragment;
    GoatActivityLivestockTradingGoatLivestockActivityFragment: GoatActivityLivestockTradingGoatLivestockActivityFragment;
  };
}) & {
  ' $fragmentName'?: 'GoatActivityLivestockGoatLivestockActivityFragment';
};

export type GetGoatLivestockActivityFeedDrySeasonMixQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetGoatLivestockActivityFeedDrySeasonMixQuery = {
  __typename: 'Query';
  goatLivestockActivity?:
    | ({ __typename: 'GoatLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          GoatFeedDrySeasonMix_GoatLivestockActivityFragmentFragment: GoatFeedDrySeasonMix_GoatLivestockActivityFragmentFragment;
        };
      })
    | null;
};

export type GoatFeedDrySeasonMix_GoatLivestockActivityFragmentFragment = {
  __typename: 'GoatLivestockActivity';
  feed?:
    | ({ __typename: 'Feed' } & {
        ' $fragmentRefs'?: {
          FeedDrySeasonMix_FeedFragmentFragment: FeedDrySeasonMix_FeedFragmentFragment;
        };
      })
    | null;
} & {
  ' $fragmentName'?: 'GoatFeedDrySeasonMix_GoatLivestockActivityFragmentFragment';
};

export type GetGoatActivityFeedQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetGoatActivityFeedQuery = {
  __typename: 'Query';
  goatLivestockActivity?:
    | ({ __typename: 'GoatLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          GoatActivityLivestockFeed_GoatLivestockActivityFragmentFragment: GoatActivityLivestockFeed_GoatLivestockActivityFragmentFragment;
        };
      })
    | null;
};

export type GoatActivityLivestockFeed_GoatLivestockActivityFragmentFragment = {
  __typename: 'GoatLivestockActivity';
  id: string;
  feed?: {
    __typename: 'Feed';
    id: string;
    pasture: boolean;
    grazingCrops: boolean;
    mineralBlock: boolean;
    weanerBlock: boolean;
    drySeasonMix: boolean;
  } | null;
} & {
  ' $fragmentName'?: 'GoatActivityLivestockFeed_GoatLivestockActivityFragmentFragment';
};

export type GetGoatLivestockActivityFeedMineralBlockQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetGoatLivestockActivityFeedMineralBlockQuery = {
  __typename: 'Query';
  goatLivestockActivity?:
    | ({ __typename: 'GoatLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          GoatFeedMineralBlock_GoatLivestockActivityFragmentFragment: GoatFeedMineralBlock_GoatLivestockActivityFragmentFragment;
        };
      })
    | null;
};

export type GoatFeedMineralBlock_GoatLivestockActivityFragmentFragment = {
  __typename: 'GoatLivestockActivity';
  feed?:
    | ({ __typename: 'Feed' } & {
        ' $fragmentRefs'?: {
          FeedMineralBlock_FeedFragmentFragment: FeedMineralBlock_FeedFragmentFragment;
        };
      })
    | null;
} & {
  ' $fragmentName'?: 'GoatFeedMineralBlock_GoatLivestockActivityFragmentFragment';
};

export type UpdateGoatFeedWeanerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateFeedInput: UpdateFeedInput;
}>;

export type UpdateGoatFeedWeanerMutation = {
  __typename: 'Mutation';
  updateFeed: { __typename: 'Feed'; id: string } & {
    ' $fragmentRefs'?: {
      FeedWeanerBlock_FeedFragmentFragment: FeedWeanerBlock_FeedFragmentFragment;
    };
  };
};

export type GetGoatLivestockActivityFeedWeanerQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetGoatLivestockActivityFeedWeanerQuery = {
  __typename: 'Query';
  goatLivestockActivity?:
    | ({ __typename: 'GoatLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          GoatFeedWeanerBlock_GoatLivestockActivityFragmentFragment: GoatFeedWeanerBlock_GoatLivestockActivityFragmentFragment;
        };
      })
    | null;
};

export type GoatFeedWeanerBlock_GoatLivestockActivityFragmentFragment = {
  __typename: 'GoatLivestockActivity';
  feed?:
    | ({ __typename: 'Feed' } & {
        ' $fragmentRefs'?: {
          FeedWeanerBlock_FeedFragmentFragment: FeedWeanerBlock_FeedFragmentFragment;
        };
      })
    | null;
} & {
  ' $fragmentName'?: 'GoatFeedWeanerBlock_GoatLivestockActivityFragmentFragment';
};

export type PropertiesBusinessActivitiesActivitiesHeaderGoatActivityFragment = {
  __typename: 'GoatLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'PropertiesBusinessActivitiesActivitiesHeaderGoatActivityFragment';
};

export type GetGoatActivityQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetGoatActivityQuery = {
  __typename: 'Query';
  goatLivestockActivity?:
    | ({ __typename: 'GoatLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          GoatActivityLivestockGoatLivestockActivityFragment: GoatActivityLivestockGoatLivestockActivityFragment;
        };
      })
    | null;
};

export type GetGoatMonthLivestockActivitiesForActivityQueryVariables = Exact<{
  listGoatMonthLivestockActivitiesInput: ListGoatMonthLivestockActivitiesInput;
}>;

export type GetGoatMonthLivestockActivitiesForActivityQuery = {
  __typename: 'Query';
  goatMonthLivestockActivities: Array<
    | ({ __typename: 'GoatMonthLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          GoatLivestockActivity_MonthActivitiesFragment: GoatLivestockActivity_MonthActivitiesFragment;
        };
      })
    | null
  >;
};

export type GetGoatActivityInHeaderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetGoatActivityInHeaderQuery = {
  __typename: 'Query';
  goatLivestockActivity?:
    | ({ __typename: 'GoatLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesActivitiesHeaderGoatActivityFragment: PropertiesBusinessActivitiesActivitiesHeaderGoatActivityFragment;
        };
      })
    | null;
};

export type UpdateGoatLivestockActivityMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateGoatLivestockActivityInput: UpdateGoatLivestockActivityInput;
}>;

export type UpdateGoatLivestockActivityMutation = {
  __typename: 'Mutation';
  updateGoatLivestockActivity: {
    __typename: 'GoatLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      GoatActivityLivestockBreedingGoatLivestockActivityFragment: GoatActivityLivestockBreedingGoatLivestockActivityFragment;
      GoatActivityLivestockTradingGoatLivestockActivityFragment: GoatActivityLivestockTradingGoatLivestockActivityFragment;
    };
  };
};

export type GetGoatMonthLivestockActivitiesForShearingQueryVariables = Exact<{
  listGoatMonthLivestockActivitiesInput: ListGoatMonthLivestockActivitiesInput;
}>;

export type GetGoatMonthLivestockActivitiesForShearingQuery = {
  __typename: 'Query';
  goatMonthLivestockActivities: Array<
    | ({ __typename: 'GoatMonthLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          GoatShearingEventMonthActivitiesFragment: GoatShearingEventMonthActivitiesFragment;
        };
      })
    | null
  >;
};

export type GoatWoolShearingEventsFragmentFragment = {
  __typename: 'GoatShearingEvent';
  id: string;
  month: Month;
  year: number;
  numberGoatShorn: number;
  woolPerHeadKg: Decimal;
  cleanWoolYieldKg: Decimal;
  goatLivestockActivityId?: string | null;
} & { ' $fragmentName'?: 'GoatWoolShearingEventsFragmentFragment' };

export type GetGoatShearingEventsQueryVariables = Exact<{
  activityId: Scalars['ID']['input'];
}>;

export type GetGoatShearingEventsQuery = {
  __typename: 'Query';
  shearingEventsByGoatLivestockActivityId: Array<
    | ({ __typename: 'GoatShearingEvent'; id: string } & {
        ' $fragmentRefs'?: {
          GoatWoolShearingEventsFragmentFragment: GoatWoolShearingEventsFragmentFragment;
        };
      })
    | null
  >;
};

export type GetGoatShearingEventQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetGoatShearingEventQuery = {
  __typename: 'Query';
  goatShearingEvent: { __typename: 'GoatShearingEvent'; id: string } & {
    ' $fragmentRefs'?: {
      WoolUpdateGoatShearingEventFragmentFragment: WoolUpdateGoatShearingEventFragmentFragment;
    };
  };
};

export type CreateGoatShearingEventMutationVariables = Exact<{
  goatLivestockActivityId: Scalars['ID']['input'];
  input: CreateGoatShearingEventInput;
}>;

export type CreateGoatShearingEventMutation = {
  __typename: 'Mutation';
  createGoatShearingEvent: { __typename: 'GoatShearingEvent'; id: string } & {
    ' $fragmentRefs'?: {
      GoatWoolShearingEventsFragmentFragment: GoatWoolShearingEventsFragmentFragment;
    };
  };
};

export type UpdateGoatShearingEventMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateGoatShearingEventInput;
}>;

export type UpdateGoatShearingEventMutation = {
  __typename: 'Mutation';
  updateGoatShearingEvent: { __typename: 'GoatShearingEvent'; id: string } & {
    ' $fragmentRefs'?: {
      GoatWoolShearingEventsFragmentFragment: GoatWoolShearingEventsFragmentFragment;
    };
  };
};

export type DeleteGoatShearingEventMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteGoatShearingEventMutation = {
  __typename: 'Mutation';
  deleteGoatShearingEvent: { __typename: 'GoatShearingEvent'; id: string };
};

export type GetGoatActivityForShearingQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetGoatActivityForShearingQuery = {
  __typename: 'Query';
  goatLivestockActivity?:
    | ({ __typename: 'GoatLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          GoatShearingEventGoatLivestockActivityFragment: GoatShearingEventGoatLivestockActivityFragment;
        };
      })
    | null;
};

export type WoolUpdateGoatShearingEventFragmentFragment = {
  __typename: 'GoatShearingEvent';
  id: string;
  date: string;
  goatClass?: GoatClass | null;
  livestockCategory: LivestockCategory;
  numberGoatShorn: number;
  woolPerHeadKg: Decimal;
  cleanWoolYieldKg: Decimal;
  goatLivestockActivityId?: string | null;
} & { ' $fragmentName'?: 'WoolUpdateGoatShearingEventFragmentFragment' };

export type GoatShearingEventGoatLivestockActivityFragment = {
  __typename: 'GoatLivestockActivity';
  start: string;
  end: string;
} & { ' $fragmentName'?: 'GoatShearingEventGoatLivestockActivityFragment' };

export type GoatShearingEventMonthActivitiesFragment = {
  __typename: 'GoatMonthLivestockActivity';
  id: string;
  month: Month;
  year: number;
  headAtStart: number;
  goatClass: GoatClass;
  livestockCategory: LivestockCategory;
} & { ' $fragmentName'?: 'GoatShearingEventMonthActivitiesFragment' };

export type GoatLivestockActivity_MonthActivitiesFragment = {
  __typename: 'GoatMonthLivestockActivity';
  id: string;
  month: Month;
  year: number;
  headAtStart: number;
  goatClass: GoatClass;
} & { ' $fragmentName'?: 'GoatLivestockActivity_MonthActivitiesFragment' };

export type CreateGoatLivestockActivityMutationVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  createGoatLivestockActivityInput: CreateGoatLivestockActivityInput;
}>;

export type CreateGoatLivestockActivityMutation = {
  __typename: 'Mutation';
  createGoatLivestockActivity: {
    __typename: 'GoatLivestockActivity';
    id: string;
  };
};

export type GetActivitiesQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
}>;

export type GetActivitiesQuery = {
  __typename: 'Query';
  activities: Array<
    | ({ __typename: 'BeefLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesIndexActivity_BeefLivestockActivity_Fragment: ActivitiesIndexActivity_BeefLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'BuffaloLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesIndexActivity_BuffaloLivestockActivity_Fragment: ActivitiesIndexActivity_BuffaloLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'BurningActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesIndexActivity_BurningActivity_Fragment: ActivitiesIndexActivity_BurningActivity_Fragment;
        };
      })
    | ({ __typename: 'CroppingActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesIndexActivity_CroppingActivity_Fragment: ActivitiesIndexActivity_CroppingActivity_Fragment;
        };
      })
    | ({ __typename: 'DairyLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesIndexActivity_DairyLivestockActivity_Fragment: ActivitiesIndexActivity_DairyLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'DeerLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesIndexActivity_DeerLivestockActivity_Fragment: ActivitiesIndexActivity_DeerLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'FeedlotActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesIndexActivity_FeedlotActivity_Fragment: ActivitiesIndexActivity_FeedlotActivity_Fragment;
        };
      })
    | ({ __typename: 'GoatLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesIndexActivity_GoatLivestockActivity_Fragment: ActivitiesIndexActivity_GoatLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'PorkLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesIndexActivity_PorkLivestockActivity_Fragment: ActivitiesIndexActivity_PorkLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'PoultryBroilerActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesIndexActivity_PoultryBroilerActivity_Fragment: ActivitiesIndexActivity_PoultryBroilerActivity_Fragment;
        };
      })
    | ({ __typename: 'PoultryLayerActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesIndexActivity_PoultryLayerActivity_Fragment: ActivitiesIndexActivity_PoultryLayerActivity_Fragment;
        };
      })
    | ({ __typename: 'SheepLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesIndexActivity_SheepLivestockActivity_Fragment: ActivitiesIndexActivity_SheepLivestockActivity_Fragment;
        };
      })
    | ({ __typename: 'VegetationActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesIndexActivity_VegetationActivity_Fragment: ActivitiesIndexActivity_VegetationActivity_Fragment;
        };
      })
    | ({ __typename: 'WildSeaFisheryActivity'; id: string } & {
        ' $fragmentRefs'?: {
          ActivitiesIndexActivity_WildSeaFisheryActivity_Fragment: ActivitiesIndexActivity_WildSeaFisheryActivity_Fragment;
        };
      })
  >;
};

type ActivitiesIndexActivity_BeefLivestockActivity_Fragment = {
  __typename: 'BeefLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'ActivitiesIndexActivity_BeefLivestockActivity_Fragment';
};

type ActivitiesIndexActivity_BuffaloLivestockActivity_Fragment = {
  __typename: 'BuffaloLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'ActivitiesIndexActivity_BuffaloLivestockActivity_Fragment';
};

type ActivitiesIndexActivity_BurningActivity_Fragment = {
  __typename: 'BurningActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & { ' $fragmentName'?: 'ActivitiesIndexActivity_BurningActivity_Fragment' };

type ActivitiesIndexActivity_CroppingActivity_Fragment = {
  __typename: 'CroppingActivity';
  croppingType: CroppingType;
  id: string;
  name: string;
  start: string;
  end: string;
} & { ' $fragmentName'?: 'ActivitiesIndexActivity_CroppingActivity_Fragment' };

type ActivitiesIndexActivity_DairyLivestockActivity_Fragment = {
  __typename: 'DairyLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'ActivitiesIndexActivity_DairyLivestockActivity_Fragment';
};

type ActivitiesIndexActivity_DeerLivestockActivity_Fragment = {
  __typename: 'DeerLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'ActivitiesIndexActivity_DeerLivestockActivity_Fragment';
};

type ActivitiesIndexActivity_FeedlotActivity_Fragment = {
  __typename: 'FeedlotActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & { ' $fragmentName'?: 'ActivitiesIndexActivity_FeedlotActivity_Fragment' };

type ActivitiesIndexActivity_GoatLivestockActivity_Fragment = {
  __typename: 'GoatLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'ActivitiesIndexActivity_GoatLivestockActivity_Fragment';
};

type ActivitiesIndexActivity_PorkLivestockActivity_Fragment = {
  __typename: 'PorkLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'ActivitiesIndexActivity_PorkLivestockActivity_Fragment';
};

type ActivitiesIndexActivity_PoultryBroilerActivity_Fragment = {
  __typename: 'PoultryBroilerActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'ActivitiesIndexActivity_PoultryBroilerActivity_Fragment';
};

type ActivitiesIndexActivity_PoultryLayerActivity_Fragment = {
  __typename: 'PoultryLayerActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'ActivitiesIndexActivity_PoultryLayerActivity_Fragment';
};

type ActivitiesIndexActivity_SheepLivestockActivity_Fragment = {
  __typename: 'SheepLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'ActivitiesIndexActivity_SheepLivestockActivity_Fragment';
};

type ActivitiesIndexActivity_VegetationActivity_Fragment = {
  __typename: 'VegetationActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'ActivitiesIndexActivity_VegetationActivity_Fragment';
};

type ActivitiesIndexActivity_WildSeaFisheryActivity_Fragment = {
  __typename: 'WildSeaFisheryActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'ActivitiesIndexActivity_WildSeaFisheryActivity_Fragment';
};

export type ActivitiesIndexActivityFragment =
  | ActivitiesIndexActivity_BeefLivestockActivity_Fragment
  | ActivitiesIndexActivity_BuffaloLivestockActivity_Fragment
  | ActivitiesIndexActivity_BurningActivity_Fragment
  | ActivitiesIndexActivity_CroppingActivity_Fragment
  | ActivitiesIndexActivity_DairyLivestockActivity_Fragment
  | ActivitiesIndexActivity_DeerLivestockActivity_Fragment
  | ActivitiesIndexActivity_FeedlotActivity_Fragment
  | ActivitiesIndexActivity_GoatLivestockActivity_Fragment
  | ActivitiesIndexActivity_PorkLivestockActivity_Fragment
  | ActivitiesIndexActivity_PoultryBroilerActivity_Fragment
  | ActivitiesIndexActivity_PoultryLayerActivity_Fragment
  | ActivitiesIndexActivity_SheepLivestockActivity_Fragment
  | ActivitiesIndexActivity_VegetationActivity_Fragment
  | ActivitiesIndexActivity_WildSeaFisheryActivity_Fragment;

export type CreateActivityFeedMutationVariables = Exact<{
  activityId: Scalars['ID']['input'];
  createFeedInput: CreateFeedInput;
}>;

export type CreateActivityFeedMutation = {
  __typename: 'Mutation';
  createFeed: { __typename: 'Feed'; id: string } & {
    ' $fragmentRefs'?: {
      ActivityLivestockFeed_FeedFragmentFragment: ActivityLivestockFeed_FeedFragmentFragment;
    };
  };
};

export type UpdateActivityFeedMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateFeedInput: UpdateFeedInput;
}>;

export type UpdateActivityFeedMutation = {
  __typename: 'Mutation';
  updateFeed: { __typename: 'Feed'; id: string } & {
    ' $fragmentRefs'?: {
      ActivityLivestockFeed_FeedFragmentFragment: ActivityLivestockFeed_FeedFragmentFragment;
    };
  };
};

export type ActivityLivestockFeed_FeedFragmentFragment = {
  __typename: 'Feed';
  id: string;
  pasture: boolean;
  grazingCrops: boolean;
  mineralBlock: boolean;
  weanerBlock: boolean;
  drySeasonMix: boolean;
} & { ' $fragmentName'?: 'ActivityLivestockFeed_FeedFragmentFragment' };

export type TransactionForm_LivestockTransactionEventFragmentFragment = {
  __typename: 'LivestockTransactionEvent';
  transactionType: TransactionType;
  livestockCount: number;
  totalWeightKg: Decimal;
  averageWeightKg?: Decimal | null;
  transactionRegion?: TransactionRegion | null;
  headPurchasedMerino?: number | null;
} & {
  ' $fragmentName'?: 'TransactionForm_LivestockTransactionEventFragmentFragment';
};

export type GetLivestockTransactionEventForUpdateTransactionQueryVariables =
  Exact<{
    id: Scalars['ID']['input'];
  }>;

export type GetLivestockTransactionEventForUpdateTransactionQuery = {
  __typename: 'Query';
  livestockTransactionEvent?:
    | ({ __typename: 'LivestockTransactionEvent'; id: string } & {
        ' $fragmentRefs'?: {
          UpdateTransaction_LivestockTransactionEventFragmentFragment: UpdateTransaction_LivestockTransactionEventFragmentFragment;
        };
      })
    | null;
};

export type DeleteLivestockTransactionEventMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteLivestockTransactionEventMutation = {
  __typename: 'Mutation';
  deleteLivestockTransactionEvent?:
    | ({ __typename: 'LivestockTransactionEvent'; id: string } & {
        ' $fragmentRefs'?: {
          UpdateTransaction_LivestockTransactionEventFragmentFragment: UpdateTransaction_LivestockTransactionEventFragmentFragment;
        };
      })
    | null;
};

export type PorkFeedBlendEditFragmentFragment = {
  __typename: 'PorkFeedBlend';
  id: string;
  amountPurchasedTonnes: Decimal;
  barley: Decimal;
  beetPulp: Decimal;
  blendType: PorkFeedBlendType;
  bloodMeal: Decimal;
  canolaMeal: Decimal;
  emissionsIntensityFeedProduction?: Decimal | null;
  fishMeal: Decimal;
  meatMeal: Decimal;
  millMix: Decimal;
  percentageAdditionalIngredients?: Decimal | null;
  soybeanMeal: Decimal;
  tallow: Decimal;
  wheat: Decimal;
  wheatBran: Decimal;
  wheyPowder: Decimal;
} & { ' $fragmentName'?: 'PorkFeedBlendEditFragmentFragment' };

export type GetPorkFeedBlendQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPorkFeedBlendQuery = {
  __typename: 'Query';
  porkFeedBlend?:
    | ({ __typename: 'PorkFeedBlend'; id: string } & {
        ' $fragmentRefs'?: {
          PorkFeedBlendEditFragmentFragment: PorkFeedBlendEditFragmentFragment;
        };
      })
    | null;
};

export type DeletePorkFeedBlendMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeletePorkFeedBlendMutation = {
  __typename: 'Mutation';
  deletePorkFeedBlend?:
    | ({ __typename: 'PorkFeedBlend'; id: string } & {
        ' $fragmentRefs'?: {
          PorkFeedBlendEditFragmentFragment: PorkFeedBlendEditFragmentFragment;
        };
      })
    | null;
};

export type UpdatePorkFeedBlendMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updatePorkFeedBlendInput: UpdatePorkFeedBlendInput;
}>;

export type UpdatePorkFeedBlendMutation = {
  __typename: 'Mutation';
  updatePorkFeedBlend?:
    | ({ __typename: 'PorkFeedBlend'; id: string } & {
        ' $fragmentRefs'?: {
          PorkFeedBlendEditFragmentFragment: PorkFeedBlendEditFragmentFragment;
        };
      })
    | null;
};

export type CreatePorkFeedBlendMutationVariables = Exact<{
  porkLivestockActivityId: Scalars['ID']['input'];
  createPorkFeedBlendInput: CreatePorkFeedBlendInput;
}>;

export type CreatePorkFeedBlendMutation = {
  __typename: 'Mutation';
  createPorkFeedBlend: { __typename: 'PorkFeedBlend'; id: string } & {
    ' $fragmentRefs'?: {
      PorkFeedBlendEditFragmentFragment: PorkFeedBlendEditFragmentFragment;
    };
  };
};

export type GetPorkActivityFeedBlendsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPorkActivityFeedBlendsQuery = {
  __typename: 'Query';
  porkLivestockActivity?:
    | ({ __typename: 'PorkLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PorkLivestockActivityFeedBlendsFragmentFragment: PorkLivestockActivityFeedBlendsFragmentFragment;
        };
      })
    | null;
};

export type PorkFeedBlendsFragmentFragment = {
  __typename: 'PorkFeedBlend';
  id: string;
  amountPurchasedTonnes: Decimal;
  blendType: PorkFeedBlendType;
} & { ' $fragmentName'?: 'PorkFeedBlendsFragmentFragment' };

export type PorkLivestockActivityFeedBlendsFragmentFragment = {
  __typename: 'PorkLivestockActivity';
  id: string;
  feed?: {
    __typename: 'Feed';
    id: string;
    pasture: boolean;
    grazingCrops: boolean;
  } | null;
  feedBlends?: Array<
    { __typename: 'PorkFeedBlend' } & {
      ' $fragmentRefs'?: {
        PorkFeedBlendsFragmentFragment: PorkFeedBlendsFragmentFragment;
      };
    }
  > | null;
} & { ' $fragmentName'?: 'PorkLivestockActivityFeedBlendsFragmentFragment' };

export type PropertiesBusinessActivitiesActivitiesHeaderPorkActivityFragment = {
  __typename: 'PorkLivestockActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'PropertiesBusinessActivitiesActivitiesHeaderPorkActivityFragment';
};

export type GetPorkActivityQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPorkActivityQuery = {
  __typename: 'Query';
  porkLivestockActivity?:
    | ({ __typename: 'PorkLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PorkActivityLivestockPorkLivestockActivityFragment: PorkActivityLivestockPorkLivestockActivityFragment;
        };
      })
    | null;
};

export type GetPorkMonthLivestockActivitiesForActivityQueryVariables = Exact<{
  listPorkMonthLivestockActivitiesInput: ListPorkMonthLivestockActivitiesInput;
}>;

export type GetPorkMonthLivestockActivitiesForActivityQuery = {
  __typename: 'Query';
  porkMonthLivestockActivities: Array<
    | ({ __typename: 'PorkMonthLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PorkLivestockActivity_MonthActivitiesFragment: PorkLivestockActivity_MonthActivitiesFragment;
        };
      })
    | null
  >;
};

export type GetPorkActivityInHeaderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPorkActivityInHeaderQuery = {
  __typename: 'Query';
  porkLivestockActivity?:
    | ({ __typename: 'PorkLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesActivitiesHeaderPorkActivityFragment: PropertiesBusinessActivitiesActivitiesHeaderPorkActivityFragment;
        };
      })
    | null;
};

export type UpdatePorkLivestockActivityMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updatePorkLivestockActivityInput: UpdatePorkLivestockActivityInput;
}>;

export type UpdatePorkLivestockActivityMutation = {
  __typename: 'Mutation';
  updatePorkLivestockActivity: {
    __typename: 'PorkLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      PorkActivityLivestockClassesFragmentFragment: PorkActivityLivestockClassesFragmentFragment;
    };
  };
};

export type UpdateTransaction_PorkMonthLivestockActivityFragmentFragment = ({
  __typename: 'PorkMonthLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    TransactionForm_PorkMonthLivestockActivityFragmentFragment: TransactionForm_PorkMonthLivestockActivityFragmentFragment;
  };
}) & {
  ' $fragmentName'?: 'UpdateTransaction_PorkMonthLivestockActivityFragmentFragment';
};

export type CreateTransaction_PorkMonthLivestockActivityFragmentFragment = ({
  __typename: 'PorkMonthLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    TransactionForm_PorkMonthLivestockActivityFragmentFragment: TransactionForm_PorkMonthLivestockActivityFragmentFragment;
  };
}) & {
  ' $fragmentName'?: 'CreateTransaction_PorkMonthLivestockActivityFragmentFragment';
};

export type PorkMonthlyForm_PorkMonthLivestockActivityFragment = {
  __typename: 'PorkMonthLivestockActivity';
  id: string;
  month: Month;
  year: number;
  nonSalePopulationChange: number;
  keyEvents?: string | null;
  headAtStart: number;
  livestockTransactionEvents: Array<{
    __typename: 'LivestockTransactionEvent';
    id: string;
    transactionType: TransactionType;
    livestockCount: number;
  } | null>;
} & { ' $fragmentName'?: 'PorkMonthlyForm_PorkMonthLivestockActivityFragment' };

export type TransactionForm_PorkMonthLivestockActivityFragmentFragment = {
  __typename: 'PorkMonthLivestockActivity';
  year: number;
  month: Month;
  porkClass: PorkClass;
} & {
  ' $fragmentName'?: 'TransactionForm_PorkMonthLivestockActivityFragmentFragment';
};

export type UpdatePorkMonthLivestockActivityForMonthlyBreakdownMutationVariables =
  Exact<{
    id: Scalars['ID']['input'];
    updatePorkMonthLivestockActivityInput: UpdatePorkMonthLivestockActivityInput;
  }>;

export type UpdatePorkMonthLivestockActivityForMonthlyBreakdownMutation = {
  __typename: 'Mutation';
  updatePorkMonthLivestockActivity: {
    __typename: 'PorkMonthLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      PorkMonthlyBreakdown_PorkMonthLivestockActivityFragment: PorkMonthlyBreakdown_PorkMonthLivestockActivityFragment;
    };
  };
};

export type CreatePorkLivestockTransactionEventForUpdateTransactionMutationVariables =
  Exact<{
    porkMonthLivestockActivityId: Scalars['ID']['input'];
    createLivestockTransactionEventInput: CreateLivestockTransactionEventInput;
  }>;

export type CreatePorkLivestockTransactionEventForUpdateTransactionMutation = {
  __typename: 'Mutation';
  createPorkLivestockTransactionEvent: {
    __typename: 'LivestockTransactionEvent';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      UpdateTransaction_LivestockTransactionEventFragmentFragment: UpdateTransaction_LivestockTransactionEventFragmentFragment;
    };
  };
};

export type GetPorkActivityForMonthlyBreakdownQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPorkActivityForMonthlyBreakdownQuery = {
  __typename: 'Query';
  porkLivestockActivity?:
    | ({ __typename: 'PorkLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PorkMonthlyBreakdown_PorkLivestockActivityFragmentFragment: PorkMonthlyBreakdown_PorkLivestockActivityFragmentFragment;
        };
      })
    | null;
};

export type GetPorkMonthLivestockActivitiesForMonthlyBreakdownQueryVariables =
  Exact<{
    listPorkMonthLivestockActivitiesInput: ListPorkMonthLivestockActivitiesInput;
  }>;

export type GetPorkMonthLivestockActivitiesForMonthlyBreakdownQuery = {
  __typename: 'Query';
  porkMonthLivestockActivities: Array<
    | ({ __typename: 'PorkMonthLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PorkMonthlyBreakdown_PorkMonthLivestockActivityFragment: PorkMonthlyBreakdown_PorkMonthLivestockActivityFragment;
        };
      })
    | null
  >;
};

export type GetPorkMonthLivestockActivityForCreateUpdateTransactionQueryVariables =
  Exact<{
    id: Scalars['ID']['input'];
  }>;

export type GetPorkMonthLivestockActivityForCreateUpdateTransactionQuery = {
  __typename: 'Query';
  porkMonthLivestockActivity: {
    __typename: 'PorkMonthLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      CreateTransaction_PorkMonthLivestockActivityFragmentFragment: CreateTransaction_PorkMonthLivestockActivityFragmentFragment;
      UpdateTransaction_PorkMonthLivestockActivityFragmentFragment: UpdateTransaction_PorkMonthLivestockActivityFragmentFragment;
    };
  };
};

export type PorkMonthlyBreakdown_PorkLivestockActivityFragmentFragment = {
  __typename: 'PorkLivestockActivity';
  sows: boolean;
  boars: boolean;
  gilts: boolean;
  weaners: boolean;
  growers: boolean;
  suckers: boolean;
  slaughterPigs: boolean;
} & {
  ' $fragmentName'?: 'PorkMonthlyBreakdown_PorkLivestockActivityFragmentFragment';
};

export type PorkMonthlyBreakdown_PorkMonthLivestockActivityFragment = ({
  __typename: 'PorkMonthLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    PorkMonthlyForm_PorkMonthLivestockActivityFragment: PorkMonthlyForm_PorkMonthLivestockActivityFragment;
  };
}) & {
  ' $fragmentName'?: 'PorkMonthlyBreakdown_PorkMonthLivestockActivityFragment';
};

export type PorkActivityLivestockClassesFragmentFragment = {
  __typename: 'PorkLivestockActivity';
  sows: boolean;
  boars: boolean;
  gilts: boolean;
  weaners: boolean;
  growers: boolean;
  suckers: boolean;
  slaughterPigs: boolean;
} & { ' $fragmentName'?: 'PorkActivityLivestockClassesFragmentFragment' };

export type PorkActivityLivestockPorkLivestockActivityFragment = ({
  __typename: 'PorkLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    PorkActivityLivestockClassesFragmentFragment: PorkActivityLivestockClassesFragmentFragment;
  };
}) & {
  ' $fragmentName'?: 'PorkActivityLivestockPorkLivestockActivityFragment';
};

export type PorkLivestockActivity_MonthActivitiesFragment = {
  __typename: 'PorkMonthLivestockActivity';
  id: string;
  month: Month;
  year: number;
  headAtStart: number;
  porkClass: PorkClass;
} & { ' $fragmentName'?: 'PorkLivestockActivity_MonthActivitiesFragment' };

export type CreatePorkLivestockActivityMutationVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  createPorkLivestockActivityInput: CreatePorkLivestockActivityInput;
}>;

export type CreatePorkLivestockActivityMutation = {
  __typename: 'Mutation';
  createPorkLivestockActivity: {
    __typename: 'PorkLivestockActivity';
    id: string;
  };
};

export type PoultryBroilersBatchFragmentFragment = {
  __typename: 'PoultryGrowerBatch';
  name: string;
  startDate: string;
  endDate: string;
} & { ' $fragmentName'?: 'PoultryBroilersBatchFragmentFragment' };

export type PoultryBroilersBatchFeedBreakdownFragmentFragment = {
  __typename: 'PoultryFeedBreakdown';
  dryMatterIntakeKgPerHeadPerDay: Decimal;
  dryMatterDigestibilityPercent: Decimal;
  crudeProteinPercent: Decimal;
  manureAshPercent: Decimal;
  nitrogenRetentionPercent: Decimal;
} & { ' $fragmentName'?: 'PoultryBroilersBatchFeedBreakdownFragmentFragment' };

export type PoultryGrowerBatchFeedBlendFragmentFragment = {
  __typename: 'PoultryFeedBlend';
  blendType: PoultryFeedBlendType;
  barleyWhole?: Decimal | null;
  millRun?: Decimal | null;
  sorghum?: Decimal | null;
  soybean?: Decimal | null;
  wheat?: Decimal | null;
  amountPurchasedTonnes: Decimal;
  percentageAdditionalIngredients?: Decimal | null;
  emissionsIntensityFeedProduction?: Decimal | null;
} & { ' $fragmentName'?: 'PoultryGrowerBatchFeedBlendFragmentFragment' };

export type GetPoultryGrowerBatchFeedBlendQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPoultryGrowerBatchFeedBlendQuery = {
  __typename: 'Query';
  poultryFeedBlend?:
    | ({ __typename: 'PoultryFeedBlend'; id: string } & {
        ' $fragmentRefs'?: {
          PoultryGrowerBatchFeedBlendFragmentFragment: PoultryGrowerBatchFeedBlendFragmentFragment;
        };
      })
    | null;
};

export type CreateGrowerBatchFeedBlendMutationVariables = Exact<{
  batchId: Scalars['ID']['input'];
  createPoultryGrowerBatchFeedBlendInput: CreatePoultryGrowerBatchFeedBlendInput;
}>;

export type CreateGrowerBatchFeedBlendMutation = {
  __typename: 'Mutation';
  createPoultryGrowerBatchFeedBlend: {
    __typename: 'PoultryFeedBlend';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      PoultryGrowerBatchFeedBlendFragmentFragment: PoultryGrowerBatchFeedBlendFragmentFragment;
    };
  };
};

export type UpdateGrowerBatchFeedBlendMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updatePoultryFeedBlendInput: UpdatePoultryFeedBlendInput;
}>;

export type UpdateGrowerBatchFeedBlendMutation = {
  __typename: 'Mutation';
  updatePoultryFeedBlend: { __typename: 'PoultryFeedBlend'; id: string } & {
    ' $fragmentRefs'?: {
      PoultryGrowerBatchFeedBlendFragmentFragment: PoultryGrowerBatchFeedBlendFragmentFragment;
    };
  };
};

export type DeletePoultryGrowerBatchFeedBlendMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeletePoultryGrowerBatchFeedBlendMutation = {
  __typename: 'Mutation';
  deletePoultryFeedBlend: { __typename: 'PoultryFeedBlend'; id: string };
};

export type PoultryBroilersBatchFeedFragmentFragment = {
  __typename: 'PoultryGrowerBatch';
  id: string;
  pasture: boolean;
  grazingCrops: boolean;
  growers: boolean;
  layers: boolean;
  other: boolean;
  feedBlends: Array<{
    __typename: 'PoultryFeedBlend';
    id: string;
    amountPurchasedTonnes: Decimal;
    blendType: PoultryFeedBlendType;
  }>;
} & { ' $fragmentName'?: 'PoultryBroilersBatchFeedFragmentFragment' };

export type GetPoultryBroilerBatchFeedBreakdownQueryVariables = Exact<{
  batchId: Scalars['ID']['input'];
  feedType: PoultryGrowerBatchFeedBreakdownType;
}>;

export type GetPoultryBroilerBatchFeedBreakdownQuery = {
  __typename: 'Query';
  poultryGrowerBatchFeedBreakdown?:
    | ({ __typename: 'PoultryFeedBreakdown' } & {
        ' $fragmentRefs'?: {
          PoultryBroilersBatchFeedBreakdownFragmentFragment: PoultryBroilersBatchFeedBreakdownFragmentFragment;
        };
      })
    | null;
};

export type UpdatePoultryGrowerBatchFeedBreakdownMutationVariables = Exact<{
  batchId: Scalars['ID']['input'];
  feedType: PoultryGrowerBatchFeedBreakdownType;
  updatePoultryGrowerBatchFeedBreakdownInput: UpdatePoultryGrowerBatchFeedBreakdownInput;
}>;

export type UpdatePoultryGrowerBatchFeedBreakdownMutation = {
  __typename: 'Mutation';
  updatePoultryGrowerBatchFeedBreakdown: {
    __typename: 'PoultryFeedBreakdown';
  } & {
    ' $fragmentRefs'?: {
      PoultryBroilersBatchFeedBreakdownFragmentFragment: PoultryBroilersBatchFeedBreakdownFragmentFragment;
    };
  };
};

export type PoultryBroilersBatchDetailsFragmentFragment = {
  __typename: 'PoultryGrowerBatchDetails';
  totalNumberOfBirds: number;
  averageLengthOfStayDaysL1: number;
  averageLiveweightPerHeadKgW1: Decimal;
  averageLengthOfStayDaysL2: number;
  averageLiveweightPerHeadKgW2: Decimal;
} & { ' $fragmentName'?: 'PoultryBroilersBatchDetailsFragmentFragment' };

export type PoultryBroilersBatchFlockFragmentFragment = {
  __typename: 'PoultryGrowerBatch';
  growers: boolean;
  layers: boolean;
  other: boolean;
} & { ' $fragmentName'?: 'PoultryBroilersBatchFlockFragmentFragment' };

export type GetPoultryBroilerBatchFlockTypeQueryVariables = Exact<{
  batchId: Scalars['ID']['input'];
  detailsType: PoultryGrowerBatchDetailsType;
}>;

export type GetPoultryBroilerBatchFlockTypeQuery = {
  __typename: 'Query';
  poultryGrowerBatchDetails?:
    | ({ __typename: 'PoultryGrowerBatchDetails' } & {
        ' $fragmentRefs'?: {
          PoultryBroilersBatchDetailsFragmentFragment: PoultryBroilersBatchDetailsFragmentFragment;
        };
      })
    | null;
};

export type UpdatePoultryGrowerBatchFlockDetailsMutationVariables = Exact<{
  batchId: Scalars['ID']['input'];
  detailsType: PoultryGrowerBatchDetailsType;
  updatePoultryGrowerBatchDetailsInput: UpdatePoultryGrowerBatchDetailsInput;
}>;

export type UpdatePoultryGrowerBatchFlockDetailsMutation = {
  __typename: 'Mutation';
  updatePoultryGrowerBatchDetails: {
    __typename: 'PoultryGrowerBatchDetails';
  } & {
    ' $fragmentRefs'?: {
      PoultryBroilersBatchDetailsFragmentFragment: PoultryBroilersBatchDetailsFragmentFragment;
    };
  };
};

export type PropertiesBusinessActivitiesActivitiesHeaderGrowerBatchFragment = {
  __typename: 'PoultryBroilerActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & {
  ' $fragmentName'?: 'PropertiesBusinessActivitiesActivitiesHeaderGrowerBatchFragment';
};

export type PoultryBroilersGrowerBatchLayoutFragmentFragment = {
  __typename: 'PoultryGrowerBatch';
  name: string;
  startDate: string;
  endDate: string;
} & { ' $fragmentName'?: 'PoultryBroilersGrowerBatchLayoutFragmentFragment' };

export type GetPoultryActivityInGrowerBatchHeaderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPoultryActivityInGrowerBatchHeaderQuery = {
  __typename: 'Query';
  poultryBroilerActivity?:
    | ({ __typename: 'PoultryBroilerActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesActivitiesHeaderGrowerBatchFragment: PropertiesBusinessActivitiesActivitiesHeaderGrowerBatchFragment;
        };
      })
    | null;
};

export type GetPoultryBroilerBatchHeaderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPoultryBroilerBatchHeaderQuery = {
  __typename: 'Query';
  poultryGrowerBatch?:
    | ({ __typename: 'PoultryGrowerBatch'; id: string } & {
        ' $fragmentRefs'?: {
          PoultryBroilersGrowerBatchLayoutFragmentFragment: PoultryBroilersGrowerBatchLayoutFragmentFragment;
        };
      })
    | null;
};

export type GetPoultryBroilerBatchFlockQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPoultryBroilerBatchFlockQuery = {
  __typename: 'Query';
  poultryGrowerBatch?:
    | ({ __typename: 'PoultryGrowerBatch'; id: string } & {
        ' $fragmentRefs'?: {
          PoultryBroilersBatchFlockFragmentFragment: PoultryBroilersBatchFlockFragmentFragment;
        };
      })
    | null;
};

export type GetPoultryBroilerBatchFeedQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPoultryBroilerBatchFeedQuery = {
  __typename: 'Query';
  poultryGrowerBatch?:
    | ({ __typename: 'PoultryGrowerBatch'; id: string } & {
        ' $fragmentRefs'?: {
          PoultryBroilersBatchFeedFragmentFragment: PoultryBroilersBatchFeedFragmentFragment;
        };
      })
    | null;
};

export type UpdatePoultryGrowerBatchMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updatePoultryGrowerBatchInput: UpdatePoultryGrowerBatchInput;
}>;

export type UpdatePoultryGrowerBatchMutation = {
  __typename: 'Mutation';
  updatePoultryGrowerBatch: { __typename: 'PoultryGrowerBatch'; id: string } & {
    ' $fragmentRefs'?: {
      PoultryBroilersBatchFragmentFragment: PoultryBroilersBatchFragmentFragment;
      PoultryBroilersBatchFlockFragmentFragment: PoultryBroilersBatchFlockFragmentFragment;
      PoultryBroilersBatchFeedFragmentFragment: PoultryBroilersBatchFeedFragmentFragment;
    };
  };
};

export type PoultryBroilers_BatchesFragmentFragment = {
  __typename: 'PoultryBroilerActivity';
  growerBatches: Array<{
    __typename: 'PoultryGrowerBatch';
    id: string;
    name: string;
    startDate: string;
    endDate: string;
  }>;
} & { ' $fragmentName'?: 'PoultryBroilers_BatchesFragmentFragment' };

export type GetPoultryBroilerBatchQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPoultryBroilerBatchQuery = {
  __typename: 'Query';
  poultryGrowerBatch?:
    | ({ __typename: 'PoultryGrowerBatch'; id: string } & {
        ' $fragmentRefs'?: {
          PoultryBroilersBatchFragmentFragment: PoultryBroilersBatchFragmentFragment;
        };
      })
    | null;
};

export type CreateGrowerBatchMutationVariables = Exact<{
  poultryActivityId: Scalars['ID']['input'];
  createPoultryGrowerBatchInput: CreatePoultryGrowerBatchInput;
}>;

export type CreateGrowerBatchMutation = {
  __typename: 'Mutation';
  createPoultryGrowerBatch: { __typename: 'PoultryGrowerBatch'; id: string } & {
    ' $fragmentRefs'?: {
      PoultryBroilersBatchFragmentFragment: PoultryBroilersBatchFragmentFragment;
    };
  };
};

export type DeletePoultryGrowerBatchMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeletePoultryGrowerBatchMutation = {
  __typename: 'Mutation';
  deletePoultryGrowerBatch: { __typename: 'PoultryGrowerBatch'; id: string };
};

export type PropertiesBusinessActivitiesActivitiesHeaderPoultryActivityFragment =
  {
    __typename: 'PoultryBroilerActivity';
    id: string;
    name: string;
    start: string;
    end: string;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesActivitiesHeaderPoultryActivityFragment';
  };

export type GetPoultryBroilerBatchesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPoultryBroilerBatchesQuery = {
  __typename: 'Query';
  poultryBroilerActivity?:
    | ({ __typename: 'PoultryBroilerActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PoultryBroilers_BatchesFragmentFragment: PoultryBroilers_BatchesFragmentFragment;
        };
      })
    | null;
};

export type GetPoultryBroilerTransactionsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPoultryBroilerTransactionsQuery = {
  __typename: 'Query';
  poultryBroilerActivity?:
    | ({ __typename: 'PoultryBroilerActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PoultryBroilersTransactionsFragmentFragment: PoultryBroilersTransactionsFragmentFragment;
        };
      })
    | null;
};

export type GetPoultryActivityInHeaderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPoultryActivityInHeaderQuery = {
  __typename: 'Query';
  poultryBroilerActivity?:
    | ({ __typename: 'PoultryBroilerActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesActivitiesHeaderPoultryActivityFragment: PropertiesBusinessActivitiesActivitiesHeaderPoultryActivityFragment;
        };
      })
    | null;
};

export type PoultryBroilersManureLitterFragmentFragment = {
  __typename: 'PoultryBroilerActivity';
  percentManureAddedToPasture?: Decimal | null;
  percentSolidManure?: Decimal | null;
  percentageLitterRecycled?: Decimal | null;
  cyclesPerYear?: number | null;
} & { ' $fragmentName'?: 'PoultryBroilersManureLitterFragmentFragment' };

export type GetPoultryBroilerManureLitterQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPoultryBroilerManureLitterQuery = {
  __typename: 'Query';
  poultryBroilerActivity?:
    | ({ __typename: 'PoultryBroilerActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PoultryBroilersManureLitterFragmentFragment: PoultryBroilersManureLitterFragmentFragment;
        };
      })
    | null;
};

export type UpdateBroilerManureLitterMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updatePoultryBroilerActivityInput: UpdatePoultryBroilerActivityInput;
}>;

export type UpdateBroilerManureLitterMutation = {
  __typename: 'Mutation';
  updatePoultryBroilerActivity: {
    __typename: 'PoultryBroilerActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      PoultryBroilersManureLitterFragmentFragment: PoultryBroilersManureLitterFragmentFragment;
    };
  };
};

export type PoultryBroilersTransactionFragmentFragment = {
  __typename: 'PoultryTransactionEvent';
  date: string;
  transactionType: TransactionType;
  class: PoultryTransactionClass;
  livestockCount: number;
  totalWeightKg: Decimal;
  averageWeightKg?: Decimal | null;
  percentPurchasedFreeRange?: Decimal | null;
} & { ' $fragmentName'?: 'PoultryBroilersTransactionFragmentFragment' };

export type UpdatePoultryBroilerTransactionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updatePoultryTransactionEventInput: UpdatePoultryTransactionEventInput;
}>;

export type UpdatePoultryBroilerTransactionMutation = {
  __typename: 'Mutation';
  updatePoultryTransactionEvent: {
    __typename: 'PoultryTransactionEvent';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      PoultryBroilersTransactionFragmentFragment: PoultryBroilersTransactionFragmentFragment;
    };
  };
};

export type PoultryBroilersTransactionsFragmentFragment = {
  __typename: 'PoultryBroilerActivity';
  transactionEvents: Array<{
    __typename: 'PoultryTransactionEvent';
    id: string;
    class: PoultryTransactionClass;
    transactionType: TransactionType;
    date: string;
  }>;
} & { ' $fragmentName'?: 'PoultryBroilersTransactionsFragmentFragment' };

export type GetPoultryBroilerTransactionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPoultryBroilerTransactionQuery = {
  __typename: 'Query';
  poultryTransactionEvent?:
    | ({ __typename: 'PoultryTransactionEvent'; id: string } & {
        ' $fragmentRefs'?: {
          PoultryBroilersTransactionFragmentFragment: PoultryBroilersTransactionFragmentFragment;
        };
      })
    | null;
};

export type CreateBroilerTransactionMutationVariables = Exact<{
  poultryBroilerActivityId: Scalars['ID']['input'];
  createPoultryTransactionEventInput: CreatePoultryTransactionEventInput;
}>;

export type CreateBroilerTransactionMutation = {
  __typename: 'Mutation';
  createPoultryBroilerTransactionEvent: {
    __typename: 'PoultryTransactionEvent';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      PoultryBroilersTransactionFragmentFragment: PoultryBroilersTransactionFragmentFragment;
    };
  };
};

export type DeletePoultryTransactionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeletePoultryTransactionMutation = {
  __typename: 'Mutation';
  deletePoultryTransactionEvent?: {
    __typename: 'PoultryTransactionEvent';
    id: string;
  } | null;
};

export type CreatePoultryBroilerActivityMutationVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  createPoultryBroilerActivityInput: CreatePoultryBroilerActivityInput;
}>;

export type CreatePoultryBroilerActivityMutation = {
  __typename: 'Mutation';
  createPoultryBroilerActivity: {
    __typename: 'PoultryBroilerActivity';
    id: string;
  };
};

export type PoultryLayerEggSalesFragmentFragment = {
  __typename: 'PoultryLayerActivity';
  totalLayerEggsProduced?: number | null;
  averageLayerEggWeightGrams?: Decimal | null;
  totalMeatChickenLayerEggsProduced?: number | null;
  averageMeatChickenLayerEggWeightGrams?: Decimal | null;
} & { ' $fragmentName'?: 'PoultryLayerEggSalesFragmentFragment' };

export type GetPoultryLayerEggSalesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPoultryLayerEggSalesQuery = {
  __typename: 'Query';
  poultryLayerActivity?:
    | ({ __typename: 'PoultryLayerActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PoultryLayerEggSalesFragmentFragment: PoultryLayerEggSalesFragmentFragment;
        };
      })
    | null;
};

export type UpdateLayerEggSalesMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updatePoultryLayerActivityInput: UpdatePoultryLayerActivityInput;
}>;

export type UpdateLayerEggSalesMutation = {
  __typename: 'Mutation';
  updatePoultryLayerActivity: {
    __typename: 'PoultryLayerActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      PoultryLayerEggSalesFragmentFragment: PoultryLayerEggSalesFragmentFragment;
    };
  };
};

export type PoultryLayerActivityFeedBlendFragmentFragment = {
  __typename: 'PoultryFeedBlend';
  blendType: PoultryFeedBlendType;
  barleyWhole?: Decimal | null;
  millRun?: Decimal | null;
  sorghum?: Decimal | null;
  soybean?: Decimal | null;
  wheat?: Decimal | null;
  amountPurchasedTonnes: Decimal;
  percentageAdditionalIngredients?: Decimal | null;
  emissionsIntensityFeedProduction?: Decimal | null;
} & { ' $fragmentName'?: 'PoultryLayerActivityFeedBlendFragmentFragment' };

export type GetPoultryLayerActivityFeedBlendQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPoultryLayerActivityFeedBlendQuery = {
  __typename: 'Query';
  poultryFeedBlend?:
    | ({ __typename: 'PoultryFeedBlend'; id: string } & {
        ' $fragmentRefs'?: {
          PoultryLayerActivityFeedBlendFragmentFragment: PoultryLayerActivityFeedBlendFragmentFragment;
        };
      })
    | null;
};

export type CreatePoultryLayerFeedBlendMutationVariables = Exact<{
  poultryLayerActivityId: Scalars['ID']['input'];
  createPoultryLayerFeedBlendInput: CreatePoultryLayerFeedBlendInput;
}>;

export type CreatePoultryLayerFeedBlendMutation = {
  __typename: 'Mutation';
  createPoultryLayerFeedBlend: {
    __typename: 'PoultryFeedBlend';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      PoultryLayerActivityFeedBlendFragmentFragment: PoultryLayerActivityFeedBlendFragmentFragment;
    };
  };
};

export type UpdatePoultryLayerFeedBlendMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updatePoultryFeedBlendInput: UpdatePoultryFeedBlendInput;
}>;

export type UpdatePoultryLayerFeedBlendMutation = {
  __typename: 'Mutation';
  updatePoultryFeedBlend: { __typename: 'PoultryFeedBlend'; id: string } & {
    ' $fragmentRefs'?: {
      PoultryLayerActivityFeedBlendFragmentFragment: PoultryLayerActivityFeedBlendFragmentFragment;
    };
  };
};

export type DeletePoultryLayerActivityFeedBlendMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeletePoultryLayerActivityFeedBlendMutation = {
  __typename: 'Mutation';
  deletePoultryFeedBlend: { __typename: 'PoultryFeedBlend'; id: string };
};

export type PoultryLayerFeedBlendsFragmentFragment = {
  __typename: 'PoultryLayerActivity';
  id: string;
  pasture: boolean;
  grazingCrops: boolean;
  feedBlends: Array<{
    __typename: 'PoultryFeedBlend';
    id: string;
    blendType: PoultryFeedBlendType;
    amountPurchasedTonnes: Decimal;
  }>;
} & { ' $fragmentName'?: 'PoultryLayerFeedBlendsFragmentFragment' };

export type GetPoultryLayerFeedBlendsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPoultryLayerFeedBlendsQuery = {
  __typename: 'Query';
  poultryLayerActivity?:
    | ({ __typename: 'PoultryLayerActivity' } & {
        ' $fragmentRefs'?: {
          PoultryLayerFeedBlendsFragmentFragment: PoultryLayerFeedBlendsFragmentFragment;
        };
      })
    | null;
};

export type UpdatePoultryLayerFeedMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updatePoultryLayerActivityInput: UpdatePoultryLayerActivityInput;
}>;

export type UpdatePoultryLayerFeedMutation = {
  __typename: 'Mutation';
  updatePoultryLayerActivity: { __typename: 'PoultryLayerActivity' } & {
    ' $fragmentRefs'?: {
      PoultryLayerFeedBlendsFragmentFragment: PoultryLayerFeedBlendsFragmentFragment;
    };
  };
};

export type PoultryLayerActivityInfoFragmentFragment = {
  __typename: 'PoultryLayerActivity';
  poultryLayerMonthActivities: Array<
    { __typename: 'PoultryLayerMonthActivity' } & {
      ' $fragmentRefs'?: {
        PoultryLayerFlockFormMonthlyActivityFragment: PoultryLayerFlockFormMonthlyActivityFragment;
      };
    }
  >;
} & { ' $fragmentName'?: 'PoultryLayerActivityInfoFragmentFragment' };

export type PoultryLayerFlockFormMonthlyActivityFragment = {
  __typename: 'PoultryLayerMonthActivity';
  id: string;
  month: Month;
  year: number;
  layerCount: number;
  meatChickenLayerCount: number;
} & { ' $fragmentName'?: 'PoultryLayerFlockFormMonthlyActivityFragment' };

export type UpdatePoultryLayerMonthActivityForMonthlyBreakdownMutationVariables =
  Exact<{
    id: Scalars['ID']['input'];
    updatePoultryLayerMonthActivityInput: UpdatePoultryLayerMonthActivityInput;
  }>;

export type UpdatePoultryLayerMonthActivityForMonthlyBreakdownMutation = {
  __typename: 'Mutation';
  updatePoultryLayerMonthActivity: {
    __typename: 'PoultryLayerMonthActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      PoultryLayerFlockFormMonthlyActivityFragment: PoultryLayerFlockFormMonthlyActivityFragment;
    };
  };
};

export type PoultryLayerActivityHeaderFragmentFragment = {
  __typename: 'PoultryLayerActivity';
  id: string;
  name: string;
  start: string;
  end: string;
} & { ' $fragmentName'?: 'PoultryLayerActivityHeaderFragmentFragment' };

export type GetPoultryLayerInfoQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPoultryLayerInfoQuery = {
  __typename: 'Query';
  poultryLayerActivity?:
    | ({ __typename: 'PoultryLayerActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PoultryLayerActivityInfoFragmentFragment: PoultryLayerActivityInfoFragmentFragment;
        };
      })
    | null;
};

export type GetPoultryLayerInHeaderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPoultryLayerInHeaderQuery = {
  __typename: 'Query';
  poultryLayerActivity?:
    | ({ __typename: 'PoultryLayerActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PoultryLayerActivityHeaderFragmentFragment: PoultryLayerActivityHeaderFragmentFragment;
        };
      })
    | null;
};

export type PoultryLayerManureLitterFragmentFragment = {
  __typename: 'PoultryLayerActivity';
  percentManureAddedToPasture?: Decimal | null;
  percentageLitterRecycled?: Decimal | null;
  percentSolidManure?: Decimal | null;
  cyclesPerYear?: number | null;
} & { ' $fragmentName'?: 'PoultryLayerManureLitterFragmentFragment' };

export type GetPoultryLayerManureLitterQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPoultryLayerManureLitterQuery = {
  __typename: 'Query';
  poultryLayerActivity?:
    | ({ __typename: 'PoultryLayerActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PoultryLayerManureLitterFragmentFragment: PoultryLayerManureLitterFragmentFragment;
        };
      })
    | null;
};

export type UpdateLayerManureLitterMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updatePoultryLayerActivityInput: UpdatePoultryLayerActivityInput;
}>;

export type UpdateLayerManureLitterMutation = {
  __typename: 'Mutation';
  updatePoultryLayerActivity: {
    __typename: 'PoultryLayerActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      PoultryLayerManureLitterFragmentFragment: PoultryLayerManureLitterFragmentFragment;
    };
  };
};

export type PoultryLayersTransactionFragmentFragment = {
  __typename: 'PoultryTransactionEvent';
  date: string;
  transactionType: TransactionType;
  class: PoultryTransactionClass;
  livestockCount: number;
  totalWeightKg: Decimal;
  averageWeightKg?: Decimal | null;
  percentPurchasedFreeRange?: Decimal | null;
} & { ' $fragmentName'?: 'PoultryLayersTransactionFragmentFragment' };

export type UpdatePoultryLayerTransactionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updatePoultryTransactionEventInput: UpdatePoultryTransactionEventInput;
}>;

export type UpdatePoultryLayerTransactionMutation = {
  __typename: 'Mutation';
  updatePoultryTransactionEvent: {
    __typename: 'PoultryTransactionEvent';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      PoultryLayersTransactionFragmentFragment: PoultryLayersTransactionFragmentFragment;
    };
  };
};

export type PoultryLayerActivityTransactionsFragmentFragment = {
  __typename: 'PoultryLayerActivity';
  transactionEvents: Array<{
    __typename: 'PoultryTransactionEvent';
    id: string;
    class: PoultryTransactionClass;
    transactionType: TransactionType;
    date: string;
  }>;
} & { ' $fragmentName'?: 'PoultryLayerActivityTransactionsFragmentFragment' };

export type GetPoultryLayerTransactionsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPoultryLayerTransactionsQuery = {
  __typename: 'Query';
  poultryLayerActivity?:
    | ({ __typename: 'PoultryLayerActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PoultryLayerActivityTransactionsFragmentFragment: PoultryLayerActivityTransactionsFragmentFragment;
        };
      })
    | null;
};

export type GetPoultryLayerTransactionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPoultryLayerTransactionQuery = {
  __typename: 'Query';
  poultryTransactionEvent?:
    | ({ __typename: 'PoultryTransactionEvent'; id: string } & {
        ' $fragmentRefs'?: {
          PoultryLayersTransactionFragmentFragment: PoultryLayersTransactionFragmentFragment;
        };
      })
    | null;
};

export type CreateLayerTransactionMutationVariables = Exact<{
  poultryLayerActivityId: Scalars['ID']['input'];
  createPoultryTransactionEventInput: CreatePoultryTransactionEventInput;
}>;

export type CreateLayerTransactionMutation = {
  __typename: 'Mutation';
  createPoultryLayerTransactionEvent: {
    __typename: 'PoultryTransactionEvent';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      PoultryLayersTransactionFragmentFragment: PoultryLayersTransactionFragmentFragment;
    };
  };
};

export type CreatePoultryLayerActivityMutationVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  createPoultryLayerActivityInput: CreatePoultryLayerActivityInput;
}>;

export type CreatePoultryLayerActivityMutation = {
  __typename: 'Mutation';
  createPoultryLayerActivity: {
    __typename: 'PoultryLayerActivity';
    id: string;
  };
};

export type UpdateTransaction_SheepMonthLivestockActivityFragmentFragment = ({
  __typename: 'SheepMonthLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    TransactionForm_SheepMonthLivestockActivityFragmentFragment: TransactionForm_SheepMonthLivestockActivityFragmentFragment;
  };
}) & {
  ' $fragmentName'?: 'UpdateTransaction_SheepMonthLivestockActivityFragmentFragment';
};

export type CreateTransaction_SheepMonthLivestockActivityFragmentFragment = ({
  __typename: 'SheepMonthLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    TransactionForm_SheepMonthLivestockActivityFragmentFragment: TransactionForm_SheepMonthLivestockActivityFragmentFragment;
  };
}) & {
  ' $fragmentName'?: 'CreateTransaction_SheepMonthLivestockActivityFragmentFragment';
};

export type SheepMonthlyForm_SheepMonthLivestockActivityFragment = {
  __typename: 'SheepMonthLivestockActivity';
  id: string;
  month: Month;
  year: number;
  averageLiveweightKg: number;
  previousMonthLiveweightKg?: number | null;
  nonSalePopulationChange: number;
  percentageEwesLambing: Decimal;
  seasonalLambingRate: Decimal;
  keyEvents?: string | null;
  headAtStart: number;
  livestockTransactionEvents: Array<{
    __typename: 'LivestockTransactionEvent';
    id: string;
    transactionType: TransactionType;
    livestockCount: number;
  } | null>;
} & {
  ' $fragmentName'?: 'SheepMonthlyForm_SheepMonthLivestockActivityFragment';
};

export type TransactionForm_SheepMonthLivestockActivityFragmentFragment = {
  __typename: 'SheepMonthLivestockActivity';
  year: number;
  month: Month;
  sheepClass: SheepClass;
} & {
  ' $fragmentName'?: 'TransactionForm_SheepMonthLivestockActivityFragmentFragment';
};

export type UpdateSheepMonthLivestockActivityForMonthlyBreakdownMutationVariables =
  Exact<{
    id: Scalars['ID']['input'];
    updateSheepMonthLivestockActivityInput: UpdateSheepMonthLivestockActivityInput;
  }>;

export type UpdateSheepMonthLivestockActivityForMonthlyBreakdownMutation = {
  __typename: 'Mutation';
  updateSheepMonthLivestockActivity: {
    __typename: 'SheepMonthLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      SheepMonthlyBreakdown_SheepMonthLivestockActivityFragment: SheepMonthlyBreakdown_SheepMonthLivestockActivityFragment;
    };
  };
};

export type CreateSheepLivestockTransactionEventForUpdateTransactionMutationVariables =
  Exact<{
    sheepMonthLivestockActivityId: Scalars['ID']['input'];
    createLivestockTransactionEventInput: CreateLivestockTransactionEventInput;
  }>;

export type CreateSheepLivestockTransactionEventForUpdateTransactionMutation = {
  __typename: 'Mutation';
  createSheepLivestockTransactionEvent: {
    __typename: 'LivestockTransactionEvent';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      UpdateTransaction_LivestockTransactionEventFragmentFragment: UpdateTransaction_LivestockTransactionEventFragmentFragment;
    };
  };
};

export type GetSheepActivityForMonthlyBreakdownQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetSheepActivityForMonthlyBreakdownQuery = {
  __typename: 'Query';
  sheepLivestockActivity?:
    | ({ __typename: 'SheepLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          SheepMonthlyBreakdown_SheepLivestockActivityFragmentFragment: SheepMonthlyBreakdown_SheepLivestockActivityFragmentFragment;
        };
      })
    | null;
};

export type GetSheepMonthLivestockActivitiesForMonthlyBreakdownQueryVariables =
  Exact<{
    listSheepMonthLivestockActivitiesInput: ListSheepMonthLivestockActivitiesInput;
  }>;

export type GetSheepMonthLivestockActivitiesForMonthlyBreakdownQuery = {
  __typename: 'Query';
  sheepMonthLivestockActivities: Array<
    | ({ __typename: 'SheepMonthLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          SheepMonthlyBreakdown_SheepMonthLivestockActivityFragment: SheepMonthlyBreakdown_SheepMonthLivestockActivityFragment;
        };
      })
    | null
  >;
};

export type GetSheepMonthLivestockActivityForCreateUpdateTransactionQueryVariables =
  Exact<{
    id: Scalars['ID']['input'];
  }>;

export type GetSheepMonthLivestockActivityForCreateUpdateTransactionQuery = {
  __typename: 'Query';
  sheepMonthLivestockActivity: {
    __typename: 'SheepMonthLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      CreateTransaction_SheepMonthLivestockActivityFragmentFragment: CreateTransaction_SheepMonthLivestockActivityFragmentFragment;
      UpdateTransaction_SheepMonthLivestockActivityFragmentFragment: UpdateTransaction_SheepMonthLivestockActivityFragmentFragment;
    };
  };
};

export type SheepMonthlyBreakdown_SheepLivestockActivityFragmentFragment = {
  __typename: 'SheepLivestockActivity';
  ewesGt2Breeding: boolean;
  eweLambsLt1Breeding: boolean;
  otherEwesGt2Breeding: boolean;
  maidenBreedingEwes1To2Breeding: boolean;
  wetherLambsLt1Breeding: boolean;
  wethersGt1Breeding: boolean;
  ramsBreeding: boolean;
  ewesGt2Trading: boolean;
  eweLambsLt1Trading: boolean;
  otherEwesGt2Trading: boolean;
  maidenBreedingEwes1To2Trading: boolean;
  wetherLambsLt1Trading: boolean;
  wethersGt1Trading: boolean;
  ramsTrading: boolean;
} & {
  ' $fragmentName'?: 'SheepMonthlyBreakdown_SheepLivestockActivityFragmentFragment';
};

export type SheepMonthlyBreakdown_SheepMonthLivestockActivityFragment = ({
  __typename: 'SheepMonthLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    SheepMonthlyForm_SheepMonthLivestockActivityFragment: SheepMonthlyForm_SheepMonthLivestockActivityFragment;
  };
}) & {
  ' $fragmentName'?: 'SheepMonthlyBreakdown_SheepMonthLivestockActivityFragment';
};

export type SheepActivityLivestockBreedingSheepLivestockActivityFragment = {
  __typename: 'SheepLivestockActivity';
  ewesGt2Breeding: boolean;
  eweLambsLt1Breeding: boolean;
  otherEwesGt2Breeding: boolean;
  maidenBreedingEwes1To2Breeding: boolean;
  wetherLambsLt1Breeding: boolean;
  wethersGt1Breeding: boolean;
  ramsBreeding: boolean;
} & {
  ' $fragmentName'?: 'SheepActivityLivestockBreedingSheepLivestockActivityFragment';
};

export type SheepActivityLivestockTradingSheepLivestockActivityFragment = {
  __typename: 'SheepLivestockActivity';
  ewesGt2Trading: boolean;
  eweLambsLt1Trading: boolean;
  otherEwesGt2Trading: boolean;
  maidenBreedingEwes1To2Trading: boolean;
  wetherLambsLt1Trading: boolean;
  wethersGt1Trading: boolean;
  ramsTrading: boolean;
} & {
  ' $fragmentName'?: 'SheepActivityLivestockTradingSheepLivestockActivityFragment';
};

export type SheepActivityLivestockSheepLivestockActivityFragment = ({
  __typename: 'SheepLivestockActivity';
} & {
  ' $fragmentRefs'?: {
    SheepActivityLivestockBreedingSheepLivestockActivityFragment: SheepActivityLivestockBreedingSheepLivestockActivityFragment;
    SheepActivityLivestockTradingSheepLivestockActivityFragment: SheepActivityLivestockTradingSheepLivestockActivityFragment;
  };
}) & {
  ' $fragmentName'?: 'SheepActivityLivestockSheepLivestockActivityFragment';
};

export type UseSheepMonthlyFeedBreakdown_ActivityFragmentFragment = {
  __typename: 'SheepLivestockActivity';
  ewesGt2Breeding: boolean;
  eweLambsLt1Breeding: boolean;
  otherEwesGt2Breeding: boolean;
  maidenBreedingEwes1To2Breeding: boolean;
  wetherLambsLt1Breeding: boolean;
  wethersGt1Breeding: boolean;
  ramsBreeding: boolean;
  ewesGt2Trading: boolean;
  eweLambsLt1Trading: boolean;
  otherEwesGt2Trading: boolean;
  maidenBreedingEwes1To2Trading: boolean;
  wetherLambsLt1Trading: boolean;
  wethersGt1Trading: boolean;
  ramsTrading: boolean;
  businessActivity: {
    __typename: 'BusinessActivity';
    id: string;
    state: StateRegion;
  };
} & {
  ' $fragmentName'?: 'UseSheepMonthlyFeedBreakdown_ActivityFragmentFragment';
};

export type UseSheepMonthlyFeedBreakdown_SheepMonthLivestockActivityFragment = {
  __typename: 'SheepMonthLivestockActivity';
  id: string;
  month: Month;
  year: number;
  crudeProteinPercentage: Decimal;
  dryMatterDigestibilityPercentage: Decimal;
  feedAvailabilityTonnesPerHectare: Decimal;
} & {
  ' $fragmentName'?: 'UseSheepMonthlyFeedBreakdown_SheepMonthLivestockActivityFragment';
};

export type UpdateSheepMonthLivestockActivityForMonthlyFeedBreakdownMutationVariables =
  Exact<{
    id: Scalars['ID']['input'];
    updateSheepMonthLivestockActivityInput: UpdateSheepMonthLivestockActivityInput;
  }>;

export type UpdateSheepMonthLivestockActivityForMonthlyFeedBreakdownMutation = {
  __typename: 'Mutation';
  updateSheepMonthLivestockActivity: {
    __typename: 'SheepMonthLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      UseSheepMonthlyFeedBreakdown_SheepMonthLivestockActivityFragment: UseSheepMonthlyFeedBreakdown_SheepMonthLivestockActivityFragment;
    };
  };
};

export type GetSheepActivityForMonthlyFeedBreakdownQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetSheepActivityForMonthlyFeedBreakdownQuery = {
  __typename: 'Query';
  sheepLivestockActivity?:
    | ({ __typename: 'SheepLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          UseSheepMonthlyFeedBreakdown_ActivityFragmentFragment: UseSheepMonthlyFeedBreakdown_ActivityFragmentFragment;
        };
      })
    | null;
};

export type GetSheepMonthLivestockActivitiesForMonthlyFeedBreakdownQueryVariables =
  Exact<{
    listSheepMonthLivestockActivitiesInput: ListSheepMonthLivestockActivitiesInput;
  }>;

export type GetSheepMonthLivestockActivitiesForMonthlyFeedBreakdownQuery = {
  __typename: 'Query';
  sheepMonthLivestockActivities: Array<
    | ({ __typename: 'SheepMonthLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          UseSheepMonthlyFeedBreakdown_SheepMonthLivestockActivityFragment: UseSheepMonthlyFeedBreakdown_SheepMonthLivestockActivityFragment;
        };
      })
    | null
  >;
};

export type GetSheepLivestockActivityFeedDrySeasonMixQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetSheepLivestockActivityFeedDrySeasonMixQuery = {
  __typename: 'Query';
  sheepLivestockActivity?:
    | ({ __typename: 'SheepLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          SheepFeedDrySeasonMix_SheepLivestockActivityFragmentFragment: SheepFeedDrySeasonMix_SheepLivestockActivityFragmentFragment;
        };
      })
    | null;
};

export type SheepFeedDrySeasonMix_SheepLivestockActivityFragmentFragment = {
  __typename: 'SheepLivestockActivity';
  feed?:
    | ({ __typename: 'Feed' } & {
        ' $fragmentRefs'?: {
          FeedDrySeasonMix_FeedFragmentFragment: FeedDrySeasonMix_FeedFragmentFragment;
        };
      })
    | null;
} & {
  ' $fragmentName'?: 'SheepFeedDrySeasonMix_SheepLivestockActivityFragmentFragment';
};

export type GetSheepActivityFeedQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetSheepActivityFeedQuery = {
  __typename: 'Query';
  sheepLivestockActivity?:
    | ({ __typename: 'SheepLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          SheepActivityLivestockFeed_SheepLivestockActivityFragmentFragment: SheepActivityLivestockFeed_SheepLivestockActivityFragmentFragment;
        };
      })
    | null;
};

export type SheepActivityLivestockFeed_SheepLivestockActivityFragmentFragment =
  {
    __typename: 'SheepLivestockActivity';
    id: string;
    ewesGt2Breeding: boolean;
    eweLambsLt1Breeding: boolean;
    otherEwesGt2Breeding: boolean;
    maidenBreedingEwes1To2Breeding: boolean;
    wetherLambsLt1Breeding: boolean;
    wethersGt1Breeding: boolean;
    ramsBreeding: boolean;
    ewesGt2Trading: boolean;
    eweLambsLt1Trading: boolean;
    otherEwesGt2Trading: boolean;
    maidenBreedingEwes1To2Trading: boolean;
    wetherLambsLt1Trading: boolean;
    wethersGt1Trading: boolean;
    ramsTrading: boolean;
    feed?: {
      __typename: 'Feed';
      id: string;
      pasture: boolean;
      grazingCrops: boolean;
      mineralBlock: boolean;
      weanerBlock: boolean;
      drySeasonMix: boolean;
    } | null;
  } & {
    ' $fragmentName'?: 'SheepActivityLivestockFeed_SheepLivestockActivityFragmentFragment';
  };

export type GetSheepLivestockActivityFeedMineralBlockQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetSheepLivestockActivityFeedMineralBlockQuery = {
  __typename: 'Query';
  sheepLivestockActivity?:
    | ({ __typename: 'SheepLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          SheepFeedMineralBlock_SheepLivestockActivityFragmentFragment: SheepFeedMineralBlock_SheepLivestockActivityFragmentFragment;
        };
      })
    | null;
};

export type SheepFeedMineralBlock_SheepLivestockActivityFragmentFragment = {
  __typename: 'SheepLivestockActivity';
  feed?:
    | ({ __typename: 'Feed' } & {
        ' $fragmentRefs'?: {
          FeedMineralBlock_FeedFragmentFragment: FeedMineralBlock_FeedFragmentFragment;
        };
      })
    | null;
} & {
  ' $fragmentName'?: 'SheepFeedMineralBlock_SheepLivestockActivityFragmentFragment';
};

export type UpdateSheepFeedWeanerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateFeedInput: UpdateFeedInput;
}>;

export type UpdateSheepFeedWeanerMutation = {
  __typename: 'Mutation';
  updateFeed: { __typename: 'Feed'; id: string } & {
    ' $fragmentRefs'?: {
      FeedWeanerBlock_FeedFragmentFragment: FeedWeanerBlock_FeedFragmentFragment;
    };
  };
};

export type GetSheepLivestockActivityFeedWeanerQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetSheepLivestockActivityFeedWeanerQuery = {
  __typename: 'Query';
  sheepLivestockActivity?:
    | ({ __typename: 'SheepLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          SheepFeedWeanerBlock_SheepLivestockActivityFragmentFragment: SheepFeedWeanerBlock_SheepLivestockActivityFragmentFragment;
        };
      })
    | null;
};

export type SheepFeedWeanerBlock_SheepLivestockActivityFragmentFragment = {
  __typename: 'SheepLivestockActivity';
  feed?:
    | ({ __typename: 'Feed' } & {
        ' $fragmentRefs'?: {
          FeedWeanerBlock_FeedFragmentFragment: FeedWeanerBlock_FeedFragmentFragment;
        };
      })
    | null;
} & {
  ' $fragmentName'?: 'SheepFeedWeanerBlock_SheepLivestockActivityFragmentFragment';
};

export type PropertiesBusinessActivitiesActivitiesHeaderSheepActivityFragment =
  {
    __typename: 'SheepLivestockActivity';
    id: string;
    name: string;
    start: string;
    end: string;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesActivitiesHeaderSheepActivityFragment';
  };

export type GetSheepActivityQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetSheepActivityQuery = {
  __typename: 'Query';
  sheepLivestockActivity?:
    | ({ __typename: 'SheepLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          SheepActivityLivestockSheepLivestockActivityFragment: SheepActivityLivestockSheepLivestockActivityFragment;
        };
      })
    | null;
};

export type GetSheepMonthLivestockActivitiesForActivityQueryVariables = Exact<{
  listSheepMonthLivestockActivitiesInput: ListSheepMonthLivestockActivitiesInput;
}>;

export type GetSheepMonthLivestockActivitiesForActivityQuery = {
  __typename: 'Query';
  sheepMonthLivestockActivities: Array<
    | ({ __typename: 'SheepMonthLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          SheepLivestockActivity_MonthActivitiesFragment: SheepLivestockActivity_MonthActivitiesFragment;
        };
      })
    | null
  >;
};

export type GetSheepActivityInHeaderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetSheepActivityInHeaderQuery = {
  __typename: 'Query';
  sheepLivestockActivity?:
    | ({ __typename: 'SheepLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesActivitiesHeaderSheepActivityFragment: PropertiesBusinessActivitiesActivitiesHeaderSheepActivityFragment;
        };
      })
    | null;
};

export type UpdateSheepLivestockActivityMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateSheepLivestockActivityInput: UpdateSheepLivestockActivityInput;
}>;

export type UpdateSheepLivestockActivityMutation = {
  __typename: 'Mutation';
  updateSheepLivestockActivity: {
    __typename: 'SheepLivestockActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      SheepActivityLivestockBreedingSheepLivestockActivityFragment: SheepActivityLivestockBreedingSheepLivestockActivityFragment;
      SheepActivityLivestockTradingSheepLivestockActivityFragment: SheepActivityLivestockTradingSheepLivestockActivityFragment;
    };
  };
};

export type SheepWoolShearingEventsFragmentFragment = {
  __typename: 'SheepShearingEvent';
  id: string;
  month: Month;
  year: number;
  numberSheepShorn: number;
  woolPerHeadKg: Decimal;
  cleanWoolYieldKg: Decimal;
  sheepLivestockActivityId?: string | null;
} & { ' $fragmentName'?: 'SheepWoolShearingEventsFragmentFragment' };

export type GetSheepShearingEventsQueryVariables = Exact<{
  activityId: Scalars['ID']['input'];
}>;

export type GetSheepShearingEventsQuery = {
  __typename: 'Query';
  shearingEventsBySheepLivestockActivityId: Array<
    | ({ __typename: 'SheepShearingEvent'; id: string } & {
        ' $fragmentRefs'?: {
          SheepWoolShearingEventsFragmentFragment: SheepWoolShearingEventsFragmentFragment;
        };
      })
    | null
  >;
};

export type GetSheepShearingEventQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetSheepShearingEventQuery = {
  __typename: 'Query';
  sheepShearingEvent: { __typename: 'SheepShearingEvent'; id: string } & {
    ' $fragmentRefs'?: {
      WoolUpdateSheepShearingEventFragmentFragment: WoolUpdateSheepShearingEventFragmentFragment;
    };
  };
};

export type CreateSheepShearingEventMutationVariables = Exact<{
  sheepLivestockActivityId: Scalars['ID']['input'];
  input: CreateSheepShearingEventInput;
}>;

export type CreateSheepShearingEventMutation = {
  __typename: 'Mutation';
  createSheepShearingEvent: { __typename: 'SheepShearingEvent'; id: string } & {
    ' $fragmentRefs'?: {
      SheepWoolShearingEventsFragmentFragment: SheepWoolShearingEventsFragmentFragment;
    };
  };
};

export type UpdateSheepShearingEventMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateSheepShearingEventInput;
}>;

export type UpdateSheepShearingEventMutation = {
  __typename: 'Mutation';
  updateSheepShearingEvent: { __typename: 'SheepShearingEvent'; id: string } & {
    ' $fragmentRefs'?: {
      SheepWoolShearingEventsFragmentFragment: SheepWoolShearingEventsFragmentFragment;
    };
  };
};

export type DeleteSheepShearingEventMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteSheepShearingEventMutation = {
  __typename: 'Mutation';
  deleteSheepShearingEvent: { __typename: 'SheepShearingEvent'; id: string };
};

export type GetSheepActivityForShearingQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetSheepActivityForShearingQuery = {
  __typename: 'Query';
  sheepLivestockActivity?:
    | ({ __typename: 'SheepLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          SheepShearingEventSheepLivestockActivityFragment: SheepShearingEventSheepLivestockActivityFragment;
        };
      })
    | null;
};

export type GetSheepMonthLivestockActivitiesForShearingQueryVariables = Exact<{
  listSheepMonthLivestockActivitiesInput: ListSheepMonthLivestockActivitiesInput;
}>;

export type GetSheepMonthLivestockActivitiesForShearingQuery = {
  __typename: 'Query';
  sheepMonthLivestockActivities: Array<
    | ({ __typename: 'SheepMonthLivestockActivity'; id: string } & {
        ' $fragmentRefs'?: {
          SheepShearingEventMonthActivitiesFragment: SheepShearingEventMonthActivitiesFragment;
        };
      })
    | null
  >;
};

export type WoolUpdateSheepShearingEventFragmentFragment = {
  __typename: 'SheepShearingEvent';
  id: string;
  date: string;
  sheepClass?: SheepClass | null;
  livestockCategory: LivestockCategory;
  numberSheepShorn: number;
  woolPerHeadKg: Decimal;
  cleanWoolYieldKg: Decimal;
  sheepLivestockActivityId?: string | null;
} & { ' $fragmentName'?: 'WoolUpdateSheepShearingEventFragmentFragment' };

export type SheepShearingEventSheepLivestockActivityFragment = {
  __typename: 'SheepLivestockActivity';
  start: string;
  end: string;
} & { ' $fragmentName'?: 'SheepShearingEventSheepLivestockActivityFragment' };

export type SheepShearingEventMonthActivitiesFragment = {
  __typename: 'SheepMonthLivestockActivity';
  id: string;
  month: Month;
  year: number;
  headAtStart: number;
  sheepClass: SheepClass;
  livestockCategory: LivestockCategory;
} & { ' $fragmentName'?: 'SheepShearingEventMonthActivitiesFragment' };

export type SheepLivestockActivity_MonthActivitiesFragment = {
  __typename: 'SheepMonthLivestockActivity';
  id: string;
  month: Month;
  year: number;
  averageLiveweightKg: number;
  headAtStart: number;
  sheepClass: SheepClass;
} & { ' $fragmentName'?: 'SheepLivestockActivity_MonthActivitiesFragment' };

export type CreateSheepLivestockActivityMutationVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  createSheepLivestockActivityInput: CreateSheepLivestockActivityInput;
}>;

export type CreateSheepLivestockActivityMutation = {
  __typename: 'Mutation';
  createSheepLivestockActivity: {
    __typename: 'SheepLivestockActivity';
    id: string;
  };
};

export type VegetationInfoFragmentFragment = {
  __typename: 'VegetationActivity';
  name: string;
  region?: VegetationRegion | null;
  soilType?: SoilType | null;
  species?: VegetationSpecies | null;
  averageAge?: number | null;
  treesPerHectare?: number | null;
  totalPlantingAreaSquareMetres?: number | null;
  plantingAreaGeometry?: GeoJSON.MultiPolygon | null;
} & { ' $fragmentName'?: 'VegetationInfoFragmentFragment' };

export type PropertiesBusinessActivitiesActivitiesHeaderVegetationActivityFragment =
  {
    __typename: 'VegetationActivity';
    id: string;
    name: string;
    start: string;
    end: string;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesActivitiesHeaderVegetationActivityFragment';
  };

export type CreateVegetationActivityMutationVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  createVegetationActivityInput: CreateVegetationActivityInput;
}>;

export type CreateVegetationActivityMutation = {
  __typename: 'Mutation';
  createVegetationActivity: { __typename: 'VegetationActivity'; id: string };
};

export type UpdateVegetationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateVegetationActivityInput: UpdateVegetationActivityInput;
}>;

export type UpdateVegetationMutation = {
  __typename: 'Mutation';
  updateVegetationActivity: { __typename: 'VegetationActivity'; id: string } & {
    ' $fragmentRefs'?: {
      VegetationInfoFragmentFragment: VegetationInfoFragmentFragment;
    };
  };
};

export type GetVegetationInfoQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetVegetationInfoQuery = {
  __typename: 'Query';
  vegetationActivity?:
    | ({ __typename: 'VegetationActivity'; id: string } & {
        ' $fragmentRefs'?: {
          VegetationInfoFragmentFragment: VegetationInfoFragmentFragment;
        };
      })
    | null;
};

export type GetVegetationActivityInHeaderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetVegetationActivityInHeaderQuery = {
  __typename: 'Query';
  vegetationActivity?:
    | ({ __typename: 'VegetationActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesActivitiesHeaderVegetationActivityFragment: PropertiesBusinessActivitiesActivitiesHeaderVegetationActivityFragment;
        };
      })
    | null;
};

export type WildSeaFisheryBaitBaitPurchasesFragmentFragment = {
  __typename: 'FisheryBaitPurchase';
  id: string;
  baitType: FisheryBaitType;
  amountPurchasedTonnes: Decimal;
  percentageAdditionalIngredients?: Decimal | null;
  emissionsIntensityBaitProduct?: Decimal | null;
} & { ' $fragmentName'?: 'WildSeaFisheryBaitBaitPurchasesFragmentFragment' };

export type GetBaitPurchasesQueryVariables = Exact<{
  activityId: Scalars['ID']['input'];
}>;

export type GetBaitPurchasesQuery = {
  __typename: 'Query';
  baitPurchasesByWildSeaFisheryActivityId: Array<
    { __typename: 'FisheryBaitPurchase'; id: string } & {
      ' $fragmentRefs'?: {
        WildSeaFisheryBaitBaitPurchasesFragmentFragment: WildSeaFisheryBaitBaitPurchasesFragmentFragment;
      };
    }
  >;
};

export type GetBaitPurchaseQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetBaitPurchaseQuery = {
  __typename: 'Query';
  fisheryBaitPurchase: { __typename: 'FisheryBaitPurchase'; id: string } & {
    ' $fragmentRefs'?: {
      WildSeaFisheryBaitPurchasesFragmentFragment: WildSeaFisheryBaitPurchasesFragmentFragment;
    };
  };
};

export type CreateBaitPurchaseMutationVariables = Exact<{
  wildSeaFisheryActivityId: Scalars['ID']['input'];
  input: CreateFisheryBaitPurchaseInput;
}>;

export type CreateBaitPurchaseMutation = {
  __typename: 'Mutation';
  createFisheryBaitPurchase: {
    __typename: 'FisheryBaitPurchase';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      WildSeaFisheryBaitPurchasesFragmentFragment: WildSeaFisheryBaitPurchasesFragmentFragment;
    };
  };
};

export type UpdateBaitPurchaseMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateFisheryBaitPurchaseInput;
}>;

export type UpdateBaitPurchaseMutation = {
  __typename: 'Mutation';
  updateFisheryBaitPurchase: {
    __typename: 'FisheryBaitPurchase';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      WildSeaFisheryBaitPurchasesFragmentFragment: WildSeaFisheryBaitPurchasesFragmentFragment;
    };
  };
};

export type DeleteBaitPurchaseMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteBaitPurchaseMutation = {
  __typename: 'Mutation';
  deleteFisheryBaitPurchase: { __typename: 'FisheryBaitPurchase'; id: string };
};

export type WildSeaFisheryBaitPurchasesFragmentFragment = {
  __typename: 'FisheryBaitPurchase';
  id: string;
  baitType: FisheryBaitType;
  amountPurchasedTonnes: Decimal;
  percentageAdditionalIngredients?: Decimal | null;
  emissionsIntensityBaitProduct?: Decimal | null;
} & { ' $fragmentName'?: 'WildSeaFisheryBaitPurchasesFragmentFragment' };

export type WildSeaFisheryActivityInfoFragmentFragment = {
  __typename: 'WildSeaFisheryActivity';
  id: string;
  productionSystem?: WildSeaFisheryProductionSystem | null;
  totalWeightCaughtKg?: Decimal | null;
  offsetsPurchasedTonnes?: Decimal | null;
} & { ' $fragmentName'?: 'WildSeaFisheryActivityInfoFragmentFragment' };

export type GetWildSeaFisheryInfoQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetWildSeaFisheryInfoQuery = {
  __typename: 'Query';
  wildSeaFisheryActivity?:
    | ({ __typename: 'WildSeaFisheryActivity'; id: string } & {
        ' $fragmentRefs'?: {
          WildSeaFisheryActivityInfoFragmentFragment: WildSeaFisheryActivityInfoFragmentFragment;
        };
      })
    | null;
};

export type UpdateWildSeaFisheryActivityInfoMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updateWildSeaFisheryActivityInput: UpdateWildSeaFisheryActivityInput;
}>;

export type UpdateWildSeaFisheryActivityInfoMutation = {
  __typename: 'Mutation';
  updateWildSeaFisheryActivity: {
    __typename: 'WildSeaFisheryActivity';
    id: string;
  } & {
    ' $fragmentRefs'?: {
      WildSeaFisheryActivityInfoFragmentFragment: WildSeaFisheryActivityInfoFragmentFragment;
    };
  };
};

export type PropertiesBusinessActivitiesActivitiesHeaderWildSeaFisheryActivityFragment =
  {
    __typename: 'WildSeaFisheryActivity';
    id: string;
    name: string;
    start: string;
    end: string;
  } & {
    ' $fragmentName'?: 'PropertiesBusinessActivitiesActivitiesHeaderWildSeaFisheryActivityFragment';
  };

export type WildSeaFisheryTransportRoutesFragmentFragment = {
  __typename: 'Route';
  id: string;
  startPoint: string;
  endPoint: string;
  vehicleType: RouteVehicleType;
  fuelType?: RouteFuelType | null;
  distanceStartToEndKm?: Decimal | null;
  numberOfTrips?: number | null;
  totalDistanceTravelledKm: Decimal;
} & { ' $fragmentName'?: 'WildSeaFisheryTransportRoutesFragmentFragment' };

export type GetTransportRoutesQueryVariables = Exact<{
  activityId: Scalars['ID']['input'];
}>;

export type GetTransportRoutesQuery = {
  __typename: 'Query';
  routesByActivityId: Array<
    { __typename: 'Route'; id: string } & {
      ' $fragmentRefs'?: {
        WildSeaFisheryTransportRoutesFragmentFragment: WildSeaFisheryTransportRoutesFragmentFragment;
      };
    }
  >;
};

export type GetTransportRouteQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetTransportRouteQuery = {
  __typename: 'Query';
  route: { __typename: 'Route'; id: string } & {
    ' $fragmentRefs'?: {
      WildSeaFisheryTransportRouteFragmentFragment: WildSeaFisheryTransportRouteFragmentFragment;
    };
  };
};

export type CreateTransportRouteMutationVariables = Exact<{
  activityId: Scalars['ID']['input'];
  input: CreateRouteInput;
}>;

export type CreateTransportRouteMutation = {
  __typename: 'Mutation';
  createRoute: { __typename: 'Route'; id: string } & {
    ' $fragmentRefs'?: {
      WildSeaFisheryTransportRoutesFragmentFragment: WildSeaFisheryTransportRoutesFragmentFragment;
    };
  };
};

export type UpdateTransportRouteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateRouteInput;
}>;

export type UpdateTransportRouteMutation = {
  __typename: 'Mutation';
  updateRoute: { __typename: 'Route'; id: string } & {
    ' $fragmentRefs'?: {
      WildSeaFisheryTransportRoutesFragmentFragment: WildSeaFisheryTransportRoutesFragmentFragment;
    };
  };
};

export type DeleteTransportRouteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteTransportRouteMutation = {
  __typename: 'Mutation';
  deleteRoute: { __typename: 'Route'; id: string };
};

export type GetFisheryActivityForTransportQueryVariables = Exact<{
  wildSeaFisheryId: Scalars['ID']['input'];
}>;

export type GetFisheryActivityForTransportQuery = {
  __typename: 'Query';
  wildSeaFisheryActivity?:
    | ({ __typename: 'WildSeaFisheryActivity'; id: string } & {
        ' $fragmentRefs'?: {
          WildSeaFisheryActivityForTransportFragmentFragment: WildSeaFisheryActivityForTransportFragmentFragment;
        };
      })
    | null;
};

export type WildSeaFisheryTransportRouteFragmentFragment = {
  __typename: 'Route';
  id: string;
  startPoint: string;
  endPoint: string;
  vehicleType: RouteVehicleType;
  fuelType?: RouteFuelType | null;
  distanceStartToEndKm?: Decimal | null;
  numberOfTrips?: number | null;
  totalDistanceTravelledKm: Decimal;
} & { ' $fragmentName'?: 'WildSeaFisheryTransportRouteFragmentFragment' };

export type WildSeaFisheryActivityForTransportFragmentFragment = {
  __typename: 'WildSeaFisheryActivity';
  id: string;
  start: string;
  end: string;
} & { ' $fragmentName'?: 'WildSeaFisheryActivityForTransportFragmentFragment' };

export type CreateWildSeaFisheryActivityMutationVariables = Exact<{
  propertyId: Scalars['ID']['input'];
  createWildSeaFisheryActivityInput: CreateWildSeaFisheryActivityInput;
}>;

export type CreateWildSeaFisheryActivityMutation = {
  __typename: 'Mutation';
  createWildSeaFisheryActivity: {
    __typename: 'WildSeaFisheryActivity';
    id: string;
  };
};

export type GetWildSeaFisheryActivityInHeaderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetWildSeaFisheryActivityInHeaderQuery = {
  __typename: 'Query';
  wildSeaFisheryActivity?:
    | ({ __typename: 'WildSeaFisheryActivity'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesBusinessActivitiesActivitiesHeaderWildSeaFisheryActivityFragment: PropertiesBusinessActivitiesActivitiesHeaderWildSeaFisheryActivityFragment;
        };
      })
    | null;
};

export type EditPropertyPage_PropertyFragmentFragment = {
  __typename: 'Property';
  id: string;
  name?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  country?: string | null;
} & { ' $fragmentName'?: 'EditPropertyPage_PropertyFragmentFragment' };

export type EditPropertyPage_BusinessActivityFragmentFragment = {
  __typename: 'BusinessActivity';
  id: string;
  enabledActivityTypes?: Array<EnabledActivityTypes> | null;
  propertyNorthOfTropicOfCapricorn: boolean;
  rainfallAbove600: boolean;
  state: StateRegion;
} & { ' $fragmentName'?: 'EditPropertyPage_BusinessActivityFragmentFragment' };

export type UpdatePropertyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updatePropertyInput: UpdatePropertyInput;
}>;

export type UpdatePropertyMutation = {
  __typename: 'Mutation';
  updateProperty?:
    | ({ __typename: 'Property'; id: string } & {
        ' $fragmentRefs'?: {
          EditPropertyPage_PropertyFragmentFragment: EditPropertyPage_PropertyFragmentFragment;
          PropertyLayoutParcelGeometries_PropertyFragmentFragment: PropertyLayoutParcelGeometries_PropertyFragmentFragment;
        };
      })
    | null;
};

export type UpdateBusinessActivityMutationVariables = Exact<{
  updateBusinessActivityInput: UpdateBusinessActivityInput;
}>;

export type UpdateBusinessActivityMutation = {
  __typename: 'Mutation';
  updateBusinessActivity: { __typename: 'BusinessActivity'; id: string } & {
    ' $fragmentRefs'?: {
      EditPropertyPage_BusinessActivityFragmentFragment: EditPropertyPage_BusinessActivityFragmentFragment;
    };
  };
};

export type GetPropertyForEditQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPropertyForEditQuery = {
  __typename: 'Query';
  property?:
    | ({ __typename: 'Property'; id: string } & {
        ' $fragmentRefs'?: {
          EditPropertyPage_PropertyFragmentFragment: EditPropertyPage_PropertyFragmentFragment;
        };
      })
    | null;
};

export type GetBusinessActivityForPropertyQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
}>;

export type GetBusinessActivityForPropertyQuery = {
  __typename: 'Query';
  businessActivityForProperty?:
    | ({ __typename: 'BusinessActivity'; id: string } & {
        ' $fragmentRefs'?: {
          EditPropertyPage_BusinessActivityFragmentFragment: EditPropertyPage_BusinessActivityFragmentFragment;
        };
      })
    | null;
};

export type DeletePropertyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeletePropertyMutation = {
  __typename: 'Mutation';
  deleteProperty?: { __typename: 'Property'; id: string } | null;
};

export type PropertiesFilesFileFragmentFragment = {
  __typename: 'File';
  id: string;
  name?: string | null;
  description?: string | null;
  uploadStatus: FileUploadStatus;
  extension: string;
  mimeType: string;
  bytes: number;
  createdAt: string;
  creator?: {
    __typename: 'User';
    firstName?: string | null;
    lastName?: string | null;
  } | null;
} & { ' $fragmentName'?: 'PropertiesFilesFileFragmentFragment' };

export type GetPropertyFileQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPropertyFileQuery = {
  __typename: 'Query';
  file?:
    | ({
        __typename: 'File';
        id: string;
        uploadStatus: FileUploadStatus;
        name?: string | null;
      } & {
        ' $fragmentRefs'?: {
          PropertiesFilesFileFragmentFragment: PropertiesFilesFileFragmentFragment;
        };
      })
    | null;
};

export type DeletePropertyFileMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeletePropertyFileMutation = {
  __typename: 'Mutation';
  deleteFile: { __typename: 'File'; id: string };
};

export type PropertyFileForUploadFragmentFragment = {
  __typename: 'FileForUpload';
  presignedPostUrl: string;
  presignedPostFields: Array<{
    __typename: 'KeyValuePair';
    key: string;
    value: string;
  }>;
  file: {
    __typename: 'File';
    id: string;
    name?: string | null;
    description?: string | null;
    bytes: number;
    createdAt: string;
    updatedAt: string;
    extension: string;
    signedUrl: string;
  };
} & { ' $fragmentName'?: 'PropertyFileForUploadFragmentFragment' };

export type PropertyFilesFragmentFragment = {
  __typename: 'Property';
  files: Array<{
    __typename: 'File';
    id: string;
    name?: string | null;
    description?: string | null;
    uploadStatus: FileUploadStatus;
    bytes: number;
    extension: string;
    createdAt: string;
    updatedAt: string;
    signedUrl: string;
  }>;
} & { ' $fragmentName'?: 'PropertyFilesFragmentFragment' };

export type GetPropertyFilesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPropertyFilesQuery = {
  __typename: 'Query';
  property?:
    | ({ __typename: 'Property'; id: string } & {
        ' $fragmentRefs'?: {
          PropertyFilesFragmentFragment: PropertyFilesFragmentFragment;
        };
      })
    | null;
};

export type CreatePropertyFileMutationVariables = Exact<{
  createFileInput: CreateFileInput;
}>;

export type CreatePropertyFileMutation = {
  __typename: 'Mutation';
  createPropertyFile: { __typename: 'FileForUpload' } & {
    ' $fragmentRefs'?: {
      PropertyFileForUploadFragmentFragment: PropertyFileForUploadFragmentFragment;
    };
  };
};

export type UpdatePropertyFileMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateFileInput;
}>;

export type UpdatePropertyFileMutation = {
  __typename: 'Mutation';
  updateFile: { __typename: 'File'; id: string } & {
    ' $fragmentRefs'?: {
      PropertiesFilesFileFragmentFragment: PropertiesFilesFileFragmentFragment;
    };
  };
};

export type PropertyPage_PropertyFragmentFragment = {
  __typename: 'Property';
  name?: string | null;
} & { ' $fragmentName'?: 'PropertyPage_PropertyFragmentFragment' };

export type GetPropertyQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPropertyQuery = {
  __typename: 'Query';
  property?:
    | ({ __typename: 'Property'; id: string } & {
        ' $fragmentRefs'?: {
          PropertyPage_PropertyFragmentFragment: PropertyPage_PropertyFragmentFragment;
        };
      })
    | null;
};

export type GetBusinessActivityForProeprtyQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
}>;

export type GetBusinessActivityForProeprtyQuery = {
  __typename: 'Query';
  businessActivityForProperty?:
    | ({ __typename: 'BusinessActivity'; id: string } & {
        ' $fragmentRefs'?: {
          UseBusinessActivityForProperty_BusinessActivityFragmentFragment: UseBusinessActivityForProperty_BusinessActivityFragmentFragment;
        };
      })
    | null;
};

export type UseBusinessActivityForProperty_BusinessActivityFragmentFragment = {
  __typename: 'BusinessActivity';
  id: string;
  enabledActivityTypes?: Array<EnabledActivityTypes> | null;
  state: StateRegion;
} & {
  ' $fragmentName'?: 'UseBusinessActivityForProperty_BusinessActivityFragmentFragment';
};

export type MapShapesEditIndex_MapShapeFragmentFragment = {
  __typename: 'MapShape';
  id: string;
  type: MapShapeType;
  name?: string | null;
  geometry?: GeoJSON.MultiPolygon | null;
} & { ' $fragmentName'?: 'MapShapesEditIndex_MapShapeFragmentFragment' };

export type GetMapShapeQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetMapShapeQuery = {
  __typename: 'Query';
  mapShape: { __typename: 'MapShape'; id: string } & {
    ' $fragmentRefs'?: {
      MapShapesEditIndex_MapShapeFragmentFragment: MapShapesEditIndex_MapShapeFragmentFragment;
    };
  };
};

export type UpdateMapShapeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateMapShapeInput;
}>;

export type UpdateMapShapeMutation = {
  __typename: 'Mutation';
  updateMapShape: { __typename: 'MapShape'; id: string } & {
    ' $fragmentRefs'?: {
      MapShapesEditIndex_MapShapeFragmentFragment: MapShapesEditIndex_MapShapeFragmentFragment;
    };
  };
};

export type DeleteMapShapeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteMapShapeMutation = {
  __typename: 'Mutation';
  deleteMapShape: { __typename: 'MapShape'; id: string };
};

export type MapShapesIndex_MapShapeFragment = ({
  __typename: 'MapShape';
  geometry?: GeoJSON.MultiPolygon | null;
  name?: string | null;
} & {
  ' $fragmentRefs'?: {
    MapShapesList_MapShapeFragment: MapShapesList_MapShapeFragment;
  };
}) & { ' $fragmentName'?: 'MapShapesIndex_MapShapeFragment' };

export type GetMapShapesQueryVariables = Exact<{
  propertyId: Scalars['ID']['input'];
}>;

export type GetMapShapesQuery = {
  __typename: 'Query';
  mapShapes: Array<
    { __typename: 'MapShape'; id: string } & {
      ' $fragmentRefs'?: {
        MapShapesIndex_MapShapeFragment: MapShapesIndex_MapShapeFragment;
      };
    }
  >;
};

export type CreateMapShapeMutationVariables = Exact<{
  input: CreateMapShapeInput;
}>;

export type CreateMapShapeMutation = {
  __typename: 'Mutation';
  createMapShape: { __typename: 'MapShape'; id: string } & {
    ' $fragmentRefs'?: {
      MapShapesIndex_MapShapeFragment: MapShapesIndex_MapShapeFragment;
    };
  };
};

export type PropertiesNotesNoteFragmentFragment = {
  __typename: 'PropertyNote';
  id: string;
  title: string;
  text: string;
} & { ' $fragmentName'?: 'PropertiesNotesNoteFragmentFragment' };

export type GetPropertyNoteQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPropertyNoteQuery = {
  __typename: 'Query';
  propertyNote?:
    | ({ __typename: 'PropertyNote'; id: string } & {
        ' $fragmentRefs'?: {
          PropertiesNotesNoteFragmentFragment: PropertiesNotesNoteFragmentFragment;
        };
      })
    | null;
};

export type UpdatePropertyNoteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdatePropertyNoteInput;
}>;

export type UpdatePropertyNoteMutation = {
  __typename: 'Mutation';
  updatePropertyNote: { __typename: 'PropertyNote'; id: string } & {
    ' $fragmentRefs'?: {
      PropertiesNotesNoteFragmentFragment: PropertiesNotesNoteFragmentFragment;
    };
  };
};

export type DeletePropertyNoteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeletePropertyNoteMutation = {
  __typename: 'Mutation';
  deletePropertyNote: { __typename: 'PropertyNote'; id: string };
};

export type PropertyNotesFragmentFragment = {
  __typename: 'Property';
  propertyNotes?: Array<{
    __typename: 'PropertyNote';
    id: string;
    title: string;
    text: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null> | null;
} & { ' $fragmentName'?: 'PropertyNotesFragmentFragment' };

export type GetPropertyNotesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPropertyNotesQuery = {
  __typename: 'Query';
  property?:
    | ({ __typename: 'Property'; id: string } & {
        ' $fragmentRefs'?: {
          PropertyNotesFragmentFragment: PropertyNotesFragmentFragment;
        };
      })
    | null;
};

export type CreatePropertyNoteMutationVariables = Exact<{
  createPropertyNoteInput: CreatePropertyNoteInput;
}>;

export type CreatePropertyNoteMutation = {
  __typename: 'Mutation';
  createPropertyNote: { __typename: 'PropertyNote'; id: string };
};

export type AllTeamPropertiesPagePropertyFragment = {
  __typename: 'Property';
  id: string;
  name?: string | null;
  addressLine1?: string | null;
  city?: string | null;
  state?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  centroid?: GeoJSON.Point | null;
} & { ' $fragmentName'?: 'AllTeamPropertiesPagePropertyFragment' };

export type GetPropertiesQueryVariables = Exact<{
  teamId: Scalars['ID']['input'];
}>;

export type GetPropertiesQuery = {
  __typename: 'Query';
  properties: Array<
    { __typename: 'Property'; id: string } & {
      ' $fragmentRefs'?: {
        AllTeamPropertiesPagePropertyFragment: AllTeamPropertiesPagePropertyFragment;
      };
    }
  >;
};

export type CreateBusinessActivityMutationVariables = Exact<{
  createBusinessActivityInput: CreateBusinessActivityInput;
}>;

export type CreateBusinessActivityMutation = {
  __typename: 'Mutation';
  createBusinessActivity: { __typename: 'BusinessActivity'; id: string } & {
    ' $fragmentRefs'?: {
      EditPropertyPage_BusinessActivityFragmentFragment: EditPropertyPage_BusinessActivityFragmentFragment;
    };
  };
};

export type GetParcelForUseEditPropertyBoundaryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetParcelForUseEditPropertyBoundaryQuery = {
  __typename: 'Query';
  parcel?:
    | ({ __typename: 'Parcel'; id: string } & {
        ' $fragmentRefs'?: {
          UseEditPropertyBoundary_ParcelFragmentFragment: UseEditPropertyBoundary_ParcelFragmentFragment;
        };
      })
    | null;
};

export type GetGeographicInformationForPointForUseEditPropertyBoundaryQueryVariables =
  Exact<{
    point: Scalars['GeoJSONPoint']['input'];
  }>;

export type GetGeographicInformationForPointForUseEditPropertyBoundaryQuery = {
  __typename: 'Query';
  geographicInformationForPoint?:
    | ({ __typename: 'GeographicInformation' } & {
        ' $fragmentRefs'?: {
          UseEditPropertyBoundary_GeographicInformationFragmentFragment: UseEditPropertyBoundary_GeographicInformationFragmentFragment;
        };
      })
    | null;
};

export type SearchParcelsQueryVariables = Exact<{
  input: ParcelSearchInput;
}>;

export type SearchParcelsQuery = {
  __typename: 'Query';
  searchParcels?: Array<{
    __typename: 'ParcelElastic';
    id: string;
    type: FeatureType;
    geometry: GeoJSON.Geometry;
    properties: {
      __typename: 'ParcelElasticProperties';
      id: string;
      name?: string | null;
    };
  } | null> | null;
};

export type UseEditPropertyBoundary_ParcelFragmentFragment = {
  __typename: 'Parcel';
  id: string;
  geoFeature?: {
    __typename: 'ParcelGeoFeature';
    id: string;
    type: FeatureType;
    geometry: GeoJSON.Geometry;
    properties: {
      __typename: 'ParcelGeoFeatureProperties';
      id: string;
      name?: string | null;
    };
  } | null;
} & { ' $fragmentName'?: 'UseEditPropertyBoundary_ParcelFragmentFragment' };

export type UseEditPropertyBoundary_GeographicInformationFragmentFragment = {
  __typename: 'GeographicInformation';
  state: State;
} & {
  ' $fragmentName'?: 'UseEditPropertyBoundary_GeographicInformationFragmentFragment';
};

export type CreatePropertyMutationVariables = Exact<{
  createPropertyInput: CreatePropertyInput;
}>;

export type CreatePropertyMutation = {
  __typename: 'Mutation';
  createProperty?: { __typename: 'Property'; id: string } | null;
};

export const Report_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Report_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sections' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reportMetadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Report_ReportFragment, unknown>;
export const ParcelsAndBuildingsPage_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ParcelsAndBuildingsPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertyParcelsList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'buildingsList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ParcelsAndBuildingsPage_ReportFragment, unknown>;
export const TableOfContentsPage_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TableOfContentsPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sections' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TableOfContentsPage_ReportFragment, unknown>;
export const OverviewMap_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OverviewMap_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyParcelsGeoJson' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buildingsGeometries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OverviewMap_ReportDataFragment, unknown>;
export const PropertyDetailsTable_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyDetailsTable_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertySite' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landmarksLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PropertyDetailsTable_ReportDataFragment, unknown>;
export const LandmarksTable_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandmarksTable_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landmarksTowns' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landmarksCities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landmarksSchools' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landmarksSilos' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LandmarksTable_ReportDataFragment, unknown>;
export const OverviewPage_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OverviewPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OverviewMap_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PropertyDetailsTable_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LandmarksTable_ReportData' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OverviewMap_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyParcelsGeoJson' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buildingsGeometries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyDetailsTable_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertySite' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landmarksLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandmarksTable_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landmarksTowns' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landmarksCities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landmarksSchools' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landmarksSilos' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OverviewPage_ReportFragment, unknown>;
export const SummaryTable_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SummaryTable_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elevationArable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landUseClasses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilAusClasses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elevationStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elevationSlope' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SummaryTable_ReportDataFragment, unknown>;
export const ProductivityCard_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductivityCard_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'yieldModelDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductivityCard_ReportDataFragment, unknown>;
export const AnnualRainfallCard_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AnnualRainfallCard_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AnnualRainfallCard_ReportDataFragment, unknown>;
export const ClimateRiskCard_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClimateRiskCard_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateRiskStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClimateRiskCard_ReportDataFragment, unknown>;
export const PropertyAndClimateSummaryPage_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyAndClimateSummaryPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SummaryTable_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProductivityCard_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'AnnualRainfallCard_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClimateRiskCard_ReportData' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'elevationArableImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landUseClassesImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landUseClasses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SummaryTable_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elevationArable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landUseClasses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilAusClasses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elevationStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elevationSlope' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductivityCard_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'yieldModelDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AnnualRainfallCard_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClimateRiskCard_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateRiskStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PropertyAndClimateSummaryPage_ReportFragment,
  unknown
>;
export const LandUse_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandUse_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landUseClasses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LandUse_ReportDataFragment, unknown>;
export const PropertyDetailsLandUseTable_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyDetailsLandUseTable_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertySite' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PropertyDetailsLandUseTable_ReportDataFragment,
  unknown
>;
export const UseTerrainSummary_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseTerrainSummary_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'satelliteRgbYearlyImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landUseClassesImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'satelliteNdviYearlyImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseTerrainSummary_ReportDataFragment, unknown>;
export const LandUsePage_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandUsePage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LandUse_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PropertyDetailsLandUseTable_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UseTerrainSummary_ReportData' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandUse_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landUseClasses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyDetailsLandUseTable_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertySite' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseTerrainSummary_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'satelliteRgbYearlyImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landUseClassesImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'satelliteNdviYearlyImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LandUsePage_ReportFragment, unknown>;
export const EviAreaProductivity_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EviAreaProductivity_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eviAreaProductivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EviAreaProductivity_ReportDataFragment, unknown>;
export const EviAreaAndRainfall_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EviAreaAndRainfall_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eviAreaProductivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallVolume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EviAreaAndRainfall_ReportDataFragment, unknown>;
export const YieldCropTypes_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'YieldCropTypes_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'yieldModelYearly' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<YieldCropTypes_ReportDataFragment, unknown>;
export const ProductivityPage_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductivityPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EviAreaProductivity_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EviAreaAndRainfall_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'YieldCropTypes_ReportData' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EviAreaProductivity_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eviAreaProductivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EviAreaAndRainfall_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eviAreaProductivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallVolume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'YieldCropTypes_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'yieldModelYearly' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductivityPage_ReportFragment, unknown>;
export const RainfallDuration_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RainfallDuration_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RainfallDuration_ReportDataFragment, unknown>;
export const RainfallVolume_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RainfallVolume_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallVolume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RainfallVolume_ReportDataFragment, unknown>;
export const SeasonalRainfallHistory_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SeasonalRainfallHistory_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallVolume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SeasonalRainfallHistory_ReportDataFragment,
  unknown
>;
export const RainfallHistory_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RainfallHistory_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallYtd' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallYtdMinus1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RainfallHistory_ReportDataFragment, unknown>;
export const RainfallPage_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RainfallPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RainfallDuration_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RainfallVolume_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SeasonalRainfallHistory_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RainfallHistory_ReportData' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RainfallDuration_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RainfallVolume_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallVolume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SeasonalRainfallHistory_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallVolume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RainfallHistory_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallYtd' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallYtdMinus1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RainfallPage_ReportFragment, unknown>;
export const Elevation_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Elevation_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elevationStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Elevation_ReportDataFragment, unknown>;
export const Slope_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Slope_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elevationSlope' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Slope_ReportDataFragment, unknown>;
export const TerrainPage_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TerrainPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Elevation_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Slope_ReportData' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'elevationElevationImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'elevationSlopeImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'elevationStatistics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'elevationSlope' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Elevation_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elevationStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Slope_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elevationSlope' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TerrainPage_ReportFragment, unknown>;
export const SoilTaxonomyPage_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SoilTaxonomyPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertyParcelsGeoJson' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'soilAusClasses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'soilAusClassesImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SoilTaxonomyPage_ReportFragment, unknown>;
export const SoilTexturePage_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SoilTexturePage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'soilOcImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'soilPhImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'soilAwcImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SoilTexturePage_ReportFragment, unknown>;
export const SoilTexture_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SoilTexture_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilSandProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilSiltProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilClayProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SoilTexture_ReportDataFragment, unknown>;
export const SoilOrganicCarbon_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SoilOrganicCarbon_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilOcProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SoilOrganicCarbon_ReportDataFragment, unknown>;
export const SoilPh_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SoilPh_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilPhProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SoilPh_ReportDataFragment, unknown>;
export const SoilAwc_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SoilAwc_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilAwcProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SoilAwc_ReportDataFragment, unknown>;
export const SoilProfilePage_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SoilProfilePage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SoilTexture_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SoilOrganicCarbon_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SoilPh_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SoilAwc_ReportData' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SoilTexture_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilSandProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilSiltProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilClayProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SoilOrganicCarbon_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilOcProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SoilPh_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilPhProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SoilAwc_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilAwcProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SoilProfilePage_ReportFragment, unknown>;
export const LandAndNaturePage_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandAndNaturePage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertyParcelsGeoJson' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'forestClasses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'forestClassesImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landCoverClasses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landCoverClassesImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LandAndNaturePage_ReportFragment, unknown>;
export const FireRiskChart_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FireRiskChart_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grasslandFireMonthly' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FireRiskChart_ReportDataFragment, unknown>;
export const TemperatureMeanHistory_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemperatureMeanHistory_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'temperatureMeanHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'temperatureMeanYtd' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'temperatureMeanYtdMinus1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TemperatureMeanHistory_ReportDataFragment,
  unknown
>;
export const GrassAndForestFireRiskPage_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GrassAndForestFireRiskPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FireRiskChart_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'TemperatureMeanHistory_ReportData',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grasslandFireMaximum' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FireRiskChart_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grasslandFireMonthly' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemperatureMeanHistory_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'temperatureMeanHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'temperatureMeanYtd' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'temperatureMeanYtdMinus1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GrassAndForestFireRiskPage_ReportFragment,
  unknown
>;
export const FiresTable_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FiresTable_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'burnedAreaEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FiresTable_ReportDataFragment, unknown>;
export const EnvironmentRiskPage_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnvironmentRiskPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FiresTable_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'TemperatureMeanHistory_ReportData',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FiresTable_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'burnedAreaEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemperatureMeanHistory_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'temperatureMeanHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'temperatureMeanYtd' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'temperatureMeanYtdMinus1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EnvironmentRiskPage_ReportFragment, unknown>;
export const ClimateChangeRainfall_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClimateChangeRainfall_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateSSP126RainfallYearly' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateSSP126RainfallYearlyStdDev' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClimateChangeRainfall_ReportDataFragment, unknown>;
export const ClimateChangeTempMin_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClimateChangeTempMin_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateSSP126TempMinYearly' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateSSP126TempMinYearlyStdDev' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClimateChangeTempMin_ReportDataFragment, unknown>;
export const ClimateChangeTempMax_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClimateChangeTempMax_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateSSP126TempMaxYearly' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateSSP126TempMaxYearlyStdDev' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClimateChangeTempMax_ReportDataFragment, unknown>;
export const ClimateChangePage_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClimateChangePage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClimateChangeRainfall_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClimateChangeTempMin_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClimateChangeTempMax_ReportData',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClimateChangeRainfall_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateSSP126RainfallYearly' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateSSP126RainfallYearlyStdDev' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClimateChangeTempMin_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateSSP126TempMinYearly' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateSSP126TempMinYearlyStdDev' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClimateChangeTempMax_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateSSP126TempMaxYearly' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateSSP126TempMaxYearlyStdDev' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClimateChangePage_ReportFragment, unknown>;
export const HeatEventsVolume_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HeatEventsVolume_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heatEventsVolume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HeatEventsVolume_ReportDataFragment, unknown>;
export const FrostEventsVolume_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FrostEventsVolume_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frostEventsVolume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FrostEventsVolume_ReportDataFragment, unknown>;
export const HeatEventsHistory_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HeatEventsHistory_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heatEventsHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heatEventsYtd' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heatEventsYtdMinus1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HeatEventsHistory_ReportDataFragment, unknown>;
export const FrostEventsHistory_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FrostEventsHistory_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frostEventsHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frostEventsYtd' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frostEventsYtdMinus1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FrostEventsHistory_ReportDataFragment, unknown>;
export const TemperatureEventsPage_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemperatureEventsPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HeatEventsVolume_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FrostEventsVolume_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HeatEventsHistory_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FrostEventsHistory_ReportData',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HeatEventsVolume_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heatEventsVolume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FrostEventsVolume_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frostEventsVolume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HeatEventsHistory_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heatEventsHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heatEventsYtd' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heatEventsYtdMinus1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FrostEventsHistory_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frostEventsHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frostEventsYtd' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frostEventsYtdMinus1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TemperatureEventsPage_ReportFragment, unknown>;
export const DeforestationOverTimeChart_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DeforestationOverTimeChart_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationYearly' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationColorMap' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationDataQuality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeforestationOverTimeChart_ReportDataFragment,
  unknown
>;
export const UseDeforestationImages_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseDeforestationImages_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationYearlyImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationRgbYearlyImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationNdviYearlyImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationDataQuality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseDeforestationImages_ReportDataFragment,
  unknown
>;
export const DeforestationOverTimePage_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DeforestationOverTimePage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DeforestationOverTimeChart_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseDeforestationImages_ReportData',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DeforestationOverTimeChart_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationYearly' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationColorMap' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationDataQuality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseDeforestationImages_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationYearlyImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationRgbYearlyImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationNdviYearlyImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationDataQuality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeforestationOverTimePage_ReportFragment, unknown>;
export const ForestsAndFiresPage_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ForestsAndFiresPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FiresTable_ReportData' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertyParcelsGeoJson' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'forestClasses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'forestClassesImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FiresTable_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'burnedAreaEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ForestsAndFiresPage_ReportFragment, unknown>;
export const RecentSalesPriceChart_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecentSalesPriceChart_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comparableSales' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'saleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'similarity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sale' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contractDate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecentSalesPriceChart_ReportFragment, unknown>;
export const RecentSalesPriceChart_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecentSalesPriceChart_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesPriceDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesPropertyHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecentSalesPriceChart_ReportDataFragment, unknown>;
export const RecentSalesPricePerAreaChart_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecentSalesPricePerAreaChart_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesPricePerAreaDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesPropertyHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecentSalesPricePerAreaChart_ReportDataFragment,
  unknown
>;
export const PricePerHectareOverTimeChart_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PricePerHectareOverTimeChart_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesAveragePriceHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PricePerHectareOverTimeChart_ReportDataFragment,
  unknown
>;
export const NumberOfSalesOverTimeChart_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NumberOfSalesOverTimeChart_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesSaleCountHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NumberOfSalesOverTimeChart_ReportDataFragment,
  unknown
>;
export const PropertyPastSalesTable_ReportDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyPastSalesTable_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesPropertyHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PropertyPastSalesTable_ReportDataFragment,
  unknown
>;
export const SalesPage_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'RecentSalesPriceChart_Report' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salesPropertyHistory' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecentSalesPriceChart_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecentSalesPricePerAreaChart_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PricePerHectareOverTimeChart_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'NumberOfSalesOverTimeChart_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PropertyPastSalesTable_ReportData',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecentSalesPriceChart_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comparableSales' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'saleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'similarity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sale' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contractDate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecentSalesPriceChart_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesPriceDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesPropertyHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecentSalesPricePerAreaChart_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesPricePerAreaDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesPropertyHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PricePerHectareOverTimeChart_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesAveragePriceHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NumberOfSalesOverTimeChart_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesSaleCountHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyPastSalesTable_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesPropertyHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SalesPage_ReportFragment, unknown>;
export const ViewReport_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewReport_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'Report_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ParcelsAndBuildingsPage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'TableOfContentsPage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'OverviewPage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'PropertyAndClimateSummaryPage_Report',
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'LandUsePage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProductivityPage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'RainfallPage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'TerrainPage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SoilTaxonomyPage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SoilTexturePage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SoilProfilePage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'LandAndNaturePage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'GrassAndForestFireRiskPage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'EnvironmentRiskPage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ClimateChangePage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'TemperatureEventsPage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'DeforestationOverTimePage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ForestsAndFiresPage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SalesPage_Report' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OverviewMap_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyParcelsGeoJson' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buildingsGeometries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyDetailsTable_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertySite' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landmarksLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandmarksTable_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landmarksTowns' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landmarksCities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landmarksSchools' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landmarksSilos' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SummaryTable_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elevationArable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landUseClasses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilAusClasses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elevationStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elevationSlope' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductivityCard_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'yieldModelDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AnnualRainfallCard_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClimateRiskCard_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateRiskStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandUse_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landUseClasses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyDetailsLandUseTable_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertySite' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseTerrainSummary_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'satelliteRgbYearlyImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landUseClassesImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'satelliteNdviYearlyImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EviAreaProductivity_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eviAreaProductivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EviAreaAndRainfall_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eviAreaProductivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallVolume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'YieldCropTypes_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'yieldModelYearly' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RainfallDuration_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RainfallVolume_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallVolume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SeasonalRainfallHistory_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallVolume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RainfallHistory_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallYtd' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallYtdMinus1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Elevation_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elevationStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Slope_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elevationSlope' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SoilTexture_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilSandProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilSiltProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilClayProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SoilOrganicCarbon_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilOcProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SoilPh_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilPhProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SoilAwc_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilAwcProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FireRiskChart_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grasslandFireMonthly' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemperatureMeanHistory_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'temperatureMeanHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'temperatureMeanYtd' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'temperatureMeanYtdMinus1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FiresTable_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'burnedAreaEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClimateChangeRainfall_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateSSP126RainfallYearly' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateSSP126RainfallYearlyStdDev' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClimateChangeTempMin_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateSSP126TempMinYearly' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateSSP126TempMinYearlyStdDev' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClimateChangeTempMax_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateSSP126TempMaxYearly' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateSSP126TempMaxYearlyStdDev' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HeatEventsVolume_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heatEventsVolume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FrostEventsVolume_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frostEventsVolume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HeatEventsHistory_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heatEventsHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heatEventsYtd' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heatEventsYtdMinus1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FrostEventsHistory_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frostEventsHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frostEventsYtd' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frostEventsYtdMinus1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DeforestationOverTimeChart_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationYearly' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationColorMap' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationDataQuality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseDeforestationImages_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationYearlyImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationRgbYearlyImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationNdviYearlyImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationDataQuality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecentSalesPriceChart_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comparableSales' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'saleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'similarity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sale' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contractDate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecentSalesPriceChart_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesPriceDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesPropertyHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecentSalesPricePerAreaChart_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesPricePerAreaDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesPropertyHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PricePerHectareOverTimeChart_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesAveragePriceHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NumberOfSalesOverTimeChart_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesSaleCountHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyPastSalesTable_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesPropertyHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Report_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sections' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reportMetadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ParcelsAndBuildingsPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertyParcelsList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'buildingsList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TableOfContentsPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sections' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OverviewPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OverviewMap_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PropertyDetailsTable_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LandmarksTable_ReportData' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyAndClimateSummaryPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SummaryTable_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProductivityCard_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'AnnualRainfallCard_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClimateRiskCard_ReportData' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'elevationArableImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landUseClassesImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landUseClasses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandUsePage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LandUse_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PropertyDetailsLandUseTable_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UseTerrainSummary_ReportData' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductivityPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EviAreaProductivity_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EviAreaAndRainfall_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'YieldCropTypes_ReportData' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RainfallPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RainfallDuration_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RainfallVolume_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SeasonalRainfallHistory_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RainfallHistory_ReportData' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TerrainPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Elevation_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Slope_ReportData' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'elevationElevationImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'elevationSlopeImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'elevationStatistics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'elevationSlope' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SoilTaxonomyPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertyParcelsGeoJson' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'soilAusClasses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'soilAusClassesImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SoilTexturePage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'soilOcImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'soilPhImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'soilAwcImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SoilProfilePage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SoilTexture_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SoilOrganicCarbon_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SoilPh_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SoilAwc_ReportData' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandAndNaturePage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertyParcelsGeoJson' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'forestClasses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'forestClassesImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landCoverClasses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landCoverClassesImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GrassAndForestFireRiskPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FireRiskChart_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'TemperatureMeanHistory_ReportData',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grasslandFireMaximum' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnvironmentRiskPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FiresTable_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'TemperatureMeanHistory_ReportData',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClimateChangePage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClimateChangeRainfall_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClimateChangeTempMin_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClimateChangeTempMax_ReportData',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemperatureEventsPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HeatEventsVolume_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FrostEventsVolume_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HeatEventsHistory_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FrostEventsHistory_ReportData',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DeforestationOverTimePage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DeforestationOverTimeChart_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseDeforestationImages_ReportData',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ForestsAndFiresPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FiresTable_ReportData' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertyParcelsGeoJson' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'forestClasses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'forestClassesImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'RecentSalesPriceChart_Report' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salesPropertyHistory' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecentSalesPriceChart_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecentSalesPricePerAreaChart_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PricePerHectareOverTimeChart_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'NumberOfSalesOverTimeChart_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PropertyPastSalesTable_ReportData',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ViewReport_ReportFragment, unknown>;
export const ComparableSaleAsideLayoutComparableSaleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ComparableSaleAsideLayoutComparableSale' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComparableSale' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sale' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contractDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertiesInSale' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'property' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'addressLine1' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'propertyParcels' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parcel' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'hectares',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'latitude',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'longitude',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'legalNumber',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ComparableSaleAsideLayoutComparableSaleFragment,
  unknown
>;
export const PortfolioLayoutPropertyCoordinates_PortfolioFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PortfolioLayoutPropertyCoordinates_Portfolio',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolioProperties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'portfolioId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'property' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'centroid' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bbox' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PortfolioLayoutPropertyCoordinates_PortfolioFragment,
  unknown
>;
export const PortfolioLayoutPropertyList_PortfolioFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioLayoutPropertyList_Portfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolioProperties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'portfolioId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'property' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PortfolioLayoutPropertyList_PortfolioFragment,
  unknown
>;
export const PortfolioLayoutPortals_PortfolioFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioLayoutPortals_Portfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolioProperties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'portfolioId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'property' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'centroid' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bbox' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PortfolioLayoutPortals_PortfolioFragment, unknown>;
export const PropertyLayoutParcelGeometries_PropertyFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertyLayoutParcelGeometries_PropertyFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyParcels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parcelId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'geometry' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PropertyLayoutParcelGeometries_PropertyFragmentFragment,
  unknown
>;
export const PropertyLayoutPortals_PropertyFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyLayoutPortals_PropertyFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'PropertyLayoutParcelGeometries_PropertyFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertyLayoutParcelGeometries_PropertyFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyParcels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parcelId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'geometry' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PropertyLayoutPortals_PropertyFragmentFragment,
  unknown
>;
export const MapShapesListItem_MapShapeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MapShapesListItem_MapShape' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MapShape' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'geometry' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MapShapesListItem_MapShapeFragment, unknown>;
export const MapShapesList_MapShapeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MapShapesList_MapShape' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MapShape' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'MapShapesListItem_MapShape' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MapShapesListItem_MapShape' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MapShape' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'geometry' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MapShapesList_MapShapeFragment, unknown>;
export const SelectMapShapeFromLibraryModal_MapShapeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SelectMapShapeFromLibraryModal_MapShape' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MapShape' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'geometry' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'MapShapesList_MapShape' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MapShapesListItem_MapShape' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MapShape' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'geometry' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MapShapesList_MapShape' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MapShape' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'MapShapesListItem_MapShape' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SelectMapShapeFromLibraryModal_MapShapeFragment,
  unknown
>;
export const AdvisorDashboardTeamsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdvisorDashboardTeamsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Team' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organisation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdvisorDashboardTeamsFragmentFragment, unknown>;
export const AllPropertiesForEditPortfolioFormFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AllPropertiesForEditPortfolioFormFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'centroid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bbox' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllPropertiesForEditPortfolioFormFragmentFragment,
  unknown
>;
export const PortfolioEditPage_PortfolioFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioEditPage_PortfolioFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolioProperties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'property' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'centroid' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'bbox' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PortfolioEditPage_PortfolioFragmentFragment,
  unknown
>;
export const PortfolioPage_PortfolioFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioPage_PortfolioFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
      },
    },
  ],
} as unknown as DocumentNode<PortfolioPage_PortfolioFragmentFragment, unknown>;
export const TeamPortfoliosFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamPortfoliosFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolioProperties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TeamPortfoliosFragmentFragment, unknown>;
export const RecentSalesPriceChart_PropertyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecentSalesPriceChart_Property' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comparableSales' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'saleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'similarity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sale' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contractDate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecentSalesPriceChart_PropertyFragment, unknown>;
export const RecentSalesPricePerAreaChart_PropertyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecentSalesPricePerAreaChart_Property' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comparableSales' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'saleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'similarity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sale' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contractDate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecentSalesPricePerAreaChart_PropertyFragment,
  unknown
>;
export const PropertyPastSalesTable_PropertySalesDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyPastSalesTable_PropertySalesData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PropertySalesData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertySalesHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PropertyPastSalesTable_PropertySalesDataFragment,
  unknown
>;
export const NumberOfSalesOverTimeChart_PropertySalesDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'NumberOfSalesOverTimeChart_PropertySalesData',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PropertySalesData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'summarySaleCountHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NumberOfSalesOverTimeChart_PropertySalesDataFragment,
  unknown
>;
export const PricePerHectareOverTimeChart_PropertySalesDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PricePerHectareOverTimeChart_PropertySalesData',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PropertySalesData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'summaryAveragePriceHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PricePerHectareOverTimeChart_PropertySalesDataFragment,
  unknown
>;
export const RecentSalesPriceChart_PropertySalesDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecentSalesPriceChart_PropertySalesData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PropertySalesData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'summaryPriceDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertySalesHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecentSalesPriceChart_PropertySalesDataFragment,
  unknown
>;
export const RecentSalesPricePerAreaChart_PropertySalesDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecentSalesPricePerAreaChart_PropertySalesData',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PropertySalesData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'summaryPricePerAreaDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertySalesHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecentSalesPricePerAreaChart_PropertySalesDataFragment,
  unknown
>;
export const CmaAssessmentAnalysisPage_PropertyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CmaAssessmentAnalysisPage_Property' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'RecentSalesPriceChart_Property' },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'RecentSalesPricePerAreaChart_Property',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PropertyPastSalesTable_PropertySalesData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'NumberOfSalesOverTimeChart_PropertySalesData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PricePerHectareOverTimeChart_PropertySalesData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecentSalesPriceChart_PropertySalesData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecentSalesPricePerAreaChart_PropertySalesData',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecentSalesPriceChart_Property' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comparableSales' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'saleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'similarity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sale' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contractDate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecentSalesPricePerAreaChart_Property' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comparableSales' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'saleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'similarity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sale' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contractDate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyPastSalesTable_PropertySalesData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PropertySalesData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertySalesHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'NumberOfSalesOverTimeChart_PropertySalesData',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PropertySalesData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'summarySaleCountHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PricePerHectareOverTimeChart_PropertySalesData',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PropertySalesData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'summaryAveragePriceHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecentSalesPriceChart_PropertySalesData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PropertySalesData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'summaryPriceDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertySalesHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecentSalesPricePerAreaChart_PropertySalesData',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PropertySalesData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'summaryPricePerAreaDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertySalesHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CmaAssessmentAnalysisPage_PropertyFragment,
  unknown
>;
export const PropertyComparableSaleFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyComparableSaleFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComparableSale' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'saleId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sale' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contractDate' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landUses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landUse' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricePerHectare' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'property' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PropertyComparableSaleFragmentFragment, unknown>;
export const CmaAssessmentSalesSearchResultsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CmaAssessmentSalesSearchResults' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComparableSaleSearchResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'saleDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'boundingGeoJson' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bbox' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceRef' } },
          { kind: 'Field', name: { kind: 'Name', value: 'similarity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CmaAssessmentSalesSearchResultsFragment, unknown>;
export const CmaAssessmentPropertyComparableSalesFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'CmaAssessmentPropertyComparableSalesFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comparableSales' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'similarity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'saleId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sale' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'externalId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contractDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'centroid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'bbox' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'geog' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'property' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CmaAssessmentPropertyComparableSalesFragmentFragment,
  unknown
>;
export const CmaAssessmentPropertyCmaVectorFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CmaAssessmentPropertyCMAVectorFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmaVector' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CmaAssessmentPropertyCmaVectorFragmentFragment,
  unknown
>;
export const CmaAssessmentReportsEditPage_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CmaAssessmentReportsEditPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sections' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CmaAssessmentReportsEditPage_ReportFragment,
  unknown
>;
export const CmaReportListItem_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CmaReportListItem_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CmaReportListItem_ReportFragment, unknown>;
export const CmaAssessmentReportsPage_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CmaAssessmentReportsPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CmaReportListItem_Report' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CmaReportListItem_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CmaAssessmentReportsPage_ReportFragment, unknown>;
export const CmaReportListItem_ReportPdfLinkFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CmaReportListItem_ReportPdfLink' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signedPdfUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CmaReportListItem_ReportPdfLinkFragment, unknown>;
export const DeforestationAssessmentReportsEditPage_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeforestationAssessmentReportsEditPage_Report',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sections' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeforestationAssessmentReportsEditPage_ReportFragment,
  unknown
>;
export const DeforestationReportListItem_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DeforestationReportListItem_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeforestationReportListItem_ReportFragment,
  unknown
>;
export const DeforestationAssessmentReportsPage_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeforestationAssessmentReportsPage_Report',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'DeforestationReportListItem_Report' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DeforestationReportListItem_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeforestationAssessmentReportsPage_ReportFragment,
  unknown
>;
export const DeforestationReportListItem_ReportPdfLinkFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeforestationReportListItem_ReportPdfLink',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signedPdfUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeforestationReportListItem_ReportPdfLinkFragment,
  unknown
>;
export const AllTotalGhgEmissions_AllEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'AllTotalGhgEmissions_AllEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AllEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'purchasedOffsets' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    AllTotalGhgEmissions_AllEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const AllBreakdownGhg_AllEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'AllBreakdownGhg_AllEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AllEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    AllBreakdownGhg_AllEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const AssessmentGhgEmissionsAllPage_AllEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'AssessmentGhgEmissionsAllPage_AllEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AllEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'AllTotalGhgEmissions_AllEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'AllBreakdownGhg_AllEmissionsCalculationResultFragment',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'AllTotalGhgEmissions_AllEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AllEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'purchasedOffsets' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'AllBreakdownGhg_AllEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AllEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    AssessmentGhgEmissionsAllPage_AllEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const BeefTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'BeefTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'intermediate' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'total' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'average' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intermediate' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'beef' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'carbonSequestration' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    BeefTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const BeefEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'BeefEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'urineAndDungN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'entericCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliserN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'savannahBurningN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'savannahBurningCH4' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliser' },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'purchasedMineralSupplementation',
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedFeed' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedLivestock' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    BeefEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const BeefBreakdownGhg_SheepBeefEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'BeefBreakdownGhg_SheepBeefEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    BeefBreakdownGhg_SheepBeefEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const BeefIntensity_SheepBeefEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'BeefIntensity_SheepBeefEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'beefIncludingSequestration' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'beefExcludingSequestration' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    BeefIntensity_SheepBeefEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const AssessmentGhgEmissionsBeefPage_SheepBeefEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'AssessmentGhgEmissionsBeefPage_SheepBeefEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'BeefTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'BeefEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'BeefBreakdownGhg_SheepBeefEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'BeefIntensity_SheepBeefEmissionsCalculationResultFragment',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'BeefTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'intermediate' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'total' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'average' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intermediate' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'beef' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'carbonSequestration' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'BeefEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'urineAndDungN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'entericCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliserN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'savannahBurningN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'savannahBurningCH4' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliser' },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'purchasedMineralSupplementation',
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedFeed' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedLivestock' },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'BeefBreakdownGhg_SheepBeefEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'BeefIntensity_SheepBeefEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'beefIncludingSequestration' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'beefExcludingSequestration' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    AssessmentGhgEmissionsBeefPage_SheepBeefEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const BuffaloTotalGhgEmissions_BuffaloEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'BuffaloTotalGhgEmissions_BuffaloEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BuffaloEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'intermediate' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'total' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'average' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    BuffaloTotalGhgEmissions_BuffaloEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const BuffaloEmissionsBreakdown_BuffaloEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'BuffaloEmissionsBreakdown_BuffaloEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BuffaloEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'transportCO2' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'transportCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'entericCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'animalWasteN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliserN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'urineAndDungN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'transportN2O' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliser' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedFeed' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedLivestock' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    BuffaloEmissionsBreakdown_BuffaloEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const BuffaloBreakdownGhg_BuffaloEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'BuffaloBreakdownGhg_BuffaloEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BuffaloEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    BuffaloBreakdownGhg_BuffaloEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const BuffaloIntensity_BuffaloEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'BuffaloIntensity_BuffaloEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BuffaloEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'buffaloMeatIncludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'buffaloMeatExcludingSequestration',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    BuffaloIntensity_BuffaloEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const AssessmentGhgEmissionsBuffaloPage_BuffaloEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'AssessmentGhgEmissionsBuffaloPage_BuffaloEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BuffaloEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'BuffaloTotalGhgEmissions_BuffaloEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'BuffaloEmissionsBreakdown_BuffaloEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'BuffaloBreakdownGhg_BuffaloEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'BuffaloIntensity_BuffaloEmissionsCalculationResultFragment',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'BuffaloTotalGhgEmissions_BuffaloEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BuffaloEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'intermediate' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'total' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'average' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'BuffaloEmissionsBreakdown_BuffaloEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BuffaloEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'transportCO2' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'transportCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'entericCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'animalWasteN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliserN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'urineAndDungN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'transportN2O' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliser' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedFeed' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedLivestock' },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'BuffaloBreakdownGhg_BuffaloEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BuffaloEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'BuffaloIntensity_BuffaloEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BuffaloEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'buffaloMeatIncludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'buffaloMeatExcludingSequestration',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    AssessmentGhgEmissionsBuffaloPage_BuffaloEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const CottonTotalGhgEmissions_CottonEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'CottonTotalGhgEmissions_CottonEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'CottonEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    CottonTotalGhgEmissions_CottonEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const CottonEmissionsBreakdown_CottonEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'CottonEmissionsBreakdown_CottonEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'CottonEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'cropResidueN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliserN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fieldBurningCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fieldBurningN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliser' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    CottonEmissionsBreakdown_CottonEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const CottonBreakdownGhg_CottonEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'CottonBreakdownGhg_CottonEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'CottonEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    CottonBreakdownGhg_CottonEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const CottonIntensity_CottonEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'CottonIntensity_CottonEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'CottonEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'balesExcludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'balesIncludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'tonnesCropExcludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'tonnesCropIncludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'lintIncludingSequestration' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'lintExcludingSequestration' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'seedIncludingSequestration' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'seedExcludingSequestration' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'lintEconomicAllocation' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'seedEconomicAllocation' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    CottonIntensity_CottonEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const AssessmentGhgEmissionsCottonPage_CottonEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'AssessmentGhgEmissionsCottonPage_CottonEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'CottonEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'CottonTotalGhgEmissions_CottonEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'CottonEmissionsBreakdown_CottonEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'CottonBreakdownGhg_CottonEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'CottonIntensity_CottonEmissionsCalculationResultFragment',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'CottonTotalGhgEmissions_CottonEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'CottonEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'CottonEmissionsBreakdown_CottonEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'CottonEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'cropResidueN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliserN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fieldBurningCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fieldBurningN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliser' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'CottonBreakdownGhg_CottonEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'CottonEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'CottonIntensity_CottonEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'CottonEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'balesExcludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'balesIncludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'tonnesCropExcludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'tonnesCropIncludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'lintIncludingSequestration' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'lintExcludingSequestration' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'seedIncludingSequestration' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'seedExcludingSequestration' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'lintEconomicAllocation' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'seedEconomicAllocation' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    AssessmentGhgEmissionsCottonPage_CottonEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const DairyTotalGhgEmissions_DairyEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'DairyTotalGhgEmissions_DairyEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DairyEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'intermediate' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'total' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'average' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    DairyTotalGhgEmissions_DairyEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const DairyEmissionsBreakdown_DairyEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'DairyEmissionsBreakdown_DairyEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DairyEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'transportCO2' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'transportCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'entericCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'animalWasteN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliserN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'urineAndDungN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'transportN2O' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliser' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedFeed' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    DairyEmissionsBreakdown_DairyEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const DairyBreakdownGhg_DairyEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'DairyBreakdownGhg_DairyEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DairyEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    DairyBreakdownGhg_DairyEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const DairyIntensity_DairyEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'DairyIntensity_DairyEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DairyEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    DairyIntensity_DairyEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const AssessmentGhgEmissionsDairyPage_DairyEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'AssessmentGhgEmissionsDairyPage_DairyEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DairyEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'DairyTotalGhgEmissions_DairyEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'DairyEmissionsBreakdown_DairyEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'DairyBreakdownGhg_DairyEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'DairyIntensity_DairyEmissionsCalculationResultFragment',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'DairyTotalGhgEmissions_DairyEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DairyEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'intermediate' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'total' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'average' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'DairyEmissionsBreakdown_DairyEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DairyEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'transportCO2' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'transportCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'entericCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'animalWasteN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliserN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'urineAndDungN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'transportN2O' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliser' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedFeed' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'DairyBreakdownGhg_DairyEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DairyEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'DairyIntensity_DairyEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DairyEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    AssessmentGhgEmissionsDairyPage_DairyEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const DeerTotalGhgEmissions_DeerEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'DeerTotalGhgEmissions_DeerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DeerEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'intermediate' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'total' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'average' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    DeerTotalGhgEmissions_DeerEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const DeerEmissionsBreakdown_DeerEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'DeerEmissionsBreakdown_DeerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DeerEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'transportCO2' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'transportCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'entericCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'animalWasteN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliserN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'urineAndDungN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'transportN2O' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliser' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedFeed' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedLivestock' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    DeerEmissionsBreakdown_DeerEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const DeerBreakdownGhg_DeerEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'DeerBreakdownGhg_DeerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DeerEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    DeerBreakdownGhg_DeerEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const DeerIntensity_DeerEmissionsCalculationResultFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeerIntensity_DeerEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intensities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'deerMeatIncludingSequestration',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'deerMeatExcludingSequestration',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeerIntensity_DeerEmissionsCalculationResultFragmentFragment,
  unknown
>;
export const AssessmentGhgEmissionsDeerPage_DeerEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'AssessmentGhgEmissionsDeerPage_DeerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DeerEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'DeerTotalGhgEmissions_DeerEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'DeerEmissionsBreakdown_DeerEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'DeerBreakdownGhg_DeerEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'DeerIntensity_DeerEmissionsCalculationResultFragment',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'DeerTotalGhgEmissions_DeerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DeerEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'intermediate' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'total' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'average' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'DeerEmissionsBreakdown_DeerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DeerEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'transportCO2' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'transportCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'entericCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'animalWasteN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliserN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'urineAndDungN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'transportN2O' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliser' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedFeed' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedLivestock' },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'DeerBreakdownGhg_DeerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DeerEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'DeerIntensity_DeerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DeerEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'deerMeatIncludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'deerMeatExcludingSequestration',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    AssessmentGhgEmissionsDeerPage_DeerEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const FeedlotTotalGhgEmissions_FeedlotEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'FeedlotTotalGhgEmissions_FeedlotEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'FeedlotEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intermediate' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'carbonSequestration' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    FeedlotTotalGhgEmissions_FeedlotEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const FeedlotEmissionsBreakdown_FeedlotEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'FeedlotEmissionsBreakdown_FeedlotEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'FeedlotEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'entericCH4' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureAppliedToSoilN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureDirectN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureIndirectN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'transportCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'transportCO2' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'transportN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliser' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'feed' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchaseLivestock' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    FeedlotEmissionsBreakdown_FeedlotEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const FeedlotBreakdownGhg_FeedlotEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'FeedlotBreakdownGhg_FeedlotEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'FeedlotEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    FeedlotBreakdownGhg_FeedlotEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const FeedlotIntensity_FeedlotEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'FeedlotIntensity_FeedlotEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'FeedlotEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'beefIncludingSequestration' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'beefExcludingSequestration' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    FeedlotIntensity_FeedlotEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const AssessmentGhgEmissionsFeedlotPage_FeedlotEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'AssessmentGhgEmissionsFeedlotPage_FeedlotEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'FeedlotEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'FeedlotTotalGhgEmissions_FeedlotEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'FeedlotEmissionsBreakdown_FeedlotEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'FeedlotBreakdownGhg_FeedlotEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'FeedlotIntensity_FeedlotEmissionsCalculationResultFragment',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'FeedlotTotalGhgEmissions_FeedlotEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'FeedlotEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intermediate' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'carbonSequestration' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'FeedlotEmissionsBreakdown_FeedlotEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'FeedlotEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'entericCH4' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureAppliedToSoilN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureDirectN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureIndirectN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'transportCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'transportCO2' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'transportN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliser' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'feed' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchaseLivestock' },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'FeedlotBreakdownGhg_FeedlotEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'FeedlotEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'FeedlotIntensity_FeedlotEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'FeedlotEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'beefIncludingSequestration' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'beefExcludingSequestration' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    AssessmentGhgEmissionsFeedlotPage_FeedlotEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const GoatTotalGhgEmissions_GoatEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'GoatTotalGhgEmissions_GoatEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'GoatEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'intermediate' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'total' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'average' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    GoatTotalGhgEmissions_GoatEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const GoatEmissionsBreakdown_GoatEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'GoatEmissionsBreakdown_GoatEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'GoatEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliserN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'entericCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'urineAndDungN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliser' },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'purchasedMineralSupplementation',
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedFeed' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedLivestock' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    GoatEmissionsBreakdown_GoatEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const GoatBreakdownGhg_GoatEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'GoatBreakdownGhg_GoatEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'GoatEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    GoatBreakdownGhg_GoatEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const GoatIntensity_GoatEmissionsCalculationResultFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatIntensity_GoatEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intensities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'goatMeatBreedingIncludingSequestration',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'goatMeatBreedingExcludingSequestration',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'woolIncludingSequestration' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'woolExcludingSequestration' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GoatIntensity_GoatEmissionsCalculationResultFragmentFragment,
  unknown
>;
export const AssessmentGhgEmissionsGoatPage_GoatEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'AssessmentGhgEmissionsGoatPage_GoatEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'GoatEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'GoatTotalGhgEmissions_GoatEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'GoatEmissionsBreakdown_GoatEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'GoatBreakdownGhg_GoatEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'GoatIntensity_GoatEmissionsCalculationResultFragment',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'GoatTotalGhgEmissions_GoatEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'GoatEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'intermediate' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'total' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'average' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'GoatEmissionsBreakdown_GoatEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'GoatEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliserN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'entericCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'urineAndDungN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliser' },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'purchasedMineralSupplementation',
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedFeed' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedLivestock' },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'GoatBreakdownGhg_GoatEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'GoatEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'GoatIntensity_GoatEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'GoatEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'goatMeatBreedingIncludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'goatMeatBreedingExcludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'woolIncludingSequestration' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'woolExcludingSequestration' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    AssessmentGhgEmissionsGoatPage_GoatEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const GrainsTotalGhgEmissions_GrainsEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'GrainsTotalGhgEmissions_GrainsEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'GrainsEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    GrainsTotalGhgEmissions_GrainsEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const GrainsEmissionsBreakdown_GrainsEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'GrainsEmissionsBreakdown_GrainsEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'GrainsEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'cropResidueN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliserN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fieldBurningCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fieldBurningN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliser' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    GrainsEmissionsBreakdown_GrainsEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const GrainsBreakdownGhg_GrainsEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'GrainsBreakdownGhg_GrainsEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'GrainsEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    GrainsBreakdownGhg_GrainsEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const GrainsIntensity_GrainsEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'GrainsIntensity_GrainsEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'GrainsEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensitiesWithSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'grainsIncludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'grainsExcludingSequestration',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    GrainsIntensity_GrainsEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const AssessmentGhgEmissionsGrainsPage_GrainsEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'AssessmentGhgEmissionsGrainsPage_GrainsEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'GrainsEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'GrainsTotalGhgEmissions_GrainsEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'GrainsEmissionsBreakdown_GrainsEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'GrainsBreakdownGhg_GrainsEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'GrainsIntensity_GrainsEmissionsCalculationResultFragment',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'GrainsTotalGhgEmissions_GrainsEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'GrainsEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'GrainsEmissionsBreakdown_GrainsEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'GrainsEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'cropResidueN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliserN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fieldBurningCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fieldBurningN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliser' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'GrainsBreakdownGhg_GrainsEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'GrainsEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'GrainsIntensity_GrainsEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'GrainsEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensitiesWithSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'grainsIncludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'grainsExcludingSequestration',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    AssessmentGhgEmissionsGrainsPage_GrainsEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const HorticultureTotalGhgEmissions_HorticultureEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'HorticultureTotalGhgEmissions_HorticultureEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: {
            kind: 'Name',
            value: 'HorticultureEmissionsCalculationResult',
          },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'intermediate' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'total' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'average' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    HorticultureTotalGhgEmissions_HorticultureEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const HorticultureEmissionsBreakdown_HorticultureEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'HorticultureEmissionsBreakdown_HorticultureEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: {
            kind: 'Name',
            value: 'HorticultureEmissionsCalculationResult',
          },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'cropResidueN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliserN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fieldBurningCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fieldBurningN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliser' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    HorticultureEmissionsBreakdown_HorticultureEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const HorticultureBreakdownGhg_HorticultureEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'HorticultureBreakdownGhg_HorticultureEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: {
            kind: 'Name',
            value: 'HorticultureEmissionsCalculationResult',
          },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    HorticultureBreakdownGhg_HorticultureEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const HorticultureIntensity_HorticultureEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'HorticultureIntensity_HorticultureEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: {
            kind: 'Name',
            value: 'HorticultureEmissionsCalculationResult',
          },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'tonnesCropIncludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'tonnesCropExcludingSequestration',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    HorticultureIntensity_HorticultureEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const AssessmentGhgEmissionsHorticulturePage_HorticultureEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'AssessmentGhgEmissionsHorticulturePage_HorticultureEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: {
            kind: 'Name',
            value: 'HorticultureEmissionsCalculationResult',
          },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'HorticultureTotalGhgEmissions_HorticultureEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'HorticultureEmissionsBreakdown_HorticultureEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'HorticultureBreakdownGhg_HorticultureEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'HorticultureIntensity_HorticultureEmissionsCalculationResultFragment',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'HorticultureTotalGhgEmissions_HorticultureEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: {
            kind: 'Name',
            value: 'HorticultureEmissionsCalculationResult',
          },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'intermediate' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'total' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'average' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'HorticultureEmissionsBreakdown_HorticultureEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: {
            kind: 'Name',
            value: 'HorticultureEmissionsCalculationResult',
          },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'cropResidueN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliserN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fieldBurningCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fieldBurningN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliser' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'HorticultureBreakdownGhg_HorticultureEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: {
            kind: 'Name',
            value: 'HorticultureEmissionsCalculationResult',
          },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'HorticultureIntensity_HorticultureEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: {
            kind: 'Name',
            value: 'HorticultureEmissionsCalculationResult',
          },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'tonnesCropIncludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'tonnesCropExcludingSequestration',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    AssessmentGhgEmissionsHorticulturePage_HorticultureEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'EmissionsCalculationInputs' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'requests' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'payload' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'primaryActivities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'activityType' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'cropping' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'croppingType' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragment,
    unknown
  >;
export const EmissionsAssessmentLayout_PropertyFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmissionsAssessmentLayout_PropertyFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  EmissionsAssessmentLayout_PropertyFragmentFragment,
  unknown
>;
export const PorkTotalGhgEmissions_PorkEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'PorkTotalGhgEmissions_PorkEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PorkEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'intermediate' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'total' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'average' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PorkTotalGhgEmissions_PorkEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const PorkEmissionsBreakdown_PorkEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'PorkEmissionsBreakdown_PorkEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PorkEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliserN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'entericCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementDirectN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'atmosphericDepositionIndirectN2O',
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffSoilN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffMMSN2O' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliser' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedFeed' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'bedding' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedLivestock' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PorkEmissionsBreakdown_PorkEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const PorkBreakdownGhg_PorkEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'PorkBreakdownGhg_PorkEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PorkEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PorkBreakdownGhg_PorkEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const PorkIntensity_PorkEmissionsCalculationResultFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PorkIntensity_PorkEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intensities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'porkMeatIncludingSequestration',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'porkMeatExcludingSequestration',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PorkIntensity_PorkEmissionsCalculationResultFragmentFragment,
  unknown
>;
export const AssessmentGhgEmissionsPorkPage_PorkEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'AssessmentGhgEmissionsPorkPage_PorkEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PorkEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'PorkTotalGhgEmissions_PorkEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'PorkEmissionsBreakdown_PorkEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'PorkBreakdownGhg_PorkEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'PorkIntensity_PorkEmissionsCalculationResultFragment',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'PorkTotalGhgEmissions_PorkEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PorkEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'intermediate' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'total' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'average' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'PorkEmissionsBreakdown_PorkEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PorkEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliserN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'entericCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementDirectN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'atmosphericDepositionIndirectN2O',
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffSoilN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffMMSN2O' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliser' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedFeed' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'bedding' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedLivestock' },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'PorkBreakdownGhg_PorkEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PorkEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'PorkIntensity_PorkEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PorkEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'porkMeatIncludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'porkMeatExcludingSequestration',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    AssessmentGhgEmissionsPorkPage_PorkEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const PoultryBroilerTotalGhgEmissions_PoultryBroilerEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'PoultryBroilerTotalGhgEmissions_PoultryBroilerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'intermediate' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'total' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'average' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PoultryBroilerTotalGhgEmissions_PoultryBroilerEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const PoultryBroilerEmissionsBreakdown_PoultryBroilerEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'PoultryBroilerEmissionsBreakdown_PoultryBroilerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementN2O' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedFeed' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedHay' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedLivestock' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PoultryBroilerEmissionsBreakdown_PoultryBroilerEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const PoultryBroilerBreakdownGhg_PoultryBroilerEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'PoultryBroilerBreakdownGhg_PoultryBroilerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PoultryBroilerBreakdownGhg_PoultryBroilerEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const PoultryBroilerIntensity_PoultryBroilerEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'PoultryBroilerIntensity_PoultryBroilerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'poultryMeatIncludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'poultryMeatExcludingSequestration',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PoultryBroilerIntensity_PoultryBroilerEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const AssessmentGhgEmissionsPoultryBroilerPage_PoultryBroilerEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'AssessmentGhgEmissionsPoultryBroilerPage_PoultryBroilerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'PoultryBroilerTotalGhgEmissions_PoultryBroilerEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'PoultryBroilerEmissionsBreakdown_PoultryBroilerEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'PoultryBroilerBreakdownGhg_PoultryBroilerEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'PoultryBroilerIntensity_PoultryBroilerEmissionsCalculationResultFragment',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'PoultryBroilerTotalGhgEmissions_PoultryBroilerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'intermediate' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'total' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'average' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'PoultryBroilerEmissionsBreakdown_PoultryBroilerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementN2O' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedFeed' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedHay' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedLivestock' },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'PoultryBroilerBreakdownGhg_PoultryBroilerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'PoultryBroilerIntensity_PoultryBroilerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'poultryMeatIncludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'poultryMeatExcludingSequestration',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    AssessmentGhgEmissionsPoultryBroilerPage_PoultryBroilerEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const PoultryLayerTotalGhgEmissions_PoultryLayerEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'PoultryLayerTotalGhgEmissions_PoultryLayerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'intermediate' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'total' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'average' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PoultryLayerTotalGhgEmissions_PoultryLayerEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const PoultryLayerEmissionsBreakdown_PoultryLayerEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'PoultryLayerEmissionsBreakdown_PoultryLayerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementN2O' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedFeed' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedHay' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedLivestock' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PoultryLayerEmissionsBreakdown_PoultryLayerEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const PoultryLayerBreakdownGhg_PoultryLayerEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'PoultryLayerBreakdownGhg_PoultryLayerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PoultryLayerBreakdownGhg_PoultryLayerEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const PoultryLayerIntensity_PoultryLayerEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'PoultryLayerIntensity_PoultryLayerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'poultryEggsIncludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'poultryEggsExcludingSequestration',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PoultryLayerIntensity_PoultryLayerEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const AssessmentGhgEmissionsPoultryLayerPage_PoultryLayerEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'AssessmentGhgEmissionsPoultryLayerPage_PoultryLayerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'PoultryLayerTotalGhgEmissions_PoultryLayerEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'PoultryLayerEmissionsBreakdown_PoultryLayerEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'PoultryLayerBreakdownGhg_PoultryLayerEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'PoultryLayerIntensity_PoultryLayerEmissionsCalculationResultFragment',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'PoultryLayerTotalGhgEmissions_PoultryLayerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'intermediate' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'total' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'average' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'PoultryLayerEmissionsBreakdown_PoultryLayerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementN2O' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedFeed' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedHay' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedLivestock' },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'PoultryLayerBreakdownGhg_PoultryLayerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'PoultryLayerIntensity_PoultryLayerEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'poultryEggsIncludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'poultryEggsExcludingSequestration',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    AssessmentGhgEmissionsPoultryLayerPage_PoultryLayerEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const SheepTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'SheepTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'intermediate' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'total' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'average' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intermediate' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'sheep' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'carbonSequestration' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    SheepTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const SheepEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'SheepEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'urineAndDungN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'entericCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliserN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'savannahBurningN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'savannahBurningCH4' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliser' },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'purchasedMineralSupplementation',
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedFeed' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedLivestock' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    SheepEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const SheepBreakdownGhg_SheepBeefEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'SheepBreakdownGhg_SheepBeefEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    SheepBreakdownGhg_SheepBeefEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const SheepIntensity_SheepBeefEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'SheepIntensity_SheepBeefEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'sheepMeatBreedingIncludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'sheepMeatBreedingExcludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'woolIncludingSequestration' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'woolExcludingSequestration' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    SheepIntensity_SheepBeefEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const AssessmentGhgEmissionsSheepPage_SheepBeefEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'AssessmentGhgEmissionsSheepPage_SheepBeefEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'SheepTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'SheepEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'SheepBreakdownGhg_SheepBeefEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'SheepIntensity_SheepBeefEmissionsCalculationResultFragment',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'SheepTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'intermediate' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'total' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'average' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intermediate' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'sheep' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'carbonSequestration' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'SheepEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'urineAndDungN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'manureManagementCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'entericCH4' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliserN2O' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'savannahBurningN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'savannahBurningCH4' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliser' },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'purchasedMineralSupplementation',
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedFeed' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'purchasedLivestock' },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'SheepBreakdownGhg_SheepBeefEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'SheepIntensity_SheepBeefEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'sheepMeatBreedingIncludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'sheepMeatBreedingExcludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'woolIncludingSequestration' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'woolExcludingSequestration' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    AssessmentGhgEmissionsSheepPage_SheepBeefEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const SugarTotalGhgEmissions_SugarEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'SugarTotalGhgEmissions_SugarEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SugarEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    SugarTotalGhgEmissions_SugarEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const SugarEmissionsBreakdown_SugarEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'SugarEmissionsBreakdown_SugarEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SugarEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliserN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'cropResidueN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fieldBurningN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fieldBurningCH4' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliser' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    SugarEmissionsBreakdown_SugarEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const SugarBreakdownGhg_SugarEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'SugarBreakdownGhg_SugarEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SugarEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    SugarBreakdownGhg_SugarEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const SugarIntensity_SugarEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'SugarIntensity_SugarEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SugarEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'sugarIncludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'sugarExcludingSequestration',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    SugarIntensity_SugarEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const AssessmentGhgEmissionsSugarPage_SugarEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'AssessmentGhgEmissionsSugarPage_SugarEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SugarEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'SugarTotalGhgEmissions_SugarEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'SugarEmissionsBreakdown_SugarEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'SugarBreakdownGhg_SugarEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'SugarIntensity_SugarEmissionsCalculationResultFragment',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'SugarTotalGhgEmissions_SugarEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SugarEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'carbonSequestration' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'SugarEmissionsBreakdown_SugarEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SugarEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliserN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'cropResidueN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fieldBurningN2O' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fieldBurningCH4' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'fertiliser' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'SugarBreakdownGhg_SugarEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SugarEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'SugarIntensity_SugarEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SugarEmissionsCalculationResult' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'sugarIncludingSequestration',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'sugarExcludingSequestration',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    AssessmentGhgEmissionsSugarPage_SugarEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const WildSeaFisheryTotalGhgEmissions_WildSeaFisheryEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'WildSeaFisheryTotalGhgEmissions_WildSeaFisheryEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: {
            kind: 'Name',
            value: 'WildSeaFisheryEmissionsCalculationResult',
          },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'purchasedOffsets' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    WildSeaFisheryTotalGhgEmissions_WildSeaFisheryEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const WildSeaFisheryEmissionsBreakdown_WildSeaFisheryEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'WildSeaFisheryEmissionsBreakdown_WildSeaFisheryEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: {
            kind: 'Name',
            value: 'WildSeaFisheryEmissionsCalculationResult',
          },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'hfcsRefrigerantLeakage' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'bait' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    WildSeaFisheryEmissionsBreakdown_WildSeaFisheryEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const WildSeaFisheryBreakdownGhg_WildSeaFisheryEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'WildSeaFisheryBreakdownGhg_WildSeaFisheryEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: {
            kind: 'Name',
            value: 'WildSeaFisheryEmissionsCalculationResult',
          },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalHFCs' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    WildSeaFisheryBreakdownGhg_WildSeaFisheryEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const WildSeaFisheryIntensity_WildSeaFisheryEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'WildSeaFisheryIntensity_WildSeaFisheryEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: {
            kind: 'Name',
            value: 'WildSeaFisheryEmissionsCalculationResult',
          },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'intensityIncludingCarbonOffset',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'intensityExcludingCarbonOffset',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    WildSeaFisheryIntensity_WildSeaFisheryEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const AssessmentGhgEmissionsWildSeaFisheryPage_WildSeaFisheryEmissionsCalculationResultFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'AssessmentGhgEmissionsWildSeaFisheryPage_WildSeaFisheryEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: {
            kind: 'Name',
            value: 'WildSeaFisheryEmissionsCalculationResult',
          },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'WildSeaFisheryTotalGhgEmissions_WildSeaFisheryEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'WildSeaFisheryEmissionsBreakdown_WildSeaFisheryEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'WildSeaFisheryBreakdownGhg_WildSeaFisheryEmissionsCalculationResultFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'WildSeaFisheryIntensity_WildSeaFisheryEmissionsCalculationResultFragment',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'WildSeaFisheryTotalGhgEmissions_WildSeaFisheryEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: {
            kind: 'Name',
            value: 'WildSeaFisheryEmissionsCalculationResult',
          },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'net' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'purchasedOffsets' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'WildSeaFisheryEmissionsBreakdown_WildSeaFisheryEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: {
            kind: 'Name',
            value: 'WildSeaFisheryEmissionsCalculationResult',
          },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'hfcsRefrigerantLeakage' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope2' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope3' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'electricity' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'bait' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'WildSeaFisheryBreakdownGhg_WildSeaFisheryEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: {
            kind: 'Name',
            value: 'WildSeaFisheryEmissionsCalculationResult',
          },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'scope1' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'totalHFCs' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'WildSeaFisheryIntensity_WildSeaFisheryEmissionsCalculationResultFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: {
            kind: 'Name',
            value: 'WildSeaFisheryEmissionsCalculationResult',
          },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'intensities' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'intensityIncludingCarbonOffset',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'intensityExcludingCarbonOffset',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    AssessmentGhgEmissionsWildSeaFisheryPage_WildSeaFisheryEmissionsCalculationResultFragmentFragment,
    unknown
  >;
export const KyeAssessmentReportsEditPage_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KyeAssessmentReportsEditPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sections' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  KyeAssessmentReportsEditPage_ReportFragment,
  unknown
>;
export const KyeReportListItem_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KyeReportListItem_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<KyeReportListItem_ReportFragment, unknown>;
export const KyeAssessmentReportsPage_ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KyeAssessmentReportsPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'KyeReportListItem_Report' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KyeReportListItem_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<KyeAssessmentReportsPage_ReportFragment, unknown>;
export const KyeReportListItem_ReportPdfLinkFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KyeReportListItem_ReportPdfLink' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signedPdfUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<KyeReportListItem_ReportPdfLinkFragment, unknown>;
export const PropertiesBusinessActivitiesAllocationIndexFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertiesBusinessActivitiesAllocationIndexFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Activity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourceActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'targetActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CroppingActivity' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'croppingType' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PropertiesBusinessActivitiesAllocationIndexFragmentFragment,
  unknown
>;
export const BusinessActivitiesAllocationConsumptionEditFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BusinessActivitiesAllocationConsumptionEditFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Consumption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumableType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumableSubType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumptionAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourceConsumption' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'targetActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BusinessActivitiesAllocationConsumptionEditFragmentFragment,
  unknown
>;
export const AllocationIndexActivitiesFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllocationIndexActivitiesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Activity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourceActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'targetActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CroppingActivity' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'croppingType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'varieties' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'yieldInKg' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllocationIndexActivitiesFragmentFragment,
  unknown
>;
export const BusinessActivitiesAllocationIndexConsumptionsFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'BusinessActivitiesAllocationIndexConsumptionsFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Consumption' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'consumableType' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'consumableSubType' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'consumptionAllocations' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'percentage' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'sourceConsumption' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      ],
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'targetActivity' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    BusinessActivitiesAllocationIndexConsumptionsFragmentFragment,
    unknown
  >;
export const AllocateToActivitiesComponentActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AllocateToActivitiesComponentActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Activity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CroppingActivity' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'croppingType' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllocateToActivitiesComponentActivityFragmentFragment,
  unknown
>;
export const BusinessActivitiesConsumptionIndexFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BusinessActivitiesConsumptionIndexFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Consumption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumableType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumableSubType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumptionAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'targetActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourceConsumption' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BusinessActivitiesConsumptionIndexFragmentFragment,
  unknown
>;
export const BusinessActivitiesConsumptionIndexConsumptionsFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'BusinessActivitiesConsumptionIndexConsumptionsFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Consumption' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
            { kind: 'Field', name: { kind: 'Name', value: 'consumableType' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'consumableSubType' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    BusinessActivitiesConsumptionIndexConsumptionsFragmentFragment,
    unknown
  >;
export const ActivitiesActivityEditFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivitiesActivityEditFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Activity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CroppingActivity' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'croppingType' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActivitiesActivityEditFragmentFragment, unknown>;
export const TransactionForm_BeefMonthLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_BeefMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beefClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TransactionForm_BeefMonthLivestockActivityFragmentFragment,
  unknown
>;
export const UpdateTransaction_BeefMonthLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UpdateTransaction_BeefMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'TransactionForm_BeefMonthLivestockActivityFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_BeefMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beefClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTransaction_BeefMonthLivestockActivityFragmentFragment,
  unknown
>;
export const TransactionForm_LivestockTransactionEventFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_LivestockTransactionEventFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LivestockTransactionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionRegion' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headPurchasedMerino' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TransactionForm_LivestockTransactionEventFragmentFragment,
  unknown
>;
export const UpdateTransaction_LivestockTransactionEventFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UpdateTransaction_LivestockTransactionEventFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LivestockTransactionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'TransactionForm_LivestockTransactionEventFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_LivestockTransactionEventFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LivestockTransactionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionRegion' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headPurchasedMerino' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTransaction_LivestockTransactionEventFragmentFragment,
  unknown
>;
export const CreateTransaction_BeefMonthLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'CreateTransaction_BeefMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'TransactionForm_BeefMonthLivestockActivityFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_BeefMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beefClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTransaction_BeefMonthLivestockActivityFragmentFragment,
  unknown
>;
export const BeefMonthlyBreakdown_BeefLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefMonthlyBreakdown_BeefLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bullsGt1Breeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsGt2Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifersLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifers1to2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifersGt2Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'steersLt1Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'steers1to2Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'steersGt2Breeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bullsGt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsGt2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1Trading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifers1to2Trading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersLt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steers1to2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersGt2Trading' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BeefMonthlyBreakdown_BeefLivestockActivityFragmentFragment,
  unknown
>;
export const BeefMonthlyForm_BeefMonthLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefMonthlyForm_BeefMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previousMonthLiveweightKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageCowsCalving' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BeefMonthlyForm_BeefMonthLivestockActivityFragment,
  unknown
>;
export const BeefMonthlyBreakdown_BeefMonthLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefMonthlyBreakdown_BeefMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'BeefMonthlyForm_BeefMonthLivestockActivity',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefMonthlyForm_BeefMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previousMonthLiveweightKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageCowsCalving' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BeefMonthlyBreakdown_BeefMonthLivestockActivityFragment,
  unknown
>;
export const BeefActivityLivestockBreedingBeefLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefActivityLivestockBreedingBeefLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bullsGt1Breeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsGt2Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifersLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifers1to2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifersGt2Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'steersLt1Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'steers1to2Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'steersGt2Breeding' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BeefActivityLivestockBreedingBeefLivestockActivityFragment,
  unknown
>;
export const BeefActivityLivestockTradingBeefLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefActivityLivestockTradingBeefLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bullsGt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsGt2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1Trading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifers1to2Trading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersLt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steers1to2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersGt2Trading' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BeefActivityLivestockTradingBeefLivestockActivityFragment,
  unknown
>;
export const BeefActivityLivestockBeefLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefActivityLivestockBeefLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'BeefActivityLivestockBreedingBeefLivestockActivity',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'BeefActivityLivestockTradingBeefLivestockActivity',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefActivityLivestockBreedingBeefLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bullsGt1Breeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsGt2Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifersLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifers1to2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifersGt2Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'steersLt1Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'steers1to2Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'steersGt2Breeding' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefActivityLivestockTradingBeefLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bullsGt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsGt2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1Trading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifers1to2Trading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersLt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steers1to2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersGt2Trading' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BeefActivityLivestockBeefLivestockActivityFragment,
  unknown
>;
export const UseBeefMonthlyFeedBreakdown_ActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseBeefMonthlyFeedBreakdown_ActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bullsGt1Breeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsGt2Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifersLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifers1to2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifersGt2Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'steersLt1Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'steers1to2Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'steersGt2Breeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bullsGt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsGt2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1Trading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifers1to2Trading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersLt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steers1to2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersGt2Trading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseBeefMonthlyFeedBreakdown_ActivityFragmentFragment,
  unknown
>;
export const UseBeefMonthlyFeedBreakdown_BeefMonthLivestockActivityFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseBeefMonthlyFeedBreakdown_BeefMonthLivestockActivity',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BeefMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'month' } },
            { kind: 'Field', name: { kind: 'Name', value: 'year' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'crudeProteinPercentage' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'dryMatterDigestibilityPercentage' },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseBeefMonthlyFeedBreakdown_BeefMonthLivestockActivityFragment,
    unknown
  >;
export const FeedDrySeasonMix_FeedFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedDrySeasonMix_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'drySeasonMixKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'drySeasonMixUreaPercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FeedDrySeasonMix_FeedFragmentFragment, unknown>;
export const BeefFeedDrySeasonMix_BeefLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefFeedDrySeasonMix_BeefLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedDrySeasonMix_FeedFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedDrySeasonMix_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'drySeasonMixKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'drySeasonMixUreaPercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BeefFeedDrySeasonMix_BeefLivestockActivityFragmentFragment,
  unknown
>;
export const BeefActivityLivestockFeed_BeefLivestockActivityFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'BeefActivityLivestockFeed_BeefLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BeefLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'bullsGt1Breeding' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'cowsGt2Breeding' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'heifersLt1Breeding' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'heifers1to2Breeding' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'heifersGt2Breeding' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'steersLt1Breeding' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'steers1to2Breeding' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'steersGt2Breeding' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'bullsGt1Trading' } },
            { kind: 'Field', name: { kind: 'Name', value: 'cowsGt2Trading' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'heifersLt1Trading' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'heifers1to2Trading' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'heifersGt2Trading' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'steersLt1Trading' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'steers1to2Trading' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'steersGt2Trading' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'feed' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'grazingCrops' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'mineralBlock' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'weanerBlock' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'drySeasonMix' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    BeefActivityLivestockFeed_BeefLivestockActivityFragmentFragment,
    unknown
  >;
export const FeedMineralBlock_FeedFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedMineralBlock_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mineralBlockKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mineralBlockUreaPercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FeedMineralBlock_FeedFragmentFragment, unknown>;
export const BeefFeedMineralBlock_BeefLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefFeedMineralBlock_BeefLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedMineralBlock_FeedFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedMineralBlock_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mineralBlockKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mineralBlockUreaPercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BeefFeedMineralBlock_BeefLivestockActivityFragmentFragment,
  unknown
>;
export const FeedWeanerBlock_FeedFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedWeanerBlock_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weanerBlockKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'weanerBlockUreaPercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FeedWeanerBlock_FeedFragmentFragment, unknown>;
export const BeefFeedWeanerBlock_BeefLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefFeedWeanerBlock_BeefLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FeedWeanerBlock_FeedFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedWeanerBlock_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weanerBlockKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'weanerBlockUreaPercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BeefFeedWeanerBlock_BeefLivestockActivityFragmentFragment,
  unknown
>;
export const PropertiesBusinessActivitiesActivitiesHeaderBeefActivityFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'PropertiesBusinessActivitiesActivitiesHeaderBeefActivity',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BeefLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'start' } },
            { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PropertiesBusinessActivitiesActivitiesHeaderBeefActivityFragment,
    unknown
  >;
export const BeefLivestockActivity_MonthActivitiesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BeefLivestockActivity_MonthActivities' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightKg' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beefClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BeefLivestockActivity_MonthActivitiesFragment,
  unknown
>;
export const TransactionForm_BuffaloMonthLivestockActivityFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'TransactionForm_BuffaloMonthLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BuffaloMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'year' } },
            { kind: 'Field', name: { kind: 'Name', value: 'month' } },
            { kind: 'Field', name: { kind: 'Name', value: 'buffaloClass' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    TransactionForm_BuffaloMonthLivestockActivityFragmentFragment,
    unknown
  >;
export const UpdateTransaction_BuffaloMonthLivestockActivityFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UpdateTransaction_BuffaloMonthLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BuffaloMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'TransactionForm_BuffaloMonthLivestockActivityFragment',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'TransactionForm_BuffaloMonthLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BuffaloMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'year' } },
            { kind: 'Field', name: { kind: 'Name', value: 'month' } },
            { kind: 'Field', name: { kind: 'Name', value: 'buffaloClass' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UpdateTransaction_BuffaloMonthLivestockActivityFragmentFragment,
    unknown
  >;
export const CreateTransaction_BuffaloMonthLivestockActivityFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'CreateTransaction_BuffaloMonthLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BuffaloMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'TransactionForm_BuffaloMonthLivestockActivityFragment',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'TransactionForm_BuffaloMonthLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BuffaloMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'year' } },
            { kind: 'Field', name: { kind: 'Name', value: 'month' } },
            { kind: 'Field', name: { kind: 'Name', value: 'buffaloClass' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    CreateTransaction_BuffaloMonthLivestockActivityFragmentFragment,
    unknown
  >;
export const BuffaloMonthlyBreakdown_BuffaloLivestockActivityFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'BuffaloMonthlyBreakdown_BuffaloLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BuffaloLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'bullsBreeding' } },
            { kind: 'Field', name: { kind: 'Name', value: 'cowsBreeding' } },
            { kind: 'Field', name: { kind: 'Name', value: 'steersBreeding' } },
            { kind: 'Field', name: { kind: 'Name', value: 'calfsBreeding' } },
            { kind: 'Field', name: { kind: 'Name', value: 'bullsTrading' } },
            { kind: 'Field', name: { kind: 'Name', value: 'cowsTrading' } },
            { kind: 'Field', name: { kind: 'Name', value: 'steersTrading' } },
            { kind: 'Field', name: { kind: 'Name', value: 'calfsTrading' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    BuffaloMonthlyBreakdown_BuffaloLivestockActivityFragmentFragment,
    unknown
  >;
export const BuffaloMonthlyForm_BuffaloMonthLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BuffaloMonthlyForm_BuffaloMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BuffaloMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageCowsCalving' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'seasonalCalvingRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BuffaloMonthlyForm_BuffaloMonthLivestockActivityFragment,
  unknown
>;
export const BuffaloMonthlyBreakdown_BuffaloMonthLivestockActivityFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'BuffaloMonthlyBreakdown_BuffaloMonthLivestockActivity',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BuffaloMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'BuffaloMonthlyForm_BuffaloMonthLivestockActivity',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'BuffaloMonthlyForm_BuffaloMonthLivestockActivity',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BuffaloMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'month' } },
            { kind: 'Field', name: { kind: 'Name', value: 'year' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'nonSalePopulationChange' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'percentageCowsCalving' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'seasonalCalvingRate' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
            { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'livestockTransactionEvents' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'transactionType' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'livestockCount' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    BuffaloMonthlyBreakdown_BuffaloMonthLivestockActivityFragment,
    unknown
  >;
export const BuffaloActivityLivestockBreedingBuffaloLivestockActivityFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'BuffaloActivityLivestockBreedingBuffaloLivestockActivity',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BuffaloLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'bullsBreeding' } },
            { kind: 'Field', name: { kind: 'Name', value: 'cowsBreeding' } },
            { kind: 'Field', name: { kind: 'Name', value: 'steersBreeding' } },
            { kind: 'Field', name: { kind: 'Name', value: 'calfsBreeding' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    BuffaloActivityLivestockBreedingBuffaloLivestockActivityFragment,
    unknown
  >;
export const BuffaloActivityLivestockTradingBuffaloLivestockActivityFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'BuffaloActivityLivestockTradingBuffaloLivestockActivity',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BuffaloLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'bullsTrading' } },
            { kind: 'Field', name: { kind: 'Name', value: 'cowsTrading' } },
            { kind: 'Field', name: { kind: 'Name', value: 'steersTrading' } },
            { kind: 'Field', name: { kind: 'Name', value: 'calfsTrading' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    BuffaloActivityLivestockTradingBuffaloLivestockActivityFragment,
    unknown
  >;
export const BuffaloActivityLivestockBuffaloLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BuffaloActivityLivestockBuffaloLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BuffaloLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'BuffaloActivityLivestockBreedingBuffaloLivestockActivity',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'BuffaloActivityLivestockTradingBuffaloLivestockActivity',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BuffaloActivityLivestockBreedingBuffaloLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BuffaloLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bullsBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calfsBreeding' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BuffaloActivityLivestockTradingBuffaloLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BuffaloLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bullsTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calfsTrading' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BuffaloActivityLivestockBuffaloLivestockActivityFragment,
  unknown
>;
export const BuffaloActivityLivestockFeed_BuffaloLivestockActivityFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'BuffaloActivityLivestockFeed_BuffaloLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BuffaloLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'feed' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'grazingCrops' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    BuffaloActivityLivestockFeed_BuffaloLivestockActivityFragmentFragment,
    unknown
  >;
export const PropertiesBusinessActivitiesActivitiesHeaderBuffaloActivityFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'PropertiesBusinessActivitiesActivitiesHeaderBuffaloActivity',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BuffaloLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'start' } },
            { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PropertiesBusinessActivitiesActivitiesHeaderBuffaloActivityFragment,
    unknown
  >;
export const BuffaloLivestockActivity_MonthActivitiesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BuffaloLivestockActivity_MonthActivities' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BuffaloMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buffaloClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BuffaloLivestockActivity_MonthActivitiesFragment,
  unknown
>;
export const BusinessActivitiesBurningActivityInfoFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BusinessActivitiesBurningActivityInfoFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BurningActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fireScarAreaSquareMetres' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fuelClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'patchiness' } },
          { kind: 'Field', name: { kind: 'Name', value: 'season' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vegetationClass' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'yearsSinceLastFire' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rainfallZone' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BusinessActivitiesBurningActivityInfoFragmentFragment,
  unknown
>;
export const PropertiesBusinessActivitiesActivitiesHeaderBurningActivityFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'PropertiesBusinessActivitiesActivitiesHeaderBurningActivity',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BurningActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'start' } },
            { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PropertiesBusinessActivitiesActivitiesHeaderBurningActivityFragment,
    unknown
  >;
export const CottonCropInfoPageCottonFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CottonCropInfoPageCottonFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CottonActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'residueBurned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentageBurned' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageCottonYieldKgPerHa' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'balesPerHa' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageWeightPerBaleKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cottonLintPerBaleKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cottonSeedPerBaleKg' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'wastePerBaleKg' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CottonCropInfoPageCottonFieldsFragment, unknown>;
export const CottonCropInfoPageCroppingActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'CottonCropInfoPageCroppingActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CroppingActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plantingAreaSquareMetres' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plantingAreaGeometry' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CottonCropInfoPageCroppingActivityFragmentFragment,
  unknown
>;
export const CropInfoPageCropVarietyFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CropInfoPageCropVarietyFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CropVariety' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'species' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yieldInKg' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CropInfoPageCropVarietyFragmentFragment, unknown>;
export const CropInfoPageCroppingActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CropInfoPageCroppingActivityFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CroppingActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'irrigated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'residueBurned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentageBurned' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plantingAreaSquareMetres' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plantingAreaGeometry' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'varieties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CropInfoPageCropVarietyFragment',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CropInfoPageCropVarietyFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CropVariety' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'species' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yieldInKg' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CropInfoPageCroppingActivityFragmentFragment,
  unknown
>;
export const PropertiesBusinessActivitiesActivitiesHeaderCroppingActivityFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'PropertiesBusinessActivitiesActivitiesHeaderCroppingActivity',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'CroppingActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'start' } },
            { kind: 'Field', name: { kind: 'Name', value: 'end' } },
            { kind: 'Field', name: { kind: 'Name', value: 'croppingType' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PropertiesBusinessActivitiesActivitiesHeaderCroppingActivityFragment,
    unknown
  >;
export const PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Fertiliser' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'amountPerHectareKg' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'applicationAreaSquareMetres' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'applicationDate' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'applicationAreaGeometry' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'ratioN' } },
            { kind: 'Field', name: { kind: 'Name', value: 'ratioP' } },
            { kind: 'Field', name: { kind: 'Name', value: 'ratioK' } },
            { kind: 'Field', name: { kind: 'Name', value: 'ratioS' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragmentFragment,
    unknown
  >;
export const PropertiesBusinessActivitiesCropLimeFertiliserFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'PropertiesBusinessActivitiesCropLimeFertiliserFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'CroppingActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'plantingAreaSquareMetres' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'lime' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'amountPerHectareKg' },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'applicationAreaSquareMetres',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'fertiliser' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'amountPerHectareKg' },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'applicationAreaSquareMetres',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'ureaseInhibitorUsed' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'nitrificationInhibitorUsed' },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PropertiesBusinessActivitiesCropLimeFertiliserFragmentFragment,
    unknown
  >;
export const PropertiesBusinessActivitiesCropLimeFertiliserLimeFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'PropertiesBusinessActivitiesCropLimeFertiliserLimeFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Lime' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'amountPerHectareKg' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'applicationAreaSquareMetres' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'applicationDate' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'applicationAreaGeometry' },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PropertiesBusinessActivitiesCropLimeFertiliserLimeFragmentFragment,
    unknown
  >;
export const PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Carbamate' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'amountPerHectareGrams' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'product' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'applicationAreaSquareMetres' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'applicationAreaGeometry' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'activeIngredientPercentage' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'applicationDate' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragmentFragment,
    unknown
  >;
export const PropertiesBusinessActivitiesCropWeedPestDiseaseListsFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'PropertiesBusinessActivitiesCropWeedPestDiseaseListsFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'CroppingActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'plantingAreaSquareMetres' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'pesticide' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'amountPerHectareGrams' },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'applicationAreaSquareMetres',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'herbicide' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'amountPerHectareGrams' },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'applicationAreaSquareMetres',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'fungicide' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'amountPerHectareGrams' },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'applicationAreaSquareMetres',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PropertiesBusinessActivitiesCropWeedPestDiseaseListsFragmentFragment,
    unknown
  >;
export const UseDairyMonthlyFeedBreakdown_ActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseDairyMonthlyFeedBreakdown_ActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'milkingCows' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grazingCrops' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseDairyMonthlyFeedBreakdown_ActivityFragmentFragment,
  unknown
>;
export const UseDairyMonthlyFeedBreakdown_DairyMonthLivestockActivityFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseDairyMonthlyFeedBreakdown_DairyMonthLivestockActivity',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'month' } },
            { kind: 'Field', name: { kind: 'Name', value: 'year' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'crudeProteinPercentage' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'dryMatterDigestibilityPercentage' },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseDairyMonthlyFeedBreakdown_DairyMonthLivestockActivityFragment,
    unknown
  >;
export const DairyFeedBreakdownSummaryFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyFeedBreakdownSummaryFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'milkingCows' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DairyFeedBreakdownSummaryFragmentFragment,
  unknown
>;
export const DairyFeedFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyFeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grazingCrops' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'milkingCows' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'DairyFeedBreakdownSummaryFragment' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyFeedBreakdownSummaryFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'milkingCows' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DairyFeedFragmentFragment, unknown>;
export const DairyFeedBreakdownSummaryMonthsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyFeedBreakdownSummaryMonthsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crudeProteinPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dryMatterDigestibilityPercentage' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DairyFeedBreakdownSummaryMonthsFragmentFragment,
  unknown
>;
export const DairyInfoFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyInfoFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'productionSystem' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'distanceTravelledBetweenFarmsKm' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'truckType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissionsRedMeatPercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DairyInfoFragmentFragment, unknown>;
export const DairyLivestockActivityHeaderFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyLivestockActivityHeaderFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DairyLivestockActivityHeaderFragmentFragment,
  unknown
>;
export const TransactionForm_DairyMonthLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_DairyMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TransactionForm_DairyMonthLivestockActivityFragmentFragment,
  unknown
>;
export const UpdateTransaction_DairyMonthLivestockActivityFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UpdateTransaction_DairyMonthLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'TransactionForm_DairyMonthLivestockActivityFragment',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'TransactionForm_DairyMonthLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'year' } },
            { kind: 'Field', name: { kind: 'Name', value: 'month' } },
            { kind: 'Field', name: { kind: 'Name', value: 'dairyClass' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UpdateTransaction_DairyMonthLivestockActivityFragmentFragment,
    unknown
  >;
export const CreateTransaction_DairyMonthLivestockActivityFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'CreateTransaction_DairyMonthLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'TransactionForm_DairyMonthLivestockActivityFragment',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'TransactionForm_DairyMonthLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'year' } },
            { kind: 'Field', name: { kind: 'Name', value: 'month' } },
            { kind: 'Field', name: { kind: 'Name', value: 'dairyClass' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    CreateTransaction_DairyMonthLivestockActivityFragmentFragment,
    unknown
  >;
export const DairyMonthlyBreakdown_DairyLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DairyMonthlyBreakdown_DairyLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'milkingCows' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DairyMonthlyBreakdown_DairyLivestockActivityFragmentFragment,
  unknown
>;
export const DairyMonthlyForm_DairyMonthLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DairyMonthlyForm_DairyMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previousMonthLiveweightKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DairyMonthlyForm_DairyMonthLivestockActivityFragment,
  unknown
>;
export const DairyMonthlyBreakdown_DairyMonthLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DairyMonthlyBreakdown_DairyMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'DairyMonthlyForm_DairyMonthLivestockActivity',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DairyMonthlyForm_DairyMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previousMonthLiveweightKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DairyMonthlyBreakdown_DairyMonthLivestockActivityFragment,
  unknown
>;
export const DairyLivestockFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyLivestockFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'milkingCows' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DairyLivestockFragmentFragment, unknown>;
export const DairyActivityLivestockFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyActivityLivestockFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'DairyLivestockFragment' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyLivestockFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'milkingCows' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DairyActivityLivestockFragmentFragment, unknown>;
export const DairyLivestockMonthsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyLivestockMonthsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DairyLivestockMonthsFragmentFragment, unknown>;
export const DairyManureFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyManureFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manureMilkingPasturePercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manureMilkingLagoonPercentage' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'manureMilkingSumpDispersalPercentage',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manureMilkingPaddocksPercentage' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'manureMilkingSolidStoragePercentage',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manureOtherPasturePercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manureOtherLagoonPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manureOtherSumpDispersalPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manureOtherPaddocksPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manureOtherSolidStoragePercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DairyManureFragmentFragment, unknown>;
export const UseDairyMonthlyMilkBreakdown_DairyMonthLivestockActivityFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseDairyMonthlyMilkBreakdown_DairyMonthLivestockActivity',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'month' } },
            { kind: 'Field', name: { kind: 'Name', value: 'year' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'litresPerHeadPerDay' },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseDairyMonthlyMilkBreakdown_DairyMonthLivestockActivityFragment,
    unknown
  >;
export const DairyMilkSummaryFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyMilkSummaryFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'milkingCows' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DairyMilkSummaryFragmentFragment, unknown>;
export const DairyMilkProductionListFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyMilkProductionListFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'milkingCows' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'DairyMilkSummaryFragment' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyMilkSummaryFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'milkingCows' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DairyMilkProductionListFragmentFragment, unknown>;
export const DairyMilkSummaryMonthsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyMilkSummaryMonthsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'litresPerHeadPerDay' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DairyMilkSummaryMonthsFragmentFragment, unknown>;
export const TransactionForm_DeerMonthLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_DeerMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deerClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TransactionForm_DeerMonthLivestockActivityFragmentFragment,
  unknown
>;
export const UpdateTransaction_DeerMonthLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UpdateTransaction_DeerMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'TransactionForm_DeerMonthLivestockActivityFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_DeerMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deerClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTransaction_DeerMonthLivestockActivityFragmentFragment,
  unknown
>;
export const CreateTransaction_DeerMonthLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'CreateTransaction_DeerMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'TransactionForm_DeerMonthLivestockActivityFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_DeerMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deerClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTransaction_DeerMonthLivestockActivityFragmentFragment,
  unknown
>;
export const DeerMonthlyBreakdown_DeerLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeerMonthlyBreakdown_DeerLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bucksBreeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breedingDoesBreeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherDoesBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fawnBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bucksTrading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breedingDoesTrading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherDoesTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fawnTrading' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeerMonthlyBreakdown_DeerLivestockActivityFragmentFragment,
  unknown
>;
export const DeerMonthlyForm_DeerMonthLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeerMonthlyForm_DeerMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageDoesFawning' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'seasonalFawningRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeerMonthlyForm_DeerMonthLivestockActivityFragment,
  unknown
>;
export const DeerMonthlyBreakdown_DeerMonthLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeerMonthlyBreakdown_DeerMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'DeerMonthlyForm_DeerMonthLivestockActivity',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeerMonthlyForm_DeerMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageDoesFawning' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'seasonalFawningRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeerMonthlyBreakdown_DeerMonthLivestockActivityFragment,
  unknown
>;
export const DeerActivityLivestockBreedingDeerLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeerActivityLivestockBreedingDeerLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bucksBreeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breedingDoesBreeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherDoesBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fawnBreeding' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeerActivityLivestockBreedingDeerLivestockActivityFragment,
  unknown
>;
export const DeerActivityLivestockTradingDeerLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeerActivityLivestockTradingDeerLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bucksTrading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breedingDoesTrading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherDoesTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fawnTrading' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeerActivityLivestockTradingDeerLivestockActivityFragment,
  unknown
>;
export const DeerActivityLivestockDeerLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeerActivityLivestockDeerLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'DeerActivityLivestockBreedingDeerLivestockActivity',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'DeerActivityLivestockTradingDeerLivestockActivity',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeerActivityLivestockBreedingDeerLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bucksBreeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breedingDoesBreeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherDoesBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fawnBreeding' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeerActivityLivestockTradingDeerLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bucksTrading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breedingDoesTrading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherDoesTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fawnTrading' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeerActivityLivestockDeerLivestockActivityFragment,
  unknown
>;
export const DeerActivityLivestockFeed_DeerLivestockActivityFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'DeerActivityLivestockFeed_DeerLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DeerLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'feed' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'grazingCrops' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    DeerActivityLivestockFeed_DeerLivestockActivityFragmentFragment,
    unknown
  >;
export const PropertiesBusinessActivitiesActivitiesHeaderDeerActivityFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'PropertiesBusinessActivitiesActivitiesHeaderDeerActivity',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DeerLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'start' } },
            { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PropertiesBusinessActivitiesActivitiesHeaderDeerActivityFragment,
    unknown
  >;
export const DeerLivestockActivity_MonthActivitiesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DeerLivestockActivity_MonthActivities' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deerClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeerLivestockActivity_MonthActivitiesFragment,
  unknown
>;
export const FeedlotGroupFeedBreakdownFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotGroupFeedBreakdownFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotFeedBreakdown' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dryMatterDigestibility' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'crudeProtein' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'netEnergyConcentrationMjPerKg' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'nitrogenRetention' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FeedlotGroupFeedBreakdownFragmentFragment,
  unknown
>;
export const FeedlotGroupFeedFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotGroupFeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedBreakdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedlotGroupFeedBreakdownFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotGroupFeedBreakdownFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotFeedBreakdown' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dryMatterDigestibility' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'crudeProtein' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'netEnergyConcentrationMjPerKg' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'nitrogenRetention' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FeedlotGroupFeedFragmentFragment, unknown>;
export const FeedlotFeedCompositionFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotFeedCompositionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotFeedComposition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalProportion' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalCellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalHemicellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalSolubleResidue' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalNitrogen' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesProportion' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesCellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesHemicellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesSolubleResidue' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesNitrogen' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'grassesProportion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'grassesCellulous' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grassesHemicellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grassesSolubleResidue' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'grassesNitrogen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legumesProportion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legumesCellulous' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'legumesHemicellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'legumesSolubleResidue' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'legumesNitrogen' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FeedlotFeedCompositionFragmentFragment, unknown>;
export const FeedlotProductionFeedFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotProductionFeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
          { kind: 'Field', name: { kind: 'Name', value: 'grazingCrops' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedComposition' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedlotFeedCompositionFragment',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'feedBreakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotFeedCompositionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotFeedComposition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalProportion' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalCellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalHemicellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalSolubleResidue' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalNitrogen' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesProportion' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesCellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesHemicellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesSolubleResidue' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesNitrogen' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'grassesProportion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'grassesCellulous' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grassesHemicellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grassesSolubleResidue' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'grassesNitrogen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legumesProportion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legumesCellulous' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'legumesHemicellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'legumesSolubleResidue' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'legumesNitrogen' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FeedlotProductionFeedFragmentFragment, unknown>;
export const PropertiesBusinessActivitiesActivitiesHeaderFeedlotActivityFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'PropertiesBusinessActivitiesActivitiesHeaderFeedlotActivity',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'FeedlotActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'start' } },
            { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PropertiesBusinessActivitiesActivitiesHeaderFeedlotActivityFragment,
    unknown
  >;
export const FeedlotLivestockGroupFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotLivestockGroupFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfLivestock' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lengthOfStayDays' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightKg' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FeedlotLivestockGroupFragmentFragment, unknown>;
export const FeedlotProductionLivestockFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotProductionLivestockFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchases' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCategory' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'head' } },
                { kind: 'Field', name: { kind: 'Name', value: 'beefClass' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sales' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCategory' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'head' } },
                { kind: 'Field', name: { kind: 'Name', value: 'beefClass' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FeedlotProductionLivestockFragmentFragment,
  unknown
>;
export const FeedlotLivestockPurchaseFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotLivestockPurchaseFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotPurchase' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'head' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightPerHeadKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beefClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FeedlotLivestockPurchaseFragmentFragment, unknown>;
export const FeedlotLivestockSaleFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotLivestockSaleFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotSale' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'head' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightPerHeadKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beefClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCategory' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FeedlotLivestockSaleFragmentFragment, unknown>;
export const FeedlotProductionSystemFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotProductionSystemFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'system' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FeedlotProductionSystemFragmentFragment, unknown>;
export const FeedlotProductionTransportFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotProductionTransportFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalDistanceTravelledKm' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'truckType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FeedlotProductionTransportFragmentFragment,
  unknown
>;
export const TransactionForm_GoatMonthLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_GoatMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goatClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TransactionForm_GoatMonthLivestockActivityFragmentFragment,
  unknown
>;
export const UpdateTransaction_GoatMonthLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UpdateTransaction_GoatMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'TransactionForm_GoatMonthLivestockActivityFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_GoatMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goatClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTransaction_GoatMonthLivestockActivityFragmentFragment,
  unknown
>;
export const CreateTransaction_GoatMonthLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'CreateTransaction_GoatMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'TransactionForm_GoatMonthLivestockActivityFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_GoatMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goatClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTransaction_GoatMonthLivestockActivityFragmentFragment,
  unknown
>;
export const GoatMonthlyBreakdown_GoatLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatMonthlyBreakdown_GoatLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bucksBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wethersBreeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breedingDoesBreeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingDoesBreeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherDoesBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kidsBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bucksTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wethersTrading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breedingDoesTrading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingDoesTrading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherDoesTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kidsTrading' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GoatMonthlyBreakdown_GoatLivestockActivityFragmentFragment,
  unknown
>;
export const GoatMonthlyForm_GoatMonthLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatMonthlyForm_GoatMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageDoesKidding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'seasonalKiddingRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GoatMonthlyForm_GoatMonthLivestockActivityFragment,
  unknown
>;
export const GoatMonthlyBreakdown_GoatMonthLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatMonthlyBreakdown_GoatMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'GoatMonthlyForm_GoatMonthLivestockActivity',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatMonthlyForm_GoatMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageDoesKidding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'seasonalKiddingRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GoatMonthlyBreakdown_GoatMonthLivestockActivityFragment,
  unknown
>;
export const GoatActivityLivestockBreedingGoatLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatActivityLivestockBreedingGoatLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bucksBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wethersBreeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breedingDoesBreeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingDoesBreeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherDoesBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kidsBreeding' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GoatActivityLivestockBreedingGoatLivestockActivityFragment,
  unknown
>;
export const GoatActivityLivestockTradingGoatLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatActivityLivestockTradingGoatLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bucksTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wethersTrading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breedingDoesTrading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingDoesTrading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherDoesTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kidsTrading' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GoatActivityLivestockTradingGoatLivestockActivityFragment,
  unknown
>;
export const GoatActivityLivestockGoatLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatActivityLivestockGoatLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'GoatActivityLivestockBreedingGoatLivestockActivity',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'GoatActivityLivestockTradingGoatLivestockActivity',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatActivityLivestockBreedingGoatLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bucksBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wethersBreeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breedingDoesBreeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingDoesBreeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherDoesBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kidsBreeding' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatActivityLivestockTradingGoatLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bucksTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wethersTrading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breedingDoesTrading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingDoesTrading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherDoesTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kidsTrading' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GoatActivityLivestockGoatLivestockActivityFragment,
  unknown
>;
export const GoatFeedDrySeasonMix_GoatLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatFeedDrySeasonMix_GoatLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedDrySeasonMix_FeedFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedDrySeasonMix_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'drySeasonMixKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'drySeasonMixUreaPercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GoatFeedDrySeasonMix_GoatLivestockActivityFragmentFragment,
  unknown
>;
export const GoatActivityLivestockFeed_GoatLivestockActivityFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'GoatActivityLivestockFeed_GoatLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'GoatLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'feed' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'grazingCrops' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'mineralBlock' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'weanerBlock' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'drySeasonMix' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    GoatActivityLivestockFeed_GoatLivestockActivityFragmentFragment,
    unknown
  >;
export const GoatFeedMineralBlock_GoatLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatFeedMineralBlock_GoatLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedMineralBlock_FeedFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedMineralBlock_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mineralBlockKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mineralBlockUreaPercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GoatFeedMineralBlock_GoatLivestockActivityFragmentFragment,
  unknown
>;
export const GoatFeedWeanerBlock_GoatLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatFeedWeanerBlock_GoatLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FeedWeanerBlock_FeedFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedWeanerBlock_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weanerBlockKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'weanerBlockUreaPercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GoatFeedWeanerBlock_GoatLivestockActivityFragmentFragment,
  unknown
>;
export const PropertiesBusinessActivitiesActivitiesHeaderGoatActivityFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'PropertiesBusinessActivitiesActivitiesHeaderGoatActivity',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'GoatLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'start' } },
            { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PropertiesBusinessActivitiesActivitiesHeaderGoatActivityFragment,
    unknown
  >;
export const GoatWoolShearingEventsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoatWoolShearingEventsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatShearingEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberGoatShorn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'woolPerHeadKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cleanWoolYieldKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goatLivestockActivityId' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoatWoolShearingEventsFragmentFragment, unknown>;
export const WoolUpdateGoatShearingEventFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WoolUpdateGoatShearingEventFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatShearingEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goatClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberGoatShorn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'woolPerHeadKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cleanWoolYieldKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goatLivestockActivityId' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WoolUpdateGoatShearingEventFragmentFragment,
  unknown
>;
export const GoatShearingEventGoatLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoatShearingEventGoatLivestockActivity' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GoatShearingEventGoatLivestockActivityFragment,
  unknown
>;
export const GoatShearingEventMonthActivitiesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoatShearingEventMonthActivities' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goatClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCategory' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoatShearingEventMonthActivitiesFragment, unknown>;
export const GoatLivestockActivity_MonthActivitiesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoatLivestockActivity_MonthActivities' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goatClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GoatLivestockActivity_MonthActivitiesFragment,
  unknown
>;
export const ActivitiesIndexActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivitiesIndexActivity' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Activity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CroppingActivity' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'croppingType' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActivitiesIndexActivityFragment, unknown>;
export const ActivityLivestockFeed_FeedFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityLivestockFeed_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
          { kind: 'Field', name: { kind: 'Name', value: 'grazingCrops' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mineralBlock' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weanerBlock' } },
          { kind: 'Field', name: { kind: 'Name', value: 'drySeasonMix' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ActivityLivestockFeed_FeedFragmentFragment,
  unknown
>;
export const PorkFeedBlendEditFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PorkFeedBlendEditFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkFeedBlend' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPurchasedTonnes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'barley' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beetPulp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blendType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bloodMeal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canolaMeal' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissionsIntensityFeedProduction' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fishMeal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'meatMeal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'millMix' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageAdditionalIngredients' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'soybeanMeal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tallow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wheat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wheatBran' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wheyPowder' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PorkFeedBlendEditFragmentFragment, unknown>;
export const PorkFeedBlendsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PorkFeedBlendsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkFeedBlend' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPurchasedTonnes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'blendType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PorkFeedBlendsFragmentFragment, unknown>;
export const PorkLivestockActivityFeedBlendsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PorkLivestockActivityFeedBlendsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grazingCrops' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedBlends' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PorkFeedBlendsFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PorkFeedBlendsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkFeedBlend' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPurchasedTonnes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'blendType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PorkLivestockActivityFeedBlendsFragmentFragment,
  unknown
>;
export const PropertiesBusinessActivitiesActivitiesHeaderPorkActivityFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'PropertiesBusinessActivitiesActivitiesHeaderPorkActivity',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PorkLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'start' } },
            { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PropertiesBusinessActivitiesActivitiesHeaderPorkActivityFragment,
    unknown
  >;
export const TransactionForm_PorkMonthLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_PorkMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'porkClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TransactionForm_PorkMonthLivestockActivityFragmentFragment,
  unknown
>;
export const UpdateTransaction_PorkMonthLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UpdateTransaction_PorkMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'TransactionForm_PorkMonthLivestockActivityFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_PorkMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'porkClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTransaction_PorkMonthLivestockActivityFragmentFragment,
  unknown
>;
export const CreateTransaction_PorkMonthLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'CreateTransaction_PorkMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'TransactionForm_PorkMonthLivestockActivityFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_PorkMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'porkClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTransaction_PorkMonthLivestockActivityFragmentFragment,
  unknown
>;
export const PorkMonthlyBreakdown_PorkLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PorkMonthlyBreakdown_PorkLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sows' } },
          { kind: 'Field', name: { kind: 'Name', value: 'boars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gilts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weaners' } },
          { kind: 'Field', name: { kind: 'Name', value: 'growers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suckers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slaughterPigs' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PorkMonthlyBreakdown_PorkLivestockActivityFragmentFragment,
  unknown
>;
export const PorkMonthlyForm_PorkMonthLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PorkMonthlyForm_PorkMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PorkMonthlyForm_PorkMonthLivestockActivityFragment,
  unknown
>;
export const PorkMonthlyBreakdown_PorkMonthLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PorkMonthlyBreakdown_PorkMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'PorkMonthlyForm_PorkMonthLivestockActivity',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PorkMonthlyForm_PorkMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PorkMonthlyBreakdown_PorkMonthLivestockActivityFragment,
  unknown
>;
export const PorkActivityLivestockClassesFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PorkActivityLivestockClassesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sows' } },
          { kind: 'Field', name: { kind: 'Name', value: 'boars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gilts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weaners' } },
          { kind: 'Field', name: { kind: 'Name', value: 'growers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suckers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slaughterPigs' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PorkActivityLivestockClassesFragmentFragment,
  unknown
>;
export const PorkActivityLivestockPorkLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PorkActivityLivestockPorkLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'PorkActivityLivestockClassesFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PorkActivityLivestockClassesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sows' } },
          { kind: 'Field', name: { kind: 'Name', value: 'boars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gilts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weaners' } },
          { kind: 'Field', name: { kind: 'Name', value: 'growers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suckers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slaughterPigs' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PorkActivityLivestockPorkLivestockActivityFragment,
  unknown
>;
export const PorkLivestockActivity_MonthActivitiesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PorkLivestockActivity_MonthActivities' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'porkClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PorkLivestockActivity_MonthActivitiesFragment,
  unknown
>;
export const PoultryBroilersBatchFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilersBatchFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryGrowerBatch' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PoultryBroilersBatchFragmentFragment, unknown>;
export const PoultryBroilersBatchFeedBreakdownFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PoultryBroilersBatchFeedBreakdownFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryFeedBreakdown' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dryMatterIntakeKgPerHeadPerDay' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dryMatterDigestibilityPercent' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crudeProteinPercent' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'manureAshPercent' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nitrogenRetentionPercent' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PoultryBroilersBatchFeedBreakdownFragmentFragment,
  unknown
>;
export const PoultryGrowerBatchFeedBlendFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryGrowerBatchFeedBlendFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryFeedBlend' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'blendType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barleyWhole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'millRun' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sorghum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'soybean' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wheat' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPurchasedTonnes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageAdditionalIngredients' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissionsIntensityFeedProduction' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PoultryGrowerBatchFeedBlendFragmentFragment,
  unknown
>;
export const PoultryBroilersBatchFeedFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilersBatchFeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryGrowerBatch' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
          { kind: 'Field', name: { kind: 'Name', value: 'grazingCrops' } },
          { kind: 'Field', name: { kind: 'Name', value: 'growers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'other' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedBlends' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amountPurchasedTonnes' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'blendType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PoultryBroilersBatchFeedFragmentFragment, unknown>;
export const PoultryBroilersBatchDetailsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilersBatchDetailsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryGrowerBatchDetails' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalNumberOfBirds' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLengthOfStayDaysL1' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightPerHeadKgW1' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLengthOfStayDaysL2' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightPerHeadKgW2' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PoultryBroilersBatchDetailsFragmentFragment,
  unknown
>;
export const PoultryBroilersBatchFlockFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilersBatchFlockFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryGrowerBatch' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'growers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'other' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PoultryBroilersBatchFlockFragmentFragment,
  unknown
>;
export const PropertiesBusinessActivitiesActivitiesHeaderGrowerBatchFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'PropertiesBusinessActivitiesActivitiesHeaderGrowerBatch',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PoultryBroilerActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'start' } },
            { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PropertiesBusinessActivitiesActivitiesHeaderGrowerBatchFragment,
    unknown
  >;
export const PoultryBroilersGrowerBatchLayoutFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilersGrowerBatchLayoutFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryGrowerBatch' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PoultryBroilersGrowerBatchLayoutFragmentFragment,
  unknown
>;
export const PoultryBroilers_BatchesFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilers_BatchesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryBroilerActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'growerBatches' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PoultryBroilers_BatchesFragmentFragment, unknown>;
export const PropertiesBusinessActivitiesActivitiesHeaderPoultryActivityFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'PropertiesBusinessActivitiesActivitiesHeaderPoultryActivity',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PoultryBroilerActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'start' } },
            { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PropertiesBusinessActivitiesActivitiesHeaderPoultryActivityFragment,
    unknown
  >;
export const PoultryBroilersManureLitterFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilersManureLitterFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryBroilerActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentManureAddedToPasture' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentSolidManure' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageLitterRecycled' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'cyclesPerYear' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PoultryBroilersManureLitterFragmentFragment,
  unknown
>;
export const PoultryBroilersTransactionFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilersTransactionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryTransactionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageWeightKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentPurchasedFreeRange' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PoultryBroilersTransactionFragmentFragment,
  unknown
>;
export const PoultryBroilersTransactionsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilersTransactionsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryBroilerActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'class' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PoultryBroilersTransactionsFragmentFragment,
  unknown
>;
export const PoultryLayerEggSalesFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayerEggSalesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryLayerActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalLayerEggsProduced' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLayerEggWeightGrams' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalMeatChickenLayerEggsProduced' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'averageMeatChickenLayerEggWeightGrams',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PoultryLayerEggSalesFragmentFragment, unknown>;
export const PoultryLayerActivityFeedBlendFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayerActivityFeedBlendFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryFeedBlend' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'blendType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barleyWhole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'millRun' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sorghum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'soybean' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wheat' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPurchasedTonnes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageAdditionalIngredients' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissionsIntensityFeedProduction' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PoultryLayerActivityFeedBlendFragmentFragment,
  unknown
>;
export const PoultryLayerFeedBlendsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayerFeedBlendsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryLayerActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedBlends' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'blendType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amountPurchasedTonnes' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
          { kind: 'Field', name: { kind: 'Name', value: 'grazingCrops' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PoultryLayerFeedBlendsFragmentFragment, unknown>;
export const PoultryLayerFlockFormMonthlyActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayerFlockFormMonthlyActivity' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryLayerMonthActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meatChickenLayerCount' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PoultryLayerFlockFormMonthlyActivityFragment,
  unknown
>;
export const PoultryLayerActivityInfoFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayerActivityInfoFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryLayerActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poultryLayerMonthActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryLayerFlockFormMonthlyActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayerFlockFormMonthlyActivity' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryLayerMonthActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meatChickenLayerCount' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PoultryLayerActivityInfoFragmentFragment, unknown>;
export const PoultryLayerActivityHeaderFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayerActivityHeaderFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryLayerActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PoultryLayerActivityHeaderFragmentFragment,
  unknown
>;
export const PoultryLayerManureLitterFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayerManureLitterFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryLayerActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentManureAddedToPasture' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageLitterRecycled' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentSolidManure' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'cyclesPerYear' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PoultryLayerManureLitterFragmentFragment, unknown>;
export const PoultryLayersTransactionFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayersTransactionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryTransactionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageWeightKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentPurchasedFreeRange' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PoultryLayersTransactionFragmentFragment, unknown>;
export const PoultryLayerActivityTransactionsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayerActivityTransactionsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryLayerActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'class' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PoultryLayerActivityTransactionsFragmentFragment,
  unknown
>;
export const TransactionForm_SheepMonthLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_SheepMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sheepClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TransactionForm_SheepMonthLivestockActivityFragmentFragment,
  unknown
>;
export const UpdateTransaction_SheepMonthLivestockActivityFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UpdateTransaction_SheepMonthLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'TransactionForm_SheepMonthLivestockActivityFragment',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'TransactionForm_SheepMonthLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'year' } },
            { kind: 'Field', name: { kind: 'Name', value: 'month' } },
            { kind: 'Field', name: { kind: 'Name', value: 'sheepClass' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UpdateTransaction_SheepMonthLivestockActivityFragmentFragment,
    unknown
  >;
export const CreateTransaction_SheepMonthLivestockActivityFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'CreateTransaction_SheepMonthLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'TransactionForm_SheepMonthLivestockActivityFragment',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'TransactionForm_SheepMonthLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'year' } },
            { kind: 'Field', name: { kind: 'Name', value: 'month' } },
            { kind: 'Field', name: { kind: 'Name', value: 'sheepClass' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    CreateTransaction_SheepMonthLivestockActivityFragmentFragment,
    unknown
  >;
export const SheepMonthlyBreakdown_SheepLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepMonthlyBreakdown_SheepLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'ewesGt2Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eweLambsLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherEwesGt2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingEwes1To2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wetherLambsLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wethersGt1Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ramsBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ewesGt2Trading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eweLambsLt1Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherEwesGt2Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingEwes1To2Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wetherLambsLt1Trading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'wethersGt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ramsTrading' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SheepMonthlyBreakdown_SheepLivestockActivityFragmentFragment,
  unknown
>;
export const SheepMonthlyForm_SheepMonthLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepMonthlyForm_SheepMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previousMonthLiveweightKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageEwesLambing' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'seasonalLambingRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SheepMonthlyForm_SheepMonthLivestockActivityFragment,
  unknown
>;
export const SheepMonthlyBreakdown_SheepMonthLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepMonthlyBreakdown_SheepMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'SheepMonthlyForm_SheepMonthLivestockActivity',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepMonthlyForm_SheepMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previousMonthLiveweightKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageEwesLambing' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'seasonalLambingRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SheepMonthlyBreakdown_SheepMonthLivestockActivityFragment,
  unknown
>;
export const SheepActivityLivestockBreedingSheepLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepActivityLivestockBreedingSheepLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'ewesGt2Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eweLambsLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherEwesGt2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingEwes1To2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wetherLambsLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wethersGt1Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ramsBreeding' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SheepActivityLivestockBreedingSheepLivestockActivityFragment,
  unknown
>;
export const SheepActivityLivestockTradingSheepLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepActivityLivestockTradingSheepLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'ewesGt2Trading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eweLambsLt1Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherEwesGt2Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingEwes1To2Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wetherLambsLt1Trading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'wethersGt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ramsTrading' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SheepActivityLivestockTradingSheepLivestockActivityFragment,
  unknown
>;
export const SheepActivityLivestockSheepLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepActivityLivestockSheepLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'SheepActivityLivestockBreedingSheepLivestockActivity',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'SheepActivityLivestockTradingSheepLivestockActivity',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepActivityLivestockBreedingSheepLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'ewesGt2Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eweLambsLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherEwesGt2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingEwes1To2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wetherLambsLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wethersGt1Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ramsBreeding' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepActivityLivestockTradingSheepLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'ewesGt2Trading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eweLambsLt1Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherEwesGt2Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingEwes1To2Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wetherLambsLt1Trading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'wethersGt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ramsTrading' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SheepActivityLivestockSheepLivestockActivityFragment,
  unknown
>;
export const UseSheepMonthlyFeedBreakdown_ActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseSheepMonthlyFeedBreakdown_ActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'ewesGt2Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eweLambsLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherEwesGt2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingEwes1To2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wetherLambsLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wethersGt1Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ramsBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ewesGt2Trading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eweLambsLt1Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherEwesGt2Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingEwes1To2Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wetherLambsLt1Trading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'wethersGt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ramsTrading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseSheepMonthlyFeedBreakdown_ActivityFragmentFragment,
  unknown
>;
export const UseSheepMonthlyFeedBreakdown_SheepMonthLivestockActivityFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseSheepMonthlyFeedBreakdown_SheepMonthLivestockActivity',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'month' } },
            { kind: 'Field', name: { kind: 'Name', value: 'year' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'crudeProteinPercentage' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'dryMatterDigestibilityPercentage' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'feedAvailabilityTonnesPerHectare' },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseSheepMonthlyFeedBreakdown_SheepMonthLivestockActivityFragment,
    unknown
  >;
export const SheepFeedDrySeasonMix_SheepLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepFeedDrySeasonMix_SheepLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedDrySeasonMix_FeedFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedDrySeasonMix_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'drySeasonMixKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'drySeasonMixUreaPercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SheepFeedDrySeasonMix_SheepLivestockActivityFragmentFragment,
  unknown
>;
export const SheepActivityLivestockFeed_SheepLivestockActivityFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'SheepActivityLivestockFeed_SheepLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
            { kind: 'Field', name: { kind: 'Name', value: 'ewesGt2Breeding' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'eweLambsLt1Breeding' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'otherEwesGt2Breeding' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'maidenBreedingEwes1To2Breeding' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'wetherLambsLt1Breeding' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'wethersGt1Breeding' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'ramsBreeding' } },
            { kind: 'Field', name: { kind: 'Name', value: 'ewesGt2Trading' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'eweLambsLt1Trading' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'otherEwesGt2Trading' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'maidenBreedingEwes1To2Trading' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'wetherLambsLt1Trading' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'wethersGt1Trading' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'ramsTrading' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'feed' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'grazingCrops' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'mineralBlock' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'weanerBlock' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'drySeasonMix' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    SheepActivityLivestockFeed_SheepLivestockActivityFragmentFragment,
    unknown
  >;
export const SheepFeedMineralBlock_SheepLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepFeedMineralBlock_SheepLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedMineralBlock_FeedFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedMineralBlock_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mineralBlockKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mineralBlockUreaPercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SheepFeedMineralBlock_SheepLivestockActivityFragmentFragment,
  unknown
>;
export const SheepFeedWeanerBlock_SheepLivestockActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepFeedWeanerBlock_SheepLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FeedWeanerBlock_FeedFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedWeanerBlock_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weanerBlockKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'weanerBlockUreaPercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SheepFeedWeanerBlock_SheepLivestockActivityFragmentFragment,
  unknown
>;
export const PropertiesBusinessActivitiesActivitiesHeaderSheepActivityFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'PropertiesBusinessActivitiesActivitiesHeaderSheepActivity',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'start' } },
            { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PropertiesBusinessActivitiesActivitiesHeaderSheepActivityFragment,
    unknown
  >;
export const SheepWoolShearingEventsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheepWoolShearingEventsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepShearingEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberSheepShorn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'woolPerHeadKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cleanWoolYieldKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheepLivestockActivityId' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SheepWoolShearingEventsFragmentFragment, unknown>;
export const WoolUpdateSheepShearingEventFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WoolUpdateSheepShearingEventFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepShearingEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sheepClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberSheepShorn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'woolPerHeadKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cleanWoolYieldKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheepLivestockActivityId' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WoolUpdateSheepShearingEventFragmentFragment,
  unknown
>;
export const SheepShearingEventSheepLivestockActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheepShearingEventSheepLivestockActivity' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SheepShearingEventSheepLivestockActivityFragment,
  unknown
>;
export const SheepShearingEventMonthActivitiesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheepShearingEventMonthActivities' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sheepClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCategory' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SheepShearingEventMonthActivitiesFragment,
  unknown
>;
export const SheepLivestockActivity_MonthActivitiesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheepLivestockActivity_MonthActivities' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightKg' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sheepClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SheepLivestockActivity_MonthActivitiesFragment,
  unknown
>;
export const VegetationInfoFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VegetationInfoFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VegetationActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'region' } },
          { kind: 'Field', name: { kind: 'Name', value: 'soilType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'species' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageAge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'treesPerHectare' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalPlantingAreaSquareMetres' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plantingAreaGeometry' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VegetationInfoFragmentFragment, unknown>;
export const PropertiesBusinessActivitiesActivitiesHeaderVegetationActivityFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'PropertiesBusinessActivitiesActivitiesHeaderVegetationActivity',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'VegetationActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'start' } },
            { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PropertiesBusinessActivitiesActivitiesHeaderVegetationActivityFragment,
    unknown
  >;
export const WildSeaFisheryBaitBaitPurchasesFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WildSeaFisheryBaitBaitPurchasesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FisheryBaitPurchase' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baitType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPurchasedTonnes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageAdditionalIngredients' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissionsIntensityBaitProduct' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WildSeaFisheryBaitBaitPurchasesFragmentFragment,
  unknown
>;
export const WildSeaFisheryBaitPurchasesFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WildSeaFisheryBaitPurchasesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FisheryBaitPurchase' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baitType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPurchasedTonnes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageAdditionalIngredients' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissionsIntensityBaitProduct' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WildSeaFisheryBaitPurchasesFragmentFragment,
  unknown
>;
export const WildSeaFisheryActivityInfoFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WildSeaFisheryActivityInfoFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WildSeaFisheryActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productionSystem' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalWeightCaughtKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offsetsPurchasedTonnes' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WildSeaFisheryActivityInfoFragmentFragment,
  unknown
>;
export const PropertiesBusinessActivitiesActivitiesHeaderWildSeaFisheryActivityFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'PropertiesBusinessActivitiesActivitiesHeaderWildSeaFisheryActivity',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'WildSeaFisheryActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'start' } },
            { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PropertiesBusinessActivitiesActivitiesHeaderWildSeaFisheryActivityFragment,
    unknown
  >;
export const WildSeaFisheryTransportRoutesFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WildSeaFisheryTransportRoutesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Route' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startPoint' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endPoint' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicleType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuelType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'distanceStartToEndKm' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfTrips' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalDistanceTravelledKm' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WildSeaFisheryTransportRoutesFragmentFragment,
  unknown
>;
export const WildSeaFisheryTransportRouteFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WildSeaFisheryTransportRouteFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Route' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startPoint' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endPoint' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicleType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuelType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'distanceStartToEndKm' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfTrips' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalDistanceTravelledKm' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WildSeaFisheryTransportRouteFragmentFragment,
  unknown
>;
export const WildSeaFisheryActivityForTransportFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'WildSeaFisheryActivityForTransportFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WildSeaFisheryActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WildSeaFisheryActivityForTransportFragmentFragment,
  unknown
>;
export const EditPropertyPage_PropertyFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditPropertyPage_PropertyFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditPropertyPage_PropertyFragmentFragment,
  unknown
>;
export const EditPropertyPage_BusinessActivityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EditPropertyPage_BusinessActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BusinessActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enabledActivityTypes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyNorthOfTropicOfCapricorn' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rainfallAbove600' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditPropertyPage_BusinessActivityFragmentFragment,
  unknown
>;
export const PropertiesFilesFileFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertiesFilesFileFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uploadStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bytes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PropertiesFilesFileFragmentFragment, unknown>;
export const PropertyFileForUploadFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyFileForUploadFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FileForUpload' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'presignedPostUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'presignedPostFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bytes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PropertyFileForUploadFragmentFragment, unknown>;
export const PropertyFilesFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyFilesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uploadStatus' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'bytes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PropertyFilesFragmentFragment, unknown>;
export const PropertyPage_PropertyFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyPage_PropertyFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
      },
    },
  ],
} as unknown as DocumentNode<PropertyPage_PropertyFragmentFragment, unknown>;
export const UseBusinessActivityForProperty_BusinessActivityFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseBusinessActivityForProperty_BusinessActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BusinessActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'enabledActivityTypes' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseBusinessActivityForProperty_BusinessActivityFragmentFragment,
    unknown
  >;
export const MapShapesEditIndex_MapShapeFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MapShapesEditIndex_MapShapeFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MapShape' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'geometry' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MapShapesEditIndex_MapShapeFragmentFragment,
  unknown
>;
export const MapShapesIndex_MapShapeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MapShapesIndex_MapShape' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MapShape' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'geometry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'MapShapesList_MapShape' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MapShapesListItem_MapShape' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MapShape' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'geometry' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MapShapesList_MapShape' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MapShape' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'MapShapesListItem_MapShape' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MapShapesIndex_MapShapeFragment, unknown>;
export const PropertiesNotesNoteFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertiesNotesNoteFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PropertyNote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PropertiesNotesNoteFragmentFragment, unknown>;
export const PropertyNotesFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyNotesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyNotes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PropertyNotesFragmentFragment, unknown>;
export const AllTeamPropertiesPagePropertyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllTeamPropertiesPageProperty' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'centroid' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllTeamPropertiesPagePropertyFragment, unknown>;
export const UseEditPropertyBoundary_ParcelFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseEditPropertyBoundary_ParcelFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Parcel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'geoFeature' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'geometry' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'properties' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseEditPropertyBoundary_ParcelFragmentFragment,
  unknown
>;
export const UseEditPropertyBoundary_GeographicInformationFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseEditPropertyBoundary_GeographicInformationFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'GeographicInformation' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseEditPropertyBoundary_GeographicInformationFragmentFragment,
    unknown
  >;
export const GetReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ViewReport_Report' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Report_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sections' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reportMetadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ParcelsAndBuildingsPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertyParcelsList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'buildingsList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TableOfContentsPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sections' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OverviewMap_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyParcelsGeoJson' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buildingsGeometries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyDetailsTable_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertySite' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landmarksLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandmarksTable_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landmarksTowns' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landmarksCities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landmarksSchools' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landmarksSilos' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OverviewPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OverviewMap_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PropertyDetailsTable_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LandmarksTable_ReportData' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SummaryTable_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elevationArable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landUseClasses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilAusClasses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elevationStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elevationSlope' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductivityCard_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'yieldModelDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AnnualRainfallCard_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClimateRiskCard_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateRiskStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyAndClimateSummaryPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SummaryTable_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProductivityCard_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'AnnualRainfallCard_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClimateRiskCard_ReportData' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'elevationArableImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landUseClassesImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landUseClasses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandUse_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landUseClasses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyDetailsLandUseTable_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertySite' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseTerrainSummary_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'satelliteRgbYearlyImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landUseClassesImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'satelliteNdviYearlyImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandUsePage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LandUse_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PropertyDetailsLandUseTable_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UseTerrainSummary_ReportData' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EviAreaProductivity_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eviAreaProductivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EviAreaAndRainfall_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eviAreaProductivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallVolume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'YieldCropTypes_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'yieldModelYearly' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductivityPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EviAreaProductivity_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EviAreaAndRainfall_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'YieldCropTypes_ReportData' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RainfallDuration_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RainfallVolume_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallVolume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SeasonalRainfallHistory_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallVolume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RainfallHistory_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallYtd' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rainfallYtdMinus1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RainfallPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RainfallDuration_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RainfallVolume_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SeasonalRainfallHistory_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RainfallHistory_ReportData' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Elevation_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elevationStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Slope_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elevationSlope' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TerrainPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Elevation_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Slope_ReportData' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'elevationElevationImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'elevationSlopeImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'elevationStatistics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'elevationSlope' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SoilTaxonomyPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertyParcelsGeoJson' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'soilAusClasses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'soilAusClassesImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SoilTexturePage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'soilOcImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'soilPhImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'soilAwcImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SoilTexture_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilSandProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilSiltProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilClayProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SoilOrganicCarbon_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilOcProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SoilPh_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilPhProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SoilAwc_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'soilAwcProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SoilProfilePage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SoilTexture_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SoilOrganicCarbon_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SoilPh_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SoilAwc_ReportData' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandAndNaturePage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertyParcelsGeoJson' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'forestClasses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'forestClassesImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landCoverClasses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landCoverClassesImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FireRiskChart_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grasslandFireMonthly' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemperatureMeanHistory_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'temperatureMeanHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'temperatureMeanYtd' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'temperatureMeanYtdMinus1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GrassAndForestFireRiskPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FireRiskChart_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'TemperatureMeanHistory_ReportData',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grasslandFireMaximum' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FiresTable_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'burnedAreaEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnvironmentRiskPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FiresTable_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'TemperatureMeanHistory_ReportData',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClimateChangeRainfall_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateSSP126RainfallYearly' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateSSP126RainfallYearlyStdDev' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClimateChangeTempMin_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateSSP126TempMinYearly' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateSSP126TempMinYearlyStdDev' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClimateChangeTempMax_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateSSP126TempMaxYearly' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateSSP126TempMaxYearlyStdDev' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClimateChangePage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClimateChangeRainfall_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClimateChangeTempMin_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClimateChangeTempMax_ReportData',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HeatEventsVolume_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heatEventsVolume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FrostEventsVolume_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frostEventsVolume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HeatEventsHistory_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heatEventsHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heatEventsYtd' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heatEventsYtdMinus1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FrostEventsHistory_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frostEventsHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frostEventsYtd' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frostEventsYtdMinus1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemperatureEventsPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HeatEventsVolume_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FrostEventsVolume_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HeatEventsHistory_ReportData' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FrostEventsHistory_ReportData',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DeforestationOverTimeChart_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationYearly' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationColorMap' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationDataQuality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseDeforestationImages_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationYearlyImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationRgbYearlyImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationNdviYearlyImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationDataQuality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DeforestationOverTimePage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DeforestationOverTimeChart_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseDeforestationImages_ReportData',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ForestsAndFiresPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FiresTable_ReportData' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertyParcelsGeoJson' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'forestClasses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'forestClassesImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecentSalesPriceChart_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comparableSales' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'saleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'similarity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sale' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contractDate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecentSalesPriceChart_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesPriceDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesPropertyHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecentSalesPricePerAreaChart_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesPricePerAreaDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesPropertyHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PricePerHectareOverTimeChart_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesAveragePriceHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NumberOfSalesOverTimeChart_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesSaleCountHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyPastSalesTable_ReportData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesPropertyHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'RecentSalesPriceChart_Report' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salesPropertyHistory' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecentSalesPriceChart_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecentSalesPricePerAreaChart_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PricePerHectareOverTimeChart_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'NumberOfSalesOverTimeChart_ReportData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PropertyPastSalesTable_ReportData',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewReport_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'Report_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ParcelsAndBuildingsPage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'TableOfContentsPage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'OverviewPage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'PropertyAndClimateSummaryPage_Report',
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'LandUsePage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProductivityPage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'RainfallPage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'TerrainPage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SoilTaxonomyPage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SoilTexturePage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SoilProfilePage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'LandAndNaturePage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'GrassAndForestFireRiskPage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'EnvironmentRiskPage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ClimateChangePage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'TemperatureEventsPage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'DeforestationOverTimePage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ForestsAndFiresPage_Report' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SalesPage_Report' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetReportQuery, GetReportQueryVariables>;
export const CreateMapShapeFromManageMapAreaButtonDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMapShapeFromManageMapAreaButton' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateMapShapeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMapShape' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMapShapeFromManageMapAreaButtonMutation,
  CreateMapShapeFromManageMapAreaButtonMutationVariables
>;
export const GetComparableSaleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetComparableSale' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'saleId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comparableSale' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'propertyId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'propertyId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'saleId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'saleId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ComparableSaleAsideLayoutComparableSale',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ComparableSaleAsideLayoutComparableSale' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComparableSale' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sale' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contractDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertiesInSale' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'property' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'addressLine1' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'propertyParcels' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parcel' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'hectares',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'latitude',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'longitude',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'legalNumber',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetComparableSaleQuery,
  GetComparableSaleQueryVariables
>;
export const GetPortfolioForLayoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPortfolioForLayout' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PortfolioLayoutPortals_Portfolio',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioLayoutPortals_Portfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolioProperties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'portfolioId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'property' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'centroid' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bbox' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPortfolioForLayoutQuery,
  GetPortfolioForLayoutQueryVariables
>;
export const GetPropertyForLayoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPropertyForLayout' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'property' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PropertyLayoutPortals_PropertyFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertyLayoutParcelGeometries_PropertyFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyParcels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parcelId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'geometry' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyLayoutPortals_PropertyFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'PropertyLayoutParcelGeometries_PropertyFragment',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPropertyForLayoutQuery,
  GetPropertyForLayoutQueryVariables
>;
export const UpdateFeedWeanerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFeedWeaner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateFeedInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFeedInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFeed' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateFeedInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateFeedInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FeedWeanerBlock_FeedFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedWeanerBlock_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weanerBlockKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'weanerBlockUreaPercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFeedWeanerMutation,
  UpdateFeedWeanerMutationVariables
>;
export const UpdateFeedDrySeasonMixDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFeedDrySeasonMix' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateFeedInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFeedInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFeed' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateFeedInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateFeedInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedDrySeasonMix_FeedFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedDrySeasonMix_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'drySeasonMixKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'drySeasonMixUreaPercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFeedDrySeasonMixMutation,
  UpdateFeedDrySeasonMixMutationVariables
>;
export const UpdateFeedMineralBlockDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFeedMineralBlock' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateFeedInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFeedInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFeed' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateFeedInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateFeedInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedMineralBlock_FeedFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedMineralBlock_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mineralBlockKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mineralBlockUreaPercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFeedMineralBlockMutation,
  UpdateFeedMineralBlockMutationVariables
>;
export const GetMapShapesForSelectMapShapeFromLibraryModalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetMapShapesForSelectMapShapeFromLibraryModal',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mapShapes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SelectMapShapeFromLibraryModal_MapShape',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MapShapesListItem_MapShape' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MapShape' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'geometry' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MapShapesList_MapShape' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MapShape' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'MapShapesListItem_MapShape' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SelectMapShapeFromLibraryModal_MapShape' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MapShape' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'geometry' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'MapShapesList_MapShape' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMapShapesForSelectMapShapeFromLibraryModalQuery,
  GetMapShapesForSelectMapShapeFromLibraryModalQueryVariables
>;
export const GetTeamsForAdvisorDashboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTeamsForAdvisorDashboard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'advisorTeams' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'AdvisorDashboardTeamsFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdvisorDashboardTeamsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Team' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organisation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTeamsForAdvisorDashboardQuery,
  GetTeamsForAdvisorDashboardQueryVariables
>;
export const CreateAdvisorInviteForUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createAdvisorInviteForUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createAdvisorInvitationInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateAdvisorInvitationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAdvisorInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createAdvisorInvitationInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateAdvisorInviteForUserMutation,
  CreateAdvisorInviteForUserMutationVariables
>;
export const GetPortfolioForEditDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPortfolioForEdit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PortfolioEditPage_PortfolioFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioEditPage_PortfolioFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolioProperties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'property' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'centroid' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'bbox' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPortfolioForEditQuery,
  GetPortfolioForEditQueryVariables
>;
export const GetPropertiesForAddToPortfolioDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPropertiesForAddToPortfolio' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'teamId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'teamId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'AllPropertiesForEditPortfolioFormFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AllPropertiesForEditPortfolioFormFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'centroid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bbox' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPropertiesForAddToPortfolioQuery,
  GetPropertiesForAddToPortfolioQueryVariables
>;
export const UpdatePortfolioDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePortfolio' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updatePortfolioInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePortfolioInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePortfolio' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updatePortfolioInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PortfolioEditPage_PortfolioFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioEditPage_PortfolioFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolioProperties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'property' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'centroid' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'bbox' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePortfolioMutation,
  UpdatePortfolioMutationVariables
>;
export const DeletePortfolioDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePortfolio' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePortfolio' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePortfolioMutation,
  DeletePortfolioMutationVariables
>;
export const GetPortfolioDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPortfolio' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PortfolioPage_PortfolioFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioPage_PortfolioFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
      },
    },
  ],
} as unknown as DocumentNode<GetPortfolioQuery, GetPortfolioQueryVariables>;
export const CreatePortfolioDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePortfolio' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePortfolioInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPortfolio' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePortfolioMutation,
  CreatePortfolioMutationVariables
>;
export const GetPortfoliosForTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPortfoliosForTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'teamId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfoliosForTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'teamId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TeamPortfoliosFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamPortfoliosFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolioProperties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPortfoliosForTeamQuery,
  GetPortfoliosForTeamQueryVariables
>;
export const GetPropertyForCmaChartsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPropertyForCmaCharts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'property' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CmaAssessmentAnalysisPage_Property',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecentSalesPriceChart_Property' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comparableSales' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'saleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'similarity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sale' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contractDate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecentSalesPricePerAreaChart_Property' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comparableSales' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'saleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'similarity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sale' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contractDate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyPastSalesTable_PropertySalesData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PropertySalesData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertySalesHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'NumberOfSalesOverTimeChart_PropertySalesData',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PropertySalesData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'summarySaleCountHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PricePerHectareOverTimeChart_PropertySalesData',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PropertySalesData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'summaryAveragePriceHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecentSalesPriceChart_PropertySalesData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PropertySalesData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'summaryPriceDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertySalesHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecentSalesPricePerAreaChart_PropertySalesData',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PropertySalesData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'summaryPricePerAreaDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertySalesHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CmaAssessmentAnalysisPage_Property' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'RecentSalesPriceChart_Property' },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'RecentSalesPricePerAreaChart_Property',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PropertyPastSalesTable_PropertySalesData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'NumberOfSalesOverTimeChart_PropertySalesData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PricePerHectareOverTimeChart_PropertySalesData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecentSalesPriceChart_PropertySalesData',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecentSalesPricePerAreaChart_PropertySalesData',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPropertyForCmaChartsQuery,
  GetPropertyForCmaChartsQueryVariables
>;
export const GetComparableSaleOnPropertyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetComparableSaleOnProperty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetComparableSaleInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comparableSale' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PropertyComparableSaleFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyComparableSaleFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComparableSale' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'saleId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sale' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contractDate' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landUses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landUse' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricePerHectare' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'property' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetComparableSaleOnPropertyQuery,
  GetComparableSaleOnPropertyQueryVariables
>;
export const UpdateComparableSaleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateComparableSale' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'saleId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateComparableSaleInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateComparableSale' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'saleId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'saleId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PropertyComparableSaleFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyComparableSaleFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComparableSale' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'saleId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sale' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contractDate' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landUses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landUse' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricePerHectare' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'property' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateComparableSaleMutation,
  UpdateComparableSaleMutationVariables
>;
export const GetPropertyComparableSalesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPropertyComparableSales' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'property' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CmaAssessmentPropertyComparableSalesFragment',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CmaAssessmentPropertyCMAVectorFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'CmaAssessmentPropertyComparableSalesFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comparableSales' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'similarity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'saleId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sale' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'externalId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contractDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'centroid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'bbox' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'geog' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'property' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CmaAssessmentPropertyCMAVectorFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmaVector' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPropertyComparableSalesQuery,
  GetPropertyComparableSalesQueryVariables
>;
export const GetPropertyComparableSaleSearchResultsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPropertyComparableSaleSearchResults' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'similarity' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Percent' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'radius' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'minPrice' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'maxPrice' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'minArea' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'maxArea' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ComparableSaleSearchOrderBy' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comparableSalesSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'similarity' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'similarity' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'radius' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'radius' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'minPrice' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'minPrice' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'maxPrice' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'maxPrice' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'minArea' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'minArea' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'maxArea' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'maxArea' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CmaAssessmentSalesSearchResults',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CmaAssessmentSalesSearchResults' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComparableSaleSearchResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'saleDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'boundingGeoJson' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bbox' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceRef' } },
          { kind: 'Field', name: { kind: 'Name', value: 'similarity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPropertyComparableSaleSearchResultsQuery,
  GetPropertyComparableSaleSearchResultsQueryVariables
>;
export const SaveComparableSalesForPropertyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'saveComparableSalesForProperty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'SaveComparableSaleInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveComparableSales' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'saleId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'property' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sale' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SaveComparableSalesForPropertyMutation,
  SaveComparableSalesForPropertyMutationVariables
>;
export const DeleteComparableSaleForPropertyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteComparableSaleForProperty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'saleId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteComparableSale' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'saleId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'saleId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'saleId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'property' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sale' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteComparableSaleForPropertyMutation,
  DeleteComparableSaleForPropertyMutationVariables
>;
export const UpdateCmaReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCmaReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateReportInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CmaAssessmentReportsPage_Report',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CmaAssessmentReportsEditPage_Report',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CmaReportListItem_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CmaAssessmentReportsPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CmaReportListItem_Report' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CmaAssessmentReportsEditPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sections' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCmaReportMutation,
  UpdateCmaReportMutationVariables
>;
export const DeleteCmaReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCmaReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCmaReportMutation,
  DeleteCmaReportMutationVariables
>;
export const GetCmaReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCmaReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CmaAssessmentReportsEditPage_Report',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CmaAssessmentReportsEditPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sections' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCmaReportQuery, GetCmaReportQueryVariables>;
export const GetCmaReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCmaReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cmaReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CmaAssessmentReportsPage_Report',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CmaReportListItem_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CmaAssessmentReportsPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CmaReportListItem_Report' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCmaReportsQuery, GetCmaReportsQueryVariables>;
export const GetCmaReportSignedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCmaReportSignedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CmaReportListItem_ReportPdfLink',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CmaReportListItem_ReportPdfLink' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signedPdfUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCmaReportSignedUrlQuery,
  GetCmaReportSignedUrlQueryVariables
>;
export const CreateCmaReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCmaReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateReportInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCmaReportMutation,
  CreateCmaReportMutationVariables
>;
export const UpdateDeforestationReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDeforestationReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateReportInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DeforestationAssessmentReportsPage_Report',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DeforestationAssessmentReportsEditPage_Report',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DeforestationReportListItem_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeforestationAssessmentReportsPage_Report',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'DeforestationReportListItem_Report' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeforestationAssessmentReportsEditPage_Report',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sections' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDeforestationReportMutation,
  UpdateDeforestationReportMutationVariables
>;
export const DeleteDeforestationReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteDeforestationReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteDeforestationReportMutation,
  DeleteDeforestationReportMutationVariables
>;
export const GetDeforestationReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDeforestationReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DeforestationAssessmentReportsEditPage_Report',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeforestationAssessmentReportsEditPage_Report',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sections' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDeforestationReportQuery,
  GetDeforestationReportQueryVariables
>;
export const GetDeforestationReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDeforestationReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deforestationReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DeforestationAssessmentReportsPage_Report',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DeforestationReportListItem_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeforestationAssessmentReportsPage_Report',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'DeforestationReportListItem_Report' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDeforestationReportsQuery,
  GetDeforestationReportsQueryVariables
>;
export const GetDeforestationReportSignedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDeforestationReportSignedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DeforestationReportListItem_ReportPdfLink',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeforestationReportListItem_ReportPdfLink',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signedPdfUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDeforestationReportSignedUrlQuery,
  GetDeforestationReportSignedUrlQueryVariables
>;
export const CreateDeforestationReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateDeforestationReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateReportInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDeforestationReportMutation,
  CreateDeforestationReportMutationVariables
>;
export const GetAllEmissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllEmissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EmissionsFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allEmissions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissionsAllPage_AllEmissionsCalculationResultFragment',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AllTotalGhgEmissions_AllEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AllEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carbonSequestration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchasedOffsets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'net' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AllBreakdownGhg_AllEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AllEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'AssessmentGhgEmissionsAllPage_AllEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AllEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'AllTotalGhgEmissions_AllEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'AllBreakdownGhg_AllEmissionsCalculationResultFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EmissionsCalculationInputs' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
                { kind: 'Field', name: { kind: 'Name', value: 'payload' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cropping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'croppingType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllEmissionsQuery,
  GetAllEmissionsQueryVariables
>;
export const GetBeefEmissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBeefEmissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EmissionsFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'beefEmissions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissionsBeefPage_SheepBeefEmissionsCalculationResultFragment',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'BeefTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carbonSequestration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'intermediate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'average' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intermediate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'beef' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'carbonSequestration' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'net' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'BeefEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'urineAndDungN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manureManagementCH4' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'entericCH4' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fertiliserN2O' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'savannahBurningN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'savannahBurningCH4' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fertiliser' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'purchasedMineralSupplementation',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'purchasedFeed' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'purchasedLivestock' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefBreakdownGhg_SheepBeefEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefIntensity_SheepBeefEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intensities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'beefIncludingSequestration' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'beefExcludingSequestration' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'AssessmentGhgEmissionsBeefPage_SheepBeefEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'BeefTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'BeefEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'BeefBreakdownGhg_SheepBeefEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'BeefIntensity_SheepBeefEmissionsCalculationResultFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EmissionsCalculationInputs' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
                { kind: 'Field', name: { kind: 'Name', value: 'payload' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cropping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'croppingType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBeefEmissionsQuery,
  GetBeefEmissionsQueryVariables
>;
export const GetBuffaloEmissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBuffaloEmissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EmissionsFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buffaloEmissions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissionsBuffaloPage_BuffaloEmissionsCalculationResultFragment',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'BuffaloTotalGhgEmissions_BuffaloEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BuffaloEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carbonSequestration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'intermediate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'average' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'net' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'BuffaloEmissionsBreakdown_BuffaloEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BuffaloEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transportCO2' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transportCH4' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'entericCH4' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manureManagementCH4' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manureManagementN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'animalWasteN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fertiliserN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'urineAndDungN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transportN2O' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fertiliser' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'purchasedFeed' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'purchasedLivestock' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BuffaloBreakdownGhg_BuffaloEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BuffaloEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BuffaloIntensity_BuffaloEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BuffaloEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intensities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'buffaloMeatIncludingSequestration',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'buffaloMeatExcludingSequestration',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'AssessmentGhgEmissionsBuffaloPage_BuffaloEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BuffaloEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'BuffaloTotalGhgEmissions_BuffaloEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'BuffaloEmissionsBreakdown_BuffaloEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'BuffaloBreakdownGhg_BuffaloEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'BuffaloIntensity_BuffaloEmissionsCalculationResultFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EmissionsCalculationInputs' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
                { kind: 'Field', name: { kind: 'Name', value: 'payload' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cropping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'croppingType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBuffaloEmissionsQuery,
  GetBuffaloEmissionsQueryVariables
>;
export const GetCottonEmissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCottonEmissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EmissionsFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cottonEmissions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissionsCottonPage_CottonEmissionsCalculationResultFragment',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'CottonTotalGhgEmissions_CottonEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CottonEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carbonSequestration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'net' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'CottonEmissionsBreakdown_CottonEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CottonEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cropResidueN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fertiliserN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fieldBurningCH4' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fieldBurningN2O' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fertiliser' } },
                { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'CottonBreakdownGhg_CottonEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CottonEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'CottonIntensity_CottonEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CottonEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intensities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'balesExcludingSequestration' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'balesIncludingSequestration' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'tonnesCropExcludingSequestration',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'tonnesCropIncludingSequestration',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lintIncludingSequestration' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lintExcludingSequestration' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'seedIncludingSequestration' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'seedExcludingSequestration' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lintEconomicAllocation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'seedEconomicAllocation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'AssessmentGhgEmissionsCottonPage_CottonEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CottonEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'CottonTotalGhgEmissions_CottonEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'CottonEmissionsBreakdown_CottonEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'CottonBreakdownGhg_CottonEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'CottonIntensity_CottonEmissionsCalculationResultFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EmissionsCalculationInputs' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
                { kind: 'Field', name: { kind: 'Name', value: 'payload' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cropping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'croppingType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCottonEmissionsQuery,
  GetCottonEmissionsQueryVariables
>;
export const GetDairyEmissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDairyEmissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EmissionsFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dairyEmissions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissionsDairyPage_DairyEmissionsCalculationResultFragment',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DairyTotalGhgEmissions_DairyEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carbonSequestration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'intermediate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'average' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'net' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'DairyEmissionsBreakdown_DairyEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transportCO2' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transportCH4' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'entericCH4' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manureManagementCH4' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manureManagementN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'animalWasteN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fertiliserN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'urineAndDungN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transportN2O' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fertiliser' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'purchasedFeed' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DairyBreakdownGhg_DairyEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DairyIntensity_DairyEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intensities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'AssessmentGhgEmissionsDairyPage_DairyEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'DairyTotalGhgEmissions_DairyEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'DairyEmissionsBreakdown_DairyEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'DairyBreakdownGhg_DairyEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'DairyIntensity_DairyEmissionsCalculationResultFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EmissionsCalculationInputs' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
                { kind: 'Field', name: { kind: 'Name', value: 'payload' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cropping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'croppingType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDairyEmissionsQuery,
  GetDairyEmissionsQueryVariables
>;
export const GetDeerEmissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDeerEmissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EmissionsFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deerEmissions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissionsDeerPage_DeerEmissionsCalculationResultFragment',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeerTotalGhgEmissions_DeerEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carbonSequestration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'intermediate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'average' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'net' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeerEmissionsBreakdown_DeerEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transportCO2' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transportCH4' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'entericCH4' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manureManagementCH4' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manureManagementN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'animalWasteN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fertiliserN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'urineAndDungN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transportN2O' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fertiliser' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'purchasedFeed' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'purchasedLivestock' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeerBreakdownGhg_DeerEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeerIntensity_DeerEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intensities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'deerMeatIncludingSequestration',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'deerMeatExcludingSequestration',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'AssessmentGhgEmissionsDeerPage_DeerEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'DeerTotalGhgEmissions_DeerEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'DeerEmissionsBreakdown_DeerEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'DeerBreakdownGhg_DeerEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'DeerIntensity_DeerEmissionsCalculationResultFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EmissionsCalculationInputs' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
                { kind: 'Field', name: { kind: 'Name', value: 'payload' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cropping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'croppingType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDeerEmissionsQuery,
  GetDeerEmissionsQueryVariables
>;
export const GetFeedlotEmissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFeedlotEmissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EmissionsFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedlotEmissions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissionsFeedlotPage_FeedlotEmissionsCalculationResultFragment',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'FeedlotTotalGhgEmissions_FeedlotEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intermediate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'carbonSequestration' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carbonSequestration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'net' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'FeedlotEmissionsBreakdown_FeedlotEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'entericCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manureAppliedToSoilN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manureDirectN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manureIndirectN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manureManagementCH4' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transportCH4' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transportCO2' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transportN2O' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fertiliser' } },
                { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'feed' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'purchaseLivestock' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'FeedlotBreakdownGhg_FeedlotEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'FeedlotIntensity_FeedlotEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intensities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'beefIncludingSequestration' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'beefExcludingSequestration' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'AssessmentGhgEmissionsFeedlotPage_FeedlotEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'FeedlotTotalGhgEmissions_FeedlotEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'FeedlotEmissionsBreakdown_FeedlotEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'FeedlotBreakdownGhg_FeedlotEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'FeedlotIntensity_FeedlotEmissionsCalculationResultFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EmissionsCalculationInputs' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
                { kind: 'Field', name: { kind: 'Name', value: 'payload' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cropping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'croppingType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFeedlotEmissionsQuery,
  GetFeedlotEmissionsQueryVariables
>;
export const GetGoatEmissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGoatEmissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EmissionsFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goatEmissions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissionsGoatPage_GoatEmissionsCalculationResultFragment',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatTotalGhgEmissions_GoatEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carbonSequestration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'intermediate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'average' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'net' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatEmissionsBreakdown_GoatEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fertiliserN2O' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'entericCH4' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manureManagementCH4' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'urineAndDungN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fertiliser' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'purchasedMineralSupplementation',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'purchasedFeed' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'purchasedLivestock' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatBreakdownGhg_GoatEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatIntensity_GoatEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intensities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'goatMeatBreedingIncludingSequestration',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'goatMeatBreedingExcludingSequestration',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'woolIncludingSequestration' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'woolExcludingSequestration' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'AssessmentGhgEmissionsGoatPage_GoatEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'GoatTotalGhgEmissions_GoatEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'GoatEmissionsBreakdown_GoatEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'GoatBreakdownGhg_GoatEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'GoatIntensity_GoatEmissionsCalculationResultFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EmissionsCalculationInputs' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
                { kind: 'Field', name: { kind: 'Name', value: 'payload' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cropping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'croppingType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGoatEmissionsQuery,
  GetGoatEmissionsQueryVariables
>;
export const GetGrainsEmissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGrainsEmissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EmissionsFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsEmissions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissionsGrainsPage_GrainsEmissionsCalculationResultFragment',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'GrainsTotalGhgEmissions_GrainsEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GrainsEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carbonSequestration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'net' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'GrainsEmissionsBreakdown_GrainsEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GrainsEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cropResidueN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fertiliserN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fieldBurningCH4' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fieldBurningN2O' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fertiliser' } },
                { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GrainsBreakdownGhg_GrainsEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GrainsEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GrainsIntensity_GrainsEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GrainsEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intensitiesWithSequestration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grainsIncludingSequestration' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grainsExcludingSequestration' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'AssessmentGhgEmissionsGrainsPage_GrainsEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GrainsEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'GrainsTotalGhgEmissions_GrainsEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'GrainsEmissionsBreakdown_GrainsEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'GrainsBreakdownGhg_GrainsEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'GrainsIntensity_GrainsEmissionsCalculationResultFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EmissionsCalculationInputs' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
                { kind: 'Field', name: { kind: 'Name', value: 'payload' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cropping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'croppingType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGrainsEmissionsQuery,
  GetGrainsEmissionsQueryVariables
>;
export const GetHorticultureEmissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHorticultureEmissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EmissionsFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'horticultureEmissions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissionsHorticulturePage_HorticultureEmissionsCalculationResultFragment',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'HorticultureTotalGhgEmissions_HorticultureEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HorticultureEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carbonSequestration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'intermediate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'average' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'net' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'HorticultureEmissionsBreakdown_HorticultureEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HorticultureEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cropResidueN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fertiliserN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fieldBurningCH4' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fieldBurningN2O' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fertiliser' } },
                { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'HorticultureBreakdownGhg_HorticultureEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HorticultureEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'HorticultureIntensity_HorticultureEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HorticultureEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intensities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'tonnesCropIncludingSequestration',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'tonnesCropExcludingSequestration',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'AssessmentGhgEmissionsHorticulturePage_HorticultureEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HorticultureEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'HorticultureTotalGhgEmissions_HorticultureEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'HorticultureEmissionsBreakdown_HorticultureEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'HorticultureBreakdownGhg_HorticultureEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'HorticultureIntensity_HorticultureEmissionsCalculationResultFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EmissionsCalculationInputs' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
                { kind: 'Field', name: { kind: 'Name', value: 'payload' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cropping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'croppingType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHorticultureEmissionsQuery,
  GetHorticultureEmissionsQueryVariables
>;
export const GetPropertyForEmissionsAssessmentLayoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPropertyForEmissionsAssessmentLayout' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'property' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmissionsAssessmentLayout_PropertyFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmissionsAssessmentLayout_PropertyFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPropertyForEmissionsAssessmentLayoutQuery,
  GetPropertyForEmissionsAssessmentLayoutQueryVariables
>;
export const GetPorkEmissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPorkEmissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EmissionsFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'porkEmissions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissionsPorkPage_PorkEmissionsCalculationResultFragment',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PorkTotalGhgEmissions_PorkEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carbonSequestration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'intermediate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'average' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'net' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PorkEmissionsBreakdown_PorkEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fertiliserN2O' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'entericCH4' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manureManagementCH4' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manureManagementDirectN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'atmosphericDepositionIndirectN2O',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leachingAndRunoffSoilN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leachingAndRunoffMMSN2O' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fertiliser' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'purchasedFeed' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bedding' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'purchasedLivestock' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PorkBreakdownGhg_PorkEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PorkIntensity_PorkEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intensities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'porkMeatIncludingSequestration',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'porkMeatExcludingSequestration',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'AssessmentGhgEmissionsPorkPage_PorkEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'PorkTotalGhgEmissions_PorkEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'PorkEmissionsBreakdown_PorkEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'PorkBreakdownGhg_PorkEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'PorkIntensity_PorkEmissionsCalculationResultFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EmissionsCalculationInputs' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
                { kind: 'Field', name: { kind: 'Name', value: 'payload' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cropping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'croppingType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPorkEmissionsQuery,
  GetPorkEmissionsQueryVariables
>;
export const GetPoultryBroilerEmissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPoultryBroilerEmissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EmissionsFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poultryBroilerEmissions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissionsPoultryBroilerPage_PoultryBroilerEmissionsCalculationResultFragment',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'PoultryBroilerTotalGhgEmissions_PoultryBroilerEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carbonSequestration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'intermediate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'average' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'net' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'PoultryBroilerEmissionsBreakdown_PoultryBroilerEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manureManagementCH4' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manureManagementN2O' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'purchasedFeed' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'purchasedHay' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'purchasedLivestock' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'PoultryBroilerBreakdownGhg_PoultryBroilerEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'PoultryBroilerIntensity_PoultryBroilerEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intensities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'poultryMeatIncludingSequestration',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'poultryMeatExcludingSequestration',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'AssessmentGhgEmissionsPoultryBroilerPage_PoultryBroilerEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'PoultryBroilerTotalGhgEmissions_PoultryBroilerEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'PoultryBroilerEmissionsBreakdown_PoultryBroilerEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'PoultryBroilerBreakdownGhg_PoultryBroilerEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'PoultryBroilerIntensity_PoultryBroilerEmissionsCalculationResultFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EmissionsCalculationInputs' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
                { kind: 'Field', name: { kind: 'Name', value: 'payload' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cropping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'croppingType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPoultryBroilerEmissionsQuery,
  GetPoultryBroilerEmissionsQueryVariables
>;
export const GetPoultryLayerEmissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPoultryLayerEmissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EmissionsFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poultryLayerEmissions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissionsPoultryLayerPage_PoultryLayerEmissionsCalculationResultFragment',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'PoultryLayerTotalGhgEmissions_PoultryLayerEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carbonSequestration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'intermediate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'average' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'net' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'PoultryLayerEmissionsBreakdown_PoultryLayerEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manureManagementCH4' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manureManagementN2O' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'purchasedFeed' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'purchasedHay' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'purchasedLivestock' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'PoultryLayerBreakdownGhg_PoultryLayerEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'PoultryLayerIntensity_PoultryLayerEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intensities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'poultryEggsIncludingSequestration',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'poultryEggsExcludingSequestration',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'AssessmentGhgEmissionsPoultryLayerPage_PoultryLayerEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'PoultryLayerTotalGhgEmissions_PoultryLayerEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'PoultryLayerEmissionsBreakdown_PoultryLayerEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'PoultryLayerBreakdownGhg_PoultryLayerEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'PoultryLayerIntensity_PoultryLayerEmissionsCalculationResultFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EmissionsCalculationInputs' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
                { kind: 'Field', name: { kind: 'Name', value: 'payload' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cropping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'croppingType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPoultryLayerEmissionsQuery,
  GetPoultryLayerEmissionsQueryVariables
>;
export const GetSheepEmissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSheepEmissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EmissionsFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheepEmissions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissionsSheepPage_SheepBeefEmissionsCalculationResultFragment',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'SheepTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carbonSequestration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'intermediate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'average' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intermediate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sheep' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'carbonSequestration' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'net' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'SheepEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'urineAndDungN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manureManagementCH4' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'entericCH4' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fertiliserN2O' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'savannahBurningN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'savannahBurningCH4' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fertiliser' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'purchasedMineralSupplementation',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'purchasedFeed' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'purchasedLivestock' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepBreakdownGhg_SheepBeefEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepIntensity_SheepBeefEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intensities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'sheepMeatBreedingIncludingSequestration',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'sheepMeatBreedingExcludingSequestration',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'woolIncludingSequestration' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'woolExcludingSequestration' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'AssessmentGhgEmissionsSheepPage_SheepBeefEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepBeefEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'SheepTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'SheepEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'SheepBreakdownGhg_SheepBeefEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'SheepIntensity_SheepBeefEmissionsCalculationResultFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EmissionsCalculationInputs' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
                { kind: 'Field', name: { kind: 'Name', value: 'payload' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cropping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'croppingType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSheepEmissionsQuery,
  GetSheepEmissionsQueryVariables
>;
export const GetSugarEmissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSugarEmissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EmissionsFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sugarEmissions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissionsSugarPage_SugarEmissionsCalculationResultFragment',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SugarTotalGhgEmissions_SugarEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SugarEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carbonSequestration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'net' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'SugarEmissionsBreakdown_SugarEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SugarEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ureaCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limeCO2' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fertiliserN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'atmosphericDepositionN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leachingAndRunoffN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cropResidueN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fieldBurningN2O' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fieldBurningCH4' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fertiliser' } },
                { kind: 'Field', name: { kind: 'Name', value: 'herbicide' } },
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lime' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SugarBreakdownGhg_SugarEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SugarEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SugarIntensity_SugarEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SugarEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intensities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sugarIncludingSequestration' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sugarExcludingSequestration' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'AssessmentGhgEmissionsSugarPage_SugarEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SugarEmissionsCalculationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'SugarTotalGhgEmissions_SugarEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'SugarEmissionsBreakdown_SugarEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'SugarBreakdownGhg_SugarEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'SugarIntensity_SugarEmissionsCalculationResultFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EmissionsCalculationInputs' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
                { kind: 'Field', name: { kind: 'Name', value: 'payload' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cropping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'croppingType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSugarEmissionsQuery,
  GetSugarEmissionsQueryVariables
>;
export const GetWildSeaFisheryEmissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWildSeaFisheryEmissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EmissionsFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wildSeaFisheryEmissions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissionsWildSeaFisheryPage_WildSeaFisheryEmissionsCalculationResultFragment',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'WildSeaFisheryTotalGhgEmissions_WildSeaFisheryEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'WildSeaFisheryEmissionsCalculationResult',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'net' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchasedOffsets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'WildSeaFisheryEmissionsBreakdown_WildSeaFisheryEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'WildSeaFisheryEmissionsCalculationResult',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuelN2O' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hfcsRefrigerantLeakage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'electricity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fuel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bait' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'WildSeaFisheryBreakdownGhg_WildSeaFisheryEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'WildSeaFisheryEmissionsCalculationResult',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCO2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCH4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalN2O' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalHFCs' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'WildSeaFisheryIntensity_WildSeaFisheryEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'WildSeaFisheryEmissionsCalculationResult',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intensities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'intensityIncludingCarbonOffset',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'intensityExcludingCarbonOffset',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'AssessmentGhgEmissionsWildSeaFisheryPage_WildSeaFisheryEmissionsCalculationResultFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'WildSeaFisheryEmissionsCalculationResult',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'WildSeaFisheryTotalGhgEmissions_WildSeaFisheryEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'WildSeaFisheryEmissionsBreakdown_WildSeaFisheryEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'WildSeaFisheryBreakdownGhg_WildSeaFisheryEmissionsCalculationResultFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'WildSeaFisheryIntensity_WildSeaFisheryEmissionsCalculationResultFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AssessmentGhgEmissions_EmissionsCalculationInputsFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EmissionsCalculationInputs' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
                { kind: 'Field', name: { kind: 'Name', value: 'payload' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cropping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'croppingType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWildSeaFisheryEmissionsQuery,
  GetWildSeaFisheryEmissionsQueryVariables
>;
export const UpdateKyeReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateKyeReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateReportInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'KyeAssessmentReportsPage_Report',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'KyeAssessmentReportsEditPage_Report',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KyeReportListItem_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KyeAssessmentReportsPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'KyeReportListItem_Report' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KyeAssessmentReportsEditPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sections' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateKyeReportMutation,
  UpdateKyeReportMutationVariables
>;
export const DeleteKyeReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteKyeReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteKyeReportMutation,
  DeleteKyeReportMutationVariables
>;
export const GetKyeReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetKyeReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'KyeAssessmentReportsEditPage_Report',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KyeAssessmentReportsEditPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sections' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetKyeReportQuery, GetKyeReportQueryVariables>;
export const GetKyeReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetKyeReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kyeReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'KyeAssessmentReportsPage_Report',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KyeReportListItem_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KyeAssessmentReportsPage_Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'KyeReportListItem_Report' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetKyeReportsQuery, GetKyeReportsQueryVariables>;
export const GetKyeReportSignedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetKyeReportSignedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'KyeReportListItem_ReportPdfLink',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KyeReportListItem_ReportPdfLink' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signedPdfUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetKyeReportSignedUrlQuery,
  GetKyeReportSignedUrlQueryVariables
>;
export const CreateKyeReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateKyeReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateReportInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateKyeReportMutation,
  CreateKyeReportMutationVariables
>;
export const GetSourceActivityAllocationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSourceActivityAllocations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesAllocationIndexFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertiesBusinessActivitiesAllocationIndexFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Activity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourceActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'targetActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CroppingActivity' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'croppingType' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSourceActivityAllocationsQuery,
  GetSourceActivityAllocationsQueryVariables
>;
export const GetActivitiesToAllocateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetActivitiesToAllocate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchParams' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ActivityQueryParamsInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchParams' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'AllocationIndexActivitiesFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllocationIndexActivitiesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Activity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourceActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'targetActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CroppingActivity' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'croppingType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'varieties' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'yieldInKg' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetActivitiesToAllocateQuery,
  GetActivitiesToAllocateQueryVariables
>;
export const GetAllocationConsumptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllocationConsumptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumptions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'BusinessActivitiesAllocationIndexConsumptionsFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BusinessActivitiesAllocationIndexConsumptionsFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Consumption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumableType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumableSubType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumptionAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourceConsumption' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'targetActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllocationConsumptionsQuery,
  GetAllocationConsumptionsQueryVariables
>;
export const GetConsumptionForAllocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetConsumptionForAllocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'BusinessActivitiesAllocationConsumptionEditFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BusinessActivitiesAllocationConsumptionEditFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Consumption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumableType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumableSubType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumptionAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourceConsumption' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'targetActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetConsumptionForAllocationQuery,
  GetConsumptionForAllocationQueryVariables
>;
export const ActivityTargetActivitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ActivityTargetActivities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchParams' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ActivityQueryParamsInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchParams' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'AllocateToActivitiesComponentActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AllocateToActivitiesComponentActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Activity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CroppingActivity' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'croppingType' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ActivityTargetActivitiesQuery,
  ActivityTargetActivitiesQueryVariables
>;
export const GetConsumptionTargetActivitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetConsumptionTargetActivities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchParams' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ActivityQueryParamsInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchParams' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'AllocateToActivitiesComponentActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AllocateToActivitiesComponentActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Activity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CroppingActivity' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'croppingType' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetConsumptionTargetActivitiesQuery,
  GetConsumptionTargetActivitiesQueryVariables
>;
export const UpdateConsumptionAllocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateConsumptionAllocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateConsumptionInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateConsumptionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateConsumption' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateConsumptionInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateConsumptionInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BusinessActivitiesConsumptionIndexFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BusinessActivitiesConsumptionIndexFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Consumption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumableType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumableSubType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumptionAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'targetActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourceConsumption' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateConsumptionAllocationMutation,
  UpdateConsumptionAllocationMutationVariables
>;
export const UpdateCropAllocationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCropAllocations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sourceActivityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateActivityAllocationsInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateActivityAllocationsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateActivityAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sourceActivityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sourceActivityId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateActivityAllocationsInput' },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updateActivityAllocationsInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourceActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'targetActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCropAllocationsMutation,
  UpdateCropAllocationsMutationVariables
>;
export const UpdateConsumptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateConsumption' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateConsumptionInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateConsumptionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateConsumption' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateConsumptionInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateConsumptionInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BusinessActivitiesConsumptionIndexFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BusinessActivitiesConsumptionIndexFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Consumption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumableType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumableSubType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumptionAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'targetActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourceConsumption' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateConsumptionMutation,
  UpdateConsumptionMutationVariables
>;
export const CreateConsumptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateConsumption' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createConsumptionInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateConsumptionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createConsumption' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createConsumptionInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createConsumptionInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BusinessActivitiesConsumptionIndexFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BusinessActivitiesConsumptionIndexFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Consumption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumableType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumableSubType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumptionAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'targetActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourceConsumption' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateConsumptionMutation,
  CreateConsumptionMutationVariables
>;
export const DeleteConsumptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteConsumption' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteConsumption' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteConsumptionMutation,
  DeleteConsumptionMutationVariables
>;
export const GetConsumptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetConsumptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumptions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'BusinessActivitiesConsumptionIndexConsumptionsFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BusinessActivitiesConsumptionIndexConsumptionsFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Consumption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumableType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumableSubType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetConsumptionsQuery,
  GetConsumptionsQueryVariables
>;
export const GetConsumptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetConsumption' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BusinessActivitiesConsumptionIndexFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BusinessActivitiesConsumptionIndexFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Consumption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumableType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumableSubType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumptionAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'targetActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourceConsumption' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetConsumptionQuery, GetConsumptionQueryVariables>;
export const GetActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ActivitiesActivityEditFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivitiesActivityEditFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Activity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CroppingActivity' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'croppingType' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetActivityQuery, GetActivityQueryVariables>;
export const UpdateActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateActivityInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateActivityInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateActivityMutation,
  UpdateActivityMutationVariables
>;
export const DeleteActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteActivityMutation,
  DeleteActivityMutationVariables
>;
export const UpdateBeefMonthLivestockActivityForMonthlyBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'updateBeefMonthLivestockActivityForMonthlyBreakdown',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'updateBeefMonthLivestockActivityInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateBeefMonthLivestockActivityInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBeefMonthLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updateBeefMonthLivestockActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updateBeefMonthLivestockActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BeefMonthlyBreakdown_BeefMonthLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefMonthlyForm_BeefMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previousMonthLiveweightKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageCowsCalving' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefMonthlyBreakdown_BeefMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'BeefMonthlyForm_BeefMonthLivestockActivity',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBeefMonthLivestockActivityForMonthlyBreakdownMutation,
  UpdateBeefMonthLivestockActivityForMonthlyBreakdownMutationVariables
>;
export const CreateBeefLivestockTransactionEventForUpdateTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'createBeefLivestockTransactionEventForUpdateTransaction',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'beefMonthLivestockActivityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'createLivestockTransactionEventInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreateLivestockTransactionEventInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'createBeefLivestockTransactionEvent',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'beefMonthLivestockActivityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'beefMonthLivestockActivityId' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'createLivestockTransactionEventInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'createLivestockTransactionEventInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'UpdateTransaction_LivestockTransactionEventFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_LivestockTransactionEventFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LivestockTransactionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionRegion' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headPurchasedMerino' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UpdateTransaction_LivestockTransactionEventFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LivestockTransactionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'TransactionForm_LivestockTransactionEventFragment',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateBeefLivestockTransactionEventForUpdateTransactionMutation,
  CreateBeefLivestockTransactionEventForUpdateTransactionMutationVariables
>;
export const GetBeefActivityForMonthlyBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBeefActivityForMonthlyBreakdown' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'beefLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BeefMonthlyBreakdown_BeefLivestockActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefMonthlyBreakdown_BeefLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bullsGt1Breeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsGt2Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifersLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifers1to2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifersGt2Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'steersLt1Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'steers1to2Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'steersGt2Breeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bullsGt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsGt2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1Trading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifers1to2Trading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersLt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steers1to2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersGt2Trading' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBeefActivityForMonthlyBreakdownQuery,
  GetBeefActivityForMonthlyBreakdownQueryVariables
>;
export const GetBeefMonthLivestockActivitiesForMonthlyBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetBeefMonthLivestockActivitiesForMonthlyBreakdown',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'listBeefMonthLivestockActivitiesInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ListBeefMonthLivestockActivitiesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'beefMonthLivestockActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'listBeefMonthLivestockActivitiesInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'listBeefMonthLivestockActivitiesInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BeefMonthlyBreakdown_BeefMonthLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefMonthlyForm_BeefMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previousMonthLiveweightKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageCowsCalving' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefMonthlyBreakdown_BeefMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'BeefMonthlyForm_BeefMonthLivestockActivity',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBeefMonthLivestockActivitiesForMonthlyBreakdownQuery,
  GetBeefMonthLivestockActivitiesForMonthlyBreakdownQueryVariables
>;
export const GetBeefMonthLivestockActivityForCreateUpdateTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetBeefMonthLivestockActivityForCreateUpdateTransaction',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'beefMonthLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'CreateTransaction_BeefMonthLivestockActivityFragment',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'UpdateTransaction_BeefMonthLivestockActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_BeefMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beefClass' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'CreateTransaction_BeefMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'TransactionForm_BeefMonthLivestockActivityFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UpdateTransaction_BeefMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'TransactionForm_BeefMonthLivestockActivityFragment',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBeefMonthLivestockActivityForCreateUpdateTransactionQuery,
  GetBeefMonthLivestockActivityForCreateUpdateTransactionQueryVariables
>;
export const UpdateBeefMonthLivestockActivityForMonthlyFeedBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'updateBeefMonthLivestockActivityForMonthlyFeedBreakdown',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'updateBeefMonthLivestockActivityInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateBeefMonthLivestockActivityInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBeefMonthLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updateBeefMonthLivestockActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updateBeefMonthLivestockActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'UseBeefMonthlyFeedBreakdown_BeefMonthLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseBeefMonthlyFeedBreakdown_BeefMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crudeProteinPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dryMatterDigestibilityPercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBeefMonthLivestockActivityForMonthlyFeedBreakdownMutation,
  UpdateBeefMonthLivestockActivityForMonthlyFeedBreakdownMutationVariables
>;
export const GetBeefActivityForMonthlyFeedBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBeefActivityForMonthlyFeedBreakdown' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'beefLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseBeefMonthlyFeedBreakdown_ActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseBeefMonthlyFeedBreakdown_ActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bullsGt1Breeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsGt2Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifersLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifers1to2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifersGt2Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'steersLt1Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'steers1to2Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'steersGt2Breeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bullsGt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsGt2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1Trading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifers1to2Trading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersLt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steers1to2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersGt2Trading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBeefActivityForMonthlyFeedBreakdownQuery,
  GetBeefActivityForMonthlyFeedBreakdownQueryVariables
>;
export const GetBeefMonthLivestockActivitiesForMonthlyFeedBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetBeefMonthLivestockActivitiesForMonthlyFeedBreakdown',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'listBeefMonthLivestockActivitiesInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ListBeefMonthLivestockActivitiesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'beefMonthLivestockActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'listBeefMonthLivestockActivitiesInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'listBeefMonthLivestockActivitiesInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'UseBeefMonthlyFeedBreakdown_BeefMonthLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseBeefMonthlyFeedBreakdown_BeefMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crudeProteinPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dryMatterDigestibilityPercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBeefMonthLivestockActivitiesForMonthlyFeedBreakdownQuery,
  GetBeefMonthLivestockActivitiesForMonthlyFeedBreakdownQueryVariables
>;
export const GetBeefLivestockActivityFeedDrySeasonMixDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBeefLivestockActivityFeedDrySeasonMix' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'beefLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BeefFeedDrySeasonMix_BeefLivestockActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedDrySeasonMix_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'drySeasonMixKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'drySeasonMixUreaPercentage' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefFeedDrySeasonMix_BeefLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedDrySeasonMix_FeedFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBeefLivestockActivityFeedDrySeasonMixQuery,
  GetBeefLivestockActivityFeedDrySeasonMixQueryVariables
>;
export const GetBeefActivityFeedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBeefActivityFeed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'beefLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'BeefActivityLivestockFeed_BeefLivestockActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefActivityLivestockFeed_BeefLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bullsGt1Breeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsGt2Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifersLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifers1to2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifersGt2Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'steersLt1Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'steers1to2Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'steersGt2Breeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bullsGt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsGt2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1Trading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifers1to2Trading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersLt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steers1to2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersGt2Trading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grazingCrops' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mineralBlock' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'weanerBlock' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'drySeasonMix' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBeefActivityFeedQuery,
  GetBeefActivityFeedQueryVariables
>;
export const GetBeefLivestockActivityFeedMineralBlockDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBeefLivestockActivityFeedMineralBlock' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'beefLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BeefFeedMineralBlock_BeefLivestockActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedMineralBlock_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mineralBlockKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mineralBlockUreaPercentage' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefFeedMineralBlock_BeefLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedMineralBlock_FeedFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBeefLivestockActivityFeedMineralBlockQuery,
  GetBeefLivestockActivityFeedMineralBlockQueryVariables
>;
export const GetBeefLivestockActivityFeedWeanerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBeefLivestockActivityFeedWeaner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'beefLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BeefFeedWeanerBlock_BeefLivestockActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedWeanerBlock_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weanerBlockKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'weanerBlockUreaPercentage' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefFeedWeanerBlock_BeefLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FeedWeanerBlock_FeedFragment' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBeefLivestockActivityFeedWeanerQuery,
  GetBeefLivestockActivityFeedWeanerQueryVariables
>;
export const UpdateBeefLivestockActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateBeefLivestockActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateBeefLivestockActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateBeefLivestockActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBeefLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updateBeefLivestockActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updateBeefLivestockActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BeefActivityLivestockBreedingBeefLivestockActivity',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BeefActivityLivestockTradingBeefLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefActivityLivestockBreedingBeefLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bullsGt1Breeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsGt2Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifersLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifers1to2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifersGt2Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'steersLt1Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'steers1to2Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'steersGt2Breeding' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefActivityLivestockTradingBeefLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bullsGt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsGt2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1Trading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifers1to2Trading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersLt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steers1to2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersGt2Trading' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBeefLivestockActivityMutation,
  UpdateBeefLivestockActivityMutationVariables
>;
export const GetBeefActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBeefActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'beefLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BeefActivityLivestockBeefLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefActivityLivestockBreedingBeefLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bullsGt1Breeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsGt2Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifersLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifers1to2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifersGt2Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'steersLt1Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'steers1to2Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'steersGt2Breeding' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefActivityLivestockTradingBeefLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bullsGt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsGt2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1Trading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heifers1to2Trading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersLt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steers1to2Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersGt2Trading' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BeefActivityLivestockBeefLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'BeefActivityLivestockBreedingBeefLivestockActivity',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'BeefActivityLivestockTradingBeefLivestockActivity',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBeefActivityQuery,
  GetBeefActivityQueryVariables
>;
export const GetBeefActivityInHeaderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBeefActivityInHeader' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'beefLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesActivitiesHeaderBeefActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertiesBusinessActivitiesActivitiesHeaderBeefActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBeefActivityInHeaderQuery,
  GetBeefActivityInHeaderQueryVariables
>;
export const GetBeefMonthLivestockActivitiesForActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetBeefMonthLivestockActivitiesForActivity',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'listBeefMonthLivestockActivitiesInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ListBeefMonthLivestockActivitiesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'beefMonthLivestockActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'listBeefMonthLivestockActivitiesInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'listBeefMonthLivestockActivitiesInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BeefLivestockActivity_MonthActivities',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BeefLivestockActivity_MonthActivities' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BeefMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightKg' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beefClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBeefMonthLivestockActivitiesForActivityQuery,
  GetBeefMonthLivestockActivitiesForActivityQueryVariables
>;
export const CreateBeefLivestockActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createBeefLivestockActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createBeefLivestockActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateBeefLivestockActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBeefLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'createBeefLivestockActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'createBeefLivestockActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateBeefLivestockActivityMutation,
  CreateBeefLivestockActivityMutationVariables
>;
export const UpdateBuffaloMonthLivestockActivityForMonthlyBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'updateBuffaloMonthLivestockActivityForMonthlyBreakdown',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'updateBuffaloMonthLivestockActivityInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateBuffaloMonthLivestockActivityInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'updateBuffaloMonthLivestockActivity',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updateBuffaloMonthLivestockActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updateBuffaloMonthLivestockActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'BuffaloMonthlyBreakdown_BuffaloMonthLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BuffaloMonthlyForm_BuffaloMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BuffaloMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageCowsCalving' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'seasonalCalvingRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BuffaloMonthlyBreakdown_BuffaloMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BuffaloMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'BuffaloMonthlyForm_BuffaloMonthLivestockActivity',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBuffaloMonthLivestockActivityForMonthlyBreakdownMutation,
  UpdateBuffaloMonthLivestockActivityForMonthlyBreakdownMutationVariables
>;
export const CreateBuffaloLivestockTransactionEventForUpdateTransactionDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'mutation',
        name: {
          kind: 'Name',
          value: 'createBuffaloLivestockTransactionEventForUpdateTransaction',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'buffaloMonthLivestockActivityId' },
            },
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: {
                kind: 'Name',
                value: 'createLivestockTransactionEventInput',
              },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'CreateLivestockTransactionEventInput',
                },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: {
                kind: 'Name',
                value: 'createBuffaloLivestockTransactionEvent',
              },
              arguments: [
                {
                  kind: 'Argument',
                  name: {
                    kind: 'Name',
                    value: 'buffaloMonthLivestockActivityId',
                  },
                  value: {
                    kind: 'Variable',
                    name: {
                      kind: 'Name',
                      value: 'buffaloMonthLivestockActivityId',
                    },
                  },
                },
                {
                  kind: 'Argument',
                  name: {
                    kind: 'Name',
                    value: 'createLivestockTransactionEventInput',
                  },
                  value: {
                    kind: 'Variable',
                    name: {
                      kind: 'Name',
                      value: 'createLivestockTransactionEventInput',
                    },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'UpdateTransaction_LivestockTransactionEventFragment',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'TransactionForm_LivestockTransactionEventFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'LivestockTransactionEvent' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
            { kind: 'Field', name: { kind: 'Name', value: 'livestockCount' } },
            { kind: 'Field', name: { kind: 'Name', value: 'totalWeightKg' } },
            { kind: 'Field', name: { kind: 'Name', value: 'averageWeightKg' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'transactionRegion' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'headPurchasedMerino' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UpdateTransaction_LivestockTransactionEventFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'LivestockTransactionEvent' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'TransactionForm_LivestockTransactionEventFragment',
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    CreateBuffaloLivestockTransactionEventForUpdateTransactionMutation,
    CreateBuffaloLivestockTransactionEventForUpdateTransactionMutationVariables
  >;
export const UpdateLivestockTransactionEventForUpdateTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'updateLivestockTransactionEventForUpdateTransaction',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'updateLivestockTransactionEventInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateLivestockTransactionEventInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateLivestockTransactionEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updateLivestockTransactionEventInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updateLivestockTransactionEventInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'UpdateTransaction_LivestockTransactionEventFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_LivestockTransactionEventFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LivestockTransactionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionRegion' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headPurchasedMerino' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UpdateTransaction_LivestockTransactionEventFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LivestockTransactionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'TransactionForm_LivestockTransactionEventFragment',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateLivestockTransactionEventForUpdateTransactionMutation,
  UpdateLivestockTransactionEventForUpdateTransactionMutationVariables
>;
export const GetBuffaloActivityForMonthlyBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBuffaloActivityForMonthlyBreakdown' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buffaloLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'BuffaloMonthlyBreakdown_BuffaloLivestockActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BuffaloMonthlyBreakdown_BuffaloLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BuffaloLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bullsBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calfsBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bullsTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calfsTrading' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBuffaloActivityForMonthlyBreakdownQuery,
  GetBuffaloActivityForMonthlyBreakdownQueryVariables
>;
export const GetBuffaloMonthLivestockActivitiesForMonthlyBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetBuffaloMonthLivestockActivitiesForMonthlyBreakdown',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'listBuffaloMonthLivestockActivitiesInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ListBuffaloMonthLivestockActivitiesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buffaloMonthLivestockActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'listBuffaloMonthLivestockActivitiesInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'listBuffaloMonthLivestockActivitiesInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'BuffaloMonthlyBreakdown_BuffaloMonthLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BuffaloMonthlyForm_BuffaloMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BuffaloMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageCowsCalving' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'seasonalCalvingRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BuffaloMonthlyBreakdown_BuffaloMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BuffaloMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'BuffaloMonthlyForm_BuffaloMonthLivestockActivity',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBuffaloMonthLivestockActivitiesForMonthlyBreakdownQuery,
  GetBuffaloMonthLivestockActivitiesForMonthlyBreakdownQueryVariables
>;
export const GetBuffaloMonthLivestockActivityForCreateUpdateTransactionDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'query',
        name: {
          kind: 'Name',
          value: 'GetBuffaloMonthLivestockActivityForCreateUpdateTransaction',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'buffaloMonthLivestockActivity' },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'id' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'id' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'CreateTransaction_BuffaloMonthLivestockActivityFragment',
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'UpdateTransaction_BuffaloMonthLivestockActivityFragment',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'TransactionForm_BuffaloMonthLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BuffaloMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'year' } },
            { kind: 'Field', name: { kind: 'Name', value: 'month' } },
            { kind: 'Field', name: { kind: 'Name', value: 'buffaloClass' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'CreateTransaction_BuffaloMonthLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BuffaloMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'TransactionForm_BuffaloMonthLivestockActivityFragment',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UpdateTransaction_BuffaloMonthLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'BuffaloMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'TransactionForm_BuffaloMonthLivestockActivityFragment',
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    GetBuffaloMonthLivestockActivityForCreateUpdateTransactionQuery,
    GetBuffaloMonthLivestockActivityForCreateUpdateTransactionQueryVariables
  >;
export const GetBuffaloActivityFeedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBuffaloActivityFeed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buffaloLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'BuffaloActivityLivestockFeed_BuffaloLivestockActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BuffaloActivityLivestockFeed_BuffaloLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BuffaloLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grazingCrops' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBuffaloActivityFeedQuery,
  GetBuffaloActivityFeedQueryVariables
>;
export const GetBuffaloActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBuffaloActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buffaloLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BuffaloActivityLivestockBuffaloLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BuffaloActivityLivestockBreedingBuffaloLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BuffaloLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bullsBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calfsBreeding' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BuffaloActivityLivestockTradingBuffaloLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BuffaloLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bullsTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calfsTrading' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BuffaloActivityLivestockBuffaloLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BuffaloLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'BuffaloActivityLivestockBreedingBuffaloLivestockActivity',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'BuffaloActivityLivestockTradingBuffaloLivestockActivity',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBuffaloActivityQuery,
  GetBuffaloActivityQueryVariables
>;
export const GetBuffaloMonthLivestockActivitiesForActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetBuffaloMonthLivestockActivitiesForActivity',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'listBuffaloMonthLivestockActivitiesInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ListBuffaloMonthLivestockActivitiesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buffaloMonthLivestockActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'listBuffaloMonthLivestockActivitiesInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'listBuffaloMonthLivestockActivitiesInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BuffaloLivestockActivity_MonthActivities',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BuffaloLivestockActivity_MonthActivities' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BuffaloMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buffaloClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBuffaloMonthLivestockActivitiesForActivityQuery,
  GetBuffaloMonthLivestockActivitiesForActivityQueryVariables
>;
export const GetBuffaloActivityInHeaderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBuffaloActivityInHeader' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buffaloLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesActivitiesHeaderBuffaloActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertiesBusinessActivitiesActivitiesHeaderBuffaloActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BuffaloLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBuffaloActivityInHeaderQuery,
  GetBuffaloActivityInHeaderQueryVariables
>;
export const UpdateBuffaloLivestockActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateBuffaloLivestockActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'updateBuffaloLivestockActivityInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateBuffaloLivestockActivityInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBuffaloLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updateBuffaloLivestockActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updateBuffaloLivestockActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'BuffaloActivityLivestockBreedingBuffaloLivestockActivity',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'BuffaloActivityLivestockTradingBuffaloLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BuffaloActivityLivestockBreedingBuffaloLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BuffaloLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bullsBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calfsBreeding' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BuffaloActivityLivestockTradingBuffaloLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BuffaloLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bullsTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cowsTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'steersTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calfsTrading' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBuffaloLivestockActivityMutation,
  UpdateBuffaloLivestockActivityMutationVariables
>;
export const CreateBuffaloLivestockActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createBuffaloLivestockActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'createBuffaloLivestockActivityInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreateBuffaloLivestockActivityInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBuffaloLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'createBuffaloLivestockActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'createBuffaloLivestockActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateBuffaloLivestockActivityMutation,
  CreateBuffaloLivestockActivityMutationVariables
>;
export const GetBurningActivityInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBurningActivityInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'burningActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BusinessActivitiesBurningActivityInfoFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BusinessActivitiesBurningActivityInfoFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BurningActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fireScarAreaSquareMetres' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fuelClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'patchiness' } },
          { kind: 'Field', name: { kind: 'Name', value: 'season' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vegetationClass' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'yearsSinceLastFire' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rainfallZone' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBurningActivityInfoQuery,
  GetBurningActivityInfoQueryVariables
>;
export const UpdateBurningDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBurning' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateBurningActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateBurningActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBurningActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateBurningActivityInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateBurningActivityInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BusinessActivitiesBurningActivityInfoFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BusinessActivitiesBurningActivityInfoFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BurningActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fireScarAreaSquareMetres' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fuelClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'patchiness' } },
          { kind: 'Field', name: { kind: 'Name', value: 'season' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vegetationClass' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'yearsSinceLastFire' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rainfallZone' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBurningMutation,
  UpdateBurningMutationVariables
>;
export const CreateBurningActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createBurningActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createBurningActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateBurningActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBurningActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createBurningActivityInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createBurningActivityInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateBurningActivityMutation,
  CreateBurningActivityMutationVariables
>;
export const GetBurningActivityInHeaderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBurningActivityInHeader' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'burningActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesActivitiesHeaderBurningActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertiesBusinessActivitiesActivitiesHeaderBurningActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BurningActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBurningActivityInHeaderQuery,
  GetBurningActivityInHeaderQueryVariables
>;
export const GetCottonCroppingActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCottonCroppingActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cottonActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CottonCropInfoPageCottonFields',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CottonCropInfoPageCottonFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CottonActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'residueBurned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentageBurned' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageCottonYieldKgPerHa' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'balesPerHa' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageWeightPerBaleKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cottonLintPerBaleKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cottonSeedPerBaleKg' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'wastePerBaleKg' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCottonCroppingActivityQuery,
  GetCottonCroppingActivityQueryVariables
>;
export const UpdateCottonCroppingForInfoPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCottonCroppingForInfoPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateCottonActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCottonActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCottonActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateCottonActivityInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateCottonActivityInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CottonCropInfoPageCottonFields',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CottonCropInfoPageCottonFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CottonActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'residueBurned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentageBurned' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageCottonYieldKgPerHa' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'balesPerHa' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageWeightPerBaleKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cottonLintPerBaleKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cottonSeedPerBaleKg' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'wastePerBaleKg' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCottonCroppingForInfoPageMutation,
  UpdateCottonCroppingForInfoPageMutationVariables
>;
export const UpdateCroppingForInfoPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCroppingForInfoPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateCroppingActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCroppingActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCroppingActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateCroppingActivityInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateCroppingActivityInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CropInfoPageCroppingActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CropInfoPageCropVarietyFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CropVariety' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'species' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yieldInKg' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CropInfoPageCroppingActivityFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CroppingActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'irrigated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'residueBurned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentageBurned' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plantingAreaSquareMetres' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plantingAreaGeometry' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'varieties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CropInfoPageCropVarietyFragment',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCroppingForInfoPageMutation,
  UpdateCroppingForInfoPageMutationVariables
>;
export const CreateCropVarietyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCropVariety' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'croppingActivityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createCropVarietyInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCropVarietyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCropVariety' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'croppingActivityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'croppingActivityId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createCropVarietyInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createCropVarietyInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CropInfoPageCropVarietyFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CropInfoPageCropVarietyFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CropVariety' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'species' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yieldInKg' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCropVarietyMutation,
  CreateCropVarietyMutationVariables
>;
export const UpdateCropVarietyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCropVariety' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateCropVarietyInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCropVarietyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCropVariety' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateCropVarietyInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateCropVarietyInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CropInfoPageCropVarietyFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CropInfoPageCropVarietyFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CropVariety' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'species' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yieldInKg' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCropVarietyMutation,
  UpdateCropVarietyMutationVariables
>;
export const GetCroppingActivityInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCroppingActivityInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'croppingActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CropInfoPageCroppingActivityFragment',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CottonCropInfoPageCroppingActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CropInfoPageCropVarietyFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CropVariety' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'species' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yieldInKg' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CropInfoPageCroppingActivityFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CroppingActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'irrigated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'residueBurned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentageBurned' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plantingAreaSquareMetres' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plantingAreaGeometry' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'varieties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CropInfoPageCropVarietyFragment',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'CottonCropInfoPageCroppingActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CroppingActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plantingAreaSquareMetres' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plantingAreaGeometry' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCroppingActivityInfoQuery,
  GetCroppingActivityInfoQueryVariables
>;
export const GetCropActivityInHeaderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCropActivityInHeader' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'croppingActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesActivitiesHeaderCroppingActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertiesBusinessActivitiesActivitiesHeaderCroppingActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CroppingActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          { kind: 'Field', name: { kind: 'Name', value: 'croppingType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCropActivityInHeaderQuery,
  GetCropActivityInHeaderQueryVariables
>;
export const CreateFertiliserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFertiliser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'croppingActivityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createFertiliserInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateFertiliserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFertiliser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'croppingActivityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'croppingActivityId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createFertiliserInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createFertiliserInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Fertiliser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPerHectareKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationAreaSquareMetres' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationAreaGeometry' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ratioN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ratioP' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ratioK' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ratioS' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFertiliserMutation,
  CreateFertiliserMutationVariables
>;
export const UpdateFertiliserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateFertiliser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateFertiliserInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFertiliserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFertiliser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateFertiliserInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateFertiliserInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Fertiliser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPerHectareKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationAreaSquareMetres' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationAreaGeometry' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ratioN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ratioP' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ratioK' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ratioS' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFertiliserMutation,
  UpdateFertiliserMutationVariables
>;
export const DeleteFertiliserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteFertiliser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFertiliser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteFertiliserMutation,
  DeleteFertiliserMutationVariables
>;
export const GetFertiliserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFertiliser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fertiliser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Fertiliser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPerHectareKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationAreaSquareMetres' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationAreaGeometry' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ratioN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ratioP' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ratioK' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ratioS' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFertiliserQuery, GetFertiliserQueryVariables>;
export const UpdateCroppingForLimeFertiliserPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCroppingForLimeFertiliserPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateCroppingActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCroppingActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCroppingActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateCroppingActivityInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateCroppingActivityInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesCropLimeFertiliserFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertiesBusinessActivitiesCropLimeFertiliserFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CroppingActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plantingAreaSquareMetres' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lime' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amountPerHectareKg' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicationAreaSquareMetres' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fertiliser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amountPerHectareKg' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicationAreaSquareMetres' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ureaseInhibitorUsed' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nitrificationInhibitorUsed' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCroppingForLimeFertiliserPageMutation,
  UpdateCroppingForLimeFertiliserPageMutationVariables
>;
export const GetCroppingLimeFertiliserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCroppingLimeFertiliser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'croppingActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesCropLimeFertiliserFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertiesBusinessActivitiesCropLimeFertiliserFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CroppingActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plantingAreaSquareMetres' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lime' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amountPerHectareKg' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicationAreaSquareMetres' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fertiliser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amountPerHectareKg' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicationAreaSquareMetres' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ureaseInhibitorUsed' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nitrificationInhibitorUsed' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCroppingLimeFertiliserQuery,
  GetCroppingLimeFertiliserQueryVariables
>;
export const CreateLimeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateLime' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'croppingActivityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createLimeInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateLimeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createLime' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'croppingActivityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'croppingActivityId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createLimeInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createLimeInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesCropLimeFertiliserLimeFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertiesBusinessActivitiesCropLimeFertiliserLimeFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Lime' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPerHectareKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationAreaSquareMetres' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationAreaGeometry' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateLimeMutation, CreateLimeMutationVariables>;
export const UpdateLimeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateLime' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateLimeInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateLimeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateLime' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateLimeInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateLimeInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesCropLimeFertiliserLimeFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertiesBusinessActivitiesCropLimeFertiliserLimeFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Lime' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPerHectareKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationAreaSquareMetres' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationAreaGeometry' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateLimeMutation, UpdateLimeMutationVariables>;
export const DeleteLimeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteLime' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteLime' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteLimeMutation, DeleteLimeMutationVariables>;
export const GetLimeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLime' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lime' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesCropLimeFertiliserLimeFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertiesBusinessActivitiesCropLimeFertiliserLimeFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Lime' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPerHectareKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationAreaSquareMetres' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationAreaGeometry' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLimeQuery, GetLimeQueryVariables>;
export const CreateCarbamateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCarbamate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'croppingActivityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createCarbamateInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCarbamateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCarbamate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'croppingActivityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'croppingActivityId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createCarbamateInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createCarbamateInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Carbamate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPerHectareGrams' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationAreaSquareMetres' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationAreaGeometry' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeIngredientPercentage' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCarbamateMutation,
  CreateCarbamateMutationVariables
>;
export const UpdateCarbamateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCarbamate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateCarbamateInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCarbamateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCarbamate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateCarbamateInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateCarbamateInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Carbamate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPerHectareGrams' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationAreaSquareMetres' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationAreaGeometry' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeIngredientPercentage' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCarbamateMutation,
  UpdateCarbamateMutationVariables
>;
export const DeleteCarbamateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCarbamate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCarbamate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCarbamateMutation,
  DeleteCarbamateMutationVariables
>;
export const GetCroppingWeedPestDiseaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCroppingWeedPestDisease' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'croppingActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesCropWeedPestDiseaseListsFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertiesBusinessActivitiesCropWeedPestDiseaseListsFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CroppingActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plantingAreaSquareMetres' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pesticide' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amountPerHectareGrams' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicationAreaSquareMetres' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'herbicide' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amountPerHectareGrams' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicationAreaSquareMetres' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fungicide' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amountPerHectareGrams' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicationAreaSquareMetres' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCroppingWeedPestDiseaseQuery,
  GetCroppingWeedPestDiseaseQueryVariables
>;
export const GetCarbamateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCarbamate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carbamate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Carbamate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPerHectareGrams' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationAreaSquareMetres' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationAreaGeometry' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeIngredientPercentage' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCarbamateQuery, GetCarbamateQueryVariables>;
export const CreateCroppingActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCroppingActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createCroppingActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCroppingActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCroppingActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createCroppingActivityInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createCroppingActivityInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCroppingActivityMutation,
  CreateCroppingActivityMutationVariables
>;
export const UpdateDairyMonthLivestockActivityForMonthlyFeedBreakdownDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'mutation',
        name: {
          kind: 'Name',
          value: 'updateDairyMonthLivestockActivityForMonthlyFeedBreakdown',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: {
                kind: 'Name',
                value: 'updateDairyMonthLivestockActivityInput',
              },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'UpdateDairyMonthLivestockActivityInput',
                },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: {
                kind: 'Name',
                value: 'updateDairyMonthLivestockActivity',
              },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'id' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'id' },
                  },
                },
                {
                  kind: 'Argument',
                  name: {
                    kind: 'Name',
                    value: 'updateDairyMonthLivestockActivityInput',
                  },
                  value: {
                    kind: 'Variable',
                    name: {
                      kind: 'Name',
                      value: 'updateDairyMonthLivestockActivityInput',
                    },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'UseDairyMonthlyFeedBreakdown_DairyMonthLivestockActivity',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseDairyMonthlyFeedBreakdown_DairyMonthLivestockActivity',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'month' } },
            { kind: 'Field', name: { kind: 'Name', value: 'year' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'crudeProteinPercentage' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'dryMatterDigestibilityPercentage' },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UpdateDairyMonthLivestockActivityForMonthlyFeedBreakdownMutation,
    UpdateDairyMonthLivestockActivityForMonthlyFeedBreakdownMutationVariables
  >;
export const GetDairyActivityForMonthlyFeedBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDairyActivityForMonthlyFeedBreakdown' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dairyLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseDairyMonthlyFeedBreakdown_ActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseDairyMonthlyFeedBreakdown_ActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'milkingCows' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grazingCrops' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDairyActivityForMonthlyFeedBreakdownQuery,
  GetDairyActivityForMonthlyFeedBreakdownQueryVariables
>;
export const GetDairyMonthLivestockActivitiesForMonthlyFeedBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetDairyMonthLivestockActivitiesForMonthlyFeedBreakdown',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'listDairyMonthLivestockActivitiesInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ListDairyMonthLivestockActivitiesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dairyMonthLivestockActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'listDairyMonthLivestockActivitiesInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'listDairyMonthLivestockActivitiesInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'UseDairyMonthlyFeedBreakdown_DairyMonthLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseDairyMonthlyFeedBreakdown_DairyMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crudeProteinPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dryMatterDigestibilityPercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDairyMonthLivestockActivitiesForMonthlyFeedBreakdownQuery,
  GetDairyMonthLivestockActivitiesForMonthlyFeedBreakdownQueryVariables
>;
export const GetDairyLivestockActivityFeedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDairyLivestockActivityFeed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dairyLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DairyFeedFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyFeedBreakdownSummaryFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'milkingCows' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyFeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grazingCrops' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'milkingCows' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'DairyFeedBreakdownSummaryFragment' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDairyLivestockActivityFeedQuery,
  GetDairyLivestockActivityFeedQueryVariables
>;
export const GetDairyMonthLivestockActivitiesForFeedBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetDairyMonthLivestockActivitiesForFeedBreakdown',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'listDairyMonthLivestockActivitiesInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ListDairyMonthLivestockActivitiesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dairyMonthLivestockActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'listDairyMonthLivestockActivitiesInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'listDairyMonthLivestockActivitiesInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DairyFeedBreakdownSummaryMonthsFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyFeedBreakdownSummaryMonthsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crudeProteinPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dryMatterDigestibilityPercentage' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDairyMonthLivestockActivitiesForFeedBreakdownQuery,
  GetDairyMonthLivestockActivitiesForFeedBreakdownQueryVariables
>;
export const GetDairyLivestockActivityInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDairyLivestockActivityInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dairyLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DairyInfoFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyInfoFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'productionSystem' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'distanceTravelledBetweenFarmsKm' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'truckType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissionsRedMeatPercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDairyLivestockActivityInfoQuery,
  GetDairyLivestockActivityInfoQueryVariables
>;
export const UpdateDairyLivestockActivityInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDairyLivestockActivityInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateDairyLivestockActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateDairyLivestockActivityInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDairyLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updateDairyLivestockActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updateDairyLivestockActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DairyInfoFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyInfoFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'productionSystem' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'distanceTravelledBetweenFarmsKm' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'truckType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissionsRedMeatPercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDairyLivestockActivityInfoMutation,
  UpdateDairyLivestockActivityInfoMutationVariables
>;
export const GetDairyLivestockActivityInHeaderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDairyLivestockActivityInHeader' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dairyLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DairyLivestockActivityHeaderFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyLivestockActivityHeaderFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDairyLivestockActivityInHeaderQuery,
  GetDairyLivestockActivityInHeaderQueryVariables
>;
export const UpdateDairyMonthLivestockActivityForMonthlyBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'updateDairyMonthLivestockActivityForMonthlyBreakdown',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'updateDairyMonthLivestockActivityInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateDairyMonthLivestockActivityInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDairyMonthLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updateDairyMonthLivestockActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updateDairyMonthLivestockActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DairyMonthlyBreakdown_DairyMonthLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DairyMonthlyForm_DairyMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previousMonthLiveweightKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DairyMonthlyBreakdown_DairyMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'DairyMonthlyForm_DairyMonthLivestockActivity',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDairyMonthLivestockActivityForMonthlyBreakdownMutation,
  UpdateDairyMonthLivestockActivityForMonthlyBreakdownMutationVariables
>;
export const CreateDairyLivestockTransactionEventForUpdateTransactionDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'mutation',
        name: {
          kind: 'Name',
          value: 'createDairyLivestockTransactionEventForUpdateTransaction',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'dairyMonthLivestockActivityId' },
            },
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: {
                kind: 'Name',
                value: 'createLivestockTransactionEventInput',
              },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'CreateLivestockTransactionEventInput',
                },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: {
                kind: 'Name',
                value: 'createDairyLivestockTransactionEvent',
              },
              arguments: [
                {
                  kind: 'Argument',
                  name: {
                    kind: 'Name',
                    value: 'dairyMonthLivestockActivityId',
                  },
                  value: {
                    kind: 'Variable',
                    name: {
                      kind: 'Name',
                      value: 'dairyMonthLivestockActivityId',
                    },
                  },
                },
                {
                  kind: 'Argument',
                  name: {
                    kind: 'Name',
                    value: 'createLivestockTransactionEventInput',
                  },
                  value: {
                    kind: 'Variable',
                    name: {
                      kind: 'Name',
                      value: 'createLivestockTransactionEventInput',
                    },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'UpdateTransaction_LivestockTransactionEventFragment',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'TransactionForm_LivestockTransactionEventFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'LivestockTransactionEvent' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
            { kind: 'Field', name: { kind: 'Name', value: 'livestockCount' } },
            { kind: 'Field', name: { kind: 'Name', value: 'totalWeightKg' } },
            { kind: 'Field', name: { kind: 'Name', value: 'averageWeightKg' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'transactionRegion' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'headPurchasedMerino' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UpdateTransaction_LivestockTransactionEventFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'LivestockTransactionEvent' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'TransactionForm_LivestockTransactionEventFragment',
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    CreateDairyLivestockTransactionEventForUpdateTransactionMutation,
    CreateDairyLivestockTransactionEventForUpdateTransactionMutationVariables
  >;
export const GetDairyLivestockActivityForMonthlyBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetDairyLivestockActivityForMonthlyBreakdown',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dairyLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DairyMonthlyBreakdown_DairyLivestockActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DairyMonthlyBreakdown_DairyLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'milkingCows' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDairyLivestockActivityForMonthlyBreakdownQuery,
  GetDairyLivestockActivityForMonthlyBreakdownQueryVariables
>;
export const GetDairyMonthLivestockActivitiesForMonthlyBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetDairyMonthLivestockActivitiesForMonthlyBreakdown',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'listDairyMonthLivestockActivitiesInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ListDairyMonthLivestockActivitiesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dairyMonthLivestockActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'listDairyMonthLivestockActivitiesInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'listDairyMonthLivestockActivitiesInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DairyMonthlyBreakdown_DairyMonthLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DairyMonthlyForm_DairyMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previousMonthLiveweightKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DairyMonthlyBreakdown_DairyMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'DairyMonthlyForm_DairyMonthLivestockActivity',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDairyMonthLivestockActivitiesForMonthlyBreakdownQuery,
  GetDairyMonthLivestockActivitiesForMonthlyBreakdownQueryVariables
>;
export const GetdairyMonthLivestockActivityForCreateUpdateTransactionDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'query',
        name: {
          kind: 'Name',
          value: 'GetdairyMonthLivestockActivityForCreateUpdateTransaction',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'dairyMonthLivestockActivity' },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'id' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'id' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'CreateTransaction_DairyMonthLivestockActivityFragment',
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'UpdateTransaction_DairyMonthLivestockActivityFragment',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'TransactionForm_DairyMonthLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'year' } },
            { kind: 'Field', name: { kind: 'Name', value: 'month' } },
            { kind: 'Field', name: { kind: 'Name', value: 'dairyClass' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'CreateTransaction_DairyMonthLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'TransactionForm_DairyMonthLivestockActivityFragment',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UpdateTransaction_DairyMonthLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'TransactionForm_DairyMonthLivestockActivityFragment',
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    GetdairyMonthLivestockActivityForCreateUpdateTransactionQuery,
    GetdairyMonthLivestockActivityForCreateUpdateTransactionQueryVariables
  >;
export const GetDairyLivestockActivityLivestockDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDairyLivestockActivityLivestock' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dairyLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DairyActivityLivestockFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyLivestockFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'milkingCows' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyActivityLivestockFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'DairyLivestockFragment' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDairyLivestockActivityLivestockQuery,
  GetDairyLivestockActivityLivestockQueryVariables
>;
export const UpdateDairyLivestockActivityLivestockDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDairyLivestockActivityLivestock' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateDairyLivestockActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateDairyLivestockActivityInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDairyLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updateDairyLivestockActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updateDairyLivestockActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DairyActivityLivestockFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyLivestockFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'milkingCows' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyActivityLivestockFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'DairyLivestockFragment' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDairyLivestockActivityLivestockMutation,
  UpdateDairyLivestockActivityLivestockMutationVariables
>;
export const GetDairyMonthLivestockActivitiesForActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetDairyMonthLivestockActivitiesForActivity',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'listDairyMonthLivestockActivitiesInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ListDairyMonthLivestockActivitiesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dairyMonthLivestockActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'listDairyMonthLivestockActivitiesInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'listDairyMonthLivestockActivitiesInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DairyLivestockMonthsFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyLivestockMonthsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDairyMonthLivestockActivitiesForActivityQuery,
  GetDairyMonthLivestockActivitiesForActivityQueryVariables
>;
export const GetDairyLivestockActivityManureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDairyLivestockActivityManure' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dairyLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DairyManureFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyManureFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manureMilkingPasturePercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manureMilkingLagoonPercentage' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'manureMilkingSumpDispersalPercentage',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manureMilkingPaddocksPercentage' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'manureMilkingSolidStoragePercentage',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manureOtherPasturePercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manureOtherLagoonPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manureOtherSumpDispersalPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manureOtherPaddocksPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manureOtherSolidStoragePercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDairyLivestockActivityManureQuery,
  GetDairyLivestockActivityManureQueryVariables
>;
export const UpdateDairyLivestockActivityManureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDairyLivestockActivityManure' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateDairyLivestockActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateDairyLivestockActivityInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDairyLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updateDairyLivestockActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updateDairyLivestockActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DairyManureFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyManureFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manureMilkingPasturePercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manureMilkingLagoonPercentage' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'manureMilkingSumpDispersalPercentage',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manureMilkingPaddocksPercentage' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'manureMilkingSolidStoragePercentage',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manureOtherPasturePercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manureOtherLagoonPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manureOtherSumpDispersalPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manureOtherPaddocksPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manureOtherSolidStoragePercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDairyLivestockActivityManureMutation,
  UpdateDairyLivestockActivityManureMutationVariables
>;
export const GetDairyMonthLivestockActivitiesForMonthlyMilkBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetDairyMonthLivestockActivitiesForMonthlyMilkBreakdown',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'listDairyMonthLivestockActivitiesInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ListDairyMonthLivestockActivitiesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dairyMonthLivestockActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'listDairyMonthLivestockActivitiesInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'listDairyMonthLivestockActivitiesInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'UseDairyMonthlyMilkBreakdown_DairyMonthLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseDairyMonthlyMilkBreakdown_DairyMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'litresPerHeadPerDay' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDairyMonthLivestockActivitiesForMonthlyMilkBreakdownQuery,
  GetDairyMonthLivestockActivitiesForMonthlyMilkBreakdownQueryVariables
>;
export const UpdateDairyMonthLivestockActivityForMonthlyMilkBreakdownDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'mutation',
        name: {
          kind: 'Name',
          value: 'updateDairyMonthLivestockActivityForMonthlyMilkBreakdown',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: {
                kind: 'Name',
                value: 'updateDairyMonthLivestockActivityInput',
              },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'UpdateDairyMonthLivestockActivityInput',
                },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: {
                kind: 'Name',
                value: 'updateDairyMonthLivestockActivity',
              },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'id' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'id' },
                  },
                },
                {
                  kind: 'Argument',
                  name: {
                    kind: 'Name',
                    value: 'updateDairyMonthLivestockActivityInput',
                  },
                  value: {
                    kind: 'Variable',
                    name: {
                      kind: 'Name',
                      value: 'updateDairyMonthLivestockActivityInput',
                    },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'UseDairyMonthlyMilkBreakdown_DairyMonthLivestockActivity',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseDairyMonthlyMilkBreakdown_DairyMonthLivestockActivity',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'month' } },
            { kind: 'Field', name: { kind: 'Name', value: 'year' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'litresPerHeadPerDay' },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UpdateDairyMonthLivestockActivityForMonthlyMilkBreakdownMutation,
    UpdateDairyMonthLivestockActivityForMonthlyMilkBreakdownMutationVariables
  >;
export const GetDairyLivestockActivityMilkProductionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDairyLivestockActivityMilkProduction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dairyLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DairyMilkProductionListFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyMilkSummaryFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'milkingCows' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyMilkProductionListFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyBullsLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersGt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heifersLt1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'milkingCows' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'DairyMilkSummaryFragment' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDairyLivestockActivityMilkProductionQuery,
  GetDairyLivestockActivityMilkProductionQueryVariables
>;
export const GetDairyMonthLivestockActivitiesForMilkProductionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetDairyMonthLivestockActivitiesForMilkProduction',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'listDairyMonthLivestockActivitiesInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ListDairyMonthLivestockActivitiesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dairyMonthLivestockActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'listDairyMonthLivestockActivitiesInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'listDairyMonthLivestockActivitiesInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DairyMilkSummaryMonthsFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DairyMilkSummaryMonthsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DairyMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'litresPerHeadPerDay' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dairyClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDairyMonthLivestockActivitiesForMilkProductionQuery,
  GetDairyMonthLivestockActivitiesForMilkProductionQueryVariables
>;
export const CreateDairyLivestockActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateDairyLivestockActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createDairyLivestockActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreateDairyLivestockActivityInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDairyLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'createDairyLivestockActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'createDairyLivestockActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDairyLivestockActivityMutation,
  CreateDairyLivestockActivityMutationVariables
>;
export const UpdateDeerMonthLivestockActivityForMonthlyBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'updateDeerMonthLivestockActivityForMonthlyBreakdown',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'updateDeerMonthLivestockActivityInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateDeerMonthLivestockActivityInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDeerMonthLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updateDeerMonthLivestockActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updateDeerMonthLivestockActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DeerMonthlyBreakdown_DeerMonthLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeerMonthlyForm_DeerMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageDoesFawning' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'seasonalFawningRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeerMonthlyBreakdown_DeerMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'DeerMonthlyForm_DeerMonthLivestockActivity',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDeerMonthLivestockActivityForMonthlyBreakdownMutation,
  UpdateDeerMonthLivestockActivityForMonthlyBreakdownMutationVariables
>;
export const CreateDeerLivestockTransactionEventForUpdateTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'createDeerLivestockTransactionEventForUpdateTransaction',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deerMonthLivestockActivityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'createLivestockTransactionEventInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreateLivestockTransactionEventInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'createDeerLivestockTransactionEvent',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deerMonthLivestockActivityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'deerMonthLivestockActivityId' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'createLivestockTransactionEventInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'createLivestockTransactionEventInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'UpdateTransaction_LivestockTransactionEventFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_LivestockTransactionEventFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LivestockTransactionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionRegion' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headPurchasedMerino' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UpdateTransaction_LivestockTransactionEventFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LivestockTransactionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'TransactionForm_LivestockTransactionEventFragment',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDeerLivestockTransactionEventForUpdateTransactionMutation,
  CreateDeerLivestockTransactionEventForUpdateTransactionMutationVariables
>;
export const GetDeerActivityForMonthlyBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDeerActivityForMonthlyBreakdown' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deerLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DeerMonthlyBreakdown_DeerLivestockActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeerMonthlyBreakdown_DeerLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bucksBreeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breedingDoesBreeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherDoesBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fawnBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bucksTrading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breedingDoesTrading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherDoesTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fawnTrading' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDeerActivityForMonthlyBreakdownQuery,
  GetDeerActivityForMonthlyBreakdownQueryVariables
>;
export const GetDeerMonthLivestockActivitiesForMonthlyBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetDeerMonthLivestockActivitiesForMonthlyBreakdown',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'listDeerMonthLivestockActivitiesInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ListDeerMonthLivestockActivitiesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deerMonthLivestockActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'listDeerMonthLivestockActivitiesInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'listDeerMonthLivestockActivitiesInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DeerMonthlyBreakdown_DeerMonthLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeerMonthlyForm_DeerMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageDoesFawning' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'seasonalFawningRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeerMonthlyBreakdown_DeerMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'DeerMonthlyForm_DeerMonthLivestockActivity',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDeerMonthLivestockActivitiesForMonthlyBreakdownQuery,
  GetDeerMonthLivestockActivitiesForMonthlyBreakdownQueryVariables
>;
export const GetDeerMonthLivestockActivityForCreateUpdateTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetDeerMonthLivestockActivityForCreateUpdateTransaction',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deerMonthLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'CreateTransaction_DeerMonthLivestockActivityFragment',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'UpdateTransaction_DeerMonthLivestockActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_DeerMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deerClass' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'CreateTransaction_DeerMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'TransactionForm_DeerMonthLivestockActivityFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UpdateTransaction_DeerMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'TransactionForm_DeerMonthLivestockActivityFragment',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDeerMonthLivestockActivityForCreateUpdateTransactionQuery,
  GetDeerMonthLivestockActivityForCreateUpdateTransactionQueryVariables
>;
export const GetDeerActivityFeedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDeerActivityFeed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deerLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DeerActivityLivestockFeed_DeerLivestockActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeerActivityLivestockFeed_DeerLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grazingCrops' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDeerActivityFeedQuery,
  GetDeerActivityFeedQueryVariables
>;
export const GetDeerActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDeerActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deerLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DeerActivityLivestockDeerLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeerActivityLivestockBreedingDeerLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bucksBreeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breedingDoesBreeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherDoesBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fawnBreeding' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeerActivityLivestockTradingDeerLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bucksTrading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breedingDoesTrading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherDoesTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fawnTrading' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeerActivityLivestockDeerLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'DeerActivityLivestockBreedingDeerLivestockActivity',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'DeerActivityLivestockTradingDeerLivestockActivity',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDeerActivityQuery,
  GetDeerActivityQueryVariables
>;
export const GetDeerMonthLivestockActivitiesForActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetDeerMonthLivestockActivitiesForActivity',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'listDeerMonthLivestockActivitiesInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ListDeerMonthLivestockActivitiesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deerMonthLivestockActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'listDeerMonthLivestockActivitiesInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'listDeerMonthLivestockActivitiesInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DeerLivestockActivity_MonthActivities',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DeerLivestockActivity_MonthActivities' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deerClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDeerMonthLivestockActivitiesForActivityQuery,
  GetDeerMonthLivestockActivitiesForActivityQueryVariables
>;
export const GetDeerActivityInHeaderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDeerActivityInHeader' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deerLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesActivitiesHeaderDeerActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertiesBusinessActivitiesActivitiesHeaderDeerActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDeerActivityInHeaderQuery,
  GetDeerActivityInHeaderQueryVariables
>;
export const UpdateDeerLivestockActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateDeerLivestockActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateDeerLivestockActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateDeerLivestockActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDeerLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updateDeerLivestockActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updateDeerLivestockActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DeerActivityLivestockBreedingDeerLivestockActivity',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DeerActivityLivestockTradingDeerLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeerActivityLivestockBreedingDeerLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bucksBreeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breedingDoesBreeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherDoesBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fawnBreeding' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DeerActivityLivestockTradingDeerLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DeerLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bucksTrading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breedingDoesTrading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherDoesTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fawnTrading' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDeerLivestockActivityMutation,
  UpdateDeerLivestockActivityMutationVariables
>;
export const CreateDeerLivestockActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createDeerLivestockActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createDeerLivestockActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateDeerLivestockActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDeerLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'createDeerLivestockActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'createDeerLivestockActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDeerLivestockActivityMutation,
  CreateDeerLivestockActivityMutationVariables
>;
export const GetFeedlotGroupFeedBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFeedlotGroupFeedBreakdown' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedlotGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FeedlotGroupFeedFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotGroupFeedBreakdownFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotFeedBreakdown' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dryMatterDigestibility' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'crudeProtein' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'netEnergyConcentrationMjPerKg' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'nitrogenRetention' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotGroupFeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedBreakdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedlotGroupFeedBreakdownFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFeedlotGroupFeedBreakdownQuery,
  GetFeedlotGroupFeedBreakdownQueryVariables
>;
export const CreateFeedBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFeedBreakdown' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'feedlotGroupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createFeedlotFeedBreakdownInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateFeedlotFeedBreakdownInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFeedlotFeedBreakdown' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'feedlotGroupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'feedlotGroupId' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'createFeedlotFeedBreakdownInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'createFeedlotFeedBreakdownInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedlotGroupFeedBreakdownFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotGroupFeedBreakdownFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotFeedBreakdown' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dryMatterDigestibility' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'crudeProtein' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'netEnergyConcentrationMjPerKg' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'nitrogenRetention' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFeedBreakdownMutation,
  CreateFeedBreakdownMutationVariables
>;
export const UpdateFeedBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateFeedBreakdown' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateFeedlotFeedBreakdownInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFeedlotFeedBreakdownInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFeedlotFeedBreakdown' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updateFeedlotFeedBreakdownInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updateFeedlotFeedBreakdownInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedlotGroupFeedBreakdownFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotGroupFeedBreakdownFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotFeedBreakdown' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dryMatterDigestibility' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'crudeProtein' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'netEnergyConcentrationMjPerKg' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'nitrogenRetention' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFeedBreakdownMutation,
  UpdateFeedBreakdownMutationVariables
>;
export const CreateFeedCompositionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFeedComposition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'feedlotActivityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createFeedlotFeedCompositionInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreateFeedlotFeedCompositionInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFeedlotFeedComposition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'feedlotActivityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'feedlotActivityId' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'createFeedlotFeedCompositionInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'createFeedlotFeedCompositionInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedlotFeedCompositionFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotFeedCompositionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotFeedComposition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalProportion' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalCellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalHemicellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalSolubleResidue' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalNitrogen' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesProportion' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesCellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesHemicellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesSolubleResidue' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesNitrogen' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'grassesProportion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'grassesCellulous' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grassesHemicellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grassesSolubleResidue' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'grassesNitrogen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legumesProportion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legumesCellulous' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'legumesHemicellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'legumesSolubleResidue' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'legumesNitrogen' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFeedCompositionMutation,
  CreateFeedCompositionMutationVariables
>;
export const UpdateFeedCompositionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateFeedComposition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateFeedlotFeedCompositionInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateFeedlotFeedCompositionInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFeedlotFeedComposition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updateFeedlotFeedCompositionInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updateFeedlotFeedCompositionInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedlotFeedCompositionFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotFeedCompositionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotFeedComposition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalProportion' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalCellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalHemicellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalSolubleResidue' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalNitrogen' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesProportion' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesCellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesHemicellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesSolubleResidue' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesNitrogen' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'grassesProportion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'grassesCellulous' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grassesHemicellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grassesSolubleResidue' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'grassesNitrogen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legumesProportion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legumesCellulous' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'legumesHemicellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'legumesSolubleResidue' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'legumesNitrogen' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFeedCompositionMutation,
  UpdateFeedCompositionMutationVariables
>;
export const GetFeedlotActivitySystemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFeedlotActivitySystem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedlotActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedlotProductionSystemFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotProductionSystemFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'system' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFeedlotActivitySystemQuery,
  GetFeedlotActivitySystemQueryVariables
>;
export const GetFeedlotActivityTransportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFeedlotActivityTransport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedlotActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedlotProductionTransportFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotProductionTransportFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalDistanceTravelledKm' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'truckType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFeedlotActivityTransportQuery,
  GetFeedlotActivityTransportQueryVariables
>;
export const GetFeedlotActivityLivestockDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFeedlotActivityLivestock' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedlotActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedlotProductionLivestockFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotProductionLivestockFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchases' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCategory' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'head' } },
                { kind: 'Field', name: { kind: 'Name', value: 'beefClass' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sales' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCategory' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'head' } },
                { kind: 'Field', name: { kind: 'Name', value: 'beefClass' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFeedlotActivityLivestockQuery,
  GetFeedlotActivityLivestockQueryVariables
>;
export const UpdateFeedlotDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateFeedlot' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateFeedlotActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFeedlotActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFeedlotActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateFeedlotActivityInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateFeedlotActivityInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedlotProductionSystemFragment',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedlotProductionFeedFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotFeedCompositionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotFeedComposition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalProportion' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalCellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalHemicellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalSolubleResidue' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalNitrogen' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesProportion' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesCellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesHemicellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesSolubleResidue' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesNitrogen' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'grassesProportion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'grassesCellulous' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grassesHemicellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grassesSolubleResidue' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'grassesNitrogen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legumesProportion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legumesCellulous' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'legumesHemicellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'legumesSolubleResidue' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'legumesNitrogen' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotProductionSystemFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'system' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotProductionFeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
          { kind: 'Field', name: { kind: 'Name', value: 'grazingCrops' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedComposition' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedlotFeedCompositionFragment',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'feedBreakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFeedlotMutation,
  UpdateFeedlotMutationVariables
>;
export const GetFeedlotActivityFeedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFeedlotActivityFeed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedlotActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedlotProductionFeedFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotFeedCompositionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotFeedComposition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalProportion' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalCellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalHemicellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalSolubleResidue' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grainsTotalNitrogen' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesProportion' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesCellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesHemicellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesSolubleResidue' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherConcentratesNitrogen' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'grassesProportion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'grassesCellulous' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grassesHemicellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grassesSolubleResidue' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'grassesNitrogen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legumesProportion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legumesCellulous' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'legumesHemicellulous' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'legumesSolubleResidue' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'legumesNitrogen' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotProductionFeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
          { kind: 'Field', name: { kind: 'Name', value: 'grazingCrops' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedComposition' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedlotFeedCompositionFragment',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'feedBreakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFeedlotActivityFeedQuery,
  GetFeedlotActivityFeedQueryVariables
>;
export const FeedlotGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FeedlotGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedlotGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedlotLivestockGroupFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotLivestockGroupFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfLivestock' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lengthOfStayDays' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightKg' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FeedlotGroupQuery, FeedlotGroupQueryVariables>;
export const CreateLivestockGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateLivestockGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'feedlotActivityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createFeedlotGroupInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateFeedlotGroupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFeedlotGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'feedlotActivityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'feedlotActivityId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createFeedlotGroupInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createFeedlotGroupInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedlotLivestockGroupFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotLivestockGroupFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfLivestock' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lengthOfStayDays' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightKg' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateLivestockGroupMutation,
  CreateLivestockGroupMutationVariables
>;
export const UpdateLivestockGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateLivestockGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateFeedlotGroupInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFeedlotGroupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFeedlotGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateFeedlotGroupInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateFeedlotGroupInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedlotLivestockGroupFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotLivestockGroupFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfLivestock' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lengthOfStayDays' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightKg' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateLivestockGroupMutation,
  UpdateLivestockGroupMutationVariables
>;
export const DeleteLivestockGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteLivestockGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFeedlotGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteLivestockGroupMutation,
  DeleteLivestockGroupMutationVariables
>;
export const FeedlotPurchaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FeedlotPurchase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedlotPurchase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedlotLivestockPurchaseFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotLivestockPurchaseFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotPurchase' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'head' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightPerHeadKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beefClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FeedlotPurchaseQuery,
  FeedlotPurchaseQueryVariables
>;
export const CreateLivestockPurchaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateLivestockPurchase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'feedlotActivityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createFeedlotPurchaseInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateFeedlotPurchaseInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFeedlotPurchase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'feedlotActivityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'feedlotActivityId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createFeedlotPurchaseInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createFeedlotPurchaseInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedlotLivestockPurchaseFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotLivestockPurchaseFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotPurchase' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'head' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightPerHeadKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beefClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateLivestockPurchaseMutation,
  CreateLivestockPurchaseMutationVariables
>;
export const UpdateLivestockPurchaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateLivestockPurchase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateFeedlotPurchaseInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFeedlotPurchaseInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFeedlotPurchase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateFeedlotPurchaseInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateFeedlotPurchaseInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedlotLivestockPurchaseFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotLivestockPurchaseFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotPurchase' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'head' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightPerHeadKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beefClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateLivestockPurchaseMutation,
  UpdateLivestockPurchaseMutationVariables
>;
export const DeleteLivestockPurchaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteLivestockPurchase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFeedlotPurchase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedlotLivestockPurchaseFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotLivestockPurchaseFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotPurchase' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'head' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightPerHeadKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beefClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteLivestockPurchaseMutation,
  DeleteLivestockPurchaseMutationVariables
>;
export const FeedlotSaleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FeedlotSale' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedlotSale' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FeedlotLivestockSaleFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotLivestockSaleFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotSale' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'head' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightPerHeadKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beefClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCategory' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FeedlotSaleQuery, FeedlotSaleQueryVariables>;
export const CreateLivestockSaleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateLivestockSale' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'feedlotActivityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createFeedlotSaleInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateFeedlotSaleInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFeedlotSale' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'feedlotActivityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'feedlotActivityId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createFeedlotSaleInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createFeedlotSaleInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FeedlotLivestockSaleFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotLivestockSaleFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotSale' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'head' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightPerHeadKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beefClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCategory' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateLivestockSaleMutation,
  CreateLivestockSaleMutationVariables
>;
export const UpdateLivestockSaleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateLivestockSale' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateFeedlotSaleInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFeedlotSaleInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFeedlotSale' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateFeedlotSaleInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateFeedlotSaleInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FeedlotLivestockSaleFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotLivestockSaleFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotSale' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'head' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightPerHeadKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beefClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCategory' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateLivestockSaleMutation,
  UpdateLivestockSaleMutationVariables
>;
export const DeleteLivestockSaleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteLivestockSale' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFeedlotSale' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FeedlotLivestockSaleFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedlotLivestockSaleFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotSale' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'head' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightPerHeadKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beefClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCategory' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteLivestockSaleMutation,
  DeleteLivestockSaleMutationVariables
>;
export const CreateFeedlotActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createFeedlotActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createFeedlotActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateFeedlotActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFeedlotActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createFeedlotActivityInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createFeedlotActivityInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFeedlotActivityMutation,
  CreateFeedlotActivityMutationVariables
>;
export const GetFeedlotActivityInHeaderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFeedlotActivityInHeader' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedlotActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesActivitiesHeaderFeedlotActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertiesBusinessActivitiesActivitiesHeaderFeedlotActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FeedlotActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFeedlotActivityInHeaderQuery,
  GetFeedlotActivityInHeaderQueryVariables
>;
export const UpdateGoatMonthLivestockActivityForMonthlyBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'updateGoatMonthLivestockActivityForMonthlyBreakdown',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'updateGoatMonthLivestockActivityInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateGoatMonthLivestockActivityInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateGoatMonthLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updateGoatMonthLivestockActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updateGoatMonthLivestockActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'GoatMonthlyBreakdown_GoatMonthLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatMonthlyForm_GoatMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageDoesKidding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'seasonalKiddingRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatMonthlyBreakdown_GoatMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'GoatMonthlyForm_GoatMonthLivestockActivity',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateGoatMonthLivestockActivityForMonthlyBreakdownMutation,
  UpdateGoatMonthLivestockActivityForMonthlyBreakdownMutationVariables
>;
export const CreateGoatLivestockTransactionEventForUpdateTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'createGoatLivestockTransactionEventForUpdateTransaction',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'goatMonthLivestockActivityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'createLivestockTransactionEventInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreateLivestockTransactionEventInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'createGoatLivestockTransactionEvent',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'goatMonthLivestockActivityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'goatMonthLivestockActivityId' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'createLivestockTransactionEventInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'createLivestockTransactionEventInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'UpdateTransaction_LivestockTransactionEventFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_LivestockTransactionEventFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LivestockTransactionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionRegion' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headPurchasedMerino' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UpdateTransaction_LivestockTransactionEventFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LivestockTransactionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'TransactionForm_LivestockTransactionEventFragment',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateGoatLivestockTransactionEventForUpdateTransactionMutation,
  CreateGoatLivestockTransactionEventForUpdateTransactionMutationVariables
>;
export const GetGoatActivityForMonthlyBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGoatActivityForMonthlyBreakdown' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goatLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'GoatMonthlyBreakdown_GoatLivestockActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatMonthlyBreakdown_GoatLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bucksBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wethersBreeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breedingDoesBreeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingDoesBreeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherDoesBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kidsBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bucksTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wethersTrading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breedingDoesTrading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingDoesTrading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherDoesTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kidsTrading' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGoatActivityForMonthlyBreakdownQuery,
  GetGoatActivityForMonthlyBreakdownQueryVariables
>;
export const GetGoatMonthLivestockActivitiesForMonthlyBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetGoatMonthLivestockActivitiesForMonthlyBreakdown',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'listGoatMonthLivestockActivitiesInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ListGoatMonthLivestockActivitiesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goatMonthLivestockActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'listGoatMonthLivestockActivitiesInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'listGoatMonthLivestockActivitiesInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'GoatMonthlyBreakdown_GoatMonthLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatMonthlyForm_GoatMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageDoesKidding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'seasonalKiddingRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatMonthlyBreakdown_GoatMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'GoatMonthlyForm_GoatMonthLivestockActivity',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGoatMonthLivestockActivitiesForMonthlyBreakdownQuery,
  GetGoatMonthLivestockActivitiesForMonthlyBreakdownQueryVariables
>;
export const GetGoatMonthLivestockActivityForCreateUpdateTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetGoatMonthLivestockActivityForCreateUpdateTransaction',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goatMonthLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'CreateTransaction_GoatMonthLivestockActivityFragment',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'UpdateTransaction_GoatMonthLivestockActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_GoatMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goatClass' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'CreateTransaction_GoatMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'TransactionForm_GoatMonthLivestockActivityFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UpdateTransaction_GoatMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'TransactionForm_GoatMonthLivestockActivityFragment',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGoatMonthLivestockActivityForCreateUpdateTransactionQuery,
  GetGoatMonthLivestockActivityForCreateUpdateTransactionQueryVariables
>;
export const GetGoatLivestockActivityFeedDrySeasonMixDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGoatLivestockActivityFeedDrySeasonMix' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goatLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'GoatFeedDrySeasonMix_GoatLivestockActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedDrySeasonMix_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'drySeasonMixKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'drySeasonMixUreaPercentage' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatFeedDrySeasonMix_GoatLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedDrySeasonMix_FeedFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGoatLivestockActivityFeedDrySeasonMixQuery,
  GetGoatLivestockActivityFeedDrySeasonMixQueryVariables
>;
export const GetGoatActivityFeedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGoatActivityFeed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goatLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'GoatActivityLivestockFeed_GoatLivestockActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatActivityLivestockFeed_GoatLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grazingCrops' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mineralBlock' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'weanerBlock' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'drySeasonMix' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGoatActivityFeedQuery,
  GetGoatActivityFeedQueryVariables
>;
export const GetGoatLivestockActivityFeedMineralBlockDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGoatLivestockActivityFeedMineralBlock' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goatLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'GoatFeedMineralBlock_GoatLivestockActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedMineralBlock_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mineralBlockKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mineralBlockUreaPercentage' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatFeedMineralBlock_GoatLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedMineralBlock_FeedFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGoatLivestockActivityFeedMineralBlockQuery,
  GetGoatLivestockActivityFeedMineralBlockQueryVariables
>;
export const UpdateGoatFeedWeanerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateGoatFeedWeaner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateFeedInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFeedInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFeed' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateFeedInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateFeedInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FeedWeanerBlock_FeedFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedWeanerBlock_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weanerBlockKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'weanerBlockUreaPercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateGoatFeedWeanerMutation,
  UpdateGoatFeedWeanerMutationVariables
>;
export const GetGoatLivestockActivityFeedWeanerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGoatLivestockActivityFeedWeaner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goatLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'GoatFeedWeanerBlock_GoatLivestockActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedWeanerBlock_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weanerBlockKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'weanerBlockUreaPercentage' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatFeedWeanerBlock_GoatLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FeedWeanerBlock_FeedFragment' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGoatLivestockActivityFeedWeanerQuery,
  GetGoatLivestockActivityFeedWeanerQueryVariables
>;
export const GetGoatActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGoatActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goatLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'GoatActivityLivestockGoatLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatActivityLivestockBreedingGoatLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bucksBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wethersBreeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breedingDoesBreeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingDoesBreeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherDoesBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kidsBreeding' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatActivityLivestockTradingGoatLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bucksTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wethersTrading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breedingDoesTrading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingDoesTrading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherDoesTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kidsTrading' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatActivityLivestockGoatLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'GoatActivityLivestockBreedingGoatLivestockActivity',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'GoatActivityLivestockTradingGoatLivestockActivity',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGoatActivityQuery,
  GetGoatActivityQueryVariables
>;
export const GetGoatMonthLivestockActivitiesForActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetGoatMonthLivestockActivitiesForActivity',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'listGoatMonthLivestockActivitiesInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ListGoatMonthLivestockActivitiesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goatMonthLivestockActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'listGoatMonthLivestockActivitiesInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'listGoatMonthLivestockActivitiesInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'GoatLivestockActivity_MonthActivities',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoatLivestockActivity_MonthActivities' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goatClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGoatMonthLivestockActivitiesForActivityQuery,
  GetGoatMonthLivestockActivitiesForActivityQueryVariables
>;
export const GetGoatActivityInHeaderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGoatActivityInHeader' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goatLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesActivitiesHeaderGoatActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertiesBusinessActivitiesActivitiesHeaderGoatActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGoatActivityInHeaderQuery,
  GetGoatActivityInHeaderQueryVariables
>;
export const UpdateGoatLivestockActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateGoatLivestockActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateGoatLivestockActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateGoatLivestockActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateGoatLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updateGoatLivestockActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updateGoatLivestockActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'GoatActivityLivestockBreedingGoatLivestockActivity',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'GoatActivityLivestockTradingGoatLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatActivityLivestockBreedingGoatLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bucksBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wethersBreeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breedingDoesBreeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingDoesBreeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherDoesBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kidsBreeding' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GoatActivityLivestockTradingGoatLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bucksTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wethersTrading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breedingDoesTrading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingDoesTrading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherDoesTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kidsTrading' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateGoatLivestockActivityMutation,
  UpdateGoatLivestockActivityMutationVariables
>;
export const GetGoatMonthLivestockActivitiesForShearingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetGoatMonthLivestockActivitiesForShearing',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'listGoatMonthLivestockActivitiesInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ListGoatMonthLivestockActivitiesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goatMonthLivestockActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'listGoatMonthLivestockActivitiesInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'listGoatMonthLivestockActivitiesInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'GoatShearingEventMonthActivities',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoatShearingEventMonthActivities' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goatClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCategory' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGoatMonthLivestockActivitiesForShearingQuery,
  GetGoatMonthLivestockActivitiesForShearingQueryVariables
>;
export const GetGoatShearingEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGoatShearingEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'activityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'shearingEventsByGoatLivestockActivityId',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'goatLivestockActivityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'activityId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'GoatWoolShearingEventsFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoatWoolShearingEventsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatShearingEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberGoatShorn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'woolPerHeadKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cleanWoolYieldKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goatLivestockActivityId' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGoatShearingEventsQuery,
  GetGoatShearingEventsQueryVariables
>;
export const GetGoatShearingEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGoatShearingEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goatShearingEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'WoolUpdateGoatShearingEventFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WoolUpdateGoatShearingEventFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatShearingEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goatClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberGoatShorn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'woolPerHeadKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cleanWoolYieldKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goatLivestockActivityId' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGoatShearingEventQuery,
  GetGoatShearingEventQueryVariables
>;
export const CreateGoatShearingEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateGoatShearingEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'goatLivestockActivityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateGoatShearingEventInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createGoatShearingEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'goatLivestockActivityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'goatLivestockActivityId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'GoatWoolShearingEventsFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoatWoolShearingEventsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatShearingEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberGoatShorn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'woolPerHeadKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cleanWoolYieldKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goatLivestockActivityId' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateGoatShearingEventMutation,
  CreateGoatShearingEventMutationVariables
>;
export const UpdateGoatShearingEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateGoatShearingEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateGoatShearingEventInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateGoatShearingEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'GoatWoolShearingEventsFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoatWoolShearingEventsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatShearingEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberGoatShorn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'woolPerHeadKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cleanWoolYieldKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goatLivestockActivityId' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateGoatShearingEventMutation,
  UpdateGoatShearingEventMutationVariables
>;
export const DeleteGoatShearingEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteGoatShearingEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteGoatShearingEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteGoatShearingEventMutation,
  DeleteGoatShearingEventMutationVariables
>;
export const GetGoatActivityForShearingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGoatActivityForShearing' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goatLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'GoatShearingEventGoatLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoatShearingEventGoatLivestockActivity' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoatLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGoatActivityForShearingQuery,
  GetGoatActivityForShearingQueryVariables
>;
export const CreateGoatLivestockActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createGoatLivestockActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createGoatLivestockActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateGoatLivestockActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createGoatLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'createGoatLivestockActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'createGoatLivestockActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateGoatLivestockActivityMutation,
  CreateGoatLivestockActivityMutationVariables
>;
export const GetActivitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetActivities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActivitiesIndexActivity' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivitiesIndexActivity' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Activity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CroppingActivity' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'croppingType' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetActivitiesQuery, GetActivitiesQueryVariables>;
export const CreateActivityFeedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateActivityFeed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'activityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createFeedInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateFeedInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFeed' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'activityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'activityId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createFeedInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createFeedInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ActivityLivestockFeed_FeedFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityLivestockFeed_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
          { kind: 'Field', name: { kind: 'Name', value: 'grazingCrops' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mineralBlock' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weanerBlock' } },
          { kind: 'Field', name: { kind: 'Name', value: 'drySeasonMix' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateActivityFeedMutation,
  CreateActivityFeedMutationVariables
>;
export const UpdateActivityFeedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateActivityFeed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateFeedInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFeedInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFeed' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateFeedInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateFeedInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ActivityLivestockFeed_FeedFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityLivestockFeed_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
          { kind: 'Field', name: { kind: 'Name', value: 'grazingCrops' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mineralBlock' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weanerBlock' } },
          { kind: 'Field', name: { kind: 'Name', value: 'drySeasonMix' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateActivityFeedMutation,
  UpdateActivityFeedMutationVariables
>;
export const GetLivestockTransactionEventForUpdateTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetLivestockTransactionEventForUpdateTransaction',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'UpdateTransaction_LivestockTransactionEventFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_LivestockTransactionEventFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LivestockTransactionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionRegion' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headPurchasedMerino' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UpdateTransaction_LivestockTransactionEventFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LivestockTransactionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'TransactionForm_LivestockTransactionEventFragment',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLivestockTransactionEventForUpdateTransactionQuery,
  GetLivestockTransactionEventForUpdateTransactionQueryVariables
>;
export const DeleteLivestockTransactionEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteLivestockTransactionEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteLivestockTransactionEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'UpdateTransaction_LivestockTransactionEventFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_LivestockTransactionEventFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LivestockTransactionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionRegion' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headPurchasedMerino' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UpdateTransaction_LivestockTransactionEventFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LivestockTransactionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'TransactionForm_LivestockTransactionEventFragment',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteLivestockTransactionEventMutation,
  DeleteLivestockTransactionEventMutationVariables
>;
export const GetPorkFeedBlendDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPorkFeedBlend' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'porkFeedBlend' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PorkFeedBlendEditFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PorkFeedBlendEditFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkFeedBlend' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPurchasedTonnes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'barley' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beetPulp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blendType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bloodMeal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canolaMeal' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissionsIntensityFeedProduction' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fishMeal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'meatMeal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'millMix' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageAdditionalIngredients' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'soybeanMeal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tallow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wheat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wheatBran' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wheyPowder' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPorkFeedBlendQuery,
  GetPorkFeedBlendQueryVariables
>;
export const DeletePorkFeedBlendDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePorkFeedBlend' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePorkFeedBlend' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PorkFeedBlendEditFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PorkFeedBlendEditFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkFeedBlend' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPurchasedTonnes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'barley' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beetPulp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blendType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bloodMeal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canolaMeal' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissionsIntensityFeedProduction' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fishMeal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'meatMeal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'millMix' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageAdditionalIngredients' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'soybeanMeal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tallow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wheat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wheatBran' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wheyPowder' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePorkFeedBlendMutation,
  DeletePorkFeedBlendMutationVariables
>;
export const UpdatePorkFeedBlendDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePorkFeedBlend' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updatePorkFeedBlendInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePorkFeedBlendInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePorkFeedBlend' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updatePorkFeedBlendInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updatePorkFeedBlendInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PorkFeedBlendEditFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PorkFeedBlendEditFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkFeedBlend' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPurchasedTonnes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'barley' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beetPulp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blendType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bloodMeal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canolaMeal' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissionsIntensityFeedProduction' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fishMeal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'meatMeal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'millMix' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageAdditionalIngredients' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'soybeanMeal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tallow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wheat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wheatBran' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wheyPowder' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePorkFeedBlendMutation,
  UpdatePorkFeedBlendMutationVariables
>;
export const CreatePorkFeedBlendDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePorkFeedBlend' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'porkLivestockActivityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createPorkFeedBlendInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePorkFeedBlendInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPorkFeedBlend' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'porkLivestockActivityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'porkLivestockActivityId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createPorkFeedBlendInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createPorkFeedBlendInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PorkFeedBlendEditFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PorkFeedBlendEditFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkFeedBlend' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPurchasedTonnes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'barley' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beetPulp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blendType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bloodMeal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canolaMeal' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissionsIntensityFeedProduction' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fishMeal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'meatMeal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'millMix' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageAdditionalIngredients' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'soybeanMeal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tallow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wheat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wheatBran' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wheyPowder' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePorkFeedBlendMutation,
  CreatePorkFeedBlendMutationVariables
>;
export const GetPorkActivityFeedBlendsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPorkActivityFeedBlends' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'porkLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PorkLivestockActivityFeedBlendsFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PorkFeedBlendsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkFeedBlend' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPurchasedTonnes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'blendType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PorkLivestockActivityFeedBlendsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grazingCrops' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedBlends' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PorkFeedBlendsFragment' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPorkActivityFeedBlendsQuery,
  GetPorkActivityFeedBlendsQueryVariables
>;
export const GetPorkActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPorkActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'porkLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PorkActivityLivestockPorkLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PorkActivityLivestockClassesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sows' } },
          { kind: 'Field', name: { kind: 'Name', value: 'boars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gilts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weaners' } },
          { kind: 'Field', name: { kind: 'Name', value: 'growers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suckers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slaughterPigs' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PorkActivityLivestockPorkLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'PorkActivityLivestockClassesFragment',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPorkActivityQuery,
  GetPorkActivityQueryVariables
>;
export const GetPorkMonthLivestockActivitiesForActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetPorkMonthLivestockActivitiesForActivity',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'listPorkMonthLivestockActivitiesInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ListPorkMonthLivestockActivitiesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'porkMonthLivestockActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'listPorkMonthLivestockActivitiesInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'listPorkMonthLivestockActivitiesInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PorkLivestockActivity_MonthActivities',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PorkLivestockActivity_MonthActivities' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'porkClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPorkMonthLivestockActivitiesForActivityQuery,
  GetPorkMonthLivestockActivitiesForActivityQueryVariables
>;
export const GetPorkActivityInHeaderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPorkActivityInHeader' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'porkLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesActivitiesHeaderPorkActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertiesBusinessActivitiesActivitiesHeaderPorkActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPorkActivityInHeaderQuery,
  GetPorkActivityInHeaderQueryVariables
>;
export const UpdatePorkLivestockActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePorkLivestockActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updatePorkLivestockActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePorkLivestockActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePorkLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updatePorkLivestockActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updatePorkLivestockActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PorkActivityLivestockClassesFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PorkActivityLivestockClassesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sows' } },
          { kind: 'Field', name: { kind: 'Name', value: 'boars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gilts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weaners' } },
          { kind: 'Field', name: { kind: 'Name', value: 'growers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suckers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slaughterPigs' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePorkLivestockActivityMutation,
  UpdatePorkLivestockActivityMutationVariables
>;
export const UpdatePorkMonthLivestockActivityForMonthlyBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'updatePorkMonthLivestockActivityForMonthlyBreakdown',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'updatePorkMonthLivestockActivityInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdatePorkMonthLivestockActivityInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePorkMonthLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updatePorkMonthLivestockActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updatePorkMonthLivestockActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PorkMonthlyBreakdown_PorkMonthLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PorkMonthlyForm_PorkMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PorkMonthlyBreakdown_PorkMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'PorkMonthlyForm_PorkMonthLivestockActivity',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePorkMonthLivestockActivityForMonthlyBreakdownMutation,
  UpdatePorkMonthLivestockActivityForMonthlyBreakdownMutationVariables
>;
export const CreatePorkLivestockTransactionEventForUpdateTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'createPorkLivestockTransactionEventForUpdateTransaction',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'porkMonthLivestockActivityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'createLivestockTransactionEventInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreateLivestockTransactionEventInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'createPorkLivestockTransactionEvent',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'porkMonthLivestockActivityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'porkMonthLivestockActivityId' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'createLivestockTransactionEventInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'createLivestockTransactionEventInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'UpdateTransaction_LivestockTransactionEventFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_LivestockTransactionEventFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LivestockTransactionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionRegion' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headPurchasedMerino' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UpdateTransaction_LivestockTransactionEventFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LivestockTransactionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'TransactionForm_LivestockTransactionEventFragment',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePorkLivestockTransactionEventForUpdateTransactionMutation,
  CreatePorkLivestockTransactionEventForUpdateTransactionMutationVariables
>;
export const GetPorkActivityForMonthlyBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPorkActivityForMonthlyBreakdown' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'porkLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PorkMonthlyBreakdown_PorkLivestockActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PorkMonthlyBreakdown_PorkLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sows' } },
          { kind: 'Field', name: { kind: 'Name', value: 'boars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gilts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weaners' } },
          { kind: 'Field', name: { kind: 'Name', value: 'growers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suckers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slaughterPigs' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPorkActivityForMonthlyBreakdownQuery,
  GetPorkActivityForMonthlyBreakdownQueryVariables
>;
export const GetPorkMonthLivestockActivitiesForMonthlyBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetPorkMonthLivestockActivitiesForMonthlyBreakdown',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'listPorkMonthLivestockActivitiesInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ListPorkMonthLivestockActivitiesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'porkMonthLivestockActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'listPorkMonthLivestockActivitiesInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'listPorkMonthLivestockActivitiesInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PorkMonthlyBreakdown_PorkMonthLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PorkMonthlyForm_PorkMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PorkMonthlyBreakdown_PorkMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'PorkMonthlyForm_PorkMonthLivestockActivity',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPorkMonthLivestockActivitiesForMonthlyBreakdownQuery,
  GetPorkMonthLivestockActivitiesForMonthlyBreakdownQueryVariables
>;
export const GetPorkMonthLivestockActivityForCreateUpdateTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetPorkMonthLivestockActivityForCreateUpdateTransaction',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'porkMonthLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'CreateTransaction_PorkMonthLivestockActivityFragment',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'UpdateTransaction_PorkMonthLivestockActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'TransactionForm_PorkMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'porkClass' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'CreateTransaction_PorkMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'TransactionForm_PorkMonthLivestockActivityFragment',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UpdateTransaction_PorkMonthLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PorkMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'TransactionForm_PorkMonthLivestockActivityFragment',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPorkMonthLivestockActivityForCreateUpdateTransactionQuery,
  GetPorkMonthLivestockActivityForCreateUpdateTransactionQueryVariables
>;
export const CreatePorkLivestockActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPorkLivestockActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createPorkLivestockActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePorkLivestockActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPorkLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'createPorkLivestockActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'createPorkLivestockActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePorkLivestockActivityMutation,
  CreatePorkLivestockActivityMutationVariables
>;
export const GetPoultryGrowerBatchFeedBlendDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPoultryGrowerBatchFeedBlend' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poultryFeedBlend' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryGrowerBatchFeedBlendFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryGrowerBatchFeedBlendFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryFeedBlend' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'blendType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barleyWhole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'millRun' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sorghum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'soybean' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wheat' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPurchasedTonnes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageAdditionalIngredients' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissionsIntensityFeedProduction' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPoultryGrowerBatchFeedBlendQuery,
  GetPoultryGrowerBatchFeedBlendQueryVariables
>;
export const CreateGrowerBatchFeedBlendDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateGrowerBatchFeedBlend' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'batchId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'createPoultryGrowerBatchFeedBlendInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreatePoultryGrowerBatchFeedBlendInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPoultryGrowerBatchFeedBlend' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'batchId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'batchId' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'createPoultryGrowerBatchFeedBlendInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'createPoultryGrowerBatchFeedBlendInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryGrowerBatchFeedBlendFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryGrowerBatchFeedBlendFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryFeedBlend' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'blendType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barleyWhole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'millRun' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sorghum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'soybean' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wheat' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPurchasedTonnes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageAdditionalIngredients' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissionsIntensityFeedProduction' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateGrowerBatchFeedBlendMutation,
  CreateGrowerBatchFeedBlendMutationVariables
>;
export const UpdateGrowerBatchFeedBlendDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateGrowerBatchFeedBlend' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updatePoultryFeedBlendInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePoultryFeedBlendInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePoultryFeedBlend' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updatePoultryFeedBlendInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updatePoultryFeedBlendInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryGrowerBatchFeedBlendFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryGrowerBatchFeedBlendFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryFeedBlend' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'blendType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barleyWhole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'millRun' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sorghum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'soybean' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wheat' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPurchasedTonnes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageAdditionalIngredients' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissionsIntensityFeedProduction' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateGrowerBatchFeedBlendMutation,
  UpdateGrowerBatchFeedBlendMutationVariables
>;
export const DeletePoultryGrowerBatchFeedBlendDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePoultryGrowerBatchFeedBlend' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePoultryFeedBlend' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePoultryGrowerBatchFeedBlendMutation,
  DeletePoultryGrowerBatchFeedBlendMutationVariables
>;
export const GetPoultryBroilerBatchFeedBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPoultryBroilerBatchFeedBreakdown' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'batchId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'feedType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'PoultryGrowerBatchFeedBreakdownType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poultryGrowerBatchFeedBreakdown' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'batchId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'batchId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'feedType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'feedType' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryBroilersBatchFeedBreakdownFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PoultryBroilersBatchFeedBreakdownFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryFeedBreakdown' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dryMatterIntakeKgPerHeadPerDay' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dryMatterDigestibilityPercent' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crudeProteinPercent' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'manureAshPercent' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nitrogenRetentionPercent' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPoultryBroilerBatchFeedBreakdownQuery,
  GetPoultryBroilerBatchFeedBreakdownQueryVariables
>;
export const UpdatePoultryGrowerBatchFeedBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePoultryGrowerBatchFeedBreakdown' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'batchId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'feedType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'PoultryGrowerBatchFeedBreakdownType',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'updatePoultryGrowerBatchFeedBreakdownInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdatePoultryGrowerBatchFeedBreakdownInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'updatePoultryGrowerBatchFeedBreakdown',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'batchId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'batchId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'feedType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'feedType' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updatePoultryGrowerBatchFeedBreakdownInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updatePoultryGrowerBatchFeedBreakdownInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryBroilersBatchFeedBreakdownFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PoultryBroilersBatchFeedBreakdownFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryFeedBreakdown' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dryMatterIntakeKgPerHeadPerDay' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dryMatterDigestibilityPercent' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crudeProteinPercent' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'manureAshPercent' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nitrogenRetentionPercent' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePoultryGrowerBatchFeedBreakdownMutation,
  UpdatePoultryGrowerBatchFeedBreakdownMutationVariables
>;
export const GetPoultryBroilerBatchFlockTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPoultryBroilerBatchFlockType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'batchId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'detailsType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PoultryGrowerBatchDetailsType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poultryGrowerBatchDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'batchId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'batchId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'detailsType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'detailsType' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryBroilersBatchDetailsFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilersBatchDetailsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryGrowerBatchDetails' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalNumberOfBirds' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLengthOfStayDaysL1' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightPerHeadKgW1' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLengthOfStayDaysL2' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightPerHeadKgW2' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPoultryBroilerBatchFlockTypeQuery,
  GetPoultryBroilerBatchFlockTypeQueryVariables
>;
export const UpdatePoultryGrowerBatchFlockDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePoultryGrowerBatchFlockDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'batchId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'detailsType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PoultryGrowerBatchDetailsType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'updatePoultryGrowerBatchDetailsInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdatePoultryGrowerBatchDetailsInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePoultryGrowerBatchDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'batchId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'batchId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'detailsType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'detailsType' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updatePoultryGrowerBatchDetailsInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updatePoultryGrowerBatchDetailsInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryBroilersBatchDetailsFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilersBatchDetailsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryGrowerBatchDetails' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalNumberOfBirds' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLengthOfStayDaysL1' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightPerHeadKgW1' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLengthOfStayDaysL2' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightPerHeadKgW2' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePoultryGrowerBatchFlockDetailsMutation,
  UpdatePoultryGrowerBatchFlockDetailsMutationVariables
>;
export const GetPoultryActivityInGrowerBatchHeaderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPoultryActivityInGrowerBatchHeader' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poultryBroilerActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesActivitiesHeaderGrowerBatch',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertiesBusinessActivitiesActivitiesHeaderGrowerBatch',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryBroilerActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPoultryActivityInGrowerBatchHeaderQuery,
  GetPoultryActivityInGrowerBatchHeaderQueryVariables
>;
export const GetPoultryBroilerBatchHeaderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPoultryBroilerBatchHeader' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poultryGrowerBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryBroilersGrowerBatchLayoutFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilersGrowerBatchLayoutFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryGrowerBatch' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPoultryBroilerBatchHeaderQuery,
  GetPoultryBroilerBatchHeaderQueryVariables
>;
export const GetPoultryBroilerBatchFlockDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPoultryBroilerBatchFlock' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poultryGrowerBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryBroilersBatchFlockFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilersBatchFlockFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryGrowerBatch' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'growers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'other' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPoultryBroilerBatchFlockQuery,
  GetPoultryBroilerBatchFlockQueryVariables
>;
export const GetPoultryBroilerBatchFeedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPoultryBroilerBatchFeed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poultryGrowerBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryBroilersBatchFeedFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilersBatchFeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryGrowerBatch' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
          { kind: 'Field', name: { kind: 'Name', value: 'grazingCrops' } },
          { kind: 'Field', name: { kind: 'Name', value: 'growers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'other' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedBlends' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amountPurchasedTonnes' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'blendType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPoultryBroilerBatchFeedQuery,
  GetPoultryBroilerBatchFeedQueryVariables
>;
export const UpdatePoultryGrowerBatchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePoultryGrowerBatch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updatePoultryGrowerBatchInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePoultryGrowerBatchInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePoultryGrowerBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updatePoultryGrowerBatchInput' },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updatePoultryGrowerBatchInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PoultryBroilersBatchFragment' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryBroilersBatchFlockFragment',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryBroilersBatchFeedFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilersBatchFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryGrowerBatch' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilersBatchFlockFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryGrowerBatch' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'growers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'other' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilersBatchFeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryGrowerBatch' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
          { kind: 'Field', name: { kind: 'Name', value: 'grazingCrops' } },
          { kind: 'Field', name: { kind: 'Name', value: 'growers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'other' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedBlends' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amountPurchasedTonnes' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'blendType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePoultryGrowerBatchMutation,
  UpdatePoultryGrowerBatchMutationVariables
>;
export const GetPoultryBroilerBatchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPoultryBroilerBatch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poultryGrowerBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PoultryBroilersBatchFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilersBatchFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryGrowerBatch' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPoultryBroilerBatchQuery,
  GetPoultryBroilerBatchQueryVariables
>;
export const CreateGrowerBatchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateGrowerBatch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'poultryActivityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createPoultryGrowerBatchInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePoultryGrowerBatchInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPoultryGrowerBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'poultryActivityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'poultryActivityId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createPoultryGrowerBatchInput' },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'createPoultryGrowerBatchInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PoultryBroilersBatchFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilersBatchFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryGrowerBatch' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateGrowerBatchMutation,
  CreateGrowerBatchMutationVariables
>;
export const DeletePoultryGrowerBatchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePoultryGrowerBatch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePoultryGrowerBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePoultryGrowerBatchMutation,
  DeletePoultryGrowerBatchMutationVariables
>;
export const GetPoultryBroilerBatchesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPoultryBroilerBatches' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poultryBroilerActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryBroilers_BatchesFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilers_BatchesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryBroilerActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'growerBatches' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPoultryBroilerBatchesQuery,
  GetPoultryBroilerBatchesQueryVariables
>;
export const GetPoultryBroilerTransactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPoultryBroilerTransactions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poultryBroilerActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryBroilersTransactionsFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilersTransactionsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryBroilerActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'class' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPoultryBroilerTransactionsQuery,
  GetPoultryBroilerTransactionsQueryVariables
>;
export const GetPoultryActivityInHeaderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPoultryActivityInHeader' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poultryBroilerActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesActivitiesHeaderPoultryActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertiesBusinessActivitiesActivitiesHeaderPoultryActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryBroilerActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPoultryActivityInHeaderQuery,
  GetPoultryActivityInHeaderQueryVariables
>;
export const GetPoultryBroilerManureLitterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPoultryBroilerManureLitter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poultryBroilerActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryBroilersManureLitterFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilersManureLitterFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryBroilerActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentManureAddedToPasture' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentSolidManure' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageLitterRecycled' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'cyclesPerYear' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPoultryBroilerManureLitterQuery,
  GetPoultryBroilerManureLitterQueryVariables
>;
export const UpdateBroilerManureLitterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBroilerManureLitter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updatePoultryBroilerActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdatePoultryBroilerActivityInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePoultryBroilerActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updatePoultryBroilerActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updatePoultryBroilerActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryBroilersManureLitterFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilersManureLitterFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryBroilerActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentManureAddedToPasture' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentSolidManure' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageLitterRecycled' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'cyclesPerYear' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBroilerManureLitterMutation,
  UpdateBroilerManureLitterMutationVariables
>;
export const UpdatePoultryBroilerTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePoultryBroilerTransaction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updatePoultryTransactionEventInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdatePoultryTransactionEventInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePoultryTransactionEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updatePoultryTransactionEventInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updatePoultryTransactionEventInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryBroilersTransactionFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilersTransactionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryTransactionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageWeightKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentPurchasedFreeRange' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePoultryBroilerTransactionMutation,
  UpdatePoultryBroilerTransactionMutationVariables
>;
export const GetPoultryBroilerTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPoultryBroilerTransaction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poultryTransactionEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryBroilersTransactionFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilersTransactionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryTransactionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageWeightKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentPurchasedFreeRange' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPoultryBroilerTransactionQuery,
  GetPoultryBroilerTransactionQueryVariables
>;
export const CreateBroilerTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateBroilerTransaction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'poultryBroilerActivityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createPoultryTransactionEventInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreatePoultryTransactionEventInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'createPoultryBroilerTransactionEvent',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'poultryBroilerActivityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'poultryBroilerActivityId' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'createPoultryTransactionEventInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'createPoultryTransactionEventInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryBroilersTransactionFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryBroilersTransactionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryTransactionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageWeightKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentPurchasedFreeRange' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateBroilerTransactionMutation,
  CreateBroilerTransactionMutationVariables
>;
export const DeletePoultryTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePoultryTransaction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePoultryTransactionEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePoultryTransactionMutation,
  DeletePoultryTransactionMutationVariables
>;
export const CreatePoultryBroilerActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPoultryBroilerActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createPoultryBroilerActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreatePoultryBroilerActivityInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPoultryBroilerActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'createPoultryBroilerActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'createPoultryBroilerActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePoultryBroilerActivityMutation,
  CreatePoultryBroilerActivityMutationVariables
>;
export const GetPoultryLayerEggSalesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPoultryLayerEggSales' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poultryLayerActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PoultryLayerEggSalesFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayerEggSalesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryLayerActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalLayerEggsProduced' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLayerEggWeightGrams' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalMeatChickenLayerEggsProduced' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'averageMeatChickenLayerEggWeightGrams',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPoultryLayerEggSalesQuery,
  GetPoultryLayerEggSalesQueryVariables
>;
export const UpdateLayerEggSalesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateLayerEggSales' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updatePoultryLayerActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePoultryLayerActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePoultryLayerActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updatePoultryLayerActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updatePoultryLayerActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PoultryLayerEggSalesFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayerEggSalesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryLayerActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalLayerEggsProduced' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLayerEggWeightGrams' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalMeatChickenLayerEggsProduced' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'averageMeatChickenLayerEggWeightGrams',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateLayerEggSalesMutation,
  UpdateLayerEggSalesMutationVariables
>;
export const GetPoultryLayerActivityFeedBlendDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPoultryLayerActivityFeedBlend' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poultryFeedBlend' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryLayerActivityFeedBlendFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayerActivityFeedBlendFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryFeedBlend' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'blendType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barleyWhole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'millRun' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sorghum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'soybean' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wheat' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPurchasedTonnes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageAdditionalIngredients' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissionsIntensityFeedProduction' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPoultryLayerActivityFeedBlendQuery,
  GetPoultryLayerActivityFeedBlendQueryVariables
>;
export const CreatePoultryLayerFeedBlendDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePoultryLayerFeedBlend' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'poultryLayerActivityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createPoultryLayerFeedBlendInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePoultryLayerFeedBlendInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPoultryLayerFeedBlend' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'poultryLayerActivityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'poultryLayerActivityId' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'createPoultryLayerFeedBlendInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'createPoultryLayerFeedBlendInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryLayerActivityFeedBlendFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayerActivityFeedBlendFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryFeedBlend' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'blendType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barleyWhole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'millRun' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sorghum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'soybean' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wheat' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPurchasedTonnes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageAdditionalIngredients' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissionsIntensityFeedProduction' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePoultryLayerFeedBlendMutation,
  CreatePoultryLayerFeedBlendMutationVariables
>;
export const UpdatePoultryLayerFeedBlendDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePoultryLayerFeedBlend' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updatePoultryFeedBlendInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePoultryFeedBlendInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePoultryFeedBlend' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updatePoultryFeedBlendInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updatePoultryFeedBlendInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryLayerActivityFeedBlendFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayerActivityFeedBlendFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryFeedBlend' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'blendType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barleyWhole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'millRun' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sorghum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'soybean' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wheat' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPurchasedTonnes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageAdditionalIngredients' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissionsIntensityFeedProduction' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePoultryLayerFeedBlendMutation,
  UpdatePoultryLayerFeedBlendMutationVariables
>;
export const DeletePoultryLayerActivityFeedBlendDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePoultryLayerActivityFeedBlend' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePoultryFeedBlend' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePoultryLayerActivityFeedBlendMutation,
  DeletePoultryLayerActivityFeedBlendMutationVariables
>;
export const GetPoultryLayerFeedBlendsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPoultryLayerFeedBlends' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poultryLayerActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryLayerFeedBlendsFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayerFeedBlendsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryLayerActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedBlends' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'blendType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amountPurchasedTonnes' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
          { kind: 'Field', name: { kind: 'Name', value: 'grazingCrops' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPoultryLayerFeedBlendsQuery,
  GetPoultryLayerFeedBlendsQueryVariables
>;
export const UpdatePoultryLayerFeedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePoultryLayerFeed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updatePoultryLayerActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePoultryLayerActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePoultryLayerActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updatePoultryLayerActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updatePoultryLayerActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryLayerFeedBlendsFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayerFeedBlendsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryLayerActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedBlends' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'blendType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amountPurchasedTonnes' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
          { kind: 'Field', name: { kind: 'Name', value: 'grazingCrops' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePoultryLayerFeedMutation,
  UpdatePoultryLayerFeedMutationVariables
>;
export const UpdatePoultryLayerMonthActivityForMonthlyBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'updatePoultryLayerMonthActivityForMonthlyBreakdown',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'updatePoultryLayerMonthActivityInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdatePoultryLayerMonthActivityInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePoultryLayerMonthActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updatePoultryLayerMonthActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updatePoultryLayerMonthActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryLayerFlockFormMonthlyActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayerFlockFormMonthlyActivity' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryLayerMonthActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meatChickenLayerCount' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePoultryLayerMonthActivityForMonthlyBreakdownMutation,
  UpdatePoultryLayerMonthActivityForMonthlyBreakdownMutationVariables
>;
export const GetPoultryLayerInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPoultryLayerInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poultryLayerActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryLayerActivityInfoFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayerFlockFormMonthlyActivity' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryLayerMonthActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meatChickenLayerCount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayerActivityInfoFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryLayerActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poultryLayerMonthActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryLayerFlockFormMonthlyActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPoultryLayerInfoQuery,
  GetPoultryLayerInfoQueryVariables
>;
export const GetPoultryLayerInHeaderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPoultryLayerInHeader' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poultryLayerActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryLayerActivityHeaderFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayerActivityHeaderFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryLayerActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPoultryLayerInHeaderQuery,
  GetPoultryLayerInHeaderQueryVariables
>;
export const GetPoultryLayerManureLitterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPoultryLayerManureLitter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poultryLayerActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryLayerManureLitterFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayerManureLitterFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryLayerActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentManureAddedToPasture' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageLitterRecycled' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentSolidManure' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'cyclesPerYear' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPoultryLayerManureLitterQuery,
  GetPoultryLayerManureLitterQueryVariables
>;
export const UpdateLayerManureLitterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateLayerManureLitter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updatePoultryLayerActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePoultryLayerActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePoultryLayerActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updatePoultryLayerActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updatePoultryLayerActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryLayerManureLitterFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayerManureLitterFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryLayerActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentManureAddedToPasture' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageLitterRecycled' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentSolidManure' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'cyclesPerYear' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateLayerManureLitterMutation,
  UpdateLayerManureLitterMutationVariables
>;
export const UpdatePoultryLayerTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePoultryLayerTransaction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updatePoultryTransactionEventInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdatePoultryTransactionEventInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePoultryTransactionEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updatePoultryTransactionEventInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updatePoultryTransactionEventInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryLayersTransactionFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayersTransactionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryTransactionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageWeightKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentPurchasedFreeRange' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePoultryLayerTransactionMutation,
  UpdatePoultryLayerTransactionMutationVariables
>;
export const GetPoultryLayerTransactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPoultryLayerTransactions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poultryLayerActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryLayerActivityTransactionsFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayerActivityTransactionsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryLayerActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'class' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPoultryLayerTransactionsQuery,
  GetPoultryLayerTransactionsQueryVariables
>;
export const GetPoultryLayerTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPoultryLayerTransaction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'poultryTransactionEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryLayersTransactionFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayersTransactionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryTransactionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageWeightKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentPurchasedFreeRange' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPoultryLayerTransactionQuery,
  GetPoultryLayerTransactionQueryVariables
>;
export const CreateLayerTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateLayerTransaction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'poultryLayerActivityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createPoultryTransactionEventInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreatePoultryTransactionEventInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPoultryLayerTransactionEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'poultryLayerActivityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'poultryLayerActivityId' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'createPoultryTransactionEventInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'createPoultryTransactionEventInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PoultryLayersTransactionFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PoultryLayersTransactionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PoultryTransactionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalWeightKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageWeightKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentPurchasedFreeRange' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateLayerTransactionMutation,
  CreateLayerTransactionMutationVariables
>;
export const CreatePoultryLayerActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPoultryLayerActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createPoultryLayerActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePoultryLayerActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPoultryLayerActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'createPoultryLayerActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'createPoultryLayerActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePoultryLayerActivityMutation,
  CreatePoultryLayerActivityMutationVariables
>;
export const UpdateSheepMonthLivestockActivityForMonthlyBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'updateSheepMonthLivestockActivityForMonthlyBreakdown',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'updateSheepMonthLivestockActivityInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateSheepMonthLivestockActivityInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSheepMonthLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updateSheepMonthLivestockActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updateSheepMonthLivestockActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SheepMonthlyBreakdown_SheepMonthLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepMonthlyForm_SheepMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previousMonthLiveweightKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageEwesLambing' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'seasonalLambingRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepMonthlyBreakdown_SheepMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'SheepMonthlyForm_SheepMonthLivestockActivity',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSheepMonthLivestockActivityForMonthlyBreakdownMutation,
  UpdateSheepMonthLivestockActivityForMonthlyBreakdownMutationVariables
>;
export const CreateSheepLivestockTransactionEventForUpdateTransactionDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'mutation',
        name: {
          kind: 'Name',
          value: 'createSheepLivestockTransactionEventForUpdateTransaction',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'sheepMonthLivestockActivityId' },
            },
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: {
                kind: 'Name',
                value: 'createLivestockTransactionEventInput',
              },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'CreateLivestockTransactionEventInput',
                },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: {
                kind: 'Name',
                value: 'createSheepLivestockTransactionEvent',
              },
              arguments: [
                {
                  kind: 'Argument',
                  name: {
                    kind: 'Name',
                    value: 'sheepMonthLivestockActivityId',
                  },
                  value: {
                    kind: 'Variable',
                    name: {
                      kind: 'Name',
                      value: 'sheepMonthLivestockActivityId',
                    },
                  },
                },
                {
                  kind: 'Argument',
                  name: {
                    kind: 'Name',
                    value: 'createLivestockTransactionEventInput',
                  },
                  value: {
                    kind: 'Variable',
                    name: {
                      kind: 'Name',
                      value: 'createLivestockTransactionEventInput',
                    },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'UpdateTransaction_LivestockTransactionEventFragment',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'TransactionForm_LivestockTransactionEventFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'LivestockTransactionEvent' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
            { kind: 'Field', name: { kind: 'Name', value: 'livestockCount' } },
            { kind: 'Field', name: { kind: 'Name', value: 'totalWeightKg' } },
            { kind: 'Field', name: { kind: 'Name', value: 'averageWeightKg' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'transactionRegion' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'headPurchasedMerino' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UpdateTransaction_LivestockTransactionEventFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'LivestockTransactionEvent' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'TransactionForm_LivestockTransactionEventFragment',
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    CreateSheepLivestockTransactionEventForUpdateTransactionMutation,
    CreateSheepLivestockTransactionEventForUpdateTransactionMutationVariables
  >;
export const GetSheepActivityForMonthlyBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSheepActivityForMonthlyBreakdown' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheepLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'SheepMonthlyBreakdown_SheepLivestockActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepMonthlyBreakdown_SheepLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'ewesGt2Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eweLambsLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherEwesGt2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingEwes1To2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wetherLambsLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wethersGt1Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ramsBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ewesGt2Trading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eweLambsLt1Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherEwesGt2Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingEwes1To2Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wetherLambsLt1Trading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'wethersGt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ramsTrading' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSheepActivityForMonthlyBreakdownQuery,
  GetSheepActivityForMonthlyBreakdownQueryVariables
>;
export const GetSheepMonthLivestockActivitiesForMonthlyBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetSheepMonthLivestockActivitiesForMonthlyBreakdown',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'listSheepMonthLivestockActivitiesInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ListSheepMonthLivestockActivitiesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheepMonthLivestockActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'listSheepMonthLivestockActivitiesInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'listSheepMonthLivestockActivitiesInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SheepMonthlyBreakdown_SheepMonthLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepMonthlyForm_SheepMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previousMonthLiveweightKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSalePopulationChange' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageEwesLambing' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'seasonalLambingRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'keyEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livestockTransactionEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestockCount' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepMonthlyBreakdown_SheepMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'SheepMonthlyForm_SheepMonthLivestockActivity',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSheepMonthLivestockActivitiesForMonthlyBreakdownQuery,
  GetSheepMonthLivestockActivitiesForMonthlyBreakdownQueryVariables
>;
export const GetSheepMonthLivestockActivityForCreateUpdateTransactionDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'query',
        name: {
          kind: 'Name',
          value: 'GetSheepMonthLivestockActivityForCreateUpdateTransaction',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'sheepMonthLivestockActivity' },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'id' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'id' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'CreateTransaction_SheepMonthLivestockActivityFragment',
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'UpdateTransaction_SheepMonthLivestockActivityFragment',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'TransactionForm_SheepMonthLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'year' } },
            { kind: 'Field', name: { kind: 'Name', value: 'month' } },
            { kind: 'Field', name: { kind: 'Name', value: 'sheepClass' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'CreateTransaction_SheepMonthLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'TransactionForm_SheepMonthLivestockActivityFragment',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UpdateTransaction_SheepMonthLivestockActivityFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'TransactionForm_SheepMonthLivestockActivityFragment',
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    GetSheepMonthLivestockActivityForCreateUpdateTransactionQuery,
    GetSheepMonthLivestockActivityForCreateUpdateTransactionQueryVariables
  >;
export const UpdateSheepMonthLivestockActivityForMonthlyFeedBreakdownDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'mutation',
        name: {
          kind: 'Name',
          value: 'updateSheepMonthLivestockActivityForMonthlyFeedBreakdown',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: {
                kind: 'Name',
                value: 'updateSheepMonthLivestockActivityInput',
              },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'UpdateSheepMonthLivestockActivityInput',
                },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: {
                kind: 'Name',
                value: 'updateSheepMonthLivestockActivity',
              },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'id' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'id' },
                  },
                },
                {
                  kind: 'Argument',
                  name: {
                    kind: 'Name',
                    value: 'updateSheepMonthLivestockActivityInput',
                  },
                  value: {
                    kind: 'Variable',
                    name: {
                      kind: 'Name',
                      value: 'updateSheepMonthLivestockActivityInput',
                    },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'UseSheepMonthlyFeedBreakdown_SheepMonthLivestockActivity',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseSheepMonthlyFeedBreakdown_SheepMonthLivestockActivity',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SheepMonthLivestockActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'month' } },
            { kind: 'Field', name: { kind: 'Name', value: 'year' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'crudeProteinPercentage' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'dryMatterDigestibilityPercentage' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'feedAvailabilityTonnesPerHectare' },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UpdateSheepMonthLivestockActivityForMonthlyFeedBreakdownMutation,
    UpdateSheepMonthLivestockActivityForMonthlyFeedBreakdownMutationVariables
  >;
export const GetSheepActivityForMonthlyFeedBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSheepActivityForMonthlyFeedBreakdown' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheepLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseSheepMonthlyFeedBreakdown_ActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseSheepMonthlyFeedBreakdown_ActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'ewesGt2Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eweLambsLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherEwesGt2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingEwes1To2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wetherLambsLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wethersGt1Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ramsBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ewesGt2Trading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eweLambsLt1Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherEwesGt2Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingEwes1To2Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wetherLambsLt1Trading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'wethersGt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ramsTrading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSheepActivityForMonthlyFeedBreakdownQuery,
  GetSheepActivityForMonthlyFeedBreakdownQueryVariables
>;
export const GetSheepMonthLivestockActivitiesForMonthlyFeedBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetSheepMonthLivestockActivitiesForMonthlyFeedBreakdown',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'listSheepMonthLivestockActivitiesInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ListSheepMonthLivestockActivitiesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheepMonthLivestockActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'listSheepMonthLivestockActivitiesInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'listSheepMonthLivestockActivitiesInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'UseSheepMonthlyFeedBreakdown_SheepMonthLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseSheepMonthlyFeedBreakdown_SheepMonthLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crudeProteinPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dryMatterDigestibilityPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedAvailabilityTonnesPerHectare' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSheepMonthLivestockActivitiesForMonthlyFeedBreakdownQuery,
  GetSheepMonthLivestockActivitiesForMonthlyFeedBreakdownQueryVariables
>;
export const GetSheepLivestockActivityFeedDrySeasonMixDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetSheepLivestockActivityFeedDrySeasonMix',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheepLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'SheepFeedDrySeasonMix_SheepLivestockActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedDrySeasonMix_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'drySeasonMixKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'drySeasonMixUreaPercentage' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepFeedDrySeasonMix_SheepLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedDrySeasonMix_FeedFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSheepLivestockActivityFeedDrySeasonMixQuery,
  GetSheepLivestockActivityFeedDrySeasonMixQueryVariables
>;
export const GetSheepActivityFeedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSheepActivityFeed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheepLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'SheepActivityLivestockFeed_SheepLivestockActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepActivityLivestockFeed_SheepLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ewesGt2Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eweLambsLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherEwesGt2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingEwes1To2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wetherLambsLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wethersGt1Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ramsBreeding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ewesGt2Trading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eweLambsLt1Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherEwesGt2Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingEwes1To2Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wetherLambsLt1Trading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'wethersGt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ramsTrading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pasture' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grazingCrops' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mineralBlock' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'weanerBlock' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'drySeasonMix' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSheepActivityFeedQuery,
  GetSheepActivityFeedQueryVariables
>;
export const GetSheepLivestockActivityFeedMineralBlockDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetSheepLivestockActivityFeedMineralBlock',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheepLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'SheepFeedMineralBlock_SheepLivestockActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedMineralBlock_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mineralBlockKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mineralBlockUreaPercentage' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepFeedMineralBlock_SheepLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FeedMineralBlock_FeedFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSheepLivestockActivityFeedMineralBlockQuery,
  GetSheepLivestockActivityFeedMineralBlockQueryVariables
>;
export const UpdateSheepFeedWeanerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSheepFeedWeaner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateFeedInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFeedInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFeed' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateFeedInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateFeedInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FeedWeanerBlock_FeedFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedWeanerBlock_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weanerBlockKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'weanerBlockUreaPercentage' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSheepFeedWeanerMutation,
  UpdateSheepFeedWeanerMutationVariables
>;
export const GetSheepLivestockActivityFeedWeanerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSheepLivestockActivityFeedWeaner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheepLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'SheepFeedWeanerBlock_SheepLivestockActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedWeanerBlock_FeedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Feed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weanerBlockKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'weanerBlockUreaPercentage' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepFeedWeanerBlock_SheepLivestockActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FeedWeanerBlock_FeedFragment' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSheepLivestockActivityFeedWeanerQuery,
  GetSheepLivestockActivityFeedWeanerQueryVariables
>;
export const GetSheepActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSheepActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheepLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SheepActivityLivestockSheepLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepActivityLivestockBreedingSheepLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'ewesGt2Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eweLambsLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherEwesGt2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingEwes1To2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wetherLambsLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wethersGt1Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ramsBreeding' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepActivityLivestockTradingSheepLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'ewesGt2Trading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eweLambsLt1Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherEwesGt2Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingEwes1To2Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wetherLambsLt1Trading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'wethersGt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ramsTrading' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepActivityLivestockSheepLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'SheepActivityLivestockBreedingSheepLivestockActivity',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'SheepActivityLivestockTradingSheepLivestockActivity',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSheepActivityQuery,
  GetSheepActivityQueryVariables
>;
export const GetSheepMonthLivestockActivitiesForActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetSheepMonthLivestockActivitiesForActivity',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'listSheepMonthLivestockActivitiesInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ListSheepMonthLivestockActivitiesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheepMonthLivestockActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'listSheepMonthLivestockActivitiesInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'listSheepMonthLivestockActivitiesInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SheepLivestockActivity_MonthActivities',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheepLivestockActivity_MonthActivities' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageLiveweightKg' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sheepClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSheepMonthLivestockActivitiesForActivityQuery,
  GetSheepMonthLivestockActivitiesForActivityQueryVariables
>;
export const GetSheepActivityInHeaderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSheepActivityInHeader' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheepLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesActivitiesHeaderSheepActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertiesBusinessActivitiesActivitiesHeaderSheepActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSheepActivityInHeaderQuery,
  GetSheepActivityInHeaderQueryVariables
>;
export const UpdateSheepLivestockActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateSheepLivestockActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateSheepLivestockActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateSheepLivestockActivityInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSheepLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updateSheepLivestockActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updateSheepLivestockActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'SheepActivityLivestockBreedingSheepLivestockActivity',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'SheepActivityLivestockTradingSheepLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepActivityLivestockBreedingSheepLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'ewesGt2Breeding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eweLambsLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherEwesGt2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingEwes1To2Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wetherLambsLt1Breeding' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wethersGt1Breeding' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ramsBreeding' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SheepActivityLivestockTradingSheepLivestockActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'ewesGt2Trading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eweLambsLt1Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherEwesGt2Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maidenBreedingEwes1To2Trading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wetherLambsLt1Trading' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'wethersGt1Trading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ramsTrading' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSheepLivestockActivityMutation,
  UpdateSheepLivestockActivityMutationVariables
>;
export const GetSheepShearingEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSheepShearingEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'activityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'shearingEventsBySheepLivestockActivityId',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sheepLivestockActivityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'activityId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SheepWoolShearingEventsFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheepWoolShearingEventsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepShearingEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberSheepShorn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'woolPerHeadKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cleanWoolYieldKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheepLivestockActivityId' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSheepShearingEventsQuery,
  GetSheepShearingEventsQueryVariables
>;
export const GetSheepShearingEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSheepShearingEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheepShearingEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'WoolUpdateSheepShearingEventFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WoolUpdateSheepShearingEventFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepShearingEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sheepClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberSheepShorn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'woolPerHeadKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cleanWoolYieldKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheepLivestockActivityId' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSheepShearingEventQuery,
  GetSheepShearingEventQueryVariables
>;
export const CreateSheepShearingEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSheepShearingEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sheepLivestockActivityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateSheepShearingEventInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSheepShearingEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sheepLivestockActivityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sheepLivestockActivityId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SheepWoolShearingEventsFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheepWoolShearingEventsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepShearingEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberSheepShorn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'woolPerHeadKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cleanWoolYieldKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheepLivestockActivityId' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSheepShearingEventMutation,
  CreateSheepShearingEventMutationVariables
>;
export const UpdateSheepShearingEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSheepShearingEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateSheepShearingEventInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSheepShearingEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SheepWoolShearingEventsFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheepWoolShearingEventsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepShearingEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberSheepShorn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'woolPerHeadKg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cleanWoolYieldKg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheepLivestockActivityId' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSheepShearingEventMutation,
  UpdateSheepShearingEventMutationVariables
>;
export const DeleteSheepShearingEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSheepShearingEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSheepShearingEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteSheepShearingEventMutation,
  DeleteSheepShearingEventMutationVariables
>;
export const GetSheepActivityForShearingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSheepActivityForShearing' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheepLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SheepShearingEventSheepLivestockActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheepShearingEventSheepLivestockActivity' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSheepActivityForShearingQuery,
  GetSheepActivityForShearingQueryVariables
>;
export const GetSheepMonthLivestockActivitiesForShearingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetSheepMonthLivestockActivitiesForShearing',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'listSheepMonthLivestockActivitiesInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ListSheepMonthLivestockActivitiesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sheepMonthLivestockActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'listSheepMonthLivestockActivitiesInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'listSheepMonthLivestockActivitiesInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SheepShearingEventMonthActivities',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheepShearingEventMonthActivities' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SheepMonthLivestockActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headAtStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sheepClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livestockCategory' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSheepMonthLivestockActivitiesForShearingQuery,
  GetSheepMonthLivestockActivitiesForShearingQueryVariables
>;
export const CreateSheepLivestockActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSheepLivestockActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createSheepLivestockActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreateSheepLivestockActivityInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSheepLivestockActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'createSheepLivestockActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'createSheepLivestockActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSheepLivestockActivityMutation,
  CreateSheepLivestockActivityMutationVariables
>;
export const CreateVegetationActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createVegetationActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createVegetationActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateVegetationActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createVegetationActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createVegetationActivityInput' },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'createVegetationActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateVegetationActivityMutation,
  CreateVegetationActivityMutationVariables
>;
export const UpdateVegetationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateVegetation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateVegetationActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateVegetationActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateVegetationActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateVegetationActivityInput' },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updateVegetationActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'VegetationInfoFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VegetationInfoFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VegetationActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'region' } },
          { kind: 'Field', name: { kind: 'Name', value: 'soilType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'species' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageAge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'treesPerHectare' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalPlantingAreaSquareMetres' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plantingAreaGeometry' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateVegetationMutation,
  UpdateVegetationMutationVariables
>;
export const GetVegetationInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetVegetationInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vegetationActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'VegetationInfoFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VegetationInfoFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VegetationActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'region' } },
          { kind: 'Field', name: { kind: 'Name', value: 'soilType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'species' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageAge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'treesPerHectare' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalPlantingAreaSquareMetres' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plantingAreaGeometry' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetVegetationInfoQuery,
  GetVegetationInfoQueryVariables
>;
export const GetVegetationActivityInHeaderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetVegetationActivityInHeader' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vegetationActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesActivitiesHeaderVegetationActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertiesBusinessActivitiesActivitiesHeaderVegetationActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VegetationActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetVegetationActivityInHeaderQuery,
  GetVegetationActivityInHeaderQueryVariables
>;
export const GetBaitPurchasesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBaitPurchases' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'activityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'baitPurchasesByWildSeaFisheryActivityId',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'wildSeaFisheryActivityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'activityId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'WildSeaFisheryBaitBaitPurchasesFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WildSeaFisheryBaitBaitPurchasesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FisheryBaitPurchase' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baitType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPurchasedTonnes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageAdditionalIngredients' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissionsIntensityBaitProduct' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBaitPurchasesQuery,
  GetBaitPurchasesQueryVariables
>;
export const GetBaitPurchaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBaitPurchase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fisheryBaitPurchase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'WildSeaFisheryBaitPurchasesFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WildSeaFisheryBaitPurchasesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FisheryBaitPurchase' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baitType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPurchasedTonnes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageAdditionalIngredients' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissionsIntensityBaitProduct' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBaitPurchaseQuery,
  GetBaitPurchaseQueryVariables
>;
export const CreateBaitPurchaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateBaitPurchase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'wildSeaFisheryActivityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateFisheryBaitPurchaseInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFisheryBaitPurchase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'wildSeaFisheryActivityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'wildSeaFisheryActivityId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'WildSeaFisheryBaitPurchasesFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WildSeaFisheryBaitPurchasesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FisheryBaitPurchase' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baitType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPurchasedTonnes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageAdditionalIngredients' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissionsIntensityBaitProduct' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateBaitPurchaseMutation,
  CreateBaitPurchaseMutationVariables
>;
export const UpdateBaitPurchaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBaitPurchase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFisheryBaitPurchaseInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFisheryBaitPurchase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'WildSeaFisheryBaitPurchasesFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WildSeaFisheryBaitPurchasesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FisheryBaitPurchase' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baitType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountPurchasedTonnes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageAdditionalIngredients' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissionsIntensityBaitProduct' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBaitPurchaseMutation,
  UpdateBaitPurchaseMutationVariables
>;
export const DeleteBaitPurchaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteBaitPurchase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFisheryBaitPurchase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteBaitPurchaseMutation,
  DeleteBaitPurchaseMutationVariables
>;
export const GetWildSeaFisheryInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWildSeaFisheryInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wildSeaFisheryActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'WildSeaFisheryActivityInfoFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WildSeaFisheryActivityInfoFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WildSeaFisheryActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productionSystem' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalWeightCaughtKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offsetsPurchasedTonnes' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWildSeaFisheryInfoQuery,
  GetWildSeaFisheryInfoQueryVariables
>;
export const UpdateWildSeaFisheryActivityInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateWildSeaFisheryActivityInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateWildSeaFisheryActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateWildSeaFisheryActivityInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWildSeaFisheryActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'updateWildSeaFisheryActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'updateWildSeaFisheryActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'WildSeaFisheryActivityInfoFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WildSeaFisheryActivityInfoFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WildSeaFisheryActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productionSystem' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalWeightCaughtKg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offsetsPurchasedTonnes' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateWildSeaFisheryActivityInfoMutation,
  UpdateWildSeaFisheryActivityInfoMutationVariables
>;
export const GetTransportRoutesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTransportRoutes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'activityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'routesByActivityId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'activityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'activityId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'WildSeaFisheryTransportRoutesFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WildSeaFisheryTransportRoutesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Route' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startPoint' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endPoint' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicleType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuelType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'distanceStartToEndKm' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfTrips' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalDistanceTravelledKm' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTransportRoutesQuery,
  GetTransportRoutesQueryVariables
>;
export const GetTransportRouteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTransportRoute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'WildSeaFisheryTransportRouteFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WildSeaFisheryTransportRouteFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Route' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startPoint' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endPoint' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicleType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuelType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'distanceStartToEndKm' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfTrips' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalDistanceTravelledKm' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTransportRouteQuery,
  GetTransportRouteQueryVariables
>;
export const CreateTransportRouteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateTransportRoute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'activityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateRouteInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createRoute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'activityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'activityId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'WildSeaFisheryTransportRoutesFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WildSeaFisheryTransportRoutesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Route' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startPoint' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endPoint' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicleType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuelType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'distanceStartToEndKm' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfTrips' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalDistanceTravelledKm' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTransportRouteMutation,
  CreateTransportRouteMutationVariables
>;
export const UpdateTransportRouteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTransportRoute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateRouteInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRoute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'WildSeaFisheryTransportRoutesFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WildSeaFisheryTransportRoutesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Route' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startPoint' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endPoint' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicleType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuelType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'distanceStartToEndKm' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfTrips' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalDistanceTravelledKm' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTransportRouteMutation,
  UpdateTransportRouteMutationVariables
>;
export const DeleteTransportRouteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteTransportRoute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteRoute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTransportRouteMutation,
  DeleteTransportRouteMutationVariables
>;
export const GetFisheryActivityForTransportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFisheryActivityForTransport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'wildSeaFisheryId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wildSeaFisheryActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'wildSeaFisheryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'WildSeaFisheryActivityForTransportFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'WildSeaFisheryActivityForTransportFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WildSeaFisheryActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFisheryActivityForTransportQuery,
  GetFisheryActivityForTransportQueryVariables
>;
export const CreateWildSeaFisheryActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createWildSeaFisheryActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createWildSeaFisheryActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreateWildSeaFisheryActivityInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createWildSeaFisheryActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'createWildSeaFisheryActivityInput',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'createWildSeaFisheryActivityInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateWildSeaFisheryActivityMutation,
  CreateWildSeaFisheryActivityMutationVariables
>;
export const GetWildSeaFisheryActivityInHeaderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWildSeaFisheryActivityInHeader' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wildSeaFisheryActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'PropertiesBusinessActivitiesActivitiesHeaderWildSeaFisheryActivity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'PropertiesBusinessActivitiesActivitiesHeaderWildSeaFisheryActivity',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WildSeaFisheryActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWildSeaFisheryActivityInHeaderQuery,
  GetWildSeaFisheryActivityInHeaderQueryVariables
>;
export const UpdatePropertyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProperty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updatePropertyInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePropertyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProperty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updatePropertyInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EditPropertyPage_PropertyFragment',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PropertyLayoutParcelGeometries_PropertyFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditPropertyPage_PropertyFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PropertyLayoutParcelGeometries_PropertyFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyParcels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parcelId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'geometry' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePropertyMutation,
  UpdatePropertyMutationVariables
>;
export const UpdateBusinessActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBusinessActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateBusinessActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateBusinessActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBusinessActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateBusinessActivityInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EditPropertyPage_BusinessActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EditPropertyPage_BusinessActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BusinessActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enabledActivityTypes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyNorthOfTropicOfCapricorn' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rainfallAbove600' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBusinessActivityMutation,
  UpdateBusinessActivityMutationVariables
>;
export const GetPropertyForEditDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPropertyForEdit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'property' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EditPropertyPage_PropertyFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditPropertyPage_PropertyFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPropertyForEditQuery,
  GetPropertyForEditQueryVariables
>;
export const GetBusinessActivityForPropertyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBusinessActivityForProperty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessActivityForProperty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EditPropertyPage_BusinessActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EditPropertyPage_BusinessActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BusinessActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enabledActivityTypes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyNorthOfTropicOfCapricorn' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rainfallAbove600' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBusinessActivityForPropertyQuery,
  GetBusinessActivityForPropertyQueryVariables
>;
export const DeletePropertyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteProperty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProperty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePropertyMutation,
  DeletePropertyMutationVariables
>;
export const GetPropertyFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPropertyFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uploadStatus' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PropertiesFilesFileFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertiesFilesFileFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uploadStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bytes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPropertyFileQuery,
  GetPropertyFileQueryVariables
>;
export const DeletePropertyFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePropertyFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePropertyFileMutation,
  DeletePropertyFileMutationVariables
>;
export const GetPropertyFilesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPropertyFiles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'property' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PropertyFilesFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyFilesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uploadStatus' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'bytes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPropertyFilesQuery,
  GetPropertyFilesQueryVariables
>;
export const CreatePropertyFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePropertyFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createFileInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateFileInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPropertyFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createFileInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PropertyFileForUploadFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyFileForUploadFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FileForUpload' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'presignedPostUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'presignedPostFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bytes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePropertyFileMutation,
  CreatePropertyFileMutationVariables
>;
export const UpdatePropertyFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePropertyFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFileInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PropertiesFilesFileFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertiesFilesFileFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uploadStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bytes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePropertyFileMutation,
  UpdatePropertyFileMutationVariables
>;
export const GetPropertyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProperty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'property' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PropertyPage_PropertyFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyPage_PropertyFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
      },
    },
  ],
} as unknown as DocumentNode<GetPropertyQuery, GetPropertyQueryVariables>;
export const GetBusinessActivityForProeprtyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBusinessActivityForProeprty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessActivityForProperty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'UseBusinessActivityForProperty_BusinessActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseBusinessActivityForProperty_BusinessActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BusinessActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enabledActivityTypes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBusinessActivityForProeprtyQuery,
  GetBusinessActivityForProeprtyQueryVariables
>;
export const GetMapShapeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMapShape' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mapShape' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'MapShapesEditIndex_MapShapeFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MapShapesEditIndex_MapShapeFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MapShape' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'geometry' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMapShapeQuery, GetMapShapeQueryVariables>;
export const UpdateMapShapeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMapShape' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateMapShapeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMapShape' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'MapShapesEditIndex_MapShapeFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MapShapesEditIndex_MapShapeFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MapShape' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'geometry' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMapShapeMutation,
  UpdateMapShapeMutationVariables
>;
export const DeleteMapShapeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteMapShape' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteMapShape' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMapShapeMutation,
  DeleteMapShapeMutationVariables
>;
export const GetMapShapesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMapShapes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mapShapes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'propertyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MapShapesIndex_MapShape' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MapShapesListItem_MapShape' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MapShape' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'geometry' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MapShapesList_MapShape' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MapShape' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'MapShapesListItem_MapShape' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MapShapesIndex_MapShape' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MapShape' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'geometry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'MapShapesList_MapShape' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMapShapesQuery, GetMapShapesQueryVariables>;
export const CreateMapShapeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMapShape' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateMapShapeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMapShape' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MapShapesIndex_MapShape' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MapShapesListItem_MapShape' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MapShape' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'geometry' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MapShapesList_MapShape' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MapShape' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'MapShapesListItem_MapShape' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MapShapesIndex_MapShape' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MapShape' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'geometry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'MapShapesList_MapShape' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMapShapeMutation,
  CreateMapShapeMutationVariables
>;
export const GetPropertyNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPropertyNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PropertiesNotesNoteFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertiesNotesNoteFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PropertyNote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPropertyNoteQuery,
  GetPropertyNoteQueryVariables
>;
export const UpdatePropertyNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePropertyNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePropertyNoteInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePropertyNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PropertiesNotesNoteFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertiesNotesNoteFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PropertyNote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePropertyNoteMutation,
  UpdatePropertyNoteMutationVariables
>;
export const DeletePropertyNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePropertyNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePropertyNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePropertyNoteMutation,
  DeletePropertyNoteMutationVariables
>;
export const GetPropertyNotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPropertyNotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'property' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PropertyNotesFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyNotesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyNotes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPropertyNotesQuery,
  GetPropertyNotesQueryVariables
>;
export const CreatePropertyNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePropertyNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createPropertyNoteInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePropertyNoteInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPropertyNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createPropertyNoteInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePropertyNoteMutation,
  CreatePropertyNoteMutationVariables
>;
export const GetPropertiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProperties' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'teamId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'teamId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'AllTeamPropertiesPageProperty',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllTeamPropertiesPageProperty' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'centroid' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPropertiesQuery, GetPropertiesQueryVariables>;
export const CreateBusinessActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateBusinessActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createBusinessActivityInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateBusinessActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBusinessActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createBusinessActivityInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EditPropertyPage_BusinessActivityFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EditPropertyPage_BusinessActivityFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BusinessActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enabledActivityTypes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyNorthOfTropicOfCapricorn' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rainfallAbove600' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateBusinessActivityMutation,
  CreateBusinessActivityMutationVariables
>;
export const GetParcelForUseEditPropertyBoundaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetParcelForUseEditPropertyBoundary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parcel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseEditPropertyBoundary_ParcelFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseEditPropertyBoundary_ParcelFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Parcel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'geoFeature' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'geometry' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'properties' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetParcelForUseEditPropertyBoundaryQuery,
  GetParcelForUseEditPropertyBoundaryQueryVariables
>;
export const GetGeographicInformationForPointForUseEditPropertyBoundaryDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'query',
        name: {
          kind: 'Name',
          value: 'GetGeographicInformationForPointForUseEditPropertyBoundary',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'point' },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'GeoJSONPoint' },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'geographicInformationForPoint' },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'point' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'point' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'UseEditPropertyBoundary_GeographicInformationFragment',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseEditPropertyBoundary_GeographicInformationFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'GeographicInformation' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    GetGeographicInformationForPointForUseEditPropertyBoundaryQuery,
    GetGeographicInformationForPointForUseEditPropertyBoundaryQueryVariables
  >;
export const SearchParcelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchParcels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ParcelSearchInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchParcels' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'geometry' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'properties' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchParcelsQuery, SearchParcelsQueryVariables>;
export const CreatePropertyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProperty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createPropertyInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePropertyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProperty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createPropertyInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePropertyMutation,
  CreatePropertyMutationVariables
>;
import { decimalTypePolicy } from '../scalars/decimal';

export const scalarTypePolicies = {
  BeefMonthLivestockActivity: {
    fields: {
      crudeProteinPercentage: decimalTypePolicy,
      dryMatterDigestibilityPercentage: decimalTypePolicy,
      percentageCowsCalving: decimalTypePolicy,
    },
  },
  BuffaloMonthLivestockActivity: {
    fields: {
      percentageCowsCalving: decimalTypePolicy,
      seasonalCalvingRate: decimalTypePolicy,
    },
  },
  BurningActivity: { fields: { yearsSinceLastFire: decimalTypePolicy } },
  Carbamate: { fields: { activeIngredientPercentage: decimalTypePolicy } },
  ComparableSale: { fields: { similarity: decimalTypePolicy } },
  ComparableSaleSearchResult: { fields: { similarity: decimalTypePolicy } },
  CottonActivity: {
    fields: {
      averageWeightPerBaleKg: decimalTypePolicy,
      balesPerHa: decimalTypePolicy,
      cottonLintPerBaleKg: decimalTypePolicy,
      cottonSeedPerBaleKg: decimalTypePolicy,
      wastePerBaleKg: decimalTypePolicy,
    },
  },
  DairyLivestockActivity: {
    fields: {
      distanceTravelledBetweenFarmsKm: decimalTypePolicy,
      emissionsRedMeatPercentage: decimalTypePolicy,
      manureMilkingLagoonPercentage: decimalTypePolicy,
      manureMilkingPaddocksPercentage: decimalTypePolicy,
      manureMilkingPasturePercentage: decimalTypePolicy,
      manureMilkingSolidStoragePercentage: decimalTypePolicy,
      manureMilkingSumpDispersalPercentage: decimalTypePolicy,
      manureOtherLagoonPercentage: decimalTypePolicy,
      manureOtherPaddocksPercentage: decimalTypePolicy,
      manureOtherPasturePercentage: decimalTypePolicy,
      manureOtherSolidStoragePercentage: decimalTypePolicy,
      manureOtherSumpDispersalPercentage: decimalTypePolicy,
    },
  },
  DairyMonthLivestockActivity: {
    fields: {
      crudeProteinPercentage: decimalTypePolicy,
      dryMatterDigestibilityPercentage: decimalTypePolicy,
      litresPerHeadPerDay: decimalTypePolicy,
    },
  },
  DeerMonthLivestockActivity: {
    fields: {
      percentageDoesFawning: decimalTypePolicy,
      seasonalFawningRate: decimalTypePolicy,
    },
  },
  Feed: {
    fields: {
      drySeasonMixUreaPercentage: decimalTypePolicy,
      mineralBlockUreaPercentage: decimalTypePolicy,
      weanerBlockUreaPercentage: decimalTypePolicy,
    },
  },
  FeedlotFeedBreakdown: {
    fields: {
      crudeProtein: decimalTypePolicy,
      dryMatterDigestibility: decimalTypePolicy,
      netEnergyConcentrationMjPerKg: decimalTypePolicy,
      nitrogenRetention: decimalTypePolicy,
    },
  },
  FeedlotFeedComposition: {
    fields: {
      grainsTotalCellulous: decimalTypePolicy,
      grainsTotalHemicellulous: decimalTypePolicy,
      grainsTotalNitrogen: decimalTypePolicy,
      grainsTotalProportion: decimalTypePolicy,
      grainsTotalSolubleResidue: decimalTypePolicy,
      grassesCellulous: decimalTypePolicy,
      grassesHemicellulous: decimalTypePolicy,
      grassesNitrogen: decimalTypePolicy,
      grassesProportion: decimalTypePolicy,
      grassesSolubleResidue: decimalTypePolicy,
      legumesCellulous: decimalTypePolicy,
      legumesHemicellulous: decimalTypePolicy,
      legumesNitrogen: decimalTypePolicy,
      legumesProportion: decimalTypePolicy,
      legumesSolubleResidue: decimalTypePolicy,
      otherConcentratesCellulous: decimalTypePolicy,
      otherConcentratesHemicellulous: decimalTypePolicy,
      otherConcentratesNitrogen: decimalTypePolicy,
      otherConcentratesProportion: decimalTypePolicy,
      otherConcentratesSolubleResidue: decimalTypePolicy,
    },
  },
  Fertiliser: {
    fields: {
      amountPerHectareKg: decimalTypePolicy,
      ratioK: decimalTypePolicy,
      ratioN: decimalTypePolicy,
      ratioP: decimalTypePolicy,
      ratioS: decimalTypePolicy,
    },
  },
  FisheryBaitPurchase: {
    fields: {
      amountPurchasedTonnes: decimalTypePolicy,
      emissionsIntensityBaitProduct: decimalTypePolicy,
      percentageAdditionalIngredients: decimalTypePolicy,
    },
  },
  GoatMonthLivestockActivity: {
    fields: {
      crudeProteinPercentage: decimalTypePolicy,
      dryMatterDigestibilityPercentage: decimalTypePolicy,
      feedAvailabilityTonnesPerHectare: decimalTypePolicy,
      percentageDoesKidding: decimalTypePolicy,
      seasonalKiddingRate: decimalTypePolicy,
    },
  },
  GoatShearingEvent: {
    fields: {
      cleanWoolYieldKg: decimalTypePolicy,
      woolPerHeadKg: decimalTypePolicy,
    },
  },
  LivestockTransactionEvent: {
    fields: {
      averageWeightKg: decimalTypePolicy,
      totalWeightKg: decimalTypePolicy,
    },
  },
  PorkFeedBlend: {
    fields: {
      amountPurchasedTonnes: decimalTypePolicy,
      barley: decimalTypePolicy,
      beetPulp: decimalTypePolicy,
      bloodMeal: decimalTypePolicy,
      canolaMeal: decimalTypePolicy,
      emissionsIntensityFeedProduction: decimalTypePolicy,
      fishMeal: decimalTypePolicy,
      meatMeal: decimalTypePolicy,
      millMix: decimalTypePolicy,
      percentageAdditionalIngredients: decimalTypePolicy,
      soybeanMeal: decimalTypePolicy,
      tallow: decimalTypePolicy,
      wheat: decimalTypePolicy,
      wheatBran: decimalTypePolicy,
      wheyPowder: decimalTypePolicy,
    },
  },
  PoultryBroilerActivity: {
    fields: {
      percentManureAddedToPasture: decimalTypePolicy,
      percentSolidManure: decimalTypePolicy,
      percentageLitterRecycled: decimalTypePolicy,
    },
  },
  PoultryFeedBlend: {
    fields: {
      amountPurchasedTonnes: decimalTypePolicy,
      barleyWhole: decimalTypePolicy,
      emissionsIntensityFeedProduction: decimalTypePolicy,
      millRun: decimalTypePolicy,
      percentageAdditionalIngredients: decimalTypePolicy,
      sorghum: decimalTypePolicy,
      soybean: decimalTypePolicy,
      wheat: decimalTypePolicy,
    },
  },
  PoultryFeedBreakdown: {
    fields: {
      crudeProteinPercent: decimalTypePolicy,
      dryMatterDigestibilityPercent: decimalTypePolicy,
      dryMatterIntakeKgPerHeadPerDay: decimalTypePolicy,
      manureAshPercent: decimalTypePolicy,
      nitrogenRetentionPercent: decimalTypePolicy,
    },
  },
  PoultryGrowerBatchDetails: {
    fields: {
      averageLiveweightPerHeadKgW1: decimalTypePolicy,
      averageLiveweightPerHeadKgW2: decimalTypePolicy,
    },
  },
  PoultryLayerActivity: {
    fields: {
      averageLayerEggWeightGrams: decimalTypePolicy,
      averageMeatChickenLayerEggWeightGrams: decimalTypePolicy,
      percentManureAddedToPasture: decimalTypePolicy,
      percentSolidManure: decimalTypePolicy,
      percentageLitterRecycled: decimalTypePolicy,
    },
  },
  PoultryTransactionEvent: {
    fields: {
      averageWeightKg: decimalTypePolicy,
      percentPurchasedFreeRange: decimalTypePolicy,
      totalWeightKg: decimalTypePolicy,
    },
  },
  Route: {
    fields: {
      distanceStartToEndKm: decimalTypePolicy,
      totalDistanceTravelledKm: decimalTypePolicy,
    },
  },
  Sale: { fields: { area: decimalTypePolicy } },
  SaleLandUse: {
    fields: { area: decimalTypePolicy, pricePerHectare: decimalTypePolicy },
  },
  SheepMonthLivestockActivity: {
    fields: {
      crudeProteinPercentage: decimalTypePolicy,
      dryMatterDigestibilityPercentage: decimalTypePolicy,
      feedAvailabilityTonnesPerHectare: decimalTypePolicy,
      percentageEwesLambing: decimalTypePolicy,
      seasonalLambingRate: decimalTypePolicy,
    },
  },
  SheepShearingEvent: {
    fields: {
      cleanWoolYieldKg: decimalTypePolicy,
      woolPerHeadKg: decimalTypePolicy,
    },
  },
  WildSeaFisheryActivity: {
    fields: {
      offsetsPurchasedTonnes: decimalTypePolicy,
      totalWeightCaughtKg: decimalTypePolicy,
    },
  },
};
