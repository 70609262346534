import { usePlausible } from 'next-plausible';
import { AnalyticsEvent } from 'analytics-events';
import { useCallback } from 'react';
import { useCurrentUser } from './useCurrentUser';
import { useApplicationMode } from './useApplicationMode';

export function useAnalyticsEvents() {
  const plausible = usePlausible();

  const { user, currentTeam } = useCurrentUser();
  const { mode } = useApplicationMode();

  const sendEvent = useCallback(
    (event: AnalyticsEvent, data?: object) => {
      if (user.data.email.endsWith('@agtuary.com') || mode === 'aia') {
        return;
      }

      const teamOrgSlug =
        `${currentTeam.organisation.name}_${currentTeam.name}`.replace(
          /[^a-zA-Z0-9]/g,
          '-',
        );

      plausible(event, { props: { team: teamOrgSlug, ...data } });
    },
    [plausible, user, currentTeam, mode],
  );

  return { plausible, sendEvent };
}
