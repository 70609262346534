import { useMutation } from '@tanstack/react-query';
import { mutations } from '../../../mutations';

export const useTeamResendInvoice = (id: string) =>
  useMutation<
    { success: boolean },
    unknown,
    {
      user: string;
    }
  >(mutations.teams.one(id)._ctx.resendInvoice);
