import { createQueryKeys } from '@lukemorales/query-key-factory';
import { fetcher } from '../helpers/clientSide/requests';

export const reports = createQueryKeys('reports', {
  one: (reportId: string) => ({
    queryKey: [reportId],
    // queryFn: () => fetcher(`/api/reports/${reportId}`), // not implemented yet
    contextQueries: {
      urls: {
        queryKey: null,
        queryFn: () => fetcher(`/api/reports/${reportId}/urls`),
      },
    },
  }),
});
