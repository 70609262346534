import { formatArea } from '@agtuary/common/helpers/formatters/formatArea';
import { is2DBoundingBox } from '@agtuary/common/helpers/geojson/guards';
import { FragmentType, getFragmentData, gql } from '@agtuary/graphql';
import { MapImage } from '@agtuary/map/components/MapImage';
import { Viewport } from '@agtuary/map/components/MapImage/types';
import { colorAqua } from '@agtuary/map/styles';
import { Badge, Box, Checkbox, Group, Paper, Text, Title } from '@mantine/core';
import { area, bbox, feature } from '@turf/turf';
import { capitalizeLowercase } from 'helpers/typed/string';
import { squareMetersToHectares } from 'helpers/units/area';
import Link from 'next/link';
import { Route } from 'nextjs-routes';
import { useMemo } from 'react';
import { SHAPE_TYPE_LABELS } from '../constants';
import styles from './MapShapesListItem.module.scss';

const MapShapesListItemMapShapeFragment = gql(/* GraphQL */ `
  fragment MapShapesListItem_MapShape on MapShape {
    id
    type
    name
    geometry
  }
`);

type MapShapesListItemProps = {
  mapShape: FragmentType<typeof MapShapesListItemMapShapeFragment>;
  index: number;
  editHref?: (mapShapeId: string) => Route;
  selectable: boolean;
  selected?: boolean;
  onChangeSelected?: (checked: boolean) => void;
};

const formattedHectares = (geometry: GeoJSON.MultiPolygon) =>
  formatArea(squareMetersToHectares(area(feature(geometry))));

const mapImageSize = 80;
const mapImagePaddingPercent = 0.1;

export function MapShapesListItem({
  mapShape,
  editHref,
  index,
  selectable = false,
  selected = false,
  onChangeSelected,
}: MapShapesListItemProps) {
  const shape = useMemo(
    () => getFragmentData(MapShapesListItemMapShapeFragment, mapShape),
    [mapShape],
  );

  const shapeFeature = useMemo(
    () =>
      feature(shape.geometry ?? null, {
        stroke: colorAqua,
        'fill-opacity': 0,
      }),
    [shape.geometry],
  );

  const viewport: Viewport = useMemo(() => {
    const b = shape.geometry ? bbox(shape.geometry) : null;
    return is2DBoundingBox(b)
      ? {
          minLongitude: b[0] - (b[2] - b[0]) * mapImagePaddingPercent,
          minLatitude: b[1] - (b[3] - b[1]) * mapImagePaddingPercent,
          maxLongitude: b[2] + (b[2] - b[0]) * mapImagePaddingPercent,
          maxLatitude: b[3] + (b[3] - b[1]) * mapImagePaddingPercent,
        }
      : undefined;
  }, [shape.geometry]);

  return (
    <Paper withBorder key={shape.id} className={styles.listItem}>
      <Group position="apart">
        <Box>
          <MapImage
            width={mapImageSize}
            height={mapImageSize}
            geoJson={shapeFeature}
            viewport={viewport}
          />
        </Box>
        {selectable && (
          <Checkbox
            id={shape.id}
            checked={selected}
            onChange={(e) => onChangeSelected?.(e.target.checked)}
          />
        )}{' '}
        <Title order={6}>
          {shape.name ||
            `${capitalizeLowercase(SHAPE_TYPE_LABELS[shape.type])} ${
              index + 1
            }`}
        </Title>
        <Text>{SHAPE_TYPE_LABELS[shape.type]}</Text>
        <Text>{formattedHectares(shape.geometry)} hectares</Text>
        {editHref && (
          <Badge component={Link} href={editHref(shape.id)} variant="outline">
            Edit
          </Badge>
        )}
      </Group>
    </Paper>
  );
}
