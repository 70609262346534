import { AppShell, Box } from '@mantine/core';
import { ModalsProvider } from 'components/modals/ModalsProvider';
import { PageTitle } from 'components/PageTitle';

import { PropsWithChildren } from 'react';

import { Header } from '../RootLayout/components/Header';
import { PageHeader, PageHeaderProps } from './components/PageHeader';

type MainLayoutProps = {
  headerProps?: PageHeaderProps;
};

/** @deprecated Use RootLayout instead */
export function MainLayout({
  children,
  headerProps,
}: PropsWithChildren<MainLayoutProps>) {
  return (
    <ModalsProvider>
      <PageTitle />
      <AppShell
        header={<Header />}
        navbar={undefined}
        styles={{
          root: {
            height: '100vh',
            overflow: 'hidden',
          },
          main: {
            overflow: 'auto',
            height: '100vh',
          },
        }}
      >
        <Box>
          <PageHeader title={headerProps?.title}>
            <Box sx={{ flex: 1 }}>{headerProps?.children}</Box>
          </PageHeader>
          {children}
        </Box>
      </AppShell>
    </ModalsProvider>
  );
}
