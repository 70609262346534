import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { queries } from '../../queries';
import { Report } from '../../types/report';

export const useReportUrls = (
  report: Report,
): UseQueryResult<{ interactiveUrl: string; pdfUrl: string }> =>
  useQuery({
    ...queries.reports.one(report?.id)._ctx.urls,
    enabled: !!report?.id && report?.status === 'completed',
  });
