import { QueryClientProvider } from '@agtuary/api/providers/QueryClientProvider';
import { useApollo } from '@agtuary/graphql/apollo';
import '@agtuary/ui/styles/styles.scss';
import { ApolloProvider } from '@apollo/client';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { Analytics } from '@vercel/analytics/react';
import 'array.prototype.tosorted/auto';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { LaunchDarklyFetcher } from 'components/LaunchDarklyFetcher';
import { PageTitle } from 'components/PageTitle';
import { UserflowOnboarding } from 'components/UserflowOnboarding';
import { MainLayout } from 'components/layouts/MainLayout';
import { ContextMenuProvider } from 'context/contextMenuContext';
import { UserProvider } from 'context/userContext';
import {
  listenForLogout,
  logout,
  removeLogoutListener,
} from 'helpers/auth/logout';
import { LocaleProvider } from 'helpers/dayjs';
import { ldConfig } from 'helpers/featureFlags/ldConfig';
import { useApplicationMode } from 'hooks/useApplicationMode';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import type { NextPage } from 'next';
import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import { ReactElement, ReactNode, useEffect } from 'react';
import 'regenerator-runtime/runtime';
import PlausibleProvider from 'next-plausible';
import { ENVIRONMENT_URL } from 'constants/environment';
import '../scripts/wdyr';

// Polyfill for CSS.escape
if (typeof CSS === 'undefined' || typeof CSS.escape !== 'function') {
  await import('../polyfills/cssEscape');
}

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const appDomain = ENVIRONMENT_URL.replace('https://', '');

function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) {
  // Add a default layout to all pages
  // if we don't want the layout use MyPage.getLayout = (page: ReactElement) => page
  const getLayout =
    Component.getLayout ??
    ((page: ReactElement) => <MainLayout>{page}</MainLayout>);

  const apolloClient = useApollo(pageProps, logout);

  // Listen for logout events from other tabs
  useEffect(() => {
    listenForLogout();

    return removeLogoutListener;
  }, []);

  const {
    options: { theme },
  } = useApplicationMode();

  return (
    <ErrorBoundary>
      <PageTitle />
      <ApolloProvider client={apolloClient}>
        <QueryClientProvider logout={logout}>
          <SessionProvider session={session}>
            <LaunchDarklyFetcher>
              <UserflowOnboarding>
                <UserProvider>
                  <PlausibleProvider domain={appDomain}>
                    <MantineProvider
                      withCSSVariables
                      withNormalizeCSS
                      withGlobalStyles
                      theme={theme}
                    >
                      <LocaleProvider>
                        <ContextMenuProvider>
                          <Notifications limit={2} />
                          {getLayout(<Component {...pageProps} />)}
                        </ContextMenuProvider>
                      </LocaleProvider>
                    </MantineProvider>
                  </PlausibleProvider>
                </UserProvider>
              </UserflowOnboarding>
            </LaunchDarklyFetcher>
          </SessionProvider>
        </QueryClientProvider>
      </ApolloProvider>
      <Analytics debug={false} />
    </ErrorBoundary>
  );
}

export default withLDProvider(ldConfig)(App);
