import qs from 'qs';
// import { Property } from '../types/property';
import { QueryParams } from '../types/request';

// export const parseAddress = (p: Property): string => {
//   const addressLine = p.addressLine2
//     ? `${p.addressLine1} ${p.addressLine2}`
//     : p.addressLine1;
//   const location = `${addressLine}, ${p.city}, ${p.state} ${p.postcode}`;
//   return location;
// };

export const parseUrl = (url: string, queryParams?: QueryParams): string => {
  const parsedUrl =
    queryParams && Object.entries(queryParams).length
      ? `${url}?${qs.stringify(queryParams)}`
      : url;
  return parsedUrl.startsWith('/') ? parsedUrl : `/${parsedUrl}`;
};
