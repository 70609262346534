import { File } from '@agtuary/api/types/file';
import { Carousel } from '@mantine/carousel';
import { Flex, Image, Text } from '@mantine/core';
import { MouseEventHandler } from 'react';

type ImageCarouselSlideProps = {
  file: File;
};

export function ImageCarouselSlide({ file }: ImageCarouselSlideProps) {
  // See https://github.com/mantinedev/mantine/issues/4174#issuecomment-1540557053
  const fixEmblaBug: MouseEventHandler = (e) => {
    e.stopPropagation();
  };

  return (
    <Carousel.Slide mah="80vh" maw="80vw">
      <Flex w="100%" h="100%" justify="center" align="center">
        <Image src={file?.signedUrl} onClick={fixEmblaBug} alt={file?.name} />
        <Text onClick={fixEmblaBug}>{file?.description}</Text>
      </Flex>
    </Carousel.Slide>
  );
}
