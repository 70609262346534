import { useCreateJob } from '@agtuary/api/hooks/jobs/mutations/useCreateJob';
import { Job } from '@agtuary/api/types/job';
import { Parcel as MapParcel } from '@agtuary/map/types/parcels';
import { Box, Button, Flex, Text, TextInput } from '@mantine/core';
// eslint-disable-next-line no-restricted-imports
import { useForm } from '@mantine/form';
import { ContextModalProps } from '@mantine/modals';
import { showError } from 'helpers/showError';
import { useCurrentUser } from 'hooks/useCurrentUser';

export type NewJobModalInnerProps = {
  onSuccess?: (job: Job) => void;
  parcels?: MapParcel[];
};

export function NewJobModal({
  innerProps: { onSuccess, parcels },
}: ContextModalProps<NewJobModalInnerProps>) {
  const {
    user: { data: user },
    currentTeam: team,
  } = useCurrentUser();
  const { organisation } = team ?? {};
  const teamName = `${organisation?.name} / ${team?.name}`;
  const { mutateAsync: trigger, isLoading, error } = useCreateJob();

  const initialValues = {
    name: '',
  };

  const form = useForm({
    initialValues,
    validateInputOnBlur: true,
  });

  const submit = form.onSubmit(({ name }) => {
    const properties = !parcels?.length
      ? undefined
      : [
          {
            parcels: parcels.map((parcel) => ({
              parcelId: parcel.id,
            })),
          },
        ];
    trigger({ name, teamId: team?.id, userId: user?.id, properties })
      .then((data) => {
        if (error) {
          showError('An unknown error occurred while creating the job.');
        } else if (data?.name === name) {
          form.resetDirty();
          form.resetTouched();
          onSuccess?.(data);
        }
      })
      .catch(() => {
        showError('An unknown error occurred while creating the job.');
      });
  });

  return (
    <>
      {/* <Text>Configure your new job</Text> */}
      {/* <Space h="md" /> */}
      <form onSubmit={submit}>
        <Flex gap="md" direction="column">
          <Box>
            <TextInput
              placeholder="Give this job a name"
              label="Job Name"
              required
              {...form.getInputProps('name')}
            />
            <Text size="xs" color="dimmed" mt={4}>
              {teamName} / {form.values.name}
            </Text>
          </Box>
          <Flex justify="end">
            <Button type="submit" loading={isLoading} loaderPosition="center">
              Start Job
            </Button>
          </Flex>
        </Flex>
      </form>
    </>
  );
}
