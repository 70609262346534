import {
  inferQueryKeyStore,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory';
import { users } from './users';
import { teams } from './teams';
import { invitations } from './invitations';
import { reports } from './reports';
import { organisations } from './organisations';
import { jobs } from './jobs';

export const mutations = mergeQueryKeys(
  invitations,
  jobs,
  organisations,
  reports,
  teams,
  users,
);

export type QueryKeys = inferQueryKeyStore<typeof mutations>;
