import { MantineThemeOverride } from '@mantine/core';

export const colorCodes: MantineThemeOverride['colors'] = {
  brand: [
    // Agtuary Green
    '#F7FBFA', // green-50
    '#E9F5F2', // green-100
    '#BDE1DE', // green-200
    '#A0D3CE', // green-300
    '#80BFB8', // green-400
    '#6FAFA8', // green-500 - Uptrend
    '#4D958D', // green-600
    '#377A73', // green-700 - Secondary
    '#0F6158', // green-800 - Primary
    '#00423B', // green-900
  ],
  gray: [
    '#FAFAFA', // Gray-50 - White
    '#F6F6F6', // Gray-100
    '#F4F4F4', // Gray-200
    '#E8E8E8', // Gray-300
    '#D6D6D6', // Gray-400
    '#ADADAD', // Gray-500
    '#9B9B9B', // Gray-600
    '#747474', // Gray-700
    '#424242', // Gray-800 - Text
    '#2D2D2D', // Gray-900 - Black
  ],
  red: [
    '#FFF2F2', // red-50
    '#FFE0DF', // red-100
    '#FFCDCC', // red-200
    '#FCA6A5', // red-300
    '#FB8887', // red-400
    '#EE6B69', // red-500
    '#E0514F', // red-600
    '#A63C3A', // red-700
    '#940A07', // red-800 - Error / Downtrend
    '#740503', // red-900
  ],

  blue: [
    '#EDF4FF', // blue-50
    '#C7DDFF', // blue-100
    '#AFCEFD', // blue-200
    '#8FB5EE', // blue-300
    '#578EDF', // blue-400
    '#477DCE', // blue-500
    '#3A65A6', // blue-600
    '#074894', // blue-700
    '#043A79', // blue-800
    '#022F65', // blue-900
  ],

  purple: [
    '#E7E1FE', // purple-50
    '#D6CCFF', // purple-100
    '#C3B4FD', // purple-200
    '#AD9BF3', // purple-300
    '#9783E4', // purple-400
    '#826DD3', // purple-500
    '#6D59BD', // purple-600
    '#5642A6', // purple-700
    '#392881', // purple-800
    '#2A1E5A', // purple-900
  ],

  clay: [
    '#F8F2E8', // clay-50
    '#F6EDDC', // clay-100
    '#F3E3C6', // clay-200
    '#EDDBB9', // clay-300
    '#E5CEA0', // clay-400
    '#CBAE81', // clay-500
    '#B9996A', // clay-600
    '#977D57', // clay-700
    '#776242', // clay-800
    '#5A4C35', // clay-900
  ],

  teal: [
    '#ECFEFD', // teal-50
    '#E2FBFB', // teal-100
    '#D0F7FA', // teal-200
    '#9BEEF3', // teal-300
    '#83DFE4', // teal-400
    '#6BC5CB', // teal-500
    '#59B1BD', // teal-600
    '#4294A6', // teal-700
    '#286B81', // teal-800
    '#1E485A', // teal-900
  ],

  yellow: [
    '#FFF9E1', // yellow-50
    '#FFF7D3', // yellow-100
    '#FFF2BA', // yellow-200
    '#FFED92', // yellow-300
    '#FFE258', // yellow-400
    '#FFCC1F', // yellow-500
    '#F5BB00', // yellow-600
    '#C09300', // yellow-700
    '#8B6B00', // yellow-800
    '#624100', // yellow-900
  ],
};

const chartColors = {
  limeGreen: '#809501',
  appleGreen: '#34A901',
  grassGreen: '#01A56A',
  aqua: '#029BCC',
  skyBlue: '#2986F4',
  grape: '#5802DF',
  lavender: '#9A6AFF',
  magenta: '#F503DD',
  watermelon: '#FF5A5A',
  safetyRed: '#F94605',
  burntOrange: '#EC7202',
  marigold: '#C37F02',
  desaturated: {
    limeGreen: '#4A5027',
    appleGreen: '#3C5E2D',
    grassGreen: '#2C5B4A',
    aqua: '#396776',
    skyBlue: '#5E7CA1',
    grape: '#5A4083',
    lavender: '#9A85C6',
    magenta: '#91488A',
    watermelon: '#C17A7A',
    safetyRed: '#935F4C',
    burntOrange: '#8D4F15',
    marigold: '#705C37',
    // extra
    yellow800: '#8B6B00',
    blue600: '#3A65A6',
    purple700: '#5642A6',
    red700: '#A63C3A',
  },
};

export const colors: MantineThemeOverride = {
  // Object of arrays with 10 colors
  colors: colorCodes,

  // Defines color scheme for all components, defaults to "light"
  colorScheme: 'light',
  // Key of theme.colors
  primaryColor: 'brand',
  // Index of color from theme.colors that is considered primary,
  // Shade type is 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
  primaryShade: 8,

  // White and black colors, defaults to '#fff' and '#000'
  white: colorCodes.gray?.[0],
  black: colorCodes.gray?.[9],

  // Default gradient used in components that support `variant="gradient"` (Button, ThemeIcon, etc.)
  //   defaultGradient: {
  //     deg: 0,
  //     from: 'brand',
  //     to: 'red',
  //   },
};

// I feel like there should be a better way to handle
// named colors and css vars. This will do for now.
export const colorsOther: MantineThemeOverride['other'] = {
  colors: {
    primary: colors.colors?.brand?.[8],
    secondary: colors.colors?.brand?.[7],
    uptrend: colors.colors?.brand?.[5],
    inProgress: colors.colors?.brand?.[5],
    text: colors.colors?.gray?.[8],
    success: colors.colors?.brand?.[5],
    error: colors.colors?.red?.[8],
    downtrend: colors.colors?.red?.[8],
    border: colors.colors?.gray?.[3],
    onboardBackground: '#FFFEF8',
    disabled: colors.colors?.gray?.[5],
    charts: chartColors,
    comparisonGreen: '#17BF63',
  },
  mantineColors: {
    secondary: 'brand.7',
    success: 'brand.5',
    disabled: 'gray.5',
    inProgress: 'brand.5',
    draft: 'gray.7',
  },
};

export const colorCSSVariables = {
  '--mantine-color-brand-primary': 'var(--mantine-color-brand-8)',
  '--mantine-color-border': 'var(--mantine-color-gray-3)',
  '--mantine-color-text': 'var(--mantine-color-gray-8)',
  '--mantine-color-link': 'var(--mantine-color-blue-7)',
  '--mantine-brand-gradient':
    'linear-gradient(90deg, var(--mantine-color-brand-6) 0%, var(--mantine-color-purple-7) 200%);',
  '--mantine-color-comparison-green': 'var(--mantine-color-blue-7)',
};
