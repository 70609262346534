import { MantineThemeOverride } from '@mantine/core';
import { AppShell } from './AppShell';
import { Breadcrumbs } from './Breadcrumbs';
import { Checkbox } from './Checkbox';
import { Header } from './Header';
import { Modal } from './Modal';
import { Navbar } from './Navbar';
import { NavLink } from './NavLink';
import { Table } from './Table';
import { Tabs } from './Tabs';

export const components: MantineThemeOverride['components'] = {
  ...AppShell,
  ...Breadcrumbs,
  ...Checkbox,
  ...Header,
  ...Modal,
  ...Navbar,
  ...NavLink,
  ...Table,
  ...Tabs,
};
