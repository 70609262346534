import { useMergedRef } from '@mantine/hooks';
import { Decimal } from 'decimal.js-light';
import { ComponentProps, ForwardedRef, forwardRef, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { BaseNumberInput } from '../NumberInput';
import { useFormattedDecimal } from '../format';
import { useFormLogic } from '../hooks';
import { RHFInputProps } from '../types';
import { buildValidationRules } from '../validation';

type PercentageInputProps = RHFInputProps &
  ComponentProps<typeof BaseNumberInput>;

function PercentageInputComponent(
  props: PercentageInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { name, validation, onChange, ...innerProps } = props;
  const { formState, setValue, control } = useFormContext();
  const { isSubmitted } = formState;

  const { error, label } = useFormLogic({
    ...props,
  });

  const validationRules = useMemo(
    () => buildValidationRules(validation),
    [validation],
  );

  const { field } = useController({
    name,
    control,
    rules: validationRules,
  });

  const mergedRef = useMergedRef(ref, field.ref);

  const formattedValue = useFormattedDecimal(field);

  return (
    <BaseNumberInput
      rightSection={<span>%</span>}
      precision={1}
      {...innerProps}
      error={error}
      label={label}
      {...field}
      value={formattedValue}
      onChange={(value) => {
        const wrappedValue = value === '' ? null : new Decimal(value);
        setValue(name, wrappedValue, {
          shouldDirty: true,
          shouldValidate: isSubmitted,
        });

        onChange?.(value);
      }}
      max={100}
      min={0}
      removeTrailingZeros
      withAsterisk={false}
      ref={mergedRef}
    />
  );
}

export const PercentageInput = forwardRef<
  HTMLInputElement,
  PercentageInputProps
>(PercentageInputComponent);
