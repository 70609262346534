import { isNil } from '@agtuary/common/helpers/nil';
import { FieldPolicy } from '@apollo/client';
import { Decimal } from 'decimal.js-light';

export const decimalTypePolicy: FieldPolicy<string, Decimal | null> = {
  /**
   * This method is called when values are read out of the cache, as opposed to merge, which is called when values are
   * inserted into the cache. By storing the value as a primitive value (like a string) it means the query can be
   * executed on the server, then the cache can be serialised and sent to the client safely. If you try to store a complex
   * object in the cache (like a Decimal object) the cache will throw an error when it tries to serialise the object.
   * Any item stored in the cache on thwe server, and then extracted, must be able to pass the serialisation test:
   *   https://github.com/vercel/next.js/blob/canary/packages/next/src/lib/is-serializable-props.ts
   * @param existing the value as it's stored in the cache
   * @returns the value wrapped in it's Decimal scalar object
   */
  read: (existing: string | undefined): Decimal | null => {
    if (isNil(existing)) {
      // It's important for these methods to return null if passed null
      return null;
    }
    return new Decimal(existing);
  },
};
