import { useMutation } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { FormMutationContext, FormMutations } from './FormMutationContext';
import { PartialMutationResult } from './types';

export const mutationResultHasChanged = (
  old: PartialMutationResult | undefined,
  current: PartialMutationResult | undefined,
) =>
  old === undefined ||
  (current !== undefined &&
    (old.loading !== current.loading ||
      old.error !== current.error ||
      old.called !== current.called));

export function useFormMutations(): Partial<FormMutations> {
  const context = useContext(FormMutationContext);

  if (!context) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn(
        'Tried to fetch a FormMutationContext but none was available',
      );
    }

    return {};
  }

  return {
    updateResult: context.updateResult,
    deleteResult: context.deleteResult,
  };
}
function useFreshFormMutations({
  updateResult: newUpdateResult,
  deleteResult: newDeleteResult,
}: FormMutations) {
  const { updateResult, deleteResult, setDeleteResult, setUpdateResult } =
    useContext(FormMutationContext) ?? {};

  useEffect(() => {
    if (
      setUpdateResult &&
      newUpdateResult &&
      mutationResultHasChanged(updateResult, newUpdateResult)
    ) {
      setUpdateResult(newUpdateResult);
    }
  }, [newUpdateResult, setUpdateResult, updateResult]);

  useEffect(() => {
    if (
      setDeleteResult &&
      newDeleteResult &&
      mutationResultHasChanged(deleteResult, newDeleteResult)
    ) {
      setDeleteResult(newDeleteResult);
    }
  }, [setDeleteResult, deleteResult, newDeleteResult]);
}
export function useFormUpdateMutation<TData>(
  mutation: Parameters<typeof useMutation<TData>>[0],
  options: Parameters<typeof useMutation<TData>>[1],
): ReturnType<typeof useMutation> {
  const [update, updateResult] = useMutation<TData>(mutation, options);

  useFreshFormMutations({ updateResult });

  return [update, updateResult];
}

export function useFormDeleteMutation(
  mutation: Parameters<typeof useMutation>[0],
  options: Parameters<typeof useMutation>[1],
): ReturnType<typeof useMutation> {
  const [update, deleteResult] = useMutation(mutation, options);

  useFreshFormMutations({ deleteResult });

  return [update, deleteResult];
}
