import { createMutationKeys } from '@lukemorales/query-key-factory';
import {
  deleteMutator,
  patchMutator,
  postMutator,
} from '../helpers/clientSide/requests';
import { Role } from '../types/role';
import { User } from '../types/user';

export const teams = createMutationKeys('teams', {
  one: (teamId: string) => ({
    mutationKey: [teamId],
    mutationFn: (arg: Partial<User>) =>
      patchMutator(`/api/teams/${teamId}`, { arg }),
    contextMutations: {
      cancelSubscription: {
        mutationKey: null,
        mutationFn: ({
          paymentSubscriptionId,
          reason,
        }: {
          paymentSubscriptionId: string;
          reason: string;
        }) =>
          deleteMutator(`/api/payment-subscriptions/${paymentSubscriptionId}`, {
            arg: { reason },
          }),
      },
      resendInvoice: {
        mutationKey: null,
        mutationFn: (arg: { user: string }) =>
          postMutator(`/api/teams/${teamId}/resendinvoice`, {
            arg,
          }),
      },
      removeUser: {
        mutationKey: null,
        mutationFn: (roleId: string) =>
          deleteMutator(`/api/roles/${roleId}`, { arg: {} }),
      },
      updateRole: {
        mutationKey: null,
        mutationFn: ({ id: roleId, name }: Partial<Role>) =>
          patchMutator(`/api/roles/${roleId}`, { arg: { name } }),
      },
    },
  }),
});
