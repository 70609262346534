import { TextInput } from '@agtuary/ui/forms/inputs';
import { Box, Divider, Flex, Space, Text } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { CancelButton, ConfirmButton } from 'components/buttons';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export type CalculateLiveweightModalInnerProps = {
  onDone: (value: number) => void;
};

export function CalculateLiveweightModal({
  context,
  id,
  innerProps: { onDone },
}: ContextModalProps<CalculateLiveweightModalInnerProps>) {
  const defaultValues = { startLiveweightKgs: '0', liveweightGainKgs: '0' };

  const formMethods = useForm({
    defaultValues,
  });

  const { watch, handleSubmit } = formMethods;
  const startLiveweightKgs = parseFloat(watch('startLiveweightKgs'));
  const liveweightGainKgs = parseFloat(watch('liveweightGainKgs'));

  const finalLiveweightKgs = useMemo(
    () => startLiveweightKgs + liveweightGainKgs,
    [liveweightGainKgs, startLiveweightKgs],
  );
  const averageLiveweightKgs = useMemo(
    () => (startLiveweightKgs + finalLiveweightKgs) / 2,
    [finalLiveweightKgs, startLiveweightKgs],
  );

  return (
    <Box p="xs">
      <FormProvider {...formMethods}>
        <form>
          <TextInput
            label="Liveweight at the start of the month in kilograms"
            name="startLiveweightKgs"
            validation={{ required: true, min: 1 }}
          />
          <Space h="md" />
          <TextInput
            label="Liveweight gain in kilograms"
            description="'Liveweight gain' is the daily increase or decrease in weight for an animal."
            name="liveweightGainKgs"
            validation={{ required: true, min: 1 }}
          />
          <Space h="md" />
          <Text size="sm">Liveweight at the end of the month</Text>
          <Text size="sm">{finalLiveweightKgs || '-'}</Text>
          <Space h="md" />
          <Text size="sm">Average liveweight for the month</Text>
          <Text size="sm">{averageLiveweightKgs || '-'}</Text>
          <Space h="md" />
          <Divider my="md" />
          <Flex direction="row" gap="sm">
            <ConfirmButton
              onClick={handleSubmit(() => {
                onDone(averageLiveweightKgs);
                context.closeModal(id);
              })}
            />
            <CancelButton onClick={() => context.closeModal(id)} />
          </Flex>
        </form>
      </FormProvider>
    </Box>
  );
}
