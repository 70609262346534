import { MantineThemeOverride } from '@mantine/core';
import { colorCSSVariables } from './colors';
import { fontCSSVariables } from './fonts';
import { shadowCSSVariables } from './shadows';

export const globalStyles: MantineThemeOverride['globalStyles'] = () => {
  return {
    ':root': {
      ...colorCSSVariables,
      ...fontCSSVariables,
      ...shadowCSSVariables,
    },

    body: {
      color: 'var(--mantine-color-text)',
    },

    thead: {
      textTransform: 'uppercase',
    },

    '.mantine-Anchor-root': {
      color: 'var(--mantine-color-link)',
    },

    '.circle': {
      borderRadius: '50%',
      flexShrink: 0,
    },

    '.border-top, .border-bottom, .border-left, .border-right': {
      borderStyle: 'solid',
      borderWidth: 0,
      borderColor: 'var(--mantine-color-border)',
    },

    '.border-top': {
      borderTopWidth: 1,
    },
    '.border-bottom': {
      borderBottomWidth: 1,
    },
    '.border-left': {
      borderLeftWidth: 1,
    },
    '.border-right': {
      borderRightWidth: 1,
    },

    '.capitalize-first': {
      '&:first-letter': {
        textTransform: 'uppercase',
      },
    },
    '.visually-hidden': {
      clip: 'rect(0 0 0 0)',
      clipPath: 'inset(50%)',
      height: '1px',
      overflow: 'hidden',
      position: 'absolute',
      whiteSpace: 'nowrap',
      width: '1px',
    },
    'a.mantine-Badge-root, button.mantine-Badge-root': {
      cursor: 'pointer',
    },

    // MapBox
    '.mapboxgl-ctrl-logo, .mapboxgl-ctrl-attrib-inner, .mapboxgl-ctrl-attrib ':
      {
        // && increases specifity
        '&&': {
          display: 'none',
          visibility: 'hidden',
          width: 0,
          height: 0,
        },
      },
    '.mapboxgl-ctrl:has(.mapboxgl-ctrl-logo)': {
      '&&': {
        padding: 0,
        margin: 0,
      },
    },
  };
};
