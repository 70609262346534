import {
  SegmentedControl as MTSegmentedControl,
  SegmentedControlProps as MTSegmentedControlProps,
} from '@mantine/core';
import { ForwardedRef, forwardRef } from 'react';
import styles from './BaseSegmentedControl.module.scss';

type BaseSegmentedControlProps = MTSegmentedControlProps;

function BaseSegmentedControlComponent(
  props: BaseSegmentedControlProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  return (
    <MTSegmentedControl
      classNames={{ input: styles.input }}
      fullWidth
      {...props}
      ref={ref}
    />
  );
}

export const BaseSegmentedControl = forwardRef<
  HTMLInputElement,
  BaseSegmentedControlProps
>(BaseSegmentedControlComponent);
