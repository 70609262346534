import { HexColor } from './types/colors';

// Unsure where these colors came from, not in design system
const colorCream: HexColor = '#FFCDCC';
const colorRed: HexColor = '#FF0000';

// Map colors
const colorAqua: HexColor = '#43E0CE';
const colorLime: HexColor = '#83E05A';
// const colorLightYellow: HexColor = '#F2D399';
// const colorLightOrange: HexColor = '#F9A763';

const propertyFillColors: HexColor[] = [
  colorLime,
  // colorLightYellow,
  // colorLightOrange,
  // Remaining colors are taken from chart colors
  // '#377A73',
  // '#075994',
  // '#0F1694',
  // '#940A07',
  // '#A67F37',
  // '#377A73',
  // '#3A79A6',
  // '#4247A6',
  // '#A63C3A',
  // '#B59D72',
  // '#6FAFA8',
  // '#4FA4E0',
  // '#5A60E0',
  // '#E0514F',

  // The Angus Collection
  '#F5F058',
  '#FF7E4F',
  '#3E97FF',
  '#FF5A5A',
  '#9A6AFF',
  '#37FF87',
  '#FFBF49',
  '#FF6FF1',
];

const mapTheme = {
  borders: {
    default: colorCream,
    drawing: colorRed,
    selected: colorAqua,
  },
  fill: {
    drawing: colorRed,
    selected: colorAqua,
  },
};

export { mapTheme, colorCream, colorAqua, propertyFillColors };
