import { Select, SelectProps } from '@mantine/core';
import { ForwardedRef, forwardRef } from 'react';
import { DEFAULT_INPUT_WRAPPER_ORDER } from '../constants';
import formStyles from '../formStyles.module.scss';

function BaseSelectComponent(
  props: SelectProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  return (
    <Select
      className={formStyles.root}
      inputWrapperOrder={DEFAULT_INPUT_WRAPPER_ORDER}
      {...props}
      ref={ref}
    />
  );
}

export const BaseSelect = forwardRef<HTMLInputElement, SelectProps>(
  BaseSelectComponent,
);
