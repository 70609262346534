import { FragmentType, getFragmentData, gql } from '@agtuary/graphql';
import { Stack } from '@mantine/core';
import { Route } from 'nextjs-routes';
import { useMemo } from 'react';
import { MapShapesListItem } from './MapShapesListItem';

const MapShapesListMapShapeFragment = gql(/* GraphQL */ `
  fragment MapShapesList_MapShape on MapShape {
    id
    ...MapShapesListItem_MapShape
  }
`);

type MapShapesListProps = {
  mapShapes: readonly FragmentType<typeof MapShapesListMapShapeFragment>[];
  editHref?: (mapShapeId: string) => Route;
} & (
  | {
      selectable: true;
      selected?: readonly string[];
      onChangeSelected?: (id: string, selected: boolean) => void;
    }
  | {
      selectable?: false;
      selected?: never;
      onChangeSelected?: never;
    }
);

export function MapShapesList({
  mapShapes,
  editHref,
  selectable = false,
  selected = [],
  onChangeSelected,
}: MapShapesListProps) {
  const shapes = useMemo(
    () => getFragmentData(MapShapesListMapShapeFragment, mapShapes),
    [mapShapes],
  );

  return (
    <Stack>
      {shapes?.map((shape, index) => (
        <MapShapesListItem
          selectable={selectable}
          key={shape.id}
          mapShape={shape}
          index={index}
          editHref={editHref}
          selected={selected.includes(shape.id)}
          onChangeSelected={(checked: boolean) =>
            onChangeSelected(shape.id, checked)
          }
        />
      ))}
    </Stack>
  );
}
