/* eslint-disable no-console */
import { noop } from '@agtuary/common/helpers/functions';
import { LDLogger, ProviderConfig } from 'launchdarkly-react-client-sdk';

const logger: LDLogger = {
  debug: noop,
  error: console.error,
  warn: console.warn,
  info: noop,
};

export const ldConfig: ProviderConfig = {
  clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID ?? '',
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  options: {
    logger,
  },
  timeout: 3,
};
