import { useReportUrls } from '@agtuary/api/hooks/reports/useReportUrls';
import { Property } from '@agtuary/api/types/property';
import { Report } from '@agtuary/api/types/report';
import { useDownloadReport } from './useDownloadReport';
import { useShareReport } from './useShareReport';

type ReportActions = {
  download: ReturnType<typeof useDownloadReport>;
  share: ReturnType<typeof useShareReport>;
};

export function useReportActions(
  report: Report,
  property: Property,
): ReportActions {
  const { data: urls } = useReportUrls(report);

  const download = useDownloadReport(report, property, urls);

  const share = useShareReport(report);

  return {
    download,
    share,
  };
}
