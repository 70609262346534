import { Title } from '@mantine/core';
import { openContextModal } from '@mantine/modals';
import { SelectMapShapeFromLibraryModalInnerProps } from './SelectMapShapeFromLibraryModal';

export const selectMapShapeFromLibraryModal = 'selectMapShapeFromLibraryModal';

export const useSelectMapShapeFromLibraryModal = () => {
  return {
    ctx: selectMapShapeFromLibraryModal,
    open: (
      innerProps: SelectMapShapeFromLibraryModalInnerProps,
      onClose?: Parameters<typeof openContextModal>[0]['onClose'],
    ) => {
      openContextModal({
        modal: selectMapShapeFromLibraryModal,
        innerProps,
        title: <Title order={4}>Choose from map shapes</Title>,
        onClose,
      });
    },
  };
};
