import { Box, Button, Group, Text } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { EventHandler, MouseEvent } from 'react';

export type ConfirmDeleteModalInnerProps = {
  onDelete: EventHandler<MouseEvent>;
};

export function ConfirmDeleteModal({
  context,
  id,
  innerProps: { onDelete },
}: ContextModalProps<ConfirmDeleteModalInnerProps>) {
  return (
    <>
      <Box mb="md">
        <Text size="sm">Are you sure you want to delete this item?</Text>
      </Box>

      <Group position="right">
        <Button variant="default" onClick={() => context.closeModal(id)}>
          Cancel
        </Button>

        <Button
          onClick={(e) => {
            context.closeModal(id);
            onDelete(e);
          }}
          data-autofocus
        >
          Confirm
        </Button>
      </Group>
    </>
  );
}
