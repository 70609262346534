import { Job } from '@agtuary/api/types/job';
import { CircleQuestionIcon, PaperCheckIcon } from '@agtuary/ui/icons';
import {
  Box,
  Loader,
  Text,
  Tooltip,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import {
  SortingState,
  TableOptions,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import { Table } from 'components/tables/Table';
import dayjs from 'dayjs';
import { PropsWithChildren, useMemo, useState } from 'react';
import { ReportRow } from './types';

type JobReportListModalInnerProps = {
  job: Job;
  onRowClick?: (row: ReportRow) => void;
};

const useStyles = createStyles((theme) => ({
  bold: {
    fontWeight: theme.other.fonts.weights.bold,
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

function NameCell({ children }: PropsWithChildren) {
  const { classes, cx } = useStyles();
  return <Text className={cx(classes.bold, classes.ellipsis)}>{children}</Text>;
}

function PropertyCell({ children }: PropsWithChildren) {
  const { classes } = useStyles();
  return <Text className={classes.ellipsis}>{children}</Text>;
}

const columnHelper = createColumnHelper<ReportRow>();

function StatusIcon({ status, size }: { status: string; size: number }) {
  const theme = useMantineTheme();

  return (
    <Tooltip
      label={
        {
          generating: 'Generating',
          completed: 'Ready',
          error: (
            <>
              Report failed
              <br />
              Click to retry
            </>
          ),
        }[status] || 'Status unknown'
      }
      withArrow
    >
      <Box component="span">
        {{
          generating: <Loader size={size} />,
          completed: (
            <PaperCheckIcon
              width={size}
              height={size}
              color={theme.other.colors.primary}
            />
          ),
        }[status] || (
          <CircleQuestionIcon
            width={size}
            height={size}
            color={theme.colors.gray[6]}
          />
        )}
      </Box>
    </Tooltip>
  );
}

const columns = [
  columnHelper.accessor('name', {
    cell: (info) => <NameCell>{info.getValue()}</NameCell>,
    size: 150,
  }),
  columnHelper.accessor('propertyName', {
    header: 'Property',
    cell: (info) => <PropertyCell>{info.getValue()}</PropertyCell>,
    size: 150,
  }),
  columnHelper.accessor('date', {
    cell: (info) => info.getValue().format('DD.MM.YYYY'),
    size: 100,
  }),
  columnHelper.accessor('status', {
    // Possible status values (presumably, this should be made into an enum on BE and FE):
    // 'generating', 'completed', 'error'
    header: null,
    cell: (info) => <StatusIcon status={info.getValue()} size={18} />,
    size: 20,
  }),
];

export function JobReportListModal({
  innerProps: { job, onRowClick },
}: ContextModalProps<JobReportListModalInnerProps>) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const data = useMemo(
    () =>
      job?.properties?.reduce<ReportRow[]>(
        (acc, pij, pijIndex) => [
          ...acc,
          ...(pij.property?.reports?.map((report) => ({
            id: report.id,
            name: report.name,
            propertyName:
              pij.name ||
              pij.property?.addressLine1 ||
              `Property ${pijIndex + 1}`,
            date: dayjs(report.createdAt),
            status: report.status,
            report,
            property: pij.property,
          })) ?? []),
        ],
        [],
      ),
    [job],
  );

  const tableOptions: TableOptions<ReportRow> = {
    defaultColumn: {
      minSize: 20,
      size: 0,
      // maxSize: 200,
    },
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  };

  return (
    <Table
      tableProps={{ highlightOnHover: true }}
      tableOptions={tableOptions}
      onRowClick={onRowClick}
      shouldDisableRow={(r) => r.status !== 'completed'}
      noDataMessage="No reports"
    />
  );
}
