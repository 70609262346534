import { UseFormReturnType } from '@mantine/form';
import { DependencyList, useEffect, useMemo, useState } from 'react';

/**
 * useLoadingInitialValues
 *
 * This hook helps to set initial values for form when all dependencies are loaded.
 * It is useful when you need to load some data from server before you can set initial values.
 *
 * Deps are checked for truthyness to determine if they are loaded.
 *
 * Returns boolean indicating if the values are still loading.
 */

export const useLoadingInitialValues = <T>(
  form: UseFormReturnType<T>,
  initialValuesFactory: () => T,
  deps: DependencyList,
) => {
  const allDepsLoaded = (deps2: DependencyList) => deps2.every((dep) => dep);

  const [loading, setLoading] = useState(!allDepsLoaded(deps));

  const initialValues = useMemo(initialValuesFactory, [
    initialValuesFactory,
    deps,
  ]);

  useEffect(() => {
    if (allDepsLoaded(deps) && loading) {
      // Only set values if the form is untouched
      if (!form.isTouched()) {
        form.setValues(initialValues);
        form.resetDirty();
      }
      setLoading(false);
    }
  }, [deps, form, initialValues, loading]);

  return loading;
};
