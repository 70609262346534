import { Image, ImageProps, rem } from '@mantine/core';
import { memo } from 'react';
import { getSrc, legalSize } from './helpers';
import { Viewport } from './types';

type MapImageProps = {
  viewport?: Viewport;
  width: number;
  height: number;
  geoJson?: GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>;
} & Omit<ImageProps, 'src' | 'w' | 'h' | 'width' | 'height'>;

function MapImageComponent({
  viewport = 'auto',
  width,
  height,
  geoJson,
  ...imageProps
}: MapImageProps) {
  const src = getSrc(viewport, legalSize(width), legalSize(height), geoJson);
  return <Image src={src} w={rem(width)} h={rem(height)} {...imageProps} />;
}

export const MapImage = memo(MapImageComponent);
