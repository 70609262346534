import { useMergedRef } from '@mantine/hooks';
import { ComponentProps, ForwardedRef, forwardRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { DEFAULT_INPUT_WRAPPER_ORDER } from '../constants';
import formStyles from '../formStyles.module.scss';
import { useFormLogic } from '../hooks';
import { RHFInputProps } from '../types';
import { BaseSelect } from './BaseSelect';

type SelectProps = RHFInputProps & ComponentProps<typeof BaseSelect>;

function SelectComponent(
  props: SelectProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { name, searchable, onChange } = props;
  const { label, error, validationRules } = useFormLogic(props);
  const { control } = useFormContext();
  const { field } = useController({ name, control, rules: validationRules });
  const mergedRef = useMergedRef(ref, field.ref);

  return (
    <BaseSelect
      className={formStyles.root}
      inputWrapperOrder={DEFAULT_INPUT_WRAPPER_ORDER}
      placeholder="Select value"
      description={
        searchable &&
        'You can type in the field to narrow down the list of options'
      }
      {...props}
      {...field}
      ref={mergedRef}
      error={error}
      label={label}
      onChange={(value) => {
        field.onChange({ target: { value } });
        if (onChange) {
          onChange(value);
        }
      }}
    />
  );
}

export const Select = forwardRef<HTMLInputElement, SelectProps>(
  SelectComponent,
);
