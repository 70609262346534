import { RoleName } from '@agtuary/api/types/role';
import { entriesFromObject } from '@agtuary/common/helpers/typed/object';
import { getApplicationMode } from 'helpers/applicationMode';

export const roleOptionsMap: Record<RoleName, string> = {
  ADMIN: 'Administrator',
  MEMBER: 'Contributor',
  VIEWER: 'Viewer',
  ADVISOR: 'Advisor',
};

export const roleDescriptionMap: Record<RoleName, string> = {
  ADMIN:
    'can invite others to this team and manage them. They can also create, edit and delete content created in this team.',
  MEMBER: 'can create, edit and delete content created in this team.',
  ADVISOR: 'can create, edit and delete content created in this team.',
  VIEWER: 'can view content created in this team.',
};

const userRoleOptionsAll = entriesFromObject(roleOptionsMap)
  .filter(([role]) => role !== 'ADVISOR')
  .map(([value, label]) => ({
    value,
    label,
  }));

const {
  options: { supportsViewerRole },
} = getApplicationMode();

export const userRoleOptions = supportsViewerRole
  ? userRoleOptionsAll
  : userRoleOptionsAll.filter((option) => option.value !== 'VIEWER');
