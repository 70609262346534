import { Dispatch, SetStateAction, createContext } from 'react';
import { PartialMutationResult } from './types';

export type FormMutations = {
  updateResult?: PartialMutationResult;
  deleteResult?: PartialMutationResult;
};

export type FormMutationContextProps = FormMutations & {
  setUpdateResult: Dispatch<SetStateAction<PartialMutationResult>>;
  setDeleteResult: Dispatch<SetStateAction<PartialMutationResult>>;
};

export const FormMutationContext = createContext<
  FormMutationContextProps | undefined
>(undefined);
