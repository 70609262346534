import { MantineThemeOverride, rem } from '@mantine/core';
import { colors, colorsOther } from './colors';
import { components } from './components';
import { fonts, fontsOther } from './fonts';
import { shadows } from './shadows';
import { breakpoints } from './breakpoints';
import { globalStyles } from './global';

export const MantineTheme: MantineThemeOverride = {
  ...colors,
  ...fonts,
  ...shadows,
  ...breakpoints,

  // Determines whether motion based animations should be disabled for
  // users who prefer to reduce motion in their OS settings
  respectReducedMotion: true,

  radius: {
    xs: rem(2),
    sm: rem(4),
    md: rem(8),
    lg: rem(16),
    xl: rem(32),
  },

  components,

  globalStyles,
  other: {
    ...colorsOther,
    ...fontsOther,
  },
};

export { globalStyles };
