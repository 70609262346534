import { Flex, Switch } from '@mantine/core';
import { PropsWithChildren } from 'react';
import { SwitchProps } from '../Switch';
import styles from './SwitchRow.module.scss';

type SwitchRowProps<T extends object> = Omit<
  PropsWithChildren<SwitchProps>,
  'name' | 'checked'
> & {
  data: T | null | undefined;
  name: string & keyof T;
};

/**
 * A component to capture the common of a Switch with a link or button on the right side of the page. All of this is underlined with a divider.
 * @param props
 * @returns
 */
export function SwitchRow<T extends object>({
  children,
  data,
  name,
  ...props
}: SwitchRowProps<T>) {
  return (
    <Flex className={styles.tableRow} direction="row" justify="space-between">
      <Switch
        onLabel="ON"
        offLabel="OFF"
        checked={Boolean(data?.[name])}
        name={name}
        {...props}
      />
      {children}
    </Flex>
  );
}
