import { useEffect, useState } from 'react';
import { Team } from '@agtuary/api/types/team';
import {
  ACTIVE_SUBSCRIPTION_STATUSES,
  BillingFrequency,
  SubscriptionStatus,
} from '@agtuary/api/constants/paymentSubscription';
import { StripeFeatures } from '@agtuary/api/types/stripeFeatures';

export const useTeamSubscription = (
  team: Team,
): {
  isSubscribed: boolean;
  isCanceled: boolean;
  seats: number;
  billFrequency: BillingFrequency | null;
  seatPrice: number | null;
  isExternalSubscription: boolean;
  status: SubscriptionStatus | null;
  hasSubscription: boolean;
  features: StripeFeatures | null;
} => {
  const [seats, setSeats] = useState<number>(0);
  const [billFrequency, setBillFrequency] = useState<BillingFrequency | null>(
    null,
  );
  const [seatPrice, setSeatPrice] = useState<number | null>(null);
  const [isExternalSubscription, setIsExternalSubscription] = useState(false);
  const [status, setStatus] = useState<SubscriptionStatus | null>(null);
  const [hasSubscription, setHasSubscription] = useState<boolean>(false);
  const [features, setFeatures] = useState<StripeFeatures | null>(null);

  useEffect(() => {
    if (team) {
      const paymentSubscription = team.paymentSubscriptions.at(0);
      if (paymentSubscription) {
        setHasSubscription(true);
        const external = paymentSubscription.billingType === 'external';
        setIsExternalSubscription(external);
        setSeats(
          Number.isNaN(paymentSubscription.noOfSeats)
            ? 0
            : paymentSubscription.noOfSeats,
        );
        setStatus(paymentSubscription.status);
        setFeatures(team.stripeEntities.at(0));

        if (external) {
          setBillFrequency(null);
          setSeatPrice(null);
        } else {
          setBillFrequency(paymentSubscription.billingFrequency);
          // eslint-disable-next-line functional/no-let
          let calculatedSeatPrice = paymentSubscription.price;
          if (paymentSubscription.discountPercentage) {
            calculatedSeatPrice -=
              calculatedSeatPrice *
              (paymentSubscription.discountPercentage / 100);
          }
          setSeatPrice(calculatedSeatPrice);
        }
      } else {
        setIsExternalSubscription(false);
        setSeats(0);
        setBillFrequency(null);
        setSeatPrice(null);
        setStatus(null);
        setHasSubscription(false);
      }
    }
  }, [team]);

  return {
    isSubscribed: !!seats && ACTIVE_SUBSCRIPTION_STATUSES.includes(status),
    isCanceled: !!seats && status === SubscriptionStatus.CANCELED,
    seats,
    billFrequency,
    seatPrice,
    isExternalSubscription,
    status,
    hasSubscription,
    features,
  };
};
