import { AsideProps, HeaderProps, NavbarProps } from '@mantine/core';

// General
const LEFT_SECTION_WIDTH: Partial<Record<string, string | number>> = {
  sm: 230,
  lg: 340,
};
const RIGHT_SECTION_WIDTH: Partial<Record<string, string | number>> = {
  sm: 230,
  lg: 340,
};

const SLIM_HEADER_HEIGHT = 53;

// Headers
export const HEADER_PAGE_TITLE_HEIGHT = 62;
export const HEADER_HEIGHT_SLIM: HeaderProps['height'] = SLIM_HEADER_HEIGHT;
export const HEADER_HEIGHT_WITH_TITLE: HeaderProps['height'] =
  SLIM_HEADER_HEIGHT + HEADER_PAGE_TITLE_HEIGHT;
export const HEADER_LEFT_SECTION_WIDTH = LEFT_SECTION_WIDTH;
export const HEADER_RIGHT_SECTION_WIDTH = RIGHT_SECTION_WIDTH;

// Navbars
export const NAVBAR_WIDTH: NavbarProps['width'] = LEFT_SECTION_WIDTH;

// Asides
export const ASIDE_WIDTH: AsideProps['width'] = RIGHT_SECTION_WIDTH;

// Account Component
export const ACCOUNT_HEIGHT = SLIM_HEADER_HEIGHT;
