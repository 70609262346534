import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { useHydratedStore } from './helpers/useHydratedStore';

type SessionStoreState = {
  paymentNudgeShown: boolean;
  /**
   * We use currentTeamId as the fallback if a team ID is not present in the URL.
   * We set currentTeamId when the user manually switches teams, or a teamID is found in the URL.
   * Eventually we want to remove this from state and only use the URL. This requires a rewrite
   * of the teams and organisation pages.
   * null when hydrated but not set. undefined when not hydrated.
   */
  currentTeamId: string | null | undefined;
};

type SessionStore = SessionStoreState & {
  setPaymentNudgeShown: (seen: boolean) => void;
  setCurrentTeamId: (teamId: string | undefined) => void;
};

const initialState: SessionStoreState = {
  paymentNudgeShown: false,
  currentTeamId: null,
};

// Appropriate values before hydration.
// e.g. if we don't want a modal to show if the user has already seen it,
// we use true here but false in initialState.
const dehydratedState: SessionStoreState = {
  ...initialState,
  paymentNudgeShown: true,
  currentTeamId: undefined,
};

export const useSessionStore = create(
  persist<SessionStore>(
    (set) => ({
      ...initialState,
      setPaymentNudgeShown: (seen: boolean) => set({ paymentNudgeShown: seen }),
      setCurrentTeamId: (teamId: string) => set({ currentTeamId: teamId }),
    }),
    {
      name: 'session-state',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

// SSR safe store (state only, no actions)
export const useHydratedSessionStore = (
  selector: (
    state: SessionStoreState,
  ) => SessionStoreState[keyof SessionStoreState],
) => useHydratedStore(useSessionStore, dehydratedState, selector);

// Helper functions to access outside of React components
export const resetSessionStore = () => {
  useSessionStore.persist.clearStorage();
};
