import { URL } from 'url';
import { API_ERROR_NAME } from '../../constants/error';
import { HTTP_METHOD } from '../../constants/request';
import { parseUrl } from '../../parsers';
import { APIError } from '../../types/error';
import { QueryParams } from '../../types/request';

export const fetcher = async (url: string | URL | [string, QueryParams]) => {
  const parsedUrl = Array.isArray(url) ? parseUrl(...url) : url;

  const response = await fetch(parsedUrl);

  if (!response.ok) {
    const json = await response.json();
    const err: APIError = ((): APIError => ({
      message: JSON.stringify(json),
      name: API_ERROR_NAME,
      status: response.status,
      info: json,
    }))();

    throw err;
  }

  return response.json();
};

type mutatorArgs<T> = { arg: T };
type partialMutatorArgs<T> = mutatorArgs<Partial<T>>;

const baseMutator = async <T, R = T>(
  url: string | URL | [string, QueryParams],
  { arg }: partialMutatorArgs<T>,
  method: HTTP_METHOD,
): Promise<R> => {
  const parsedUrl = Array.isArray(url) ? parseUrl(...url) : url;

  const requiresMultipart =
    arg !== undefined &&
    arg !== null &&
    typeof arg === 'object' &&
    Object.values(arg).some((value) => value instanceof File);

  const body = (() => {
    if (requiresMultipart) {
      const formData = new FormData();

      Object.entries(arg).forEach(([key, value]) =>
        formData.append(
          key,
          value instanceof File ? value : JSON.stringify(value),
        ),
      );

      return formData;
    }

    return JSON.stringify(arg);
  })();

  const headers = requiresMultipart
    ? undefined
    : { 'Content-type': 'application/json; charset=UTF-8' };

  const response = await fetch(parsedUrl, {
    method,
    body,
    headers,
  });

  const contentType = response.headers.get('content-type');
  const isJSON = contentType && contentType.indexOf('application/json') !== -1;

  if (!response.ok) {
    // When the response is not ok, we don't know if the response is JSON or not.
    const message = await response.text();
    const err: APIError = {
      message,
      name: API_ERROR_NAME,
      status: response.status,
      info: (() => {
        if (isJSON) {
          try {
            return JSON.parse(message);
          } catch (_) {
            return message;
          }
        }
        return message;
      })(),
    };

    throw err;
  }

  return response.json();
};

export const patchMutator = <T, R = T>(
  url: string | URL | [string, QueryParams],
  args: partialMutatorArgs<T>,
): Promise<R> => baseMutator<T, R>(url, args, HTTP_METHOD.PATCH);

export const postMutator = <T, R = T>(
  url: string | URL | [string, QueryParams],
  args: mutatorArgs<T>,
): Promise<R> => baseMutator<T, R>(url, args, HTTP_METHOD.POST);

export const putMutator = <T, R = T>(
  url: string | URL | [string, QueryParams],
  args: mutatorArgs<T>,
): Promise<R> => baseMutator<T, R>(url, args, HTTP_METHOD.PUT);

export const deleteMutator = <T, R = T>(
  url: string | URL | [string, QueryParams],
  args: mutatorArgs<T>,
): Promise<R> => baseMutator<T, R>(url, args, HTTP_METHOD.DELETE);
