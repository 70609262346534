export const aiaOnboardingEnterprises = [
  { value: 'Beef', label: 'Beef' },
  { value: 'Feedlot', label: 'Feedlot' },
  { value: 'Sheep', label: 'Sheep' },
  { value: 'Grains', label: 'Grains' },
  { value: 'Horticulture', label: 'Horticulture' },
  { value: 'Dairy', label: 'Dairy' },
  { value: 'Pork', label: 'Pork' },
  { value: 'Eggs', label: 'Eggs' },
  { value: 'Rice', label: 'Rice' },
  { value: 'Chicken meat', label: 'Chicken meat' },
  { value: 'Fish', label: 'Fish' },
  { value: 'Aquaculture', label: 'Aquaculture' },
  { value: 'Cotton', label: 'Cotton' },
  { value: 'Sugar', label: 'Sugar' },
  { value: 'Wine', label: 'Wine' },
  { value: 'Buffalo', label: 'Buffalo' },
  { value: 'Goat', label: 'Goat' },
  { value: 'Deer', label: 'Deer' },
  { value: 'Forestry', label: 'Forestry' },
];
