import { useUser } from '@agtuary/api/hooks/users/useUser';
import { useUserTeams } from '@agtuary/api/hooks/users/useUserTeams';
import { Team } from '@agtuary/api/types/team';
import { setUser as setSentryUser } from '@sentry/core';
import { signIn, useSession } from 'next-auth/react';
import { useEffect } from 'react';
import { useCurrentTeam } from './useCurrentTeam';

// This custom hook returns the currently authenticated user and their associated teams
export const useCurrentUser = (): {
  user: ReturnType<typeof useUser>; // The currently authenticated user
  userId: string | undefined; // The ID of the currently authenticated user
  teams: ReturnType<typeof useUserTeams>; // The teams associated with the current user
  currentTeam: Team | undefined; // The current team for the user
  setCurrentTeam: (team: Team) => void; // A function to set the current team for the user
} => {
  const session = useSession();

  // If the user is not authenticated, redirect them to the login page
  useEffect(() => {
    if (
      session?.status !== 'loading' &&
      (!session?.data?.user?.id || session?.status === 'unauthenticated')
    ) {
      signIn('auth0');
    }
  }, [session]);

  // Get the current user's ID from the session
  const userId = session?.data?.user?.id;

  // Get the user's data from the API
  const user = useUser(userId);

  // Get the teams associated with the current user
  const teams = useUserTeams(userId);

  // Get the current team for the user
  const { currentTeam, setCurrentTeam } = useCurrentTeam(
    teams?.data,
    user?.data,
  );

  useEffect(() => {
    if (userId) {
      setSentryUser({
        id: userId,
        username:
          [user?.data?.firstName, user?.data?.lastName].join(' ') || undefined,
      });
    }
  }, [userId, user?.data?.firstName, user?.data?.lastName]);

  return {
    user,
    userId,
    teams,
    currentTeam,
    setCurrentTeam,
  };
};
