import { Anchor, Card, Text } from '@mantine/core';
import { getApplicationMode } from 'helpers/applicationMode';

export function DeleteInformationModal() {
  const {
    options: { bulkDeleteEmail },
  } = getApplicationMode();
  return (
    <Card w="100%" p="xl">
      <Card.Section>
        <Text component="p" mt={0} fw={600}>
          Please email us at{' '}
          <Anchor
            variant="text"
            href={`mailto: ${bulkDeleteEmail}`}
            color="brand.7"
          >
            {bulkDeleteEmail}
          </Anchor>{' '}
          requesting deletion of all data for this organisation.
        </Text>
        <Text component="p" mt={0}>
          If reports generated in this platform have been shared with third
          parties, they may request to see the data used to generate those
          reports. We encourage requesting a backup of your data before
          deleting.
        </Text>
      </Card.Section>
    </Card>
  );
}
