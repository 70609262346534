import { Property } from '@agtuary/api/types/property';
import { Report } from '@agtuary/api/types/report';
import { downloadReportPdf } from 'helpers/reports';
import { useCallback } from 'react';

export function useDownloadReport(
  report: Report,
  property: Property,
  urls: { interactiveUrl: string; pdfUrl: string },
): () => void {
  return useCallback(() => {
    if (urls?.pdfUrl) {
      downloadReportPdf(urls.pdfUrl, report, property);
    }
  }, [property, report, urls?.pdfUrl]);
}
