import { PlusIcon } from '@agtuary/ui/icons';
import { Button, Flex } from '@mantine/core';
import { CancelButton, ConfirmButton, DeleteButton } from 'components/buttons';
import { ExtendedHTMLFormElement } from 'components/forms/Form';
import { useFormMutations } from 'components/layouts/FormLayout/hooks';
import { useAccessRights } from 'hooks/useAccessRights';
import {
  MouseEvent,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styles from './FormFooter.module.scss';

type FormFooterProps = {
  formRef: RefObject<ExtendedHTMLFormElement | null>;
  doneLabel?: string;
  includePadding?: boolean;
  showAddAnother?: boolean;
  allowEdit?: boolean;
} & (
  | {
      hideCancel?: false;
      cancelLabel?: string;
      goBackOnSuccess?: boolean;
      goBack: () => void;
    }
  | {
      hideCancel: true;
      cancelLabel?: never;
      goBackOnSuccess: false;
      goBack?: never;
    }
) &
  (
    | {
        hideDelete: false;
        onDelete: () => void;
      }
    | {
        hideDelete?: true;
        onDelete?: never;
      }
  );

const addAnotherIcon = <PlusIcon />;

export function FormFooter({
  goBack,
  doneLabel,
  hideCancel,
  cancelLabel,
  hideDelete = true,
  includePadding = true,
  onDelete,
  formRef,
  showAddAnother = false,
  goBackOnSuccess = true,
  allowEdit = true,
}: FormFooterProps) {
  const onCancel = useCallback(() => {
    formRef.current?.reset();
    if (goBack) {
      goBack();
    }
  }, [formRef, goBack]);

  const onSubmit = useCallback(() => {
    formRef.current?.requestSubmit();
  }, [formRef]);

  const { updateResult, deleteResult } = useFormMutations();

  const [waitingToGoBack, setWaitingToGoBack] = useState(false);
  const [confirmButtonUsed, setConfirmButtonUsed] = useState<
    'confirm' | 'addAnother' | null
  >(null);

  const confirmLoading = Boolean(updateResult?.loading);
  const deleteLoading = Boolean(deleteResult?.loading);
  const confirmErrored = Boolean(updateResult?.error);
  const deleteErrored = Boolean(deleteResult?.error);
  const confirmComplete =
    updateResult?.called && !confirmLoading && !confirmErrored;
  const deleteComplete =
    deleteResult?.called && !deleteLoading && !deleteErrored;
  const isSubmitting = confirmLoading || deleteLoading;
  const isComplete = confirmComplete || deleteComplete;
  // If cancel is hidden, the form persists after save, we want to allow additional saves
  const acceptClicks = !isSubmitting && (hideCancel || !isComplete);

  const confirmButtonLoading =
    confirmLoading && confirmButtonUsed === 'confirm';
  const addAnotherButtonLoading =
    confirmLoading && confirmButtonUsed === 'addAnother';

  const handleConfirm = useCallback(
    (addAnother: boolean) => (e: MouseEvent<HTMLButtonElement>) => {
      if (acceptClicks) {
        e.preventDefault();
        setConfirmButtonUsed(addAnother ? 'addAnother' : 'confirm');
        onSubmit();
        setWaitingToGoBack(!addAnother && goBackOnSuccess);
      }
    },
    [acceptClicks, goBackOnSuccess, onSubmit],
  );

  useEffect(() => {
    if (confirmComplete || confirmErrored) {
      setConfirmButtonUsed(null);
    }
  }, [confirmComplete, confirmErrored]);

  useEffect(() => {
    if (isComplete) {
      updateResult?.reset();
      deleteResult?.reset();

      if (confirmButtonUsed === 'addAnother') {
        // clear is a custom method we have exposed on our custom ref type, it is not a standard HTMLFormElement method
        // It is a more aggressive clearing of the form compared to just resetting the form (via reset)
        formRef.current?.clear();
        formRef.current?.reset();
      }

      if (waitingToGoBack) {
        goBack?.();
        setWaitingToGoBack(false);
      }
    }
  }, [
    confirmButtonUsed,
    deleteResult,
    formRef,
    goBack,
    isComplete,
    updateResult,
    waitingToGoBack,
  ]);

  const confirmOnClick = useMemo(() => handleConfirm(false), [handleConfirm]);
  const addAnotherOnClick = useMemo(() => handleConfirm(true), [handleConfirm]);

  const cancelOnClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (acceptClicks) {
        e.preventDefault();
        onCancel();
      }
    },
    [acceptClicks, onCancel],
  );

  const { canEdit } = useAccessRights();

  return (
    <Flex
      direction="row"
      gap="sm"
      justify="space-between"
      className={includePadding ? styles.padWrapper : undefined}
    >
      <Flex direction="row" gap="sm" className={styles.container}>
        {canEdit && allowEdit ? (
          <>
            {showAddAnother && (
              <Button
                variant="outline"
                onClick={addAnotherOnClick}
                loading={addAnotherButtonLoading}
                leftIcon={addAnotherIcon}
              >
                Save and add another
              </Button>
            )}
            <ConfirmButton
              onClick={confirmOnClick}
              loading={confirmButtonLoading}
              text={doneLabel || 'Confirm'}
            />
          </>
        ) : null}
        {!hideCancel && (
          <CancelButton onClick={cancelOnClick} label={cancelLabel} />
        )}
      </Flex>
      <Flex direction="row" gap="sm" className={styles.container}>
        {canEdit && hideDelete === false && onDelete && (
          <DeleteButton
            loading={deleteLoading}
            acceptClicks={acceptClicks}
            onDelete={onDelete}
          />
        )}
      </Flex>
    </Flex>
  );
}
