import {
  Textarea as MTTextarea,
  TextareaProps as MTTextareaProps,
} from '@mantine/core';
import { useMergedRef } from '@mantine/hooks';
import { ForwardedRef, forwardRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { DEFAULT_INPUT_WRAPPER_ORDER } from '../constants';
import formStyles from '../formStyles.module.scss';
import { useFormLogic } from '../hooks';
import { RHFInputProps } from '../types';

type TextareaProps = RHFInputProps & MTTextareaProps;

function TextareaComponent(
  props: TextareaProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { name, ...innerProps } = props;
  const { control } = useFormContext();

  const { error, label, validationRules } = useFormLogic(props);

  const { field } = useController({ name, control, rules: validationRules });

  const mergedRef = useMergedRef(ref, field.ref);

  return (
    <MTTextarea
      className={formStyles.root}
      inputWrapperOrder={DEFAULT_INPUT_WRAPPER_ORDER}
      withAsterisk={false}
      {...innerProps}
      {...field}
      label={label}
      error={error}
      ref={mergedRef}
    />
  );
}

export const Textarea = forwardRef<HTMLInputElement, TextareaProps>(
  TextareaComponent,
);
