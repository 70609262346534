import { AIAEAPLogo } from '@agtuary/aia/components/AIAEAPLogo';
import { MantineTheme as AiaTheme } from '@agtuary/aia/theme';
import { MantineTheme as AgtuaryTheme } from '@agtuary/ui/theme';
import { MantineThemeOverride } from '@mantine/core';
import { ImageLogo } from 'components/layouts/components/ImageLogo';
import { ReactElement } from 'react';

const applicationModes = ['agtuary', 'aia'] as const;
type ApplicationMode = (typeof applicationModes)[number];

const isApplicationMode = (value: unknown): value is ApplicationMode =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- type guard
  applicationModes.includes(value as ApplicationMode);

type ApplicationModeOptions = {
  primaryLogo: ReactElement;
  pageTitle: string;
  theme: MantineThemeOverride;
  aiaCustomOrgFields?: boolean;
  contactUrl: string;
  termsUrl: string;
  privacyUrl: string;
  faviconFolder: string;
  helpUrl?: string;
  bulkDeleteEmail: string;
  supportsViewerRole: boolean;
};

const agtuaryOptions: ApplicationModeOptions = {
  primaryLogo: <ImageLogo />,
  pageTitle: 'Agtuary',
  theme: AgtuaryTheme,
  contactUrl: 'https://www.agtuary.com/contact',
  termsUrl: 'https://www.agtuary.com/legal/terms-of-use',
  privacyUrl: 'https://www.agtuary.com/legal/privacy-policy',
  faviconFolder: '/',
  helpUrl:
    'https://agtuary.notion.site/Agtuary-Help-4f0ae6e05b6e4b969fa7ce33fd9c2b87',
  bulkDeleteEmail: 'bulkdelete@agtuary.com',
  supportsViewerRole: false,
};

const applicationModeOptions: Record<ApplicationMode, ApplicationModeOptions> =
  {
    agtuary: agtuaryOptions,
    aia: {
      primaryLogo: <AIAEAPLogo />,
      pageTitle: 'Agricultural Innovation Australia',
      theme: AiaTheme,
      aiaCustomOrgFields: true,
      contactUrl: 'https://aginnovationaustralia.com.au/contact-us/',
      termsUrl: 'https://www.aiaeap.com/t-cs',
      privacyUrl: 'https://aginnovationaustralia.com.au/privacy-policy/',
      faviconFolder: '/aia-favicon/',
      bulkDeleteEmail: 'info@aiaplatform.com.au',
      helpUrl: 'https://www.aiaeap.com/aia-eap-help',
      supportsViewerRole: true,
    },
  };

/**
 * Return application config based on the environment variable CUSTOM_APPLICATION_MODE
 * If the variable is not set, it will return the default config for Agtuary platform behaviour
 */
export function getApplicationMode() {
  const applicationMode: ApplicationMode = isApplicationMode(
    process.env.NEXT_PUBLIC_CUSTOM_APPLICATION_MODE,
  )
    ? process.env.NEXT_PUBLIC_CUSTOM_APPLICATION_MODE
    : 'agtuary';

  return {
    mode: applicationMode,
    options: applicationModeOptions[applicationMode],
  };
}
