import { Router } from 'next/router';
import { useEffect } from 'react';

/**
 * A hook to get user confirmation before a navigation event can occur. This covers next.js navigation and
 * browser / page events like closing the window or tab.
 * Inspired by https://gist.github.com/yuvalkarmi/ab7944b2da693c71cf697db6a47e5b5d
 * @param shouldBlockNavigation should navigation events be blocked?
 */
export const useUnsavedChanges = (
  shouldBlockNavigation: boolean,
  customMessage?: string,
) => {
  useEffect(() => {
    const nativeBrowserHandler = (event: BeforeUnloadEvent) => {
      if (shouldBlockNavigation) {
        event.preventDefault();
      }
    };

    const nextNavigationHandler = (_url: string) => {
      if (shouldBlockNavigation) {
        if (
          // eslint-disable-next-line no-alert
          !window.confirm(
            customMessage ??
              'Are you sure you want to navigate away? Your current changes will be lost.',
          )
        ) {
          Router.events.emit('routeChangeError');
          // eslint-disable-next-line no-throw-literal
          throw "Abort route change by user's confirmation.";
        }
      }
    };

    window.addEventListener('beforeunload', nativeBrowserHandler);
    Router.events.on('beforeHistoryChange', nextNavigationHandler);

    // On component unmount, remove the event listener
    return () => {
      window.removeEventListener('beforeunload', nativeBrowserHandler);
      Router.events.off('beforeHistoryChange', nextNavigationHandler);
    };
  }, [customMessage, shouldBlockNavigation]);
};
