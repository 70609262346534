import { Anchor } from '@mantine/core';
import { captureException } from '@sentry/nextjs';
import Link from 'next/link';
import { Component, ErrorInfo, ReactNode } from 'react';
import { ErrorContent } from './ErrorContent';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, _errorInfo: ErrorInfo) {
    captureException(error);
  }

  public render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <ErrorContent
          message={
            <>
              An application error occurred. Please{' '}
              <Anchor component={Link} href="/auth/logout">
                try logging in again
              </Anchor>
              .
            </>
          }
        />
      );
    }

    return children;
  }
}

export { ErrorBoundary };
