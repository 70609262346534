import { useMergedRef } from '@mantine/hooks';
import {
  ComponentProps,
  ForwardedRef,
  forwardRef,
  useCallback,
  useMemo,
} from 'react';
import { useController, useFormContext } from 'react-hook-form';
import formStyles from '../formStyles.module.scss';
import { useFormLogic } from '../hooks';
import { RHFInputProps } from '../types';
import { buildValidationRules } from '../validation';
import { BaseNumberInput } from './BaseNumberInput';

export type NumberInputProps = RHFInputProps &
  ComponentProps<typeof BaseNumberInput>;

function NumberInputComponent(
  props: NumberInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { name, validation, onChange, ...innerProps } = props;
  const { formState, setValue, control } = useFormContext();
  const { isSubmitted } = formState;

  const { error, label, isWarning } = useFormLogic({
    ...props,
    valueAsNumber: true,
  });

  const validationRules = useMemo(
    () => buildValidationRules(validation),
    [validation],
  );

  const { field } = useController({ name, control, rules: validationRules });

  const mergedRef = useMergedRef(ref, field.ref);

  const wrappedOnChange = useCallback(
    (value: number | '') => {
      setValue(name, value === '' ? null : value, {
        shouldValidate: isSubmitted,
        shouldDirty: true,
      });
      onChange?.(value);
    },
    [isSubmitted, name, onChange, setValue],
  );

  return (
    <BaseNumberInput
      classNames={{
        root: formStyles.root,
        error: isWarning ? formStyles.isWarning : undefined,
        input: isWarning ? formStyles.isWarning : undefined,
      }}
      {...innerProps}
      error={error}
      label={label}
      {...field}
      value={Number.isNaN(Number(field.value)) ? '' : Number(field.value)}
      onChange={wrappedOnChange}
      ref={mergedRef}
    />
  );
}

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  NumberInputComponent,
);
