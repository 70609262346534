import { ApolloLink, HttpLink, HttpOptions } from '@apollo/client';

/**
 * Apollo API.
 * Used in the Apollo context to determine which API to use.
 */
export enum APOLLO_API {
  BAS = 'business-activity-service',
  STATEN_ISLAND = 'staten-island',
}

/**
 * Apollo HTTP options for each API.
 */
export const httpOptions: Record<APOLLO_API, HttpOptions> = {
  [APOLLO_API.BAS]: {
    uri:
      process.env.APOLLO_ACTIVITY_URI ??
      process.env.NEXT_PUBLIC_APOLLO_ACTIVITY_URI,
  },
  [APOLLO_API.STATEN_ISLAND]: {
    uri: process.env.APOLLO_CORE_URI ?? process.env.NEXT_PUBLIC_APOLLO_CORE_URI,
  },
};

/**
 * Apollo HTTP link.
 * Splits the link based on the API context.
 */
export const httpLink = ApolloLink.split(
  (operation) => operation.getContext().api === APOLLO_API.BAS,
  new HttpLink(httpOptions[APOLLO_API.BAS]),
  new HttpLink(httpOptions[APOLLO_API.STATEN_ISLAND]),
);
