import { Text, Space, Button } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { ReactNode } from 'react';

export type AlertModalInnerProps = {
  message: ReactNode;
  okButtonChildren?: ReactNode;
};

export function AlertModal({
  context,
  id,
  innerProps: { message, okButtonChildren },
}: ContextModalProps<AlertModalInnerProps>) {
  return (
    <>
      {/* <Center p="sm" pt={0}> */}
      <Text>{message}</Text>
      <Space h="lg" />
      {/* </Center> */}
      <Button fullWidth onClick={() => context.closeModal(id)}>
        {okButtonChildren ?? 'OK'}
      </Button>
    </>
  );
}
