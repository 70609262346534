/**
 * Checks if a value is a 2D Bounding Box
 * @param bbox Bounding Box
 * @returns true if the value is a 2D Bounding Box
 */
export const is2DBoundingBox = (
  bbox: unknown,
): bbox is [number, number, number, number] =>
  Array.isArray(bbox) &&
  bbox.length === 4 &&
  bbox.every(
    (val, index) =>
      typeof val === 'number' &&
      !Number.isNaN(val) &&
      val >= [-180, -90][index % 2] &&
      val <= [180, 90][index % 2],
  );

export const is3DBoundingBox = (
  bbox: unknown,
): bbox is [number, number, number, number, number, number] =>
  Array.isArray(bbox) &&
  bbox.length === 6 &&
  bbox.every(
    (val, index) =>
      typeof val === 'number' &&
      !Number.isNaN(val) &&
      val >= [-180, -90, -Infinity][index % 3] &&
      val <= [180, 90, Infinity][index % 3],
  );

export const isBoundingBox = (bbox: unknown): bbox is GeoJSON.BBox =>
  is2DBoundingBox(bbox) || is3DBoundingBox(bbox);
