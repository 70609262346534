import { Box, BoxProps } from '@mantine/core';
import {
  NavigationProgress as MTNavigationProgress,
  nprogress,
} from '@mantine/nprogress';
import { Router, useRouter } from 'next/router';
import { useEffect } from 'react';

export function NavigationProgress(props: BoxProps) {
  const router = useRouter();

  useEffect(() => {
    const handleStart = (url: string) =>
      url !== router.asPath && nprogress.start();
    const handleComplete = nprogress.complete;

    Router.events.on('routeChangeStart', handleStart);
    Router.events.on('routeChangeComplete', handleComplete);
    Router.events.on('routeChangeError', handleComplete);
    return () => {
      Router.events.off('routeChangeStart', handleStart);
      Router.events.off('routeChangeComplete', handleComplete);
      Router.events.off('routeChangeError', handleComplete);
    };
  }, [router.asPath]);

  return (
    <Box {...props}>
      <MTNavigationProgress color="green.7" withinPortal={false} autoReset />
    </Box>
  );
}
