import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Job, PropertyInJob } from '../../../types/job';
import { User } from '../../../types/user';
import { Property } from '../../../types/property';
import { ParcelInProperty } from '../../../types/parcel';
import { mutations } from '../../../mutations';
import { queries } from '../../../queries';
import { Team } from '../../../types/team';

export type PostJobBody = {
  name?: Job['name'];
  userId: User['id'];
  teamId: Team['id'];
  properties?: (Partial<Omit<Property, 'parcels'>> &
    Partial<PropertyInJob> & {
      parcels?: (Partial<Omit<ParcelInProperty, 'parcelId'>> &
        Pick<ParcelInProperty, 'parcelId'>)[];
    })[];
};

export const useCreateJob = () => {
  const queryClient = useQueryClient();

  return useMutation<Job, unknown, PostJobBody>({
    ...mutations.jobs.create,

    onSettled: (report, error, { teamId }) => {
      queryClient.invalidateQueries(queries.jobs.all);
      queryClient.invalidateQueries(queries.teams.one(teamId)._ctx.jobs);
      queryClient.refetchQueries(queries.teams.one(teamId)._ctx.jobs);
    },
  });
};
