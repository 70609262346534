import { Team } from '@agtuary/api/types/team';
import { User } from '@agtuary/api/types/user';
import { LDContext } from '@launchdarkly/node-server-sdk';

/**
 * The minimum user information required to create a LaunchDarkly context.
 */
export type LDContextUser = Pick<
  User,
  'id' | 'firstName' | 'lastName' | 'email'
>;

/**
 * The minimum team information required to create a LaunchDarkly context.
 */
export type LDContextTeam = Pick<Team, 'id'> & {
  paymentSubscriptions: Pick<Team['paymentSubscriptions'][number], 'status'>[];
};

export const getLDContext = (user: LDContextUser | undefined): LDContext => {
  return {
    kind: 'multi',
    user: {
      key: user?.id,
      name: `${user?.firstName} ${user?.lastName}`,
      email: user?.email,
      _meta: {
        privateAttributes: ['email'],
      },
    },
  };
};
