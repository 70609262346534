import { Ref, useCallback } from 'react';
import { DefaultValues, FieldValues, FormProvider } from 'react-hook-form';
import { ExtendedHTMLFormElement, useForm } from './hooks';

type FormProps<T extends FieldValues> = {
  onSubmit: (data: T) => void;
  defaultValues: DefaultValues<T>;
  children: (
    renderProps: ReturnType<typeof useForm<T>> & {
      inputProps: (name: keyof T & string) => { name: keyof T };
    },
  ) => JSX.Element;
  formRef: Ref<ExtendedHTMLFormElement>;
  shouldUseNativeValidation?: boolean;
  protectUnsavedChanges?: boolean;
  disabled?: boolean;
  mode?: 'onBlur' | 'onChange' | 'onSubmit' | 'onTouched' | 'all' | undefined;
};

export function Form<T extends FieldValues>({
  onSubmit,
  defaultValues,
  children,
  formRef,
  shouldUseNativeValidation,
  disabled = false,
  protectUnsavedChanges = true,
  mode,
}: FormProps<T>) {
  const formMethods = useForm({
    onSubmit,
    defaultValues,
    shouldUseNativeValidation,
    protectUnsavedChanges,
    disabled,
    formRef,
    mode,
  });

  const inputProps = useCallback((name: keyof T & string) => ({ name }), []);

  const renderProps = {
    ...formMethods,
    inputProps,
  };

  const { Form: FormComponent } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <FormComponent>{children(renderProps)}</FormComponent>
    </FormProvider>
  );
}
