import { CircleCheckIcon } from '@agtuary/ui/icons';
import { Button, ButtonProps } from '@mantine/core';
import { EventHandler, MouseEvent } from 'react';

type ConfirmButtonProps = {
  onClick: EventHandler<MouseEvent>;
  text?: string;
} & ButtonProps;

export function ConfirmButton({ onClick, text, ...props }: ConfirmButtonProps) {
  return (
    <Button
      {...props}
      type="submit"
      rightIcon={<CircleCheckIcon width={10} height={10} />}
      onClick={onClick}
    >
      {text || 'Confirm'}
    </Button>
  );
}
