import { useCurrentUser } from 'hooks/useCurrentUser';
import { useFlags } from 'hooks/useFlags';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import Userflow from 'userflow.js';

export function UserflowOnboarding({ children }: PropsWithChildren) {
  const userflowToken = process.env.NEXT_PUBLIC_USER_FLOW_TOKEN;

  const { 'user-flow-onboarding': userFlowOnboarding } = useFlags();

  const userflowEnabled = useMemo(
    () => userFlowOnboarding && userflowToken,
    [userFlowOnboarding, userflowToken],
  );

  useEffect(() => {
    if (userflowEnabled) {
      Userflow.init(userflowToken);
    }
  }, [userflowEnabled, userflowToken]);

  const { user } = useCurrentUser();

  useEffect(() => {
    if (
      userflowEnabled &&
      user &&
      user?.data &&
      user.data.id &&
      user.data.email
    ) {
      Userflow.identify(user.data.id, {
        email: user.data.email,
        name: `${user.data.firstName} ${user.data.lastName}`,
      });
    }
  }, [user, user?.data, userflowEnabled]);

  return children;
}
