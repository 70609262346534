import { openContextModal } from '@mantine/modals';
import { useCallback } from 'react';
import { useTutorialStore } from 'stores/TutorialStore';

export const useMapIntroModal = () => {
  const setSeen = useTutorialStore((state) => state.setMapIntroSeen);

  return useCallback(
    () =>
      openContextModal({
        modal: 'mapIntro',
        withCloseButton: false,
        onClose: () => setSeen(true),
        size: 361,
        padding: 0,
        innerProps: {},
      }),
    [setSeen],
  );
};
