import { setContext } from '@apollo/client/link/context';
import { NEXT_REQUEST_TIMEOUT_HEADER } from '@agtuary/common/constants/headers';

/**
 * Client-side only.
 * Adds the timeout header to the Apollo request. (Max 30 seconds)
 * See apps/web/helpers/api/timeout.ts for more information.
 */
export const gatewayTimeoutLink = setContext((_operation, context) => {
  const timeout = parseInt(context.timeout, 10);

  if (!Number.isNaN(timeout)) {
    return {
      ...context,
      headers: {
        ...context.headers,
        [NEXT_REQUEST_TIMEOUT_HEADER]: timeout,
      },
    };
  }

  return context;
});
