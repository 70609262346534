import {
  entriesFromObject,
  objectFromEntries,
} from '@agtuary/common/helpers/typed/object';
import {
  ComponentProps,
  DetailedHTMLProps,
  FormEventHandler,
  FormHTMLAttributes,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import {
  DefaultValues,
  FieldValues,
  UseFormProps,
  UseFormReturn,
  useForm as rhfUseForm,
} from 'react-hook-form';
import { ObjectEntries } from 'type-fest/source/entries';
import { ObjectEntry } from 'type-fest/source/entry';
import { useUnsavedChanges } from '../../hooks/useUnsavedChanges';

const entryHasStringKey = (
  entry: [unknown, unknown],
): entry is ObjectEntry<string> => typeof entry[0] === 'string';

type FormApi = {
  ref: Ref<HTMLFormElement>;
  onSubmit: FormEventHandler<HTMLFormElement>;
  onReset: () => void;
};

type FormType = (
  formProps: DetailedHTMLProps<
    FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  >,
) => ReactNode;

type InternalFormType = FormType & { api: FormApi };

export type ExtendedHTMLFormElement = {
  clear: () => void;
  requestSubmit: () => void;
  reset: () => void;
};

export function useForm<
  TFieldValues extends FieldValues = FieldValues,
  TContext = unknown,
>(
  props: Omit<UseFormProps<TFieldValues, TContext>, 'defaultValues'> & {
    defaultValues: DefaultValues<TFieldValues>;
    protectUnsavedChanges?: boolean;
    onSubmit: (data: TFieldValues) => void;
    formRef: Ref<ExtendedHTMLFormElement>;
  },
): UseFormReturn<TFieldValues, TContext> & {
  onReset: () => void;
  onSubmit: FormEventHandler<HTMLFormElement>;
  realFormRef: Ref<HTMLFormElement>;
  Form: FormType;
} {
  const formMethods = rhfUseForm<TFieldValues, TContext>(props);

  const {
    reset,
    trigger,
    formState: { isDirty },
  } = formMethods;

  useUnsavedChanges(Boolean(props.protectUnsavedChanges) && isDirty);

  /* If the defaultValues change for a form, we want to allow the form to 'reset' so the values can be displayed to the user
   * There are a few ways that this can occur:
   * - the 'vanilla' scenario is that the form is rendered, then the form's data then arrives when the query resolves. This means the form is going from blank to having data
   * - the form is fully populated, then something happens to fetch new data. This is an edge case but will happen in more complex forms, maybe with multiple screens
   *
   * In both cases we want to retain dirty values, and the overall dirty state of the form
   * Calling trigger is for forms who want to revalidate on change, which is more aggressive than the default 'onSubmit'
   */
  useEffect(() => {
    reset(props.defaultValues, {
      keepDirtyValues: true,
      keepDirty: true,
      keepErrors: true,
    });
    if (props.reValidateMode === 'onChange') {
      trigger();
    }
  }, [props.defaultValues, props.reValidateMode, reset, trigger]);

  const onReset = useCallback(() => {
    reset();
  }, [reset]);

  // https://github.com/orgs/react-hook-form/discussions/3951#discussioncomment-294279
  const wrappedOnSubmit: FormEventHandler<HTMLFormElement> = useMemo(() => {
    return formMethods.handleSubmit((data: TFieldValues) => {
      reset(data);
      props.onSubmit(data);
    });
  }, [formMethods, props, reset]);

  const { defaultValues, formRef } = props;
  const clear = useCallback(() => {
    formMethods.reset((v: TFieldValues) => {
      // If the key is in the defaultValues, reset to that value, otherwise reset to null
      const objectEntries: ObjectEntries<TFieldValues> = entriesFromObject(v);
      const stringKeyedEntries = objectEntries.filter(entryHasStringKey);
      const clearedEntries: ObjectEntries<TFieldValues> =
        // @ts-expect-error work around limit in react hook form types
        stringKeyedEntries.map(([k, _]) => [k, defaultValues?.[k] ?? null]);
      const result: TFieldValues = objectFromEntries(clearedEntries);
      return result;
    });
  }, [formMethods, defaultValues]);

  const realFormRef = useRef<HTMLFormElement>(null);

  /**
   * A plain old HTMLFormElement ref exposes some of the methods we need across all the form-related components, but we need to extend
   * it with some custom methods as well.
   */
  useImperativeHandle(
    formRef,
    (): ExtendedHTMLFormElement => ({
      requestSubmit: () => realFormRef.current?.requestSubmit(),
      reset: () => realFormRef.current?.reset(),
      clear,
    }),
  );

  /**
   * This component is constructed inline but memoized so that the react runtime treats it as a stable entity.
   * The values generated by this hook are tacked on the side of the component as a property so that they can be accessed
   * directly. This has the upside that everything can be rolled into this one hook, with no need to create several layers
   * or use a context. The downside is the hacky way of accessing the 'api' values within the inline component.
   */
  // @ts-expect-error allow access to local values in inline form component
  const Form: InternalFormType = useMemo(() => {
    function AgtuaryForm(formProps: ComponentProps<'form'>) {
      return <form {...Form.api} {...formProps} />;
    }

    return AgtuaryForm;
  }, []);
  Form.api = {
    ref: realFormRef,
    onSubmit: wrappedOnSubmit,
    onReset,
  };

  const result = {
    ...formMethods,
    onReset,
    onSubmit: wrappedOnSubmit,
    realFormRef,
    Form,
  };

  return result;
}
