import {
  Slider as MTSlider,
  SliderProps as MTSliderProps,
} from '@mantine/core';
import { ForwardedRef, forwardRef } from 'react';

type BaseSliderInputProps = MTSliderProps;

function BaseSliderInputComponent(
  props: BaseSliderInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  return <MTSlider {...props} ref={ref} />;
}

export const BaseSliderInput = forwardRef<
  HTMLInputElement,
  BaseSliderInputProps
>(BaseSliderInputComponent);
