/* eslint-disable no-console -- Only runs server-side */
import { onError } from '@apollo/client/link/error';
import { isServerSide } from '@agtuary/common/helpers/environment';

/**
 * Server-side only.
 * Logs any GraphQL errors or network errors.
 */
export const logErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (isServerSide) {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${JSON.stringify(
            message,
          )}, Location: ${JSON.stringify(locations)}, Path: ${JSON.stringify(
            path,
          )}`,
        ),
      );
    if (networkError)
      console.error(`[Network error]: ${JSON.stringify(networkError)}`);
  }
});
