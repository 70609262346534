import { Text } from '@mantine/core';
import { ReactNode, useMemo } from 'react';
import { get, useFormContext } from 'react-hook-form';
import {
  RHFInputProps,
  RHFValidationOptions,
  isRuleWithMessage,
} from './types';
import { buildValidationRules } from './validation';

type RHFFormLogic = {
  label: ReactNode;
  error?: string;
  isWarning: boolean;
  validationRules: Partial<RHFValidationOptions>;
};

type UseFormLogicProps = Omit<RHFInputProps, 'type'> & {
  valueAsNumber?: boolean;
};

export const useFormLogic = ({
  name,
  validation,
  optionalInLabel,
  label,
  warning,
}: UseFormLogicProps): RHFFormLogic => {
  const { formState } = useFormContext();
  const { errors } = formState;
  const errorItem = get(errors, name);
  const error = useMemo(() => errorItem?.message?.toString(), [errorItem]);

  const validationRules = useMemo(
    () => buildValidationRules(validation),
    [validation],
  );

  const { required } = validationRules;
  const enhancedLabel =
    (isRuleWithMessage(required) ? required.value : required) ||
    optionalInLabel === false ? (
      label
    ) : (
      <>
        {label}
        &nbsp;&nbsp;
        <Text span c="dimmed">
          (optional)
        </Text>
      </>
    );

  const errorOrWarning = error ?? warning;
  const isWarning = error === undefined && warning !== undefined;
  return {
    label: enhancedLabel,
    error: errorOrWarning,
    isWarning,
    validationRules,
  };
};
