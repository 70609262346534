import { createMutationKeys } from '@lukemorales/query-key-factory';
import {
  deleteMutator,
  patchMutator,
  postMutator,
} from '../helpers/clientSide/requests';
import { ComparableSale } from '../types/comparableSale';
import { Job, PropertyInJob } from '../types/job';
import { Parcel } from '../types/parcel';

export const jobs = createMutationKeys('jobs', {
  one: (jobId: string) => ({
    mutationKey: [jobId],
    mutationFn: (arg: Partial<Job>) =>
      patchMutator(`/api/jobs/${jobId}`, { arg }),
    contextMutations: {
      properties: {
        mutationKey: null,
        contextMutations: {
          create: {
            mutationKey: null,
            mutationFn: (arg: Partial<PropertyInJob>) => {
              return postMutator(`/api/jobs/${jobId}/properties`, {
                arg,
              });
            },
          },
          one: (propertyId: string) => ({
            mutationKey: [propertyId],
            mutationFn: (arg: { name?: string }) =>
              patchMutator(`/api/jobs/${jobId}/properties/${propertyId}`, {
                arg,
              }),
            contextMutations: {
              delete: {
                mutationKey: null,
                mutationFn: (arg: object) =>
                  deleteMutator(`/api/jobs/${jobId}/properties/${propertyId}`, {
                    arg,
                  }),
              },
              parcels: {
                mutationKey: null,
                contextMutations: {
                  one: (parcelId: string) => ({
                    mutationKey: [parcelId],
                    mutationFn: (arg: {
                      updates: {
                        name?: string;
                      };
                      after?: Promise<unknown>;
                    }) =>
                      // Sometimes we need to wait for other mutations (e.g. create the parcel)
                      (arg.after ?? Promise.resolve()).then(() =>
                        patchMutator(
                          `/api/jobs/${jobId}/properties/${propertyId}/parcels/${parcelId}`,
                          {
                            arg: {
                              ...arg.updates,
                            },
                          },
                        ),
                      ),
                  }),
                },
              },
              propertyNotes: {
                mutationKey: null,
                contextMutations: {
                  create: {
                    mutationKey: null,
                    mutationFn: (arg: { title: string; text: string }) =>
                      postMutator(
                        `/api/jobs/${jobId}/properties/${propertyId}/property-notes`,
                        {
                          arg,
                        },
                      ),
                  },
                  one: (propertyNoteId: string) => ({
                    mutationKey: [propertyNoteId],
                    mutationFn: (arg: { title?: string; text?: string }) =>
                      patchMutator(
                        `/api/jobs/${jobId}/properties/${propertyId}/property-notes/${propertyNoteId}`,
                        {
                          arg,
                        },
                      ),
                    contextMutations: {
                      delete: {
                        mutationKey: null,
                        mutationFn: (arg: object) =>
                          deleteMutator(
                            `/api/jobs/${jobId}/properties/${propertyId}/property-notes/${propertyNoteId}`,
                            {
                              arg,
                            },
                          ),
                      },
                    },
                  }),
                },
              },
              comparableSales: {
                mutationKey: null,
                contextMutations: {
                  create: {
                    mutationKey: null,
                    mutationFn: (arg: Partial<ComparableSale>) =>
                      postMutator<Partial<ComparableSale>>(
                        `/api/jobs/${jobId}/properties/${propertyId}/comparable-sales`,
                        {
                          arg,
                        },
                      ),
                  },
                  one: (saleId: string) => ({
                    mutationKey: [saleId],
                    mutationFn: (arg: Partial<ComparableSale>) =>
                      patchMutator<Partial<ComparableSale>>(
                        `/api/jobs/${jobId}/properties/${propertyId}/comparable-sales/${saleId}`,
                        {
                          arg,
                        },
                      ),
                    contextMutations: {
                      delete: {
                        mutationKey: null,
                        mutationFn: (arg: object) =>
                          deleteMutator<object>(
                            `/api/jobs/${jobId}/properties/${propertyId}/comparable-sales/${saleId}`,
                            {
                              arg,
                            },
                          ),
                      },
                    },
                  }),
                },
              },
              files: {
                mutationKey: null,
                contextMutations: {
                  create: {
                    mutationKey: null,
                    mutationFn: (arg: object) =>
                      postMutator<object>(
                        `/api/jobs/${jobId}/properties/${propertyId}/files`,
                        {
                          arg,
                        },
                      ),
                  },
                  one: (fileId: string) => ({
                    mutationKey: [fileId],
                    mutationFn: (arg: object) =>
                      patchMutator<object>(
                        `/api/jobs/${jobId}/properties/${propertyId}/files/${fileId}`,
                        {
                          arg,
                        },
                      ),
                    contextMutations: {
                      delete: {
                        mutationKey: null,
                        mutationFn: (arg: object) =>
                          deleteMutator<object>(
                            `/api/jobs/${jobId}/properties/${propertyId}/files/${fileId}`,
                            {
                              arg,
                            },
                          ),
                      },
                    },
                  }),
                },
              },
            },
          }),
          createParcel: {
            mutationKey: null,
            mutationFn: (arg: {
              propertyId: string;
              parcel: Partial<Omit<Parcel, 'id'>> & Pick<Parcel, 'id'>;
              parcelInPropertyData: {
                name: string;
              };
              after?: Promise<unknown>;
            }) =>
              // Sometimes we need to wait for other mutations (e.g. create the property)
              (arg.after ?? Promise.resolve()).then(() =>
                postMutator(
                  `/api/jobs/${jobId}/properties/${arg.propertyId}/parcels`,
                  {
                    arg: {
                      parcelId: arg.parcel.id,
                      ...arg.parcelInPropertyData,
                    },
                  },
                ),
              ),
          },
          deleteParcel: {
            mutationKey: null,
            mutationFn: (arg: {
              propertyId: string;
              parcelId: string;
              after?: Promise<unknown>;
            }) =>
              // Sometimes we need to wait for other mutations (e.g. create the property)
              (arg.after ?? Promise.resolve()).then(() =>
                deleteMutator(
                  `/api/jobs/${jobId}/properties/${arg.propertyId}/parcels/${arg.parcelId}`,
                  {
                    arg: {},
                  },
                ),
              ),
          },
        },
      },
      delete: {
        mutationKey: null,
        mutationFn: (arg: object) =>
          deleteMutator(`/api/jobs/${jobId}`, {
            arg,
          }),
      },
    },
  }),
  create: {
    mutationKey: null,
    mutationFn: (arg: Partial<Job>) =>
      postMutator('/api/jobs', {
        arg,
      }),
  },
});
