import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queries } from '../../../queries';
import { mutations } from '../../../mutations';
import { Organisation } from '../../../types/organisation';

export const useUpdateOrganisation = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation<
    Organisation,
    unknown,
    Partial<Organisation>,
    { previous: Organisation | undefined }
  >({
    ...mutations.organisations.one(id),

    onMutate: async (newData: Partial<Organisation>) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queries.organisations.one(id));

      // Snapshot the previous value
      const previous = queryClient.getQueryData<Organisation>(
        queries.organisations.one(id).queryKey,
      );

      // Optimistically update to the new value
      queryClient.setQueryData(queries.organisations.one(id).queryKey, {
        ...previous,
        ...newData,
      });

      // Return a context object with the snapshotted value
      return { previous };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newData, context) => {
      queryClient.setQueryData(
        queries.organisations.one(id).queryKey,
        context?.previous,
      );
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(queries.organisations.one(id));
      queryClient.invalidateQueries(queries.users.one._def);
      queryClient.invalidateQueries(queries.teams._def);
    },
  });
};
