import { ModalsProvider as BaseModalsProvider } from '@mantine/modals';
import { ReportViewDrawerProvider } from 'context/reportViewDrawerContext';
import { PropsWithChildren } from 'react';
import { AlertModal } from '../AlertModal';
import { CalculateLiveweightModal } from '../CalculateLiveweightModal/CalculateLiveweightModal';
import { ConfirmDeleteModal, confirmDelete } from '../ConfirmDeleteModal';
import { DeleteInformationModal } from '../DeleteInformationModal';
import { DeleteProfileModal } from '../DeleteProfileModal';
import { EditTeamMemberModal, editTeamMember } from '../EditTeamMemberModal';
import { ImageCarouselModal } from '../ImageCarouselModal';
import { JobReportListModal } from '../JobReportListModal';
import { MapIntroModal } from '../MapIntroModal';
import { NewJobModal } from '../NewJobModal';
import { OnboardModal } from '../OnboardModal';
import { PaymentModal } from '../PaymentModal';
import { PaymentNudgeModal } from '../PaymentNudgeModal';
import {
  SelectMapShapeFromLibraryModal,
  selectMapShapeFromLibraryModal,
} from '../SelectMapShapeFromLibraryModal';
import { ShareModal } from '../ShareModal';
import { TeamInviteModal } from '../TeamInviteModal';
import { UserCheckModals } from './UserCheckModals';

const modals = {
  alert: AlertModal,
  calculateLiveweight: CalculateLiveweightModal,
  deleteInformation: DeleteInformationModal,
  deleteProfile: DeleteProfileModal,
  payment: PaymentModal,
  teamInvite: TeamInviteModal,
  paymentNudge: PaymentNudgeModal,
  newJob: NewJobModal,
  share: ShareModal,
  onboard: OnboardModal,
  mapIntro: MapIntroModal,
  jobReportList: JobReportListModal,
  imageCarousel: ImageCarouselModal,
  [selectMapShapeFromLibraryModal]: SelectMapShapeFromLibraryModal,
  [editTeamMember]: EditTeamMemberModal,
  [confirmDelete]: ConfirmDeleteModal,
};

export function ModalsProvider({ children }: PropsWithChildren) {
  return (
    <BaseModalsProvider modals={modals}>
      <ReportViewDrawerProvider>
        <UserCheckModals>{children}</UserCheckModals>
      </ReportViewDrawerProvider>
    </BaseModalsProvider>
  );
}
