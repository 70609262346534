import {
  Box,
  Center,
  ChevronIcon,
  Loader,
  Table as MTable,
  TableProps as MTableProps,
  Text,
} from '@mantine/core';
import {
  RowData,
  TableOptions,
  flexRender,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo } from 'react';
import styles from './Table.module.scss';

type TableProps<T extends RowData> = {
  tableOptions: TableOptions<T>;
  tableProps: MTableProps;
  onRowClick?: (row: T) => void;
  shouldDisableRow?: (row: T) => boolean;
  noDataMessage?: string;
  isLoading?: boolean;
  error?: string;
  renderHeader?: boolean;
};

export function Table<T>({
  tableOptions,
  tableProps,
  noDataMessage,
  onRowClick,
  shouldDisableRow,
  isLoading,
  error,
  renderHeader = true,
}: TableProps<T>) {
  const reactTable = useReactTable<T>(tableOptions);
  const { data } = tableOptions;

  const state: 'loading' | 'error' | 'empty' | 'success' = useMemo(() => {
    const isEmpty = !data?.length;
    if (isLoading && isEmpty) return 'loading';
    if (error) return 'error';
    if (isEmpty) return 'empty';
    return 'success';
  }, [data?.length, isLoading, error]);

  const rows = useMemo(
    () =>
      state === 'success' && data ? reactTable.getRowModel()?.rows : undefined,
    [data, reactTable, state],
  );

  return (
    <MTable {...tableProps}>
      {renderHeader && (
        <thead>
          {reactTable.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  style={{
                    width:
                      header.getSize() !==
                      (reactTable.options?.defaultColumn?.size ?? 150)
                        ? header.getSize()
                        : undefined,
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <Box
                      onClick={header.column.getToggleSortingHandler()}
                      {...(header.column.getCanSort()
                        ? { className: styles.sortableTableHeader }
                        : {})}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {header.isPlaceholder}
                      <ChevronIcon
                        style={{
                          opacity: header.column.getIsSorted() ? 1 : 0,
                          transition: 'transform 0.1s ease',
                          transform:
                            header.column.getIsSorted() === 'asc'
                              ? 'rotate(180deg)'
                              : 'rotate(0deg)',
                        }}
                      />
                    </Box>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
      )}
      <tbody>
        {state === 'loading' && (
          <tr className={styles.disabledTableRow}>
            <td
              colSpan={reactTable.getHeaderGroups()[0].headers.length}
              aria-label="Loading"
            >
              <Center>
                <Loader />
              </Center>
            </td>
          </tr>
        )}

        {state === 'error' && (
          <tr className={styles.disabledTableRow}>
            <td colSpan={reactTable.getHeaderGroups()[0].headers.length}>
              <Center>
                <Text color="red">{error}</Text>
              </Center>
            </td>
          </tr>
        )}

        {state === 'empty' && (
          <tr className={styles.disabledTableRow}>
            <td colSpan={reactTable.getHeaderGroups()[0].headers.length}>
              <Center>{noDataMessage ?? 'No data'}</Center>
            </td>
          </tr>
        )}

        {state === 'success' &&
          rows?.map((row) => {
            const isDisabled = shouldDisableRow?.(row.original) ?? false;
            return (
              <tr
                key={row.id}
                className={
                  isDisabled ? styles.disabledTableRow : styles.tableRow
                }
                onClick={() => !isDisabled && onRowClick?.(row.original)}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    style={{
                      width:
                        cell.column.getSize() !==
                        (reactTable.options?.defaultColumn?.size ?? 150)
                          ? cell.column.getSize()
                          : undefined,
                      minWidth: cell.column.getSize() ?? 0,
                      maxWidth: cell.column.getSize() ?? undefined,
                      margin: 0,
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
      </tbody>
    </MTable>
  );
}
