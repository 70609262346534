import { MantineTheme, MantineThemeOverride, TableProps } from '@mantine/core';

export const Table: MantineThemeOverride['components'] = {
  Table: {
    defaultProps: (theme: MantineTheme): Partial<TableProps> => ({
      verticalSpacing: 'md',
      fontSize: 'md',
      fw: theme.other.fonts.weights.medium,
    }),
    styles: (theme: MantineTheme) => ({
      root: {
        '& > thead': {
          position: 'sticky',
          top: 0,
          backgroundColor: theme.white,
          zIndex: 1,
          paddingTop: theme.spacing.sm,
          boxShadow: `0 1px 0 0 ${theme.other.colors.border}`,
        },
      },
    }),
  },
};
