import { MantineThemeOverride } from '@mantine/core';

export const breakpoints: MantineThemeOverride = {
  // ! Ensure these match packages/ui/styles/mixins/breakpoints.scss
  // We should look at postCSS when migrating to Mantine v7
  // https://mantine.dev/styles/responsive/#breakpoints-variables-in-css-modules
  breakpoints: {
    xs: '36em',
    sm: '48em',
    md: '62em',
    lg: '75em',
    xl: '88em',
    xxl: '110em',
    xxxl: '150em',
  },
};
