import { Job } from '@agtuary/api/types/job';
import { Title } from '@mantine/core';
import { openContextModal } from '@mantine/modals';
import { ReportRow } from './types';

type useJobReportListModalParams = {
  job: Job;
  title?: string;
  onRowClick?: (
    row: ReportRow,
    urls: { interactiveUrl: string; pdfUrl: string },
  ) => void;
};
export const useJobReportListModal =
  () =>
  ({ job, title, onRowClick }: useJobReportListModalParams) => {
    openContextModal({
      modal: 'jobReportList',
      title: <Title order={4}>{title ?? 'Reports'}</Title>,
      innerProps: {
        job,
        onRowClick,
      },
    });
  };
