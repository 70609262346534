import { LoadingOverlay, LoadingOverlayProps } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useTimeout } from '@mantine/hooks';

type DelayedLoadingOverlayProps = {
  delay?: number;
} & LoadingOverlayProps;

export function DelayedLoadingOverlay({
  visible,
  delay,
  children,
  ...rest
}: DelayedLoadingOverlayProps) {
  const [delayedVisible, setDelayedVisible] = useState(false);
  const { start, clear } = useTimeout(
    () => setDelayedVisible(true),
    delay ?? 0,
  );

  useEffect(() => {
    if (visible && !delayedVisible) {
      start();
    } else {
      setDelayedVisible(visible);
      clear();
    }
  }, [clear, delayedVisible, start, visible]);

  return (
    <>
      <LoadingOverlay visible={delayedVisible} {...rest} />
      {delayedVisible === visible && children}
    </>
  );
}
