import { createContext } from 'react';
import type { RootLayoutProps } from '../RootLayout';

export type RootLayoutContextType = {
  headerContentBoxId: string | undefined;
  navAfterItemsSectionId: string | undefined;
  layoutProps: RootLayoutProps | undefined;
  initialLayoutProps: RootLayoutProps | undefined;
  setLayoutProps: (props: Partial<RootLayoutProps>) => void;
  resetLayoutProps: () => void;
};

export const defaultRootLayoutContext: RootLayoutContextType = {
  headerContentBoxId: undefined,
  navAfterItemsSectionId: undefined,
  layoutProps: undefined,
  initialLayoutProps: undefined,
  setLayoutProps: () => undefined,
  resetLayoutProps: () => undefined,
};

export const RootLayoutContext = createContext(defaultRootLayoutContext);
