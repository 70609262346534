import { hasValue } from '@agtuary/common/helpers/nil';
import Decimal from 'decimal.js-light';
import {
  Validate,
  ValidationRule,
  ValidationValue,
  ValidationValueMessage,
} from 'react-hook-form';
import {
  RHFValidationOptions,
  ValidationOptions,
  isRuleWithMessage,
} from './types';

const ruleWithMessage = <T extends ValidationValue>(
  rule: ValidationRule<T>,
  messageBuilder: (val: T) => string,
): ValidationValueMessage<T> =>
  isRuleWithMessage(rule)
    ? rule
    : {
        value: rule,
        message: messageBuilder(rule),
      };

const gt0Validator = (value: unknown) => {
  if (value instanceof Decimal) {
    return value.gt(0) ? true : 'Value must be greater than 0';
  }
  if (typeof value !== 'number') {
    return true;
  }

  if (value <= 0) {
    return 'Value must be greater than 0';
  }
  return true;
};

const combineValidators = <TFieldValue, TFormValues>(
  validators: Validate<TFieldValue, TFormValues>[],
) => {
  return (value: TFieldValue, formValues: TFormValues) => {
    return validators.reduce((result, validator) => {
      if (result !== true) {
        return result;
      }
      return validator(value, formValues);
    }, true);
  };
};

export const buildValidationRules = (
  validation?: ValidationOptions,
): Partial<RHFValidationOptions> => {
  if (!validation) return {};

  const min =
    validation.min !== undefined
      ? ruleWithMessage(
          validation.min,
          (val) => `Value must be at least ${val}`,
        )
      : undefined;
  const max =
    validation.max !== undefined
      ? ruleWithMessage(
          validation.max,
          (val) => `Value must be less than or equal to ${val}`,
        )
      : undefined;
  const required = validation.required
    ? ruleWithMessage(validation.required, () => 'Value is required')
    : undefined;

  const validators: Validate<unknown, unknown>[] = [
    validation.gt0 ? gt0Validator : undefined,
    validation.validate,
  ].filter(hasValue);

  const validate =
    validators.length > 0 ? combineValidators(validators) : undefined;

  return { min, max, required, validate };
};
