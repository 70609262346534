import { BillingFrequency } from '@agtuary/api/constants/paymentSubscription';

export const billingFrequencyToString = (
  billingFrequency: BillingFrequency,
) => {
  switch (billingFrequency) {
    case BillingFrequency.DAY:
      return 'daily';
    case BillingFrequency.WEEK:
      return 'weekly';
    case BillingFrequency.MONTH:
      return 'monthly';
    case BillingFrequency.YEAR:
      return 'yearly';

    default:
      return 'unknown frequency';
  }
};
