import { PopOutIcon } from '@agtuary/ui/icons';
import {
  Title,
  Text,
  Space,
  Center,
  Flex,
  ThemeIcon,
  Button,
  Anchor,
} from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { useCurrentTeamSubscription } from 'hooks/useCurrentTeamSubscription';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useMemo } from 'react';

const popOutIcon = <PopOutIcon width={10} height={10} />;

function PaymentSuccess() {
  return (
    <Center>
      <Flex direction="column" align="center" gap="sm">
        <Space h="lg" />
        <ThemeIcon radius="xl" size={60}>
          {/* TODO: use own icon */}
          <IconCheck width={50} height={50} />
        </ThemeIcon>
        <Title order={5}>Subscription active!</Title>
        <Text size="sm">
          You can now access all the features of the Agtuary platform.
        </Text>
        <Space h="xl" />
      </Flex>
    </Center>
  );
}

function Canceled() {
  return (
    <Center>
      <Flex direction="column" align="center" gap="sm">
        <Space h="lg" />
        <Text size="sm">Your subscription is currently canceled.</Text>
        <Button
          variant="outline"
          rightIcon={popOutIcon}
          onClick={() => {
            window.open(
              process.env.NEXT_PUBLIC_STRIPE_BILLING_MANAGE_URL,
              '_blank',
            );
          }}
        >
          Manage Subscription
        </Button>
        <Space h="xl" />
      </Flex>
    </Center>
  );
}

const noSubscription = (currentTeamId: string, userEmail: string) => (
  <>
    <Title order={5}>
      Select a plan to start making the most of the Agtuary platform!
    </Title>
    <Text>
      Our report pricing is {process.env.NEXT_PUBLIC_NORMAL_REPORT_PRICE} while
      on a free subscription, or {process.env.NEXT_PUBLIC_REDUCED_REPORT_PRICE}{' '}
      while on a paid team subscription.
    </Text>
    <Space h="xl" />
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore - stripe-pricing-table is not typed */}
    <stripe-pricing-table
      pricing-table-id={process.env.NEXT_PUBLIC_STRIPE_PRICING_TABLE_ID}
      publishable-key={process.env.NEXT_PUBLIC_STRIPE_PRICING_PUBLISHABLE_ID}
      client-reference-id={currentTeamId}
      customer-email={userEmail}
    />
    <Space h="md" />
  </>
);

function noTeamId() {
  return (
    <Text>
      There has been an error loading subscription plans. Please refresh,
      re-login or contact us at{' '}
      <Anchor variant="text" href="mailto:contact@agtuary.com" color="brand.7">
        contact@agtuary.com
      </Anchor>
    </Text>
  );
}

export function PaymentModal() {
  const { currentTeam, user } = useCurrentUser();
  const {
    isSubscribed: isCurrentTeamSubscribed,
    isCanceled: isCurrentTeamCanceled,
  } = useCurrentTeamSubscription();

  const ModalContent = useMemo(() => {
    // should never appear
    if (isCurrentTeamSubscribed) {
      return PaymentSuccess();
    }

    if (isCurrentTeamCanceled) {
      return Canceled();
    }

    if (!currentTeam || !currentTeam?.id) {
      return noTeamId();
    }

    return noSubscription(currentTeam?.id, user?.data.email);
  }, [
    currentTeam,
    isCurrentTeamSubscribed,
    isCurrentTeamCanceled,
    user?.data.email,
  ]);

  return ModalContent;
}
