import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useHydratedStore } from './helpers/useHydratedStore';

type TeamInvitesStoreState = {
  invites: string[];
};

type TeamInvitesStore = TeamInvitesStoreState & {
  addInvite: (invite: string) => void;
  removeInvite: (invite: string) => void;
};

const initialState: TeamInvitesStoreState = {
  invites: [],
};

// Appropriate values before hydration.
// e.g. if we dont want a modal to show if the user has already seen it,
// we use true here but false in initialState.
const dehydratedState = initialState;

export const useTeamInvitesStore = create(
  persist<TeamInvitesStore>(
    (set, get) => ({
      ...initialState,
      addInvite: (invite: string) =>
        set({ invites: [...get().invites, invite] }),
      removeInvite: (invite: string) =>
        set({ invites: get().invites.filter((i) => i !== invite) }),
    }),
    {
      name: 'team-invites',
    },
  ),
);

// SSR safe store (state only, no actions)
export const useHydratedTeamInvitesStore = (
  selector: (
    state: TeamInvitesStoreState,
  ) => TeamInvitesStoreState[keyof TeamInvitesStoreState],
) => useHydratedStore(useTeamInvitesStore, dehydratedState, selector);

// Helper functions to access outside of React components
export const resetTeamInvitesStore = () => {
  useTeamInvitesStore.persist.clearStorage();
};
