import {
  Input,
  Switch as MTSwitch,
  SwitchProps as MTSwitchProps,
} from '@mantine/core';
import { useMergedRef } from '@mantine/hooks';
import { ForwardedRef, forwardRef, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { DEFAULT_INPUT_WRAPPER_ORDER } from '../constants';
import { RHFInputProps } from '../types';
import { buildValidationRules } from '../validation';

export type SwitchProps = RHFInputProps & MTSwitchProps;

function SwitchComponent(
  props: SwitchProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { name, validation, onLabel, offLabel } = props;
  const { control } = useFormContext();

  const validationRules = useMemo(
    () => buildValidationRules(validation),
    [validation],
  );

  const { field } = useController({ name, control, rules: validationRules });

  const mergedRef = useMergedRef(ref, field.ref);

  return (
    <Input.Wrapper inputWrapperOrder={DEFAULT_INPUT_WRAPPER_ORDER}>
      <Input
        unstyled
        component={MTSwitch}
        {...props}
        checked={field.value}
        onLabel={onLabel ?? 'ON'}
        offLabel={offLabel ?? 'OFF'}
        name={field.name}
        onChange={field.onChange}
        ref={mergedRef}
      />
    </Input.Wrapper>
  );
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  SwitchComponent,
);
