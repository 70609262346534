import { useCreateInvitation } from '@agtuary/api/hooks/invitations/mutations/useCreateInvitation';
import { Organisation } from '@agtuary/api/types/organisation';
import { RoleName } from '@agtuary/api/types/role';
import { Team } from '@agtuary/api/types/team';
import {
  Badge,
  Button,
  Flex,
  Group,
  Radio,
  Space,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useFlags } from 'hooks/useFlags';
// eslint-disable-next-line no-restricted-imports
import { useForm } from '@mantine/form';
import { ContextModalProps } from '@mantine/modals';
import { billingFrequencyToString } from 'helpers/billingFrequency';
import { showError } from 'helpers/showError';
import { showSuccess } from 'helpers/showSuccess';
import { validateEmail } from 'helpers/validation/validation';
import { useTeamSubscription } from 'hooks/useTeamSubscription';
import { useAnalyticsEvents } from 'hooks/useAnalyticsEvents';
import { roleDescriptionMap, userRoleOptions } from '../EditTeamMemberModal';
import styles from './styles.module.scss';

type TeamInviteModalInnerProps = {
  team: Team;
  organisation: Organisation;
  closeOnSuccess?: boolean;
};

type DefaultValues = {
  name: string;
  email: string;
  roleName: RoleName | null;
};

export function TeamInviteModal({
  context,
  id,
  innerProps: { team, organisation, closeOnSuccess },
}: ContextModalProps<TeamInviteModalInnerProps>) {
  const { isLoading, mutateAsync: trigger } = useCreateInvitation();
  const { billFrequency, seatPrice, isExternalSubscription } =
    useTeamSubscription(team);
  const teamName = `${organisation.name} / ${team.name}`;
  const theme = useMantineTheme();

  const { sendEvent } = useAnalyticsEvents();

  const initialValues: DefaultValues = {
    name: '',
    email: '',
    roleName: null,
  };

  const { 'property-reports': propertyReportsEnabled } = useFlags();

  const form = useForm({
    initialValues,
    validate: {
      email: (value) =>
        validateEmail(value, 'Please enter a valid email address.'),
      roleName: (value) =>
        value === null ? 'Please select a user role.' : null,
    },
    validateInputOnBlur: true,
  });

  const submit = form.onSubmit(({ name, email, roleName }) => {
    if (form.isDirty() && form.isTouched() && form.isValid()) {
      trigger({
        invitationEmail: email,
        invitationName: name,
        teamId: team.id,
        organisationId: team.organisationId,
        roleName,
      })
        .then((data) => {
          if (data?.status === 'active') {
            form.resetDirty();
            form.resetTouched();
            form.setValues(initialValues);
            if (closeOnSuccess) context.closeModal(id);
            showSuccess({
              title: 'Invitation Sent',
              message: `${name} has been invited to join ${teamName}`,
            });
            sendEvent('team-user-invited');
          }
        })
        .catch(() => {
          showError('An error occurred while inviting the user.');
        });
    }
  });

  return (
    <>
      <Text component="p">Invite a new member to join {teamName}.</Text>

      {propertyReportsEnabled && (
        <>
          {isExternalSubscription || (
            <>
              <Space h="md" />
              <Group spacing="xs">
                <Badge color="red">Warning!</Badge>
                <Text
                  size="sm"
                  color="red"
                  fw={theme.other.fonts.weights.medium}
                >
                  {seatPrice && billFrequency ? (
                    <>
                      Each new user will increase your{' '}
                      {billingFrequencyToString(billFrequency)} subscription
                      charge by{' '}
                      <Text
                        component="span"
                        fw={theme.other.fonts.weights.bold}
                      >
                        ${seatPrice / 100}
                      </Text>{' '}
                      <Text
                        component="span"
                        size="xs"
                        fw={theme.other.fonts.weights.medium}
                      >
                        AUD
                      </Text>
                      .
                    </>
                  ) : (
                    <>Each new user may increase your subscription charge.</>
                  )}
                </Text>
              </Group>
            </>
          )}
          <Space h="md" />
        </>
      )}

      <form onSubmit={submit}>
        <Flex gap="md" direction="column">
          <TextInput
            placeholder="Recipients name"
            label="Name"
            required
            {...form.getInputProps('name')}
          />
          <TextInput
            placeholder="Recipients email"
            label="Email"
            required
            {...form.getInputProps('email')}
          />
          <Radio.Group
            label="User role"
            required
            placeholder="Select user role"
            {...form.getInputProps('roleName')}
            error={form.errors?.roleName}
          >
            {userRoleOptions.map(({ label, value }) => (
              <Radio
                className={styles.radioOption}
                value={value}
                label={label}
                key={value}
              />
            ))}
          </Radio.Group>
          {userRoleOptions.map(({ value, label }) => (
            <Text component="span" key={value}>
              <strong>{label}s</strong> {roleDescriptionMap[value]}
              <br />
            </Text>
          ))}

          <Flex justify="end">
            <Button type="submit" loading={isLoading} loaderPosition="center">
              Send
            </Button>
          </Flex>
        </Flex>
      </form>
    </>
  );
}
