import parsePhoneNumber from 'libphonenumber-js/mobile';

type Validator = (value: string, error?: string) => string | null;

// Validate given a regular expression
const validateRe = (re: RegExp, value: string, error: string | null = null) =>
  re.test(value) ? null : error;

// Validate email
export const validateEmail: Validator = (value, error) =>
  validateRe(/^\S+@\S+$/, value, error);

// Validate Australian mobile number
export const validateMobile: Validator = (value, error) => {
  const ph = parsePhoneNumber(`${value}`);
  // Because we import the mobile version of libphonenumber-js,
  // isValid will only return true if the number is a mobile number
  // If we need to import /max, we need to add a check here:
  // ph.getType() === 'MOBILE'
  return ph && ph.isValid() && ph.country === 'AU' ? null : error;
};
