import { ACTIVE_SUBSCRIPTION_STATUSES } from '@agtuary/api/constants/paymentSubscription';
import { Team } from '@agtuary/api/types/team';
import { User } from '@agtuary/api/types/user';
import { b62DecodeUUIDUnsafeOrOriginal } from '@agtuary/common/helpers/encode/base62';
import { isNil } from '@agtuary/common/helpers/nil';
import { useRouter } from 'next/router';
import { useCallback, useMemo, useState } from 'react';
import { useHydratedSessionStore, useSessionStore } from 'stores/SessionStore';
import { useBetween } from 'use-between';
import { useDebouncedEffect } from './useDebouncedEffect';

const isValidSessionCurrentTeamId = (
  value: unknown,
): value is string | undefined | null =>
  value === undefined || value === null || typeof value === 'string';

type TeamMatcher = (t: Team) => boolean;

const paymentMatcher: TeamMatcher = (t: Team) =>
  t.paymentSubscriptions.some(
    (ps) =>
      ps.status !== null &&
      ps.noOfSeats !== null &&
      ACTIVE_SUBSCRIPTION_STATUSES.includes(ps.status) &&
      ps.noOfSeats > 0,
  );
const findFirstTeam: TeamMatcher = () => true;

const useSelectedTeam = () => {
  const [selectedTeamId, setSelectedTeamId] = useState<string | null>(null);
  return { selectedTeamId, setSelectedTeamId };
};

const useSharedSelectedTeam = () => useBetween(useSelectedTeam);

// This custom hook returns the current team for the user
// Takes an array of teams the user is currently in to verify the current team is valid
export const useCurrentTeam = (
  teams: Team[] | undefined,
  currentUser: User | undefined,
): {
  currentTeam: Team | undefined; // The current team for the user
  setCurrentTeam: (team: Team) => void; // A function to set the current team for the user
} => {
  const router = useRouter();

  const rawSessionCurrentTeamId = useHydratedSessionStore(
    (state) => state.currentTeamId,
  );

  // Get the current team ID from the user's session
  const sessionCurrentTeamId: string | undefined | null =
    isValidSessionCurrentTeamId(rawSessionCurrentTeamId)
      ? rawSessionCurrentTeamId
      : null;

  const sessionUpdater = useSessionStore((state) => state.setCurrentTeamId);

  const setCurrentTeamIdInSession = useCallback(
    (latestTeamId: string | null | undefined) => {
      // If the team ID is different from the session current team ID,
      // update the session current team ID so it can be remembered on next visit
      if (!isNil(latestTeamId) && latestTeamId !== sessionCurrentTeamId) {
        sessionUpdater(latestTeamId);
      }
    },
    [sessionUpdater, sessionCurrentTeamId],
  );

  const { selectedTeamId, setSelectedTeamId } = useSharedSelectedTeam();

  // A function to set the current team for the user in the session
  // This can only be used when the route does not have a team ID in the URL
  // And will be overwritten by the URL if it does
  const setCurrentTeam = useCallback(
    (team: Team) => {
      sessionUpdater(team.id);
      setSelectedTeamId(team.id);
    },
    [sessionUpdater, setSelectedTeamId],
  );

  // Get the team ID from the URL query parameters
  const routerTeamId = router.query.teamId;
  const teamIdFromUrl = useMemo(() => {
    if (typeof routerTeamId === 'string') {
      return b62DecodeUUIDUnsafeOrOriginal(routerTeamId);
    }
    if (Array.isArray(routerTeamId)) {
      return b62DecodeUUIDUnsafeOrOriginal(routerTeamId[0]);
    }
    return null;
  }, [routerTeamId]);

  // Get the current team for the user from the URL,
  // falling back to the first subscribed team or finally just the first team.
  const currentTeam = useMemo(() => {
    const teamIdFromCurrentContext = selectedTeamId ?? teamIdFromUrl;
    const teamFromContext = teams?.find(
      (t) => t.id === teamIdFromCurrentContext,
    );

    if (teamFromContext) {
      return teamFromContext;
    }

    if (currentUser?.defaultTeamId) {
      const defaultTeam = teams?.find(
        (t) => t.id === currentUser.defaultTeamId,
      );

      if (defaultTeam) {
        return defaultTeam;
      }
    }

    const teamFromLastSession = teams?.find(
      (t) => t.id === sessionCurrentTeamId,
    );

    if (teamFromLastSession) {
      return teamFromLastSession;
    }

    // Always use these matchers as a fallback
    const matchers = [paymentMatcher, findFirstTeam];

    const team =
      teams === undefined
        ? undefined
        : matchers.reduce<Team | undefined>((acc, currentMatcher) => {
            // Search using each matcher, bail out as soon as a match is found
            if (acc) return acc;
            return teams.find(currentMatcher);
          }, undefined);

    return team;
  }, [
    currentUser?.defaultTeamId,
    selectedTeamId,
    sessionCurrentTeamId,
    teamIdFromUrl,
    teams,
  ]);

  useDebouncedEffect(
    () => setCurrentTeamIdInSession(currentTeam?.id),
    [currentTeam?.id],
    1000,
  );

  return {
    currentTeam,
    setCurrentTeam,
  };
};
