import { isClientSide } from '@agtuary/common/helpers/environment';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { shouldLogout } from '../helpers';

/**
 * Client-side only.
 * If the server returns a 401, log the user out.
 * @param logout - The logout function to call.
 */
export const logoutLink = (logout: () => void) =>
  onError((error: ErrorResponse) => {
    if (isClientSide && shouldLogout(error)) {
      logout();
    }
  });
