import { Input, Text } from '@mantine/core';
import formStyles from '../formStyles.module.scss';

type LabelAndValueProps = {
  label?: string;
  value: string | null | undefined;
  error?: string;
};

export function LabelAndValue({ label, value, error }: LabelAndValueProps) {
  return (
    <Input.Wrapper classNames={{ root: formStyles.root }}>
      {label && <Input.Label>{label}</Input.Label>}
      {value && <Text>{value}</Text>}
      {error && <Input.Error>{error}</Input.Error>}
    </Input.Wrapper>
  );
}
