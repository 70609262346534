import { createQueryKeys } from '@lukemorales/query-key-factory';
import { fetcher } from '../helpers/clientSide/requests';

export const users = createQueryKeys('users', {
  one: (userId: string) => ({
    queryKey: [userId],
    queryFn: () => fetcher(`/api/users/${userId}`),
    contextQueries: {
      external: {
        queryKey: null,
        queryFn: () => fetcher(`/api/users/external/${userId}`),
      },
      jobs: {
        queryKey: null,
        queryFn: () => fetcher(`/api/users/${userId}/jobs`),
      },
      organisations: {
        queryKey: null,
        queryFn: () => fetcher(`/api/users/${userId}/organisations`),
      },
      teams: {
        queryKey: null,
        queryFn: () => fetcher(`/api/users/${userId}/teams`),
      },
    },
  }),
});
