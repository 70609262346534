import { NumberInput, NumberInputProps } from '@mantine/core';
import { ForwardedRef, MouseEvent, forwardRef, useCallback } from 'react';
import { DEFAULT_INPUT_WRAPPER_ORDER } from '../constants';
import formStyles from '../formStyles.module.scss';

function BaseNumberInputComponent(
  props: NumberInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  // Custom onClick behaviour so that the input value is cleared when the user
  // clicks on a number input with a value of 0
  const onClick = useCallback(
    ({ currentTarget }: MouseEvent<HTMLInputElement>) => {
      const content = currentTarget.value;
      if (content === '0') {
        // eslint-disable-next-line functional/immutable-data, no-param-reassign
        currentTarget.value = '';
      }
    },
    [],
  );

  return (
    <NumberInput
      onClick={onClick}
      className={formStyles.root}
      inputWrapperOrder={DEFAULT_INPUT_WRAPPER_ORDER}
      {...props}
      ref={ref}
      thousandsSeparator=","
      hideControls
    />
  );
}

export const BaseNumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  BaseNumberInputComponent,
);
