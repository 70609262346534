import { useApplicationMode } from 'hooks/useApplicationMode';
import Link from 'next/link';

export function HeaderLogoLink() {
  const {
    options: { primaryLogo },
  } = useApplicationMode();

  return <Link href="/">{primaryLogo}</Link>;
}
