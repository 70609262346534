import { PropsWithChildren, useMemo, useState } from 'react';
import { ContextMenu } from './ContextMenu';
import {
  ContextMenuContext,
  defaultContextMenuContext,
} from './ContextMenuContext';

export function ContextMenuProvider({ children }: PropsWithChildren) {
  const [opened, setOpened] = useState(defaultContextMenuContext.opened);
  const [point, setPoint] = useState(defaultContextMenuContext.point);
  const [title, setTitle] = useState(defaultContextMenuContext.title);
  const [menuItems, setMenuItems] = useState(
    defaultContextMenuContext.menuItems,
  );

  const contextValue = useMemo(
    () => ({
      opened,
      setOpened,
      point,
      setPoint,
      title,
      setTitle,
      menuItems,
      setMenuItems,
    }),
    [opened, point, title, menuItems],
  );

  return (
    <ContextMenuContext.Provider value={contextValue}>
      {children}
      <ContextMenu />
    </ContextMenuContext.Provider>
  );
}
