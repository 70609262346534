import { MantineTheme, MantineThemeOverride, rem } from '@mantine/core';

export const Tabs: MantineThemeOverride['components'] = {
  Tabs: {
    variants: {
      default: (theme: MantineTheme) => ({
        root: {
          color: theme.primaryColor,
          fontSize: theme.fontSizes.sm,
        },
        tabsList: {
          borderBottomWidth: 1,
        },
        tab: {
          borderStyle: 'solid',
          paddingLeft: 4,
          paddingRight: 4,
          marginLeft: `calc(${theme.spacing.md} - ${rem(4)})`,
          marginRight: `calc(${theme.spacing.md} - ${rem(4)})`,
          borderBottomWidth: 1,
          fontSize: theme.fontSizes.lg,
          fontWeight: theme.other.fonts.weights.medium,
          '&[data-active="true"]': {
            borderBottomWidth: 2,
            fontWeight: theme.other.fonts.weights.bold,
          },
          '&:hover:not([data-active="true"])': {
            backgroundColor: 'inherit',
            borderColor: 'transparent',
          },
        },
      }),
    },
  },
};
