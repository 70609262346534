import Router from 'next/router';
import { Route } from 'nextjs-routes';

const logoutBroadcastChannel = new BroadcastChannel('logout-signal');

const logoutRoute: Route = {
  pathname: '/auth/logout',
};

/**
 * What to do when the user logs out.
 */
const onLogout = () => {
  if (Router.pathname !== logoutRoute.pathname) Router.push(logoutRoute);
};

/**
 * Broadcasts a logout signal to all open tabs and redirects to the logout page.
 */
export const logout = () => {
  logoutBroadcastChannel.postMessage('logout');

  onLogout();
};

/**
 * Listens for logout signals from other tabs and redirects to the logout page.
 */
export const listenForLogout = () => {
  logoutBroadcastChannel.addEventListener('message', onLogout);
};

/**
 * Removes the logout listener.
 */
export const removeLogoutListener = () => {
  logoutBroadcastChannel.removeEventListener('message', onLogout);
};
