import { useModals } from '@mantine/modals';
import { useCheckUser } from 'hooks/useCheckUser';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useHydratedSessionStore, useSessionStore } from 'stores/SessionStore';
import { useHydratedTeamInvitesStore } from 'stores/TeamInvitesStore';
import { AcceptInviteModal } from '../AcceptInviteModal';
import { useOnboardModal } from '../OnboardModal';
import { usePaymentNudgeModal } from '../PaymentNudgeModal/usePaymentNudgeModal';

export function UserCheckModals({ children }: PropsWithChildren): JSX.Element {
  const { currentTeam } = useCurrentUser();
  const { moreInfoRequired } = useCheckUser();
  const pendingTeamInvites = useHydratedTeamInvitesStore(
    (state) => state.invites,
  );

  const { modals, closeAll: closeAllModals } = useModals();

  const paymentNudgeShown = useHydratedSessionStore(
    (state) => state.paymentNudgeShown,
  );
  const setPaymentNudgeShown = useSessionStore(
    (state) => state.setPaymentNudgeShown,
  );
  const showPaymentNudgeModal = usePaymentNudgeModal();

  // Show payment nudge modal
  useEffect(() => {
    if (
      moreInfoRequired === false &&
      !paymentNudgeShown &&
      currentTeam?.paymentSubscriptions.at(0)?.requestPayment &&
      currentTeam?.paymentSubscriptions.at(0)?.checkoutUrl
    ) {
      setPaymentNudgeShown(true);
      showPaymentNudgeModal();
    }
  }, [
    showPaymentNudgeModal,
    currentTeam,
    moreInfoRequired,
    paymentNudgeShown,
    setPaymentNudgeShown,
  ]);

  const [invitationCode, setInvititationCode] = useState<string | undefined>(
    undefined,
  );

  // Show any pending team invites
  useEffect(() => {
    if (pendingTeamInvites && pendingTeamInvites.length > 0) {
      closeAllModals();
      setInvititationCode(pendingTeamInvites[0]);
    }
  }, [closeAllModals, modals.length, pendingTeamInvites]);

  const closeInviteModal = useCallback(
    () => setInvititationCode(undefined),
    [setInvititationCode],
  );

  const { ctx: onboardModalCtx, open: showOnboardModal } = useOnboardModal();

  // Show onboarding modal
  useEffect(() => {
    if (
      moreInfoRequired &&
      !invitationCode &&
      modals.filter((m) => m.type === 'context' && m.ctx === onboardModalCtx)
        .length === 0
    ) {
      // Show onboarding modal
      showOnboardModal();
    }
  }, [
    invitationCode,
    modals,
    moreInfoRequired,
    onboardModalCtx,
    showOnboardModal,
  ]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (
    <>
      {invitationCode && (
        <AcceptInviteModal
          invitationCode={invitationCode}
          closeModal={closeInviteModal}
        />
      )}
      {children}
    </>
  );
}
