import { useApplicationMode } from 'hooks/useApplicationMode';
import Head from 'next/head';
import { PropsWithChildren } from 'react';

export function PageTitle({ children }: PropsWithChildren) {
  const {
    options: { pageTitle },
  } = useApplicationMode();

  return (
    <Head>
      <title>{children ? `${children} | ${pageTitle}` : pageTitle}</title>
    </Head>
  );
}
