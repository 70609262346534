import { Box, clsx } from '@mantine/core';
import Image, { ImageProps } from 'next/image';
import { memo } from 'react';
import styles from './ImageLogo.module.scss';

type ImageLogoProps = Omit<ImageProps, 'src' | 'alt'>;

function ImageLogoComponent({ className, ...imageProps }: ImageLogoProps) {
  return (
    <Box className={clsx(styles.container, className)}>
      <Image src="/logo/rounded.svg" alt="Agtuary Logo" fill {...imageProps} />
    </Box>
  );
}

export const ImageLogo = memo(ImageLogoComponent);
