import { useMutation, useQueryClient } from '@tanstack/react-query';
import { mutations } from '../../../mutations';
import { queries } from '../../../queries';
import { Role } from '../../../types/role';

export const useTeamUpdateRole = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation<
    { success: boolean },
    unknown,
    Partial<Role>,
    {
      previousTeamUsers: Role[] | undefined;
    }
  >({
    ...mutations.teams.one(id)._ctx.updateRole,

    onMutate: async () => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queries.teams.one(id)._ctx.users);
      // Snapshot the previous value
      const previousTeamUsers = queryClient.getQueryData<Role[]>(
        queries.teams.one(id)._ctx.users.queryKey,
      );

      // Return a context object with the snapshotted value
      return { previousTeamUsers };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newData, context) => {
      queryClient.setQueryData(
        queries.teams.one(id)._ctx.users.queryKey,
        context?.previousTeamUsers,
      );
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(queries.teams.one(id)._ctx.users);
    },
  });
};
