import Decimal from 'decimal.js-light';
import { useMemo } from 'react';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';

export const formatDecimalValue = (value: Decimal | number | null) => {
  if (value instanceof Decimal) {
    return value.toNumber();
  }
  if (value === null) {
    return '';
  }

  return value;
};

export const useFormattedDecimal = (
  field: ControllerRenderProps<FieldValues, string>,
) => useMemo(() => formatDecimalValue(field.value), [field.value]);
