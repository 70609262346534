import { createQueryKeys } from '@lukemorales/query-key-factory';
import { fetcher } from '../helpers/clientSide/requests';

export const external = createQueryKeys('external', {
  stripeSubscription: (subscriptionId: string) => ({
    queryKey: [subscriptionId],
    queryFn: () =>
      fetcher(`/api/external/stripe/subscriptions/${subscriptionId}`),
  }),
});
