import { ApolloLink } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { APOLLO_API, httpOptions } from './httpLink';

/**
 * Apollo batch HTTP options for each API.
 */
const batchHttpOptions: Record<APOLLO_API, BatchHttpLink.Options> = {
  [APOLLO_API.BAS]: {
    batchMax: 10,
    batchInterval: 10,
    ...httpOptions[APOLLO_API.BAS],
  },
  [APOLLO_API.STATEN_ISLAND]: {
    batchMax: 10,
    batchInterval: 10,
    ...httpOptions[APOLLO_API.STATEN_ISLAND],
  },
};

/**
 * Apollo batch HTTP link.
 * Splits the link based on the API context.
 */
export const batchHttpLink = ApolloLink.split(
  (operation) => operation.getContext().api === APOLLO_API.BAS,
  new BatchHttpLink(batchHttpOptions[APOLLO_API.BAS]),
  new BatchHttpLink(batchHttpOptions[APOLLO_API.STATEN_ISLAND]),
);
