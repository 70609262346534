import { useMutation } from '@tanstack/react-query';
import { mutations } from '../../../mutations';

export const useShareReport = (reportId: string) =>
  useMutation<
    { sent: boolean },
    unknown,
    {
      name: string;
      target: string;
    }
  >(mutations.reports.one(reportId)._ctx.share);
