import {
  Input,
  SegmentedControlProps as MTSegmentedControlProps,
} from '@mantine/core';
import { useMergedRef } from '@mantine/hooks';
import { ForwardedRef, forwardRef, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { DEFAULT_INPUT_WRAPPER_ORDER } from '../constants';
import formStyles from '../formStyles.module.scss';
import { RHFInputProps } from '../types';
import { buildValidationRules } from '../validation';
import { BaseSegmentedControl } from './BaseSegmentedControl';

type SegmentedControlProps = RHFInputProps & MTSegmentedControlProps;

function SegmentedControlComponent(
  props: SegmentedControlProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { name, validation, label } = props;
  const { control } = useFormContext();

  const validationRules = useMemo(
    () => buildValidationRules(validation),
    [validation],
  );

  const { field } = useController({ name, control, rules: validationRules });

  const { name: _, ...segmentedControlProps } = props;

  const mergedRef = useMergedRef(ref, field.ref);

  return (
    <Input.Wrapper
      classNames={{ root: formStyles.root }}
      label={label}
      inputWrapperOrder={DEFAULT_INPUT_WRAPPER_ORDER}
    >
      <Input type="hidden" {...field} ref={mergedRef} />
      <BaseSegmentedControl
        fullWidth
        {...segmentedControlProps}
        disabled={field.disabled}
        value={field.value}
        onChange={(v) => field.onChange({ target: { value: v } })}
      />
    </Input.Wrapper>
  );
}

export const SegmentedControl = forwardRef<
  HTMLInputElement,
  SegmentedControlProps
>(SegmentedControlComponent);
