import { PropsWithChildren, useMemo, useState } from 'react';
import { Report } from '@agtuary/api/types/report';
import { Property } from '@agtuary/api/types/property';
import {
  ReportViewDrawerContext,
  defaultReportViewDrawerContext,
} from './ReportViewDrawerContext';
import { ReportViewDrawer } from './ReportViewDrawer';
import { ReportViewDrawerOnClose } from './types';

export function ReportViewDrawerProvider({ children }: PropsWithChildren) {
  const [report, setReport] = useState<Report | null>(
    defaultReportViewDrawerContext.report,
  );
  const [property, setProperty] = useState<Property | null>(
    defaultReportViewDrawerContext.property,
  );
  const [onClose, setOnClose] = useState<ReportViewDrawerOnClose>(
    defaultReportViewDrawerContext.onClose,
  );

  const contextValue = useMemo(
    () => ({
      report,
      setReport,
      property,
      setProperty,
      onClose,
      setOnClose,
    }),
    [report, property, onClose],
  );

  return (
    <ReportViewDrawerContext.Provider value={contextValue}>
      {children}
      <ReportViewDrawer />
    </ReportViewDrawerContext.Provider>
  );
}
