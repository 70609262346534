import {
  Button,
  Divider,
  Box,
  Title,
  Flex,
  Text,
  useMantineTheme,
  Center,
} from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { IconPlus, IconSearch } from '@tabler/icons-react';

export function MapIntroModal({ id, context }: ContextModalProps) {
  const theme = useMantineTheme();

  return (
    <>
      <Box p="lg">
        <Title order={4}>Finding specific properties</Title>
      </Box>
      <Divider />
      <Box p="lg">
        <Flex gap="md" direction="column">
          <Box>
            <Text size="md" fw={theme.other.fonts.weights.normal}>
              You can search for properties by:
            </Text>
          </Box>
          <Flex gap={8} align="center">
            <Center
              p={4}
              sx={() => ({
                borderRadius: theme.radius.xs,
                borderColor: theme.colors.gray[2],
                borderWidth: 1,
                borderStyle: 'solid',
              })}
            >
              <IconSearch size={14} color={theme.other.colors.text} />
            </Center>
            <Text size="md" fw={theme.other.fonts.weights.normal}>
              <Text
                component="span"
                fw={theme.other.fonts.weights.bold}
                color="brand"
              >
                Search
              </Text>{' '}
              for the address
            </Text>
          </Flex>

          <Flex gap={8} align="center">
            <Center
              p={4}
              sx={() => ({
                borderRadius: theme.radius.xs,
                borderColor: theme.colors.gray[2],
                borderWidth: 1,
                borderStyle: 'solid',
              })}
            >
              <IconSearch size={14} color={theme.other.colors.text} />
            </Center>
            <Text size="md" fw={theme.other.fonts.weights.normal}>
              Location (lat/lon)
            </Text>
          </Flex>

          <Flex gap={8} align="center">
            <Center
              p={4}
              sx={() => ({
                borderRadius: theme.radius.xs,
                borderColor: theme.colors.gray[2],
                borderWidth: 1,
                borderStyle: 'solid',
              })}
            >
              <IconSearch size={14} color={theme.other.colors.text} />
            </Center>
            <Text size="md" fw={theme.other.fonts.weights.normal}>
              Lot and plan number
            </Text>
          </Flex>

          <Flex gap={8} align="center">
            <Center
              p={4}
              sx={() => ({
                borderRadius: theme.radius.xs,
                borderColor: theme.colors.gray[2],
                borderWidth: 1,
                borderStyle: 'solid',
              })}
            >
              <IconPlus size={14} color={theme.other.colors.text} />
            </Center>
            <Text size="md" fw={theme.other.fonts.weights.normal}>
              <Text
                component="span"
                fw={theme.other.fonts.weights.bold}
                color="brand"
              >
                Zoom
              </Text>{' '}
              in on the map
            </Text>
          </Flex>

          <Button w="100%" onClick={() => context.closeModal(id)}>
            Continue
          </Button>
        </Flex>
      </Box>
    </>
  );
}
