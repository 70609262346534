import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { queries } from '../../queries';
import { Organisation } from '../../types/organisation';

export const useOrganisation = (
  organisationId: string,
): UseQueryResult<Organisation> =>
  useQuery({
    ...queries.organisations.one(organisationId),
    enabled: !!organisationId,
  });
