import { useUpdateOrganisation } from '@agtuary/api/hooks/organisations/mutations/useUpdateOrganisation';
import { useOrganisation } from '@agtuary/api/hooks/organisations/useOrganisation';
import { useUpdateUser } from '@agtuary/api/hooks/users/mutations/useUpdateUser';
import { Organisation } from '@agtuary/api/types/organisation';
import { User } from '@agtuary/api/types/user';
import { DelayedLoadingOverlay } from '@agtuary/ui/DelayedLoadingOverlay';
import { PhoneInput } from '@agtuary/ui/inputs/MobileInput';
import {
  Anchor,
  Button,
  Card,
  Checkbox,
  Flex,
  MultiSelect,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
// eslint-disable-next-line no-restricted-imports
import { useForm } from '@mantine/form';
import { ContextModalProps } from '@mantine/modals';
import { UserContext } from 'context/userContext';
import { MORE_INFO_REQUIRED_ACTION_TYPE } from 'context/userContext/constants';
import { showError } from 'helpers/showError';
import { validateMobile } from 'helpers/validation/validation';
import { useLoadingInitialValues } from 'hooks/form/useLoadingInitialValues';
import { useApplicationMode } from 'hooks/useApplicationMode';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useContext, useEffect, useMemo, useState } from 'react';
import { aiaOnboardingEnterprises } from './constants';

export function OnboardModal({ context, id }: ContextModalProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { moreInfoRequired, moreInfoRequiredDispatch } =
    useContext(UserContext);
  const {
    user: { data: user },
    currentTeam,
  } = useCurrentUser();
  const { mutateAsync: mutateUser } = useUpdateUser(user?.id);

  const { data: organisation } = useOrganisation(currentTeam?.organisation?.id);
  const { mutateAsync: mutateOrganisation } = useUpdateOrganisation(
    currentTeam?.organisation?.id,
  );

  useEffect(() => {
    if (!moreInfoRequired) {
      context.closeModal(id);
    }
  }, [context, id, moreInfoRequired]);

  useEffect(() => {
    setIsSubmitting(false);
    moreInfoRequiredDispatch({
      type: MORE_INFO_REQUIRED_ACTION_TYPE.Check,
      payload: { user, team: currentTeam },
    });
  }, [user, currentTeam, moreInfoRequiredDispatch]);

  const initialValues: {
    user: Partial<User>;
    organisation: Partial<Organisation>;
  } = {
    user: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      mobile: user?.mobile ?? '',
      termsAcceptedAt: undefined,
    },
    organisation: {
      name: organisation?.name ?? '',
      aiaEnterprises: organisation?.aiaEnterprises,
      aiaTermsAccepted: organisation?.aiaTermsAccepted,
    },
  };

  const isOwner = useMemo(
    () => organisation?.ownerId === user?.id,
    [organisation, user],
  );

  const form = useForm({
    initialValues,
    validate: {
      user: {
        mobile: (value) =>
          validateMobile(value, 'Please enter a valid mobile number.'),
      },
    },
    validateInputOnBlur: true,
  });

  const loading = useLoadingInitialValues(form, () => initialValues, [
    user,
    organisation,
  ]);

  const submit = form.onSubmit(
    ({
      user: { termsAcceptedAt, ...userData },
      organisation: { aiaTermsAccepted, aiaEnterprises, name },
    }) => {
      if (termsAcceptedAt) {
        setIsSubmitting(true);
        mutateUser({
          ...userData,
          termsAcceptedAt: new Date(),
        }).catch(() => {
          showError('Failed to update user details');
        });
        mutateOrganisation({
          name,
          aiaEnterprises,
          aiaTermsAccepted,
        }).catch(() => {
          showError('Failed to update organisation details');
        });
      }
    },
  );

  const {
    options: { aiaCustomOrgFields, termsUrl, privacyUrl },
  } = useApplicationMode();
  const disabledUnlessOwner = !isOwner;

  return (
    <Card w="100%" p="xl" radius="sm" withBorder>
      <Card.Section withBorder inheritPadding py="xl">
        <Title order={4} mb={4}>
          Account Details
        </Title>
        <Text size="sm" color="dimmed">
          We need a few more details to complete your account.
        </Text>
      </Card.Section>
      <Card.Section inheritPadding py="xl">
        <DelayedLoadingOverlay visible={loading} overlayBlur={0.2} delay={200}>
          <form onSubmit={submit}>
            <Flex gap="lg" direction="column">
              <TextInput
                placeholder="Your first name"
                label={
                  <Title order={5} color="brand" mb={8}>
                    First Name
                  </Title>
                }
                required
                withAsterisk={false}
                {...form.getInputProps('user.firstName')}
              />
              <TextInput
                placeholder="Your last name"
                label={
                  <Title order={5} color="brand" mb={8}>
                    Last Name
                  </Title>
                }
                required
                withAsterisk={false}
                {...form.getInputProps('user.lastName')}
              />
              <PhoneInput
                placeholder="04XX XXX XXX"
                label={
                  <Title order={5} color="brand" mb={8}>
                    Mobile number
                  </Title>
                }
                required
                withAsterisk={false}
                {...form.getInputProps('user.mobile')}
              />
              <TextInput
                placeholder="Your organisation"
                label={
                  <Title order={5} color="brand" mb={8}>
                    Organisation
                  </Title>
                }
                required={isOwner}
                disabled={disabledUnlessOwner}
                withAsterisk={false}
                {...form.getInputProps('organisation.name')}
              />
              {aiaCustomOrgFields ? (
                <>
                  <MultiSelect
                    placeholder="Please select one or more"
                    disabled={disabledUnlessOwner}
                    label={
                      <Title order={5} color="brand" mb={8}>
                        This organisation produces the following commodities:
                      </Title>
                    }
                    withAsterisk={false}
                    data={aiaOnboardingEnterprises}
                    {...form.getInputProps('organisation.aiaEnterprises')}
                  />
                  <div>
                    <Title order={4} color="brand" mb={8}>
                      Terms acceptance
                    </Title>
                    <Text>
                      By creating an account for the Platform, you agree to the
                      following:
                      <ul>
                        <li>
                          You are bound by the{' '}
                          <Anchor
                            href={termsUrl}
                            variant="text"
                            td="underline"
                            target="_blank"
                          >
                            Platform Access Terms
                          </Anchor>
                        </li>
                        <li>
                          AIA may collect, use and disclose the data you provide
                          to us (which may include your personal information) in
                          accordance with the{' '}
                          <Anchor
                            href={termsUrl}
                            variant="text"
                            td="underline"
                            target="_blank"
                          >
                            Platform Access Terms
                          </Anchor>{' '}
                          and AIA&lsquo;s{' '}
                          <Anchor
                            href={privacyUrl}
                            variant="text"
                            td="underline"
                            target="_blank"
                          >
                            Privacy Policy
                          </Anchor>{' '}
                          .
                        </li>
                        <li>
                          As part of using the Platform, you will provide AIA
                          with Submitted Data which AIA will transform into
                          Aggregated Data in accordance with the{' '}
                          <Anchor
                            href={termsUrl}
                            variant="text"
                            td="underline"
                            target="_blank"
                          >
                            Platform Access Terms
                          </Anchor>
                          .
                        </li>
                        <li>
                          You may request that AIA delete your Submitted Data
                          and your account, however AIA will retain the
                          Aggregated Data.
                        </li>
                        <li>
                          AIA will store and use the Aggregated Data even if
                          your agreement with AIA is terminated and/or your
                          account is deleted.
                        </li>
                      </ul>
                    </Text>
                  </div>
                  <Checkbox
                    label={
                      <Text>
                        I agree to the &lsquo;Terms acceptance&lsquo; points
                        listed above.
                      </Text>
                    }
                    required
                    {...form.getInputProps('user.termsAcceptedAt')}
                  />
                  <Title order={4} color="brand" mb={8}>
                    Data usage consent
                  </Title>
                  <Checkbox
                    label={
                      <Text>
                        By selecting this option, you agree that AIA can share
                        your Aggregated Data with third parties for their
                        research purposes (optional).
                      </Text>
                    }
                    {...form.getInputProps('organisation.aiaTermsAccepted')}
                    checked={form.values.organisation.aiaTermsAccepted}
                    disabled={disabledUnlessOwner}
                  />
                </>
              ) : (
                <Checkbox
                  label={
                    <Text>
                      I have read and agree to the{' '}
                      <Anchor
                        href={termsUrl}
                        variant="text"
                        td="underline"
                        target="_blank"
                      >
                        Terms & Conditions
                      </Anchor>{' '}
                      and{' '}
                      <Anchor
                        href={privacyUrl}
                        variant="text"
                        td="underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </Anchor>
                    </Text>
                  }
                  required
                  {...form.getInputProps('user.termsAcceptedAt')}
                />
              )}

              <Button type="submit" loading={isSubmitting}>
                Save
              </Button>
            </Flex>
          </form>
        </DelayedLoadingOverlay>
      </Card.Section>
    </Card>
  );
}
