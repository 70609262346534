import { useTeamUpdateRole } from '@agtuary/api/hooks/teams/mutations/useTeamUpdateRole';
import { Role, RoleName } from '@agtuary/api/types/role';
import { Team } from '@agtuary/api/types/team';
import { User } from '@agtuary/api/types/user';
import { FormStack } from '@agtuary/ui/form-stack';
import { RadioGroup, VALIDATION_REQUIRED } from '@agtuary/ui/forms/inputs';
import { Text } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { FormFooter } from 'components/FormFooter';
import { ExtendedHTMLFormElement, Form } from 'components/forms/Form';
import { showError } from 'helpers/showError';
import { showSuccess } from 'helpers/showSuccess';
import { useCallback, useMemo, useRef } from 'react';
import { roleDescriptionMap, userRoleOptions } from './types';

type TeamInviteModalInnerProps = {
  user: User;
  role: Role;
  team: Team;
  closeOnSuccess?: boolean;
};

type DefaultValues = {
  roleName: RoleName | undefined;
};

export function EditTeamMemberModal({
  context,
  id,
  innerProps: { team, role, user },
}: ContextModalProps<TeamInviteModalInnerProps>) {
  const formRef = useRef<ExtendedHTMLFormElement>(null);

  const updateRole = useTeamUpdateRole(team.id);

  const onDone = useCallback(
    async (params: DefaultValues) => {
      try {
        await updateRole.mutateAsync({ name: params.roleName, id: role.id });
        showSuccess({
          title: 'Role updated',
          message: `Team member role has been updated`,
        });
        context.closeModal(id);
      } catch (e) {
        showError('An error occurred while updating the user role.');
      }
    },
    [context, id, role?.id, updateRole],
  );

  const defaultValues: DefaultValues = useMemo(
    () => ({ roleName: role?.name }),
    [role],
  );

  const userName = `${user?.firstName} ${user?.lastName}`;

  const goBack = useCallback(() => context.closeModal(id), [context, id]);

  return (
    <>
      <Text component="p">
        Choose a role in team &apos;{team?.name}&apos; for user {userName}.
      </Text>
      <Form onSubmit={onDone} formRef={formRef} defaultValues={defaultValues}>
        {() => (
          <FormStack>
            <RadioGroup
              name="roleName"
              label="Role type"
              options={userRoleOptions}
              validation={VALIDATION_REQUIRED}
              placeholder="Select user role"
            />
          </FormStack>
        )}
      </Form>
      {userRoleOptions.map(({ value, label }) => (
        <Text component="span" key={value}>
          <strong>{label}s</strong> {roleDescriptionMap[value]}
          <br />
        </Text>
      ))}
      <FormFooter formRef={formRef} goBack={goBack} includePadding={false} />
    </>
  );
}
