import { Title } from '@mantine/core';
import { openContextModal } from '@mantine/modals';
import { Sharable } from './types';

export const useShareModal =
  () => (title: string, sharable: Sharable, onClose?: () => void) => {
    openContextModal({
      modal: 'share',
      title: <Title order={4}>{title}</Title>,
      innerProps: {
        sharable,
      },
      onClose,
    });
  };
