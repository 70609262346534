import { Input } from '@mantine/core';
import {
  DateValue,
  MonthPickerInput as MTMonthPickerInput,
  MonthPickerInputProps as MTMonthPickerInputProps,
} from '@mantine/dates';
import { useMergedRef } from '@mantine/hooks';
import { ForwardedRef, forwardRef, useCallback, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { DEFAULT_INPUT_WRAPPER_ORDER } from '../constants';
import formStyles from '../formStyles.module.scss';
import { useFormLogic } from '../hooks';
import { RHFInputProps } from '../types';

type MonthPickerInputProps = Omit<RHFInputProps, 'type'> &
  Omit<MTMonthPickerInputProps<'default'>, 'type'>;

function MonthPickerComponent(
  props: MonthPickerInputProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { name } = props;
  const { control } = useFormContext();

  const { error, label, validationRules } = useFormLogic(props);

  const { field } = useController({ name, control, rules: validationRules });

  const { name: _, onChange: propsOnChange, ...datePickerProps } = props;

  const mergedRef = useMergedRef(ref, field.ref);

  const classNames = useMemo(() => ({ root: formStyles.root }), []);

  const onChange = useCallback(
    (v: DateValue) => {
      field.onChange({ target: { value: v } });
      if (propsOnChange) {
        propsOnChange(v);
      }
    },
    [field, propsOnChange],
  );

  return (
    <Input.Wrapper
      classNames={classNames}
      inputWrapperOrder={DEFAULT_INPUT_WRAPPER_ORDER}
    >
      <Input type="hidden" {...field} ref={mergedRef} />
      <MTMonthPickerInput
        clearable={false}
        inputWrapperOrder={DEFAULT_INPUT_WRAPPER_ORDER}
        {...datePickerProps}
        label={label}
        type="default"
        placeholder="Please select"
        value={field.value}
        disabled={field.disabled}
        onChange={onChange}
      />
      <Input.Error>{error}</Input.Error>
    </Input.Wrapper>
  );
}

export const MonthPickerInput = forwardRef<
  HTMLDivElement,
  MonthPickerInputProps
>(MonthPickerComponent);
