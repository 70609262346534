import { Title } from '@mantine/core';
import { openContextModal } from '@mantine/modals';

const ctx = 'deleteInformation';

export const useDeleteInformationModal = () => {
  return {
    ctx,
    open: () => {
      openContextModal({
        modal: ctx,
        innerProps: {},
        title: <Title order={4}>Bulk deleting of data</Title>,
      });
    },
  };
};
