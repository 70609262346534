import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  from,
} from '@apollo/client';
import fragmentMatcher from '../../__generated__/fragment-matcher.json';
import {
  APOLLO_CLIENT_NAME,
  APOLLO_CLIENT_VERSION,
  APOLLO_STATE_PROP_NAME,
} from '../constants';
import { isNotFound, shouldLogout } from '../helpers';
import { authorizationLink } from '../links/authorizationLink';
import { httpLink } from '../links/httpLink';
import { logErrorLink } from '../links/logErrorLink';
import { typePolicies } from '../type-policies';

type GetServerSidePropsResult<Props> =
  | { props: Props | Promise<Props> }
  | {
      redirect:
        | {
            statusCode: 301 | 302 | 303 | 307 | 308;
            destination: string;
            basePath?: false;
          }
        | {
            permanent: boolean;
            destination: string;
            basePath?: false;
          };
    }
  | { notFound: true };

/**
 * Get an Apollo Client on the server-side.
 * On the server-side we always use a new client instance.
 */
const getApolloServerClient = (accessToken: string | null) => {
  return new ApolloClient({
    name: APOLLO_CLIENT_NAME,
    version: APOLLO_CLIENT_VERSION,
    cache: new InMemoryCache({
      possibleTypes: fragmentMatcher.possibleTypes,
      typePolicies,
    }),
    link: from([
      logErrorLink,
      ...(accessToken === null ? [] : [authorizationLink(accessToken)]),
      httpLink,
    ]),
    ssrMode: true,
  });
};

/**
 * Add the Apollo state to the page props.
 * @param client The Apollo Client.
 * @param pageProps The page props.
 * @returns The page props with the Apollo state.
 */
export const addApolloState = <TPageProps extends Record<string, unknown>>(
  client: ApolloClient<unknown>,
  pageProps: TPageProps,
) =>
  'props' in pageProps && pageProps?.props
    ? {
        ...pageProps,
        props: {
          [APOLLO_STATE_PROP_NAME]: client.cache.extract(),
        },
      }
    : pageProps;

/**
 * Preload any data required for the page. Handles logging out if the user is no longer authenticated.
 * @param accessToken The access token to use for the Apollo Client. If null, the user is not authenticated.
 * @param preloadCallback A callback that preloads the data into the Apollo Client cache.
 * @param pageProps The page props to return with the Apollo state injected.
 * @returns The page props with the Apollo state.
 */
export const preloadApolloState = async <Props>(
  accessToken: string | null,
  preloadCallback: (
    client: ApolloClient<NormalizedCacheObject>,
  ) => Promise<void>,
  pageProps: GetServerSidePropsResult<Props>,
): Promise<GetServerSidePropsResult<Props>> => {
  const isAuthed = accessToken !== null;

  try {
    const client = await getApolloServerClient(accessToken);
    await preloadCallback(client);

    return addApolloState(client, pageProps);
  } catch (error) {
    if (isAuthed && shouldLogout(error)) {
      return {
        redirect: {
          destination: '/logout',
          permanent: false,
        },
      };
    }

    if (isNotFound(error)) {
      return {
        notFound: true,
      };
    }

    // On error that isn't a logoutLink error, return the original page props
    return pageProps;
  }
};
