import { MultiSelect as MTMultiSelect } from '@mantine/core';
import { useMergedRef } from '@mantine/hooks';
import { ComponentProps, ForwardedRef, forwardRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { DEFAULT_INPUT_WRAPPER_ORDER } from '../constants';
import formStyles from '../formStyles.module.scss';
import { useFormLogic } from '../hooks';
import { RHFInputProps } from '../types';

type MultiSelectProps = RHFInputProps & ComponentProps<typeof MTMultiSelect>;

function MultiSelectComponent(
  props: MultiSelectProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { name, searchable } = props;
  const { label, error, validationRules } = useFormLogic(props);
  const { control } = useFormContext();
  const { field } = useController({ name, control, rules: validationRules });
  const mergedRef = useMergedRef(ref, field.ref);

  return (
    <MTMultiSelect
      className={formStyles.root}
      inputWrapperOrder={DEFAULT_INPUT_WRAPPER_ORDER}
      placeholder="Select value"
      description={
        searchable &&
        'You can type in the field to narrow down the list of options'
      }
      {...props}
      {...field}
      ref={mergedRef}
      error={error}
      label={label}
    />
  );
}

export const MultiSelect = forwardRef<HTMLInputElement, MultiSelectProps>(
  MultiSelectComponent,
);
