/**
 * Node converts all headers to lowercase so we enforce lowercase header names.
 */
type Header = Lowercase<`${string}`>;
type AgtuaryHeader = Lowercase<`agtuary-${Header}`>;

/**
 * Agtuary custom headers.
 */

/**
 * Sets the timeout header for the API request on the Next server-side.
 * Consumed by the Next server-side when forwarding a request to an external API.
 */
export const NEXT_REQUEST_TIMEOUT_HEADER: AgtuaryHeader =
  'agtuary-next-request-timeout';

/**
 * Apollo client headers.
 */

/**
 * Apollo client name header.
 * @see https://www.apollographql.com/docs/react/data/operation-best-practices/#set-your-apps-name-and-version-for-metrics-reporting-paid
 */
export const APOLLO_CLIENT_NAME_HEADER: Header = 'apollographql-client-name';

/**
 * Apollo client version header.
 * @see https://www.apollographql.com/docs/react/data/operation-best-practices/#set-your-apps-name-and-version-for-metrics-reporting-paid
 */
export const APOLLO_CLIENT_VERSION_HEADER: Header =
  'apollographql-client-version';
