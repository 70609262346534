import type { ObjectEntries } from 'type-fest/source/entries';

/**
 * Type-safe version of Object.entries.
 * @param object The object to get entries from.
 * @returns The entries of the object.
 */
export function entriesFromObject<T extends object>(
  object: T,
): ObjectEntries<T> {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- Filling TS gaps
  return Object.entries(object) as ObjectEntries<T>;
}

/**
 * Type-safe version of Object.fromEntries.
 * @param entries The entries to get an object from.
 * @returns The object from the entries.
 */
export function objectFromEntries<T extends object>(
  entries: ObjectEntries<T>,
): T {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- Filling TS gaps
  return Object.fromEntries(entries) as T;
}

/**
 * Type-safe version of Object.values.
 * @param object The object to get values from.
 * @returns The values of the object.
 */
export function valuesFromObject<T extends object>(
  object: T,
): Array<T[keyof T]> {
  return Object.values(object);
}

/**
 * Type-safe version of Object.keys.
 * @param object The object to get keys from.
 * @returns The keys of the object.
 */
export function keysFromObject<T extends object>(object: T): Array<keyof T> {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- Filling TS gaps
  return Object.keys(object) as Array<keyof T>;
}

export type Nullable<T> = { [K in keyof T]: T[K] | null };
export type NullOrUndefined<T> = { [K in keyof T]: T[K] | null | undefined };

export const isObject = (value: unknown): value is object =>
  typeof value === 'object' && value !== null && !Array.isArray(value);

export const swapObjectKeysAndValues = <
  K extends PropertyKey,
  V extends PropertyKey,
>(
  input: Record<K, V>,
): Record<V, K> =>
  objectFromEntries(
    entriesFromObject(input).map(([key, value]) => [value, key]),
  );

export const optionsFromLabels = <T extends string, L>(
  labels: Record<T, L>,
): { value: T; label: L }[] =>
  entriesFromObject(labels).map(([value, label]) => ({ value, label }));
