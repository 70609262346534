import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useCurrentUser } from './useCurrentUser';

export function useAccessRights() {
  const { currentTeam, userId } = useCurrentUser();
  const router = useRouter();

  const currentRole = useMemo(
    () => currentTeam?.roles?.find((role) => role.userId === userId)?.name,
    [currentTeam?.roles, userId],
  );

  const { hasAdmin, hasMember, hasViewer, hasAdvisor } = useMemo(
    () => ({
      hasAdmin: currentRole === 'ADMIN',
      hasAdvisor: currentRole === 'ADVISOR',
      hasMember: ['ADMIN', 'MEMBER', 'ADVISOR'].includes(currentRole),
      hasViewer: ['ADMIN', 'MEMBER', 'VIEWER', 'ADVISOR'].includes(currentRole),
    }),
    [currentRole],
  );

  /* NOTE: This is potentially a maintenance problem, we may need to revisit this solution as the app evolves.
   * At the moment we have most pages under the team area, but a few outside. As the combinations of roles and permissions
   * increases, matching by URL may become unwieldy. */
  if (router.query.teamId) {
    return {
      hasAdmin,
      hasAdvisor,
      hasMember,
      canEdit: hasMember,
      hasViewer,
    };
  }

  return {
    hasAdmin: true,
    hasAdvisor: false,
    hasMember: true,
    canEdit: true,
    hasViewer: true,
  };
}
