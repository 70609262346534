import {
  DateInput as MTDateInput,
  DateInputProps as MTDateInputProps,
} from '@mantine/dates';
import { useMergedRef } from '@mantine/hooks';
import { ForwardedRef, forwardRef, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { DEFAULT_INPUT_WRAPPER_ORDER } from '../constants';
import formStyles from '../formStyles.module.scss';
import { useFormLogic } from '../hooks';
import { RHFInputProps } from '../types';
import { buildValidationRules } from '../validation';

type DateInputProps = RHFInputProps & MTDateInputProps;

function DateInputComponent(
  props: DateInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { name, validation, clearable } = props;
  const { setValue, control, formState } = useFormContext();
  const { isSubmitted } = formState;
  const { error, label } = useFormLogic({
    ...props,
  });

  const validationRules = useMemo(
    () => buildValidationRules(validation),
    [validation],
  );

  const { field } = useController({ name, control, rules: validationRules });

  const mergedRef = useMergedRef(ref, field.ref);

  return (
    <MTDateInput
      className={formStyles.root}
      inputWrapperOrder={DEFAULT_INPUT_WRAPPER_ORDER}
      valueFormat="l"
      placeholder="DD/MM/YYYY"
      description="dd/mm/yyyy"
      {...props}
      label={label}
      allowDeselect={false}
      {...field}
      onChange={(value) => {
        setValue(name, value, {
          shouldDirty: true,
          shouldValidate: isSubmitted,
        });
      }}
      error={error}
      ref={mergedRef}
      clearable={clearable ?? true}
    />
  );
}

export const DateInput = forwardRef<HTMLInputElement, DateInputProps>(
  DateInputComponent,
);
