import { PropsWithChildren, useMemo, useReducer } from 'react';
import { moreInfoRequiredReducer } from './reducers';
import { defaultUserContext, UserContext } from './userContext';

export function UserProvider({ children }: PropsWithChildren) {
  const [moreInfoRequired, moreInfoRequiredDispatch] = useReducer(
    moreInfoRequiredReducer,
    defaultUserContext.moreInfoRequired,
  );

  const contextValue = useMemo(
    () => ({
      moreInfoRequired,
      moreInfoRequiredDispatch,
    }),
    [moreInfoRequired],
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
}
