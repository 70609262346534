import { transformUnits } from './common';

const acresPerHectare = 2.4710538147;
const squareMetersPerHectare = 10000;

export const hectaresToAcres = transformUnits(
  (hectares) => hectares * acresPerHectare,
);

export const acresToHectares = transformUnits(
  (acres) => acres / acresPerHectare,
);

export const squareMetersToHectares = transformUnits(
  (squareMeters) => squareMeters / squareMetersPerHectare,
);

export const hectaresToSquareMeters = transformUnits(
  (hectares) => hectares * squareMetersPerHectare,
);
