import { Title } from '@mantine/core';
import { useSessionStorage } from '@mantine/hooks';
import { closeAllModals, openContextModal } from '@mantine/modals';
import { useEffect } from 'react';

export const usePaymentNudgeModal = () => {
  const [showPaymentNudge, setShowPaymentNudge] = useSessionStorage<
    boolean | null
  >({
    key: 'show-payment-nudge',
    defaultValue: null,
  });

  useEffect(() => {
    if (showPaymentNudge === false) {
      closeAllModals();
    }
  }, [showPaymentNudge]);

  return () => {
    openContextModal({
      modal: 'paymentNudge',
      title: <Title order={4}>Outstanding Invoice</Title>,
      innerProps: {},
      onClose: () => {
        setShowPaymentNudge(false);
      },
    });
  };
};
