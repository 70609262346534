import { Badge, Button, ButtonProps, Flex, Text } from '@mantine/core';
import Link from 'next/link';
import { Route } from 'nextjs-routes';
import { ComponentProps } from 'react';
import styles from './HeaderMenu.module.scss';

type HeaderMenuProps = {
  buttons: {
    [key: string]: {
      label: string;
      subLabel?: string;
      subLabelDetail?: string;
      hasAdvisor: boolean;
      isActive: boolean;
      buttonProps?: ButtonProps;
    } & (
      | {
          route: Route;
          externalLink?: never;
        }
      | {
          route?: never;
          externalLink: string;
        }
    );
  };
};

export function HeaderMenu({ buttons }: HeaderMenuProps) {
  return (
    <Button.Group className={styles.group}>
      {Object.entries(buttons).map(
        ([
          key,
          {
            label,
            subLabel,
            subLabelDetail,
            hasAdvisor,
            route,
            isActive,
            buttonProps,
            externalLink,
          },
        ]) => {
          const baseProps = {
            'data-active': isActive || undefined,
            classNames: {
              root: styles.button,
              rightIcon: styles.rightIcon,
              label: styles.label,
            },
          };

          const linkProps = externalLink
            ? { component: 'a' as const, href: externalLink }
            : { component: Link, href: route };

          const props: ComponentProps<typeof Button> = {
            ...baseProps,
            ...linkProps,
            ...buttonProps,
          };

          return (
            <Button key={key} {...props}>
              <Flex
                direction="column"
                align="end"
                className={styles.buttonColumn}
              >
                {label}
                {subLabel && (
                  <Flex direction="row" gap={0} align="center">
                    <Text className={styles.subLabel}>{subLabel}</Text>
                    <Text className={styles.subLabelDetail}>
                      {subLabelDetail}
                    </Text>
                    {hasAdvisor ? (
                      <Badge className={styles.clientBadge}>Client</Badge>
                    ) : undefined}
                  </Flex>
                )}
              </Flex>
            </Button>
          );
        },
      )}
    </Button.Group>
  );
}
