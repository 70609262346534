import merge from 'deepmerge';
import { scalarTypePolicies } from '..';

const livestockFields = {
  livestockTransactionEvents: {
    merge(_existing: unknown[], incoming: unknown[]) {
      return incoming;
    },
  },
};

const localTypePolicies = {
  ...scalarTypePolicies,
  ActivityAllocation: {
    keyFields: ['sourceActivity', ['id'], 'targetActivity', ['id']],
  },
  ConsumptionAllocation: {
    keyFields: ['sourceConsumption', ['id'], 'targetActivity', ['id']],
  },
  ParcelInProperty: {
    keyFields: ['propertyId', 'parcelId'],
  },
  ComparableSale: {
    keyFields: ['propertyId', 'saleId'],
  },
  /* We need to set an explicit policy for livestockTransactionEvents arrays because the monthly forms make direct updates
   * of these arrays and their contents. The policy here is just to replace the array with the incoming one. */
  BeefMonthLivestockActivity: {
    fields: livestockFields,
  },
  GoatMonthLivestockActivity: {
    fields: livestockFields,
  },
  PorkMonthLivestockActivity: {
    fields: livestockFields,
  },
  SheepMonthLivestockActivity: {
    fields: livestockFields,
  },
  DairyMonthLivestockActivity: {
    fields: livestockFields,
  },
};

export const typePolicies = merge(localTypePolicies, scalarTypePolicies);
