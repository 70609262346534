// eslint-disable-next-line no-restricted-imports
import { useForm as uiUseForm } from '@agtuary/ui/forms/Form';
import { useAccessRights } from 'hooks/useAccessRights';
import { useSession } from 'next-auth/react';
import { FieldValues } from 'react-hook-form';

type UseFormProps<T extends FieldValues, C> = Parameters<
  typeof uiUseForm<T, C>
>[0];

export function useForm<
  TFieldValues extends FieldValues = FieldValues,
  TContext = unknown,
>({
  protectUnsavedChanges = true,
  ...rest
}: UseFormProps<TFieldValues, TContext>): ReturnType<
  typeof uiUseForm<TFieldValues, TContext>
> {
  const { status } = useSession();
  const innerProtectUnsavedChanges =
    protectUnsavedChanges && status === 'authenticated';

  const { canEdit } = useAccessRights();

  return uiUseForm({
    protectUnsavedChanges: innerProtectUnsavedChanges,
    disabled: !canEdit,
    ...rest,
  });
}
