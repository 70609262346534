import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useHydratedStore } from './helpers/useHydratedStore';

type TutorialStoreState = {
  mapIntroSeen: boolean;
};

type TutorialStore = TutorialStoreState & {
  setMapIntroSeen: (seen: boolean) => void;
};

const initialState: TutorialStoreState = {
  mapIntroSeen: false,
};

// Appropriate values before hydration.
// e.g. if we dont want a modal to show if the user has already seen it,
// we use true here but false in initialState.
const dehydratedState = {
  ...initialState,
  mapIntroSeen: true,
};

export const useTutorialStore = create(
  persist<TutorialStore>(
    (set) => ({
      ...initialState,
      setMapIntroSeen: (seen: boolean) => set({ mapIntroSeen: seen }),
    }),
    {
      name: 'tutorial-state',
    },
  ),
);

// SSR safe store (state only, no actions)
export const useHydratedTutorialStore = (
  selector: (
    state: TutorialStoreState,
  ) => TutorialStoreState[keyof TutorialStoreState],
) => useHydratedStore(useTutorialStore, dehydratedState, selector);

// Helper functions to access outside of React components
export const resetTutorialStore = () => {
  useTutorialStore.persist.clearStorage();
};
