import { HTTP_STATUS_CODE } from '@agtuary/common/constants/statusCode';
import { GraphQLErrors, NetworkError } from '@apollo/client/errors';
import { ErrorResponse } from '@apollo/client/link/error';

export class NotFoundError extends Error {}

const isUnauthorizedNetworkError = (networkError: NetworkError | undefined) =>
  typeof networkError === 'object' &&
  networkError !== null &&
  'statusCode' in networkError &&
  networkError.statusCode === HTTP_STATUS_CODE.UNAUTHORIZED;

const isUnauthorizedGraphQLError = (graphQLErrors: GraphQLErrors | undefined) =>
  graphQLErrors?.some(
    (error) => error.extensions?.code === 'UNAUTHENTICATED',
  ) ?? false;

/**
 * Determines if the user should be logged out as a result of an Apollo error.
 */
const isErrorResponse = (error: unknown): error is ErrorResponse =>
  typeof error === 'object' &&
  error !== null &&
  'networkError' in error &&
  'graphQLErrors' in error;

export const shouldLogout = (error: unknown) =>
  isErrorResponse(error) &&
  (isUnauthorizedNetworkError(error.networkError) ||
    isUnauthorizedGraphQLError(error.graphQLErrors));

export const isNotFound = (error: unknown): error is NotFoundError =>
  error instanceof NotFoundError;
