import { useMantineTheme } from '@mantine/core';
import { openContextModal } from '@mantine/modals';

const ctx = 'onboard';

export const useOnboardModal = () => {
  const theme = useMantineTheme();

  return {
    ctx,
    open: () => {
      openContextModal({
        modal: ctx,
        size: 'lg',
        shadow: 'sm',
        styles: {
          content: {
            '&': {
              padding: 0,
            },
          },
        },
        overlayProps: {
          color: theme.other.colors.onboardBackground,
          opacity: 1,
        },
        withCloseButton: false,
        closeOnClickOutside: false,
        closeOnEscape: false,
        innerProps: undefined,
      });
    },
  };
};
