import { useMutation } from '@tanstack/react-query';
import { mutations } from '../../../mutations';
import { Invitation } from '../../../types/invitation';

export const useCreateInvitation = () =>
  useMutation<
    Invitation,
    unknown,
    Pick<
      Invitation,
      | 'invitationEmail'
      | 'invitationName'
      | 'teamId'
      | 'organisationId'
      | 'roleName'
    >
  >(mutations.invitations.create);
