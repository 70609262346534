import { useAcceptInvitation } from '@agtuary/api/hooks/invitations/mutations/useAcceptInvitation';
import { useInvitation } from '@agtuary/api/hooks/invitations/useInvitation';
import { routeId } from '@agtuary/common/helpers/encode/base62';
import { showError } from 'helpers/showError';
import { useCurrentTeamSubscription } from 'hooks/useCurrentTeamSubscription';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useTeamInvitesStore } from 'stores/TeamInvitesStore';
import { AcceptAdvisorInviteModal } from './AcceptAdvisorInviteModal';
import { AcceptTeamInviteModal } from './AcceptTeamInviteModal';

type AcceptTeamInviteModalProps = {
  invitationCode: string;
  closeModal: () => void;
};

export function AcceptInviteModal({
  invitationCode,
  closeModal,
}: AcceptTeamInviteModalProps) {
  const { data: invitation } = useInvitation(invitationCode);
  const { mutateAsync: triggerAccept } = useAcceptInvitation();
  const [isLoading, setIsLoading] = useState(false);
  const { isSubscribed: isCurrentTeamSubscribed } =
    useCurrentTeamSubscription();
  const router = useRouter();
  const removeInvite = useTeamInvitesStore((state) => state.removeInvite);

  const team = invitation?.team;
  const teamName = team?.organisation?.name
    ? `${team.organisation.name} / ${team.name}`
    : team?.name;

  useEffect(() => {
    removeInvite(invitationCode);
  }, [invitationCode, removeInvite]);

  const {
    user: { data: user },
  } = useCurrentUser();

  const acceptInvite = useCallback(() => {
    if (invitationCode && user?.id) {
      setIsLoading(true);
      triggerAccept({ invitationCode, userId: user.id })
        .then((data) => {
          if (data?.id) {
            closeModal();
          }
        })
        .catch(() => {
          showError('There was an error adding you to the team.');
        })
        .finally(() => {
          // If the users current team is not paid, switch to the new team
          if (
            !isCurrentTeamSubscribed ||
            invitation?.invitationType === 'advisor'
          ) {
            router.push({
              pathname: '/team/[teamId]',
              query: { teamId: routeId(team?.id) },
            });
          }
          setIsLoading(false);
        });
    }
  }, [
    closeModal,
    invitation?.invitationType,
    invitationCode,
    isCurrentTeamSubscribed,
    router,
    team?.id,
    triggerAccept,
    user?.id,
  ]);

  if (!invitation) {
    return null;
  }

  if (invitation.invitationType === 'advisor') {
    return (
      <AcceptAdvisorInviteModal
        invitation={invitation}
        teamName={teamName}
        onClose={closeModal}
        acceptInvite={acceptInvite}
        isLoading={isLoading}
      />
    );
  }

  return (
    <AcceptTeamInviteModal
      acceptInvite={acceptInvite}
      onClose={closeModal}
      teamName={teamName}
      isLoading={isLoading}
    />
  );
}
