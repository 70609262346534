import { useTeamSubscription } from './useTeamSubscription';
import { useCurrentUser } from './useCurrentUser';

export const useCurrentTeamSubscription = (): ReturnType<
  typeof useTeamSubscription
> => {
  const { currentTeam } = useCurrentUser();

  return useTeamSubscription(currentTeam);
};
