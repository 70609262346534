import { Checkbox, MantineStyleSystemProps } from '@mantine/core';
import { useToggle } from '@mantine/hooks';
import { cloneElement, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { RHFInputProps } from '../types';

type InputChildProps = MantineStyleSystemProps & RHFInputProps;

type OptionalInputProps = {
  checkboxLabel: string;
  children: React.ReactElement<InputChildProps>;
  initialValue: number | null | undefined;
};

export function OptionalInput({
  children,
  checkboxLabel,
  initialValue,
}: OptionalInputProps) {
  const [showInput, toggleShowInput] = useToggle([false, true]);

  useEffect(() => {
    const toggleEnabled = initialValue !== null && initialValue !== undefined;
    toggleShowInput(toggleEnabled);
  }, [initialValue, toggleShowInput]);

  const { setValue } = useFormContext();
  const fieldName = children.props.name;

  return (
    <>
      <Checkbox
        checked={showInput}
        label={checkboxLabel}
        onChange={({ target: { checked } }) => {
          toggleShowInput();
          if (!checked) {
            setValue(fieldName, null, { shouldDirty: true });
          }
        }}
      />
      {cloneElement(children, {
        display: showInput ? 'block' : 'none',
        validation: {
          ...children.props.validation,
          required: showInput,
        },
      })}
    </>
  );
}
