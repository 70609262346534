import {
  QueryClientProvider as BaseQueryClientProvider,
  QueryClient,
} from '@tanstack/react-query';
import { PropsWithChildren, useMemo } from 'react';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HTTP_STATUS_CODE } from '@agtuary/common/constants/statusCode';

type QueryClientProviderProps = {
  // devTools?: boolean;
  logout: () => void;
};

const queryClient = (logout: () => void) =>
  new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000, // 1 second
        refetchOnWindowFocus: 'always', // refetch even if stale
        // onError is deprecated and will be removed in v5
        // We use it only because we plan to remove the use of react-query
        // in favor of apollo-client very soon
        onError: (error) => {
          if (
            typeof error === 'object' &&
            error !== null &&
            'status' in error
          ) {
            if (error.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
              logout();
            }
          }
        },
      },
    },
  });

export function QueryClientProvider({
  children,
  // devTools = false,
  logout,
}: PropsWithChildren<QueryClientProviderProps>) {
  const client = useMemo(() => queryClient(logout), [logout]);

  return (
    <BaseQueryClientProvider client={client}>
      {children}
      {/* {devTools && <ReactQueryDevtools initialIsOpen={false} />} */}
    </BaseQueryClientProvider>
  );
}
