import { FeatureFlags } from 'feature-flags';
// eslint-disable-next-line no-restricted-imports -- This is the hook extending it
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import { useCurrentUser } from './useCurrentUser';

/**
 * A hook that returns the feature flags for the current user.
 * @returns The feature flags for the current user.
 */
export const useFlags = (): FeatureFlags => {
  // Ensure whenever we are using feature flags, the context is configured.
  useCurrentUser();

  return useLDFlags<FeatureFlags>();
};
