import { useCallback, useContext } from 'react';
import { Property } from '@agtuary/api/types/property';
import { Report } from '@agtuary/api/types/report';
import { ReportViewDrawerContext } from './ReportViewDrawerContext';
import { ReportViewDrawerOnClose } from './types';

export const useReportViewDrawer = () => {
  const { setReport, setProperty, setOnClose } = useContext(
    ReportViewDrawerContext,
  );

  const show = useCallback(
    (report: Report, property: Property, onClose?: ReportViewDrawerOnClose) => {
      setReport(report);
      setProperty(property);
      setOnClose(onClose ?? null);
    },
    [setReport, setProperty, setOnClose],
  );

  const hide = useCallback(() => {
    setReport(null);
    setProperty(null);
    setOnClose(null);
  }, [setReport, setProperty, setOnClose]);

  return {
    show,
    hide,
  };
};
