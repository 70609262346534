import { createQueryKeys } from '@lukemorales/query-key-factory';
import { fetcher } from '../helpers/clientSide/requests';

export const teams = createQueryKeys('teams', {
  one: (teamId: string) => ({
    queryKey: [teamId],
    queryFn: () => fetcher(`/api/teams/${teamId}`),
    contextQueries: {
      jobs: {
        queryKey: null,
        queryFn: () => fetcher(`/api/teams/${teamId}/jobs`),
      },
      users: {
        queryKey: null,
        queryFn: () => fetcher(`/api/teams/${teamId}/roles`),
      },
    },
  }),
});
