import { MantineThemeOverride } from '@mantine/core';

export const Header: MantineThemeOverride['components'] = {
  Header: {
    styles: () => ({
      root: {
        borderColor: 'var(--mantine-color-border)',
      },
    }),
  },
};
