import { createQueryKeys } from '@lukemorales/query-key-factory';
import { fetcher } from '../helpers/clientSide/requests';
import { QueryParams } from '../types/request';

export const mapSearch = createQueryKeys('mapSearch', {
  search: (filters: QueryParams) => ({
    queryKey: [{ filters }],
    queryFn: () => fetcher(['/api/map-search', filters]),
  }),
  addressAtCoordinates: (latitude: number, longitude: number) => ({
    queryKey: [latitude, longitude],
    queryFn: () => fetcher(`/api/map-search/address/${latitude}/${longitude}`),
  }),
});
