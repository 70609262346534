import { Text } from '@mantine/core';
import { NotificationProps, showNotification } from '@mantine/notifications';

type ShowSuccessOptions =
  | string
  | (Omit<NotificationProps, 'title' | 'message'> &
      (
        | {
            title: string;
            message?: string;
          }
        | {
            title?: string;
            message: string;
          }
      ));

export const showSuccess = (options: ShowSuccessOptions) => {
  const { title, message, ...props } =
    typeof options === 'string'
      ? { title: options, message: undefined }
      : options;

  showNotification({
    title: title ?? 'Success!',
    message: message && <Text>{message}</Text>,
    color: 'brand.5',
    ...props,
  });
};
