import { Box, Title } from '@mantine/core';
import { HEADER_PAGE_TITLE_HEIGHT } from 'constants/layout';
import { ReactNode } from 'react';

export type PageHeaderProps = {
  children?: ReactNode;
  title?: string;
};

// NOTE: The styles below are so the title is present for screen readers, but visually hidden

export function PageHeader({ children, title }: PageHeaderProps) {
  return (
    <Box>
      {children}
      {title && (
        <Title
          className="visually-hidden"
          order={3}
          h={HEADER_PAGE_TITLE_HEIGHT}
        >
          {title}
        </Title>
      )}
    </Box>
  );
}
