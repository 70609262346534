import {
  Validate,
  ValidationRule,
  ValidationValueMessage,
} from 'react-hook-form';

export const isRuleWithMessage = (
  value?: ValidationRule,
): value is ValidationValueMessage => {
  return typeof value === 'object' && 'message' in value;
};

export type ValidationOption = boolean | { value: number; message: string };

export type RHFValidationOptions = {
  validate: Validate<unknown, unknown>;
  required: ValidationRule<boolean>;
  min: ValidationRule<number | string>;
  max: ValidationRule<number | string>;
  gt0: ValidationRule<boolean>;
};

export type ValidationOptions = Partial<RHFValidationOptions>;

export type RHFInputProps = {
  name: string;
  validation?: ValidationOptions;
  optionalInLabel?: boolean;
  warning?: string;
} & (
  | { label: string | null }
  | {
      // If the input is hidden, it doesn't need a label
      type: 'hidden';
      label?: never;
    }
);

export type OptionsWithPlaceholder<T> = { value: T | ''; label: string }[];
