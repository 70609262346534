import { createQueryKeys } from '@lukemorales/query-key-factory';
import { fetcher } from '../helpers/clientSide/requests';

export const jobs = createQueryKeys('jobs', {
  all: {
    queryKey: null,
    queryFn: () => fetcher('/api/jobs'),
  },
  one: (jobId: string) => ({
    queryKey: [jobId],
    queryFn: () => fetcher(`/api/jobs/${jobId}`),
    contextQueries: {
      properties: {
        queryKey: null,
        queryFn: () => fetcher(`/api/jobs/${jobId}/properties`),
        contextQueries: {
          one: (propertyId: string) => ({
            queryKey: [propertyId],
            queryFn: () =>
              fetcher(`/api/jobs/${jobId}/properties/${propertyId}`),
            contextQueries: {
              spatialFiles: {
                queryKey: null,
                queryFn: () =>
                  fetcher(
                    `/api/jobs/${jobId}/properties/${propertyId}/spatial-files`,
                  ),
              },
              propertyNotes: {
                queryKey: null,
                queryFn: () =>
                  fetcher(
                    `/api/jobs/${jobId}/properties/${propertyId}/property-notes`,
                  ),
                contextQueries: {
                  one: (propertyNoteId: string) => ({
                    queryKey: [propertyNoteId],
                    queryFn: () =>
                      fetcher(
                        `/api/jobs/${jobId}/properties/${propertyId}/property-notes/${propertyNoteId}`,
                      ),
                  }),
                },
              },
              inspections: {
                queryKey: null,
                queryFn: () =>
                  fetcher(
                    `/api/jobs/${jobId}/properties/${propertyId}/inspections`,
                  ),
                contextQueries: {
                  one: (inspectionId: string) => ({
                    queryKey: [inspectionId],
                    queryFn: () =>
                      fetcher(
                        `/api/jobs/${jobId}/properties/${propertyId}/inspections/${inspectionId}`,
                      ),
                    contextQueries: {
                      files: {
                        queryKey: null,
                        queryFn: () =>
                          fetcher(
                            `/api/jobs/${jobId}/properties/${propertyId}/inspections/${inspectionId}/files`,
                          ),
                        contextQueries: {
                          one: (fileId: string) => ({
                            queryKey: [fileId],
                            queryFn: () =>
                              fetcher(
                                `/api/jobs/${jobId}/properties/${propertyId}/inspections/${inspectionId}/files/${fileId}`,
                              ),
                          }),
                        },
                      },
                      assets: {
                        queryKey: null,
                        queryFn: () =>
                          fetcher(
                            `/api/jobs/${jobId}/properties/${propertyId}/inspections/${inspectionId}/assets`,
                          ),
                        contextQueries: {
                          one: (assetId: string) => ({
                            queryKey: [assetId],
                            queryFn: () =>
                              fetcher(
                                `/api/jobs/${jobId}/properties/${propertyId}/inspections/${inspectionId}/assets/${assetId}`,
                              ),
                            contextQueries: {
                              files: {
                                queryKey: null,
                                queryFn: () =>
                                  fetcher(
                                    `/api/jobs/${jobId}/properties/${propertyId}/inspections/${inspectionId}/assets/${assetId}/files`,
                                  ),
                                contextQueries: {
                                  one: (fileId: string) => ({
                                    queryKey: [fileId],
                                    queryFn: () =>
                                      fetcher(
                                        `/api/jobs/${jobId}/properties/${propertyId}/inspections/${inspectionId}/assets/${assetId}/files/${fileId}`,
                                      ),
                                  }),
                                },
                              },
                            },
                          }),
                        },
                      },
                      landUses: {
                        queryKey: null,
                        queryFn: () =>
                          fetcher(
                            `/api/jobs/${jobId}/properties/${propertyId}/inspections/${inspectionId}/land-uses`,
                          ),
                        contextQueries: {
                          one: (landUseId: string) => ({
                            queryKey: [landUseId],
                            queryFn: () =>
                              fetcher(
                                `/api/jobs/${jobId}/properties/${propertyId}/inspections/${inspectionId}/land-uses/${landUseId}`,
                              ),
                            contextQueries: {
                              files: {
                                queryKey: null,
                                queryFn: () =>
                                  fetcher(
                                    `/api/jobs/${jobId}/properties/${propertyId}/inspections/${inspectionId}/land-uses/${landUseId}/files`,
                                  ),
                                contextQueries: {
                                  one: (fileId: string) => ({
                                    queryKey: [fileId],
                                    queryFn: () =>
                                      fetcher(
                                        `/api/jobs/${jobId}/properties/${propertyId}/inspections/${inspectionId}/land-uses/${landUseId}/files/${fileId}`,
                                      ),
                                  }),
                                },
                              },
                            },
                          }),
                        },
                      },
                    },
                  }),
                },
              },
              files: {
                queryKey: null,
                queryFn: () =>
                  fetcher(`/api/jobs/${jobId}/properties/${propertyId}/files`),
                contextQueries: {
                  one: (fileId: string) => ({
                    queryKey: [fileId],
                    queryFn: () =>
                      fetcher(
                        `/api/jobs/${jobId}/properties/${propertyId}/files/${fileId}`,
                      ),
                    contextQueries: {
                      signedUrl: {
                        queryKey: null,
                        queryFn: () =>
                          fetcher(
                            `/api/jobs/${jobId}/properties/${propertyId}/files/${fileId}/signed-url`,
                          ),
                      },
                    },
                  }),
                },
              },
            },
          }),
        },
      },
    },
  }),
  // one: (jobId: string) => ({
  //   queryKey: [userId],
  //   queryFn: () => fetcher(`/api/users/${userId}`),
  //   contextQueries: {
  //     external: {
  //       queryKey: null,
  //       queryFn: () => fetcher(`/api/users/external/${userId}`),
  //     },
  //     organisations: {
  //       queryKey: null,
  //       queryFn: () => fetcher(`/api/users/${userId}/organisations`),
  //     },
  //     teams: {
  //       queryKey: null,
  //       queryFn: () => fetcher(`/api/users/${userId}/teams`),
  //     },
  //   },
  // }),
});
