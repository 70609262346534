import { MantineThemeOverride, ModalProps } from '@mantine/core';

export const Modal: MantineThemeOverride['components'] = {
  Modal: {
    defaultProps: (): Partial<ModalProps> => ({
      size: 'lg',
      overlayProps: {
        blur: 1,
        opacity: 0.1,
      },
      centered: true,
    }),
    styles: (theme) => ({
      header: { alignItems: 'start' },
      title: {
        fontWeight: 700,
        fontSize: theme.fontSizes.xl,
      },
    }),
  },
};
