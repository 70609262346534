import { Organisation } from '@agtuary/api/types/organisation';
import { Team } from '@agtuary/api/types/team';
import { User } from '@agtuary/api/types/user';

export const needsMoreInfoMinimumRequiredFields: {
  user: Array<keyof User>;
  team: Array<keyof Team>;
  'team.organisation': Array<keyof Organisation>;
} = {
  user: ['firstName', 'lastName', 'email', 'mobile', 'termsAcceptedAt'],
  team: [],
  'team.organisation': ['name'],
};

export enum MORE_INFO_REQUIRED_ACTION_TYPE {
  Check,
}
