import { Header as BaseHeader, Box, Group } from '@mantine/core';
import { NavigationProgress } from 'components/NavigationProgress';
import { HEADER_HEIGHT_SLIM } from 'constants/layout';
import { useContext } from 'react';
import { RootLayoutContext } from '../../context/RootLayoutContext';
import styles from './Header.module.scss';
import { HeaderLogoLink } from './components/HeaderLogoLink/HeaderLogoLink';
import { MainHeaderMenu } from './components/MainHeaderMenu/MainHeaderMenu';
import { SecondaryHeaderMenu } from './components/SecondaryHeaderMenu';

export function Header() {
  const { headerContentBoxId } = useContext(RootLayoutContext);

  return (
    <BaseHeader height={HEADER_HEIGHT_SLIM} className={styles.header}>
      <Group position="apart" className={styles.group} grow>
        <Box className={styles.section}>
          <Box className={styles.sectionContents}>
            <Group className={styles.logoBreadcrumbGroup} spacing="xs">
              <HeaderLogoLink />
              <MainHeaderMenu />
              <Box id={headerContentBoxId} />
            </Group>
          </Box>
        </Box>
        <Box className={styles.section}>
          <Box className={styles.sectionContents}>
            <SecondaryHeaderMenu />
          </Box>
        </Box>
      </Group>
      <NavigationProgress className={styles.progress} />
    </BaseHeader>
  );
}
