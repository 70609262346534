import { Anchor, Card, Text } from '@mantine/core';
import { getApplicationMode } from 'helpers/applicationMode';

export function DeleteProfileModal() {
  const {
    options: { bulkDeleteEmail },
  } = getApplicationMode();

  return (
    <Card w="100%" p="xl">
      <Card.Section>
        <Text component="p" mt={0} fw={600}>
          Please email us at{' '}
          <Anchor
            variant="text"
            href={`mailto: ${bulkDeleteEmail}`}
            color="brand.7"
          >
            {bulkDeleteEmail}
          </Anchor>{' '}
          requesting deletion of your profile.
        </Text>
        <Text component="p" mt={0}>
          Once your profile is deleted, the organisation and the team that were
          created when you first signed up will also be deleted (along with any
          properties and associated data).
        </Text>
        <Text component="p" mt={0}>
          Any data (like properties) created by you in teams you had been
          <Text component="s" fs="italic">
            &nbsp;invited to
          </Text>{' '}
          will stay active and still be accessible to other team members.
        </Text>
        <Text component="p" mt={0}>
          If reports generated in this platform have been shared with third
          parties, they may request to see the data used to generate those
          reports. We encourage requesting a backup of your data before
          deleting.
        </Text>
      </Card.Section>
    </Card>
  );
}
