import { createMutationKeys } from '@lukemorales/query-key-factory';
import {
  deleteMutator,
  patchMutator,
  postMutator,
} from '../helpers/clientSide/requests';
import { Report } from '../types/report';

export const reports = createMutationKeys('reports', {
  one: (reportId: string) => ({
    mutationKey: [reportId],
    mutationFn: (arg: Partial<Report>) =>
      patchMutator(`/api/reports/${reportId}/fe`, { arg }),
    contextMutations: {
      share: {
        mutationKey: null,
        mutationFn: (arg: { name: string; target: string }) =>
          postMutator(`/api/reports/${reportId}/share`, {
            arg,
          }),
      },
      delete: {
        mutationKey: null,
        mutationFn: (arg: object) =>
          deleteMutator(`/api/reports/${reportId}/delete`, { arg }),
      },
    },
  }),
});
