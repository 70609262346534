import { getLDContext } from 'helpers/featureFlags/getLDContext';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { PropsWithChildren, useEffect, useMemo } from 'react';

export function LaunchDarklyFetcher({ children }: PropsWithChildren) {
  const { user, currentTeam, teams } = useCurrentUser();

  // A list of all unique team IDs for the current user
  const teamIds = useMemo(
    () => teams?.data?.map((team) => team.id),
    [teams?.data],
  );

  const ldClient = useLDClient();
  useEffect(() => {
    const ldContext =
      user?.data?.id && teamIds && currentTeam?.id
        ? getLDContext(user?.data)
        : undefined;

    if (ldClient && ldContext) {
      ldClient?.identify(ldContext);
    }
  }, [currentTeam?.id, ldClient, teamIds, user?.data]);

  return children;
}
