import {
  Flex,
  Radio as MTRadio,
  RadioGroupProps as MTRadioGroupProps,
} from '@mantine/core';
import { ForwardedRef, forwardRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { DEFAULT_INPUT_WRAPPER_ORDER } from '../constants';
import formStyles from '../formStyles.module.scss';
import { useFormLogic } from '../hooks';
import { RHFInputProps } from '../types';
import styles from './RadioGroup.module.scss';

export type RadioOption = {
  value: string;
  label: string;
};
export type RadioGroupProps = {
  options: RadioOption[];
} & Omit<MTRadioGroupProps, 'type' | 'children'> &
  Omit<RHFInputProps, 'type'>;

export function RadioGroupComponent(
  props: RadioGroupProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { options, name, onChange } = props;
  const { control } = useFormContext();

  const { error, label, validationRules } = useFormLogic({
    ...props,
  });

  const { field } = useController({ name, control, rules: validationRules });

  return (
    <MTRadio.Group
      classNames={{ root: formStyles.root }}
      inputWrapperOrder={DEFAULT_INPUT_WRAPPER_ORDER}
      {...props}
      {...field}
      label={label}
      error={error}
      onChange={(value) => {
        field.onChange({ target: { value } });
        if (onChange) {
          onChange(value);
        }
      }}
      ref={ref}
    >
      <Flex direction="column">
        {options.map((option) => (
          <MTRadio
            classNames={{ root: styles.root }}
            key={option.value}
            value={option.value}
            label={option.label}
            disabled={field.disabled}
          />
        ))}
      </Flex>
    </MTRadio.Group>
  );
}

export const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(
  RadioGroupComponent,
);
