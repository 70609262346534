import { createMutationKeys } from '@lukemorales/query-key-factory';
import { postMutator } from '../helpers/clientSide/requests';
import { Invitation } from '../types/invitation';
import { ENVIRONMENT_URL } from '../constants/environment';

export const invitations = createMutationKeys('invitations', {
  create: {
    mutationKey: null,
    mutationFn: (
      arg: Pick<
        Invitation,
        'invitationEmail' | 'invitationName' | 'teamId' | 'organisationId'
      >,
    ) =>
      postMutator('/api/invitations', {
        arg: {
          ...arg,
          invitationUrl: `${ENVIRONMENT_URL}/p/teams/{inviteCode}/invited`,
        },
      }),
  },
  accept: {
    mutationKey: null,
    mutationFn: (arg: { invitationCode: string; userId: string }) =>
      postMutator('/api/roles', {
        arg,
      }),
  },
});
