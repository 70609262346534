// eslint-disable-next-line no-restricted-imports -- this wrap of @agtuary/ui Form is preferred for all of apps/web
import { Form as UIForm } from '@agtuary/ui/forms/Form';
import { useAccessRights } from 'hooks/useAccessRights';
import { useSession } from 'next-auth/react';
import { ComponentProps } from 'react';
import { FieldValues } from 'react-hook-form';

type FormProps<T extends FieldValues> = ComponentProps<typeof UIForm<T>>;

export function Form<T extends FieldValues>({
  protectUnsavedChanges = true,
  ...rest
}: FormProps<T>) {
  const { status } = useSession();
  const innerProtectUnsavedChanges =
    protectUnsavedChanges && status === 'authenticated';
  const { canEdit } = useAccessRights();
  return (
    <UIForm
      protectUnsavedChanges={innerProtectUnsavedChanges}
      disabled={!canEdit}
      {...rest}
    />
  );
}
