import { Organisation } from '@agtuary/api/types/organisation';
import { Team } from '@agtuary/api/types/team';
import { Title } from '@mantine/core';
import { openContextModal } from '@mantine/modals';

export const useTeamInviteModal =
  () =>
  (
    team: Team | undefined,
    organisation: Organisation | null | undefined,
    closeOnSuccess?: boolean,
  ) => {
    openContextModal({
      modal: 'teamInvite',
      title: <Title order={4}>Team Member Invite</Title>,
      innerProps: {
        team,
        organisation,
        closeOnSuccess,
      },
    });
  };
