import { Button, ButtonProps, Stack } from '@mantine/core';
import { isRouteActive } from 'helpers/routes/is-active';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Route } from 'nextjs-routes';
import styles from './MainHeaderMenuItems.module.scss';

type MainHeaderMenuItemsProps = {
  targets: {
    [key: string]: {
      label: string;
      route: Route;
      buttonProps?: ButtonProps;
    };
  };
};

export function MainHeaderMenuItems({ targets }: MainHeaderMenuItemsProps) {
  const { route: currentRoute } = useRouter();
  return (
    <Stack className={styles.group}>
      {Object.entries(targets).map(([key, { label, route, buttonProps }]) => (
        <Button
          key={key}
          component={Link}
          href={route}
          data-active={isRouteActive(currentRoute, route.pathname)}
          classNames={{
            root: styles.button,
            rightIcon: styles.rightIcon,
          }}
          {...buttonProps}
        >
          {label}
        </Button>
      ))}
    </Stack>
  );
}
