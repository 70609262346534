import { createMutationKeys } from '@lukemorales/query-key-factory';
import { patchMutator } from '../helpers/clientSide/requests';
import { User } from '../types/user';

export const users = createMutationKeys('users', {
  one: (userId: string) => ({
    mutationKey: [userId],
    mutationFn: (arg: Partial<User>) =>
      patchMutator(`/api/users/${userId}`, { arg }),
  }),
});
