import { useShareReport } from '@agtuary/api/hooks/reports/mutations/useShareReport';
import { Button, Flex, Space, Text, TextInput } from '@mantine/core';
// eslint-disable-next-line no-restricted-imports
import { useForm } from '@mantine/form';
import { ContextModalProps } from '@mantine/modals';
import { showError } from 'helpers/showError';
import { showSuccess } from 'helpers/showSuccess';
import { validateEmail } from 'helpers/validation/validation';
import { Sharable } from './types';

type ShareModalInnerProps = {
  sharable: Sharable;
};

export function ShareModal({
  context,
  id,
  innerProps: { sharable },
}: ContextModalProps<ShareModalInnerProps>) {
  const { isLoading, mutateAsync: trigger } = useShareReport(sharable.id);

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
  };

  const form = useForm({
    initialValues,
    validate: {
      email: (value) =>
        validateEmail(value, 'Please enter a valid email address.'),
    },
    validateInputOnBlur: true,
  });

  const submit = form.onSubmit(({ firstName, lastName, email }) => {
    if (form.isDirty() && form.isTouched() && form.isValid()) {
      trigger({
        name: `${firstName} ${lastName}`,
        target: email,
      })
        .then((data) => {
          if (data?.sent) {
            form.resetDirty();
            form.resetTouched();
            context.closeModal(id);
            form.setValues(initialValues);
            showSuccess({
              title: 'Report Sent',
              message: `The report for ${sharable?.name} has been sent to ${firstName} ${lastName}.`,
            });
          }
        })
        .catch(() => {
          showError('There was an error sending the report.');
        });
    }
  });

  return (
    <>
      <Text>Send the interactive report for: {sharable?.name}</Text>
      <Space h="md" />
      <form onSubmit={submit}>
        <Flex gap="md" direction="column">
          <TextInput
            placeholder="Recipients first name"
            label="First Name"
            required
            {...form.getInputProps('firstName')}
          />
          <TextInput
            placeholder="Recipients last name"
            label="Last Name"
            required
            {...form.getInputProps('lastName')}
          />
          <TextInput
            placeholder="Recipients email"
            label="Email"
            required
            {...form.getInputProps('email')}
          />
          <Flex justify="end">
            <Button type="submit" loading={isLoading} loaderPosition="center">
              Send
            </Button>
          </Flex>
        </Flex>
      </form>
    </>
  );
}
