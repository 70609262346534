import { MantineThemeOverride } from '@mantine/core';

export const shadows: MantineThemeOverride = {
  // Values used for box-shadow
  shadows: {
    md: '1px 1px 3px rgba(0, 0, 0, .25)',
    xl: '5px 5px 3px rgba(0, 0, 0, .25)',
  },
};

export const shadowCSSVariables = {
  '--mantine-shadow-md': '1px 1px 3px rgba(0, 0, 0, .25)',
  '--mantine-shadow-xl': '0px 2px 5px rgba(0, 0, 0, .25)',
};
