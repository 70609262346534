import baseX from 'base-x';
import { UUID } from 'crypto';
import isUUID from 'validator/lib/isUUID';
import { isNil } from '../nil';

/**
 * We can use base62 to shorten UUIDs to 22 characters and keep them URL-safe.
 */

const B62_ALPHABET =
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
const UUID_ENCODING: BufferEncoding = 'hex';

// Define a base62 encoding using the characters 0-9, a-z, and A-Z
const base62 = baseX(B62_ALPHABET);

const isUUIDv4 = (uuid: string): uuid is UUID => isUUID(uuid, 4);

/**
 * Encodes a string as a base62 string.
 * @param {string} input The input string to encode.
 * @param {BufferEncoding} [encoding='utf8'] The encoding of the input string (default: 'utf-8').
 * @returns {string} The input string encoded as a base62 string.
 */
export const b62Encode = (
  input: string,
  encoding: BufferEncoding = 'utf-8',
): string => {
  // Convert the input to a buffer and encode it as a base62 string
  const buffer = Buffer.from(input, encoding);
  return base62.encode(buffer);
};

/**
 * Decodes a base62 string to a string.
 * @param {string} input The base62 string to decode.
 * @param {BufferEncoding} [encoding='utf8'] The encoding of the output string (default: 'utf-8').
 * @returns {string} The base62 string decoded to a string.
 * @throws {Error} If the input is not a valid base62 string.
 */
export const b62Decode = (
  input: string,
  encoding: BufferEncoding = 'utf-8',
): string => {
  // Decode the base62 string to a buffer and convert it to a string
  const buffer = Buffer.from(base62.decode(input));
  return buffer.toString(encoding);
};

/**
 * Encodes a UUID as a base62 string.
 * @param {string} uuid The UUID to encode.
 * @returns {string} The UUID encoded as a base62 string.
 */
export const b62EncodeUUID = (uuid: string): string => {
  // Remove hyphens from the UUID
  const input = uuid.replace(/-/g, '');

  // Encode the UUID as a base62 string and return it
  return b62Encode(input, UUID_ENCODING);
};

/**
 * Decodes a base62 string to a UUID.
 * @param {string} b62 The base62 string to decode.
 * @returns {UUID} The base62 string decoded to a UUID.
 * @throws {Error} If the input is not a valid base62 string.
 */
export const b62DecodeUUID = (b62: string): UUID => {
  // Decode the base62 string to a buffer and convert it to a string
  const decoded = b62Decode(b62, UUID_ENCODING);

  // Insert hyphens into the UUID and return it
  return `${decoded.slice(0, 8)}-${decoded.slice(8, 12)}-${decoded.slice(
    12,
    16,
  )}-${decoded.slice(16, 20)}-${decoded.slice(20)}`;
};

/**
 * Decodes a base62 string to a string. Returns undefined if the input is not a valid base62 string.
 * @param {string} b62 The base62 string to decode.
 * @returns {string | undefined} The base62 string decoded to a string, or undefined.
 */
export const b62DecodeUnsafe = (b62: string): string | undefined => {
  try {
    return b62Decode(b62);
  } catch (err) {
    return undefined;
  }
};

/**
 * Decodes a base62 string to a UUID. Returns undefined if the input is not a valid base62 string.
 * @param {string} b62 The base62 string to decode.
 * @returns {string | undefined} The base62 string decoded to a UUID, or undefined.
 */
export const b62DecodeUUIDUnsafe = (b62: string): UUID | undefined => {
  try {
    return b62DecodeUUID(b62);
  } catch (err) {
    return undefined;
  }
};

/**
 * Decodes a base62 string to a UUID. Returns the original string if the input is not a valid
 * base62 string.
 * @param {string} b62 The base62 string to decode.
 * @returns {string} The base62 string decoded to a UUID, or the original string.
 */
export const b62DecodeUUIDUnsafeOrOriginal = (b62: string): UUID | string =>
  b62DecodeUUIDUnsafe(b62) ?? b62;

/**
 * Encodes a UUID as a base62 string. Returns the original string if the input is not a valid UUID.
 * Useful for encoding route IDs.
 * @param {string} uuid The UUID to encode.
 * @returns {string} The UUID encoded as a base62 string, or the original string.
 */
export const routeId = (uuid?: string | string[]) => {
  const strUuid = `${uuid}`;
  return isUUID(strUuid) ? b62EncodeUUID(strUuid) : strUuid;
};

/**
 * Reads a UUID from a route parameter that may be encoded as a base62 string.
 * @param {string} id The route parameter to read.
 * @returns {UUID | string} The decoded UUID, the original string, an empty string for empty inputs.
 */
export const readRouteId = (id?: string | string[] | null): UUID | string => {
  if (isNil(id)) {
    return '';
  }
  const strId = id.toString();
  if (isUUIDv4(strId)) {
    return strId;
  }

  const decoded = b62DecodeUUIDUnsafe(strId);
  if (decoded === undefined) {
    return strId;
  }

  return decoded;
};
