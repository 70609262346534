import { TeamIcon } from '@agtuary/ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  Modal,
  Space,
  Text,
  ThemeIcon,
  Title,
} from '@mantine/core';

type AcceptTeamInviteModalProps = {
  onClose: () => void;
  teamName: string | undefined;
  acceptInvite: () => void;
  isLoading: boolean;
};

export function AcceptTeamInviteModal({
  onClose,
  teamName,
  acceptInvite,
  isLoading,
}: AcceptTeamInviteModalProps) {
  return (
    <Modal opened onClose={onClose} title="New Team Invitation">
      <Box p="xs">
        <Center>
          <Flex direction="column" align="center" gap="sm">
            <Space h="lg" />
            <ThemeIcon radius="xl" size={60}>
              {/* TODO: use own icon */}
              <TeamIcon width={40} height={40} />
            </ThemeIcon>
            <Title order={5}>{teamName}</Title>
            <Text size="sm">
              You have been invited to join {teamName || 'a team'}
            </Text>
            <Space h="xl" />
          </Flex>
        </Center>
        <Flex justify="space-between">
          {/* REVISIT: Should we actually expire the invite if the user declines it? */}
          <Button variant="outline" color="gray" onClick={onClose}>
            Decline
          </Button>
          <Space w="md" />
          <Button color="brand" onClick={acceptInvite} loading={isLoading}>
            Accept
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
}
