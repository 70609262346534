import { useMergedRef } from '@mantine/hooks';
import { Decimal } from 'decimal.js-light';
import {
  ComponentProps,
  ForwardedRef,
  forwardRef,
  useCallback,
  useMemo,
} from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { BaseNumberInput } from '../NumberInput';
import { useFormattedDecimal } from '../format';
import { useFormLogic } from '../hooks';
import { RHFInputProps } from '../types';
import { buildValidationRules } from '../validation';

export type DecimalInputProps = RHFInputProps &
  ComponentProps<typeof BaseNumberInput>;

function DecimalInputComponent(
  props: DecimalInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { name, validation, onChange, ...innerProps } = props;
  const { control, setValue, formState } = useFormContext();
  const { isSubmitted } = formState;

  const { error, label } = useFormLogic({
    ...props,
    valueAsNumber: true,
  });

  const validationRules = useMemo(
    () => buildValidationRules(validation),
    [validation],
  );

  const { field } = useController({ name, control, rules: validationRules });

  const mergedRef = useMergedRef(ref, field.ref);

  const wrappedOnChange = useCallback(
    (value: number | '') => {
      const wrappedValue = value === '' ? null : new Decimal(value);
      setValue(name, wrappedValue, {
        shouldDirty: true,
        shouldValidate: isSubmitted,
      });
      onChange?.(value);
    },
    [isSubmitted, name, onChange, setValue],
  );

  const formattedValue = useFormattedDecimal(field);

  return (
    <BaseNumberInput
      precision={3}
      removeTrailingZeros
      {...innerProps}
      error={error}
      label={label}
      {...field}
      value={formattedValue}
      onChange={wrappedOnChange}
      ref={mergedRef}
    />
  );
}

export const DecimalInput = forwardRef<HTMLInputElement, DecimalInputProps>(
  DecimalInputComponent,
);
