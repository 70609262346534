import { Title } from '@mantine/core';
import { openContextModal } from '@mantine/modals';
import { NewJobModalInnerProps } from './NewJobModal';

const ctx = 'newJob';

export const useNewJobModal = () => ({
  ctx,
  open: (innerProps: NewJobModalInnerProps, onClose?: () => void) => {
    openContextModal({
      modal: ctx,
      title: <Title order={4}>New Job</Title>,
      innerProps,
      onClose,
    });
  },
});
