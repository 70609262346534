import { XIcon } from '@agtuary/ui/icons';
import { Button, ButtonProps } from '@mantine/core';
import { EventHandler, MouseEvent } from 'react';

type CancelButtonProps = {
  onClick: EventHandler<MouseEvent>;
  label?: string;
} & ButtonProps;

export function CancelButton({ onClick, label, ...props }: CancelButtonProps) {
  return (
    <Button
      {...props}
      variant="white"
      leftIcon={<XIcon width={10} height={10} />}
      onClick={onClick}
    >
      {label ?? 'Cancel'}
    </Button>
  );
}
