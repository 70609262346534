import { useMutation } from '@tanstack/react-query';
import { mutations } from '../../../mutations';
import { Role } from '../../../types/role';

export const useAcceptInvitation = () =>
  useMutation<
    Role,
    unknown,
    {
      invitationCode: string;
      userId: string;
    }
  >(mutations.invitations.accept);
