import { MantineThemeOverride } from '@mantine/core';

export const Breadcrumbs: MantineThemeOverride['components'] = {
  Breadcrumbs: {
    styles: (theme) => ({
      separator: {
        marginRight: 4,
        marginLeft: 4,
        fontSize: 'inherit',
      },
      breadcrumb: {
        color: theme.colors.gray[7],
        fontWeight: theme.other.fonts.weights.bold,
      },
    }),
  },
};
