import { simplify } from '@turf/turf';
import { MapImageBoundingBox, MapImageCoordinates, Viewport } from './types';

const mapboxMaxUrlLength = 8192;

const isBoundingBox = (viewport: Viewport): viewport is MapImageBoundingBox => {
  return (
    typeof viewport === 'object' &&
    'maxLatitude' in viewport &&
    'minLatitude' in viewport &&
    'maxLongitude' in viewport &&
    'minLongitude' in viewport
  );
};

const isCoordinates = (viewport: Viewport): viewport is MapImageCoordinates => {
  return (
    typeof viewport === 'object' &&
    'zoom' in viewport &&
    'coordinates' in viewport
  );
};

const createShortEnoughUrl = (
  {
    viewport,
    width,
    height,
    geoJSON,
  }: {
    viewport: string;
    width: number;
    height: number;
    geoJSON?: GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>;
  },
  attempt = 0,
): string => {
  const geoJsonSegment = geoJSON
    ? `/geojson(${encodeURIComponent(JSON.stringify(geoJSON))})`
    : '';
  const url = `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/static${geoJsonSegment}/${viewport}/${width}x${height}?attribution=false&logo=false&access_token=${process.env.NEXT_PUBLIC_MAPBOX_TOKEN}`;
  if (geoJSON && url.length > mapboxMaxUrlLength && attempt < 10) {
    return createShortEnoughUrl(
      {
        viewport,
        width,
        height,
        geoJSON: simplify(geoJSON, {
          tolerance: 0.01 * 2 ** attempt,
        }),
      },
      attempt + 1,
    );
  }
  return url;
};

export const getSrc = (
  viewport: Viewport,
  width: number,
  height: number,
  geoJSON?: GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>,
) => {
  const vp = (() => {
    if (isBoundingBox(viewport)) {
      const { maxLatitude, minLatitude, maxLongitude, minLongitude } = viewport;
      return `[${minLongitude},${minLatitude},${maxLongitude},${maxLatitude}]`;
    }
    if (isCoordinates(viewport)) {
      const {
        zoom,
        coordinates: { longitude, latitude },
      } = viewport;
      return `${longitude},${latitude},${zoom}`;
    }
    return 'auto';
  })();

  return createShortEnoughUrl({ viewport: vp, width, height, geoJSON });
};

// Mapbox required width and height to be between 1 and 1280.
export const legalSize = (size: number) => Math.min(Math.max(size, 1), 1280);
