import { Report } from '@agtuary/api/types/report';
import { useModals } from '@mantine/modals';
import { useShareModal } from 'components/modals/ShareModal';
import { useCallback } from 'react';

export function useShareReport(report?: Report): (report?: Report) => void {
  const { closeAll } = useModals();
  const openShareModal = useShareModal();
  return useCallback(
    (r?: Report) => {
      closeAll();
      if (!r && !report) return;
      openShareModal('Share Report', r ?? report);
    },
    [closeAll, openShareModal, report],
  );
}
