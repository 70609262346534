import { ValidationOptions } from './types';

export const VALIDATION_REQUIRED: ValidationOptions = { required: true };

export const VALIDATION_REQUIRED_GT_0: ValidationOptions = {
  required: true,
  gt0: true,
};

export const VALIDATION_REQUIRED_GT_0_MAX_99999999: ValidationOptions = {
  required: true,
  gt0: true,
  max: 99999999,
};

export const VALIDATION_REQUIRED_MIN_0: ValidationOptions = {
  required: true,
  min: 0,
};

export const VALIDATION_REQUIRED_MIN_0_MAX_99999999: ValidationOptions = {
  required: true,
  min: 0,
  max: 99999999,
};

export const VALIDATION_REQUIRED_MIN_1: ValidationOptions = {
  required: true,
  min: 1,
};

export const VALIDATION_REQUIRED_MIN_1_MAX_99999999: ValidationOptions = {
  required: true,
  min: 1,
  max: 99999999,
};

export const VALIDATION_OPTIONAL_MIN_0: ValidationOptions = {
  min: 0,
};

export const VALIDATION_OPTIONAL_MIN_0_MAX_99999999: ValidationOptions = {
  min: 0,
  max: 99999999,
};

export const VALIDATION_OPTIONAL_GT_0: ValidationOptions = {
  gt0: true,
};

export const VALIDATION_OPTIONAL_GT_0_MAX_99999999: ValidationOptions = {
  gt0: true,
  max: 99999999,
};
