import {
  TextInput as MTTextInput,
  TextInputProps as MTTextInputProps,
} from '@mantine/core';
import { useMergedRef } from '@mantine/hooks';
import { ForwardedRef, forwardRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { DEFAULT_INPUT_WRAPPER_ORDER } from '../constants';
import formStyles from '../formStyles.module.scss';
import { useFormLogic } from '../hooks';
import { RHFInputProps } from '../types';

type TextInputProps = RHFInputProps & MTTextInputProps;

function TextInputComponent(
  props: TextInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { name, ...innerProps } = props;
  const { control } = useFormContext();

  const { error, label, validationRules } = useFormLogic(props);

  const { field } = useController({ name, control, rules: validationRules });

  const mergedRef = useMergedRef(ref, field.ref);

  const { type } = props;

  return (
    <MTTextInput
      className={formStyles.root}
      inputWrapperOrder={DEFAULT_INPUT_WRAPPER_ORDER}
      withAsterisk={false}
      {...innerProps}
      {...field}
      error={error}
      ref={mergedRef}
      label={type === 'hidden' ? null : label}
    />
  );
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  TextInputComponent,
);
