import { CircleInfoIcon } from '@agtuary/ui/icons';
import { Tooltip, useMantineTheme } from '@mantine/core';
import Link from 'next/link';
import { Route } from 'nextjs-routes';
import { MouseEvent, useCallback } from 'react';

type InfoButtonProps = {
  href: Route;
  label: string;
};

export function InfoButton({ href, label }: InfoButtonProps) {
  const theme = useMantineTheme();

  const onClick = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
  }, []);

  return (
    <Tooltip color="gray" label={label} withArrow mr={20}>
      <Link href={href} onClick={onClick}>
        <CircleInfoIcon width={16} height={16} color={theme.colors.gray[7]} />
      </Link>
    </Tooltip>
  );
}
