import { Text } from '@mantine/core';
import { NotificationProps, showNotification } from '@mantine/notifications';
import { captureException, captureMessage } from '@sentry/core';

type ShowErrorOptions =
  | string
  | (Omit<NotificationProps, 'title' | 'message'> &
      (
        | {
            title: string;
            message?: string;
          }
        | {
            title?: string;
            message: string;
          }
      ) & {
        /** Should the title (or message if none) be sent to Sentry? (Defaults to true) */
        captureMessage?: boolean;
        /** Supply an error or other data to be sent to Sentry as an exception */
        captureException?: unknown;
      });

export const showError = (options: ShowErrorOptions) => {
  const {
    title,
    message,
    captureException: exceptionToCapture = undefined,
    captureMessage: shouldCaptureMessage = true,
    ...props
  } = typeof options === 'string'
    ? { title: options, message: undefined }
    : options;

  // Log this as a warning to Sentry
  if (shouldCaptureMessage && (title || message)) {
    captureMessage(title || message, {
      level: 'warning',
    });
  }

  // If provided, pass the exception to Sentry
  if (exceptionToCapture) {
    captureException(exceptionToCapture);
  }

  showNotification({
    title: title ?? 'Something went wrong!',
    message: message && <Text>{message}</Text>,
    color: 'red',
    ...props,
  });
};
