import {
  Flex,
  Checkbox as MTCheckbox,
  CheckboxGroupProps as MTCheckboxGroupProps,
} from '@mantine/core';
import { ForwardedRef, forwardRef, useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { DEFAULT_INPUT_WRAPPER_ORDER } from '../constants';
import formStyles from '../formStyles.module.scss';
import { useFormLogic } from '../hooks';
import { RHFInputProps } from '../types';
import styles from './CheckboxGroup.module.scss';

export type CheckboxOption = {
  value: string;
  label: string;
  disabled?: boolean;
  description?: string;
  error?: string;
};
export type CheckboxGroupProps = {
  options: CheckboxOption[];
  disabled?: boolean;
} & Omit<MTCheckboxGroupProps, 'type' | 'children'> &
  Omit<RHFInputProps, 'type'>;

const mtCheckboxGroupClassNames = {
  root: formStyles.root,
};

const mtCheckboxClassNames = {
  root: styles.root,
};

export function CheckboxGroupComponent(
  props: CheckboxGroupProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { options, name, onChange, disabled } = props;
  const { control } = useFormContext();

  const { error, label, validationRules } = useFormLogic({
    ...props,
  });

  const { field } = useController({ name, control, rules: validationRules });

  const checkboxGroupOnChange = useCallback(
    (value: string[]) => {
      field.onChange({ target: { value } });
      if (onChange) {
        onChange(value);
      }
    },
    [field, onChange],
  );

  return (
    <MTCheckbox.Group
      classNames={mtCheckboxGroupClassNames}
      inputWrapperOrder={DEFAULT_INPUT_WRAPPER_ORDER}
      {...props}
      {...field}
      label={label}
      error={error}
      onChange={checkboxGroupOnChange}
      ref={ref}
    >
      <Flex direction="column">
        {options.map((option) => (
          <MTCheckbox
            classNames={mtCheckboxClassNames}
            key={option.value}
            value={option.value}
            label={option.label}
            disabled={disabled || field.disabled || option.disabled}
            description={option.description}
            error={option.error}
          />
        ))}
      </Flex>
    </MTCheckbox.Group>
  );
}

export const CheckboxGroup = forwardRef<HTMLDivElement, CheckboxGroupProps>(
  CheckboxGroupComponent,
);
