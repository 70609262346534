import { createContext, Dispatch } from 'react';
import { MoreInfoRequiredAction } from './types';

export const defaultUserContext: {
  moreInfoRequired: boolean | null;
  moreInfoRequiredDispatch: Dispatch<MoreInfoRequiredAction>;
} = {
  moreInfoRequired: null,
  moreInfoRequiredDispatch: () => undefined,
};

export const UserContext = createContext(defaultUserContext);
