import { useReportUrls } from '@agtuary/api/hooks/reports/useReportUrls';
import { PaperPDFIcon, SendIcon } from '@agtuary/ui/icons';
import {
  ActionIcon,
  Box,
  CloseButton,
  Drawer,
  Group,
  LoadingOverlay,
  Title,
  Tooltip,
} from '@mantine/core';
import { useDidUpdate, useElementSize } from '@mantine/hooks';
import { HEADER_HEIGHT_SLIM } from 'constants/layout';
import { useReportActions } from 'hooks/report/useReportActions';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ReportViewDrawerContext } from './ReportViewDrawerContext';

export function ReportViewDrawer() {
  const { report, property, onClose, setReport, setProperty, setOnClose } =
    useContext(ReportViewDrawerContext);
  const [isLoading, setIsLoading] = useState(true);

  const { data: urls } = useReportUrls(report);

  const { ref: drawerSizeRef, width: drawerWidth } = useElementSize();

  const reportWidth = 1191;

  const frameScale = drawerWidth ? drawerWidth / reportWidth : 1;

  const [isOpen, setIsOpen] = useState(!!report?.id);

  const doOnClose = useCallback(() => {
    const original = {
      report,
      property,
      onClose,
    };

    setReport(null);
    setProperty(null);
    setOnClose(null);

    original.onClose?.(original.report, original.property);
  }, [onClose, setReport, setProperty, setOnClose, report, property]);

  useEffect(() => {
    setIsOpen(!!report?.id);
    setIsLoading(true);
  }, [report?.id]);

  useDidUpdate(() => {
    if (!isOpen) {
      doOnClose();
    }
  }, [isOpen]);

  const { download, share } = useReportActions(report, property);

  return (
    <Drawer
      opened={isOpen}
      position="right"
      withCloseButton={false}
      title={
        <Box w={drawerWidth} h={HEADER_HEIGHT_SLIM} px="sm">
          <Group spacing="xs" h="100%" p={0} m={0} position="apart">
            <Title order={5} p={0} m={0}>
              {report?.name ?? 'Report'}
            </Title>
            <Group spacing={6} h="100%" p={0} m={0}>
              <Tooltip
                label="Download as PDF"
                withArrow
                zIndex={500}
                disabled={!urls?.pdfUrl}
              >
                <ActionIcon
                  variant="transparent"
                  color="gray"
                  size="xs"
                  disabled={!urls?.pdfUrl}
                  onClick={() => download()}
                >
                  <PaperPDFIcon width={14} height={14} />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Share report" withArrow zIndex={500}>
                <ActionIcon
                  variant="transparent"
                  color="gray"
                  size="xs"
                  onClick={() => share()}
                >
                  <SendIcon width={14} height={14} />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Close" withArrow zIndex={500}>
                <CloseButton
                  size="xs"
                  iconSize={16}
                  ml="xs"
                  onClick={() => setIsOpen(false)}
                />
              </Tooltip>
            </Group>
          </Group>
        </Box>
      }
      overlayProps={{
        blur: 2,
        opacity: 0,
      }}
      size={850}
      onClose={doOnClose}
      styles={{
        body: {
          overflow: 'hidden',
          height: '100%',
          padding: 0,
        },
        header: {
          padding: 0,
          margin: 0,
        },
      }}
    >
      <Box
        ref={drawerSizeRef}
        w="100%"
        h="100%"
        pos="relative"
        style={{ overflow: 'hidden' }}
      >
        <LoadingOverlay visible={isLoading} />
        <iframe
          title="Report"
          src={urls?.interactiveUrl ?? urls?.pdfUrl}
          style={{
            border: 'none',
            overflowX: 'hidden',
            width: reportWidth,
            height: `calc((100% - ${HEADER_HEIGHT_SLIM}px) / ${frameScale} + ${HEADER_HEIGHT_SLIM}px)`,
            margin: 0,
            padding: 0,
            transform: `scale(${frameScale})`,
            WebkitTransform: `scale(${frameScale})`,
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- old code, will be removed as jobs are removed
            ['MozTransform' as string]: `scale(${frameScale})`,
            msTransform: `scale(${frameScale})`,
            OTransform: `scale(${frameScale})`,
            transformOrigin: '0 0',
            WebkitTransformOrigin: '0 0',
            MozTransformOrigin: '0 0',
            msTransformOrigin: '0 0',
            OTransformOrigin: '0 0',
          }}
          onLoad={() => setIsLoading(false)}
        />
      </Box>
    </Drawer>
  );
}
