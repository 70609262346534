import { createMutationKeys } from '@lukemorales/query-key-factory';
import { Organisation } from '../types/organisation';
import { patchMutator } from '../helpers/clientSide/requests';

export const organisations = createMutationKeys('organisations', {
  one: (organisationId: string) => ({
    mutationKey: [organisationId],
    mutationFn: (arg: Partial<Organisation>) =>
      patchMutator(`/api/organisations/${organisationId}`, { arg }),
  }),
});
