import { useMutation, useQueryClient } from '@tanstack/react-query';
import { User } from '../../../types/user';
import { queries } from '../../../queries';
import { mutations } from '../../../mutations';

export const useUpdateUser = (id: string) => {
  const queryClient = useQueryClient();
  const query = queries.users.one(id);

  return useMutation<
    User,
    unknown,
    Partial<User>,
    { previous: User | undefined }
  >({
    ...mutations.users.one(id),

    onMutate: async (newData: Partial<User>) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(query);

      // Snapshot the previous value
      const previous = queryClient.getQueryData<User>(query.queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(query.queryKey, {
        ...previous,
        ...newData,
      });

      // Return a context object with the snapshotted value
      return { previous };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newData, context) => {
      queryClient.setQueryData(query.queryKey, context?.previous);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(query);
    },
  });
};
