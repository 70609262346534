import { createContext, SetStateAction, Dispatch } from 'react';
import { Property } from '@agtuary/api/types/property';
import { Report } from '@agtuary/api/types/report';
import { ReportViewDrawerOnClose } from './types';

export const defaultReportViewDrawerContext: {
  report: Report | null;
  setReport: Dispatch<SetStateAction<Report | null>>;
  property: Property | null;
  setProperty: Dispatch<SetStateAction<Property | null>>;
  onClose: ReportViewDrawerOnClose;
  setOnClose: Dispatch<SetStateAction<ReportViewDrawerOnClose>>;
} = {
  report: null,
  setReport: () => undefined,
  property: null,
  setProperty: () => undefined,
  onClose: null,
  setOnClose: () => undefined,
};

export const ReportViewDrawerContext = createContext(
  defaultReportViewDrawerContext,
);
