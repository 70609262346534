import { getFragmentData, gql } from '@agtuary/graphql';
import { useQuery } from '@apollo/client';
import { Button, Center, Group, Loader, Text } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { MapShapesList } from 'components/mapShapes/MapShapesList';
import { useCallback, useMemo, useState } from 'react';
import { GET_MAP_SHAPES } from './gql';

const SelectMapShapeFromLibraryModalShapeFragment = gql(/* GraphQL */ `
  fragment SelectMapShapeFromLibraryModal_MapShape on MapShape {
    id
    geometry
    ...MapShapesList_MapShape
  }
`);

export type SelectMapShapeFromLibraryModalInnerProps = {
  propertyId: string;
  onConfirm: (
    mapShapes: {
      id: string;
      geometry: GeoJSON.MultiPolygon;
    }[],
  ) => void;
};

export function SelectMapShapeFromLibraryModal({
  context,
  id,
  innerProps: { propertyId, onConfirm },
}: ContextModalProps<SelectMapShapeFromLibraryModalInnerProps>) {
  const { closeModal } = context;
  const [selected, setSelected] = useState<string[]>([]);

  const handleSelect = useCallback((selectedId: string, checked: boolean) => {
    setSelected((prev) => {
      const filtered = prev.filter((current) => current !== selectedId);
      return checked ? [...filtered, selectedId] : filtered;
    });
  }, []);

  const {
    data: mapShapesData,
    loading,
    error,
  } = useQuery(GET_MAP_SHAPES, {
    variables: { propertyId },
    fetchPolicy: 'cache-and-network',
  });

  const mapShapes = useMemo(
    () =>
      getFragmentData(
        SelectMapShapeFromLibraryModalShapeFragment,
        mapShapesData?.mapShapes,
      ),
    [mapShapesData?.mapShapes],
  );

  const handleCancel = useCallback(() => closeModal(id), [closeModal, id]);
  const handleConfirm = useCallback(() => {
    onConfirm(
      mapShapes?.reduce<{ id: string; geometry: GeoJSON.MultiPolygon }[]>(
        (acc, shape) =>
          selected.includes(shape.id) && shape.geometry
            ? [
                ...acc,
                {
                  id: shape.id,
                  geometry: shape.geometry,
                },
              ]
            : acc,
        [],
      ) || [],
    );
    closeModal(id);
  }, [closeModal, id, mapShapes, onConfirm, selected]);

  return (
    <>
      {loading && (
        <Center>
          <Loader />
        </Center>
      )}

      {error && (
        <Center>
          <Text color="red">Failed to load map shapes</Text>
        </Center>
      )}

      {!loading && !error && (
        <>
          <MapShapesList
            selectable
            onChangeSelected={handleSelect}
            selected={selected}
            mapShapes={mapShapes}
          />
          <Group position="apart" mt="md">
            <Button onClick={handleConfirm}>Confirm</Button>
            <Button variant="outline" onClick={handleCancel}>
              Cancel
            </Button>
          </Group>
        </>
      )}
    </>
  );
}
